import { combineReducers } from 'redux'

import badges from './badges'
import contacts from './contacts'
import exhibitors from './exhibitors'
import myschedule from './myschedule'
import recommendations from './recommendations'
import schedule from './schedule'
import speakers from './speakers'
import timeline from './timeline'
import topics from './topics'

const filterSettings = combineReducers({
  badges: badges.reducers.index,
  contacts: contacts.reducers.index,
  exhibitors: exhibitors.reducers.index,
  myschedule: myschedule.reducers.index,
  recommendations: recommendations.reducers.index,
  schedule: schedule.reducers.index,
  speakers: speakers.reducers.index,
  timeline: timeline.reducers.index,
  topics: topics.reducers.index,
})

export default filterSettings
