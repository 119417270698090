import { serviceGetExhibitorTypes, serviceCreateExhibitorType, serviceUpdateExhibitorType, serviceDeleteExhibitorType } from 'admin/services/exhibitors'
import { generateDataModule } from 'admin/modules/generateModule'
import { listToMapping } from 'common/utils/componentHelper'
import sort from 'common/utils/sortHelper'
import search, { buildFieldList } from 'common/utils/search'


const ExhibitorTypesModule = generateDataModule({
  initialState: {
    searchTerm: '',
  },
  moduleKey: 'cpo/exhibitorTypes',
  reducerKey: 'admin.exhibitorTypes',
  refreshTime: 10000,

  services: {
    get: { service: serviceGetExhibitorTypes },
    post: { service: serviceCreateExhibitorType },
    put: { service: serviceUpdateExhibitorType },
    delete: { service: serviceDeleteExhibitorType },
  },
})


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

ExhibitorTypesModule.registerSelector(
  'listData',
  [
    (state, moduleState) => moduleState.data,
    state => ExhibitorTypesModule.selectors.searchTerm(state),
  ],
  (exhibitorTypes, searchTerm) => {
    let filteredExhibitorTypes = [...exhibitorTypes]

    if (searchTerm) {
      filteredExhibitorTypes = filteredExhibitorTypes.filter(exhibitorType => search(
        searchTerm,
        buildFieldList(exhibitorType, ['title'])
      ))
    }

    return sort(filteredExhibitorTypes, [{ id: 'order', order: -1 }])
  }
)

ExhibitorTypesModule.registerSelector(
  'dropdownValues',
  state => ExhibitorTypesModule.selectors.listData(state),
  exhibitorTypes => exhibitorTypes.map(exhibitorType => ({ label: exhibitorType.title, value: exhibitorType.id })),
)

ExhibitorTypesModule.registerSelector(
  'searchTerm',
  (state, moduleState) => moduleState.searchTerm,
  searchTerm => searchTerm,
)

ExhibitorTypesModule.registerSelector(
  'nextOrder',
  state => ExhibitorTypesModule.selectors.data(state),
  exhibitorTypes => Math.max(exhibitorTypes.map(d => d.order || -1)) + 1
)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////

ExhibitorTypesModule.registerAction('setSearchTerm', searchTerm => dispatch => dispatch({
  type: ExhibitorTypesModule.actionKeys.setSearchTerm,
  payload: searchTerm,
}))

ExhibitorTypesModule.registerDataAction('reorder', data => dispatch => {
  const eventId = ExhibitorTypesModule._getEventId()
  const exhibitorTypesById = listToMapping(data, 'id')

  dispatch({ type: ExhibitorTypesModule.actionKeys.reorderStart, payload: exhibitorTypesById })

  const reorder = () => {
    let result = Promise.resolve()

    for (let i = 0; i < data.length; i++) {
      result = result.then(() => serviceUpdateExhibitorType(eventId, data[i]))
    }

    return result
  }

  return Promise.all([reorder()])
    .then(() => {
      dispatch({ type: ExhibitorTypesModule.actionKeys.reorderSuccess, payload: exhibitorTypesById })
    })
    .catch(() => {
      dispatch({ type: ExhibitorTypesModule.actionKeys.reorderError, payload: exhibitorTypesById })
    })
})


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////

ExhibitorTypesModule.registerReducer(
  ExhibitorTypesModule.actionKeys.setSearchTerm,
  (state, action) => ({ ...state, searchTerm: action.payload })
)

ExhibitorTypesModule.registerReducer(
  ExhibitorTypesModule.actionKeys.reorderSuccess,
  (state, action) => ({
    ...state,
    data: state.data.map(data => {
      const exhibitorType = action.payload[data.id]
      return exhibitorType ? { ...data, order: data.order } : data
    }),
  })
)


export default ExhibitorTypesModule
