import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { getAxiosConfig } from './helper'
import { translateCustomField } from './events'


function translateDeviceGroup(deviceGroup) {
  return deviceGroup ? Promise.resolve({
    ...deviceGroup,
    layers: deviceGroup.layers.map(layer => ({
      ...layer,
      groups: layer.groups.map(group => ({ ...group, title: getTranslatedObject(group.title) })),
      field: translateCustomField(layer.field),
    })),
  }) : {}
}

export function serviceCreateGroupings(eventId, success, error) {
  return axios.post(`/events/${eventId}/attendees/device_groups`, {}, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetDeviceGroups(eventId, success, error) {
  return axios.get(`/events/${eventId}/device_groups`, getAxiosConfig())
    .then((response) => translateDeviceGroup(response.data))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceSaveDeviceGroups(eventId, groups, success, error) {
  return axios.put(`/events/${eventId}/device_groups`, groups, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGenerateDeviceGroups(eventId, success, error) {
  return axios.post(`/events/${eventId}/device_groups`, {}, getAxiosConfig())
    .then((response) => translateDeviceGroup(response.data))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceRefreshDeviceGroupsStats(eventId, success, error) {
  return axios.post(`/events/${eventId}/device_groups/stats`, {}, getAxiosConfig())
    .then((response) => translateDeviceGroup(response.data))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeleteDeviceGroups(eventId, success, error) {
  return axios.delete(`/events/${eventId}/device_groups`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
