import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { getAxiosConfig } from './helper'
import { parsePaginationHeaders } from 'common/services/helper'

function translateSpeaker(speaker) {
  return {
    ...speaker,
    bio: getTranslatedObject(speaker.bio),
    experience: speaker.experience
      ? speaker.experience.map(exp => ({
        ...exp,
        position: getTranslatedObject(exp.position),
        company: getTranslatedObject(exp.company),
      }))
      : [],
  }
}

function buildQueryParams(_query) {
  const query = { ..._query }
  const queryKeys = Object.keys(query)
  let queryString = '?'

  if (queryKeys.length === 0 && query.constructor === Object) {
    return ''
  }

  queryKeys.forEach(key => {
    if (query[key] != null) {
      queryString += `&${key}=${query[key]}`
    }
  })

  return queryString
}


export function serviceGetSpeakers(eventId, queryParams, success, error) {
  const params = buildQueryParams(queryParams)
  return axios.get(`/events/${eventId}/speakers${params}`, getAxiosConfig())
    .then((response) => ({
      data: response.data.map(translateSpeaker),
      ...parsePaginationHeaders(response),
    }))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetSpeaker(eventId, speakerId, success, error) {
  return axios.get(`/events/${eventId}/speakers/${speakerId}`, getAxiosConfig())
    .then((response) => Promise.resolve(translateSpeaker(response.data)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function servicePostSpeaker(eventId, speaker, success, error) {
  return axios.post(`/events/${eventId}/speakers`, speaker, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateSpeaker(eventId, speaker, success, error) {
  return axios.put(`/events/${eventId}/speakers/${speaker.id}`, JSON.stringify(speaker), getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeleteSpeaker(eventId, speakerId, success, error) {
  return axios.delete(`/events/${eventId}/speakers/${speakerId}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeleteSpeakers(eventId, success, error) {
  return axios.delete(`/events/${eventId}/speakers`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
