import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { getAxiosConfig } from './helper'


export function serviceGetTags(eventId, success, error) {
  return axios.get(`/events/${eventId}/tags/sessions?limit=1000`, getAxiosConfig())
    .then((response) => {
      const data = response.data.slice().map(tag => ({ ...tag, name: getTranslatedObject(tag.name) }))
      return Promise.resolve(data)
    })
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceAddTag(eventId, tag, success, error) {
  return axios.put(`/events/${eventId}/tags/sessions/${tag.id}`, tag, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateTags(eventId, tags, success, error) {
  return axios.put(`/events/${eventId}/tags/sessions/`, { tags: tags }, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeleteTag(eventId, tagId, success, error) {
  return axios.delete(`/events/${eventId}/tags/sessions/${tagId}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
