import { addTimeFilterSupport } from './helper'
import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetCheckInOvertime, serviceGetCheckInOvertimeSum } from 'admin/services/dashboard'
import { currentEventIdSelector, eventEndDateSelector, eventTimezoneSelector } from 'admin/modules/events'
import Moment from 'moment'


const CheckInOvertimeModule = generateDataModule({
  initialData: {
    fullOvertime: [],
    minimalOvertime: [],
    shownTotal: 0,
    total: 0,
  },
  moduleKey: 'dashboardCheckInOvertime',
  reducerKey: 'admin.dashboard.checkInOvertime',
  services: {
    get: { service: serviceGetCheckInOvertime },
  },
})

addTimeFilterSupport(CheckInOvertimeModule, true)


CheckInOvertimeModule.registerSelector('fullOvertime', (state, moduleState) => moduleState.data.fullOvertime, data => data || [])
CheckInOvertimeModule.registerSelector('minimalOvertime', (state, moduleState) => moduleState.data.minimalOvertime, data => data || [])
CheckInOvertimeModule.registerSelector('shownTotal', (state, moduleState) => moduleState.data.shownTotal, data => data || 0)
CheckInOvertimeModule.registerSelector('total', (state, moduleState) => moduleState.data.total, data => data || 0)


CheckInOvertimeModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {
    if (!CheckInOvertimeModule.selectors.areTimeFiltersInitialized(getState())) {
      dispatch(CheckInOvertimeModule.actions.initializeFilters())
      dispatch(CheckInOvertimeModule.actions.initializeMinimalFilters())
    }

    const state = getState()
    const eventId = currentEventIdSelector(state)
    const timeFilters = CheckInOvertimeModule.selectors.timeFilters(state)
    const timezone = eventTimezoneSelector(state)
    const currentMoment = Moment.unix(Moment().unix()).tz(timezone)
    const overtimeService = CheckInOvertimeModule._getService('get')
    const overtimeSumService = (...args) => serviceGetCheckInOvertimeSum(eventId, ...args)

    const getMinimalOvertime = () => overtimeService({
      startDate: currentMoment.startOf('day').unix(),
      endDate: undefined,
    }, timezone)

    const getFullOvertime = () => overtimeService(timeFilters, timezone)
    const getFullCount = () => overtimeSumService(timeFilters, timezone)
    const getTotalCount = () => overtimeSumService({ endDate: eventEndDateSelector(state) }, timezone)

    dispatch({ type: CheckInOvertimeModule.actionKeys.getStart })
    return Promise.all([getMinimalOvertime(), getFullOvertime(), getFullCount(), getTotalCount()])
      .then(responses => {
        dispatch({
          type: CheckInOvertimeModule.actionKeys.getSuccess,
          payload: {
            fullOvertime: responses[1],
            minimalOvertime: responses[0],
            shownTotal: responses[2],
            total: responses[3],
          },
        })
      })
      .catch(() => {
        dispatch({ type: CheckInOvertimeModule.actionKeys.getError })
      })
  }
)

CheckInOvertimeModule.registerReducer(CheckInOvertimeModule.actionKeys.getSuccess, (state, action) => ({
  ...state,
  data: action.payload,
  isLoading: false,
  isLoaded: true,
  lastUpdated: Date.now(),
}))


export default CheckInOvertimeModule

