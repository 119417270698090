import axios from 'axios'
import config from 'config'
import i18n from 'i18next'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { buildAssetURL } from 'common/utils/assets'
import { getAxiosConfig } from './helper'


export function serviceGetProfileAdmin(success, error) {
  return axios.get('/accounts/me', getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetProfile(eventId, success, error) {
  return axios.get('/events/' + eventId + '/attendees/me', getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeleteCredential(eventId, credential, success, error) {
  return axios.delete(`/events/${eventId}/attendees/me/credentials/${credential}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateProfile(eventId, profile, success, error) {
  const id = profile.id
  delete profile.id // eslint-disable-line no-param-reassign

  return axios.put('/events/' + eventId + '/attendees/' + id, JSON.stringify(profile), getAxiosConfig())
    .then((response) => { success(profile, response.data); profile.id = id }, apiErrorHandler(error, 'backend')) // eslint-disable-line no-param-reassign
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateProfilePicture(file, success, error) {
  let axiosConfig = getAxiosConfig()

  // Increase timeout because uploading larger picture would timeout
  axiosConfig = { ...axiosConfig, headers: { ...axiosConfig.headers, 'Content-Type': 'multipart/form-data' }, timeout: 30000 }

  const onError = () => {
    const errorResponse = {
      response: {
        status: 504,
        data: {
          description: i18n.getFixedT(i18n.language, 'attendee_customModule')('profile.upload_picture.error'),
        },
      },
    }

    return apiErrorHandler(error, 'backend')(errorResponse)
  }

  return axios.post(`${config.assetsUrl}/upload`, file, axiosConfig)
    .then((response) => success(buildAssetURL(response.data.id)))
    .catch((response) => onError(response))
}

export function serviceAddSocialNetwork(eventId, code, codeType, redirectUri, importProfile, success, error) {
  const data = {
    code: code,
    code_type: codeType,
    redirectUri: redirectUri,
    client_id: config.clientId,
  }

  return axios.post(`/events/${eventId}/attendees/me/connect?import_profile=${importProfile}`, JSON.stringify(data), getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceRemoveSocialNetwork(eventId, socialName, success, error) {
  let axiosConfig = getAxiosConfig()
  axiosConfig = { ...axiosConfig, data: { [socialName]: true } }

  return axios.delete(`/events/${eventId}/attendees/me/connect`, axiosConfig)
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetNetworkingPreviewProfile(eventId) {
  return axios.get(`/events/${eventId}/attendees/me/networking_preview`, getAxiosConfig())
    .then(response => response.data)
    .catch(response => apiCatchError(response))
}
