import { combineReducers } from 'redux'
import contactExchanges from './contactExchanges'
import sessionAttendance from './sessionAttendance'


const distributionGraphs = combineReducers({
  contactExchanges,
  sessionAttendance,
})

export default distributionGraphs
