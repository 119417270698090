import { combineReducers } from 'redux'

import accountGroups from './accountGroups'
import AccountManagementModule from './accountManagement'
import achievements from './achievements'
import analytics from './Analytics'
import app from './app'
import appointmentSummary from './appointmentSummary'
import AttendeesModule from './attendees'
import attendeeTypes from './attendeeTypes'
import badgePrinting from './badgePrinting'
import blueTraceReducers from './BlueTrace'
import infras from './infras'
import bridges from './bridges'
import calendars from './calendars'
import carousels from './carousels'
import checkinProgress from './checkinProgress'
import customFields from './customFields'
import customPages from './customPages'
import dashboard from './Dashboard'
import deviceGroups from './deviceGroups'
import DocTokenModule from './docToken'
import domainBranding from './domainBranding'
import domains from './domains'
import emailEditor from './emailEditor'
import emailTemplates from './emailTemplates'
import eventFeatures from './eventFeatures'
import events from './events'
import ExhibitorsModule from './exhibitors'
import ExhibitorTypesModule from './exhibitorTypes'
import FieldsManagerModule from './fieldsManager'
import floorPlan from './floorPlan'
import GamificationModule from './gamification'
import InfoPopupsModule from './infoPopups'
import HubsModule from './hubs'
import locations from './locations'
import MenuModule from './menu'
import permissions from './permissions'
import PrivacyPolicyModule from './privacyPolicy'
import profile from './profile'
import registrationProgress from './registrationProgress'
import schedules from './schedules'
import sessions from './sessions'
import speakers from './speakers'
import tags from './tags'
import topics from './topics'
import TouchpointsModule from './touchpoints'
import UserManagementModule from './userManagement'
import venueMaps from './venueMaps'
import zoneStats from './zoneStats'
import trackingTagCounts from './trackingTagCounts'
import WearablesModule from './wearables'


const admin = combineReducers({
  accountGroups,
  accountManagement: AccountManagementModule.reducers.index,
  achievements,
  analytics,
  app,
  appointmentSummary,
  attendees: AttendeesModule.reducers.index,
  attendeeTypes: attendeeTypes.reducers.index,
  badgePrinting,
  bluetrace: blueTraceReducers,
  infras,
  bridges,
  calendars,
  carousels,
  checkinProgress,
  customFields,
  customPages: customPages.reducers.index,
  dashboard,
  deviceGroups,
  docToken: DocTokenModule.reducers.index,
  domainBranding,
  domains: domains.reducers.index,
  emailEditor,
  emailTemplates,
  eventFeatures,
  events,
  exhibitors: ExhibitorsModule.reducers.index,
  exhibitorTypes: ExhibitorTypesModule.reducers.index,
  fieldsManager: FieldsManagerModule.reducers.index,
  floorPlan,
  gamification: GamificationModule.reducers.index,
  infoPopups: InfoPopupsModule.reducers.index,
  hubs: HubsModule.reducers.index,
  locations,
  menu: MenuModule.reducers.index,
  permissions,
  privacyPolicy: PrivacyPolicyModule.reducers.index,
  profile,
  registrationProgress,
  schedules,
  sessions,
  speakers,
  tags,
  topics: topics.reducers.index,
  touchpoints: TouchpointsModule.reducers.index,
  userManagement: UserManagementModule.reducers.index,
  venueMaps,
  zoneStats,
  trackingTagCounts,
  wearables: WearablesModule.reducers.index,
})

export default admin

/**
 * Returns a multi-line string containing all error messages passed as arguments
 * @param {string[]} errors
 */
export function buildErrorMessage(errors) {
  let errorMessage = ''
  errors.forEach(error => { if (error) errorMessage = `${errorMessage} \n` })
  return errorMessage
}
