import { serviceGetEventMenu, serviceUpdateEventMenu } from 'admin/services/events'
import { generateDataModule } from './generateModule'


const MenuModule = generateDataModule({
  moduleKey: 'cpo/menu',
  reducerKey: 'admin.menu',

  services: {
    get: { service: serviceGetEventMenu },
    put: { service: serviceUpdateEventMenu },
  },
})


export default MenuModule
