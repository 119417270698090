import { addTimeFilterSupport } from './helper'
import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetContactExchanges, serviceGetAttendeeEngagement, serviceGetCurrentSumContactExchanges } from 'admin/services/dashboard'
import { currentEventIdSelector, eventTimezoneSelector } from 'admin/modules/events'

const ContactExchangesModule = generateDataModule({
  initialData: [],
  initialState: { attendeeEngagement: {}, currentSum: null, minimalData: [] },
  moduleKey: 'dashboardContactExchanges',
  reducerKey: 'admin.dashboard.contactExchanges',
  services: {
    get: { service: serviceGetContactExchanges },
  },
})

addTimeFilterSupport(ContactExchangesModule, true)

ContactExchangesModule.registerSelector('avgContactExchanges', (state, moduleState) => moduleState.attendeeEngagement, attendeeEngagement => attendeeEngagement.avg_contacts_exchanged)
ContactExchangesModule.registerSelector('currentSumOfContactExchanges', (state, moduleState) => moduleState.currentSum, currentSum => currentSum)
ContactExchangesModule.registerSelector('fullData', (state, moduleState) => moduleState.data, data => data)
ContactExchangesModule.registerSelector('minimalData', (state, moduleState) => moduleState.minimalData, data => data)
ContactExchangesModule.registerSelector('totalContactExchanges', (state, moduleState) => moduleState.attendeeEngagement, attendeeEngagement => attendeeEngagement.contacts_exchanged)

ContactExchangesModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {
    const eventId = currentEventIdSelector(getState())
    if (!ContactExchangesModule.selectors.areTimeFiltersInitialized(getState())) {
      dispatch(ContactExchangesModule.actions.initializeFilters())
      dispatch(ContactExchangesModule.actions.initializeMinimalFilters())
    }
    dispatch({ type: ContactExchangesModule.actionKeys.getStart })
    const timeFilters = ContactExchangesModule.selectors.timeFilters(getState())
    const minimalFilters = ContactExchangesModule.selectors.minimalFilters(getState())
    const timezone = eventTimezoneSelector(getState())

    const getContactExchanges = () => ContactExchangesModule._getService('get')(timeFilters, timezone)
    const getMinimal = () => ContactExchangesModule._getService('get')(minimalFilters, timezone)
    const getCurrentSum = () => serviceGetCurrentSumContactExchanges(eventId, timeFilters, timezone)
    const getAttendeeEngagement = () => serviceGetAttendeeEngagement(eventId)

    return Promise.all([getContactExchanges(), getCurrentSum(), getAttendeeEngagement(), getMinimal()])
      .then(responses => {
        dispatch({
          type: ContactExchangesModule.actionKeys.getSuccess,
          payload: {
            contactExchanges: responses[0],
            currentSum: responses[1],
            attendeeEngagement: responses[2],
            minimalData: responses[3],
          },
        })
      })
      .catch(() => {
        dispatch({ type: ContactExchangesModule.actionKeys.getError })
      })
  }
)

ContactExchangesModule.registerReducer(ContactExchangesModule.actionKeys.getSuccess, (state, action) => ({
  ...state,
  data: action.payload.contactExchanges,
  attendeeEngagement: action.payload.attendeeEngagement,
  currentSum: action.payload.currentSum,
  minimalData: action.payload.minimalData,
  isLoading: false,
  isLoaded: true,
  lastUpdated: Date.now(),
}))

export default ContactExchangesModule

