import axios from 'axios'
import { buildError } from 'common/utils/api'
import { getAxiosConfig } from './helper'
import { listToMapping } from 'common/utils/componentHelper'


export function serviceGetQualifiers(eventId) {
  return axios.get(`/events/${eventId}/representatives/me/qualifiers`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetQualifiersAnswers(eventId, leadId) {
  return axios.get(`/events/${eventId}/representatives/me/leads/${leadId}/qualifiers/responses`, getAxiosConfig())
    .then(response => listToMapping(response.data, 'qualifier_id'))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceUpdateQualifierAnswer(eventId, leadId, qualifierId, data) {
  return axios.put(`/events/${eventId}/representatives/me/leads/${leadId}/qualifiers/${qualifierId}/response`, data, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}
