import permissions, { getPermissions, getRole } from 'common/utils/Permissions/index'

const initialState = {
  ...permissions.full,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'CHECK_PERMISSIONS':
      return { ...getPermissions(getRole(action.data.roles, action.data.eventId)) }

    default:
      return state
  }
}
