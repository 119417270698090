import { serviceGetSessions, serviceGetCurrentSessions, serviceGetNextSessions, serviceAddSession, serviceUpdateSession, serviceDeleteSession, serviceGetSession, serviceGetParticipantsForSession, serviceAddParticipantToSession, serviceRemoveParticipantFromSession, serviceDeleteSessions, serviceStartLiveStream, serviceStopLiveStream, serviceSetSessionStreamPreview, serviceInitializeLiveStream } from 'admin/services/sessions'
import generateModule from 'common/utils/generateModule'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'
import Moment from 'moment'
import { listToMapping } from 'common/utils/componentHelper'
import { getTranslatedValue } from 'common/utils/translator'
import { currentEventIdSelector, eventTimezoneSelector } from 'admin/modules/events'
import { createSelector } from 'reselect'


const selectState = state => state.admin.sessions
const selectSessions = state => state.admin.sessions.data
const selectParticipants = state => selectState(state).participants

export const goingParticipantsSelector = createSelector(selectParticipants, participants => participants)

export const sessionsByIdSelector = createSelector(selectSessions, sessions => listToMapping(sessions, 'id'))


const { actions, reducers, initialState } = generateModule({
  itemName: 'session',
  idFieldName: 'id',
  toasterFieldName: 'title',
  services: {
    get: serviceGetSessions,
    add: serviceAddSession,
    upd: serviceUpdateSession,
    del: serviceDeleteSession,
  },
})

initialState.currentSession = undefined
initialState.currentCalendarId = undefined
initialState.participants = {
  going: [],
  checked_in: [],
  attended: [],
}
initialState.nextSessions = []


const findIndexOfSession = (allSessions, session) => {
  for (let i = 0; i < allSessions.length; i++) {
    if (allSessions[i].id === session.id) return i
  }
  return -1
}

const updateMySession = (allSessions, session) => {
  const sessionList = allSessions.slice()
  const index = findIndexOfSession(sessionList, session)
  if (index !== -1) sessionList[index] = session
  return sessionList
}

const removeSession = (allSessions, session) => {
  const sessionList = allSessions.slice()
  const index = findIndexOfSession(sessionList, session)
  if (index !== -1) sessionList.splice(index, 1)
  return sessionList
}


actions.get = (calendarId, search = '', location = '', date = '') =>
  (dispatch, getState) => {
    dispatch(actions.getStart())

    const params = { query: search, location: location }

    if (date) {
      const timezone = eventTimezoneSelector(getState())
      const moment = Moment.unix(date).tz(timezone)
      params.start_date = moment.startOf('day').unix()
      params.end_date = moment.endOf('day').unix()
    }

    return serviceGetSessions(
      getState().admin.events.currentEvent.id,
      calendarId,
      params,
      (response) => dispatch({ type: 'GET_SESSIONS_SUCCESS', payload: { currentCalendarId: calendarId, sessions: response } }), // dispatch(actions.getSuccess(response)),
      (error) => dispatch(actions.getError(error))
    )
  }

reducers['GET_SESSIONS_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  data: action.payload.sessions,
  currentCalendarId: action.payload.currentCalendarId,
  isModifying: false,
  didInvalidate: false,
})

actions.getCurrentSessions = () =>
  (dispatch, getState) => {
    dispatch(actions.getStart())
    return serviceGetCurrentSessions(
      getState().admin.events.currentEvent.id,
      (response) => dispatch({ type: 'GET_CURRENT_SESSIONS_SUCCESS', payload: { sessions: response } }),
      (error) => dispatch(actions.getError(error))
    )
  }

reducers['GET_CURRENT_SESSIONS_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  data: action.payload.sessions,
  isModifying: false,
  didInvalidate: false,
})

actions.getNextSessions = () =>
  (dispatch, getState) => {
    dispatch(actions.getStart())
    return serviceGetNextSessions(
      getState().admin.events.currentEvent.id,
      (response) => dispatch({ type: 'GET_NEXT_SESSIONS_SUCCESS', payload: { sessions: response } }),
      (error) => dispatch(actions.getError(error))
    )
  }

reducers['GET_NEXT_SESSIONS_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  nextSessions: action.payload.sessions,
  isModifying: false,
  didInvalidate: false,
})

actions.add = (session) =>
  (dispatch, getState) => {
    dispatch({ type: 'ADD_SESSION_START' })
    return serviceAddSession(
      getState().admin.events.currentEvent.id,
      session,
      (response) => {
        dispatch({ type: 'ADD_SESSION_SUCCESS', payload: { session: response } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language)('common_module:add.title'),
          message: i18n.getFixedT(i18n.language)('common_module:add.success', { itemName: 'session', id: getTranslatedValue(session.title) }),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch({ type: 'ADD_SESSION_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language)('common_module:add.title'),
          message: error.description,
          level: 'success',
          permanent: false,
        }))
      }
    )
  }

reducers['ADD_SESSION_START'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: true,
  didInvalidate: false,
})

reducers['ADD_SESSION_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  data: [...state.data, action.payload.session],
  isModifying: false,
  didInvalidate: false,
})

reducers['ADD_SESSION_ERROR'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: false,
  didInvalidate: true,
})


actions.update = (session) =>
  (dispatch, getState) => {
    dispatch({ type: 'UPDATE_SESSION_START' })
    return serviceUpdateSession(
      getState().admin.events.currentEvent.id,
      session,
      (response) => {
        dispatch({ type: 'UPDATE_SESSION_SUCCESS', payload: { session: response } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language)('common_module:update.title'),
          message: i18n.getFixedT(i18n.language)('common_module:update.success', { itemName: 'session', id: getTranslatedValue(session.title) }),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch({ type: 'UPDATE_SESSION_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language)('common_module:update.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['UPDATE_SESSION_START'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: true,
  didInvalidate: false,
})

reducers['UPDATE_SESSION_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: false,
  didInvalidate: false,
  data: updateMySession(state.data, action.payload.session),
})

reducers['UPDATE_SESSION_ERROR'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: false,
  didInvalidate: true,
})


actions.delete = (session) =>
  (dispatch, getState) => {
    dispatch({ type: 'DELETE_SESSION_START' })
    return serviceDeleteSession(
      getState().admin.events.currentEvent.id,
      session.id,
      () => {
        dispatch({ type: 'DELETE_SESSION_SUCCESS', payload: { session: session } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language)('common_module:delete.title'),
          message: i18n.getFixedT(i18n.language)('common_module:delete.success', { itemName: 'session', id: getTranslatedValue(session.title) }),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch(actions.deleteError())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language)('common_module:delete.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['DELETE_SESSION_START'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: true,
  didInvalidate: false,
})

reducers['DELETE_SESSION_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: false,
  didInvalidate: false,
  data: removeSession(state.data, action.payload.session),
})


actions.getSession = (sessionId, showErrorToast) =>
  (dispatch, getState) => {
    dispatch({ type: 'GET_SESSION_START' })
    return serviceGetSession(
      getState().admin.events.currentEvent.id,
      sessionId,
      (response) => {
        dispatch({ type: 'GET_SESSION_SUCCESS', payload: { session: response } })
      },
      (error) => {
        dispatch({ type: 'GET_SESSION_ERROR' })
        if (showErrorToast) {
          dispatch(showToast({
            title: i18n.getFixedT(i18n.language)('common_module:get.title'),
            message: error.description,
            level: 'error',
            permanent: false,
          }))
        }
        return Promise.reject(error)
      }
    )
  }

reducers['GET_SESSION_START'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isFetching: true,
  isLoaded: false,
  isError: false,
})

reducers['GET_SESSION_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  isFetching: false,
  isLoaded: true,
  isError: false,
  currentSession: action.payload.session,
})

reducers['GET_SESSION_ERROR'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isFetching: false,
  isLoaded: true,
  isError: true,
})


actions.deleteSessions = (calendarId) =>
  (dispatch, getState) => {
    dispatch({ type: 'DELETE_SESSIONS_START' })
    return serviceDeleteSessions(
      getState().admin.events.currentEvent.id,
      calendarId,
      () => {
        dispatch({ type: 'DELETE_SESSIONS_SUCCESS' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language)('admin_customModule:sessions.delete_sessions.title'),
          message: i18n.getFixedT(i18n.language)('admin_customModule:sessions.delete_sessions.success'),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch({ type: 'DELETE_SESSIONS_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language)('admin_customModule:sessions.delete_sessions.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['DELETE_SESSIONS_START'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isFetching: true,
  isLoaded: false,
  isError: false,
})

reducers['DELETE_SESSIONS_SUCCESS'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isFetching: false,
  isLoaded: true,
  isError: false,
  data: [],
})

reducers['DELETE_SESSIONS_ERROR'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isFetching: false,
  isLoaded: true,
  isError: true,
})


actions.getParticipants = (session, relation, query) =>
  (dispatch, getState) => serviceGetParticipantsForSession(
    getState().admin.events.currentEvent.id,
    session.calendar_id,
    session.id,
    relation,
    query,
    (response) => dispatch({ type: 'GET_PARTICIPANTS_FOR_SESSION', payload: { participants: response, relation } }),
    (error) => dispatch(showToast({
      title: i18n.getFixedT(i18n.language)('admin_customModule:sessions.get_participants.title'),
      message: error.description,
      level: 'error',
      permanent: false,
    }))
  )

reducers['GET_PARTICIPANTS_FOR_SESSION'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  participants: {
    ...state.participants,
    [action.payload.relation]: action.payload.participants,
  },
})

actions.addParticipant = (attendee, sessionId) =>
  (dispatch, getState) => serviceAddParticipantToSession(
    getState().admin.events.currentEvent.id,
    attendee.id,
    sessionId,
    () => {
      dispatch({ type: 'ADD_PARTICIPANT_TO_SESSION', payload: { participant: attendee } })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language)('admin_customModule:sessions.add_participant.title'),
        message: i18n.getFixedT(i18n.language)('admin_customModule:sessions.add_participant.success'),
        level: 'success',
        permanent: false,
      }))
      return Promise.resolve()
    },
    (error) => {
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language)('admin_customModule:sessions.add_participant.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    }
  )

reducers['ADD_PARTICIPANT_TO_SESSION'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  participants: {
    ...state.participants,
    going: [...state.participants.going, action.payload.participant],
  },
})

actions.removeParticipant = (attendeeId, sessionId) =>
  (dispatch, getState) => serviceRemoveParticipantFromSession(
    getState().admin.events.currentEvent.id,
    attendeeId,
    sessionId,
    () => {
      dispatch({ type: 'REMOVE_PARTICIPANT_FROM_SESSION', payload: { attendeeId } })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language)('admin_customModule:sessions.remove_participant.title'),
        message: i18n.getFixedT(i18n.language)('admin_customModule:sessions.remove_participant.success'),
        level: 'success',
        permanent: false,
      }))
    },
    (error) => dispatch(showToast({
      title: i18n.getFixedT(i18n.language)('admin_customModule:sessions.remove_participant.title'),
      message: error.description,
      level: 'error',
      permanent: false,
    }))
  )

reducers['REMOVE_PARTICIPANT_FROM_SESSION'] = (state, action) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  isError: false,
  participants: {
    ...state.participants,
    going: state.participants.going.filter(d => d.id !== action.payload.attendeeId), // We can only remove reserved attendees
  },
})


actions.initializeSessionLiveStream = session =>
  (dispatch, getState) => {
    dispatch({ type: 'INITIALIZE_SESSION_LIVE_STREAM_START', payload: session })

    return serviceInitializeLiveStream(currentEventIdSelector(getState()), session.id)
      .then(response => {
        dispatch({ type: 'INITIALIZE_SESSION_LIVE_STREAM_SUCCESS', payload: response })
        return response
      })
      .catch(error => {
        dispatch({ type: 'INITIALIZE_SESSION_LIVE_STREAM_ERROR', payload: session })
        return Promise.reject(error)
      })
  }

reducers['INITIALIZE_SESSION_LIVE_STREAM_SUCCESS'] = (state, action) => ({
  ...state,
  data: state.data.map(s => (s.id === action.payload.id ? action.payload : s)),
})

actions.startSessionLiveStream = session =>
  (dispatch, getState) => {
    dispatch({ type: 'START_SESSION_LIVE_STREAM_START', payload: session })

    return serviceStartLiveStream(currentEventIdSelector(getState()), session.id)
      .then(response => {
        dispatch({ type: 'START_SESSION_LIVE_STREAM_SUCCESS', payload: response })
        return response
      })
      .catch(error => {
        dispatch({ type: 'START_SESSION_LIVE_STREAM_ERROR', payload: session })
        return Promise.reject(error)
      })
  }

reducers['START_SESSION_LIVE_STREAM_SUCCESS'] = (state, action) => ({
  ...state,
  data: state.data.map(s => (s.id === action.payload.id ? action.payload : s)),
})

actions.stopSessionLiveStream = session =>
  (dispatch, getState) => {
    dispatch({ type: 'STOP_SESSION_LIVE_STREAM_START', payload: session })

    return serviceStopLiveStream(currentEventIdSelector(getState()), session.id)
      .then(response => {
        dispatch({ type: 'STOP_SESSION_LIVE_STREAM_SUCCESS', payload: response })
        return response
      })
      .catch(error => {
        dispatch({ type: 'STOP_SESSION_LIVE_STREAM_ERROR', payload: session })
        return Promise.reject(error)
      })
  }

reducers['STOP_SESSION_LIVE_STREAM_SUCCESS'] = (state, action) => ({
  ...state,
  data: state.data.map(s => (s.id === action.payload.id ? action.payload : s)),
})

actions.stopSessionLiveStream = (session, isPreview) =>
  (dispatch, getState) => {
    dispatch({ type: 'STOP_SESSION_LIVE_STREAM_START', payload: session })

    return serviceStopLiveStream(currentEventIdSelector(getState()), session.id, session.stream_id, isPreview)
      .then(response => {
        dispatch({ type: 'STOP_SESSION_LIVE_STREAM_SUCCESS', payload: response })
        return response
      })
      .catch(error => {
        dispatch({ type: 'STOP_SESSION_LIVE_STREAM_ERROR', payload: session })
        return Promise.reject(error)
      })
  }

reducers['STOP_SESSION_LIVE_STREAM_SUCCESS'] = (state, action) => ({
  ...state,
  data: state.data.map(s => (s.id === action.payload.id ? action.payload : s)),
})


actions.updateSessionInState = session =>
  (dispatch, getState) => {
    const currentSession = session ? sessionsByIdSelector(getState())[session.id] : undefined

    if (currentSession) {
      dispatch({ type: 'UPDATE_SESSION_IN_STATE', payload: session })
    }

    return Promise.resolve()
  }

reducers['UPDATE_SESSION_IN_STATE'] = (state, action) => ({
  ...state,
  data: state.data.map(s => (s.id === action.payload.id ? action.payload : s)),
})


actions.setSessionStreamPreview = (sessionId, isPreviewing) =>
  (dispatch, getState) => {
    dispatch({ type: 'SET_SESSION_STREAM_PREVIEW_START', payload: { id: sessionId, stream_is_previewing: isPreviewing } })

    return serviceSetSessionStreamPreview(currentEventIdSelector(getState()), sessionId, isPreviewing)
      .then(response => {
        dispatch({ type: 'SET_SESSION_STREAM_PREVIEW_SUCCESS', payload: response })
      })
      .catch(error => {
        dispatch({ type: 'SET_SESSION_STREAM_PREVIEW_ERROR', payload: { id: sessionId, stream_is_previewing: !isPreviewing } })
        return Promise.reject(error)
      })
  }

reducers['SET_SESSION_STREAM_PREVIEW_START'] = (state, action) => ({
  ...state,
  data: state.data.map(s => (s.id === action.payload.id ? { ...s, ...action.payload } : s)),
})

reducers['SET_SESSION_STREAM_PREVIEW_ERROR'] = (state, action) => ({
  ...state,
  data: state.data.map(s => (s.id === action.payload.id ? { ...s, ...action.payload } : s)),
})


export const getSessions = actions.get
export const getSessionsIfNeeded = actions.getIfNeeded
export const getSession = actions.getSession
export const getCurrentSessions = actions.getCurrentSessions
export const getNextSessions = actions.getNextSessions
export const addSession = actions.add
export const updateSession = actions.update
export const deleteSession = actions.delete
export const deleteSessions = actions.deleteSessions
export const getParticipants = actions.getParticipants
export const addParticipant = actions.addParticipant
export const removeParticipant = actions.removeParticipant
export const startSessionLiveStream = actions.startSessionLiveStream
export const stopSessionLiveStream = actions.stopSessionLiveStream
export const setSessionStreamPreview = actions.setSessionStreamPreview
export const updateSessionInState = actions.updateSessionInState
export const initializeSessionLiveStream = actions.initializeSessionLiveStream

const sessions = reducers.index
export default sessions
