import { createSelector } from 'reselect'
import { getProperty } from 'common/utils/componentHelper'
import { readItem, setItem } from 'common/utils/accessToken'

import { infrasByIdSelector } from 'admin/modules/infras'
import { locationsByIdSelector } from 'admin/modules/locations'
import TouchpointsModule from 'admin/modules/touchpoints'
import { mapsSelector } from 'admin/modules/venueMaps'


const initialState = {
  selection: null,
  closestTagId: null,
  testTracking: {
    isEnabled: false,
    pixelName: readItem('trackingPixel'),
  },
  deployementMapId: null,
  isDeploymentMap: false,

  addInfraModal: {
    show: false,
    mapId: null,
    coordinates: [],
  },

  deleteMapItemConfirmationModal: {
    show: false,
    itemId: null,
    itemType: null,
  },

  deployInfraModal: {
    show: false,
    infraType: null,
    mapId: null,
    zoneId: null,
    coordinates: [],
  },

  editInfraModal: {
    show: false,
    infraId: null,
    mapId: null,
  },

  tagTuningModal: {
    show: false,
    mapId: null,
    zoneId: null,
    coordinates: [],
  },

  touchpointCreationModal: {
    show: false,
    coordinates: [],
    mapId: null,
  },

  touchpointListModal: {
    show: false,
    position: null,
    mapId: null,
  },

  touchpointTuningModal: {
    show: false,
    coordinates: [],
    touchpointId: null,
    mapId: null,
  },

  zoneModal: {
    show: false,
    zoneId: false,
    mapId: null,
    coordinates: [],
    tuningBeacons: [],
    tuningTags: [],
  },
}

const getActionKey = (key) => `FloorPlan/${key}`
const isDeploymentMap = (pathname) => /\/admin\/events\/[^/]+\/deployment\/map$/g.test(pathname)


// ////////////////////////////////////////////////////////////////////////////
// Selectors
// ////////////////////////////////////////////////////////////////////////////

const selectState = (state) => state.admin.floorPlan
const selectSelection = (state) => selectState(state).selection

const selectSelectablesByType = (state) => ({
  infra: infrasByIdSelector(state),
  touchpoint: TouchpointsModule.selectors.dataById(state),
  zone: locationsByIdSelector(state),
})

export const addInfraModalSelector = createSelector(selectState, (state) => state.addInfraModal)
export const deleteMapItemConfirmationModalSelector = createSelector(selectState, (state) => state.deleteMapItemConfirmationModal)
export const deployInfraModalSelector = createSelector(selectState, (state) => state.deployInfraModal)
export const editInfraModalSelector = createSelector(selectState, (state) => state.editInfraModal)
export const tagTuningModalSelector = createSelector(selectState, (state) => state.tagTuningModal)
export const touchpointCreationModalSelector = createSelector(selectState, (state) => state.touchpointCreationModal)
export const touchpointListModalSelector = createSelector(selectState, (state) => state.touchpointListModal)
export const touchpointTuningModalSelector = createSelector(selectState, (state) => state.touchpointTuningModal)
export const zoneModalSelector = createSelector(selectState, (state) => state.zoneModal)

export const isDeploymentMapSelector = createSelector(selectState, (state) => state.isDeploymentMap)
export const selectionSelector = createSelector(selectState, (state) => state.selection)
export const deploymentMapIdSelector = createSelector(selectState, mapsSelector, (state, maps) => state.deploymentMapId || getProperty(maps[0], 'id'))
export const testTrackingSelector = createSelector(selectState, (state) => state.testTracking)
export const closestTagSelector = createSelector(selectState, infrasByIdSelector, (state, infras) => infras[state.closestTagId])

const getSelectionByType = (selection, selectables, type) => (getProperty(selection, 'type') === type ? selectables[type][selection.dataId] : null)
export const selectedInfraSelector = createSelector(selectSelection, selectSelectablesByType, (selection, selectables) => getSelectionByType(selection, selectables, 'infra'))
export const selectedTouchpointSelector = createSelector(selectSelection, selectSelectablesByType, (selection, selectables) => getSelectionByType(selection, selectables, 'touchpoint'))
export const selectedZoneSelector = createSelector(selectSelection, selectSelectablesByType, (selection, selectables) => getSelectionByType(selection, selectables, 'zone'))


// ////////////////////////////////////////////////////////////////////////////
// Actions
// ////////////////////////////////////////////////////////////////////////////

export function reset() {
  return (dispatch, getState) => {
    const _isDeploymentMap = isDeploymentMap(getState().router.location.pathname)
    dispatch({ type: getActionKey('RESET'), payload: { isDeploymentMap: _isDeploymentMap } })
  }
}

export function setClosestTag(tagId) {
  return (dispatch) => {
    dispatch({ type: getActionKey('SET_CLOSEST_TAG'), payload: tagId })
  }
}

export function setDeploymentMap(mapId) {
  return (dispatch) => {
    dispatch({ type: getActionKey('SET_DEPLOYMENT_MAP'), payload: mapId })
  }
}

export function setSelection(dataId, type) {
  return (dispatch) => {
    const selection = dataId ? { dataId: dataId, type: type } : null
    dispatch({ type: getActionKey('SET_SELECTION'), payload: selection })
  }
}

export function setTestTracking(isEnabled, pixelName) {
  return (dispatch) => {
    if (pixelName) {
      setItem('trackingPixel', pixelName)
    }

    dispatch({
      type: getActionKey('SET_TEST_TRACKING'),
      payload: {
        isEnabled: isEnabled,
        pixelName: pixelName,
      },
    })
  }
}

const setModal = (dispatch, key, options) => dispatch({ type: getActionKey(key), payload: options })
export const setAddInfraModal = (options) => (dispatch) => setModal(dispatch, 'SET_ADD_INFRA_MODAL', options)
export const setDeleteMapItemConfirmationModal = (options) => (dispatch) => setModal(dispatch, 'SET_DELETE_MAP_ITEM_CONFIRMATION_MODAL', options)
export const setDeployInfraModal = (options) => (dispatch) => setModal(dispatch, 'SET_DEPLOY_INFRA_MODAL', options)
export const setEditInfraModal = (options) => (dispatch) => setModal(dispatch, 'SET_EDIT_INFRA_MODAL', options)
export const setTagTuningModal = (options) => (dispatch) => setModal(dispatch, 'SET_TAG_TUNING_MODAL', options)
export const setTouchpointCreationModal = (options) => (dispatch) => setModal(dispatch, 'SET_TOUCHPOINT_CREATION_MODAL', options)
export const setTouchpointListModal = (options) => (dispatch) => setModal(dispatch, 'SET_TOUCHPOINT_LIST_MODAL', options)
export const setTouchpointTuningModal = (options) => (dispatch) => setModal(dispatch, 'SET_TOUCHPOINT_TUNING_MODAL', options)
export const setZoneModal = (options) => (dispatch) => setModal(dispatch, 'SET_ZONE_MODAL', options)


// ////////////////////////////////////////////////////////////////////////////
// Reducers
// ////////////////////////////////////////////////////////////////////////////

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return { ...state, isDeploymentMap: isDeploymentMap(action.payload.location.pathname) }

    case 'GET_VENUEMAPS_SUCCESS':
      return { ...state, deployementMapId: state.deployementMapId || getProperty(action.payload.data[0], 'id') }


    case getActionKey('RESET'):
      return { ...initialState, isDeploymentMap: action.payload.isDeploymentMap }

    case getActionKey('SET_CLOSEST_TAG'):
      return { ...state, closestTagId: action.payload }

    case getActionKey('SET_DEPLOYMENT_MAP'):
      return { ...state, deploymentMapId: action.payload }

    case getActionKey('SET_SELECTION'):
      return { ...state, selection: action.payload }

    case getActionKey('SET_TEST_TRACKING'):
      return { ...state, testTracking: action.payload }


    case getActionKey('SET_ADD_INFRA_MODAL'):
      return { ...state, addInfraModal: { ...state.addInfraModal, ...action.payload } }

    case getActionKey('SET_DELETE_MAP_ITEM_CONFIRMATION_MODAL'):
      return { ...state, deleteMapItemConfirmationModal: { ...state.deleteMapItemConfirmationModal, ...action.payload } }

    case getActionKey('SET_DEPLOY_INFRA_MODAL'):
      return { ...state, deployInfraModal: { ...state.deployInfraModal, ...action.payload } }

    case getActionKey('SET_EDIT_INFRA_MODAL'):
      return { ...state, editInfraModal: { ...state.editInfraModal, ...action.payload } }

    case getActionKey('SET_TAG_TUNING_MODAL'):
      return { ...state, tagTuningModal: { ...state.tagTuningModal, ...action.payload } }

    case getActionKey('SET_TOUCHPOINT_CREATION_MODAL'):
      return { ...state, touchpointCreationModal: { ...state.touchpointCreationModal, ...action.payload } }

    case getActionKey('SET_TOUCHPOINT_LIST_MODAL'):
      return { ...state, touchpointListModal: { ...state.tagTuningModal, ...action.payload } }

    case getActionKey('SET_TOUCHPOINT_TUNING_MODAL'):
      return { ...state, touchpointTuningModal: { ...state.touchpointTuningModal, ...action.payload } }

    case getActionKey('SET_ZONE_MODAL'):
      return { ...state, zoneModal: { ...state.zoneModal, ...action.payload } }

    default:
      return state
  }
}
