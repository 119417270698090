import { generateDataModule } from './generateModule'
import { serviceGetAdminAccounts, serviceCreateAdminAccount, serviceUpdateAdminAccount, serviceAddPermission, serviceDeleteAdminAccount, serviceDeletePermission } from 'admin/services/users'
import { showToast } from 'common/modules/toaster'
import { setAccountMergeModal } from 'admin/modules/app'
import sort from 'common/utils/sortHelper'
import i18n from 'i18next'


const AccountManagementModule = generateDataModule({
  moduleKey: 'cpo/accountManagement',
  reducerKey: 'admin.accountManagement',

  services: {
    delete: { service: serviceDeleteAdminAccount },
    get: { service: serviceGetAdminAccounts },
    post: { service: serviceCreateAdminAccount },
    put: { service: serviceUpdateAdminAccount },
  },
})

AccountManagementModule.registerSelector(
  'sortedAccounts',
  (state, moduleState) => moduleState.data,
  accounts => sort(accounts, ['first_name', 'last_name', 'email', 'id'])
)


AccountManagementModule.actions.get = (query) => dispatch => {
  dispatch({ type: AccountManagementModule.actionKeys.getStart })

  return serviceGetAdminAccounts(AccountManagementModule._getEventId(), { query })
    .then(response => {
      dispatch({ type: AccountManagementModule.actionKeys.getSuccess, payload: response })
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({ type: AccountManagementModule.actionKeys.getError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('account_management.get.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject(error)
    })
}


AccountManagementModule.actions.put = (account, params) => dispatch => {
  dispatch({ type: AccountManagementModule.actionKeys.putStart })

  return serviceUpdateAdminAccount(AccountManagementModule._getEventId(), account, { allow_merge: false, ...params })
    .then(response => {
      dispatch({ type: AccountManagementModule.actionKeys.putSuccess, payload: response })
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({ type: AccountManagementModule.actionKeys.putError })

      if (error.can_merge) {
        dispatch(setAccountMergeModal({ isVisible: true, account: account, isAttendeeUpdate: false }))
      } else {
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('account_management.update.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }

      return Promise.reject(error)
    })
}


AccountManagementModule.actions.post = account => dispatch => {
  dispatch({ type: AccountManagementModule.actionKeys.postStart })

  return serviceCreateAdminAccount(AccountManagementModule._getEventId(), account)
    .then(response => {
      dispatch({ type: AccountManagementModule.actionKeys.postSuccess, payload: response })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('account_management.create.title'),
        message: i18n.getFixedT(i18n.language, 'admin_customModule')('account_management.create.success', { name: `${account.first_name} ${account.last_name}` }),
        level: 'success',
        permanent: false,
      }))
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({ type: AccountManagementModule.actionKeys.postError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('account_management.create.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject(error)
    })
}


AccountManagementModule.actions.delete = account => dispatch => {
  dispatch({ type: AccountManagementModule.actionKeys.deleteStart })

  return serviceDeleteAdminAccount(AccountManagementModule._getEventId(), account.id)
    .then(() => {
      dispatch({ type: AccountManagementModule.actionKeys.deleteSuccess, payload: account.id })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('account_management.delete.title'),
        message: i18n.getFixedT(i18n.language, 'admin_customModule')('account_management.delete.success', { name: `${account.first_name} ${account.last_name}` }),
        level: 'success',
        permanent: false,
      }))
      return Promise.resolve(account.id)
    })
    .catch(error => {
      dispatch({ type: AccountManagementModule.actionKeys.deleteError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('account_management.delete.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject(error)
    })
}


AccountManagementModule.registerDataAction('addPermission', data => dispatch => {
  dispatch({ type: AccountManagementModule.actionKeys.addPermissionStart })

  return serviceAddPermission(data.eventId || AccountManagementModule._getEventId(), data)
    .then(response => {
      dispatch({ type: AccountManagementModule.actionKeys.addPermissionSuccess, payload: response })
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({ type: AccountManagementModule.actionKeys.addPermissionError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('account_management.permission.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject(error)
    })
})


AccountManagementModule.registerDataAction('deletePermission', (eventId, account) => dispatch => {
  dispatch({ type: AccountManagementModule.actionKeys.deletePermissionStart })

  return serviceDeletePermission(eventId, account.id)
    .then(response => {
      dispatch({ type: AccountManagementModule.actionKeys.deletePermissionSuccess, payload: response })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('account_management.delete.title'),
        message: i18n.getFixedT(i18n.language, 'admin_customModule')('account_management.delete.success', { name: `${account.first_name} ${account.last_name}` }),
        level: 'success',
        permanent: false,
      }))
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({ type: AccountManagementModule.actionKeys.deletePermissionError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('account_management.delete.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject(error)
    })
})


export default AccountManagementModule
