import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceGetContacts(eventId, success, error) {
  return axios.get(`/events/${eventId}/attendees/me/contacts`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateContact(eventId, accountId, data, success, error) {
  return axios.put(`/events/${eventId}/attendees/me/contacts/${accountId}`, data, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeleteContact(eventId, contactId, success, error) {
  return axios.delete(`/events/${eventId}/attendees/me/contacts/${contactId}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
