import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetZoneDailyScores } from 'admin/services/bluetrace'

import { eventTimezoneSelector } from 'admin/modules/events'


const DailyScoresModule = generateDataModule({
  initialData: {
    previousWeek: [],
    currentWeek: [],
  },
  moduleKey: 'cpo/bluetrace/employeeDailyScores',
  reducerKey: 'admin.bluetrace.employeeContagion.dailyScores',
  refreshTime: 10000,
  services: {
    get: { service: serviceGetZoneDailyScores },
  },
})


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////

DailyScoresModule.registerDataAction('get', zoneId => (dispatch, getState) => {
  const state = getState()
  if (!zoneId) return Promise.resolve()

  const timezone = eventTimezoneSelector(state)

  dispatch({ type: DailyScoresModule.actionKeys.getStart })

  return DailyScoresModule._getService('get')(zoneId, timezone)
    .then(response => dispatch({ type: DailyScoresModule.actionKeys.getSuccess, payload: response }))
    .catch(error => {
      dispatch({ type: DailyScoresModule.actionKeys.getError })
      return Promise.reject(error)
    })
})


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////


export default DailyScoresModule
