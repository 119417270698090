import { BaseModule as ReduxBaseModule, DataModule } from 'redux-data-module'
import BaseModule from 'common/modules/BaseModule'
import i18n from 'i18next'
import { getProperty } from 'common/utils/componentHelper'
import { currentEventIdSelector } from './events'
import { addPaginationSupport, addSearchSupport, addSortingSupport } from './Dashboard/helper'
import { store } from '../../index'


function generateEventServices(services) {
  const newServices = {}

  Object.keys(services).forEach(key => {
    const newService = {
      ...services[key],
      service: (getState, ...args) => services[key].service(currentEventIdSelector(getState()), ...args),
    }

    newServices[key] = { ...newService }
  })

  return newServices
}

export default function generateModule(options) {
  const adminModule = new BaseModule({
    ...options,
    app: 'admin',
    reducerKey: `admin.${options.reducerKey || options.moduleKey}`,
    services: generateEventServices(options.services),
    t: (key, extra) => i18n.getFixedT(i18n.language, 'admin_customModule')(`${options.moduleKey}.${key}`, extra),
  })

  adminModule.reducers['CHANGE_EVENT_SUCCESS'] = state => ({
    ...state,
    didInvalidate: true,
  })

  return adminModule
}


export function invalidateOnEventChange(reduxModule) {
  reduxModule.reducers['CHANGE_EVENT_SUCCESS'] = state => ({ ...state, lastUpdated: 0 }) // eslint-disable-line no-param-reassign
}

export function generateBaseModule(options) {
  const adminModule = new ReduxBaseModule({ ...options })
  adminModule._getEventId = () => currentEventIdSelector(store.getState())
  return adminModule
}

export function generateDataModule(options) {
  const adminModule = new DataModule({ ...options })
  adminModule._getEventId = () => currentEventIdSelector(store.getState())
  adminModule._getService = (type) => getProperty(adminModule.config, `services.${type}.service`)

  Object.keys(adminModule.config.services).forEach(key => {
    const serviceFunction = adminModule.config.services[key].service
    adminModule.config.services[key].service = (...args) => serviceFunction(adminModule._getEventId(), ...args)
  })

  return adminModule
}

export function generateTableModule(options) {
  const moduleOptions = { ...options.module }

  const getInitialStateData = key => getProperty(moduleOptions, `initialState.${key}`)
  moduleOptions.initialState = {
    ...moduleOptions.initialState,
    columnsVisibility: {
      ...getInitialStateData('columnsVisibility'),
    },
    pagination: {
      page: 1,
      pageCount: 1,
      per_page: 50,
      ...getInitialStateData('pagination'),
    },
    query: {
      ...getInitialStateData('query'),
    },
  }

  const tableModule = generateDataModule(moduleOptions)
  addSearchSupport(tableModule)
  addPaginationSupport(tableModule)
  addSortingSupport(tableModule, options.initialSort || { id: 'id', order: 1 })
  invalidateOnEventChange(tableModule)

  tableModule.registerSelector('columnsVisibility', (state, moduleState) => moduleState.columnsVisibility, visibility => visibility)
  tableModule.registerSelector(
    'queryParams',
    [
      (state, moduleState) => moduleState.query,
      state => tableModule.selectors.pagination(state),
      state => tableModule.selectors.search(state),
      state => tableModule.selectors.sorter(state),
    ],
    (query, pagination, searchTerm, sorter) => ({
      ...query,
      page: pagination.page,
      per_page: pagination.per_page,
      query: searchTerm,
      sort_column: sorter.id,
      sort_order: sorter.order,
    })
  )

  tableModule.registerAction('get', () => (dispatch, getState) => {
    const queryParams = tableModule.selectors.query(getState())

    dispatch({ type: tableModule.actionKeys.getStart })

    return tableModule._getService('get')(queryParams)
      .then(response => dispatch({ type: tableModule.actionKeys.getSuccess, payload: response }))
      .catch(error => {
        dispatch({ type: tableModule.actionKeys.getError })
        return Promise.reject(error)
      })
  })
  tableModule.registerReducer(
    tableModule.actionKeys.getSuccess,
    (state, action) => ({
      ...state,
      data: action.payload.data,
      pagination: {
        ...state.pagination,
        ...action.payload.pagination,
      },
    })
  )

  tableModule.registerAction('setVisibility', (columnName, isVisible) => dispatch => {
    dispatch({ type: tableModule.actionKeys.setVisibility, payload: { columnName, isVisible: !!isVisible } })
    return Promise.resolve()
  })
  tableModule.registerReducer(
    tableModule.actionKeys.setVisibility,
    (state, action) => ({ ...state, columnsVisibility: { ...state.columnsVisibility, [action.payload.columnName]: action.payload.isVisible } })
  )

  const fetchDataOnQueryChangesIfNeeded = customCheck => {
    const isFetchNeeded = customCheck != null ? customCheck : options.needsDataRefreshOnQueryChanges
    return isFetchNeeded ? store.dispatch(tableModule.actions.get()) : undefined
  }

  tableModule.helpers = {
    changePage: (...args) => store.dispatch(tableModule.actions.changePage(...args))
      .then(() => fetchDataOnQueryChangesIfNeeded()),

    resetTable: shouldRefreshData => Promise.all([
      tableModule.helpers.changePage(tableModule.initialState.pagination.page),
      tableModule.helpers.setSearch(tableModule.initialState.search),
      tableModule.helpers.setSorter(tableModule.initialState.sorter.id, tableModule.initialState.sorter.order),
    ]).then(() => fetchDataOnQueryChangesIfNeeded(shouldRefreshData)),

    setSearch: (...args) => Promise.all([
      store.dispatch(tableModule.actions.setSearch(...args)),
      tableModule.helpers.changePage(1),
    ]).then(() => fetchDataOnQueryChangesIfNeeded()),

    setSorter: (...args) => store.dispatch(tableModule.actions.setSorter(...args))
      .then(() => fetchDataOnQueryChangesIfNeeded()),

    setColumnVisibility: (...args) => store.dispatch(tableModule.actions.setVisibility(...args)),
  }

  return tableModule
}
