import axios from 'axios'
import moment from 'moment'
import { apiErrorHandler, apiCatchError, buildError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { getAxiosConfig } from './helper'


function translateSessionCustomFields(customFields) {
  const translatedFields = {}
  Object.keys(customFields || {}).forEach(key => { translatedFields[key] = getTranslatedObject(customFields[key]) })
  return translatedFields
}

export function translateSession(session) {
  const baseSession = {
    ...session,
    title: getTranslatedObject(session.title),
    description: getTranslatedObject(session.description),
    call_to_actions: (session.call_to_actions || []).map(cta => ({ ...cta, label: getTranslatedObject(cta.label) })),
    tags: (session.tags || []).map(tag => ({ ...tag, name: getTranslatedObject(tag.name) })),
    speakers: (session.speakers || []).map(speaker => ({ ...speaker, role: getTranslatedObject(speaker.role) })),
    custom_fields: translateSessionCustomFields(session.custom_fields),
  }

  if (session.location) {
    baseSession.location = {
      ...session.location,
      display_name: getTranslatedObject(session.location.display_name),
    }
  }

  return baseSession
}

export function serviceGetSession(eventId, sessionId, success, error) {
  return axios.get(`/events/${eventId}/sessions/${sessionId}`, getAxiosConfig())
    .then((response) => Promise.resolve(translateSession(response.data)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetSessions(eventId, calendarId, params, success, error) {
  let queryParams = ''
  Object.keys(params).forEach(key => {
    if (params[key]) queryParams += `&${key}=${params[key]}`
  })

  const url = calendarId
    ? `/events/${eventId}/calendars/${calendarId}/sessions?${queryParams}`
    : `/events/${eventId}/sessions`

  return axios.get(url, getAxiosConfig())
    .then((response) => Promise.resolve(response.data.map(translateSession)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetCurrentSessions(eventId, success, error) {
  return axios.get(`/events/${eventId}/sessions?when=${moment().unix()}`, getAxiosConfig())
    .then((response) => Promise.resolve(response.data.map(translateSession)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetNextSessions(eventId, success, error) {
  return axios.get(`/events/${eventId}/sessions?start_date=${moment().unix()}&end_date=${moment().add(4, 'hours').unix()}`, getAxiosConfig())
    .then((response) => Promise.resolve(response.data.map(translateSession)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeleteSessions(eventId, calendarId, success, error) {
  return axios.delete(`/events/${eventId}/calendars/${calendarId}/sessions`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceAddSession(eventId, session, success, error) {
  return axios.post(`/events/${eventId}/sessions`, JSON.stringify(session), getAxiosConfig())
    .then((response) => Promise.resolve(translateSession(response.data)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateSession(eventId, session, success, error) {
  const id = session.id
  delete session.id // eslint-disable-line no-param-reassign

  return axios.put(`/events/${eventId}/sessions/${id}`, JSON.stringify(session), getAxiosConfig())
    .then((response) => {
      session.id = id // eslint-disable-line no-param-reassign
      return Promise.resolve(translateSession(response.data))
    })
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeleteSession(eventId, sessionId, success, error) {
  return axios.delete(`/events/${eventId}/sessions/${sessionId}`, getAxiosConfig())
    .then((response) => success(sessionId, response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetParticipantsForSession(eventId, calendarId, sessionId, relation, query, success, error) {
  return axios.get(`/events/${eventId}/calendars/${calendarId}/sessions/${sessionId}/attendees/${relation}?query=${query}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceAddParticipantToSession(eventId, attendeeId, sessionId, success, error) {
  return axios.put(`/events/${eventId}/attendees/${attendeeId}/sessions/${sessionId}/going?skip_capacity_check=true`, {}, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceRemoveParticipantFromSession(eventId, attendeeId, sessionId, success, error) {
  return axios.delete(`/events/${eventId}/attendees/${attendeeId}/sessions/${sessionId}/going`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetCalendars(eventId, success, error) {
  return axios.get(`/events/${eventId}/calendars`, getAxiosConfig())
    .then((response) => {
      const data = response.data.slice().map(calendar => ({
        ...calendar,
        name: getTranslatedObject(calendar.name),
        description: getTranslatedObject(calendar.description),
      }))

      return Promise.resolve(data)
    })
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function servicePostCalendar(eventId, calendar, success, error) {
  return axios.post(`/events/${eventId}/calendars`, calendar, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function servicePutCalendar(eventId, calendar, success, error) {
  return axios.put(`/events/${eventId}/calendars/${calendar.id}`, calendar, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeleteCalendar(eventId, calendarId, success, error) {
  return axios.delete(`/events/${eventId}/calendars/${calendarId}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}


export function serviceInitializeLiveStream(eventId, sessionId) {
  return axios.put(`/events/${eventId}/sessions/${sessionId}/stream`, { status: 'ready' }, getAxiosConfig())
    .then(response => translateSession(response.data))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceStartLiveStream(eventId, sessionId) {
  return axios.put(`/events/${eventId}/sessions/${sessionId}`, { id: sessionId, stream_is_public: true }, getAxiosConfig())
    .then(response => translateSession(response.data))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceStopLiveStream(eventId, sessionId) {
  return axios.put(`/events/${eventId}/sessions/${sessionId}/stream`, { status: 'done' }, getAxiosConfig())
    .then(response => translateSession(response.data))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceSetSessionStreamPreview(eventId, sessionId, isPreviewing) {
  return axios.put(`/events/${eventId}/sessions/${sessionId}`, { id: sessionId, stream_is_previewing: isPreviewing }, getAxiosConfig())
    .then(response => translateSession(response.data))
    .catch(error => Promise.reject(buildError(error)))
}
