import { serviceGetProfile, serviceGetProfileAdmin, serviceDeleteCredential, serviceUpdateProfile, serviceUpdateProfilePicture, serviceAddSocialNetwork, serviceRemoveSocialNetwork, serviceGetNetworkingPreviewProfile } from 'attendee/services/profile'
import { showToast } from 'common/modules/toaster'
import { retrieveCurrentUser } from 'common/modules/auth'
import { handleErrorMessage } from './index'
import { browserHistoryPush } from 'common/components/Link'
import generateModule from 'common/utils/generateModule'
import { getProperty } from 'common/utils/componentHelper'
import i18n from 'i18next'
import { createSelector } from 'reselect'
import { eventIdSelector } from './events'
import ReactGA from 'react-ga'

export const selectState = state => state.attendee.attProfile
export const selectProfile = state => state.attendee.attProfile.data

export const stateSelector = createSelector(selectState, state => state)
export const profileSelector = createSelector(selectProfile, profile => profile)
export const accountIdSelector = createSelector(selectProfile, profile => profile.id)
export const hasOptedInDirectorySelector = createSelector(selectProfile, profile => profile.directory_opt_in)
export const fieldVisibilitySelector = createSelector(selectProfile, profile => profile.field_visibility)
export const customFieldsSelector = createSelector(selectProfile, profile => profile.custom_fields)
export const isModifyingPrivacySettingsSelector = createSelector(selectState, state => Object.keys(state.privacySettingsRequests).length > 0)
export const attendeePreferredLanguageSelector = createSelector(selectProfile, profile => getProperty(profile, 'language'))
export const isRepresentativeSelector = createSelector(selectProfile, profile => !!getProperty(profile, 'representative.accepted'))
export const representativeExhibitorIdSelector = createSelector(selectProfile, isRepresentativeSelector, (profile, isRepresentative) => isRepresentative && getProperty(profile, 'representative.exhibitor_id'))
export const networkingProfileSelector = createSelector(selectState, state => state.networkingProfile)


const attProfile = generateModule({
  itemName: 'attProfile',
  itemNameDisplay: 'profile',
  autoRefresh: 60,
  dataIsNotArray: true,
  services: {
    get: serviceGetProfile,
    upd: serviceUpdateProfile,
  },
  app: 'attendee',
  initialState: {
    isFetching: false,
    isModifying: false,
    isUploadingProfilePicture: false,
    isAddingSocialNetwork: '',
    isError: false,
    isLoaded: false,
    didInvalidate: true,
    lastUpdated: 0,
    lastOperationStatus: '',
    data: {},
    notifiedUserIsUnpaired: false,
    triedSettingVisitedWebsite: false,
    privacySettingsRequests: {},
    networkingProfile: undefined,
  },
})


attProfile.actions.get = () =>
  (dispatch, getState) => {
    dispatch(attProfile.actions.getStart('me'))
    return serviceGetProfile(
      getState().attendee.attEvents.currEvent.id,
      (result) => {
        ReactGA.set({ dimension5: result.id })
        dispatch(attProfile.actions.getSuccess(result))
        return Promise.resolve()
      },
      (error) =>
        serviceGetProfileAdmin(
          (result) => dispatch(attProfile.actions.getSuccess(result)),
          () => dispatch(attProfile.actions.getError(error.description))
        )
    )
  }

// TODO: Standardize
attProfile.actions.update = (newProfile, redirect = false, showToaster = false, refetch = true, optimistic = true) =>
  (dispatch, getState) => {
    const eventId = getState().attendee.attEvents.currEvent.id

    if (newProfile.visited_website) {
      dispatch({ type: 'ATTENDEE_VISITED_WEBSITE' })
    }

    dispatch({ type: 'UPDATE_ATTPROFILE_START', payload: { data: newProfile, optimistic } })
    return serviceUpdateProfile(
      eventId,
      newProfile,
      (prof) => {
        const message = i18n.getFixedT(i18n.language, 'attendee_customModule')('profile.update.success')
        dispatch({ type: 'UPDATE_ATTPROFILE_SUCCESS', payload: { data: prof, optimistic } })
        if (refetch) {
          dispatch(attProfile.actions.get(false))
        }

        if (redirect) {
          browserHistoryPush(`/events/${eventId}`)
        }

        if (showToaster) {
          dispatch(showToast({
            title: i18n.getFixedT(i18n.language, 'attendee_customModule')('profile.update.title'),
            message: message,
            level: 'success',
            permanent: false,
          }))
        }
      },
      (error) => {
        dispatch(attProfile.actions.updateError(error.description))
        dispatch(attProfile.actions.get(newProfile.id))
        if (showToaster) {
          handleErrorMessage(dispatch, getState, error)
        }
        return Promise.reject('error')
      }
    )
  }

attProfile.reducers['UPDATE_ATTPROFILE_START'] = (state, action) => ({
  ...state,
  isModifying: true,
  didInvalidate: false,
  lastOperationStatus: action.payload.status,
  data: action.payload.optimistic ? { ...state.data, ...action.payload.data } : { ...state.data },
})

attProfile.reducers['UPDATE_ATTPROFILE_SUCCESS'] = (state, action) => ({
  ...state,
  isModifying: false,
  didInvalidate: true,
  lastOperationStatus: action.payload.status,
  data: action.payload.optimistic ? { ...state.data } : { ...state.data, ...action.payload.data },
})


attProfile.actions.setVisitedWebsite = () =>
  (dispatch) => {
    dispatch({ type: 'ATTENDEE_VISITED_WEBSITE' })
    dispatch(attProfile.actions.update({ id: 'me', visited_website: true }))
    return Promise.resolve()
  }

attProfile.reducers['ATTENDEE_VISITED_WEBSITE'] = (state) => ({
  ...state,
  triedSettingVisitedWebsite: true,
})


function uploadProfilePictureStart() {
  return {
    type: 'UPLOAD_ATT_PROFILE_PICTURE_START',
  }
}

function uploadProfilePictureSuccess() {
  return {
    type: 'UPLOAD_ATT_PROFILE_PICTURE_SUCCESS',
  }
}

function uploadProfilePictureError() {
  return {
    type: 'UPLOAD_ATT_PROFILE_PICTURE_ERROR',
  }
}

attProfile.reducers['UPLOAD_ATT_PROFILE_PICTURE_START'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  didInvalidate: false,
  isUploadingProfilePicture: true,
})

attProfile.reducers['UPLOAD_ATT_PROFILE_PICTURE_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  didInvalidate: false,
  isUploadingProfilePicture: false,
})

attProfile.reducers['UPLOAD_ATT_PROFILE_PICTURE_ERROR'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  didInvalidate: false,
  isUploadingProfilePicture: false,
})


attProfile.actions.updateProfilePicture = (imgFile, updateProfile = true) =>
  (dispatch, getState) => {
    dispatch(uploadProfilePictureStart())
    return serviceUpdateProfilePicture(
      imgFile,
      (response) => {
        if (updateProfile) dispatch(attProfile.actions.update({ id: getState().attendee.attProfile.data.id, image: response }, false, false))
        dispatch(uploadProfilePictureSuccess())
        return Promise.resolve(response)
      },
      (error) => {
        dispatch(uploadProfilePictureError())
        handleErrorMessage(dispatch, getState, error)
      }
    )
  }


function addSocialNetworkStart(network) {
  return {
    type: 'ADD_SOCIAL_NETWORK_START',
    data: {
      network: network,
    },
  }
}

function addSocialNetworkSuccess() {
  return {
    type: 'ADD_SOCIAL_NETWORK_SUCCESS',
  }
}

function addSocialNetworkError() {
  return {
    type: 'ADD_SOCIAL_NETWORK_ERROR',
  }
}

attProfile.reducers['ADD_SOCIAL_NETWORK_START'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  didInvalidate: false,
  isAddingSocialNetwork: action.data.network,
})

attProfile.reducers['ADD_SOCIAL_NETWORK_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  didInvalidate: false,
  isAddingSocialNetwork: '',
})

attProfile.reducers['ADD_SOCIAL_NETWORK_ERROR'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  didInvalidate: false,
  isAddingSocialNetwork: '',
})

attProfile.actions.addSocialNetwork = (authCode, codeType, redirectUri, importProfile = false) =>
  (dispatch, getState) => {
    dispatch(addSocialNetworkStart(codeType))

    return serviceAddSocialNetwork(
      getState().attendee.attEvents.currEvent.id,
      authCode,
      codeType,
      redirectUri,
      importProfile,
      () => {
        dispatch(addSocialNetworkSuccess())
        dispatch(attProfile.actions.get('me'))
        return dispatch(retrieveCurrentUser())
      },
      (error) => {
        dispatch(addSocialNetworkError())
        handleErrorMessage(dispatch, getState, error)
      }
    )
  }

attProfile.actions.removeSocialNetwork = (socialName) =>
  (dispatch, getState) => serviceRemoveSocialNetwork(
    getState().attendee.attEvents.currEvent.id,
    socialName,
    () => {
      dispatch({ type: 'REMOVE_SOCIAL_NETWORK_SUCCESS' })
      dispatch(attProfile.actions.get('me'))
    },
    (error) => {
      dispatch({ type: 'REMOVE_SOCIAL_NETWORK_ERROR' })
      handleErrorMessage(dispatch, getState, error)
    }
  )

attProfile.reducers['REMOVE_SOCIAL_NETWORK_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  didInvalidate: false,
})

attProfile.reducers['REMOVE_SOCIAL_NETWORK_ERROR'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  didInvalidate: true,
})


attProfile.actions.clearProfile = () =>
  ({ type: 'CLEAR_PROFILE_DATA' })


attProfile.reducers['CLEAR_PROFILE_DATA'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isFetching: false,
  isError: false,
  isLoaded: false,
  data: {},
})


attProfile.actions.getNetworkingPreviewProfile = () =>
  (dispatch, getState) => serviceGetNetworkingPreviewProfile(eventIdSelector(getState()))
    .then(response => dispatch({
      type: 'GET_NETWORKING_PREVIEW_PROFILE',
      payload: response,
    }))

attProfile.reducers['GET_NETWORKING_PREVIEW_PROFILE'] = (state, action) => ({
  ...state,
  networkingProfile: action.payload,
})


attProfile.actions.deleteCredential = (credentialId, type = 'email') =>
  (dispatch, getState) => serviceDeleteCredential(
    getState().attendee.attEvents.currEvent.id,
    `${type}:${encodeURIComponent(credentialId)}`,
    () => {
      dispatch({ type: 'ATT_DELETE_ATTENDEE_CREDENTIAL', data: { credentialId } })

      if (type !== 'email') {
        dispatch(attProfile.actions.removeSocialNetwork(type))
      }

      dispatch(retrieveCurrentUser())
    },
    (error) => dispatch(showToast({
      title: '',
      message: error.description,
      level: 'error',
      permanent: false,
    }))
  )

// attProfile.reducers['ATT_DELETE_ATTENDEE_CREDENTIAL'] = (state, action) => ({ // eslint-disable-line dot-notation
//   ...state,
//   data: {
//     ...state.data,
//     credentials: state.data.credentials.filter(cred => cred.id !== action.data.credentialId),
//   },
// })


const modifyPrivacySettingsRequest = (requests, requestTime, isAdding) => {
  const _requests = { ...requests }
  if (isAdding) _requests[requestTime] = true
  else delete _requests[requestTime]
  return _requests
}

attProfile.actions.updatePrivacySettings = (privacySettings) =>
  (dispatch, getState) => {
    const requestTime = Date.now()
    dispatch({ type: 'ATTENDEE_PROFILE_UPDATE_PRIVACY_SETTINGS_START', requestTime, payload: privacySettings })
    return serviceUpdateProfile(
      eventIdSelector(getState()),
      { field_visibility: privacySettings, id: 'me' },
      () => dispatch({ type: 'ATTENDEE_PROFILE_UPDATE_PRIVACY_SETTINGS_SUCCESS', requestTime, payload: privacySettings }),
      () => dispatch({ type: 'ATTENDEE_PROFILE_UPDATE_PRIVACY_SETTINGS_ERROR', requestTime, payload: privacySettings })
    )
  }

attProfile.reducers['ATTENDEE_PROFILE_UPDATE_PRIVACY_SETTINGS_START'] = (state, action) => ({
  ...state,
  privacySettingsRequests: modifyPrivacySettingsRequest(state.privacySettingsRequests, action.requestTime, true),
})

attProfile.reducers['ATTENDEE_PROFILE_UPDATE_PRIVACY_SETTINGS_SUCCESS'] = (state, action) => ({
  ...state,
  privacySettingsRequests: modifyPrivacySettingsRequest(state.privacySettingsRequests, action.requestTime, false),
  data: {
    ...state.data,
    field_visibility: {
      ...state.data.field_visibility,
      ...action.payload,
      custom_fields: {
        ...state.data.field_visibility.custom_fields,
        ...action.payload.custom_fields,
      },
      social_networks: {
        ...state.data.field_visibility.social_networks,
        ...action.payload.social_networks,
      },
    },
  },
})

attProfile.reducers['ATTENDEE_PROFILE_UPDATE_PRIVACY_SETTINGS_ERROR'] = (state, action) => ({
  ...state,
  privacySettingsRequests: modifyPrivacySettingsRequest(state.privacySettingsRequests, action.requestTime, false),
})


attProfile.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isLoaded: false,
  isError: false,
  didInvalidate: true,
  data: {},
})


attProfile.actions.getProfile = attProfile.actions.get
attProfile.actions.updateProfile = attProfile.actions.update
attProfile.actions.deleteCredential = attProfile.actions.deleteCredential
attProfile.actions.setVisitedWebstite = attProfile.actions.setVisitedWebsite

export default attProfile
