import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetNetworkGraph } from 'admin/services/bluetrace'

import FiltersModule from './filters'


const NetworkGraphModule = generateDataModule({
  initialData: {
    accounts: {},
    links: [],
    nodes: [],
    relations: {},
  },
  moduleKey: 'cpo/bluetrace/networkGraph',
  reducerKey: 'admin.bluetrace.employeeNetwork.networkGraph',
  refreshTime: 10000,
  services: {
    get: { service: serviceGetNetworkGraph },
  },
})


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

NetworkGraphModule.registerSelector(
  'links',
  (state, moduleState) => moduleState.data.links,
  data => data
)

NetworkGraphModule.registerSelector(
  'nodes',
  (state, moduleState) => moduleState.data.nodes,
  nodes => nodes
)

NetworkGraphModule.registerSelector(
  'relationsByAccountId',
  (state, moduleState) => moduleState.data.relations,
  data => data
)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////

NetworkGraphModule.registerDataAction('get', () => (dispatch, getState) => {
  const state = getState()
  const employeeId = FiltersModule.selectors.employeeId(state)
  if (!employeeId) return Promise.resolve()

  const graphFilters = FiltersModule.selectors.graph(state)

  const params = {
    start_date: graphFilters.start_date,
    end_date: graphFilters.end_date,
    min_duration: graphFilters.min_duration,
    order: 2,
  }

  dispatch({ type: NetworkGraphModule.actionKeys.getStart })

  return NetworkGraphModule._getService('get')(employeeId, params)
    .then(response => dispatch({ type: NetworkGraphModule.actionKeys.getSuccess, payload: response }))
    .catch(error => {
      dispatch({ type: NetworkGraphModule.actionKeys.getError })
      return Promise.reject(error)
    })
})


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////


export default NetworkGraphModule
