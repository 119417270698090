import { generateDataModule } from './generate'
import { serviceGetEvents } from 'exhibitor/services/events'
import { getProperty } from 'common/utils/componentHelper'
import { getTranslatedValue } from 'common/utils/translator'
import { store } from '../../index'


const EventsModule = generateDataModule({
  moduleKey: 'cpe/events',
  reducerKey: 'events',
  initialData: {},

  services: {
    get: { service: serviceGetEvents },
  },
})

EventsModule.helpers = {
  getEventTitle: (exhibitor) => {
    const eventId = getProperty(exhibitor, 'event_id')
    if (!eventId) {
      console.error('The exhibitor needs an \'event_id\' param')
      return ''
    }

    const eventsById = EventsModule.selectors.data(store.getState())
    const event = eventsById[eventId]
    if (!event) {
      console.error(`No events found for id '${eventId}'`)
      return ''
    }

    return getTranslatedValue(event.title) || event.id
  },
}

export default EventsModule
