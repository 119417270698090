import { createSelector } from 'reselect'

const selectState = (state) => state.app
export const isNativeAppSelector = createSelector(selectState, state => state.isNativeApp)
export const platformSelector = createSelector(selectState, state => state.nativeAppPlatform)
export const isWebviewSelector = createSelector(selectState, state => state.isWebview)


export function setAsWebview(isWebview) {
  return dispatch => {
    dispatch({ type: 'SET_AS_WEBVIEW', data: { isWebview } })
    return Promise.resolve()
  }
}

export function setAsNativeApp(isNativeApp) {
  return dispatch => {
    dispatch({ type: 'SET_AS_NATIVE_APP', data: { isNativeApp } })
    return Promise.resolve()
  }
}

export function setExternalFrame(params) {
  return dispatch => {
    dispatch({ type: 'SET_EXTERNAL_FRAME', data: { externalFrame: params } })
    return Promise.resolve()
  }
}

export function setNativeAppPlatform(platform) {
  return dispatch => {
    dispatch({ type: 'SET_NATIVE_APP_PLATFORM', data: { platform } })
    return Promise.resolve()
  }
}


const initialState = {
  isNativeApp: false,
  isWebview: false,
  nativeAppPlatform: undefined,

  externalFrame: {
    isVisible: false,
    link: 'https://klik.co',
  },
}

export default function app(state = initialState, action) {
  switch (action.type) {
    case 'SET_AS_NATIVE_APP':
      return { ...state, isNativeApp: action.data.isNativeApp }

    case 'SET_AS_WEBVIEW':
      return { ...state, isWebview: action.data.isWebview }

    case 'SET_EXTERNAL_FRAME':
      return { ...state, externalFrame: { ...state.externalFrame, ...action.data.externalFrame } }

    case 'SET_NATIVE_APP_PLATFORM':
      return { ...state, nativeAppPlatform: action.data.platform }

    case 'LOGOUT':
      return { ...state, externalFrame: initialState.externalFrame }

    default:
      return state
  }
}
