import { serviceGetMenu } from 'attendee/services/menu'
import generateModule from 'common/utils/generateModule'
import { createSelector } from 'reselect'
import { eventIdSelector, eventFeaturesSelector } from 'attendee/modules/events'
import { isFeatureEnabled } from 'attendee/utils/decorators/withFeatureAccess'
import { getURLfromCTA, isKlikURL } from 'common/utils/links'
import { getProperty } from 'common/utils/componentHelper'


const selectMenuItems = state => state.attendee.menus.data

export const menuItemsSelector = createSelector(
  selectMenuItems,
  eventIdSelector,
  eventFeaturesSelector,
  (menuItems, eventId, eventFeatures) => {
    let validMenuItems = menuItems.map(menuItem => ({
      label: menuItem.call_to_action.label,
      path: getURLfromCTA(menuItem.call_to_action),
      icon: menuItem.icon,
      callToAction: menuItem.call_to_action,
      isKlikUrl: isKlikURL(menuItem.call_to_action.url),
    }))

    const isNetworkingEnabled = ['networking', 'networking_conversations', 'networking_topics'].some(featureId => isFeatureEnabled(eventFeatures, featureId))
    if (!isNetworkingEnabled) {
      validMenuItems = validMenuItems.filter(menuItem => (menuItem.path === `/events/${eventId}/networking` ? isNetworkingEnabled : true))
    }

    return validMenuItems
  }
)

export const isScheduleInMenuSelector = createSelector(
  menuItemsSelector,
  menuItems => !!menuItems.find(menuItem => /^klik:\/(\/(webview|webview_native))?\/events\/[^/]+\/schedule\/?/g.test(getProperty(menuItem, 'callToAction.url')))
)

export const isNetworkingInMenuSelector = createSelector(
  menuItemsSelector,
  menuItems => !!menuItems.find(menuItem => /^klik:\/(\/(webview|webview_native))?\/events\/[^/]+\/networking\/?/g.test(getProperty(menuItem, 'callToAction.url')))
)


const menu = generateModule({
  itemName: 'menu',
  itemNameDisplay: 'menu',
  autoRefresh: 60,
  services: {
    get: serviceGetMenu,
  },
  app: 'attendee',
})

menu.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isError: false,
  isLoaded: false,
  didInvalidate: true,
  data: [],
})

menu.actions.getMenus = menu.actions.get
export default menu
