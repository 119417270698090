import { addSortingSupport, addPaginationSupport } from './helper'
import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetBadgesData } from 'admin/services/dashboard'
import sort from 'common/utils/sortHelper'


const BadgesModule = generateDataModule({
  initialData: [],
  initialState: {
    queryParams: {
      include_stats: true,
      sort_column: 'ranking',
      sort_order: 1,
      per_page: 15,
      page: undefined,
    },
    pagination: {
      page: 1,
      pageCount: 1,
    },
  },
  moduleKey: 'dashboardBadges',
  reducerKey: 'admin.dashboard.badges',
  services: {
    get: { service: serviceGetBadgesData },
  },
})

addSortingSupport(BadgesModule, { id: 'ranking', order: 1 })
addPaginationSupport(BadgesModule)

BadgesModule.registerSelector(
  'minimalData',
  (state, moduleState) => moduleState.data,
  badges => badges.slice(0, 4)
)

BadgesModule.registerSelector(
  'fullData',
  [
    (state, moduleState) => moduleState.data,
    state => BadgesModule.selectors.sorter(state),
  ],
  (badges, sorter) => sort(badges, [
    { id: sorter.id, order: sorter.order },
    { id: 'achieved_count', order: -1 },
    'title',
    'id',
  ])
)

BadgesModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {
    const queryParams = BadgesModule.selectors.queryParams(getState())
    const getCommunications = () => BadgesModule._getService('get')(queryParams)
    dispatch({ type: BadgesModule.actionKeys.getStart })

    return getCommunications().then(response => {
      dispatch({
        type: BadgesModule.actionKeys.getSuccess,
        payload: response,
      })
    })
      .catch(() => {
        dispatch({ type: BadgesModule.actionKeys.getError })
      })
  }
)

BadgesModule.registerReducer(BadgesModule.actionKeys.getSuccess, (state, action) => ({
  ...state,
  data: action.payload.data,
  pagination: {
    page: action.payload.page,
    pageCount: action.payload.pageCount,
  },
  isLoading: false,
  isLoaded: true,
}))

export default BadgesModule
