import { serviceGetRegistrationStats } from 'admin/services/analytics'
import generateModule from 'common/utils/generateModule'

const { actions, reducers } = generateModule({
  itemName: 'registrationProgress',
  services: {
    get: serviceGetRegistrationStats,
  },
  dataIsNotArray: true,
})

actions.get = (attendeeType) =>
  (dispatch, getState) => {
    dispatch(actions.getStart())
    return serviceGetRegistrationStats(
      getState().admin.events.currentEvent.id,
      attendeeType,
      (response) => dispatch(actions.getSuccess(response)),
      () => dispatch(actions.getError()),
    )
  }

export const getRegistrationProgress = actions.get
const stats = reducers.index
export default stats
