import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { isIDevice, isAndroid } from 'common/utils/domUtils'

import * as appAC from 'common/modules/app'

const WEBVIEW_NATIVE_PATHNAME = '/webview_native'
const WEBVIEW_PATHNAME = '/webview'


@withRouter
@connect(
  undefined,
  dispatch => bindActionCreators({
    setAsNativeApp: appAC.setAsNativeApp,
    setAsWebview: appAC.setAsWebview,
    setNativeAppPlatform: appAC.setNativeAppPlatform,
  }, dispatch)
)
export default class WebviewDetector extends Component {
  componentWillMount() {
    const { location: { pathname } } = this.props

    if (pathname.startsWith(WEBVIEW_NATIVE_PATHNAME)) {
      this.props.setAsNativeApp(true)

      const deviceType = this.getDeviceType()
      if (deviceType) {
        this.props.setNativeAppPlatform(this.getDeviceType())
      }
    } else if (pathname.startsWith(WEBVIEW_PATHNAME)) {
      this.props.setAsWebview(true)
    }
  }

  getDeviceType() {
    if (isIDevice()) {
      return 'iOS'
    } else if (isAndroid()) {
      return 'Android'
    }
    return undefined
  }

  render() {
    return this.props.children
  }
}
