import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetNetworkingConversationAcceptanceStats } from 'admin/services/dashboard'


const ConversationAcceptanceStatsModule = generateDataModule({
  initialData: {
    accepted: 0,
    notAccepted: 0,
    total: 0,
  },
  moduleKey: 'dashboardConversationAcceptanceStats',
  reducerKey: 'admin.dashboard.conversationAcceptanceStats',
  services: {
    get: { service: serviceGetNetworkingConversationAcceptanceStats },
  },
})


ConversationAcceptanceStatsModule.registerDataAction(
  'get',
  () => dispatch => {
    dispatch({ type: ConversationAcceptanceStatsModule.actionKeys.getStart })

    return ConversationAcceptanceStatsModule._getService('get')()
      .then(response => {
        dispatch({ type: ConversationAcceptanceStatsModule.actionKeys.getSuccess, payload: response })
      })
      .catch(() => {
        dispatch({ type: ConversationAcceptanceStatsModule.actionKeys.getError })
      })
  }
)

ConversationAcceptanceStatsModule.registerReducer(
  ConversationAcceptanceStatsModule.actionKeys.getSuccess,
  (state, action) => ({
    ...state,
    data: {
      accepted: action.payload.accepted,
      notAccepted: action.payload.notAccepted,
      total: action.payload.total,
    },
  })
)


export default ConversationAcceptanceStatsModule
