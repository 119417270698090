import axios from 'axios'
import config from 'config'
import i18n from 'i18next'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getAccessToken, buildQueryString } from './helper'


function getUploadFormat(type) {
  return type === 'text/csv' ? 'csv' : 'xlsx'
}

function buildMappingString(mapping) {
  let mappingString = ''
  mapping.forEach((m, i) => { mappingString += `${i ? '&' : ''}${encodeURIComponent(m.header)}=${encodeURIComponent(m.mapping)}` })
  return mappingString
}

function buildUploadConfig() {
  return {
    baseURL: config.apiUrl,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Accept-Language': i18n.language,
      'Content-Type': 'text/csv; charset=utf8',
    },
  }
}

function sendImport(path, data, success) {
  return new Promise((resolve, reject) => {
    try {
      const conf = buildUploadConfig()
      const req = new XMLHttpRequest()
      let state = {}
      req.open('POST', conf.baseURL + path)
      req.setRequestHeader('Authorization', conf.headers.Authorization)
      req.send(data)
      req.onreadystatechange = () => {
        try {
          state = JSON.parse(req.responseText.split('\n').slice(-1))
        } catch (e) {
          // sometimes state can be empty, skip until next one
        }

        if (req.readyState !== 4) {
          success(state)
        } else if (req.status === 200) {
          resolve(state)
        } else {
          // XMLHttpRequest.onerror won't catch those errors because 4xx statuses are valid
          reject({
            response: {
              status: state.status,
              data: {
                description: state.description,
              },
            },
          })
        }
      }
    } catch (e) {
      reject(e)
    }
  })
}

export function serviceUploadAppointments(eventId, data, calendarId, sessionId, success, error) {
  const format = getUploadFormat(data.type)

  return axios.post(`/events/${eventId}/calendars/${calendarId}/sessions/${sessionId}/appointments.${format}?preview=true`, data, buildUploadConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadAppointmentsWithMapping(eventId, data, calendarId, sessionId, mapping, success, error) {
  const format = getUploadFormat(data.type)
  const mappingString = buildMappingString(mapping)
  const url = `/events/${eventId}/calendars/${calendarId}/sessions/${sessionId}/appointments.${format}?progress=true&${mappingString}`

  return sendImport(url, data, success)
    .then((response) => success(response), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadAppointmentParticipants(eventId, data, calendarId, sessionId, success, error) {
  const format = getUploadFormat(data.type)

  return axios.post(`/events/${eventId}/calendars/${calendarId}/sessions/${sessionId}/appointments/attendees.${format}?preview=true`, data, buildUploadConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadAppointmentParticipantsWithMapping(eventId, data, calendarId, sessionId, mapping, success, error) {
  const format = getUploadFormat(data.type)
  const mappingString = buildMappingString(mapping)
  const url = `/events/${eventId}/calendars/${calendarId}/sessions/${sessionId}/appointments/attendees.${format}?progress=true&${mappingString}`

  return sendImport(url, data, success)
    .then((response) => success(response), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadSessions(eventId, data, success, error) {
  const format = getUploadFormat(data.type)

  return axios.post(`/events/${eventId}/sessions/fields?fmt=${format}`, data, buildUploadConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadPreRegistration(eventId, data, success, error) {
  const format = getUploadFormat(data.type)

  return axios.post(`/events/${eventId}/sessions/relations/fields?fmt=${format}`, data, buildUploadConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadSessionsWithMapping(eventId, data, calendarId, mapping, success, error) {
  const format = getUploadFormat(data.type)
  const mappingString = buildMappingString(mapping)

  const queryString = buildQueryString({
    calendar_id: calendarId,
    progress: true,
  })

  const url = `/events/${eventId}/sessions.${format}${queryString}&${mappingString}`

  return sendImport(url, data, success)
    .then((response) => success(response), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadPreRegistrationWithMapping(eventId, data, calendarId, mapping, success, error) {
  const format = getUploadFormat(data.type)
  const mappingString = buildMappingString(mapping)
  const url = `/events/${eventId}/calendars/${calendarId}/sessions/relations.${format}?progress=true&${mappingString}`

  return sendImport(url, data, success)
    .then((response) => success(response), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadProfiles(eventId, data, success, error) {
  const format = getUploadFormat(data.type)

  return axios.post(`/events/${eventId}/attendees/fields?fmt=${format}`, data, buildUploadConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadProfilesWithMapping(eventId, data, mapping, success, error) {
  const format = getUploadFormat(data.type)
  const mappingString = buildMappingString(mapping)
  const url = `/events/${eventId}/attendees.${format}?progress=true&${mappingString}`

  return sendImport(url, data, success)
    .then((response) => success(response), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadSpeakers(eventId, data, success, error) {
  const format = getUploadFormat(data.type)

  return axios.post(`/events/${eventId}/speakers/fields?fmt=${format}`, data, buildUploadConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadSpeakersWithMapping(eventId, data, mapping, success, error) {
  const format = getUploadFormat(data.type)
  const mappingString = buildMappingString(mapping)
  const url = `/events/${eventId}/speakers.${format}?progress=true&${mappingString}`

  return sendImport(url, data, success)
    .then((response) => success(response), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadTouchpoints(eventId, data, success, error) {
  const format = getUploadFormat(data.type)

  return axios.post(`/events/${eventId}/touchpoints/fields?fmt=${format}`, data, buildUploadConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadTouchpointsWithMapping(eventId, data, mapping, success, error) {
  const format = getUploadFormat(data.type)
  const mappingString = buildMappingString(mapping)
  const url = `/events/${eventId}/touchpoints.${format}?progress=true&${mappingString}`

  return sendImport(url, data, success)
    .then((response) => success(response), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadExhibitors(eventId, data, success, error) {
  const format = getUploadFormat(data.type)
  return axios.post(`/events/${eventId}/exhibitors/fields?fmt=${format}`, data, buildUploadConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadExhibitorsWithMapping(eventId, data, mapping, success, error) {
  const format = getUploadFormat(data.type)
  const mappingString = buildMappingString(mapping)
  const url = `/events/${eventId}/exhibitors.${format}?progress=true&${mappingString}`

  return sendImport(url, data, success)
    .then((response) => success(response), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadSessionParticipants(eventId, calendarId, sessionId, relation, data, success, error) {
  const format = getUploadFormat(data.type)
  return axios.post(`/events/${eventId}/calendars/${calendarId}/sessions/${sessionId}/attendees/${relation}.${format}`, data, buildUploadConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadSessionParticipantsWithMapping(eventId, calendarId, sessionId, relation, data, mapping, success, error) {
  const format = getUploadFormat(data.type)
  const mappingString = buildMappingString(mapping)
  const url = `/events/${eventId}/calendars/${calendarId}/sessions/${sessionId}/attendees/${relation}.${format}?progress=true&${mappingString}`

  return sendImport(url, data, success)
    .then((response) => success(response), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
