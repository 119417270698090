import { serviceGetDeviceGroups, serviceSaveDeviceGroups, serviceGenerateDeviceGroups, serviceRefreshDeviceGroupsStats, serviceDeleteDeviceGroups } from 'admin/services/groupings'
import generateModule from 'common/utils/generateModule'
import i18n from 'i18next'
import { showToast } from 'common/modules/toaster'

const { actions, reducers } = generateModule({
  itemName: 'deviceGroup',
  displayName: 'deviceGroup',
  services: {
    get: serviceGetDeviceGroups,
  },
})

actions.saveDeviceGroups = (groups) =>
  (dispatch, getState) => {
    dispatch({ type: 'SAVE_DEVICE_GROUPS_START' })
    return serviceSaveDeviceGroups(
      getState().admin.events.currentEvent.id,
      groups,
      () => {
        dispatch({ type: 'SAVE_DEVICE_GROUPS_SUCCESS' })
        dispatch(actions.get())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('deviceGroups.save.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('deviceGroups.save.success'),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch({ type: 'SAVE_DEVICE_GROUPS_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('deviceGroups.save.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['SAVE_DEVICE_GROUPS_START'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: true,
})

reducers['SAVE_DEVICE_GROUPS_SUCCESS'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: false,
})

reducers['SAVE_DEVICE_GROUPS_ERROR'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: false,
})


actions.generateDeviceGroups = () =>
  (dispatch, getState) => {
    dispatch({ type: 'GENERATE_DEVICE_GROUPS_START' })
    return serviceGenerateDeviceGroups(
      getState().admin.events.currentEvent.id,
      (response) => {
        dispatch({ type: 'GENERATE_DEVICE_GROUPS_SUCCESS', payload: { deviceGroups: response } })
      },
      (error) => {
        dispatch({ type: 'GENERATE_DEVICE_GROUPS_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('deviceGroups.generate.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['GENERATE_DEVICE_GROUPS_START'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  data: {},
  isModifying: true,
})

reducers['GENERATE_DEVICE_GROUPS_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  data: action.payload.deviceGroups,
  isModifying: false,
})

reducers['GENERATE_DEVICE_GROUPS_ERROR'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: false,
})


actions.refreshDeviceGroupsStats = () =>
  (dispatch, getState) => {
    dispatch(actions.getStart())
    return serviceRefreshDeviceGroupsStats(
      getState().admin.events.currentEvent.id,
      (response) => {
        dispatch(actions.getSuccess(response))
      },
      (error) => {
        dispatch(actions.getError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('deviceGroups.refresh.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }


actions.deleteDeviceGroups = () =>
  (dispatch, getState) => {
    dispatch({ type: 'DELETE_DEVICE_GROUPS_START' })
    return serviceDeleteDeviceGroups(
      getState().admin.events.currentEvent.id,
      () => {
        dispatch({ type: 'DELETE_DEVICE_GROUPS_SUCCESS' })
      },
      (error) => {
        dispatch({ type: 'DELETE_DEVICE_GROUPS_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('deviceGroups.delete.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['DELETE_DEVICE_GROUPS_START'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: true,
})

reducers['DELETE_DEVICE_GROUPS_SUCCESS'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  data: {},
  isModifying: false,
})

reducers['DELETE_DEVICE_GROUPS_ERROR'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: false,
})

export const getDeviceGroups = actions.get
export const saveDeviceGroups = actions.saveDeviceGroups
export const generateDeviceGroups = actions.generateDeviceGroups
export const refreshDeviceGroupsStats = actions.refreshDeviceGroupsStats
export const deleteDeviceGroups = actions.deleteDeviceGroups

const deviceGroups = reducers.index
export default deviceGroups
