import { serviceFavoriteSession, serviceUnfavoriteSession } from 'attendee/services/sessions'
import { handleErrorMessage } from './index'


export function favoriteSession(sessionId) {
  return (dispatch, getState) => {
    dispatch({ type: 'FAVORITE_SESSION', payload: { sessionId } })
    return serviceFavoriteSession(
      getState().attendee.attEvents.currEvent.id,
      'me',
      sessionId,
      () => dispatch({ type: 'FAVORITE_SESSION', payload: { sessionId } }),
      error => {
        dispatch({ type: 'UNFAVORITE_SESSION', payload: { sessionId } })
        handleErrorMessage(dispatch, getState, error)
      }
    )
  }
}


export function unfavoriteSession(sessionId) {
  return (dispatch, getState) => {
    dispatch({ type: 'UNFAVORITE_SESSION', payload: { sessionId } })
    return serviceUnfavoriteSession(
      getState().attendee.attEvents.currEvent.id,
      'me',
      sessionId,
      () => dispatch({ type: 'UNFAVORITE_SESSION', payload: { sessionId } }),
      error => {
        dispatch({ type: 'FAVORITE_SESSION', payload: { sessionId } })
        handleErrorMessage(dispatch, getState, error)
      }
    )
  }
}
