import { serviceGetEngagementOvertime } from 'admin/services/analytics'
import generateModule from 'common/utils/generateModule'


const { actions, reducers, initialState } = generateModule({
  itemName: 'engagement',
  autoRefresh: 60,
  dataIsNotArray: true,
  getModuleState: (getState) => getState().admin.analytics.overtimeGraphs.engagement,
  services: {
    get: serviceGetEngagementOvertime,
  },
})


function formatStats(stats) {
  const groups = {}

  for (let i = 0; i < stats.length; i++) {
    for (let j = 0; j < stats[i].groups.length; j++) {
      const group = stats[i].groups[j]

      if (groups[group['activity_type']] === undefined) {
        groups[group['activity_type']] = []
      }

      groups[group['activity_type']].push({ date: stats[i].date, value: group.count })
    }
  }

  return groups
}


actions.get = (query) =>
  (dispatch, getState) => {
    dispatch(actions.getStart())
    return serviceGetEngagementOvertime(
      getState().admin.events.currentEvent.id,
      { ...query, groupby: 'activity_type' },
      (response) => dispatch(actions.getSuccess(formatStats(response.timeseries))),
      () => dispatch(actions.getError())
    )
  }


reducers['CHANGE_EVENT_SUCCESS'] = (state) => ({
  ...state,
  didInvalidate: true,
  data: initialState.data,
})


export const getEngagementOvertime = actions.get
export const getEngagementOvertimeIfNeeded = actions.getIfNeeded

const engagement = reducers.index
export default engagement
