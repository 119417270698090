import { generateDataModule, invalidateOnEventChange } from 'admin/modules/generateModule'
import { serviceGetCountEmployees } from 'admin/services/bluetrace'


const CountEmployeesModule = generateDataModule({
  initialData: 0,
  moduleKey: 'cpo/bluetrace/dashboard/countEmployees',
  reducerKey: 'admin.bluetrace.dashboard.countEmployees',
  refreshTime: 10000,
  services: {
    get: { service: serviceGetCountEmployees },
  },
})

invalidateOnEventChange(CountEmployeesModule)


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////


export default CountEmployeesModule
