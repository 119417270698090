import i18n from 'i18next'
import generateServices from './Services/index'


function validateOptions(options) {
  if (!options.moduleKey) throw new Error('A module key is required.')
}


export default class Module {
  constructor(options) {
    validateOptions(options)

    this.config = {
      moduleKey: options.moduleKey,
      reducerKey: options.reducerKey || options.moduleKey,
      refreshTime: options.refreshTime || 60,
      services: options.services || {},
      idField: Object.prototype.hasOwnProperty.call(options, 'idField') ? options.idField : 'id',
      app: options.app || null,
      t: options.t || ((key, extra) => i18n.getFixedT(i18n.language, 'common_customModule')(`${options.moduleKey}.${key}`, extra)),
    }

    this.initialState = {
      isFetching: false,
      isModifying: false,
      isError: false,
      isLoaded: false,
      didInvalidate: false,
      lastUpdated: 0,
      data: options.initialData || [],
    }

    const { actions, reducers } = generateServices(this.config, this.initialState)
    this.actions = actions
    this.reducers = reducers
  }
}
