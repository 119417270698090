import React, { Component } from 'react'
import { apiUrl } from 'config'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import i18n from 'i18next'
import { translate } from 'react-i18next'
import WithCurrentEvent from 'attendee/utils/decorators/WithCurrentEvent'
import handlePageLoader from 'common/utils/decorators/PageLoader'

import * as authAC from 'common/modules/auth'

import { Card } from 'common/components/Layout'
import ExportLink from 'attendee/components/ExportContactButton/ExportLink'

import { styles } from './styles.scss'


@translate(['common_customModule'], { wait: true })
@WithCurrentEvent()
@connect(
  state => ({ auth: state.auth }),
  dispatch => bindActionCreators(authAC, dispatch)
)
@handlePageLoader()
export default class ExportContacts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      eventId: undefined,
      accessToken: undefined,
    }
  }

  componentDidMount() {
    const eventId = this.props.match.params.event_id
    const token = encodeURIComponent(this.props.auth.user.accessToken)
    window.location.href = `${apiUrl}/events/${eventId}/attendees/me/contacts.xlsx?access_token=${token}&_lang=${i18n.language}`
  }

  render() {
    const { t, currentEvent } = this.props

    return (
      <div className={styles}>
        <div className="splash">
          <Card id="contact-well">
            <div className="logo" />

            {this.props.t('Export Contacts Message')}

            <ExportLink className="btn-secondary" currentEvent={currentEvent} eventId={this.props.match.params.event_id}>
              {t('Export Contacts')}
            </ExportLink>
          </Card>
        </div>
      </div>
    )
  }
}
