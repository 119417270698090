import React, { Component } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { store, browserHistory } from '../../../index'


export function prepareLink(link) {
  const state = store.getState()
  if (state.app.isNativeApp && !link.startsWith('/webview_native')) return '/webview_native' + link
  if (state.app.isWebview && !link.startsWith('/webview')) return '/webview' + link
  return link
}

export function setAsAdminPreviewUrl(url) {
  return `${url}` + (url.match(/\?/) ? '&_adminpreview=true' : '?_adminpreview=true')
}

export function browserHistoryPush(link) {
  browserHistory.push(prepareLink(link))
}

export function browserHistoryBack() {
  browserHistory.goBack()
}


let lastQueryParams = {}
export function getQueryParam(location, param) {
  const params = lastQueryParams[location.search]
    ? lastQueryParams[location.search]
    : new window.URLSearchParams(location.search)

  lastQueryParams = { [location.search]: params }

  if (!param) {
    return params
  }

  return Array.isArray(param)
    ? param.map(p => params.get(p))
    : params.get(param)
}

export default class Link extends Component {
  render() {
    const to = this.props.to ? prepareLink(this.props.to) : this.props.to
    return <RouterLink {...this.props} to={to}>{this.props.children}</RouterLink>
  }
}
