import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceGetMenu(eventId, success, error) { // eslint-disable-line import/prefer-default-export
  return axios.get(`events/${eventId}/attendees/me/my_menu/`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
