import { DataModule } from 'redux-data-module'
import { serviceGetLocations } from 'exhibitor/services/maps'
import { selectors as eventSelectors } from 'exhibitor/modules/event'


const LocationsModule = new DataModule({
  moduleKey: 'cpe/locations',
  reducerKey: 'exhibitor.locations',

  services: {
    get: { service: serviceGetLocations },
  },
})

LocationsModule.actions.get = () => (dispatch, getState) => {
  dispatch({ type: LocationsModule.actionKeys.getStart })

  return serviceGetLocations(eventSelectors.eventId(getState()))
    .then(response => {
      dispatch({ type: LocationsModule.actionKeys.getSuccess, payload: response })
      return Promise.resolve()
    })
    .catch(() => {
      dispatch({ type: LocationsModule.actionKeys.getError })
      return Promise.reject()
    })
}


export default LocationsModule
