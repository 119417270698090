import { serviceGetBadges } from 'attendee/services/gamification'
import generateModule from 'common/utils/generateModule'
import { createSelector } from 'reselect'
import { filtersSelector } from './FilterSettings/badges'
import { tagsSelector } from './tags'
import { listToMapping } from 'common/utils/componentHelper'
import search, { buildFieldList } from 'common/utils/search'

const getTagId = (tag) => ((typeof (tag) === 'string') ? tag : tag.id)

const selectBadges = (state) => state.attendee.badges.data

export const filteredBadgesSelector = createSelector(
  selectBadges,
  filtersSelector,
  (badges, filters) => {
    let filteredBadges = [...badges];

    (function filterBySearch() {
      if (!filters.search) return
      filteredBadges = filteredBadges.filter(badge => search(filters.search, buildFieldList(badge, ['name', 'description'])))
    }());

    (function filterByTags() {
      if (!filters.tags || filters.tags.length <= 0) return

      filteredBadges = filteredBadges.filter(badge => (
        filters.tagsConditionType === 'AND'
          ? filters.tags.every(tag => (badge.tags || []).some(badgeTag => (getTagId(tag) === getTagId(badgeTag))))
          : filters.tags.some(tag => (badge.tags || []).some(badgeTag => (getTagId(tag) === getTagId(badgeTag))))
      ))
    }());

    return filteredBadges
  }
)

export const badgesTagsSelector = createSelector(
  selectBadges,
  tagsSelector,
  (badges, tags) => {
    const badgeTagsMap = new Map();
    badges.forEach(badge => {
      (badge.tags || []).forEach(tag => {
        badgeTagsMap.set(getTagId(tag), tag)
      })
    })

    return tags.filter(tag => badgeTagsMap.has(getTagId(tag)))
  }
)

export const badgesByIdSelector = createSelector(
  selectBadges,
  (badges) => listToMapping(badges, 'id')
)


const badges = generateModule({
  itemName: 'badge',
  itemNameDisplay: 'badge',
  autoRefresh: 60,
  services: {
    get: serviceGetBadges,
  },
  app: 'attendee',
})

badges.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isLoaded: false,
  isError: false,
  didInvalidate: true,
  data: [],
})

export default badges
