import { serviceGetAttendeeTypes, serviceCreateAttendeeType, serviceUpdateAttendeeType, serviceDeleteAttendeeType } from 'admin/services/attendeeTypes'
import generateModule from './generateModule'
import { listToMapping } from 'common/utils/componentHelper'
import { createSelector } from 'reselect'
import { eventLanguageSelector } from './events'
import { getTranslatedValue } from 'common/utils/translator'


const selectState = (state) => state.admin.attendeeTypes
const selectAttendeeTypes = (state) => selectState(state).data

export const sortedAttendeeTypesSelector = createSelector(
  selectAttendeeTypes,
  eventLanguageSelector,
  (attendeeTypes, eventLanguage) => attendeeTypes.sort((a, b) => {
    const titleA = getTranslatedValue(a.title, eventLanguage) || a.id
    const titleB = getTranslatedValue(b.title, eventLanguage) || b.id
    return titleA.localeCompare(titleB)
  })
)

export const attendeeTypesSelector = createSelector(selectState, moduleState => moduleState.data)
export const attendeeTypesByIdSelector = createSelector(attendeeTypesSelector, attendeeTypes => listToMapping(attendeeTypes, 'id'))

const attendeeTypeModule = generateModule({
  moduleKey: 'attendeeTypes',
  services: {
    get: {
      service: serviceGetAttendeeTypes,
      showOnError: true,
    },
    post: {
      service: serviceCreateAttendeeType,
      showOnError: true,
    },
    put: {
      service: serviceUpdateAttendeeType,
      showOnError: true,
    },
    delete: {
      service: serviceDeleteAttendeeType,
      showOnError: true,
    },
  },
})


export const getAttendeeTypes = attendeeTypeModule.actions.get
export const getAttendeeTypesIfNeeded = attendeeTypeModule.actions.getIfNeeded
export const createAttendeeType = attendeeTypeModule.actions.post
export const updateAttendeeType = attendeeTypeModule.actions.put
export const deleteAttendeeType = attendeeTypeModule.actions.delete
export default attendeeTypeModule
