import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { translate } from 'react-i18next'
import { getTextColor, isIE } from 'common/utils/domUtils'
import { getQueryParam } from 'common/components/Link'

import Input from 'common/components/Input'


@translate(['login'], { wait: true })
@reduxForm({ form: 'login', fields: ['email'], destroyOnUnmount: false })
export default class EnterEmail extends Component {
  constructor(props) {
    super(props)
    this.justSubmitted = false
    this.tryAutoLogin = false
  }

  componentDidMount() {
    this.props.changeInputBorderColor()

    const username = getQueryParam(this.props.location, 'username')
    if (username && !this.props.user.isLoggedIn) {
      this.props.fields.email.onChange(username)
      this.tryAutoLogin = true
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.justSubmitted && nextProps.loginErrorMessage) {
      const emailInput = document.getElementById('email')
      emailInput && emailInput.focus()
      this.justSubmitted = false
    }
  }

  componentDidUpdate() {
    if (this.tryAutoLogin) {
      this.onSubmit('send_code')
    }
  }

  onSubmit = (page) => {
    this.justSubmitted = true
    this.tryAutoLogin = false
    this.props.loginEmail(this.props.values.email, this.props.location, this.props.domain, page)
  }

  onFormSubmit = (e) => {
    e && e.preventDefault()
    document.activeElement.blur()
    this.onSubmit()
  }

  render() {
    const { t, loginErrorMessage } = this.props
    const { email: fEmail } = this.props.fields
    const { brandColor } = this.props.loginBranding
    const autofocus = !isIE() // IE auto-focus removes placeholder text, making field un-labeled

    return (
      <div className="EnterEmail">
        <form onSubmit={this.onFormSubmit}>
          <Input field={fEmail} placeholder={t('login_email.placeholder')} type="email" autoFocus={autofocus} />
          {loginErrorMessage &&
            <div className="login-error-message-container">
              {loginErrorMessage}
            </div>}

          <button
            id="login-btn"
            style={{ color: getTextColor(brandColor), backgroundColor: brandColor }}
            className="btn btn-block"
            type="submit"
          >
            {this.props.isFetching
              ? <i className="fa fa-spin fa-circle-o-notch" aria-hidden="true" />
              : t('login_email.next')}
          </button>
        </form>
      </div>
    )
  }
}
