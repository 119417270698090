import { setWebviewFunction } from 'klikWebview'
import { store } from '../../index'
import { socialNetworkAuthorizationSelector } from 'common/modules/auth'

const settings = 'scrollbars=no,toolbar=no,location=no,titlebar=no,directories=no,status=no,menubar=no'

function getPopupOffset({ width, height }) {
  const wLeft = window.screenLeft ? window.screenLeft : window.screenX
  const wTop = window.screenTop ? window.screenTop : window.screenY

  const left = (wLeft + (window.innerWidth / 2)) - (width / 2)
  const top = (wTop + (window.innerHeight / 2)) - (height / 2)

  return { top, left }
}

function getPopupSize(provider) {
  const sizes = {
    facebook: { width: 580, height: 410 },
    google: { width: 452, height: 633 },
    github: { width: 1020, height: 618 },
    linkedin: { width: 527, height: 582 },
    twitter: { width: 495, height: 645 },
    live: { width: 500, height: 560 },
    yahoo: { width: 559, height: 519 },
    salesforce: { width: 481, height: 524 },
    instagram: { width: 700, height: 500 },
  }

  return sizes[provider] || { width: 1020, height: 618 }
}

function getPopupDimensions(provider) {
  const { width, height } = getPopupSize(provider)
  const { top, left } = getPopupOffset({ width, height })

  return `width=${width},height=${height},top=${top},left=${left}`
}

export function openPopup(provider, url, name) {
  return window.open(url, name, `${settings},${getPopupDimensions(provider)}`)
}

function getSocialCode(url) {
  const queryString = url.split('?')[1]
  const codeStart = queryString.indexOf('code=')
  if (codeStart === -1) {
    return null
  }

  const codeEnd = (() => {
    const nextParamIndex = queryString.indexOf('&', codeStart)
    const hashIndex = queryString.indexOf('#', codeStart)

    if (nextParamIndex !== -1) {
      return nextParamIndex
    } else if (hashIndex !== -1) {
      return hashIndex
    }
    return -1
  })()

  return queryString.substring(codeStart + 'code='.length, (codeEnd < 0) ? queryString.length : codeEnd)
}

function handleSocialUrl(url, network, redirectUri, onSuccess) {
  const code = getSocialCode(url)
  if (!code) {
    return
  }

  try {
    window.webkit.messageHandlers.logger.postMessage('Ready for callback')
  } catch (error) {
    // Catch me
  }
  onSuccess(decodeURIComponent(code), network, redirectUri)
}

let interval = null
function startSocialLoginProcess(networkType, url, redirectUri, onSuccess) {
  if (interval) {
    clearInterval(interval)
  }

  const pop = openPopup(networkType, url, 'connectPopup')

  // NOTE: This flag is set in the android native app
  if (window.isAndroidWebview) {
    return undefined
  }

  interval = setInterval(() => {
    try {
      const redirectHost = redirectUri.split('?')[0]

      const backhome = redirectHost.startsWith(pop.location.origin) || redirectHost.startsWith(pop.location.protocol + '//' + pop.location.host)

      if (backhome) {
        handleSocialUrl(pop.location.href, networkType, redirectUri, onSuccess)
        pop.close()
      }
    } catch (error) {
      // ignore security error from cross-protocol pop-up access, we don't care until we get back to our origin
    }

    if (!pop || pop.closed || pop.closed === undefined) {
      clearInterval(interval);
      interval = null;
    }
  }, 500)

  return interval
}

export function getSocialNetworkAuthInfo(socialNetworkName) {
  return socialNetworkAuthorizationSelector(store.getState(), socialNetworkName)
}

export function connectSocialNetwork(network, onSuccess) {
  const socialNetwork = getSocialNetworkAuthInfo(network)
  if (!socialNetwork) throw new Error('Unsupported social network: ' + network)

  const url = socialNetwork.url
  const queryParams = new window.URLSearchParams(url)
  const redirectUri = queryParams.get('redirect_uri')

  // TODO: Test if this is still necessary
  //       Using this, LinkedIn doesn't work anymore
  // if (network === 'linkedin' || network === 'facebook') {
  //   if (network === 'facebook') {
  //     redirectUri += '/'
  //   }
  //   redirectUri = `${redirectUri}?redirect_uri=${encodeURIComponent(redirectUri)}`
  // }


  setWebviewFunction('socialNetworkLoginCallback', _url => handleSocialUrl(_url, network, redirectUri, onSuccess))
  return startSocialLoginProcess(network, url, redirectUri, onSuccess)
}

export const socialNetworksBranding = {
  linkedin: { fontIcon: 'fa fa-linkedin', color: '#0077b5', textColor: '#fff', label: 'LinkedIn' },
  facebook: { fontIcon: 'fa fa-facebook', color: '#3b5998', textColor: '#fff', label: 'Facebook' },
  google: { fontIcon: 'fa fa-google-plus', color: '#C63D2D', textColor: '#fff', label: 'Google' },
  twitter: { fontIcon: 'fa fa-twitter', color: '#00a0d1', textColor: '#fff', label: 'Twitter' },
  instagram: { fontIcon: 'fa fa-instagram', color: '#4E433C', textColor: '#fff', label: 'Instagram', background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)' },
  eegsignal: {},
  email: { fontIcon: 'fa fa-envelope-o', color: '', textColor: '#000' },
}
