import { serviceGetFonts } from 'common/services/fonts'
import { loadFonts } from 'common/utils/FontLoader'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'


export function getFonts() {
  return dispatch => {
    dispatch({ type: 'GET_FONTS_START' })

    return new Promise(resolve => {
      serviceGetFonts(
        response => {
          const fontsToLoad = []
          const fontsByFontFamily = {}
          const fontFamilyByFontName = {}

          response.forEach(fontFamily => {
            fontsByFontFamily[fontFamily.family] = {
              fonts: [],
              default: fontFamily.default.name,
            }

            fontFamily.fonts.forEach(font => {
              fontsToLoad.push({ name: font.name, url: font.url })
              fontsByFontFamily[fontFamily.family].fonts.push(font.name)
              fontFamilyByFontName[font.name] = fontFamily.family
            })
          })

          loadFonts(fontsToLoad).finally(() => {
            dispatch({ type: 'GET_FONTS_SUCCESS', payload: { fontsByFontFamily, fontFamilyByFontName } })
            resolve()
          })
        },
        error => {
          dispatch({ type: 'GET_FONTS_ERROR' })
          dispatch(showToast({
            title: i18n.getFixedT(i18n.language, 'common_module')('fonts.get.title'),
            message: error.description,
            level: 'error',
            permanent: false,
          }))
          resolve()
        }
      )
    })
  }
}


const initialState = {
  isFetching: false,
  isLoaded: false,
  isError: false,

  fontsByFontFamily: {},
  fontFamilyByFontName: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_FONTS_START':
      return {
        ...state,
        isFetching: true,
        isError: false,
      }

    case 'GET_FONTS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        fontsByFontFamily: action.payload.fontsByFontFamily,
        fontFamilyByFontName: action.payload.fontFamilyByFontName,
      }

    case 'GET_FONTS_ERROR':
      return {
        ...state,
        isFetching: false,
        isError: true,
      }

    default:
      return state
  }
}
