import axios from 'axios'

import { buildError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { getAxiosConfig } from './helper'


export function translateInfoPopup(data) {
  return {
    ...data,
    text: getTranslatedObject(data.text),
  }
}


export function serviceGetInfoPopups(eventId) {
  return axios.get(`/events/${eventId}/info_popups`, getAxiosConfig())
    .then(response => response.data.map(translateInfoPopup))
    .catch(error => Promise.reject(buildError(error)))
}

export function servicePostInfoPopup(eventId, data) {
  return axios.post(`/events/${eventId}/info_popups`, data, getAxiosConfig())
    .then(response => translateInfoPopup(response.data))
    .catch(error => Promise.reject(buildError(error)))
}

export function servicePutInfoPopup(eventId, data) {
  return axios.put(`/events/${eventId}/info_popups/${data.id}`, data, getAxiosConfig())
    .then(response => translateInfoPopup(response.data))
    .catch(error => Promise.reject(buildError(error)))
}
