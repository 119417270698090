import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetWearables } from 'admin/services/wearables'


const WearablesModule = generateDataModule({
  initialData: [],
  initialState: {
    queryParams: {
      since_last_report: 60 * 60,
    },
    filters: {
      not_found: true,
      long_activation: true,
    },
  },

  moduleKey: 'wearables',
  reducerKey: 'admin.wearables',

  services: {
    get: { service: serviceGetWearables },
  },
})


WearablesModule.registerSelector(
  'filteredData',
  (state, moduleState) => moduleState,
  moduleState => {
    const filters = moduleState.filters
    let data = moduleState.data
    if (!filters.not_found) {
      data = data.filter(w => w.status !== 'Not found')
    }
    if (!filters.long_activation) {
      data = data.filter(w => w.status !== 'Long activation')
    }
    return data
  }
)

WearablesModule.registerSelector(
  'queryParams',
  (state, moduleState) => moduleState.queryParams,
  queryParams => queryParams
)

WearablesModule.registerSelector(
  'filters',
  (state, moduleState) => moduleState.filters,
  filters => filters
)

WearablesModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {
    const queryParams = WearablesModule.selectors.queryParams(getState())

    dispatch({ type: WearablesModule.actionKeys.getStart })
    return WearablesModule._getService('get')(queryParams)
      .then(response => {
        dispatch({ type: WearablesModule.actionKeys.getSuccess, payload: response })
      })
      .catch(() => {
        dispatch({ type: WearablesModule.actionKeys.getError })
      })
  }
)

WearablesModule.registerActionKey('setFilters')

WearablesModule.actions.setFilters = (state) => dispatch => {
  dispatch({
    type: WearablesModule.actionKeys.setFilters,
    payload: state,
  })
}

WearablesModule.reducers[WearablesModule.actionKeys.setFilters] = (state, action) => ({
  ...state,
  filters: action.payload,
})

export default WearablesModule
