require('./polyfills')

import './serviceWorker'

/* eslint-disable import/first */
import React from 'react'
import ReactDOM from 'react-dom'
// import { browserHistory } from 'react-router'
// import { syncHistoryWithStore } from 'react-router-redux'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { createBrowserHistory } from 'history'
import { detectNetworkStatus } from 'common/modules/offline'
import configureStore from './configureStore'
import { browserHistoryPush } from 'common/components/Link'
import AppContainer from './AppContainer'
import { initializeWebview } from 'klikWebview'
/* eslint-enable import/first */

export const browserHistory = createBrowserHistory()

export const store = configureStore(undefined, browserHistory) // eslint-disable-line import/prefer-default-export
initializeWebview()

detectNetworkStatus(store.dispatch)

const tagManagerArgs = {
  gtmId: 'GTM-MX2XRNF',
}

// Initialize Google Analytics component to the corresponding project
switch (process.env.NODE_ENV) {
  case 'production':
    ReactGA.initialize('UA-91608988-1')
    TagManager.initialize(tagManagerArgs)
    break
  case 'staging':
    ReactGA.initialize('UA-91608988-2')
    break
  case 'test':
    ReactGA.initialize('UA-91608988-3')
    break
  case 'demo':
    ReactGA.initialize('UA-91608988-4')
    break
  default:
    // No ReactGA for development environment
    break
}

// Returns a number whose value is limited to the given range.
Math.clamp = (num, min, max) => Math.min(Math.max(num, min), max) // eslint-disable-line no-extend-native

// Workaround to redirect links from old event emails (when we used hashHistory)
// But disable for #! which is obvi not a login link, and is added by some social networks to the OAuth redirect
if (location.hash.indexOf('#') === 0 && location.hash !== '#!') {
  browserHistoryPush(location.hash.substring(1) + location.search)
}


ReactDOM.render(
  <AppContainer history={browserHistory} store={store} />,
  document.getElementById('root')
)
