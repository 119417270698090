import PropTypes from 'prop-types'


export const PropTypeTranslatable = PropTypes.oneOf([PropTypes.string, PropTypes.object])

export const PropTypeCallToAction = PropTypes.shape({
  label: PropTypeTranslatable.isRequired,
  http_url: PropTypes.string,
  target: PropTypes.string,

  url: PropTypes.url,
  app_link: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
})
