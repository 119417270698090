import { generateBaseModule } from 'admin/modules/generateModule'


const NetworkFiltersModule = generateBaseModule({
  initialState: {
    search: '',
    selectedEmployeeId: undefined,
    graph: {
      start_date: undefined,
      end_date: undefined,
      min_duration: undefined,
    },
  },
  moduleKey: 'cpo/bluetrace/networkFilters',
  reducerKey: 'admin.bluetrace.employeeNetwork.filters',
})


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

NetworkFiltersModule.registerSelector(
  'state',
  (state, moduleState) => moduleState,
  state => state
)

NetworkFiltersModule.registerSelector(
  'search',
  (state, moduleState) => moduleState.search,
  value => value
)

NetworkFiltersModule.registerSelector(
  'employeeId',
  (state, moduleState) => moduleState.selectedEmployeeId,
  value => value
)

NetworkFiltersModule.registerSelector(
  'graph',
  (state, moduleState) => moduleState.graph,
  value => value
)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////

NetworkFiltersModule.registerAction('setSearch', searchTerm => dispatch => {
  dispatch({ type: NetworkFiltersModule.actionKeys.setSearch, payload: searchTerm || '' })
  return Promise.resolve()
})

NetworkFiltersModule.registerAction('setEmployeeId', employeeId => dispatch => {
  dispatch({ type: NetworkFiltersModule.actionKeys.setEmployeeId, payload: employeeId })
  return Promise.resolve()
})

NetworkFiltersModule.registerAction('setGraph', graphFilters => dispatch => {
  dispatch({ type: NetworkFiltersModule.actionKeys.setGraph, payload: graphFilters })
  return Promise.resolve()
})


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////

NetworkFiltersModule.registerReducer(
  NetworkFiltersModule.actionKeys.setSearch,
  (state, action) => ({ ...state, search: action.payload })
)

NetworkFiltersModule.registerReducer(
  NetworkFiltersModule.actionKeys.setEmployeeId,
  (state, action) => ({ ...state, selectedEmployeeId: action.payload })
)

NetworkFiltersModule.registerReducer(
  NetworkFiltersModule.actionKeys.setGraph,
  (state, action) => ({ ...state, graph: { ...state.graph, ...action.payload } })
)


export default NetworkFiltersModule
