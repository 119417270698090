import { serviceGetZoneStatsOvertime } from 'admin/services/locator'
import generateModule from 'common/utils/generateModule'


const { actions, reducers, initialState } = generateModule({
  itemName: 'sessionByGroupOvertime',
  autoRefresh: 3,
  getModuleState: (getState) => getState().admin.analytics.sessionsByGroupsOvertime,
  dataIsNotArray: true,
  services: {
    get: serviceGetZoneStatsOvertime,
  },
})

actions.getSessionByGroupsOvertime = (sessionId, zoneId, start, end) =>
  (dispatch, getState) => {
    dispatch(actions.getStart())
    return serviceGetZoneStatsOvertime(
      getState().admin.events.currentEvent.id,
      zoneId,
      'group',
      start,
      end,
      (response) => {
        const newData = { ...getState().admin.analytics.sessionsByGroupsOvertime.data }
        newData[sessionId] = response.timeseries.map(timeserie => {
          const data = {
            date: timeserie.date,
            count: timeserie.count,
          }

          const groups = {}
          timeserie.groups.forEach(group => {
            if (group.group) {
              groups[group.group] = group.count
            }
          })
          data.groups = groups

          return data
        })

        dispatch(actions.getSuccess(newData))
      },
      () => dispatch(actions.getError())
    )
  }

reducers['GET_SESSIONBYGROUPSOVERTIM_SUCCESS'] = (state, action) => {
  const newData = { ...state.data }
  newData[action.data.id] = { ...action.data.session }

  return {
    ...state,
    data: newData,
  }
}


reducers['CHANGE_EVENT_SUCCESS'] = (state) => ({
  ...state,
  didInvalidate: true,
  data: initialState.data,
})


export const getSessionByGroupsOvertime = actions.getSessionByGroupsOvertime

const sessionsByGroup = reducers.index
export default sessionsByGroup
