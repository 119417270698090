import { DataModule } from 'redux-data-module'
import { serviceGetBroadcast, serviceSetBroadcast, serviceDeleteBroadcast } from 'common/services/broadcast'


const BroadcastModule = new DataModule({
  moduleKey: 'broadcast',
  reducerKey: 'broadcast',
  initialData: {},
  services: {
    get: { service: serviceGetBroadcast },
    post: { service: serviceSetBroadcast },
    delete: { service: serviceDeleteBroadcast },
  },
});

BroadcastModule.registerSelector(
  'data',
  (state, moduleState) => moduleState.data,
  (data) => (!data || !data.message ? null : data)
)

BroadcastModule.reducers[BroadcastModule.actionKeys.postSuccess] = (state, action) => ({
  ...state,
  isModifying: false,
  data: { ...action.payload },
  lastUpdated: Date.now(),
})

BroadcastModule.reducers[BroadcastModule.actionKeys.deleteSuccess] = (state) => ({
  ...state,
  isModifying: false,
  data: BroadcastModule.initialData,
  lastUpdated: Date.now(),
})

export default BroadcastModule
