const TOOLTIP_DEFAULTS = {
  id: undefined,
  elementId: undefined,
  referenceId: undefined,
  button: false,
  content: undefined,
  popperOptions: {},
}

export default class Tooltip {
  constructor(options) {
    const initOptions = Object.assign({}, { ...TOOLTIP_DEFAULTS }, { ...options })

    this.id = initOptions.id
    this.elementId = initOptions.elementId
    this.referenceId = initOptions.referenceId
    this.button = initOptions.button
    this.onDismiss = initOptions.onDismiss
    this.content = initOptions.content
    this.popperOptions = initOptions.popperOptions
  }
}
