import React, { Component } from 'react'
import { getProperty, appendPropsClassName } from 'common/utils/componentHelper'
import { getTranslatedValue } from 'common/utils/translator'

import Select, { components } from 'react-select';
import CheckboxInput from 'common/components/Input/CheckboxInput'


export default class SelectDropdown extends Component {
  state = {
    cachedData: {},
  }

  componentWillMount() {
    this.cacheData()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.updateDependent) { // when dropdown is dependent on other dropdown change
      this.cacheData(nextProps)
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.menuIsOpen !== nextProps.menuIsOpen) return true
    if (this.props.updateDependent) return true; // needs to be updated based on other dropdown selection
    if (nextProps.value !== this.props.value) return true;
    if (nextProps.isDisabled !== this.props.isDisabled) return true;
    return false;
  }


  cacheData = (props = this.props) => {
    const { textField, valueField } = props
    const newCachedData = { ...this.state.cachedData }

    props.data.forEach(option => {
      const cached = this.state.cachedData[JSON.stringify(option[props.valueField])] || null

      const buildComparable = objectToCompare => ({
        [textField]: getProperty(objectToCompare, textField),
        [valueField]: getProperty(objectToCompare, valueField),
      })

      const optionComparable = buildComparable(option)
      const cachedComparable = buildComparable(cached)

      if (JSON.parse(JSON.stringify(optionComparable)) !== JSON.parse(JSON.stringify(cachedComparable))) {
        newCachedData[JSON.stringify(option[valueField])] = { ...option }
      }
    })

    this.setState({ cachedData: newCachedData })
  }

  getIcon = (data) => {
    const icon = typeof (data.icon) === 'string'
      ? <i className={`item-icon fa ${data.icon}`} />
      : data.icon;
    return <div className="drawing-mode-item"><div className="item-icon-wrapper">{icon}</div>{data[this.props.textField]}</div>
  }

  getValue = () => {
    const value = this.props.value === null ? null : this.props.value
    const obj = this.state.cachedData[JSON.stringify(value)]
    return this.props.icons ? { label: this.getIcon(obj), value: obj.value } : obj
  }

  getContainerClassName = () => {
    const { widthAuto, noMargin, inline } = this.props
    const className = appendPropsClassName('select-dropdown-container', this.props, 'containerClassName')
    return `${className} ${widthAuto ? 'width-auto' : ''} ${noMargin ? 'no-margin' : ''} ${inline ? 'inline' : ''}`
  }


  render() {
    const { data, className, id, onChange, textField, valueField, defaultValue, isMulti, closeOnSelect, placeholder, checkbox, isOptionSelected, menuIsOpen, menuPlacement, isDisabled, isSearchable, icons, styles, styles2 } = this.props

    const DropdownIndicator = props =>
      <components.DropdownIndicator {...props}>
        <i className={`fa fa-chevron-${props.selectProps.menuIsOpen ? 'up' : 'down'}`} />
      </components.DropdownIndicator >

    const SingleValue = props => (
      <components.SingleValue {...props}>
        {getTranslatedValue(props.data[textField])}
      </components.SingleValue>
    )

    let Option = props => (
      <components.Option {...props}>
        <span>{getTranslatedValue(props.data[textField])}</span>
      </components.Option>
    )

    if (checkbox) {
      Option = props =>
        <components.Option {...props}>
          <CheckboxInput label={getTranslatedValue(props.data[textField])} field={{ checked: props.isSelected, onChange: () => null }} />
        </components.Option >
    }

    if (icons) {
      Option = props =>
        <components.Option {...props}>
          {this.getIcon(props.data)}
        </components.Option >
    }

    if (styles) {
      Option = props =>
        <components.Option {...props}>
          <span style={{ fontFamily: this.props.fontFamilyByFontName[props.data[valueField]].default, pointerEvents: 'none' }}>{getTranslatedValue(props.data[textField])}</span>
        </components.Option >
    }
    if (styles2) {
      Option = props =>
        <components.Option {...props}>
          <span style={{ fontFamily: props.data[valueField], pointerEvents: 'none' }}>{getTranslatedValue(props.data[textField])}</span>
        </components.Option >
    }

    return (
      <div className={this.getContainerClassName()} >
        <Select
          id={id}
          classNamePrefix="klik-dropdown"
          className={className}
          components={{ DropdownIndicator, Option, SingleValue }}
          options={data}
          getOptionLabel={(option) => option[textField]}
          getOptionValue={(option) => option[valueField]}
          value={defaultValue || this.getValue() || null}
          onChange={onChange}
          isMulti={isMulti}
          placeholder={placeholder}
          closeMenuOnSelect={closeOnSelect}
          isOptionSelected={isOptionSelected}
          menuPlacement={menuPlacement}
          menuIsOpen={menuIsOpen}
          isDisabled={isDisabled}
          isSearchable={isSearchable}
        />
      </div>
    )
  }
}

SelectDropdown.defaultProps = {
  textField: 'label',
  valueField: 'value',
  menuIsOpen: undefined,
  menuPlacement: 'bottom',
  isSearchable: false,
  allOption: {
    label: 'Select all',
    value: 'All Values',
  },
}
