import { serviceGetActivities } from 'attendee/services/activities'
import generateModule from 'common/utils/generateModule'
import { cloneObject, getProperty } from 'common/utils/componentHelper'
import { favoriteSession, unfavoriteSession, sendFeedbackForSession } from './sessionRelations'
import { eventIdSelector } from './events'
import { createSelector } from 'reselect'


export const getActivityId = (activity) => {
  let id = `${activity.type}`
  id += activity.created_at ? `-${activity.created_at}` : ''
  return id
}

export const getActivityPoints = (activity, gameName = 'main') => getProperty(activity, `game_points.${gameName}`)

const selectState = (state) => state.attendee.activities
const selectActivities = (state) => selectState(state).data

export const activitiesSelector = createSelector(selectActivities, (data) => data)
export const sortedActivitiesSelector = createSelector(
  selectActivities,
  (data) => data.sort((a, b) => (b.activity_date - a.activity_date !== 0
    ? b.activity_date - a.activity_date
    : b.description.localeCompare(a.description)
  ))
)

export const activitiesByIdSelector = createSelector(
  selectActivities,
  (activities) => {
    const activitiesById = {}
    activities.forEach((activity) => {
      activitiesById[getActivityId(activity)] = activity
    })
    return activitiesById
  }
)

export const ActivityType = Object.freeze({ // Read-only object
  ACHIEVEMENT: 'achievement',
  ADD_CONTACT: 'add_contact',
  ATTEND_SESSION: 'attend_session',
  CLICK_SESSION: 'session_click',
  CLICK_TOUCHPOINT: 'touchpoint_click',
  COMPLETE_PROFILE: 'complete_registration',
  CUSTOM: 'custom',
  GAME_RULES: 'game_rules',
  REGISTER_SESSION: 'register_session',
  SEND_CONVERSATION_INVITATION: 'send_conversation_invitation',
  SEND_SESSION_FEEDBACK: 'send_session_feedback',
  TUTORIAL: 'tutorial',
  WELCOME: 'welcome',
})


const activities = generateModule({
  itemName: 'activitie',
  itemNameMessage: 'activities',
  autoRefresh: 60,
  services: {
    get: serviceGetActivities,
  },
  app: 'attendee',
})


activities.actions.get = (params) =>
  (dispatch, getState) => {
    const queryParams = {
      limit: getProperty(params, 'limit') || 30,
      before: getProperty(params, 'before') || null,
    }

    if (queryParams.before) {
      queryParams.before = parseInt(queryParams.before, 10)
    }

    dispatch(activities.actions.getStart())

    return serviceGetActivities(
      eventIdSelector(getState()),
      queryParams,
      (response) => {
        const currentActivities = activitiesSelector(getState())
        const newActivitiesById = {}
        response.forEach((activity) => {
          const activityId = getActivityId(activity)
          newActivitiesById[activityId] = activity
        })

        const updatedActivities = []
        for (let i = 0; i < currentActivities.length; i++) {
          const currentActivityId = getActivityId(currentActivities[i])

          if (newActivitiesById[currentActivityId]) {
            updatedActivities.push(newActivitiesById[currentActivityId])
            delete newActivitiesById[currentActivityId]
          } else {
            updatedActivities.push(currentActivities[i])
          }
        }
        Object.keys(newActivitiesById).forEach(id => { updatedActivities.push(newActivitiesById[id]) })

        dispatch(activities.actions.getSuccess(updatedActivities))
        return Promise.resolve(response.length < queryParams.limit)
      },
      (error) => dispatch(activities.actions.getError(error))
    )
  }


function findIndexOfActivity(allActivities, type, targetId) {
  for (let i = 0; i < allActivities.length; i++) {
    if (allActivities[i].type === type && allActivities[i].target.id === targetId) return i
  }
  return -1
}

activities.reducers['FAVORITE_SESSION'] = (state, action) => { // eslint-disable-line dot-notation
  const allActivities = state.data.slice()
  const activityIndex = findIndexOfActivity(allActivities, 'attend_session', action.payload.sessionId)
  if (activityIndex !== -1) allActivities[activityIndex].favorited = true

  return {
    ...state,
    data: allActivities,
  }
}

activities.reducers['UNFAVORITE_SESSION'] = (state, action) => { // eslint-disable-line dot-notation
  const allActivities = state.data.slice()
  const activityIndex = findIndexOfActivity(allActivities, 'attend_session', action.payload.sessionId)
  if (activityIndex !== -1) allActivities[activityIndex].favorited = false // eslint-disable-line no-unneeded-ternary

  return {
    ...state,
    data: allActivities,
  }
}

activities.reducers['UPDATE_CONTACT_RATINGS'] = (state, action) => {
  const allActivities = [...state.data]
  const activityIndex = findIndexOfActivity(allActivities, 'add_contact', action.payload.id)
  if (activityIndex !== -1) {
    allActivities[activityIndex].target.ratings = action.payload.ratings
  }

  return {
    ...state,
    data: allActivities,
  }
}

activities.reducers['UPDATE_CONTACT_NOTES'] = (state, action) => {
  const allActivities = [...state.data]
  const activityIndex = findIndexOfActivity(allActivities, 'add_contact', action.payload.id)
  if (activityIndex !== -1) {
    allActivities[activityIndex].target.notes = action.payload.notes
  }

  return {
    ...state,
    data: allActivities,
  }
}

activities.reducers['SEND_FEEDBACK_FOR_SESSION'] = (state, action) => { // eslint-disable-line dot-notation
  const newData = [...state.data]
  const activityIndex = findIndexOfActivity(newData, 'attend_session', action.payload.session.id)
  if (activityIndex !== -1 && newData[activityIndex].target.me) {
    const updatedActivity = cloneObject(newData[activityIndex])
    updatedActivity.target.me.feedback = {
      rating: action.payload.feedback.rating,
      feedback_id: action.payload.feedback.id,
      comments: action.payload.feedback.comments,
    }
    newData[activityIndex] = updatedActivity
  }

  return {
    ...state,
    data: newData,
  }
}

activities.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isFetching: false,
  isError: false,
  isLoaded: false,
  didInvalidate: true,
  data: [],
})

activities.reducers['DELETED_CONTACT'] = (state, action) => ({
  ...state,
  data: state.data.filter((activity) => activity.type !== 'add_contact' ||
    (
      activity.type === 'add_contact' &&
      (activity.target && activity.target.account && action.data.id.indexOf(activity.target.account.id) === -1)
    )
  ),
})


activities.actions.getActivities = activities.actions.get
activities.actions.favoriteSession = favoriteSession
activities.actions.unfavoriteSession = unfavoriteSession
activities.actions.sendFeedbackForSession = sendFeedbackForSession
export default activities
