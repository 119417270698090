import i18n from 'i18next'

export function safeScrollToTop(x = 0) {
  // On iOS, we have this "Universal Link" banner. If we scroll to (0, 0), the banner is not visible.
  // To work around this issue, we scroll past the 0 limit.
  window.scrollTo(x, -100)
}

export function isVerticalScrollbarVisible() {
  const height = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight)
  return (height > window.innerHeight)
}

export function getScrollbarWidth() {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = 'scroll';

  // add innerdiv
  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
}

export function getRealViewportWidth() {
  let w = window.innerWidth
  if (isVerticalScrollbarVisible()) {
    w -= getScrollbarWidth()
  }
  return w
}

export function getDocHeight() {
  const d = document
  return Math.max(
    d.body.scrollHeight, d.documentElement.scrollHeight,
    d.body.offsetHeight, d.documentElement.offsetHeight,
    d.body.clientHeight, d.documentElement.clientHeight
  )
}

export function getElementPosition(element) {
  let xPosition = 0
  let yPosition = 0

  while (element) {
    xPosition += ((element.offsetLeft - element.scrollLeft) + element.clientLeft)
    yPosition += ((element.offsetTop - element.scrollTop) + element.clientTop)
    element = element.offsetParent // eslint-disable-line no-param-reassign
  }

  return { x: xPosition, y: yPosition }
}


let hidden = ''
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden'
} else if (typeof document.mozHidden !== 'undefined') {
  hidden = 'mozHidden'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
}

export function isPageVisible() {
  return !document[hidden]
}

export function getBrowser(overrideUserAgent) {
  let browserName = ''
  let fullVersion = ''
  let majorVersion = 0

  const userAgent = overrideUserAgent || navigator.userAgent
  let verOffset = 0

  if (/Edge\/\d./i.test(userAgent)) {
    fullVersion = userAgent.substring(userAgent.indexOf('Edge/') + 5)
    browserName = 'edge'
  } else if (userAgent.indexOf('Chrome') !== -1) { // In Chrome, the true version is after 'Chrome'
    verOffset = userAgent.indexOf('Chrome')
    browserName = 'chrome'
    fullVersion = userAgent.substring(verOffset + 7)
  } else if (userAgent.indexOf('BB10') !== -1 || userAgent.indexOf('BlackBerry') !== -1) {
    browserName = 'blackberry'
    fullVersion = ''
  } else if (userAgent.indexOf('Firefox') !== -1) { // In Firefox, the true version is after 'Firefox'
    verOffset = userAgent.indexOf('Firefox')
    browserName = 'firefox'
    fullVersion = userAgent.substring(verOffset + 8)
  } else if (userAgent.indexOf('CriOS') !== -1) { // Chrome on iDevice
    verOffset = userAgent.indexOf('CriOS')
    browserName = 'chrome'
    fullVersion = userAgent.substring(verOffset + 6)
  } else if (userAgent.indexOf('MSIE ') >= 0 || userAgent.indexOf('Trident/') >= 0) {
    fullVersion = userAgent.substring(userAgent.indexOf('rv:11.0') + 3)
    browserName = 'ie'
  } else if (userAgent.indexOf('Safari') > -1) { // In Safari, the true version is after 'Safari' or after 'Version'
    verOffset = userAgent.indexOf('Safari')
    browserName = 'safari'
    fullVersion = userAgent.substring(verOffset + 7)
    if (userAgent.indexOf('Version') !== -1) {
      verOffset = userAgent.indexOf('Version')
      fullVersion = userAgent.substring(verOffset + 8)
    }
  } else if (userAgent.indexOf('AppleWebKit') > -1) {
    browserName = 'webkit'
    fullVersion = userAgent.substring(userAgent.indexOf('AppleWebKit/') + 12)
  }

  // trim the fullVersion string at semicolon/space if present
  if (fullVersion.indexOf(';') !== -1) {
    fullVersion = fullVersion.substring(0, fullVersion.indexOf(';'))
  }
  if (fullVersion.indexOf(' ') !== -1) {
    fullVersion = fullVersion.substring(0, fullVersion.indexOf(' '))
  }

  majorVersion = parseInt('' + fullVersion, 10)
  if (isNaN(majorVersion)) {
    fullVersion = '' + parseFloat(navigator.appVersion)
    majorVersion = parseInt(navigator.appVersion, 10)
  }

  return { name: browserName, version: majorVersion }
}

export function isIE() {
  return getBrowser().name === 'ie'
}

export function isIE9OrBelow() {
  return (/MSIE\s/.test(navigator.userAgent) && parseFloat(navigator.appVersion.split('MSIE')[1]) < 10)
}

export function isEdge() {
  return getBrowser().name === 'edge'
}

export function isSafari() {
  return getBrowser().name === 'safari'
}

export function isIDevice() {
  return navigator.platform.substr(0, 2) === 'iP'
}

export function isAndroid() {
  return navigator.userAgent.match(/Android/i)
}

export function isDesktop() {
  return !(navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/))
}

export function isWebview() {
  const ua = navigator.userAgent
  const iosWebview = ua.match(/(iPhone|iPod|iPad)/) && !ua.match(/Safari/)
  const androidWebview = ua.match(/Android/) && (ua.match('Version/') || ua.match('; wv'))
  return iosWebview || androidWebview
}

export function isBrowserSupported(overrideUserAgent) {
  const supportedVersions = {
    chrome: 57,
    firefox: 52,
    ie: 11,
    edge: 12,
    safari: 10,
    blackberry: 999,
    webkit: 602,
  }
  const browser = getBrowser(overrideUserAgent)
  return supportedVersions[browser.name] && browser.version >= supportedVersions[browser.name]
}


export function hexToRgb(hex) {
  const bigint = parseInt(hex.replace(/[^0-9A-F]/gi, ''), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return [r, g, b].join()
}

export function getTextColor(hex) {
  const rgb = parseInt(hex.substring(1), 16) // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff
  const g = (rgb >> 8) & 0xff
  const b = (rgb >> 0) & 0xff
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

  return (luma < 128) ? '#FFF' : '#000'
}

/*
  Copy text to clipboard

  sourceEvent: The DOM event triggered
  targetId: Target id (mostly inputs)
  animate: Should animate or not
*/
export function copyToClipboard(sourceEvent, targetId, animate = false) {
  const sourceTarget = sourceEvent.target
  const input = document.getElementById(targetId)

  // Checks if there's an input and if it's selectable
  if (input && input.select) {
    // Copy text
    input.select()
    document.execCommand('copy')
    input.blur()

    // Set animation
    if (animate) {
      sourceTarget.classList.remove('copied')
      sourceTarget.classList.remove('copied-' + i18n.language)

      // Triggers the re-animation
      sourceTarget.offsetWidth

      sourceTarget.classList.add('copied')
      sourceTarget.classList.add('copied-' + i18n.language)
    }
  }
}

export function loopThroughDOMElements(array, callback, reversed = false) {
  if (reversed) {
    for (let i = array.length - 1; i >= 0; i--) {
      callback(array[i], i)
    }
  } else {
    for (let i = 0; i < array.length; i++) {
      callback(array[i], i)
    }
  }
}

export function shadeBlend(percentage, color1, color2) {
  const shade = percentage < 0 ? percentage * -1 : percentage

  // RGB Values
  if (color1.length > 7) {
    const rgbValues = color1.split(',')
    const toColorValues = (color2 || percentage < 0 ? 'rgb(0,0,0)' : 'rgb(255,255,255)').split(',')

    const R = parseInt(rgbValues[0].slice(4), 10)
    const G = parseInt(rgbValues[1], 10)
    const B = parseInt(rgbValues[2], 10);

    return 'rgb(' +
      (parseInt((Math.round(toColorValues[0].slice(4)) - R) * shade, 10) + R) + ',' +
      (parseInt((Math.round(toColorValues[1]) - G) * shade, 10) + G) + ',' +
      (parseInt((Math.round(toColorValues[2]) - B) * shade, 10) + B) + ')'
  }

  // Hexadecimal Values
  const hexColor = parseInt(color1.slice(1), 16)
  const toColorValues = parseInt((color2 || percentage < 0 ? '#000000' : '#FFFFFF').slice(1), 16)

  const R = hexColor >> 16
  const G = hexColor >> 8 & 0x00FF
  const B = hexColor & 0x0000FF;

  return '#' +
    (0x1000000 + (Math.round(((toColorValues >> 16) - R) * shade) + R)
    * 0x10000 + (Math.round(((toColorValues >> 8 & 0x00FF) - G) * shade) + G)
    * 0x100 + (Math.round(((toColorValues & 0x0000FF) - B) * shade) + B)).toString(16).slice(1)
}

export function setGradient(elementId, fromColor, toColor) {
  const prefixes = ['-o-', '-ms-', '-moz-', '-webkit-']

  const color1 = toColor || shadeBlend(0.60, fromColor)
  const color2 = fromColor

  const element = document.getElementById(elementId)
  if (element) {
    prefixes.some((p) => {
      element.style.background = `${p}linear-gradient(${color1}, ${color2})`
      return element.style.background
    })

    // Remove prefixes in case none of them worked so we don't have an invalid property
    element.style.background = `linear-gradient(${color1}, ${color2})`
  }
}

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  const byteString = (dataURI.split(',')[0].indexOf('base64') >= 0) ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1])
  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], { type: mimeString })
}

// Prevent from scrolling parent elements
export function lockScroll(elementId) {
  let elements = document.querySelectorAll(`#${elementId}`)
  if (elements.length === 0) {
    elements = document.querySelectorAll(`.${elementId}`)
  }

  /* eslint-disable no-param-reassign */
  loopThroughDOMElements(elements, (element) => {
    const isMacWebkit = (navigator.userAgent.indexOf('Macintosh') !== -1 && navigator.userAgent.indexOf('WebKit') !== -1)
    const isFirefox = (navigator.userAgent.indexOf('firefox') !== -1)

    const wheelHandler = (event) => {
      const e = event || window.event

      let deltaY = e.deltaY * -30 || e.wheelDeltaY / 4 || (e.wheelDeltaY === undefined && e.wheelDeltaY / 4) || e.detail * -10 || 0

      if (isMacWebkit) {
        deltaY /= 30
      }

      e.currentTarget.scrollTop -= deltaY

      if (isFirefox && e.type !== 'DOMMouseScroll') element.removeEventListener('DOMMouseScroll', wheelHandler, false)

      if (e.preventDefault) e.preventDefault()
      if (e.stopPropagation) e.stopPropagation()
      e.cancelBubble = true
      e.returnValue = false
      return false
    }

    element.onwheel = wheelHandler
    element.onmousewheel = wheelHandler
    if (isFirefox) {
      element.scrollTop = 0
      element.addEventListener('DOMMouseScroll', wheelHandler, false)
    }
  })
  /* eslint-enable */
}


/*
  Insert content into an invisible copy of the parent container and return
  the height of the content
 */
export function getContentSize(container, content) {
  const contentCopy = content.cloneNode(true)

  // Allow us to get the actual height
  contentCopy.style.maxHeight = 'none'
  contentCopy.style.maxWidth = 'none'

  // Make sure we won't see a flicker
  contentCopy.style.visibility = 'hidden'

  // Add an invisible copy of the content, get the height then remove the copy
  container.appendChild(contentCopy)
  const contentHeight = contentCopy.clientHeight
  const contentWidth = contentCopy.getBoundingClientRect().width
  container.removeChild(contentCopy)

  return { height: contentHeight, width: contentWidth }
}


/*
  Search and return the DOM node

  id: Can be an id, class or selector ('.class', '#id', 'classOrId')
  all: Should return all or one value? [Default: false]
*/
export function getElement(id, all = false) {
  const queryElement = all ? document.querySelectorAll : document.querySelector

  if (id.startsWith('#') || id.startsWith('.')) return queryElement(id)

  let element = queryElement(`#${id}`)
  if (!element) element = queryElement(`.${id}`)

  return element
}

/**
 * Returns selected text
 */
export function getSelection() {
  let text = '';

  if (typeof window.getSelection !== 'undefined') {
    text = window.getSelection().toString();
  } else if (typeof document.selection !== 'undefined' && document.selection.type === 'Text') {
    text = document.selection.createRange().text;
  }

  return text;
}


/**
 * Checks if an element is visible on the screen
 *
 * @param element DOM element
 */
export function isElementVisibleOnScreen(element) {
  if (!element) {
    return false
  }

  const rect = element.getBoundingClientRect()
  const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
}


export function getElementWidth(element, include = { margin: true, padding: true, border: true }) {
  const style = element.currentStyle || window.getComputedStyle(element)
  const width = parseFloat(element.offsetWidth)
  const margin = (parseFloat(style.marginLeft) || 0) + (parseFloat(style.marginRight) || 0)
  const padding = (parseFloat(style.paddingLeft) || 0) + (parseFloat(style.paddingRight) || 0)
  const border = (parseFloat(style.borderWidthLeft) || 0) + (parseFloat(style.borderWidthRight) || 0)

  return width + (include.margin ? margin : 0) + (include.padding ? padding : 0) + (include.border ? border : 0)
}

export function getElementHeight(element, include = { margin: true, padding: true, border: true }) {
  const style = element.currentStyle || window.getComputedStyle(element)
  const height = parseFloat(element.offsetHeight)
  const margin = (parseFloat(style.marginTop) || 0) + (parseFloat(style.marginBottom) || 0)
  const padding = (parseFloat(style.paddingTop) || 0) + (parseFloat(style.paddingBottom) || 0)
  const border = (parseFloat(style.borderWidthTop) || 0) + (parseFloat(style.borderWidthBottom) || 0)

  return height + (include.margin ? margin : 0) + (include.padding ? padding : 0) + (include.border ? border : 0)
}


export function swapElements(elementA, elementB) {
  const parentB = elementB.parentNode;
  const nextB = elementB.nextSibling;

  if (nextB === elementA) {
    parentB.insertBefore(elementA, elementB);
  } else {
    elementA.parentNode.insertBefore(elementB, elementA);

    if (nextB) {
      parentB.insertBefore(elementA, nextB);
    } else {
      parentB.appendChild(elementA);
    }
  }
}

export function applyTransforms(element, transforms) {
  let transformString = ''

  transforms.forEach(transform => {
    if (transform) transformString += ` ${transform}`
  })

  element.style.transform = transformString // eslint-disable-line no-param-reassign
}

export function findElementInHierarchy(event, elementToFind) {
  if (event.path) {
    return event.path.find(e => e === elementToFind)
  }

  let node = event.target
  while (node && node !== document.body) {
    if (node === elementToFind) return node
    node = node.parentNode
  }

  return null
}
