import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceGetActivities(eventId, query, success, error) {
  let queryParamString = ''
  Object.keys(query).forEach(key => {
    if (query[key]) queryParamString += `&${key}=${query[key]}`
  })

  return axios.get(`/events/${eventId}/attendees/me/activities?${queryParamString}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
