import { serviceGetEvent, serviceGetEventFeatures } from 'admin/services/events'
import { getProperty } from 'common/utils/componentHelper'
import generateModule from 'common/utils/generateModule'
import { createSelector } from 'reselect'

const selectEventData = (state) => state.exhibitor.event.data

export const selectors = {
  eventLanguage: createSelector(selectEventData, (event) => getProperty(event, 'language')),
  availableLanguages: createSelector(selectEventData, (event) => getProperty(event, 'available_languages') || []),
  eventId: createSelector(selectEventData, (event) => getProperty(event, 'id')),
}


const { actions, reducers, initialState } = generateModule({
  itemName: 'event',
  getModuleState: (getState) => getState().exhibitor.event,
  idFieldName: 'id',
  app: 'exhibitor',
  dataIsNotArray: true,
  services: {},
})

initialState.features = {}


actions.selectEvent = (eventId) =>
  (dispatch) => {
    const getEvent = () => serviceGetEvent(
      eventId,
      (response) => Promise.resolve(response),
      (error) => Promise.reject(error.description)
    )

    const getFeatures = () => serviceGetEventFeatures(
      eventId,
      (response) => Promise.resolve(response),
      (error) => Promise.reject(error.description)
    )

    return Promise.all([getEvent(), getFeatures()])
      .then((values) => {
        const event = values[0]
        const features = values[1]

        dispatch({ type: 'CPE_SELECT_EVENT', payload: { event, features } })
      })
      .catch(() => dispatch({ type: 'CPE_SELECT_EVENT_ERROR' }))
  }


reducers['CPE_SELECT_EVENT'] = (state, action) => ({
  ...state,
  data: action.payload.event,
  features: action.payload.features,
})


export const selectEvent = actions.selectEvent

const event = reducers.index
export default event
