import { getProperty } from 'common/utils/componentHelper'
import { getTranslatedValue } from 'common/utils/translator'


export default function search(searchString, fieldsList) {
  const terms = searchString.toLowerCase().split(' ').map(t => t.trim()).filter(t => t)
  const fields = fieldsList.map(f => f.toLowerCase())

  // EVERY term should be present in SOME field
  return terms.every(t => fields.some(f => f.indexOf(t) > -1))
}


/**
 * Returns an array with the values for every fields in the object provided. It is possible
 * to pass a field like "field.subfield".
 *
 * @param object - Object to use
 * @param fields - Array of fields that you want
 * @return list - Array of values
 */
export function buildFieldList(object, fields) {
  if (!object) {
    return []
  }

  const list = []

  fields.forEach(field => {
    const value = getTranslatedValue(getProperty(object, field))
    if (value) list.push(value)
  })

  return list
}
