import axios from 'axios'
import { buildError } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceGetInfoPopups(eventId) {
  return axios.get(`/events/${eventId}/info_popups`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}
