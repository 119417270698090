import { serviceGetVenueMaps } from 'attendee/services/maps'
import generateModule from 'common/utils/generateModule'
import { listToMapping } from 'common/utils/componentHelper'
import { createSelector } from 'reselect'

// ////////////////////////////////////////////////////////////////////////////
// Selectors
// ////////////////////////////////////////////////////////////////////////////

const selectMaps = state => state.attendee.attVenueMaps.data

export const mapsSelector = createSelector(selectMaps, maps => maps)

export const mapsByIdSelector = createSelector(selectMaps, maps => listToMapping(maps, 'id'))

export const publicMapsSelector = createSelector(selectMaps, maps => maps.filter(map => !map.private))

export const publicMapsByIdSelector = createSelector(publicMapsSelector, maps => listToMapping(maps, 'id'))

export const mapsDropdownDataSelector = createSelector(publicMapsSelector, maps => maps.map(map => ({ label: map.name, value: map.id })))


// ////////////////////////////////////////////////////////////////////////////
// Module
// ////////////////////////////////////////////////////////////////////////////

const attVenueMaps = generateModule({
  itemName: 'attVenueMap',
  itemNameDisplay: 'venueMap',
  toasterFieldName: 'name',
  idFieldName: 'id',
  autoRefresh: 60,
  services: {
    get: serviceGetVenueMaps,
  },
  app: 'attendee',
})

attVenueMaps.actions.updateMapViewPreferences = (prefs) =>
  (dispatch) => {
    dispatch(((p) => ({
      type: 'UPDATE_MAP_VIEW_PREFERENCES',
      data: { prefs: p },
    }))(prefs))
  }

attVenueMaps.reducers['UPDATE_MAP_VIEW_PREFERENCES'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  prefs: {
    ...state.prefs,
    ...action.data.prefs,
  },
})

attVenueMaps.initialState.prefs = {
  map: true,
  zones: true,
}

attVenueMaps.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isLoaded: false,
  isError: false,
  didInvalidate: true,
  data: [],
})

attVenueMaps.actions.getVenueMaps = attVenueMaps.actions.get
export default attVenueMaps
