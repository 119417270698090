import axios from 'axios'
import { buildError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { getAxiosConfig } from './helper'


export function translateLocation(data) {
  return {
    ...data,
    display_name: getTranslatedObject(data.display_name),
  }
}

export function translateMap(data) {
  return {
    ...data,
    name: getTranslatedObject(data.name),
    image_url: getTranslatedObject(data.image_url),
  }
}


export function serviceGetMaps(eventId) {
  return axios.get(`/events/${eventId}/maps`, getAxiosConfig())
    .then(response => response.data.map(translateMap))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetLocations(eventId) {
  return axios.get(`/events/${eventId}/locations`, getAxiosConfig())
    .then(response => response.data.map(translateLocation))
    .catch(error => Promise.reject(buildError(error)))
}
