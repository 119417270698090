import axios from 'axios'
import { buildError } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceGetAllContactInvitations(eventId) {
  const getReceivedInvitations = () => axios.get(
    `/events/${eventId}/attendees/me/invitations/contact`,
    getAxiosConfig(),
  )
    .then((response) => response.data)

  const getSentInvitations = () => axios.get(
    `/events/${eventId}/attendees/me/invitations/contact/sent`,
    getAxiosConfig(),
  )
    .then((response) => response.data)

  return Promise.all([
    getReceivedInvitations(),
    getSentInvitations(),
  ])
    .catch((error) => Promise.reject(buildError(error)))
}

export function serviceCreateContactInvitation(eventId, accountId) {
  return axios.post(`/events/${eventId}/attendees/${accountId}/invitations/contact`, {}, getAxiosConfig())
    .then((response) => response.data)
    .catch((error) => Promise.reject(buildError(error)))
}

export function serviceAcceptContactInvitation(eventId, invitationId) {
  return axios.post(`/events/${eventId}/attendees/me/invitations/contact/${invitationId}/accept`, {}, getAxiosConfig())
    .then((response) => response.data)
    .catch((error) => Promise.reject(buildError(error)))
}

export function serviceRejectContactInvitation(eventId, invitationId) {
  return axios.post(`/events/${eventId}/attendees/me/invitations/contact/${invitationId}/reject`, {}, getAxiosConfig())
    .then((response) => response.data)
    .catch((error) => Promise.reject(buildError(error)))
}

export function serviceReinviteContactInvitation(eventId, invitationId) {
  return axios.post(`/events/${eventId}/attendees/me/invitations/contact/${invitationId}/reinvite`, {}, getAxiosConfig())
    .then((response) => response.data)
    .catch((error) => Promise.reject(buildError(error)))
}
