export default function initializeActions(MapGraphicsModule) {
  const getCurrentSelection = (state, mapId, type) => (type === 'zone'
    ? MapGraphicsModule.selectors.selectedZonesById(state, mapId)
    : MapGraphicsModule.selectors.selectedInfrasById(state, mapId)
  )

  MapGraphicsModule.registerAction(
    'setDrawingMode',
    (mapId, mode) => dispatch => dispatch({
      type: MapGraphicsModule.actionKeys.setDrawingMode,
      payload: { mapId, mode },
    })
  )

  MapGraphicsModule.registerAction(
    'setSelection',
    (mapId, selection) => dispatch => dispatch({
      type: MapGraphicsModule.actionKeys.setSelection,
      payload: {
        mapId,
        selection: Array.isArray(selection) ? selection : [selection],
      },
    })
  )

  MapGraphicsModule.registerAction(
    'addToSelection',
    (mapId, dataId, type) => (dispatch, getState) => {
      const currentSelection = getCurrentSelection(getState(), mapId, type)

      if (!currentSelection[dataId]) {
        dispatch({
          type: MapGraphicsModule.actionKeys.addToSelection,
          payload: {
            mapId,
            selection: { dataId, type },
          },
        })
      }
    }
  )

  MapGraphicsModule.registerAction(
    'removeFromSelection',
    (mapId, dataId, type) => (dispatch, getState) => {
      const currentSelection = getCurrentSelection(getState(), mapId, type)

      if (currentSelection[dataId]) {
        dispatch({
          type: MapGraphicsModule.actionKeys.removeFromSelection,
          payload: { mapId, dataId, type },
        })
      }
    }
  )

  MapGraphicsModule.registerAction(
    'clearSelection',
    (mapId) => dispatch => dispatch({
      type: MapGraphicsModule.actionKeys.clearSelection,
      payload: mapId,
    })
  )
}
