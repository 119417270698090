import fullAccessPermissions from './FullAccess'

export default {
  action: {
    ...fullAccessPermissions.action,
  },

  menu: {
    ...fullAccessPermissions.menu,
    analytics: false,
    checkIn: false,
    communication: false,
    dashboard: false,
    event: false,
    exhibitors: false,
    gamification: false,
    networking: false,
    pages: false,
    registration: false,
    schedule: false,
    speakers: false,
    system: false,
    users: false,
  },

  page: {
    ...fullAccessPermissions.page,

    analyticsEngagement: false,
    analyticsExports: false,
    analyticsGamification: false,
    analyticsMultiEvent: false,
    analyticsSummary: false,

    dashboardEngagement: false,
    dashboardOnboarding: false,
    dashboardStatistics: false,

    floorPlanTouchpoints: false,
  },
}
