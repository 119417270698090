import { addTimeFilterSupport } from './helper'
import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetAppTrafficStats } from 'admin/services/dashboard'
import { eventEndDateSelector, eventTimezoneSelector } from 'admin/modules/events'
import Moment from 'moment'


const initialDataState = {
  stats: { logins: 0, visits: 0 },
  overtime: [],
}

const AppTrafficModule = generateDataModule({
  initialData: {
    full: initialDataState,
    minimal: initialDataState,
    total: initialDataState,
  },
  initialState: {},
  moduleKey: 'dashboardAppTraffic',
  reducerKey: 'admin.dashboard.appTraffic',
  services: {
    get: { service: serviceGetAppTrafficStats },
  },
})

addTimeFilterSupport(AppTrafficModule, true)


AppTrafficModule.registerSelector('fullData', (state, moduleState) => moduleState.data.full, data => data)
AppTrafficModule.registerSelector('fullOvertime', state => AppTrafficModule.selectors.fullData(state), data => data.overtime)
AppTrafficModule.registerSelector('fullStats', state => AppTrafficModule.selectors.fullData(state), data => data.stats)

AppTrafficModule.registerSelector('minimalData', (state, moduleState) => moduleState.data.minimal, data => data)
AppTrafficModule.registerSelector('minimalOvertime', state => AppTrafficModule.selectors.minimalData(state), data => data.overtime)
AppTrafficModule.registerSelector('minimalStats', state => AppTrafficModule.selectors.minimalData(state), data => data.stats)

AppTrafficModule.registerSelector('totalData', (state, moduleState) => moduleState.data.total, data => data)
AppTrafficModule.registerSelector('totalOvertime', state => AppTrafficModule.selectors.totalData(state), data => data.overtime)
AppTrafficModule.registerSelector('totalStats', state => AppTrafficModule.selectors.totalData(state), data => data.stats)
AppTrafficModule.registerSelector('totalAverageVisitsPerLogins', state => AppTrafficModule.selectors.totalStats(state), data => data.average_visits_per_logins)


AppTrafficModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {
    if (!AppTrafficModule.selectors.areTimeFiltersInitialized(getState())) {
      dispatch(AppTrafficModule.actions.initializeFilters())
      dispatch(AppTrafficModule.actions.initializeMinimalFilters())
    }

    const state = getState()
    const timeFilters = AppTrafficModule.selectors.timeFilters(state)
    const timezone = eventTimezoneSelector(state)
    const currentMoment = Moment.unix(Moment().unix()).tz(timezone)
    const endDayOfEventEnd = Moment.unix(eventEndDateSelector(state)).tz(timezone).endOf('day').unix()
    const service = AppTrafficModule._getService('get')

    const getMinimalData = () => service({
      startDate: currentMoment.startOf('day').unix(),
      endDate: undefined,
    }, timezone)

    const getFullData = () => service(timeFilters, timezone)
    const getTotalData = () => service({ endDate: endDayOfEventEnd }, timezone)

    dispatch({ type: AppTrafficModule.actionKeys.getStart })
    return Promise.all([getMinimalData(), getFullData(), getTotalData()])
      .then(responses => {
        dispatch({
          type: AppTrafficModule.actionKeys.getSuccess,
          payload: {
            full: responses[1],
            minimal: responses[0],
            total: responses[2],
          },
        })
      })
      .catch(() => {
        dispatch({ type: AppTrafficModule.actionKeys.getError })
      })
  }
)

AppTrafficModule.registerReducer(AppTrafficModule.actionKeys.getSuccess, (state, action) => ({
  ...state,
  data: action.payload,
  isLoading: false,
  isLoaded: true,
  lastUpdated: Date.now(),
}))


export default AppTrafficModule

