import { serviceGetDomains } from 'admin/services/domains'
import generateModule from './generateModule'
import { createSelector } from 'reselect'

export const domainsSelector = createSelector(state => state.admin.domains.data, domains => domains)


const module = generateModule({
  moduleKey: 'domains',
  services: {
    get: {
      service: serviceGetDomains,
      showOnError: true,
    },
  },
})

export default module
