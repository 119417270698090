const initialState = {
  online: true,
  notification: false,
  pulseNotification: false,
  isFetching: false,
  isModifying: false,
  isError: false,
  isLoaded: true,
  isRehydrated: false,
  didInvalidate: false,
}

function changeOnlineStatus(status) {
  return {
    type: 'OFFLINE_CHANGE_ONLINE_STATUS',
    data: { online: status },
  }
}

function handle(callback, result, dispatch) {
  if (window.requestAnimationFrame) {
    window.requestAnimationFrame(() => dispatch(callback(result)))
  } else {
    setTimeout(() => dispatch(callback(result)), 0)
  }
}

export function detectNetworkStatus(dispatch) {
  if (typeof window !== 'undefined' && window.addEventListener) {
    window.addEventListener('online', () => handle(changeOnlineStatus, true, dispatch))
    window.addEventListener('offline', () => handle(changeOnlineStatus, false, dispatch))
    handle(changeOnlineStatus, window.navigator.onLine, dispatch)
  }
}


export function dismissOfflineNotification() {
  return {
    type: 'OFFLINE_DISMISS_NOTIFICATION',
  }
}

export function pulseOfflineNotification() {
  return {
    type: 'OFFLINE_PULSE_NOTIFICATION',
  }
}

export function pulseOfflineNotificationOr(dispatch, getState, error, execute) {
  const errorOffline = error && error.offline;
  const stateOffline = !getState().offlineNotification.online;

  (errorOffline || stateOffline) ? dispatch(pulseOfflineNotification()) : dispatch(execute())
}

export function dismissOfflinePulseNotification() {
  return {
    type: 'OFFLINE_DISMISS_PULSE_NOTIFICATION',
  }
}

export function offline(state = initialState, action) {
  switch (action.type) {
    case 'OFFLINE_CHANGE_ONLINE_STATUS':
      return {
        ...state,
        online: action.data.online,
        notification: !action.data.online,
      }
    case 'Offline/STATUS_CHANGED':
      return {
        ...state,
        notification: !action.payload.online,
      }
    case 'OFFLINE_DISMISS_NOTIFICATION':
      return {
        ...state,
        notification: false,
      }
    case 'OFFLINE_PULSE_NOTIFICATION':
      return {
        ...state,
        notification: true,
        pulseNotification: true,
      }
    case 'OFFLINE_DISMISS_PULSE_NOTIFICATION':
      return {
        ...state,
        pulseNotification: false,
      }
    case 'persist/REHYDRATE':
      return { ...state }
    default:
      return state
  }
}
