import { serviceGetGameLog } from 'attendee/services/gamification'
import generateModule from 'common/utils/generateModule'

const gameLog = generateModule({
  itemName: 'gameLog',
  itemNameDisplay: 'gameLog',
  getModuleState: (getState) => getState().attendee.gameLog,
  autoRefresh: 60,
  services: {
    get: serviceGetGameLog,
  },
  app: 'attendee',
})


gameLog.actions.get = () =>
  (dispatch, getState) => {
    dispatch(gameLog.actions.getStart())
    return serviceGetGameLog(
      getState().attendee.attEvents.currEvent.id,
      getState().attendee.games.currentGame.id,
      (response) => dispatch(gameLog.actions.getSuccess(response)),
      (error) => dispatch(gameLog.actions.getError(error))
    )
  }


gameLog.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isError: false,
  isLoaded: false,
  didInvalidate: true,
  data: [],
})

gameLog.actions.getGameLog = gameLog.actions.get
export default gameLog
