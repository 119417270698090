import { DataModule } from 'redux-data-module'
import { serviceGetMaps } from 'exhibitor/services/maps'
import { selectors as eventSelectors } from 'exhibitor/modules/event'


const MapsModule = new DataModule({
  moduleKey: 'cpe/maps',
  reducerKey: 'exhibitor.maps',

  services: {
    get: { service: serviceGetMaps },
  },
})

MapsModule.actions.get = () => (dispatch, getState) => {
  dispatch({ type: MapsModule.actionKeys.getStart })

  return serviceGetMaps(eventSelectors.eventId(getState()))
    .then(response => {
      dispatch({ type: MapsModule.actionKeys.getSuccess, payload: response })
      return Promise.resolve()
    })
    .catch(() => {
      dispatch({ type: MapsModule.actionKeys.getError })
      return Promise.reject()
    })
}


export default MapsModule
