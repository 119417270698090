import config from 'config'
import { getAccessToken } from 'common/services/helper'


export function getAxiosConfig() {
  return {
    baseURL: config.apiUrl,
    headers: {
      'Accept-Language': 'mul',
      Authorization: `Bearer ${getAccessToken()}`,
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json; charset=utf-8',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      Pragma: 'no-cache',
    },
  }
}

export function buildQueryParams(_query) {
  const query = { ..._query }
  const queryKeys = Object.keys(query)
  let queryString = '?'

  if (queryKeys.length === 0 && query.constructor === Object) {
    return ''
  }

  queryKeys.forEach(key => {
    if (query[key] != null) {
      queryString += `&${key}=${query[key]}`
    }
  })

  return queryString
}
