import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceGetTags(eventId, success, error) {
  return axios.get(`events/${eventId}/tags/sessions?limit=1000`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetSessions(eventId, success, error) {
  return axios.get(`/events/${eventId}/attendees/me/sessions`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetCalendar(eventId, success, error) {
  return axios.get(`/events/${eventId}/attendees/me/calendar?include_system_calendars=true&include_event_calendars=false`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceFavoriteSession(eventId, attendeeId, sessionId, success, error) {
  return axios.put(`/events/${eventId}/attendees/${attendeeId}/sessions/${sessionId}/favorite`, {}, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUnfavoriteSession(eventId, attendeeId, sessionId, success, error) {
  return axios.delete(`/events/${eventId}/attendees/${attendeeId}/sessions/${sessionId}/favorite`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceAddToAgenda(eventId, sessionId, success, error) {
  return axios.put(`/events/${eventId}/attendees/me/sessions/${sessionId}/interest`, {}, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceRemoveFromAgenda(eventId, sessionId, success, error) {
  return axios.delete(`/events/${eventId}/attendees/me/sessions/${sessionId}/interest`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceConfirmAttendanceToSession(eventId, sessionId, success, error) {
  return axios.put(`/events/${eventId}/attendees/me/sessions/${sessionId}/going`, {}, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceCancelAttendanceToSession(eventId, sessionId, success, error) {
  return axios.delete(`/events/${eventId}/attendees/me/sessions/${sessionId}/going`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceConfirmAppointmentReservation(eventId, sessionId, appointmentId, isReplacing, success, error) {
  const requestPayload = { appointment_id: appointmentId }
  if (isReplacing) requestPayload.replace_session = sessionId

  return axios.put(`/events/${eventId}/attendees/me/sessions/${sessionId}/going`, requestPayload, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceCancelAppointmentReservation(eventId, sessionId, appointmentId, success, error) {
  return axios.delete(`/events/${eventId}/attendees/me/sessions/${sessionId}/going?appointment_id=${appointmentId}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetFeedback(eventId, feedbackId, success, error) {
  return axios.get(`/events/${eventId}/feedback/${feedbackId}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceSendFeedbackForSession(eventId, sessionId, feedback, success, error) {
  return axios.put(`/events/${eventId}/attendees/me/sessions/${sessionId}/feedback`, feedback, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceAcceptInvitation(eventId, sessionId, success, error) {
  return axios.post(`/events/${eventId}/attendees/me/sessions/${sessionId}/invite`, { action: 'accept' }, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeclineInvitation(eventId, sessionId, success, error) {
  return axios.post(`/events/${eventId}/attendees/me/sessions/${sessionId}/invite`, { action: 'decline' }, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetAppointments(eventId, calendarId, sessionId, shouldFilterOutConflicting, success, error) {
  let queryParams = ''
  if (shouldFilterOutConflicting) queryParams += 'available=true'

  return axios.get(`/events/${eventId}/attendees/me/sessions/${sessionId}/appointments?${queryParams}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceGetSyncCalendarLink(eventId) {
  return axios.get(`/events/${eventId}/attendees/me/calendar_url`, getAxiosConfig())
    .then((response) => response.data)
    .catch((error) => console.error(error))
}

export function serviceIncrementSessionAttendance(eventId, sessionId, increment) {
  return axios.put(`/events/${eventId}/attendees/me/sessions/${sessionId}/virtually_attend?inc=${increment}`, {}, getAxiosConfig())
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      return Promise.reject(error)
    })
}
