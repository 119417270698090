import { serviceGetExhibitor, serviceUpdateExhibitor, serviceGetManagedExhibitors, serviceGetRepresentativesForExhibitor, serviceDeleteRepresentative, serviceAddRepresentative, serviceResetExhibitorAdminPassword, serviceAddExhibitorAdmin, serviceDeleteExhibitorAdmin } from 'admin/services/exhibitors'
import { serviceUploadAsset } from 'admin/services/events'
import { browserHistoryPush } from 'common/components/Link'
import { getProperty } from 'common/utils/componentHelper'
import generateModule from 'common/utils/generateModule'
import { showToast } from 'common/modules/toaster'
import { createSelector } from 'reselect'
import i18n from 'i18next'
import { selectEvent } from './event'


function getEventId(getState) {
  return getState().exhibitor.exhibitors.currentExhibitor.event_id
}

function isCurrentExhibitor(state, exhibitorId) {
  return (!state.currentExhibitor || state.currentExhibitor.id === exhibitorId)
}

const selectExhibitorData = (state) => state.exhibitor.exhibitors.currentExhibitor

export const selectors = {
  exhibitorId: createSelector(selectExhibitorData, (exhibitor) => getProperty(exhibitor, 'id')),
}

const { actions, reducers, initialState } = generateModule({
  itemName: 'exhibitor',
  getModuleState: (getState) => getState().exhibitor.exhibitors,
  getEventId: getEventId,
  idFieldName: 'id',
  app: 'exhibitor',
  services: {
    get: serviceGetManagedExhibitors,
  },
})

initialState.currentExhibitor = undefined
initialState.assetUploadInProgress = []
initialState.representatives = []


actions.getExhibitors = () =>
  dispatch => {
    dispatch({ type: 'CPE_GET_EXHIBITORS_START' })

    return serviceGetManagedExhibitors()
      .then(response => {
        dispatch({ type: 'CPE_GET_EXHIBITORS_SUCCESS', payload: { exhibitors: response } })
        return Promise.resolve(response)
      })
      .catch(() => {
        dispatch({ type: 'CPE_GET_EXHIBITORS_ERROR' })
        return Promise.reject()
      })
  }

reducers['CPE_GET_EXHIBITORS_START'] = (state) => ({
  ...state,
  isFetching: true,
  isError: false,
})

reducers['CPE_GET_EXHIBITORS_SUCCESS'] = (state, action) => ({
  ...state,
  isFetching: false,
  data: action.payload.exhibitors,
})

reducers['CPE_GET_EXHIBITORS_ERROR'] = (state) => ({
  ...state,
  isFetching: false,
  isError: false,
  data: [],
})


actions.getExhibitor = (exhibitorId, eventId) =>
  (dispatch, getState) => {
    dispatch({ type: 'CPE_GET_EXHIBITOR_START' })
    const _eventId = eventId || getEventId(getState)

    const getExhibitor = () => serviceGetExhibitor(_eventId, exhibitorId)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.description))

    const getRepresentatives = () => serviceGetRepresentativesForExhibitor(_eventId, exhibitorId)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.description))

    return Promise.all([getExhibitor(), getRepresentatives()])
      .then((values) => {
        const exhibitor = values[0]
        const representatives = values[1]

        dispatch({ type: 'CPE_GET_EXHIBITOR_SUCCESS', payload: { exhibitor, representatives } })
        return Promise.resolve(exhibitor)
      })
      .catch(() => dispatch({ type: 'CPE_GET_EXHIBITOR_ERROR' }))
  }

reducers['CPE_GET_EXHIBITOR_SUCCESS'] = (state, action) => {
  const shouldUpdateCurrentExhibitor = isCurrentExhibitor(state, action.payload.exhibitor.id)

  return {
    ...state,
    data: state.data.find(d => d.id === action.payload.exhibitor.id) ? state.data : [...state.data, action.payload.exhibitor],
    currentExhibitor: shouldUpdateCurrentExhibitor ? action.payload.exhibitor : state.currentExhibitor,
    representatives: shouldUpdateCurrentExhibitor ? action.payload.representatives : state.representatives,
  }
}


actions.selectExhibitor = (exhibitorId) =>
  (dispatch, getState) => {
    const matchingExhibitor = getState().exhibitor.exhibitors.data.find(d => d.id === exhibitorId)
    const eventId = matchingExhibitor.event_id

    return dispatch(selectEvent(eventId))
      .then(() => {
        dispatch({ type: 'CPE_SELECT_EXHIBITOR', payload: { exhibitor: matchingExhibitor } })
        dispatch(actions.getExhibitor(exhibitorId))
      })
      .then(() => {
        // Matches 3 groups (prefix, part we want to change, the rest)
        browserHistoryPush(window.location.pathname.replace(/(\/admin\/exhibitors)(\/[^/]+\/[^/]+)?(\/.+)?/, `$1/${eventId}/${exhibitorId}$3`) + window.location.search)
      })
  }

reducers['CPE_SELECT_EXHIBITOR'] = (state, action) => ({
  ...state,
  currentExhibitor: action.payload.exhibitor || state.currentExhibitor,
})


actions.updateExhibitor = (exhibitor) =>
  (dispatch, getState) => {
    dispatch({ type: 'CPE_UPDATE_EXHIBITOR_START' })

    return serviceUpdateExhibitor(getEventId(getState), exhibitor)
      .then(response => {
        dispatch({ type: 'CPE_UPDATE_EXHIBITOR_SUCCESS', payload: { exhibitor: response } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'cpe_modules')('exhibitors.update.title'),
          message: i18n.getFixedT(i18n.language, 'cpe_modules')('exhibitors.update.success'),
          level: 'success',
          permanent: false,
        }))

        return Promise.resolve()
      })
      .catch(error => {
        dispatch({ type: 'CPE_UPDATE_EXHIBITOR_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'cpe_modules')('exhibitors.update.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))

        return Promise.reject()
      })
  }

reducers['CPE_UPDATE_EXHIBITOR_SUCCESS'] = (state, action) => ({
  ...state,
  currentExhibitor: { ...state.currentExhibitor, ...action.payload.exhibitor },
})


actions.uploadExhibitorAsset = (assetName, asset) =>
  (dispatch, getState) => {
    dispatch({ type: 'CPE_UPLOAD_EXHIBITOR_ASSET_START', payload: { assetName } })

    return serviceUploadAsset(
      getEventId(getState),
      assetName,
      asset,
      (response) => {
        dispatch({ type: 'CPE_UPLOAD_EXHIBITOR_ASSET_SUCCESS', payload: { assetName } })
        return Promise.resolve(response)
      },
      (error) => {
        dispatch({ type: 'CPE_UPLOAD_EXHIBITOR_ASSET_ERROR', payload: { assetName } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'cpe_modules')('exhibitors.asset_upload.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['CPE_UPLOAD_EXHIBITOR_ASSET_START'] = (state, action) => ({
  ...state,
  assetUploadInProgress: [...state.assetUploadInProgress, action.payload.assetName],
})

reducers['CPE_UPLOAD_EXHIBITOR_ASSET_SUCCESS'] = (state, action) => ({
  ...state,
  assetUploadInProgress: state.assetUploadInProgress.filter(d => d !== action.payload.assetName),
})

reducers['CPE_UPLOAD_EXHIBITOR_ASSET_ERROR'] = (state, action) => ({
  ...state,
  assetUploadInProgress: state.assetUploadInProgress.filter(d => d !== action.payload.assetName),
})


actions.deleteRepresentative = (exhibitorId, accountId) =>
  (dispatch, getState) => {
    dispatch({ type: 'CPE_DELETE_REPRESENTATIVE_START' })

    return serviceDeleteRepresentative(getEventId(getState), exhibitorId, accountId)
      .then(() => {
        dispatch({ type: 'CPE_DELETE_REPRESENTATIVE_SUCCESS', payload: { exhibitorId, accountId } })
      })
      .catch(() => {
        dispatch({ type: 'CPE_DELETE_REPRESENTATIVE_ERROR' })
        return Promise.reject()
      })
  }

reducers['CPE_DELETE_REPRESENTATIVE_SUCCESS'] = (state, action) => ({
  ...state,
  representatives: isCurrentExhibitor(state, action.payload.exhibitorId) ? state.representatives.filter(d => d.account.id !== action.payload.accountId) : state.representatives,
})


actions.addRepresentative = (exhibitorId, accountId) =>
  (dispatch, getState) => {
    dispatch({ type: 'CPE_ADD_REPRESENTATIVE_START', payload: { exhibitorId, accountId } })

    return serviceAddRepresentative(getEventId(getState), exhibitorId, accountId)
      .then(() => {
        dispatch({ type: 'CPE_ADD_REPRESENTATIVE_SUCCESS', payload: { exhibitorId, accountId } })
      })
      .catch(() => {
        dispatch({ type: 'CPE_ADD_REPRESENTATIVE_ERROR', payload: { exhibitorId, accountId } })
        return Promise.reject()
      })
  }

actions.addRepresentativeByEmail = (exhibitorId, email) =>
  (dispatch) => dispatch(actions.addRepresentative(exhibitorId, `email:${email}`))


actions.resetExhibitorPassword = (exhibitorId, accountId, password) =>
  (dispatch, getState) => serviceResetExhibitorAdminPassword(getEventId(getState), exhibitorId, accountId, password)
    .then(() => {
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'cpe_modules')('exhibitors.reset_password.title'),
        message: i18n.getFixedT(i18n.language, 'cpe_modules')('exhibitors.reset_password.success'),
        level: 'success',
        permanent: false,
      }))
    })
    .catch(error => {
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'cpe_modules')('exhibitors.reset_password.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    })

actions.addExhibitorAdmin = (exhibitorId, data) =>
  (dispatch, getState) => {
    dispatch({ type: 'CPE_ADD_EXHIBITOR_ADMIN_START', payload: { ...data } })

    return serviceAddExhibitorAdmin(getEventId(getState), exhibitorId, data)
      .then(response => {
        dispatch({ type: 'CPE_ADD_EXHIBITOR_ADMIN_SUCCESS', payload: { ...response } })
      })
      .catch(error => {
        dispatch({ type: 'CPE_ADD_EXHIBITOR_ADMIN_ERROR', payload: { ...data } })
        dispatch(showToast({
          title: '',
          message: error.description,
          level: 'error',
          permanent: false,
        }))
        return Promise.reject()
      })
  }

actions.deleteExhibitorAdmin = (exhibitorId, accountId) =>
  (dispatch, getState) => {
    dispatch({ type: 'CPE_DELETE_EXHIBITOR_ADMIN_START', payload: { accountId } })

    return serviceDeleteExhibitorAdmin(getEventId(getState), exhibitorId, accountId)
      .then(() => {
        dispatch({ type: 'CPE_DELETE_EXHIBITOR_ADMIN_SUCCESS', payload: { accountId } })
      })
      .catch(error => {
        dispatch({ type: 'CPE_DELETE_EXHIBITOR_ADMIN_ERROR', payload: { accountId } })
        dispatch(showToast({
          title: '',
          message: error.description,
          level: 'error',
          permanent: false,
        }))
        return Promise.reject()
      })
  }


export const getExhibitor = actions.getExhibitor
export const getExhibitors = actions.getExhibitors
export const updateExhibitor = actions.updateExhibitor
export const selectExhibitor = actions.selectExhibitor
export const addExhibitorAdmin = actions.addExhibitorAdmin
export const deleteExhibitorAdmin = actions.deleteExhibitorAdmin
export const deleteRepresentative = actions.deleteRepresentative
export const uploadExhibitorAsset = actions.uploadExhibitorAsset
export const resetExhibitorPassword = actions.resetExhibitorPassword
export const addRepresentativeByEmail = actions.addRepresentativeByEmail

const exhibitors = reducers.index
export default exhibitors
