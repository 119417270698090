import React, { Component } from 'react'
import { card as styles } from './styles.scss'

export default class Card extends Component {
  _getClassName() {
    let className = `card ${styles}`
    className += this.props.className ? ` ${this.props.className}` : ''
    return className
  }

  _getProps() {
    const props = { ...this.props }
    delete props.className
    return props
  }

  render() {
    return (
      <div className={this._getClassName()} {...this._getProps()}>
        {this.props.children}
      </div>
    )
  }
}
