import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { socialNetworksBranding, connectSocialNetwork, getSocialNetworkAuthInfo } from 'common/utils/social'

import * as authAC from 'common/modules/auth'


@connect(
  state => state.auth,
  dispatch => bindActionCreators(authAC, dispatch)
)
export default class ButtonContainer extends Component {
  getButton = (type) => {
    switch (type) {
      case 'email':
        return (
          <div className="btn email" onClick={(e) => { this.sendLoginCodeBy(e, 'email') }}>
            <i className="fa fa-envelope" aria-hidden="true" />
          </div>
        )

      case 'sms':
        return (
          <div className="btn sms" onClick={(e) => { this.sendLoginCodeBy(e, 'sms') }}>
            <i className="fa fa-mobile" aria-hidden="true" />
          </div>
        )

      default: {
        const socialBranding = { ...getSocialNetworkAuthInfo(type), ...socialNetworksBranding[type] }
        if (!socialBranding) return null

        return (
          <div className={`btn social-connect ${type}`} onClick={(e) => { this.handleSocial(e, type) }}>
            {socialBranding.icon
              ? <img className="social-logo" src={socialBranding.icon} role="presentation" />
              : <i className={`social-logo fa fa-${type}`} />
            }
          </div>
        )
      }
    }
  }

  handleSocial = (e, net) => {
    e.preventDefault()

    connectSocialNetwork(net, (code, codeType, redirectUri) => {
      this.props.loginSocial(code, codeType, redirectUri, this.props.domain)
        .then(this.props.loginCallback)
    })
  }

  sendLoginCodeBy(e, type) {
    e.preventDefault()
    e.stopPropagation()

    this.props.sendLoginCode(type)
    this.props.changeLoginPage(this.props.location, 'enter_code')
  }

  render() {
    const { buttons } = this.props
    const content = buttons.map(this.getButton)

    return (
      <div className="ButtonContainer">
        {content.map(e => e)}
      </div>
    )
  }
}
