import { generateDataModule } from 'admin/modules/generateModule'
import sort from 'common/utils/sortHelper'
import { seviceGetClicksPerTouchpoint } from 'admin/services/dashboard'
import TouchpointsHelper from 'admin/utils/DataObjects/Touchpoints'

const clicksPerTouchpointModule = generateDataModule({
  initialData: {},
  moduleKey: 'dashboardClicksPerTouchpoint',
  reducerKey: 'admin.dashboard.clicksPerTouchpoint',
  services: {
    get: { service: seviceGetClicksPerTouchpoint },
  },
})

const formatData = data => {
  if (data && data.length) {
    const formattedData = data.map(touchpoint => ({
      count: touchpoint.count,
      fieldValue: touchpoint.fieldValue,
      fieldLabel: TouchpointsHelper.getTouchpointTitle(touchpoint.fieldValue),
    }))
    return sort(formattedData, [
      { id: 'count', order: -1 },
      'fieldLabel',
      'fieldValue',
    ])
  }
  return []
}

clicksPerTouchpointModule.registerSelector('minimalData', (state, moduleState) => moduleState.data, data => formatData(data).slice(0, 5))
clicksPerTouchpointModule.registerSelector('fullData', (state, moduleState) => moduleState.data, data => formatData(data))

clicksPerTouchpointModule.registerDataAction(
  'get',
  () => (dispatch) => {
    dispatch({ type: clicksPerTouchpointModule.actionKeys.getStart })


    return clicksPerTouchpointModule._getService('get')()
      .then(response => {
        dispatch({ type: clicksPerTouchpointModule.actionKeys.getSuccess, payload: response })
      })
      .catch(() => {
        dispatch({ type: clicksPerTouchpointModule.actionKeys.getError })
      })
  }
)

export default clicksPerTouchpointModule

