import fullAccessPermissions from './FullAccess'

export default {
  action: {
    ...fullAccessPermissions.action,

    // Events
    createEvents: false,
  },

  menu: {
    ...fullAccessPermissions.menu,
    system: false,
  },

  page: {
    ...fullAccessPermissions.page,
    broadcast: false,
    exports: false,
  },
}
