import Moment from 'moment'
import { combineReducers } from 'redux'
import generateAnalyticsModule from 'admin/utils/generateAnalyticsModule'

import { serviceGetAttendeeEngagement, serviceGetSessionsStats, serviceGetSessionsAnalytics, serviceGetTopAttendees, serviceGetTopSessions, serviceGetEventEngagement, serviceGetNetworkingEngagement, serviceGetMultiEventsStats } from 'admin/services/analytics'

import appMetrics from './appMetrics'
import badges from './badges'
import distributionGraphs from './DistributionGraphs'
import onlineEngagement from './OnlineEngagement'
import overtimeGraphs from './OvertimeGraphs'
import sessionsByGroups from './sessionsByGroups'
import sessionsByGroupsOvertime from './sessionsByGroupsOvertime'
import topicsTags from './topicsTags'
import tracking from './tracking'


export const modules = {
  attendeeEngagement: generateAnalyticsModule({
    moduleKey: 'attendeeEngagement',
    initialData: {},
    getter: serviceGetAttendeeEngagement,
  }),

  currentSessions: generateAnalyticsModule({
    moduleKey: 'currentSessions',
    getter: (eventId, query, onSuccess, onError) => {
      const q = { sort: 'start_date', when: Moment().unix(), ...query }
      return serviceGetSessionsAnalytics(eventId, q, onSuccess, onError)
    },
  }),

  eventEngagement: generateAnalyticsModule({
    moduleKey: 'eventEngagement',
    initialData: { attendees: {}, sessions: {} },
    getter: serviceGetEventEngagement,
  }),

  multiEvents: generateAnalyticsModule({
    moduleKey: 'multiEvents',
    initialData: { events: [], benchmark: {} },
    getter: serviceGetMultiEventsStats,
  }),

  networkingEngagement: generateAnalyticsModule({
    moduleKey: 'networkingEngagement',
    initialData: { conversationsCreated: 0, conversationsAccepted: 0, meetingsCreated: 0, meetingsAccepted: 0 },
    getter: serviceGetNetworkingEngagement,
  }),

  pastSessions: generateAnalyticsModule({
    moduleKey: 'pastSessions',
    getter: (eventId, query, onSuccess, onError) => {
      const q = { sort: 'start_date', end_date: Moment().unix(), ...query }

      // Either get the end of day or the current time if query is current day
      // date must already be a UTC timestamp for the start of the day in the local time zone
      if (q.date) {
        q.start_date = q.date
        q.end_date = Math.min(q.date + (24 * 60 * 60), Moment().unix())
        delete q.date
      }

      return serviceGetSessionsAnalytics(eventId, q, onSuccess, onError)
    },
  }),

  sessionEngagement: generateAnalyticsModule({
    moduleKey: 'sessionEngagement',
    initialData: {},
    getter: serviceGetSessionsStats,
  }),

  topAttendees: generateAnalyticsModule({
    moduleKey: 'topAttendees',
    getter: serviceGetTopAttendees,
  }),

  topSessions: generateAnalyticsModule({
    moduleKey: 'topSessions',
    getter: serviceGetTopSessions,
  }),

  badges: badges,
  topicsTags: topicsTags,
}

const reducers = {
  appMetrics,
  distributionGraphs,
  onlineEngagement,
  overtimeGraphs,
  sessionsByGroups,
  sessionsByGroupsOvertime,
  tracking,
}

// Combine reducers from generic modules
Object.keys(modules).forEach(moduleKey => { reducers[moduleKey] = modules[moduleKey].reducers.index })

export default combineReducers(reducers)
