import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceGetLeaderboard(eventId, gameId, success, error) {
  return axios.get(`events/${eventId}/games/${gameId}/leaderboard`, getAxiosConfig())
    .then(response => Promise.resolve(response))
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetLeaderboardAround(eventId, gameId, attendeeId, limit, success, error) {
  return axios.get(`/events/${eventId}/games/${gameId}/leaderboard/${attendeeId}?limit=${limit}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGenerateLeaderboardLink(eventId, success, error) {
  return axios.post(`/events/${eventId}/leaderboards/access_token`, {}, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
