import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { getAxiosConfig } from './helper'


export function translateMinimalEvent(event) {
  const data = { ...event }

  if (data.location) {
    data.location = getTranslatedObject(data.location)
  }
  if (data.date_label) {
    data.date_label = getTranslatedObject(data.date_label)
  }

  return data
}

export function serviceGetMinimalEvent(eventId, success, error) {
  return axios.get('/events/' + eventId, getAxiosConfig({ withToken: false }))
    .then((response) => Promise.resolve(translateMinimalEvent(response.data)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetDomainBranding(domain, success, error) {
  return axios.get('/domains/' + domain, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
