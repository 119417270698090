import { combineReducers } from 'redux'
import { pulseOfflineNotification } from 'common/modules/offline'

import app from './app'

import attProfile from './profile'
import attContacts from './contacts'
import attEvents from './events'
import activities from './activities'
import badges from './badges'
import busyCalendars from './busyCalendars'
import carousel from './carousel'
import contactInvitations from './contactInvitations'
import conversation from './conversation'
import conversationInvitations from './conversationInvitations'
import exhibitors from './exhibitors'
import gameLog from './gameLog'
import games from './games'
import InfoPopupsModule from './infoPopups'
import lastVisits from './lastVisits'
import menu from './menu'
import meetingRecommendations from './meetingRecommendations'
import meetings from './meetings'
import notifications from './notifications'
import QualifiersModule from './qualifiers'
import sessions from './sessions'
import speakers from './speakers'
import toasters from './toasters'
import attVenueMaps from './venueMaps'
import attLocations from './locations'
import attAttendeeLocation from './attendeeLocation'
import tags from './tags'
import topics from './topics'
import filterSettings from './FilterSettings'
import calendar from './calendar'
import customPages from './customPages'

import VideoCallInvitationsModule from './videoCallInvitations'

const attendee = combineReducers({
  app: app,
  attProfile: attProfile.reducers.index,
  attContacts: attContacts.reducers.index,
  attEvents: attEvents.reducers.index,
  activities: activities.reducers.index,
  badges: badges.reducers.index,
  busyCalendars: busyCalendars.reducers.index,
  carousels: carousel.reducers.index,
  contactInvitations: contactInvitations.reducers.index,
  conversation: conversation.reducers.index,
  conversationInvitations: conversationInvitations.reducers.index,
  exhibitors: exhibitors.reducers.index,
  gameLog: gameLog.reducers.index,
  games: games.reducers.index,
  infoPopups: InfoPopupsModule.reducers.index,
  lastVisits: lastVisits.reducers.index,
  menus: menu.reducers.index,
  meetingRecommendations: meetingRecommendations.reducers.index,
  meetings: meetings.reducers.index,
  notifications: notifications.reducers.index,
  qualifiers: QualifiersModule.reducers.index,
  sessions: sessions.reducers.index,
  speakers: speakers.reducers.index,
  attVenueMaps: attVenueMaps.reducers.index,
  attLocations: attLocations.reducers.index,
  attAttendeeLocation: attAttendeeLocation.reducers.index,
  tags: tags.reducers.index,
  toasters: toasters.reducers.index,
  topics: topics.reducers.index,
  calendar: calendar.reducers.index,
  customPages: customPages.reducers.index,
  filterSettings,
  videoCallInvitations: VideoCallInvitationsModule.reducers.index,
})


export function handleErrorMessage(dispatch, getState, error) {
  const errorOffline = error && error.offline;
  const stateOffline = !getState().offlineNotification.online;

  if (errorOffline || stateOffline) {
    dispatch(pulseOfflineNotification())
  } else {
    dispatch(toasters.actions.addToast(error.description))
  }
}

export default attendee
