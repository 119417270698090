import { serviceGetTooltipsSeenStatus, serviceUpdateTooltipsSeenStatus } from 'common/services/tutorials'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'
import Tooltip from './Tooltips'


const initialState = {
  isFetching: false,
  isLoaded: false,
  isError: false,
  data: [],
  currentIndex: 0,
  seenStatus: {},
}

function updateSeenStatus(seenStatus, id, seen) {
  const newSeenStatus = { ...seenStatus }
  newSeenStatus[id] = seen
  return newSeenStatus
}

export default function tooltips(state = initialState, action) {
  switch (action.type) {
    case 'ADD_TOOLTIP':
      return {
        ...state,
        data: [...state.data, action.data.tooltip],
      }

    case 'DISMISS_TOOLTIPS':
      return {
        ...state,
        data: [],
        currentIndex: 0,
      }

    case 'GO_TO_NEXT_TOOLTIP':
      return {
        ...state,
        currentIndex: state.currentIndex + 1,
      }

    case 'GET_TOOLTIPS_SEEN_STATUS_START':
      return {
        ...state,
        isFetching: true,
        isLoaded: false,
        isError: false,
      }

    case 'GET_TOOLTIPS_SEEN_STATUS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        seenStatus: action.data.status,
      }

    case 'GET_TOOLTIPS_SEEN_STATUS_ERROR':
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        isError: true,
      }

    case 'UPDATE_TOOLTIPS_SEEN_STATUS_START':
      return {
        ...state,
        seenStatus: updateSeenStatus(state.seenStatus, action.data.tooltipId, action.data.seen),
      }

    case 'UPDATE_TOOLTIPS_SEEN_STATUS_SUCCESS':
      return { ...state }

    case 'UPDATE_TOOLTIPS_SEEN_STATUS_ERROR':
      return {
        ...state,
        seenStatus: updateSeenStatus(state.seenStatus, action.data.tooltipId, !action.data.seen),
      }

    default:
      return state
  }
}


export function getTooltipsSeenStatus(eventId) {
  return dispatch => {
    dispatch({ type: 'GET_TOOLTIPS_SEEN_STATUS_START' })

    return serviceGetTooltipsSeenStatus(
      eventId,
      (response) => {
        dispatch({ type: 'GET_TOOLTIPS_SEEN_STATUS_SUCCESS', data: { status: response } })
      },
      (error) => {
        dispatch({ type: 'GET_TOOLTIPS_SEEN_STATUS_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'common_module')('tooltips.get_seen_status.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}

export function updateTooltipsSeenStatus(eventId, tooltipId, seen = true) {
  return dispatch => {
    dispatch({ type: 'UPDATE_TOOLTIPS_SEEN_STATUS_START', data: { tooltipId, seen } })

    return serviceUpdateTooltipsSeenStatus(
      eventId,
      { [tooltipId]: seen },
      () => {
        dispatch({ type: 'UPDATE_TOOLTIPS_SEEN_STATUS_SUCCESS', data: { tooltipId, seen } })
      },
      (error) => {
        dispatch({ type: 'UPDATE_TOOLTIPS_SEEN_STATUS_ERROR', data: { tooltipId, seen } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'common_module')('tooltips.update_seen_status.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}


export function addTooltip(options) {
  return (dispatch, getState) => {
    // Make sure to not add multiple tooltips of the same ID. We don't want to dispatch any action
    if (getState().tooltips.data.find(t => t.id === options.id)) {
      return {}
    }

    dispatch({
      type: 'ADD_TOOLTIP',
      data: {
        tooltip: new Tooltip(options),
      },
    })

    return Promise.resolve()
  }
}

export function dismissTooltips(eventId, startAtIndex = 0) {
  return (dispatch, getState) => {
    const tooltipsToDismiss = [...getState().tooltips.data]

    const dismissAll = () => {
      let result = Promise.resolve()
      for (let i = startAtIndex; i < tooltipsToDismiss.length; i++) {
        result = result.then(() => dispatch(updateTooltipsSeenStatus(eventId, tooltipsToDismiss[i].id)))
      }
    }

    dispatch({ type: 'DISMISS_TOOLTIPS' })
    return Promise.all([dismissAll()])
  }
}

export function clickNextTooltip(eventId) {
  return (dispatch, getState) => {
    const tooltipState = getState().tooltips
    const prevIndex = tooltipState.currentIndex
    const nextIndex = prevIndex + 1

    dispatch(updateTooltipsSeenStatus(eventId, tooltipState.data[prevIndex].id))

    if (nextIndex >= tooltipState.data.length) {
      return dispatch(dismissTooltips(eventId, nextIndex))
    }

    dispatch({ type: 'GO_TO_NEXT_TOOLTIP' })
    return Promise.resolve()
  }
}
