import { serviceGetEmailTemplate, serviceGetEmailTemplates, servicePostEmailTemplateRenderedHTML, servicePostEmailTemplate, servicePutEmailTemplate, servicePostEmailRenderedHTML, serviceScheduleEmailTemplate } from 'admin/services/emailing'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'

import generateModule from 'common/utils/generateModule'

const { actions, reducers, initialState } = generateModule({
  itemName: 'emailTemplate',
  dataIsNotArray: true,
  services: {
    get: serviceGetEmailTemplates,
  },
})

initialState.data = {
  currentTemplate: {},
  templates: [],
  renderedHTML: '',
}


// /////////////////////////////////////////////////////////////////////////////
// GET email templates
// /////////////////////////////////////////////////////////////////////////////

actions.get = () =>
  ((dispatch, getState) => {
    dispatch(actions.getStart())
    return serviceGetEmailTemplates(
      getState().admin.events.currentEvent.id,
      (result) => dispatch(actions.getSuccess(result)),
      (error) => {
        dispatch(actions.getError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_communicationEmail')('actions.get_email_templates.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  })

actions.getEmailTemplate = (templateId) =>
  ((dispatch, getState) => {
    dispatch({ type: 'GET_EMAIL_TEMPLATE_START' })
    return serviceGetEmailTemplate(
      getState().admin.events.currentEvent.id,
      templateId,
      (result) => dispatch({ type: 'GET_EMAIL_TEMPLATE_SUCCESS', payload: { template: result } }),
      (error) => {
        dispatch({ type: 'GET_EMAIL_TEMPLATE_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_communicationEmail')('actions.get_email_templates.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  })

reducers['GET_EMAIL_TEMPLATE_START'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isFetching: true,
  isLoaded: false,
  isError: false,
  lastOperationStatus: 'GET',
})

reducers['GET_EMAIL_TEMPLATE_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  isFetching: false,
  isLoaded: true,
  isError: false,
  data: {
    ...state.data,
    currentTemplate: action.payload.template,
  },
})

reducers['GET_EMAIL_TEMPLATE_ERROR'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isFetching: false,
  isLoaded: true,
  isError: true,
})

reducers['GET_EMAILTEMPLATES_START'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isFetching: true,
  isLoaded: false,
  isError: false,
  lastOperationStatus: 'GET',
})

reducers['GET_EMAILTEMPLATES_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  data: {
    ...state.data,
    currentTemplate: state.data.currentTemplate,
    templates: action.payload.data,
  },
})

// /////////////////////////////////////////////////////////////////////////////
// GET email template rendered HTML
// /////////////////////////////////////////////////////////////////////////////

function getEmailTemplateRenderedHTMLStart() {
  return { type: 'GET_EMAIL_TEMPLATE_RENDERED_HTML_START' }
}

function getEmailTemplateRenderedHTMLSuccess(renderedHTML) {
  return {
    type: 'GET_EMAIL_TEMPLATE_RENDERED_HTML_SUCCESS',
    payload: {
      renderedHTML: renderedHTML,
    },
  }
}

function getEmailTemplateRenderedHTMLError() {
  return { type: 'GET_EMAIL_TEMPLATE_RENDERED_HTML_ERROR' }
}

actions.getEmailTemplateRenderedHTML = (templateId, templateFields, lang) =>
  ((dispatch, getState) => {
    dispatch(getEmailTemplateRenderedHTMLStart())
    return servicePostEmailTemplateRenderedHTML(
      getState().admin.events.currentEvent.id,
      templateId, templateFields, lang,
      (response) => dispatch(getEmailTemplateRenderedHTMLSuccess(response.html)),
      (error) => {
        dispatch(getEmailTemplateRenderedHTMLError())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_communicationEmail')('actions.get_email_templates.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  })

actions.getEmailRenderedHTML = (html) =>
  ((dispatch, getState) =>
    servicePostEmailRenderedHTML(
      getState().admin.events.currentEvent.id,
      html,
      (response) => Promise.resolve(response.html),
      (error) => {
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_communicationEmail')('actions.get_email_templates.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  )

reducers['GET_EMAIL_TEMPLATE_RENDERED_HTML_START'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isFetching: true,
  isLoaded: false,
  isError: false,
  lastOperationStatus: 'GET',
})

reducers['GET_EMAIL_TEMPLATE_RENDERED_HTML_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  isFetching: false,
  isLoaded: true,
  isError: false,
  data: {
    ...state.data,
    renderedHTML: action.payload.renderedHTML,
  },
})

reducers['GET_EMAIL_TEMPLATE_RENDERED_HTML_ERROR'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isFetching: false,
  isLoaded: true,
  isError: true,
})


// /////////////////////////////////////////////////////////////////////////////
// SELECT email template
// /////////////////////////////////////////////////////////////////////////////

actions.selectTemplate = (template) =>
  (dispatch) => Promise.resolve(dispatch({ type: 'SELECT_EMAIL_TEMPLATE', payload: { template: template } }))

reducers['SELECT_EMAIL_TEMPLATE'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  lastOperationStatus: 'SELECT',
  data: {
    ...state.data,
    currentTemplate: action.payload.template,
    templates: state.data.templates,
  },
})


// /////////////////////////////////////////////////////////////////////////////
// POST email template
// /////////////////////////////////////////////////////////////////////////////

function postEmailTemplateStart() {
  return { type: 'POST_EMAIL_TEMPLATE_START' }
}

function postEmailTemplateSuccess(template) {
  return {
    type: 'POST_EMAIL_TEMPLATE_SUCCESS',
    payload: {
      template: template,
    },
  }
}

function postEmailTemplateError() {
  return { type: 'POST_EMAIL_TEMPLATE_ERROR' }
}

actions.postEmailTemplate = (newTemplate) =>
  ((dispatch, getState) => {
    dispatch(postEmailTemplateStart())
    return servicePostEmailTemplate(
      getState().admin.events.currentEvent.id,
      newTemplate,
      (result) => dispatch(postEmailTemplateSuccess(result)),
      (error) => {
        dispatch(postEmailTemplateError())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_communication')('actions.save_email_template.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  })

reducers['POST_EMAIL_TEMPLATE_START'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isFetching: true,
  isLoaded: false,
  isError: false,
  lastOperationStatus: 'POST',
})

reducers['POST_EMAIL_TEMPLATE_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  isFetching: false,
  isLoaded: true,
  isError: false,
  data: {
    ...state.data,
    currentTemplate: action.payload.template,
  },
})

reducers['POST_EMAIL_TEMPLATE_ERROR'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isFetching: false,
  isLoaded: true,
  isError: true,
})


// /////////////////////////////////////////////////////////////////////////////
// PUT email template
// /////////////////////////////////////////////////////////////////////////////

function putEmailTemplateStart() {
  return { type: 'PUT_EMAIL_TEMPLATE_START' }
}

function putEmailTemplateSuccess(template) {
  return {
    type: 'PUT_EMAIL_TEMPLATE_SUCCESS',
    payload: {
      template: template,
    },
  }
}

function putEmailTemplateError() {
  return { type: 'PUT_EMAIL_TEMPLATE_ERROR' }
}

actions.putEmailTemplate = (newTemplate) =>
  ((dispatch, getState) => {
    dispatch(putEmailTemplateStart())
    return servicePutEmailTemplate(
      getState().admin.events.currentEvent.id,
      newTemplate,
      (result) => dispatch(putEmailTemplateSuccess(result)),
      (error) => {
        dispatch(putEmailTemplateError())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_communication')('actions.save_email_template.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  })

reducers['PUT_EMAIL_TEMPLATE_START'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isFetching: true,
  isLoaded: false,
  isError: false,
  lastOperationStatus: 'PUT',
})

reducers['PUT_EMAIL_TEMPLATE_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  isFetching: false,
  isLoaded: true,
  isError: false,
  data: {
    ...state.data,
    currentTemplate: action.payload.template,
  },
})

reducers['PUT_EMAIL_TEMPLATE_ERROR'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isFetching: false,
  isLoaded: true,
  isError: true,
})

actions.instantiateTemplate = (templateId) =>
  ((dispatch, getState) =>
    serviceScheduleEmailTemplate(
      getState().admin.events.currentEvent.id, templateId,
      (schedule) => {
        dispatch({ type: 'INSTANTIATED_TEMPLATE', payload: { schedule } })
        return Promise.resolve(schedule)
      },
      (error) => {
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_communication')('actions.save_email_template.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  )


export const getEmailTemplate = actions.getEmailTemplate
export const getEmailTemplates = actions.get
export const getEmailRenderedHTML = actions.getEmailRenderedHTML
export const getEmailTemplateRenderedHTML = actions.getEmailTemplateRenderedHTML
export const selectEmailTemplate = actions.selectTemplate
export const postEmailTemplate = actions.postEmailTemplate
export const putEmailTemplate = actions.putEmailTemplate
export const instantiateTemplate = actions.instantiateTemplate
const emailTemplates = reducers.index
export default emailTemplates
