import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceOpenKlikProfile(eventId, profileId, success, error) {
  const refreshExpireIn = 60 * 10
  return axios.post(`/events/${eventId}/attendees/${profileId}/access_token?refresh_expire_in=${refreshExpireIn}`, {}, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetCurrentUser(success, error) {
  return axios.get('/accounts/me', getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateAccount(account, success, error) {
  return axios.put('/accounts/me', account, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
