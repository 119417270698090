import { serviceGetLastVisits, serviceUpdateLastVisit } from 'attendee/services/lastVisits'
import generateModule from 'common/utils/generateModule'
import { listToMapping } from 'common/utils/componentHelper'
import { createSelector } from 'reselect'

const selectState = (state) => state.attendee.lastVisits
const selectLastVisits = (state) => selectState(state).data

export const lastVisitsByTypeSelector = createSelector(selectLastVisits, lastVisits => listToMapping(lastVisits, 'type'))


const lastVisits = generateModule({
  itemName: 'lastVisit',
  itemNameDisplay: 'lastVisit',
  toasterFieldName: 'id',
  getModuleState: (getState) => getState().attendee.lastVisits,
  autoRefresh: 60,
  services: {
    get: serviceGetLastVisits,
  },
  app: 'attendee',
})


function mergeLastVisits(visits, newVisit) {
  const data = [...visits]

  for (let i = 0; i < data.length; i++) {
    if (data[i].type === newVisit.type) {
      data[i] = { ...newVisit }
      return data
    }
  }

  data.push({ ...newVisit })
  return data
}


lastVisits.actions.updateLastVisit = (type) =>
  (dispatch, getState) => {
    dispatch({ type: 'UPDATE_LAST_VISIT_START' })
    return serviceUpdateLastVisit(
      getState().attendee.attEvents.currEvent.id,
      type,
      (response) => dispatch({ type: 'UPDATE_LAST_VISIT_SUCCESS', payload: { lastVisit: response } }),
      () => dispatch({ type: 'UPDATE_LAST_VISIT_ERROR' })
    )
  }

lastVisits.reducers['UPDATE_LAST_VISIT_START'] = (state) => ({
  ...state,
  isModifying: true,
  didInvalidate: false,
})

lastVisits.reducers['UPDATE_LAST_VISIT_SUCCESS'] = (state, action) => ({
  ...state,
  isModifying: false,
  data: mergeLastVisits(state.data, action.payload.lastVisit),
})

lastVisits.reducers['UPDATE_LAST_VISIT_ERROR'] = (state) => ({
  ...state,
  isModifying: false,
  didInvalidate: true,
})


lastVisits.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isLoaded: false,
  isError: false,
  didInvalidate: true,
  data: {},
})

lastVisits.actions.getLastVisits = lastVisits.actions.get
lastVisits.actions.updateLastVisit = lastVisits.actions.updateLastVisit
export default lastVisits
