import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetHighRiskZonesComparison } from 'admin/services/bluetrace'
import { locationsByIdSelector } from 'admin/modules/locations'
import { getProperty } from 'common/utils/componentHelper'
import sort from 'common/utils/sortHelper'


const HighRiskZonesComparisonModule = generateDataModule({
  initialData: {
    currentWeek: [],
    previousWeek: [],
  },
  moduleKey: 'cpo/bluetrace/highRiskZonesComparison',
  reducerKey: 'admin.bluetrace.highRiskComparisons.zones',
  refreshTime: 10000,
  services: {
    get: { service: serviceGetHighRiskZonesComparison },
  },
})


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

function getFormattedTopZones(zonesById, currentScores, previousScores = {}) {
  const topZones = []

  Object.keys(currentScores).forEach(zoneId => {
    const zoneEntry = {
      contagionScore: currentScores[zoneId] || 0,
      id: zoneId,
      name: getProperty(zonesById[zoneId], 'display_name') || zoneId,
      previousContagionScore: previousScores[zoneId] || 0,
    }

    topZones.push(zoneEntry)
  })

  return sort(topZones, [
    { id: 'contagionScore', order: -1 },
    { id: 'previousContagionScore', order: -1 },
    'name',
    'id',
  ])
}

HighRiskZonesComparisonModule.registerSelector(
  'currentWeek',
  [
    (state, moduleState) => moduleState.data.currentWeek,
    (state, moduleState) => moduleState.data.previousWeek,
    state => locationsByIdSelector(state),
  ],
  (currentScores, previousScores, zonesById) => getFormattedTopZones(zonesById, currentScores, previousScores)
)

HighRiskZonesComparisonModule.registerSelector(
  'previousWeek',
  [
    (state, moduleState) => moduleState.data.previousWeek,
    state => locationsByIdSelector(state),
  ],
  (scores, zonesById) => getFormattedTopZones(zonesById, scores)
)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////


export default HighRiskZonesComparisonModule
