import { generateDataModule, invalidateOnEventChange } from 'admin/modules/generateModule'
import { serviceGetAverageContagionScoreZones } from 'admin/services/bluetrace'


const AverageContagionScoreZones = generateDataModule({
  initialData: {
    currentScore: undefined,
    previousScore: undefined,
  },
  moduleKey: 'cpo/bluetrace/averageContagionScoreZones',
  reducerKey: 'admin.bluetrace.averageContagionScores.zones',
  refreshTime: 10000,
  services: {
    get: { service: serviceGetAverageContagionScoreZones },
  },
})

invalidateOnEventChange(AverageContagionScoreZones)


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

AverageContagionScoreZones.registerSelector(
  'previousScore',
  (state, moduleState) => moduleState.data.previousScore,
  score => score || 0
)

AverageContagionScoreZones.registerSelector(
  'currentScore',
  (state, moduleState) => moduleState.data.currentScore,
  score => score || 0
)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////


export default AverageContagionScoreZones
