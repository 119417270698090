export function getSessions(_calendars) {
  const calendars = [..._calendars]
  const sessions = []

  calendars.forEach(cal => {
    cal.sessions.forEach(s =>
      sessions.push({
        ...s,
        type: cal.name,
        calendar: { ...cal },
        color: cal.color,
      })
    )
  })

  sessions.sort((a, b) => {
    if (a.start_date === b.start_date) {
      if (a.title === b.title) {
        if (a.id < b.id) return -1
        if (a.id > b.id) return 1
        return 0
      }

      if (a.title < b.title) return -1
      if (a.title > b.title) return 1
      return 0
    }

    return a.start_date - b.start_date
  })

  return { sessions, calendars }
}

function findSessionIndex(sessions, session) {
  for (let i = 0; i < sessions.length; i++) {
    if (sessions[i].id === session.id) {
      return i
    }
  }

  return -1
}

export function updateSessionRelation(sessions, session, relation, capacityChange = 0) {
  const sessionIndex = findSessionIndex(sessions, session)
  const newSessions = [...sessions]

  if (sessionIndex !== -1) {
    newSessions[sessionIndex] = {
      ...sessions[sessionIndex],
      me: {
        ...sessions[sessionIndex].me,
        ...relation,
      },
      capacity_available: sessions[sessionIndex].capacity !== null
        ? sessions[sessionIndex].capacity_available + capacityChange
        : sessions[sessionIndex].capacity_available,
    }

    if (relation.invited) {
      newSessions[sessionIndex].me.invited = {
        ...newSessions[sessionIndex].me.invited,
        ...relation.invited,
      }
    }
  }

  return newSessions
}

export function removeSession(sessions, session) {
  const sessionIndex = findSessionIndex(sessions, session)
  const newSessions = [...sessions]
  newSessions.splice(sessionIndex, 1)
  return newSessions
}
