import axios from 'axios'
import config from 'config'
import moment from 'moment'
import sort from 'common/utils/sortHelper'
import CheckInBreakdownModule from 'admin/modules/Dashboard/checkInBreakdown'
import { getProperty } from 'common/utils/componentHelper'
import { buildError } from 'common/utils/api'
import { buildQueryParams, getAxiosConfig } from './helper'
import { getTranslatedObject } from 'common/utils/translator'
import { translateSession } from './sessions'
import { parsePaginationHeaders } from 'common/services/helper'


function formatTimeQuery(timeFilters, timezone) {
  if (timeFilters.range) {
    return {
      range: timeFilters.range,
    }
  }

  const _timeOfDay = timeFilters.timeOfDay || 'entire'
  const startOfDay = moment(timeFilters.startDate * 1000).tz(timezone).startOf('day')
  switch (_timeOfDay.toLowerCase()) {
    case 'morning':
      return {
        start_date: startOfDay.clone().add(6, 'hours').unix(),
        end_date: startOfDay.clone().add(12, 'hours').unix(),
      }

    case 'afternoon':
      return {
        start_date: startOfDay.clone().add(12, 'hours').unix(),
        end_date: startOfDay.clone().add(18, 'hours').unix(),
      }

    case 'evening':
      return {
        start_date: startOfDay.clone().add(18, 'hours').unix(),
        end_date: startOfDay.clone().add(24, 'hours').unix(),
      }

    case 'overnight':
      return {
        start_date: startOfDay.clone().add(24, 'hours').unix(),
        end_date: startOfDay.clone().add(30, 'hours').unix(),
      }

    default:
      return {
        start_date: timeFilters.startDate,
        end_date: timeFilters.endDate,
      }
  }
}

function translateEmailSchedule(schedule) {
  const data = { ...schedule }

  if (data.template && data.template.subject) {
    data.template = { ...data.template, subject: getTranslatedObject(data.template.subject) }
  }

  return data
}

function formatCommunicationData(communications) {
  const arr = [];
  const sent = communications.filter(c => c.stats.sent || c.completed_on)
  sent.forEach(c => arr.push(
    {
      id: c.id,
      name: c.template.name,
      number_recipients: c.stats.sent,
      sent: c.stats.sent,
      read: c.stats.read,
      open_percentage: Math.round(((((c.stats.read || 0) * 100) / (c.stats.sent || 1)))),
      click_rate: Math.round(((((c.stats.clicked || 0) * 100) / (c.stats.sent || 1)))),
      completed_on: c.completed_on,
      recipient_type: c.target.type,
      clicks: c.stats.clicked || 0,
      channels: c.transports.map(x => x.type),
    }
  ))
  return arr;
}


export function serviceGetBadgesData(eventId, queryParams) { // eslint-disable-line
  const params = buildQueryParams(queryParams)
  return axios.get(`/events/${eventId}/games/main/achievements${params}`, getAxiosConfig())
    .then(response => {
      let badges = response.data.map(entry => ({ ...entry, achieved_count: getProperty(entry, 'stats.achieved') || 0 }))
      badges = sort(badges, [
        { id: 'achieved_count', order: -1 },
        'title',
        'id',
      ])

      const data = badges.map((badge, index) => ({
        ...badge,
        ranking: index + 1,
      }))

      return {
        data: data,
        ...parsePaginationHeaders(response),
      }
    })
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetCommunicationsData(eventId, queryParams) {
  const params = buildQueryParams(queryParams)
  return axios.get(`/events/${eventId}/scheduled_notifications${params}`, getAxiosConfig())
    .then((response) => ({
      data: formatCommunicationData(response.data).map(translateEmailSchedule),
      ...parsePaginationHeaders(response),
    }))
}

export function serviceGetLeaderboard(eventId, gameId = 'main') {
  return axios.get(`/events/${eventId}/games/${gameId}/leaderboard?limit=20`, getAxiosConfig())
    .then(response => response.data.leaderboard)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetCheckInProgress(eventId) {
  return axios.get(`/events/${eventId}/attendees/stats?groupby=checked_in`, getAxiosConfig())
    .then(response => {
      const checkedIn = response.data.groups.find(d => d.checked_in)
      const notCheckedIn = response.data.groups.find(d => !d.checked_in)

      return {
        checked_in: getProperty(checkedIn, 'count') || 0,
        not_checked_in: getProperty(notCheckedIn, 'count') || 0,
      }
    })
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetCheckInBreakdown(eventId, field) {
  const groupBy = field === CheckInBreakdownModule.ATTENDEE_TYPE ? field : `custom_fields.${field}`

  return axios.get(`/events/${eventId}/attendees/stats?groupby=${groupBy}&filter=checked_in:true`, getAxiosConfig())
    .then(response => {
      const groups = sort(response.data.groups, [{ id: 'count', order: -1 }])
      return groups.map(group => ({
        count: group.count,
        fieldId: field,
        fieldValue: getProperty(group, groupBy),
      }))
    })
    .catch(error => Promise.reject(buildError(error)))
}


function serviceGetInvitationAcceptanceStats(eventId, types) {
  const getStats = (type) => axios.get(`/events/${eventId}/stats/${type}/sum`, getAxiosConfig())
    .then(response => response.data.count)

  return Promise.all(types.map(type => getStats(type)))
    .then(values => {
      const accepted = (values[0] || 0) + (values[1] || 0)
      const total = (values[2] || 0) + (values[3] || 0)
      const notAccepted = total - accepted

      return { accepted, notAccepted, total }
    })
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetNetworkingConversationAcceptanceStats(eventId) {
  const invitationTypes = [
    'conversation_invitations_accepted',
    'conversation_invitations_accepted_from_topic',
    'conversation_invitations_created',
    'conversation_invitations_created_from_topic',
  ]

  return serviceGetInvitationAcceptanceStats(eventId, invitationTypes)
}

export function serviceGetNetworkingMeetingAcceptanceStats(eventId) {
  const invitationTypes = [
    'meeting_invitations_accepted',
    'meeting_invitations_accepted_from_topic',
    'meeting_invitations_created',
    'meeting_invitations_created_from_topic',
  ]

  return serviceGetInvitationAcceptanceStats(eventId, invitationTypes)
}

export function serviceGetAttendeeOnSiteStats(eventId, currentTimestamp, startOfDayTimestamp) {
  const axiosConfig = getAxiosConfig()
  axiosConfig.baseURL = config.locatorUrl
  const buildURL = (params) => `/events/${eventId}/devices/stats${buildQueryParams(params)}`

  const getDayStats = () => axios.get(buildURL({ since: currentTimestamp - startOfDayTimestamp }), axiosConfig)
    .then(response => response.data)

  const getNowStats = () => axios.get(buildURL({ since: 60 * 10 }), axiosConfig)
    .then(response => response.data)

  return Promise.all([getDayStats(), getNowStats()])
    .then(values => ({
      day: values[0],
      now: values[1],
    }))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetContactExchanges(eventId, timeFilters, timezone) {
  const query = formatTimeQuery(timeFilters, timezone)
  const queryParams = buildQueryParams({ ...query, groupby: 'activity_type' })
  return axios.get(`/events/${eventId}/stats/kliks/overtime${queryParams}`, getAxiosConfig())
    .then(response => response.data.timeseries.reduce((acc, current) => {
      const count = getProperty((current.groups || []).find(g => g.activity_type === 'add_contact'), 'count') || 0
      return [...acc, ({ date: current.date, value: count })]
    }, []))
    .catch(error => Promise.reject(buildError(error)))
}
export function serviceGetCurrentSumContactExchanges(eventId, timeFilters, timezone) {
  const query = formatTimeQuery(timeFilters, timezone)
  const queryParams = buildQueryParams({ ...query, groupby: 'activity_type' })
  return axios.get(`/events/${eventId}/stats/kliks/sum${queryParams}`, getAxiosConfig())
    .then(response => (
      Array.isArray(response.data) ?
        response.data.filter(sums => sums.group.activity_type === 'add_contact')[0].count
        : 0
    ))
    .catch(error => Promise.reject(buildError(error)))
}


export function serviceGetAttendeeEngagement(eventId, query) {
  return axios.get(`/events/${eventId}/attendees/onsite/stats${buildQueryParams(query)}`, getAxiosConfig())
    .then((response) => response.data)
}

function getCurrentSessionsData(eventId, currentMoment) {
  const queryString = buildQueryParams({ when: currentMoment.unix() })

  return axios.get(`/events/${eventId}/sessions${queryString}`, getAxiosConfig())
    .then(response => response.data.map(session => translateSession(session)))
}

function getUpcomingSessionsData(eventId, currentMoment, queryParams) {
  const queryString = buildQueryParams({
    start_date: currentMoment.unix(),
    end_date: currentMoment.endOf('day').unix(),
    ...queryParams,
  })

  return axios.get(`/events/${eventId}/sessions${queryString}`, getAxiosConfig())
    .then(response => response)
}

function getSessionsAttendanceOvertime(eventId) {
  const queryString = buildQueryParams({
    per_location: true,
    range: 240,
  })

  return axios.get(`/events/${eventId}/locations/stats/attendees_overtime${queryString}`, getAxiosConfig())
    .then(response => getProperty(response, 'data.timeseries') || [])
}

export function serviceGetSessionsData(eventId, currentMoment, queryParams) {
  return Promise.all([
    getCurrentSessionsData(eventId, currentMoment),
    getUpcomingSessionsData(eventId, currentMoment, queryParams),
    getSessionsAttendanceOvertime(eventId),
  ])
    .then(values => ({
      current: values[0],
      upcoming: values[1].data.map(session => translateSession(session)),
      tracking: values[2],
      ...parsePaginationHeaders(values[1]),
    }))
    .catch(error => Promise.reject(buildError(error)))
}


export function serviceGetTouchpointClicks(eventId, timeFilters, timezone) {
  const query = formatTimeQuery(timeFilters, timezone)
  const queryParams = buildQueryParams({ ...query, groupby: 'activity_type' })
  return axios.get(`/events/${eventId}/stats/kliks/overtime${queryParams}`, getAxiosConfig())
    .then(response => response.data.timeseries.reduce((acc, current) => {
      const count = getProperty((current.groups || []).find(g => g.activity_type === 'touchpoint_click'), 'count') || 0
      return [...acc, ({ date: current.date, value: count })]
    }, []))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetCurrentSumTouchpointClicks(eventId, timeFilters, timezone) {
  const query = formatTimeQuery(timeFilters, timezone)
  const queryParams = buildQueryParams({ ...query, groupby: 'activity_type' })
  return axios.get(`/events/${eventId}/stats/kliks/sum${queryParams}`, getAxiosConfig())
    .then(response => (
      Array.isArray(response.data) ?
        response.data.filter(sums => sums.group.activity_type === 'touchpoint_click')[0].count
        : 0
    ))
    .catch(error => Promise.reject(buildError(error)))
}


export function seviceGetClicksPerTouchpoint(eventId) {
  return axios.get(`/events/${eventId}/stats/touchpoint_kliks/sum?groupby=touchpoint_id`, getAxiosConfig())
    .then(response => (
      response.data.map(touchpoint => ({
        count: touchpoint.count,
        fieldValue: touchpoint.group.touchpoint_id,
        fieldLabel: touchpoint.group.touchpoint_id,
      }))
    )).catch((error) => Promise.reject(buildError(error)))
}


function getInstallsStats(eventId, start, end) {
  const query = { appmetric: 'visit' }
  if (start != null) query.start_date = start
  if (end != null) query.end_date = end

  const queryString = buildQueryParams(query)

  return axios.get(`/events/${eventId}/stats/appmetrics/sum${queryString}`, getAxiosConfig())
    .then(response => response.data.count)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetInstallsStats(eventId, eventStartMoment, eventEndMoment, currentDayMoment) {
  const startDayOfEventStart = eventStartMoment.startOf('day').unix()
  const endDayOfEventEnd = eventEndMoment.endOf('day').unix()
  const startDayOfToday = currentDayMoment.startOf('day').unix()
  const endDayOfToday = currentDayMoment.endOf('day').unix()

  return Promise.all([
    getInstallsStats(eventId, undefined, endDayOfEventEnd), // Total
    getInstallsStats(eventId, startDayOfToday, endDayOfToday), // Today
    getInstallsStats(eventId, undefined, startDayOfEventStart - 1), // Before
    getInstallsStats(eventId, startDayOfEventStart, endDayOfEventEnd), // During
  ])
    .then(values => ({
      before_event: values[2],
      during_event: values[3],
      today: values[1],
      total: values[0],
    }))
    .catch(error => Promise.reject(buildError(error)))
}


function getAppTrafficOvertimeStats(eventId, timeFilters, timezone) {
  const queryString = buildQueryParams({ ...formatTimeQuery(timeFilters, timezone), appmetric: 'visit', entity_count: true })
  return axios.get(`/events/${eventId}/stats/appmetrics/overtime${queryString}`, getAxiosConfig())
    .then(response => {
      const logins = []
      const visits = []

      response.data.timeseries.forEach(timeserie => {
        logins.push({ date: timeserie.date, value: timeserie.entity_count || 0 })
        visits.push({ date: timeserie.date, value: timeserie.gross_count || 0 })
      })

      return { logins, visits }
    })
    .catch(error => Promise.reject(buildError(error)))
}

function getAppTrafficStats(eventId, timeFilters, timezone) {
  const queryString = buildQueryParams({ ...formatTimeQuery(timeFilters, timezone), appmetric: 'visit', entity_count: true })
  return axios.get(`/events/${eventId}/stats/appmetrics/sum${queryString}`, getAxiosConfig())
    .then(response => {
      const logins = response.data.entity_count || 0
      const visits = response.data.gross_count || 0

      return {
        logins,
        visits,
        average_visits_per_logins: visits / (logins || 1),
      }
    })
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetAppTrafficStats(eventId, timeFilters, timezone) {
  return Promise.all([
    getAppTrafficStats(eventId, timeFilters, timezone),
    getAppTrafficOvertimeStats(eventId, timeFilters, timezone),
  ])
    .then(values => ({
      stats: values[0],
      overtime: values[1],
    }))
    .catch(error => Promise.reject(buildError(error)))
}


export function serviceGetDevicesOnsiteStatsOvertime(eventId, timeFilters, timezone) {
  const query = formatTimeQuery(timeFilters, timezone)
  const queryParams = buildQueryParams(query)
  return axios.get(`/events/${eventId}/locations/stats/attendees_overtime${queryParams}`, getAxiosConfig())
    .then((response) => response.data.timeseries.map(data => ({ date: data.date, value: data.count })))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetCheckInOvertime(eventId, timeFilters, timezone) {
  const queryString = buildQueryParams(formatTimeQuery(timeFilters, timezone))
  return axios.get(`/events/${eventId}/stats/attendee.checked_in/overtime${queryString}`, getAxiosConfig())
    .then(response => response.data.timeseries.map(timeserie => ({ date: timeserie.date, value: timeserie.count })))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetCheckInOvertimeSum(eventId, timeFilters, timezone) {
  const queryString = buildQueryParams(formatTimeQuery(timeFilters, timezone))
  return axios.get(`/events/${eventId}/stats/attendee.checked_in/sum${queryString}`, getAxiosConfig())
    .then(response => response.data.count)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetTopicsTagsUsage(eventId) {
  return axios.get(`/events/${eventId}/topics/stats/top_tags/used`, getAxiosConfig())
    .then(response => (
      response.data.map(topic => ({
        count: topic.count,
        fieldValue: topic.tag.id,
        fieldLabel: topic.tag.name,
      }))
    )).catch(error => Promise.reject(buildError(error)))
}

export function serviceGetCurrentAndUpcomingMeetings(eventId, currentQuery, upcomingQuery) {
  const currentParams = buildQueryParams(currentQuery)
  const upcomingParams = buildQueryParams(upcomingQuery)
  const axiosConfig = getAxiosConfig()

  const getCurrentMeetings = () => axios.get(`/events/${eventId}/meetings/stats${currentParams}`, axiosConfig)
  const getUpcomingMeetings = () => axios.get(`/events/${eventId}/meetings/stats${upcomingParams}`, axiosConfig)

  return Promise.all([getCurrentMeetings(), getUpcomingMeetings()])
    .then(values => ({
      currentMeetings: values[0],
      upcomingMeetings: values[1],
      ...parsePaginationHeaders(values[1]),
    }))
}

export function serviceGetStatistics(eventId) {
  const axiosConfig = getAxiosConfig()
  const buildURL = (statType, sumOrAvg) => `/events/${eventId}/stats/${statType}/${sumOrAvg}?full_event=true`

  // finding the highest count and if it data is sum or average
  const formatData = (data) => {
    const dataType = (data.total.count !== undefined || null) ? 'sum' : 'average'
    const countKey = dataType === 'sum' ? 'count' : 'count_average'
    const countArray = data.days.map(day => day[countKey])
    countArray.push(data.pre_event[countKey], data.post_event[countKey])
    const max = Math.max(...countArray)
    return { ...data, maxDayCount: max, dataType: dataType }
  }

  const getTrackedAttendees = () => axios.get(`/events/${eventId}/stats/onsite_visits/sum?full_event=true&entity_count=true`, axiosConfig).then(response => formatData(response.data))
  const getSumTouchpoints = () => axios.get(buildURL('touchpoint_kliks', 'sum'), axiosConfig).then(response => formatData(response.data))
  const getAvgTouchpoints = () => axios.get(buildURL('touchpoint_kliks', 'average'), axiosConfig).then(response => formatData(response.data))
  const getSumContactExchanges = () => axios.get(buildURL('contact_exchanges', 'sum'), axiosConfig).then(response => formatData(response.data))
  const getAvgContactExchanges = () => axios.get(buildURL('contact_exchanges', 'average'), axiosConfig).then(response => formatData(response.data))
  const getSumActivityAttendance = () => axios.get(buildURL('session_attendances', 'sum'), axiosConfig).then(response => formatData(response.data))
  const getAvgActivityAttendance = () => axios.get(buildURL('session_attendances', 'average'), axiosConfig).then(response => formatData(response.data))
  const getSumChatRequests = () => axios.get(buildURL('conversation_invitations_created', 'sum'), axiosConfig).then(response => formatData(response.data))
  const getAcceptedChatRequests = () => axios.get(buildURL('conversation_invitations_accepted', 'sum'), axiosConfig).then(response => formatData(response.data))
  const getSumMeetingReqests = () => axios.get(buildURL('meeting_invitations_created', 'sum'), axiosConfig).then(response => formatData(response.data))
  const getSumScheduledMeetings = () => axios.get(buildURL('meeting_invitations_accepted', 'sum'), axiosConfig).then(response => formatData(response.data))

  return Promise.all([
    getTrackedAttendees(),
    getSumTouchpoints(),
    getAvgTouchpoints(),
    getSumContactExchanges(),
    getAvgContactExchanges(),
    getSumActivityAttendance(),
    getAvgActivityAttendance(),
    getSumChatRequests(),
    getAcceptedChatRequests(),
    getSumMeetingReqests(),
    getSumScheduledMeetings(),
  ])
    .then(values => ({
      trackedAttendees: values[0],
      sumTouchpoints: values[1],
      avgTouchpoints: values[2],
      sumContactExchanges: values[3],
      avgContactExchanges: values[4],
      sumActivityAttendance: values[5],
      avgActivityAttendance: values[6],
      sumChatRequests: values[7],
      acceptedChatRequests: values[8],
      sumMeetingRequests: values[9],
      sumScheduledMeetings: values[10],
    }))
    .catch(error => Promise.reject(buildError(error)))
}

