import axios from 'axios'
import config from 'config'
import { getAccessToken } from 'common/services/helper'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'


// /////////////////////////////////////////////////////////////////////////////
// Axios Configuration
// /////////////////////////////////////////////////////////////////////////////

function cfg(eventId) {
  return {
    baseURL: `${config.locatorUrl}/events/${eventId}`,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  }
}


// /////////////////////////////////////////////////////////////////////////////
// Stats
// /////////////////////////////////////////////////////////////////////////////

export function serviceGetZoneStats(eventId, groupby, success, error) {
  let q = ''
  if (groupby) {
    q = '?groupby=' + groupby
  }

  axios.get('/zones/stats' + q, cfg(eventId))
    .then((response) => success(response.data), apiErrorHandler(error, 'locator'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetZoneStatsOvertime(eventId, zone, groupby, start, end, success, error) {
  let q = '?'
  if (groupby) {
    q += '&groupby=' + groupby
  }
  if (start) {
    q += '&start=' + Math.floor(start)
  }
  if (end) {
    q += '&end=' + Math.floor(end)
  }

  axios.get(`/zones/${zone}/stats/overtime${q}`, cfg(eventId))
    .then((response) => success(response.data), apiErrorHandler(error, 'locator'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetZonesStatsOvertime(eventId, success, error) {
  return axios.get('/zones/stats/overtime', cfg(eventId))
    .then((response) => success(response.data), apiErrorHandler(error, 'locator'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetDeviceStats(eventId, success, error) {
  axios.get('/devices/stats?since=600', cfg(eventId))
    .then((response) => success(response.data), apiErrorHandler(error, 'locator'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetTagCounts(eventId, success, error) {
  return axios.get('/devices/stats/bytag', cfg(eventId))
    .then((response) => success(response.data), apiErrorHandler(error, 'locator'))
    .catch((response) => apiCatchError(response))
}
