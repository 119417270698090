import { serviceGetTrackingAnalytics, serviceGetTrackingZoneOverview } from 'admin/services/analytics'
import generateModule from 'common/utils/generateModule'
import moment from 'moment'


/**
 * Returns an object that contains the start date and the end date for the
 * time range selected.
 *
 * @param dateTimestamp Timestamp of the day we want to get the time range
 * @param timeOfDay Key for the time of day (morning, afternoon, etc.)
 */
function getTimeRange(dateTimestamp, timeOfDay, event) {
  const _timeOfDay = timeOfDay || 'day'
  let _dateTimestamp = dateTimestamp
  const timezone = event ? event.timezone : 'US/Eastern'

  if (!_dateTimestamp && event) {
    return { start: event.start_date, end: event.end_date }
  } else if (!_dateTimestamp) { // should not happen
    _dateTimestamp = moment().unix()
  }

  const startOfDay = moment(_dateTimestamp * 1000).tz(timezone).startOf('day')

  switch (_timeOfDay.toLowerCase()) {
    case 'morning':
      return {
        start: startOfDay.clone().add(6, 'hours').unix(),
        end: startOfDay.clone().add(12, 'hours').unix(),
      }

    case 'afternoon':
      return {
        start: startOfDay.clone().add(12, 'hours').unix(),
        end: startOfDay.clone().add(18, 'hours').unix(),
      }

    case 'evening':
      return {
        start: startOfDay.clone().add(18, 'hours').unix(),
        end: startOfDay.clone().add(24, 'hours').unix(),
      }

    case 'overnight':
      return {
        start: startOfDay.clone().add(24, 'hours').unix(),
        end: startOfDay.clone().add(30, 'hours').unix(),
      }

    default:
      return {
        start: startOfDay.clone().add(6, 'hours').unix(),
        end: startOfDay.clone().add(24, 'hours').unix(),
      }
  }
}


const { actions, reducers, initialState } = generateModule({
  itemName: 'tracking',
  autoRefresh: 3,
  getModuleState: (getState) => getState().admin.analytics.tracking,
  services: {
    get: () => { },
  },
})

initialState.timeSelector = {
  start: undefined,
  end: undefined,
  head: undefined,
  isPlaying: false,
  isLive: false,
}

initialState.filters = {
  mapId: undefined,
  date: undefined,
  time: undefined,
  attendeeType: undefined,
  zoneId: undefined,
}

initialState.data = {
  overtime: [],
  byTag: [],
  zoneOverview: undefined,
}


actions.resetTimeSelector = () =>
  dispatch => dispatch({ type: 'TRACKING_RESET_TIME_SELECTOR' })

reducers['TRACKING_RESET_TIME_SELECTOR'] = (state) => ({
  ...state,
  timeSelector: initialState.timeSelector,
})

actions.modifyTimeSelector = (timeSelector) =>
  dispatch => dispatch({ type: 'TRACKING_MODIFY_TIME_SELECTOR', data: { timeSelector } })

reducers['TRACKING_MODIFY_TIME_SELECTOR'] = (state, action) => ({
  ...state,
  timeSelector: { ...state.timeSelector, ...action.data.timeSelector },
})


actions.modifyFilters = (filters) =>
  (dispatch, getState) => {
    dispatch({ type: 'TRACKING_MODIFY_FILTERS', data: { filters } })
    const _filters = { ...getState().admin.analytics.tracking.filters, ...{ filters } }
    const currEvent = getState().admin.events.currentEvent

    if (_filters.zoneId) {
      const { start: startDate, end: endDate } = getTimeRange(_filters.date, _filters.time, currEvent.fields)

      return serviceGetTrackingZoneOverview(
        currEvent.id,
        _filters.zoneId,
        { start_date: startDate, end_date: endDate, group: _filters.attendeeType },
        (response) => dispatch({
          type: 'GET_TRACKINGS_SUCCESS',
          data: {
            zoneOverview: response,
          },
        }),
        () => { },
      )
    }

    return Promise.resolve()
  }

reducers['TRACKING_MODIFY_FILTERS'] = (state, action) => ({
  ...state,
  filters: { ...state.filters, ...action.data.filters },
})


actions.resetController = () =>
  dispatch => dispatch({ type: 'TRACKING_RESET_CONTROLLER' })

reducers['TRACKING_RESET_CONTROLLER'] = (state) => ({
  ...state,
  filters: initialState.filters,
  timeSelector: initialState.timeSelector,
})

actions.get = () =>
  (dispatch, getState) => {
    const filters = { ...getState().admin.analytics.tracking.filters }
    const currEvent = getState().admin.events.currentEvent
    const { start: startDate, end: endDate } = getTimeRange(filters.date, filters.time, currEvent.fields)

    const query = {
      start_date: startDate,
      end_date: endDate,
      group: filters.attendeeType,
    }

    dispatch(actions.getStart())
    return serviceGetTrackingAnalytics(
      currEvent.id,
      query,
      (response) => {
        dispatch({
          type: 'GET_TRACKINGS_SUCCESS',
          data: {
            overtime: response.overtime,
            byTag: response.byTag,
          },
        })
      },
      () => { },
    )
  }

reducers['GET_TRACKINGS_SUCCESS'] = (state, action) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  data: {
    ...state.data,
    ...action.data,
  },
})


reducers['CHANGE_EVENT_SUCCESS'] = (state) => ({
  ...state,
  didInvalidate: true,
  data: initialState.data,
  timeSelector: initialState.timeSelector,
  filters: initialState.filters,
})


export const getTrackingStats = actions.get

export const modifyTimeSelector = actions.modifyTimeSelector
export const modifyFilters = actions.modifyFilters
export const resetTimeSelector = actions.resetTimeSelector
export const resetController = actions.resetController

const tracking = reducers.index
export default tracking
