import { serviceGetCalendar } from 'attendee/services/sessions'
import generateModule from 'common/utils/generateModule'

const calendar = generateModule({
  itemName: 'calendar',
  itemNameDisplay: 'calendar',
  autoRefresh: 60,
  services: {
    get: serviceGetCalendar,
  },
  app: 'attendee',
  dataIsNotArray: true,
})

calendar.initialState = {
  isFetching: false,
  isLoaded: false,
  isError: false,
  didInvalidate: true,
  data: [],
}

calendar.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  ...calendar.initialState,
})

export default calendar
