import React from 'react'

export default function LoadingIcon(props) {
  let className = 'LoadingIcon'
  className += props.shouldFitParent ? ' fit-parent' : ''

  return (
    <div className={className} style={props.style}>
      <div className="LoadingIcon-circle" />
      <div className="LoadingIcon-circle" />
      <div className="LoadingIcon-circle" />
    </div>
  )
}
