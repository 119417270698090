import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetAttendeeOnSiteStats } from 'admin/services/dashboard'
import { eventTimezoneSelector } from 'admin/modules/events'
import Moment from 'moment'


const AttendeesOnSiteStatsModule = generateDataModule({
  initialData: {
    day: {},
    now: {},
  },
  moduleKey: 'dashboardAttendeesOnSiteStats',
  reducerKey: 'admin.dashboard.attendeesOnSiteStats',
  services: {
    get: { service: serviceGetAttendeeOnSiteStats },
  },
})


AttendeesOnSiteStatsModule.registerSelector('dayStats', (state, moduleState) => moduleState.data.day, data => data)
AttendeesOnSiteStatsModule.registerSelector('nowStats', (state, moduleState) => moduleState.data.now, data => data)

AttendeesOnSiteStatsModule.registerSelector(
  'hasHighUnpairing',
  state => AttendeesOnSiteStatsModule.selectors.nowStats(state),
  nowStats => nowStats.unpaired_active > nowStats.active * 0.10
)


AttendeesOnSiteStatsModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {
    const timezone = eventTimezoneSelector(getState())
    const currentTimestamp = Moment().unix()
    const startOfDayTimestamp = Moment.unix(currentTimestamp).tz(timezone).startOf('day').unix()

    dispatch({ type: AttendeesOnSiteStatsModule.actionKeys.getStart })

    const service = AttendeesOnSiteStatsModule._getService('get')
    return service(currentTimestamp, startOfDayTimestamp)
      .then(response => {
        dispatch({ type: AttendeesOnSiteStatsModule.actionKeys.getSuccess, payload: response })
      })
      .catch(() => {
        dispatch({ type: AttendeesOnSiteStatsModule.actionKeys.getError })
      })
  }
)

AttendeesOnSiteStatsModule.registerReducer(
  AttendeesOnSiteStatsModule.actionKeys.getSuccess,
  (state, action) => ({
    ...state,
    data: {
      day: action.payload.day,
      now: action.payload.now,
    },
    isLoaded: true,
    isLoading: false,
    lastUpdated: Date.now(),
  })
)


export default AttendeesOnSiteStatsModule
