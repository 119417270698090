import React, { Component } from 'react'
import { translate } from 'react-i18next'
import { getTextColor } from 'common/utils/domUtils'

import TitleLabel from 'common/containers/Login/TitleLabel'
import InputCode from 'common/components/InputCode'


@translate(['login'], { wait: true })
export default class EnterCode extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentCode: undefined,
      lastSubmittedCode: undefined,
      didChangeCode: false,
    }
  }

  componentWillMount() {
    // Cannot submit a code if we don't have the email
    if (!this.props.user.email) {
      this.props.changeLoginPage(this.props.location, 'email')
    }
  }

  clickSendNewCode = () => {
    this.props.changeLoginPage(this.props.location, 'send_code')
  }

  onSubmit = (e) => {
    e.preventDefault()
    document.activeElement.blur()

    const code = this.InputCode.getCode()
    this.setState({ lastSubmittedCode: code, didChangeCode: false, sendingCode: true })
    this.props.loginWithCode(code)
      .then(({ needDomainSelection }) => {
        if (needDomainSelection) {
          this.props.changeLoginPage(this.props.location, 'domain_selector')
        } else {
          // Everything should be fine. Redirect should happend in the auth reducer
        }
      })
      .catch(() => { /* */ })
      .then(() => this.setState({ sendingCode: false }))
  }

  render() {
    const { t, codeSentBy, loginErrorMessage } = this.props
    const { lastSubmittedCode, didChangeCode, sendingCode } = this.state
    const { brandColor } = this.props.loginBranding

    return (
      <div className="EnterCode">
        <div id="code-sent-by">{t(`Login code sent by ${codeSentBy}`)}</div>
        <TitleLabel id="enter-code" label={t('Enter Login Code')} uppercase />

        <form onSubmit={this.onSubmit}>
          <InputCode
            length={4}
            placeholder="1234"
            onCodeChange={(newCode) => this.setState({ currentCode: newCode, didChangeCode: true })}
            ref={ref => { this.InputCode = ref }}
          />

          {loginErrorMessage && lastSubmittedCode && !didChangeCode &&
            <div className="login-error-message-container">
              {loginErrorMessage}
            </div>}

          <button style={{ color: getTextColor(brandColor), backgroundColor: brandColor }} className="btn btn-block" type="submit">
            {sendingCode
              ? <i className="fa fa-spin fa-circle-o-notch" aria-hidden="true" />
              : t('login_password.log_in')}
          </button>
        </form>

        <TitleLabel id="send-new-code" label={t('Send a new code')} onClick={this.clickSendNewCode} />
      </div>
    )
  }
}
