import { generateTableModule } from './generateModule'
import {
  serviceCreateHub,
  serviceDeleteHub,
  serviceGetHubByInternalId,
  serviceGetHubs,
  serviceModifyHub,
  serviceReauthenticateHub,
  serviceRestartAmp,
  serviceRestartApp,
} from 'admin/services/hubs'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'


const HubsModule = generateTableModule({
  initialSort: {
    id: 'internal_id',
    order: 1,
  },
  module: {
    initialData: [],
    initialState: {
      columnsVisibility: {},
      hubDetails: undefined,
      pagination: {
        per_page: 10,
      },
      totalCount: 0, // TODO HUBREG: Update this so it reflects the right number
    },
    moduleKey: 'cpo/hubs',
    reducerKey: 'admin.hubs',
    refreshTime: 10000,
    services: {
      get: { service: serviceGetHubs },
      post: { service: serviceCreateHub },
      put: { service: serviceModifyHub },
      delete: { service: serviceDeleteHub },
    },
  },
  needsDataRefreshOnQueryChanges: true,
})


HubsModule.registerSelector(
  'hubDetails',
  (state, moduleState) => moduleState.hubDetails,
  (hub) => hub
)

HubsModule.registerSelector(
  'totalCount',
  (state, moduleState) => moduleState.totalCount,
  (count) => count
)


HubsModule.actions.get = (eventId) => (dispatch, getState) => {
  const queryParams = { ...HubsModule.selectors.queryParams(getState()) }

  dispatch({ type: HubsModule.actionKeys.getStart })

  return serviceGetHubs(eventId, queryParams)
    .then(response => {
      dispatch({ type: HubsModule.actionKeys.getSuccess, payload: response })
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({ type: HubsModule.actionKeys.getError })
      return Promise.reject(error)
    })
}


HubsModule.registerDataAction(
  'getHub',
  (internalId) => (dispatch) => {
    dispatch({ type: HubsModule.actionKeys.getHubStart })
    return serviceGetHubByInternalId(internalId)
      .then(response => {
        dispatch({
          payload: response,
          type: HubsModule.actionKeys.getHubSuccess,
        })
        return response
      })
      .catch(error => {
        dispatch({ type: HubsModule.actionKeys.getHubError })
        return Promise.reject(error)
      })
  }
)

HubsModule.registerReducer(
  HubsModule.actionKeys.getHubStart,
  (state) => ({
    ...state,
    hubDetails: undefined,
  })
)

HubsModule.registerReducer(
  HubsModule.actionKeys.getHubSuccess,
  (state, action) => ({
    ...state,
    hubDetails: action.payload,
  })
)


HubsModule.actions.post = data => dispatch => {
  dispatch({ type: HubsModule.actionKeys.postStart })

  return serviceCreateHub(data)
    .then(response => {
      dispatch({ type: HubsModule.actionKeys.postSuccess, payload: response })
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({ type: HubsModule.actionKeys.postError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('hubs.post.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject(error)
    })
}

// We don't want to add the created hub in the table on post
HubsModule.registerReducer(
  HubsModule.actionKeys.postSuccess,
  (state) => ({
    ...state,
    isModifying: false,
    lastUpdated: Date.now(),
  })
)


HubsModule.registerReducer(
  HubsModule.actionKeys.putSuccess,
  (state, action) => ({
    ...state,
    hubDetails: action.payload,
  })
)


HubsModule.registerReducer(
  HubsModule.actionKeys.deleteSuccess,
  (state) => ({
    ...state,
    hubDetails: undefined,
  })
)


HubsModule.registerDataAction(
  'restartAmp',
  (hubId) => (dispatch) => {
    dispatch({
      payload: { hubId },
      type: HubsModule.actionKeys.restartAmpStart,
    })

    return serviceRestartAmp(hubId)
      .then(() => {
        dispatch({
          payload: { hubId },
          type: HubsModule.actionKeys.restartAmpSuccess,
        })
      })
      .catch(() => {
        dispatch({
          payload: { hubId },
          type: HubsModule.actionKeys.restartAmpError,
        })
      })
  }
)


HubsModule.registerDataAction(
  'restartApp',
  (hubId) => (dispatch) => {
    dispatch({
      payload: { hubId },
      type: HubsModule.actionKeys.restartAppStart,
    })

    return serviceRestartApp(hubId)
      .then(() => {
        dispatch({
          payload: { hubId },
          type: HubsModule.actionKeys.restartAppSuccess,
        })
      })
      .catch(() => {
        dispatch({
          payload: { hubId },
          type: HubsModule.actionKeys.restartAppError,
        })
      })
  }
)


HubsModule.registerDataAction(
  'reauthenticate',
  (hubId) => (dispatch) => {
    dispatch({
      payload: { hubId },
      type: HubsModule.actionKeys.reauthenticateStart,
    })

    return serviceReauthenticateHub(hubId)
      .then((response) => {
        dispatch({
          payload: { hubId },
          type: HubsModule.actionKeys.reauthenticateSuccess,
        })
        return response
      })
      .catch(() => {
        dispatch({
          payload: { hubId },
          type: HubsModule.actionKeys.reauthenticateError,
        })
      })
  }
)


export default HubsModule
