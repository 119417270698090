import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { sanitizeLink } from 'common/utils/links'

import { isBizzaboEventSelector } from 'attendee/modules/events'
import * as appAC from 'common/modules/app'


@connect(
  state => ({
    app: state.app,
    isBizzaboEvent: isBizzaboEventSelector(state),
  }),
  dispatch => bindActionCreators({ ...appAC }, dispatch)
)
export default class ExternalLink extends Component {
  onClick = (e) => {
    if (this.props.app.isWebview && !this.props.isBizzaboEvent) {
      e.preventDefault()
      this.props.setExternalFrame({ isVisible: true, link: this.props.href })
    }

    if (this.props.onClick) {
      this.props.onClick(e)
    }
  }

  getTarget = () => {
    if (this.props.forceTarget) {
      return this.props.forceTarget
    }

    return this.props.app.isNativeApp ? '_blank' : this.props.target
  }

  // "noopener noreferrer" must ALWAYS be set when target is a new tab. We
  // prevent xss vulnerability doing so.
  getRel = (target) => (target === '_blank' ? 'noopener noreferrer' : this.props.rel)

  render() {
    const { href, className, style, id, disabled, extraProps } = this.props
    const target = this.getTarget() || '_blank'
    const rel = this.getRel(target)

    return (
      <a className={className} id={id} href={sanitizeLink(href)} onClick={this.onClick} target={target} rel={rel} style={style} disabled={disabled} {...extraProps}>
        {this.props.children}
      </a>
    )
  }
}

ExternalLink.defaultProps = {
  extraProps: {},
}
