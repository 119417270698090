import { serviceGetAttendeeLocation } from 'attendee/services/maps'
import generateModule from 'common/utils/generateModule'
import { getProperty } from 'common/utils/componentHelper'
import { createSelector } from 'reselect'
import { publicMapsByIdSelector } from './venueMaps'


const selectState = state => state.attendee.attAttendeeLocation
const selectLocation = state => getProperty(selectState(state).data, 'location')

export const attendeeLocationSelector = createSelector(
  selectLocation,
  publicMapsByIdSelector,
  (location, publicMapsById) => {
    const map = publicMapsById[getProperty(location, 'map')]
    return map ? location : undefined
  }
)


const attAttendeeLocation = generateModule({
  itemName: 'attAttendeeLocation',
  itemNameDisplay: 'attendeeLocation',
  toasterFieldName: 'id',
  dataIsNotArray: true,
  autoRefresh: 60,
  services: {
    get: serviceGetAttendeeLocation,
  },
  app: 'attendee',
})

attAttendeeLocation.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isLoaded: false,
  isError: false,
  didInvalidate: true,
  data: {},
})

attAttendeeLocation.actions.getAttendeeLocation = attAttendeeLocation.actions.get
export default attAttendeeLocation
