import * as d3 from 'd3'
import simpleheat from './simpleheat'

/*
 How squished together are two adjacent points heat map circles; Assumes a uniform distribution of points on the map.
 Below 1, two adjacent points' core colors will begin to overlap (you will see a sea of solid red)
 Above 2, two adjacent points' dissipation colors will no longer overlap (you will see islands of red)
*/
const SQUISH = 1.5

const DISSIPATION = 1.3 // how much to dissipate around the hotspot. This is not spacially-based.


export default function Heatmap(conf) {
  this.root = undefined
  this.mapImage = conf.mapImage
  this.conf = conf
  this.debug = conf.debug

  this.canvas = undefined
  this.heat = undefined
  this.dataPoints = []
  this.heatData = []
  this.dataURL = undefined
  this.legendMax = 0

  this.xyscale = d3.scaleLinear().domain([0, 1])

  this.create()
}


Heatmap.prototype.create = function create() {
  if (this.conf.id) {
    this.root = d3.select('#' + this.conf.id)
    this.root.selectAll('*').remove()
  } else {
    this.root = d3.select(document.createElement('div'))
  }

  if (this.conf.showLegend) {
    const legend = this.root.append('div').classed('legend', true)
    legend.append('span').classed('color', true).text('\uf111')
    this.legendMax = legend.append('span').classed('value', true)
  }

  const container = this.root
    .append('div')
    .classed('heatmap-container', true)

  this.canvas = container.append('canvas')
    .classed('heatmap-canvas', true)

  if (this.conf.id) {
    this.canvas.attr('id', `${this.conf.id}_heatmap`)
    this.heat = simpleheat(`${this.conf.id}_heatmap`)
  } else {
    this.heat = simpleheat(this.canvas.node())
  }

  this.setCanvasResolution(this.conf.canvasResolution || 500)

  if (this.mapImage) {
    this.updateMapImage(this.mapImage)
  }
}


Heatmap.prototype.update = function update(points, setmax) {
  this._updateHeatmapData(points, setmax)
  this.heat.draw()
}

Heatmap.prototype._updateHeatmapData = function _updateHeatmapData(points, setmax) {
  if (points) {
    this.dataPoints = points
  }
  this.heatData = this.dataPoints.map(d => [this.xyscale(d.x), this.xyscale(d.y), d.value])
  this.heat.data(this.heatData)

  const max = setmax || Math.max(...this.heatData.map(d => d[2])) || 1 // max of 0 screws up heatmap
  this.heat.max(max)
  if (this.conf.showLegend) {
    this.legendMax.text(max)
  }

  const meanSeparation = Math.sqrt(this.canvasWidth * this.canvasHeight / this.heatData.length)
  const pointRadius = meanSeparation / 2 / SQUISH
  this.heat.radius(pointRadius, pointRadius * DISSIPATION)

  this.dataURL = null
}

Heatmap.prototype.updateMapImage = function updateMapImage(mapImage) {
  this.mapImage = mapImage

  const img = new Image()
  const self = this
  img.onload = function onload() {
    const imgHeight = this.height
    const imgWidth = this.width
    const imgRatio = self.imgRatio = imgWidth / imgHeight

    self.setCanvasResolution(self.canvasResolution, imgRatio)

    self.canvas
      .style('background', "url('" + self.mapImage + "')")
      .style('background-size', 'contain')
      .style('background-repeat', 'no-repeat')

    self.heat.resize()
    self.heat.draw()
  }

  img.src = this.mapImage
}

Heatmap.prototype.draw = function draw() {
  this.heat.draw()
}

Heatmap.prototype.setCanvasResolution = function setCanvasResolution(resolution, aspectRatio = 1) {
  this.canvasResolution = resolution || this.canvasResolution
  const fitParent = this.conf.fitParent

  if (fitParent) {
    // Reset the size so we get the available size of the parent
    this.canvas.attr('width', 0)
    this.canvas.attr('height', 0)

    const parentRect = this.canvas.node().parentElement.getBoundingClientRect()

    if (parentRect.height < parentRect.width) {
      this.canvasHeight = parentRect.height
      this.canvasWidth = this.canvasHeight * (this.imgRatio || 1)
    } else {
      this.canvasWidth = parentRect.width
      this.canvasHeight = this.canvasWidth / (this.imgRatio || 1)
    }

    this.xyscale.range([0, Math.max(this.canvasWidth, this.canvasHeight)])
  } else {
    this.canvasHeight = this.canvasWidth = this.canvasResolution

    if (aspectRatio > 1) {
      this.xyscale.range([0, this.canvasWidth])
      this.canvasHeight = this.canvasWidth / aspectRatio
    } else {
      this.xyscale.range([0, this.canvasHeight])
      this.canvasWidth = this.canvasHeight * aspectRatio
    }
  }

  this.canvas
    .attr('width', this.canvasWidth)
    .attr('height', this.canvasHeight)

  this._updateHeatmapData()
  this.heat.resize()
  this.heat.draw()
}

Heatmap.prototype.getImageDataURL = function getImageDataURL() {
  if (!this.dataURL) {
    this.dataURL = this.canvas.node().toDataURL()
  }
  return this.dataURL
}
