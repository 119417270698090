import { generateDataModule } from './generateModule'
import { serviceGetDocToken } from 'admin/services/users'


const DocTokenModule = generateDataModule({
  moduleKey: 'cpo/docToken',
  reducerKey: 'admin.docToken',
  initialData: {},

  services: {
    get: { service: serviceGetDocToken },
  },
})

export default DocTokenModule
