import React, { Component } from 'react'
import { getTranslatedValue } from 'common/utils/translator'
import { getProperty } from 'common/utils/componentHelper'
import Heatmap from './heatmap'

import { styles } from './styles.scss'

export default class HeatMap extends Component {
  componentDidMount() {
    this.chart = new Heatmap({
      id: this.props.id || 'heatmap_container',
      mapImage: getTranslatedValue(this.props.map.image_url),
      showLegend: this.props.showLegend,
      ...this.props.heatmapConfig,
    })
    this.componentDidUpdate()

    window.addEventListener('resize', this.onWindowResized)
  }

  componentWillReceiveProps(newProps) {
    if (this.props.map.id !== newProps.map.id) {
      this.chart.updateMapImage(getTranslatedValue(newProps.map.image_url))
    }
  }

  componentDidUpdate(prevProps) {
    const tagsbyid = new Map(this.props.tags.map(t => [t.id, t]))
    const mergedstats = this.props.tagCounts.map(s => ({ tag: tagsbyid.get(s.tag), count: s.count }))
    const mappedstats = mergedstats.filter(s => s.tag !== undefined && s.tag.x && s.tag.map === this.props.map.id)
    const data = mappedstats.map(s => ({ x: s.tag.x, y: s.tag.y, value: s.count }))

    this.chart.update(data)

    const canvasResolution = getProperty(this.props, 'heatmapConfig.canvasResolution')
    if (canvasResolution && getProperty(prevProps, 'heatmapConfig.canvasResolution') !== canvasResolution) {
      this.chart.setCanvasResolution(canvasResolution, this.chart.imgRatio)
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.onWindowResized)
  }

  onWindowResized = () => {
    if (getProperty(this.props, 'heatmapConfig.fitParent')) {
      requestAnimationFrame(() => {
        this.chart.setCanvasResolution()
      })
    }
  }

  render() {
    return <div id={this.props.id || 'heatmap_container'} className={styles} style={{ height: this.props.height || '100%', position: 'relative' }} />
  }
}
