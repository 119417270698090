import { serviceGetLeads, serviceGetLead, serviceGetLeadContacts, serviceGetLeadStats, serviceUpdateLeadNotes, serviceDeleteLead, serviceGetLeadsBreakdownStats } from 'admin/services/exhibitors'
import { serviceGetQualifiersByLead } from 'exhibitor/services/qualifiers'
import generateModule from 'common/utils/generateModule'
import { createSelector } from 'reselect'
import { getProperty } from 'common/utils/componentHelper'

function getEventId(getState) {
  return getState().exhibitor.exhibitors.currentExhibitor.event_id
}

function getExhibitorId(getState) {
  return getState().exhibitor.exhibitors.currentExhibitor.id
}

const selectState = state => state.exhibitor.leads
const selectLeads = state => state.exhibitor.leads.data
const selectCurrentLead = state => state.exhibitor.leads.currentLead

export const selectors = {
  leadsCount: createSelector(selectLeads, leads => {
    const data = []
    const history = []
    leads.forEach(lead => {
      const rating = lead.rating != null ? Math.round(lead.rating) : null
      if (history.includes(rating)) {
        data.filter(obj => obj.rating === rating)[0].count += 1;
      } else {
        data.push({
          rating: rating,
          count: 1,
        })
        history.push(rating)
      }
    })
    return data;
  }),
  getQualifiers: createSelector(selectCurrentLead, lead => getProperty(lead, 'qualifiers') || []),
  leadsBreakdownTotalCount: createSelector(selectState, state => state.breakdown.total),
  leadsBreakdownGroupedByRatings: createSelector(selectState, state => state.breakdown.ratings),
}


const { actions, reducers, initialState } = generateModule({
  itemName: 'lead',
  getModuleState: (getState) => getState().exhibitor.leads,
  getEventId: getEventId,
  idFieldName: 'id',
  app: 'exhibitor',
  services: {
    get: serviceGetLeads,
  },
})

initialState.currentLead = undefined
initialState.pageCount = 0
initialState.breakdown = {
  total: 0,
  ratings: {},
}


actions.getLeads = (query) =>
  (dispatch, getState) => {
    dispatch({ type: 'CPE_GET_LEADS_START' })

    return serviceGetLeads(
      getEventId(getState),
      getExhibitorId(getState),
      query,
      (response) => {
        dispatch({ type: 'CPE_GET_LEADS_SUCCESS', payload: { leads: response } })
        return Promise.resolve(response)
      },
      () => {
        dispatch({ type: 'CPE_GET_LEADS_ERROR' })
        return Promise.reject()
      }
    )
  }

reducers['CPE_GET_LEADS_START'] = (state) => ({
  ...state,
  isFetching: true,
  isError: false,
})

reducers['CPE_GET_LEADS_SUCCESS'] = (state, action) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  data: action.payload.leads,
})

reducers['CPE_GET_LEADS_ERROR'] = (state) => ({
  ...state,
  isFetching: false,
  isError: false,
  data: [],
})


actions.getLead = (leadId) =>
  (dispatch, getState) => {
    dispatch({ type: 'CPE_GET_LEAD_START' })
    const eventId = getEventId(getState)
    const exhibitorId = getExhibitorId(getState)

    const getLead = () => serviceGetLead(
      eventId,
      exhibitorId,
      leadId,
      (response) => Promise.resolve(response),
      (error) => Promise.resolve(error)
    )

    const getLeadContacts = () => serviceGetLeadContacts(
      eventId,
      exhibitorId,
      leadId,
      (response) => Promise.resolve(response),
      (error) => Promise.resolve(error)
    )

    const getLeadStats = () => serviceGetLeadStats(
      eventId,
      exhibitorId,
      leadId,
      (response) => Promise.resolve(response),
      (error) => Promise.resolve(error),
    )

    const getLeadQualifiers = () => serviceGetQualifiersByLead(
      getEventId(getState),
      getExhibitorId(getState),
      leadId
    )

    return Promise.all([getLead(), getLeadContacts(), getLeadStats(), getLeadQualifiers()])
      .then((values) => {
        const lead = values[0]
        lead.representatives = [...values[1]]
        lead.stats = { ...values[2] }
        lead.qualifiers = [...values[3]]

        dispatch({ type: 'CPE_GET_LEAD_SUCCESS', payload: { lead } })
        return Promise.resolve()
      })
      .catch(() => dispatch({ type: 'CPE_GET_LEAD_ERROR' }))
  }


reducers['CPE_GET_LEAD_SUCCESS'] = (state, action) => ({
  ...state,
  currentLead: action.payload.lead,
})


actions.updateLeadNotes = (accountId, notes) =>
  (dispatch, getState) => {
    dispatch({ type: 'CPE_UPDATE_LEAD_NOTES_START', payload: { accountId, notes } })

    return serviceUpdateLeadNotes(
      getEventId(getState),
      getExhibitorId(getState),
      accountId,
      notes,
      () => dispatch({ type: 'CPE_UPDATE_LEAD_NOTES_SUCCESS', payload: { accountId, notes } }),
      () => dispatch({ type: 'CPE_UPDATE_LEAD_NOTES_ERROR', payload: { accountId, notes } })
    )
  }

actions.deleteLead = (accountId) =>
  (dispatch, getState) => {
    dispatch({ type: 'CPE_DELETE_LEAD_START', payload: { accountId } })

    return serviceDeleteLead(
      getEventId(getState),
      getExhibitorId(getState),
      accountId,
      () => dispatch({ type: 'CPE_DELETE_LEAD_SUCCESS', payload: { accountId } }),
      () => dispatch({ type: 'CPE_DELETE_LEAD_ERROR', payload: { accountId } })
    )
  }


actions.getLeadsBreakdownStats = () => (dispatch, getState) => {
  dispatch({ type: 'CPE_GET_LEADS_BREAKDOWN_STATS_START' })

  return serviceGetLeadsBreakdownStats(getEventId(getState), getExhibitorId(getState))
    .then(response => dispatch({
      type: 'CPE_GET_LEADS_BREAKDOWN_STATS_SUCCESS',
      payload: {
        totalCount: response.count || 0,
        ratings: response.ratings || {},
      },
    }))
    .catch(() => dispatch({ type: 'CPE_GET_LEADS_BREAKDOWN_ERROR' }))
}

reducers['CPE_GET_LEADS_BREAKDOWN_STATS_SUCCESS'] = (state, action) => ({
  ...state,
  breakdown: action.payload,
})


export const getLead = actions.getLead
export const getLeads = actions.getLeads
export const deleteLead = actions.deleteLead
export const updateLeadNotes = actions.updateLeadNotes
export const getQualifiersByLead = actions.getQualifiersByLead
export const getLeadsBreakdownStats = actions.getLeadsBreakdownStats

const leads = reducers.index
export default leads
