import { serviceGetNotifications, serviceClickNotification, serviceReadNotification } from 'attendee/services/notifications'
import generateModule from 'common/utils/generateModule'
import { createSelector } from 'reselect'


const removeDuplicates = (notifications) => {
  const _notifications = [];
  const checkIfDuplicate = {}
  notifications.forEach(n => {
    if (n.type === 'video_call_request') {
      if (!checkIfDuplicate[n.call_to_action.url]) {
        checkIfDuplicate[n.call_to_action.url] = true;
        _notifications.push(n)
      }
    } else {
      _notifications.push(n)
    }
  })
  return _notifications
}

const selectNotifications = state => state.attendee.notifications.data

export const notificationsSelector = createSelector(selectNotifications, notificationsData => notificationsData.notifications)
export const unclickedNotificationsSelector = createSelector(notificationsSelector, notifications => {
  const _notifications = removeDuplicates(notifications)
  return _notifications.filter(n => n.read === false)
})


const notifications = generateModule({
  itemName: 'notification',
  itemNameDisplay: 'notification',
  autoRefresh: 60,
  services: {
    get: serviceGetNotifications,
  },
  app: 'attendee',
})

notifications.initialState.data = {
  notifications: [],
}

notifications.actions.clickNotification = notification =>
  (dispatch, getState) => {
    serviceClickNotification(
      getState().attendee.attEvents.currEvent.id,
      notification,
      (response) => {
        const newNotifications = getState().attendee.notifications.data.notifications.map(_notification => (_notification.id === notification.id
          ? { ...response, clicked: true, read: true }
          : _notification
        ))
        dispatch({ type: 'CLICK_NOTIFICATION', payload: { notifications: newNotifications } })
      },
      () => { }
    )
  }

notifications.reducers['CLICK_NOTIFICATION'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  data: {
    notifications: action.payload.notifications,
  },
})


notifications.actions.readNotifications = () =>
  (dispatch, getState) => {
    serviceReadNotification(
      getState().attendee.attEvents.currEvent.id,
      () => {
        dispatch({ type: 'READ_NOTIFICATIONS' })
        dispatch(notifications.actions.get())
      },
      () => { }
    )
  }

notifications.reducers['READ_NOTIFICATIONS'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
})


notifications.actions.receiveNotification = (notification) =>
  dispatch => dispatch({ type: 'RECEIVE_NOTIFICATION', payload: { notification } })

notifications.reducers['RECEIVE_NOTIFICATION'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  data: {
    notifications: [action.payload.notification, ...state.data.notifications],
  },
})

notifications.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isLoaded: false,
  isError: false,
  didInvalidate: true,
  data: [],
})

notifications.actions.clickNotification = notifications.actions.clickNotification
notifications.actions.readNotifications = notifications.actions.readNotifications
export default notifications
