import { serviceGetTopicsTagsUsage } from 'admin/services/analytics'
import generateAnalyticsModule from 'admin/utils/generateAnalyticsModule'
import { createSelector } from 'reselect'

function sortByCountDescending(statA, statB) {
  return statB.count - statA.count
}

const selectState = (state) => state.admin.analytics.topicsTags
const selectTagStats = (state) => selectState(state).data

export const topicsTagsModuleSelector = createSelector(selectState, (state) => state)
export const topicsTagsMostEngagedSelector = createSelector(selectTagStats, (tagStats) => [...tagStats.mostEngaged].sort(sortByCountDescending))
export const topicsTagsMostUsedSelector = createSelector(selectTagStats, (tagStats) => [...tagStats.mostUsed].sort(sortByCountDescending))


const topicsTagsModule = generateAnalyticsModule({
  moduleKey: 'topicsTags',
  initialData: {
    mostEngaged: [],
    mostUsed: [],
  },
  getter: serviceGetTopicsTagsUsage,
})

export default topicsTagsModule
