import { BaseModule } from 'redux-data-module'

import initializeSelectors from './Selectors'
import initializeActions from './Actions'
import initializeReducers from './Reducers'


const MapGraphicsModule = new BaseModule({
  moduleKey: 'mapGraphics',
})

MapGraphicsModule._initialMapState = {
  drawingMode: null,
  selection: [],
}

initializeSelectors(MapGraphicsModule)
initializeActions(MapGraphicsModule)
initializeReducers(MapGraphicsModule)


export default MapGraphicsModule
