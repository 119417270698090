import generateModule from 'common/utils/generateModule'


export function getInitialState(filterId) {
  switch (filterId) {
    case 'badges':
      return {
        search: '',
        tags: [],
        tagsConditionType: 'OR',
      }

    case 'timeline':
      return {
        search: '',
        date: '',
        type: '',
      }

    case 'topics':
      return {
        search: '',
        tags: [],
      }

    case 'recommendations':
      return {
        customFields: {},
        query: '',
        tags: [],
      }

    case 'speakers':
      return {
        search: '',
      }

    case 'exhibitors':
      return {
        search: '',
      }

    case 'contacts':
      return {
        search: '',
        sortType: '',
        filterType: '',
        showFavoritesOnly: false,
      }

    case 'myschedule':
    case 'schedule':
      return {
        search: '',
        selectedDate: undefined,
        calendarTypes: [],
        location: '',
        hideSessionsAtFullCapacity: false,
        hideSessionsConflicting: false,
        expandAllSessions: false,
        showDatesWithNoSessions: false,
        showFavoritesOnly: false,
        showSavedOnly: false,
        showConfirmedOnly: false,
        tags: [],
        tagsConditionType: 'OR', // AND || OR
      }

    default:
      throw new Error('Unknown Filter ID')
  }
}


export default function generateFilterModule(filterId) {
  const uppercaseFilterId = filterId.toUpperCase()
  const module = generateModule({
    itemName: `filter-${filterId}`,
    getModuleState: (getState) => getState().attendee.filterSettings[filterId],
    dataIsNotArray: true,
    autoRefresh: 60,
    services: {},
    app: 'attendee',
  })

  module.initialState.data = getInitialState(filterId)

  module.actions.setFilters = (filterSettings) =>
    dispatch => {
      dispatch({
        type: `ATTENDEE_SET_FILTER_SETTINGS_${uppercaseFilterId}`,
        data: { filterSettings },
      })
      return Promise.resolve()
    }

  module.reducers[`ATTENDEE_SET_FILTER_SETTINGS_${uppercaseFilterId}`] = (state, action) => ({
    ...state,
    data: { ...state.data, ...action.data.filterSettings },
  })

  module.actions.changeFilters = (id, value) =>
    dispatch => {
      dispatch({
        type: `ATTENDEE_CHANGE_FILTER_SETTINGS_${uppercaseFilterId}`,
        data: { [id]: value },
      })

      return Promise.resolve()
    }

  module.reducers[`ATTENDEE_CHANGE_FILTER_SETTINGS_${uppercaseFilterId}`] = (state, action) => ({
    ...state,
    data: { ...state.data, ...action.data },
  })

  module.actions.clearFilters = () =>
    dispatch => {
      dispatch({ type: `ATTENDEE_CLEAR_FILTER_SETTINGS_${uppercaseFilterId}` })
      return Promise.resolve()
    }

  module.reducers[`ATTENDEE_CLEAR_FILTER_SETTINGS_${uppercaseFilterId}`] = (state) => {
    let data = { ...module.initialState.data }
    if (filterId === 'schedule') {
      data = { ...data, selectedDate: state.data.selectedDate }
    }

    return { ...state, data: data }
  }

  module.actions.clearSomeFilters = (ids) =>
    dispatch => {
      dispatch({ type: `ATTENDEE_CLEAR_SOME_FILTER_SETTINGS_${uppercaseFilterId}`, payload: { ids } })
      return Promise.resolve()
    }

  module.reducers[`ATTENDEE_CLEAR_SOME_FILTER_SETTINGS_${uppercaseFilterId}`] = (state, action) => {
    const data = {}
    action.payload.ids.forEach(id => { data[id] = module.initialState.data[id] })
    return { ...state, data: { ...state.data, ...data } }
  }


  module.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
    ...state,
    isFetching: false,
    isLoaded: false,
    isError: false,
    didInvalidate: true,
    data: module.initialState.data,
  })

  return module
}
