import * as common from './common'

const config = {
  // keep alphabetized
  assetsUrl: 'https://cdn.klik.co',
  apiUrl: 'https://api.klik.co',
  appUrl: 'https://app.klik.co',
  envName: 'production',
  locatorUrl: 'https://tracker.klik.co',
  ...common,
  // This must be placed at the end
  authProvider: {
    ...common.authProvider,
    eventbrite: {
      ...common.authProvider.eventbrite,
      clientId: 'HA7C4HD5QPHMSFBDQQ',
    },
  },
}

config.exportUrl = 'https://export.klik.co'
config.twilioServiceUrl = config.apiUrl

module.exports = config
