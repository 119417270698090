import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetTopicsTagsUsage } from 'admin/services/dashboard'


const TopicsModule = generateDataModule({
  initialData: {},
  moduleKey: 'dashboardTopics',
  reducerKey: 'admin.dashboard.topics',
  services: {
    get: { service: serviceGetTopicsTagsUsage },
  },
})

TopicsModule.registerSelector('minimalData', (state, moduleState) => moduleState.data, data => data.slice(0, 5))


TopicsModule.registerDataAction(
  'get',
  () => dispatch => {
    dispatch({ type: TopicsModule.actionKeys.getStart })

    return TopicsModule._getService('get')()
      .then(response => {
        dispatch({ type: TopicsModule.actionKeys.getSuccess, payload: response })
      })
      .catch(() => {
        dispatch({ type: TopicsModule.actionKeys.getError })
      })
  }
)

export default TopicsModule
