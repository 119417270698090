import { serviceGetTags, serviceAddTag, serviceUpdateTags, serviceDeleteTag } from 'admin/services/tags'
import generateModule from 'common/utils/generateModule'
import { showToast } from 'common/modules/toaster'
import { getTranslatedValue } from 'common/utils/translator'
import i18n from 'i18next'
import { eventLanguageSelector } from 'admin/modules/events'
import { createSelector } from 'reselect'
import { listToMapping } from 'common/utils/componentHelper'


const selectState = (state) => state.admin.tags

export const tagsSelector = createSelector(selectState, (state) => state.data)
export const tagsByIdSelector = createSelector(tagsSelector, tags => listToMapping(tags, 'id'))

export const sortedTagsSelector = createSelector(
  tagsSelector,
  eventLanguageSelector,
  (tags, eventLanguage = 'en') => tags.sort((a, b) => {
    const nameA = getTranslatedValue(a.name, eventLanguage)
    const nameB = getTranslatedValue(b.name, eventLanguage)
    return nameA.localeCompare(nameB)
  })
)


const { actions, reducers, initialState } = generateModule({
  itemName: 'tag',
  itemNameDisplay: 'tag',
  services: {
    get: serviceGetTags,
    add: serviceAddTag,
  },
})

initialState.prefs = {
  map: true,
  zones: true,
  beacons: false,
  attendees: true,
}

actions.addTag = (tag) =>
  (dispatch, getState) => {
    dispatch({ type: 'ADD_TAG_START' })
    return serviceAddTag(
      getState().admin.events.currentEvent.id,
      tag,
      (response) => {
        dispatch({ type: 'ADD_TAG_SUCCESS', payload: { tag: response } })
        return Promise.resolve(response)
      },
      (error) => {
        dispatch({ type: 'ADD_TAG_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('tags.add.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['ADD_TAG_START'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: true,
  isError: false,
})

reducers['ADD_TAG_SUCCESS'] = (state, action) => { // eslint-disable-line dot-notation
  const addNewTag = () => {
    const newTag = Object.assign({}, action.payload.tag)
    let index = -1
    for (let i = 0; i < state.data; i++) {
      if (state.data[i].id === newTag) {
        index = i;
        break
      }
    }

    const newData = state.data.slice(0)
    if (index !== -1) newData[index] = newTag
    else newData.push(newTag)

    return newData
  }

  return {
    ...state,
    data: addNewTag(),
    isModifying: false,
  }
}

reducers['ADD_TAG_ERROR'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: false,
  isError: true,
})


actions.updateTags = (tags) =>
  (dispatch, getState) => {
    dispatch({ type: 'UPDATE_TAGS_START' })
    return serviceUpdateTags(
      getState().admin.events.currentEvent.id,
      tags,
      (response) => {
        dispatch({ type: 'UPDATE_TAGS_SUCCESS', payload: { tags: response } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('tags.update.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('tags.update.success'),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch({ type: 'UPDATE_TAGS_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('tags.update.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['UPDATE_TAGS_START'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: true,
  isError: false,
})

reducers['UPDATE_TAGS_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  data: action.payload.tags.tags,
  isModifying: false,
})

reducers['UPDATE_TAGS_ERROR'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: false,
  isError: true,
})


actions.deleteTag = (tagId) =>
  (dispatch, getState) => {
    dispatch({ type: 'DELETE_TAG_START' })
    return serviceDeleteTag(
      getState().admin.events.currentEvent.id,
      tagId,
      () => {
        dispatch({ type: 'DELETE_TAG_SUCCESS', payload: { id: tagId } })
        return Promise.resolve(tagId)
      },
      (error) => {
        dispatch({ type: 'DELETE_TAG_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('tags.delete.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
        return Promise.reject(error)
      }
    )
  }

reducers['DELETE_TAGS_START'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: true,
  isError: false,
})

reducers['DELETE_TAGS_SUCCESS'] = (state, action) => ({
  ...state,
  data: [...state.data.filter(d => d.id !== action.payload.id)],
  isModifying: false,
})

reducers['DELETE_TAGS_ERROR'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: false,
  isError: true,
})


export const getTags = actions.get
export const addTag = actions.addTag
export const updateTags = actions.updateTags
export const deleteTag = actions.deleteTag
const tags = reducers.index
export default tags
