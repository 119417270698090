import { serviceGetProfile, serviceGetAttendeeLocation, serviceGetAttendeeLogs, serviceGetProfileStats, serviceGetAccount, serviceAddAdmin, serviceAddAttendee, serviceUpdateProfile, serviceDeleteCredential, serviceGetActivities, serviceAddActivity, serviceAddAchievement } from 'admin/services/users'
import { serviceGetExhibitor } from 'admin/services/exhibitors'
import { showToast } from 'common/modules/toaster'
import { setAccountMergeModal } from 'admin/modules/app'
import i18n from 'i18next'
import { getProperty } from 'common/utils/componentHelper'
import { composePromises } from 'common/utils/promiseHelper'

import AttendeesModule from './attendees'


// //////////////////////////////////////////////////////////////////////////////
// Location
// //////////////////////////////////////////////////////////////////////////////

function getLocationStart() {
  return {
    type: 'GET_LOCATION_START',
    data: {
      status: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.get_events_device.start'),
    },
  }
}

function getLocationSuccess(json) {
  return {
    type: 'GET_LOCATION_SUCCESS',
    data: {
      events: json,
      receivedAt: Date.now(),
    },
  }
}

function getLocationError(status) {
  return {
    type: 'GET_LOCATION_ERROR',
    data: {
      status: status,
      receivedAt: Date.now(),
    },
  }
}

export function getAttendeeLocation(eventId, attendeeId, showError = true) {
  return (dispatch) => {
    dispatch(getLocationStart())
    return serviceGetAttendeeLocation(
      eventId,
      attendeeId,
      (result) => {
        dispatch(getLocationSuccess(result))
      },
      (error) => {
        dispatch(getLocationError(error.description))
        if (showError) {
          dispatch(showToast({
            title: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.get_events_device.start'),
            message: error.description,
            level: 'error',
            permanent: false,
          }))
        }
      }
    )
  }
}

export function getCurrentUserProfile() {
  return (dispatch) => {
    dispatch({ type: 'RETRIEVE_CURRENT_USER_PROFILE_START' })
    serviceGetAccount(
      (result) => dispatch({
        type: 'RETRIEVED_CURRENT_USER_PROFILE',
        data: { profile: result },
      }),
      () => dispatch({
        type: 'RETRIEVED_CURRENT_USER_PROFILE',
        data: { profile: { first_name: 'Not', last_name: 'Attending' } },
      })
    )
  }
}


// //////////////////////////////////////////////////////////////////////////////
// Profile
// //////////////////////////////////////////////////////////////////////////////

export function clearProfile() {
  return { type: 'CLEAR_PROFILE' }
}

function getProfileStart(profileId) {
  return {
    type: 'GET_PROFILE_START',
    data: {
      profileId: profileId,
      status: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.get_profile.start'),
    },
  }
}

function getProfileSuccess(json) {
  return {
    type: 'GET_PROFILE_SUCCESS',
    data: {
      profile: json,
      receivedAt: Date.now(),
      status: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.get_profile.success'),
    },
  }
}

function getProfileError(status) {
  return {
    type: 'GET_PROFILE_ERROR',
    data: {
      status: status,
      receivedAt: Date.now(),
    },
  }
}

export function getProfile(profileId) {
  return (dispatch, getState) => {
    dispatch(getProfileStart(profileId))

    if (getState().admin.permissions.action.viewAttendeesLocation) {
      dispatch(getAttendeeLocation(getState().admin.events.currentEvent.id, profileId))
    }

    const eventId = getState().admin.events.currentEvent.id

    const _getProfile = () => serviceGetProfile(
      eventId,
      profileId,
      (response) => Promise.resolve(response),
      (error) => Promise.reject(error)
    )

    const getExhibitor = (exhibitorId) => serviceGetExhibitor(eventId, exhibitorId)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))

    return _getProfile()
      .then((profileResponse) => {
        const exhibitorId = getProperty(profileResponse.representative, 'exhibitor_id')

        return (!exhibitorId)
          ? Promise.resolve(profileResponse)
          : getExhibitor(exhibitorId).then(exhibitorResponse => Promise.resolve({ ...profileResponse, exhibitor: { ...exhibitorResponse } }))
      })
      .then((profileData) => {
        dispatch(getProfileSuccess(profileData))
        return Promise.resolve()
      })
      .catch((error) => {
        dispatch(getProfileError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.get_profile.start'),
          message: error.description,
          name: 'get_profile_error',
          level: 'error',
          permanent: false,
        }))

        return Promise.reject()
      })
  }
}

export function deleteCredential(credential, type = 'email') {
  return (dispatch, getState) => serviceDeleteCredential(
    getState().admin.events.currentEvent.id,
    getState().admin.profile.profile.id,
    `${type}:${encodeURIComponent(credential)}`,
    () => dispatch({ type: 'DELETE_ATTENDEE_CREDENTIAL', data: { credential: credential } }),
    (error) => dispatch(showToast({
      title: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.delete_credential'),
      message: error.description,
      level: 'error',
      permanent: false,
    }))
  )
}

function shouldFetchProfile(state) {
  const data = state.profile
  let retValue = false
  if (!data) {
    retValue = true
  } else if (data.isFetching) {
    retValue = false
  } else {
    retValue = data.didInvalidate
  }
  return retValue
}

export function getProfileIfNeeded(profileId) {
  return (dispatch, getState) => {
    if (shouldFetchProfile(getState())) {
      return dispatch(getProfile(profileId))
    }
    return {}
  }
}


// //////////////////////////////////////////////////////////////////////////////
// Activities
// //////////////////////////////////////////////////////////////////////////////

function getProfileActivitiesStart() {
  return {
    type: 'GET_PROFILE_ACTIVITIES_START',
    data: {
      receivedAt: Date.now(),
      status: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.get_profile_activities.start'),
    },
  }
}

function getProfileActivitiesSuccess(activities) {
  return {
    type: 'GET_PROFILE_ACTIVITIES_SUCCESS',
    data: {
      activities: activities,
      receivedAt: Date.now(),
      status: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.get_profile_activities.success'),
    },
  }
}

function getProfileActivitiesError(status) {
  return {
    type: 'GET_PROFILE_ACTIVITIES_ERROR',
    data: {
      status: status,
      receivedAt: Date.now(),
    },
  }
}

export function getProfileActivities(profileId) {
  return (dispatch, getState) => {
    dispatch(getProfileActivitiesStart())
    serviceGetActivities(
      getState().admin.events.currentEvent.id,
      profileId,
      (response) => {
        dispatch(getProfileActivitiesSuccess(response))
      },
      (error) => {
        dispatch(getProfileActivitiesError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.get_profile_activities.start'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}

function addCustomActivityStart() {
  return {
    type: 'ADD_CUSTOM_ACTIVITY_START',
    data: {
      receivedAt: Date.now(),
      status: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.add_custom_activity.start'),
    },
  }
}

function addCustomActivitySuccess(activity) {
  return {
    type: 'ADD_CUSTOM_ACTIVITY_SUCCESS',
    data: {
      activity: activity,
      receivedAt: Date.now(),
    },
  }
}

function addCustomActivityError(status) {
  return {
    type: 'ADD_CUSTOM_ACTIVITY_ERROR',
    data: {
      status: status,
      receivedAt: Date.now(),
    },
  }
}

export function addCustomActivity(activity, profileId) {
  return (dispatch, getState) => {
    dispatch(addCustomActivityStart())
    return serviceAddActivity(
      getState().admin.events.currentEvent.id,
      profileId,
      activity,
      () => {
        dispatch(addCustomActivitySuccess(activity))
        dispatch(AttendeesModule.actions.get())
        dispatch(getProfile(profileId))
        const message = i18n.getFixedT(i18n.language, 'admin_customModule')('profile.add_custom_activity.success')
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.add_custom_activity.start'),
          message: message,
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch(addCustomActivityError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.add_custom_activity.start'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}


// //////////////////////////////////////////////////////////////////////////////
// Add achievements
// //////////////////////////////////////////////////////////////////////////////

function addAchievement(profileId, achievementId) {
  return (dispatch, getState) => {
    dispatch({ type: 'ADD_USER_ACHIEVEMENT_START', payload: { profileId, achievementId } })

    return serviceAddAchievement(
      getState().admin.events.currentEvent.id,
      profileId,
      achievementId,
      () => {
        dispatch({ type: 'ADD_USER_ACHIEVEMENT_SUCCESS', payload: { profileId, achievementId } })
        return Promise.resolve()
      },
      (error) => {
        dispatch({ type: 'ADD_USER_ACHIEVEMENT_ERROR', payload: { profileId, achievementId } })
        return Promise.reject(error.description)
      }
    )
  }
}

export function addAchievements(profileId, achievementIds) {
  return (dispatch) => {
    dispatch({ type: 'ADD_USER_ACHIEVEMENTS_START', payload: { profileId, achievementIds } })

    return composePromises(...achievementIds.map(achievementId => () => new Promise((resolve, reject) =>
      dispatch(addAchievement(profileId, achievementId)).then(resolve).catch(reject)
    )))()
      .then(() => {
        dispatch({ type: 'ADD_USER_ACHIEVEMENTS_SUCCESS', payload: profileId, achievementIds })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.add_achievement.title', { count: achievementIds.length }),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.add_achievement.success', { count: achievementIds.length }),
          level: 'success',
          permanent: false,
        }))
      })
      .catch((errorMessage) => {
        dispatch({ type: 'ADD_USER_ACHIEVEMENTS_ERROR', payload: profileId, achievementIds })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.add_achievement.title', { count: achievementIds.length }),
          message: errorMessage,
          level: 'error',
          permanent: false,
        }))
      })
  }
}


// //////////////////////////////////////////////////////////////////////////////
// Create Profile
// //////////////////////////////////////////////////////////////////////////////

function addProfileStart(p) {
  return {
    type: 'ADD_PROFILE_START',
    data: {
      profile: p,
      status: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.add_profile.start'),
    },
  }
}

function addProfileSuccess(p, status) {
  return {
    type: 'ADD_PROFILE_SUCCESS',
    data: {
      profile: p,
      status: status,
      receivedAt: Date.now(),
    },
  }
}

function addProfileError(status) {
  return {
    type: 'ADD_PROFILE_ERROR',
    data: {
      status: status,
      receivedAt: Date.now(),
    },
  }
}

export function addProfile(p, attendee) {
  return (dispatch, getState) => {
    dispatch(addProfileStart(p))
    let service = serviceAddAdmin
    if (attendee === true) {
      service = serviceAddAttendee
    }
    service(
      getState().admin.events.currentEvent.id,
      p,
      (prf, data) => {
        const message = i18n.getFixedT(i18n.language, 'admin_customModule')('profile.add_profile.success', { firstName: prf.first_name, lastName: prf.last_name })
        dispatch(addProfileSuccess(data, message))
        dispatch(AttendeesModule.actions.get())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.add_profile.start'),
          message: message,
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch(addProfileError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.add_profile.start'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}


// //////////////////////////////////////////////////////////////////////////////
// Update Profile
// //////////////////////////////////////////////////////////////////////////////

function updateProfileStart() {
  return {
    type: 'UPDATE_PROFILE_START',
    data: {
      status: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.update_profile.start'),
    },
  }
}

function updateProfileSuccess(p, status) {
  return {
    type: 'UPDATE_PROFILE_SUCCESS',
    data: {
      profile: p,
      status: status,
      receivedAt: Date.now(),
    },
  }
}

function updateProfileError(status) {
  return {
    type: 'UPDATE_PROFILE_ERROR',
    data: {
      status: status,
      receivedAt: Date.now(),
    },
  }
}

export function updateProfile(p, params) {
  return (dispatch, getState) => {
    dispatch(updateProfileStart())
    return serviceUpdateProfile(
      getState().admin.events.currentEvent.id,
      p,
      { allow_merge: false, ...params },
      (response) => {
        const message = i18n.getFixedT(i18n.language, 'admin_customModule')('profile.update_profile.success')
        dispatch(updateProfileSuccess(response, message))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.update_profile.start'),
          message: message,
          level: 'success',
          permanent: false,
        }))

        return Promise.resolve()
      },
      (error) => {
        dispatch(updateProfileError(error.description))

        if (typeof error.can_merge === 'boolean') {
          return new Promise((resolve, reject) => {
            dispatch(setAccountMergeModal({ isVisible: true, account: p, isAttendeeUpdate: true, canMerge: error.can_merge, errorMessage: error.description, resolver: resolve, rejecter: reject }))
          })
        }

        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.update_profile.start'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))

        return Promise.reject()
      }
    )
  }
}

export function resetPassword(profileId, password) {
  return (dispatch, getState) =>
    serviceUpdateProfile(
      getState().admin.events.currentEvent.id,
      { id: profileId, password: password },
      null,
      () => {
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.reset_password.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.reset_password.success'),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('profile.reset_password.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
}


// //////////////////////////////////////////////////////////////////////////////
// Unmount Profile
// //////////////////////////////////////////////////////////////////////////////

export function unmountProfile() {
  return {
    type: 'UNMOUNT_PROFILE_START',
  }
}

export function getProfileStats(id) {
  return (dispatch, getState) => {
    dispatch({ type: 'GET_PROFILE_STATS_START' })
    return serviceGetProfileStats(
      getState().admin.events.currentEvent.id,
      id,
      (response) => dispatch({ type: 'GET_PROFILE_STATS_SUCCESS', data: { stats: response } }),
      () => dispatch({ type: 'GET_PROFILE_STATS_ERROR' })
    )
  }
}

export function getAttendeeLog(attendeeId) {
  return (dispatch, getState) => {
    dispatch({ type: 'GET_ATTENDEE_LOGS_START' })
    return serviceGetAttendeeLogs(
      getState().admin.events.currentEvent.id,
      attendeeId,
      (response) => dispatch({ type: 'GET_ATTENDEE_LOGS_SUCCESS', data: { attendeeLogs: response.logs } }),
      () => dispatch({ type: 'GET_ATTENDEE_LOGS_ERROR' })
    )
  }
}


const initialState = {
  isFetching: false,
  isModifying: false,
  didInvalidate: true,
  lastUpdated: 0,
  lastOperationStatus: '',
  profile: {},
  location: null,
  activities: [],
  stats: undefined,
  attendeeLogs: [],
}


export default function profile(state = initialState, action) {
  switch (action.type) {
    case 'CLEAR_PROFILE':
      return initialState

    case 'GET_PROFILE_START':
      return {
        ...state,
        profile: { ...state.profile, id: action.data.profileId },
        isFetching: true,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
      }

    case 'GET_PROFILE_SUCCESS':
      return {
        ...state,
        profile: {
          id: state.profile.id,
          ...action.data.profile,
        },
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.data.receivedAt,
        lastOperationStatus: action.data.status,
      }

    case 'GET_PROFILE_ERROR':
      return {
        ...state,
        profile: {},
        isFetching: false,
        didInvalidate: true,
        lastOperationStatus: action.data.status,
      }


    case 'ADD_PROFILE_START':
      return {
        ...state,
        profile: action.data.profile,
        isModifying: true,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
      }

    case 'ADD_PROFILE_SUCCESS':
      return {
        ...state,
        profile: {},
        isModifying: false,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
      }

    case 'ADD_PROFILE_ERROR':
      return {
        ...state,
        isModifying: false,
        didInvalidate: true,
        lastOperationStatus: action.data.status,
      }


    case 'UPDATE_PROFILE_START':
      return {
        ...state,
        isModifying: true,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
      }

    case 'UPDATE_PROFILE_SUCCESS':
      return {
        ...state,
        profile: action.data.profile,
        isModifying: false,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
      }

    case 'UPDATE_PROFILE_ERROR':
      return {
        ...state,
        isModifying: false,
        didInvalidate: true,
        lastOperationStatus: action.data.status,
      }

    case 'UNMOUNT_PROFILE_START':
      return {
        isFetching: false,
        didInvalidate: true,
        lastUpdated: 0,
        lastOperationStatus: '',
        profile: {},
        events: {},
      }

    case 'GET_LOCATION_START':
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
      }

    case 'GET_LOCATION_SUCCESS': {
      const newState = {
        ...state,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.data.receivedAt,
      }
      newState.location = getProperty(action.data, 'events.location')
      return newState
    }

    case 'GET_LOCATION_ERROR':
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
      }

    case 'GET_PROFILE_ACTIVITIES_START':
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        lastOperationStatus: 'Retrieving custom activities',
        receivedAt: action.data.receivedAt,
      }

    case 'GET_PROFILE_ACTIVITIES_SUCCESS':
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        activities: action.data.activities,
        lastOperationStatus: 'Retrieved custom activities successfully',
        receivedAt: action.data.receivedAt,
      }

    case 'GET_PROFILE_ACTIVITIES_ERROR':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        lastOperationStatus: action.data.status,
        receivedAt: action.data.receivedAt,
      }

    case 'ADD_CUSTOM_ACTIVITY_START':
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
        receivedAt: action.data.receivedAt,
      }

    case 'ADD_CUSTOM_ACTIVITY_SUCCESS': {
      const activities = state.activities.slice(0)
      activities.push(action.data.activity)
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        lastOperationStatus: 'Added custom activity successfully',
        activities: activities,
        receivedAt: action.data.receivedAt,
      }
    }

    case 'ADD_CUSTOM_ACTIVITY_ERROR':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        lastOperationStatus: action.data.status,
        receivedAt: action.data.receivedAt,
      }

    case 'DELETE_ATTENDEE_CREDENTIAL':
      return {
        ...state,
        profile: {
          ...state.profile,
          credentials: state.profile.credentials.filter(cred => cred.id !== action.data.credential),
        },
      }

    case 'GET_PROFILE_STATS_ERROR':
    case 'GET_PROFILE_STATS_START':
      return {
        ...state,
        stats: initialState.stats,
      }

    case 'GET_PROFILE_STATS_SUCCESS':
      return {
        ...state,
        stats: action.data.stats,
      }

    case 'GET_ATTENDEE_LOGS_ERROR':
    case 'GET_ATTENDEE_LOGS_START':
      return {
        ...state,
        attendeeLogs: initialState.attendeeLogs,
      }

    case 'GET_ATTENDEE_LOGS_SUCCESS':
      return {
        ...state,
        attendeeLogs: action.data.attendeeLogs,
      }

    default:
      return state
  }
}
