import { DataModule } from 'redux-data-module'
import { serviceGetQualifiers, serviceGetQualifiersAnswers, serviceUpdateQualifierAnswer } from 'attendee/services/qualifiers'
import { handleErrorMessage } from './index'
import { getProperty } from 'common/utils/componentHelper'

import { eventIdSelector } from 'attendee/modules/events'
import { representativeExhibitorIdSelector } from 'attendee/modules/profile'


const QualifiersModule = new DataModule({
  initialState: {
    qualifiersAnswers: {},
  },
  moduleKey: 'uf/qualifiers',
  reducerKey: 'attendee.qualifiers',

  services: {
    get: { service: serviceGetQualifiers },
  },
})

QualifiersModule.registerSelector(
  'data',
  (state, moduleState) => moduleState.data,
  qualifiers => qualifiers.sort((a, b) => {
    if (a.order === b.order) {
      return a.title.localeCompare(b.title)
    }

    return a.order - b.order
  })
)

QualifiersModule.registerSelector(
  'qualifiersAnswersByQualifierId',
  (state, moduleState) => moduleState.qualifiersAnswers,
  answers => answers
)


QualifiersModule.actions.get = () => (dispatch, getState) => {
  const state = getState()

  dispatch({ type: QualifiersModule.actionKeys.getStart })

  return serviceGetQualifiers(eventIdSelector(state), representativeExhibitorIdSelector(state))
    .then(response => {
      dispatch({ type: QualifiersModule.actionKeys.getSuccess, payload: response })
    })
    .catch(error => {
      dispatch({ type: QualifiersModule.actionKeys.getError })
      handleErrorMessage(dispatch, getState, error)
      return Promise.reject()
    })
}


QualifiersModule.registerDataAction('getQualifiersAnswers', (leadId) => (dispatch, getState) => {
  dispatch({ type: QualifiersModule.actionKeys.getQualifiersAnswersStart })

  return serviceGetQualifiersAnswers(eventIdSelector(getState()), leadId)
    .then(response => {
      dispatch({ type: QualifiersModule.actionKeys.getQualifiersAnswersSuccess, payload: response })
    })
    .catch(error => {
      dispatch({ type: QualifiersModule.actionKeys.getQualifiersAnswersError })
      handleErrorMessage(dispatch, getState, error)
      return Promise.reject(error)
    })
})

QualifiersModule.registerReducer(QualifiersModule.actionKeys.getQualifiersAnswersSuccess, (state, action) => ({
  ...state,
  qualifiersAnswers: action.payload,
}))


QualifiersModule.registerAction('clearQualifiersAnswers', () => dispatch => {
  dispatch({ type: QualifiersModule.actionKeys.clearQualifiersAnswers })
  return Promise.resolve()
})

QualifiersModule.registerReducer(QualifiersModule.actionKeys.clearQualifiersAnswers, state => ({
  ...state,
  qualifiersAnswers: QualifiersModule.initialState.qualifiersAnswers,
}))


QualifiersModule.registerDataAction('updateQualifierAnswer', (leadId, qualifierId, answer) => (dispatch, getState) => {
  const state = getState()
  const previousAnswer = getProperty(QualifiersModule.selectors.qualifiersAnswersByQualifierId(state)[qualifierId], 'response')
  dispatch({ type: QualifiersModule.actionKeys.updateQualifierAnswerStart, payload: { answer, qualifierId } })

  return serviceUpdateQualifierAnswer(eventIdSelector(state), leadId, qualifierId, { response: answer })
    .then(() => {
      dispatch({ type: QualifiersModule.actionKeys.updateQualifierAnswerSuccess })
    })
    .catch(error => {
      dispatch({ type: QualifiersModule.actionKeys.updateQualifierAnswerError, payload: { previousAnswer, qualifierId } })
      handleErrorMessage(dispatch, getState, error)
      return Promise.reject(error)
    })
})

QualifiersModule.registerReducer(QualifiersModule.actionKeys.updateQualifierAnswerStart, (state, action) => ({
  ...state,
  qualifiersAnswers: {
    ...state.qualifiersAnswers,
    [action.payload.qualifierId]: {
      ...state.qualifiersAnswers[action.payload.qualifierId],
      response: action.payload.answer,
    },
  },
}))

QualifiersModule.registerReducer(QualifiersModule.actionKeys.updateQualifierAnswerError, (state, action) => ({
  ...state,
  qualifiersAnswers: {
    ...state.qualifiersAnswers,
    [action.payload.qualifierId]: {
      ...state.qualifiersAnswers[action.payload.qualifierId],
      response: action.payload.previousAnswer,
    },
  },
}))


export default QualifiersModule
