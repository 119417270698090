import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetTrackingOvertime } from 'admin/services/bluetrace'
import Moment from 'moment'

import { eventTimezoneSelector } from 'admin/modules/events'
import TrackingFiltersModule from './filters'


const OvertimeModule = generateDataModule({
  initialData: [],
  moduleKey: 'cpo/bluetrace/trackingOvertime',
  reducerKey: 'admin.bluetrace.tracking.overtime',
  refreshTime: 10000,
  services: {
    get: { service: serviceGetTrackingOvertime },
  },
})


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

OvertimeModule.registerSelector(
  'data',
  (state, moduleState) => moduleState.data,
  data => data.map(d => ({
    date: d.date,
    value: Math.round(d.count),
  }))
)

OvertimeModule.registerSelector(
  'dataByZoneName',
  (state, moduleState) => moduleState.data,
  data => {
    const dataByZoneName = {}

    data.forEach(d => {
      d.locations.forEach(location => {
        if (!dataByZoneName[location.name]) {
          dataByZoneName[location.name] = []
        }

        dataByZoneName[location.name].push({
          date: d.date,
          value: Math.round(location.count),
        })
      })
    })

    return dataByZoneName
  }
)

OvertimeModule.registerSelector(
  'zoneStats',
  [
    (state, moduleState) => moduleState.data,
    state => TrackingFiltersModule.selectors.timeSelector(state),
  ],
  (overtimeData, timeSelector) => {
    if (overtimeData.length === 0) {
      return []
    }

    const earlierData = overtimeData.filter(data => data.date <= timeSelector.head)
    return earlierData.length > 0 ? earlierData[earlierData.length - 1].locations : overtimeData[0].locations
  }
)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////

OvertimeModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {
    const state = getState()
    const timezone = eventTimezoneSelector(state)
    const dateMoment = Moment.unix(TrackingFiltersModule.selectors.date(state)).tz(timezone)

    const queryParams = {
      group: TrackingFiltersModule.selectors.attendeeType(state),
      start_date: dateMoment.startOf('day').unix(),
      end_date: dateMoment.endOf('day').unix(),
    }

    dispatch({ type: OvertimeModule.actionKeys.getStart })

    return serviceGetTrackingOvertime(OvertimeModule._getEventId(), queryParams)
      .then(response => {
        dispatch({
          type: OvertimeModule.actionKeys.getSuccess,
          payload: response,
        })
      })
      .catch(() => {
        dispatch({ type: OvertimeModule.actionKeys.getError })
        return Promise.reject()
      })
  }
)


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////


export default OvertimeModule
