export default {
  action: { // Can the user perform an action
    // Appointments
    addAppointments: true,
    addParticipantsToAppointments: true,
    deleteAppointments: true,
    editAppointments: true,
    exportAppointments: true,
    importAppointments: true,
    removeParticipantsFromAppointments: true,

    // Attendees
    addAttendees: true,
    deleteAttendees: true,
    editAttendees: true,
    exportAttendees: true,
    generateWebviewLinks: true,
    impersonateAttendees: true,
    importAttendees: true,
    manageFields: true,
    viewAttendeesLocation: true,

    // Events
    createEvents: true,
    editEvents: true,

    // Exhibitors
    addExhibitorAdmins: true,
    addExhibitors: true,
    addRepresentatives: true,
    addTouchpoints: true,
    deleteExhibitorAdmins: true,
    deleteExhibitors: true,
    deleteRepresentatives: true,
    editExhibitors: true,
    exportExhibitors: true,
    impersonateExhibitors: true,
    importExhibitors: true,
    viewExhibitorsLocation: true,

    // Maps
    modifyMaps: true,

    // Schedule
    addAttendeesToSessions: true,
    addCalendars: true,
    addSessions: true,
    deleteSessions: true,
    editSessions: true,
    exportSessions: true,
    importSessions: true,
    modifyCalendars: true,
    modifyTags: true,

    // Speakers
    modifySpeakers: true,

    // Users
    modifyUsers: true,
  },

  menu: { // Is the menu available for the user
    analytics: true,
    attendees: true,
    checkIn: true,
    communication: true,
    dashboard: true,
    devices: true,
    event: true,
    exhibitors: true,
    floorPlan: true,
    gamification: true,
    networking: true,
    pages: true,
    registration: true,
    safeteams: true,
    schedule: true,
    speakers: true,
    system: true,
    users: true,
  },

  page: { // Can the user access the page
    analyticsEngagement: true,
    analyticsExports: true,
    analyticsGamification: true,
    analyticsMultiEvent: true,
    analyticsSessions: true,
    analyticsSummary: true,
    analyticsTracking: true,

    dashboardEngagement: true,
    dashboardMonitoring: true,
    dashboardOnboarding: true,
    dashboardStatistics: true,

    floorPlanMaps: true,
    floorPlanTouchpoints: true,

    safeteamsDashboard: true,
    safeteamsEmployeeContagion: true,
    safeteamsEmployeeNetwork: true,
    safeteamsHubs: true,
    safeteamsReport: true,
    safeteamsTags: true,
    safeteamsTracking: true,
    safeteamsWearables: true,
    safeteamsZoneContagion: true,

    apiDocs: true,
    broadcast: true,
    exports: true,
    platformNews: true,
  },
}
