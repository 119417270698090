
import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetStatistics } from 'admin/services/dashboard'

const DEFAULT_DATA = {
  maxDayCount: null,
  dataType: null,
  days: [],
  total: {},
  pre_event: {},
  post_event: {},
}

const StatisticsModule = generateDataModule({
  initialData: {
    trackedAttendees: DEFAULT_DATA,
    sumTouchpoints: DEFAULT_DATA,
    avgTouchpoints: DEFAULT_DATA,
    sumContactExchanges: DEFAULT_DATA,
    avgContactExchanges: DEFAULT_DATA,
    sumActivityAttendance: DEFAULT_DATA,
    avgActivityAttendance: DEFAULT_DATA,
    sumChatRequests: DEFAULT_DATA,
    acceptedChatRequests: DEFAULT_DATA,
    sumMeetingRequests: DEFAULT_DATA,
    sumScheduledMeetings: DEFAULT_DATA,
  },
  moduleKey: 'dashboardStatistics',
  reducerKey: 'admin.dashboard.statistics',
  services: {
    get: { service: serviceGetStatistics },
  },
})


StatisticsModule.registerSelector('trackedAttendees', (state, moduleState) => moduleState.data.trackedAttendees, trackedAttendees => trackedAttendees)
// StatisticsModule.registerSelector('avgDwellTime', (state, moduleState) => moduleState.data.avgDwellTime, avgDwellTime => avgDwellTime)
StatisticsModule.registerSelector('sumTouchpoints', (state, moduleState) => moduleState.data.sumTouchpoints, sumTouchpoints => sumTouchpoints)
StatisticsModule.registerSelector('avgTouchpoints', (state, moduleState) => moduleState.data.avgTouchpoints, avgTouchpoints => avgTouchpoints)
StatisticsModule.registerSelector('sumContactExchanges', (state, moduleState) => moduleState.data.sumContactExchanges, sumContactExchanges => sumContactExchanges)
StatisticsModule.registerSelector('avgContactExchanges', (state, moduleState) => moduleState.data.avgContactExchanges, avgContactExchanges => avgContactExchanges)
StatisticsModule.registerSelector('sumActivityAttendance', (state, moduleState) => moduleState.data.sumActivityAttendance, sumActivityAttendance => sumActivityAttendance)
StatisticsModule.registerSelector('avgActivityAttendance', (state, moduleState) => moduleState.data.avgActivityAttendance, avgActivityAttendance => avgActivityAttendance)
StatisticsModule.registerSelector('sumChatRequests', (state, moduleState) => moduleState.data.sumChatRequests, sumChatRequests => sumChatRequests)
StatisticsModule.registerSelector('acceptedChatRequests', (state, moduleState) => moduleState.data.acceptedChatRequests, acceptedChatRequests => acceptedChatRequests)
StatisticsModule.registerSelector('sumMeetingRequests', (state, moduleState) => moduleState.data.sumMeetingRequests, sumMeetingRequests => sumMeetingRequests)
StatisticsModule.registerSelector('sumScheduledMeetings', (state, moduleState) => moduleState.data.sumScheduledMeetings, sumScheduledMeetings => sumScheduledMeetings)


StatisticsModule.registerDataAction(
  'get',
  () => dispatch => {
    dispatch({ type: StatisticsModule.actionKeys.getStart })

    return StatisticsModule._getService('get')()
      .then(response => {
        dispatch({ type: StatisticsModule.actionKeys.getSuccess, payload: response })
      })
      .catch(() => {
        dispatch({ type: StatisticsModule.actionKeys.getError })
      })
  }
)

export default StatisticsModule
