import React, { Component } from 'react'

import Row from 'ControlPanel/Components/Row'

import { styles } from './styles.scss'


export default class ButtonContainer extends Component {
  _getClassName = () => {
    let className = `button-container ${styles}`
    className += this.props.className ? ` ${this.props.className}` : ''
    return className
  }

  _getStyle = () => ({ ...this.props.style })

  render() {
    const { alignment, children } = this.props

    return (
      <Row className={this._getClassName()} style={this._getStyle()} alignment={alignment}>
        {children}
      </Row>
    )
  }
}
