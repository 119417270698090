import axios from 'axios'
import { buildError } from 'common/utils/api'
import { getAxiosConfig } from './helper'
import { getTranslatedObject } from 'common/utils/translator'


export function translateCustomPage(page) {
  const data = { ...page }

  if (data.title) {
    data.title = getTranslatedObject(data.title)
  }

  if (data.children) {
    data.children = data.children.map(child => translateCustomPage(child))
  }

  if (data.parents) {
    data.parents = data.parents.map(parent => translateCustomPage(parent))
  }

  return data
}


export function serviceGetCustomPages(eventId) {
  return axios.get(`/events/${eventId}/custom_resources?adjacents=true`, getAxiosConfig())
    .then(response => response.data.map(page => translateCustomPage(page)))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetCustomPage(eventId, pageId) {
  return axios.get(`/events/${eventId}/custom_resources/${pageId}`, getAxiosConfig())
    .then(response => translateCustomPage(response.data))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceCreateCustomPage(eventId, page) {
  return axios.post(`/events/${eventId}/custom_resources`, page, getAxiosConfig())
    .then(response => translateCustomPage(response.data))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceUpdateCustomPage(eventId, page) {
  return axios.put(`/events/${eventId}/custom_resources/${page.id}`, page, getAxiosConfig())
    .then(response => translateCustomPage(response.data))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceDeleteCustomPage(eventId, pageId) {
  return axios.delete(`/events/${eventId}/custom_resources/${pageId}`, getAxiosConfig())
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceCreateCustomPageChild(eventId, pageId, page) {
  return axios.post(`/events/${eventId}/custom_resources/${pageId}/children`, page, getAxiosConfig())
    .then(response => translateCustomPage(response.data))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceReorderCustomPageChildren(eventId, pageId, childrenIds) {
  return axios.put(`/events/${eventId}/custom_resources/${pageId}/children/order`, childrenIds, getAxiosConfig())
    .then(response => translateCustomPage(response.data))
    .catch(error => Promise.reject(buildError(error)))
}
