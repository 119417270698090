import { serviceGetAppMetrics } from 'admin/services/analytics'
import generateModule from 'common/utils/generateModule'
import { createSelector } from 'reselect'
import Moment from 'moment'
import { eventStartDateSelector, eventEndDateSelector, eventTimezoneSelector } from 'admin/modules/events'


const selectAppMetricsOvertime = state => state.admin.analytics.appMetrics.data

export const installsOvertimeSelector = createSelector(selectAppMetricsOvertime, metrics => metrics.installsOvertime || [])
export const loginsOvertimeSelector = createSelector(selectAppMetricsOvertime, metrics => metrics.loginsOvertime || [])
export const visitsOvertimeSelector = createSelector(selectAppMetricsOvertime, metrics => metrics.visitsOvertime || [])


const { actions, reducers, initialState } = generateModule({
  itemName: 'appMetrics',
  autoRefresh: 3,
  getModuleState: (getState) => getState().admin.analytics.appMetrics,
  dataIsNotArray: true,
  services: {},
})

initialState.data = {
  installsOvertime: [],
  loginsOvertime: [],
  visitsOvertime: [],
}

initialState.clientId = null

actions.getAppMetrics = query =>
  (dispatch, getState) => {
    dispatch({ type: 'GET_APP_METRICS_START' })

    const requestQuery = { appmetric: 'visit', entity_count: true, step: 60 * 24, ...query }
    if (requestQuery.start_date == null) requestQuery.start_date = Moment.unix(eventStartDateSelector(getState())).tz(eventTimezoneSelector(getState())).startOf('day').subtract(16, 'days').unix()
    if (requestQuery.end_date == null) requestQuery.end_date = Moment.unix(eventEndDateSelector(getState())).tz(eventTimezoneSelector(getState())).endOf('day').add(4, 'days').unix()

    return serviceGetAppMetrics(
      getState().admin.events.currentEvent.id,
      requestQuery,
      (response) => {
        const data = {
          installsOvertime: [],
          loginsOvertime: [],
          visitsOvertime: [],
        }

        response.timeseries.forEach(timeserie => {
          if (timeserie.count) {
            data.installsOvertime.push({ date: timeserie.date, value: timeserie.count })
          }

          if (timeserie.entity_count) {
            data.loginsOvertime.push({ date: timeserie.date, value: timeserie.entity_count })
          }

          if (timeserie.gross_count) {
            data.visitsOvertime.push({ date: timeserie.date, value: timeserie.gross_count })
          }
        })

        dispatch({ type: 'GET_APP_METRICS_SUCCESS', payload: { ...data } })
      },
      () => dispatch({ type: 'GET_APP_VISITS_ERROR' })
    )
  }

reducers['GET_APP_METRICS_SUCCESS'] = (state, action) => ({ ...state, data: { ...action.payload } })


actions.changeClientId = clientId =>
  dispatch => {
    dispatch({ type: 'SET_APP_METRICS_CLIENT_ID', payload: clientId })
    return Promise.resolve()
  }

reducers['SET_APP_METRICS_CLIENT_ID'] = (state, action) => ({
  ...state,
  clientId: action.payload,
  data: initialState.data,
})


reducers['CHANGE_EVENT_SUCCESS'] = (state) => ({
  ...state,
  didInvalidate: true,
  data: initialState.data,
})


export const getAppMetrics = actions.getAppMetrics
export const changeClientId = actions.changeClientId

const reducer = reducers.index
export default reducer
