import { saveTokens, clearTokens } from 'common/utils/accessToken'
import { serviceOpenKlikProfile, serviceGetCurrentUser } from 'common/services/profile'
import { serviceGetMinimalEvent, serviceGetDomainBranding } from 'common/services/event'
import { serviceInvalidateTokens, serviceLogin, serviceLoginEmail, serviceLoginMultiFactorAuthentification, servicePreviewKlikProfile, serviceRefreshLogin, serviceSocialLogin, serviceSendLoginCode, serviceLoginWithCode, serviceGetSocialNetworkAuthorizationUrls } from 'common/services/login'
import { browserHistoryPush } from 'common/components/Link'
import { showToast, deleteAllToasts } from 'common/modules/toaster'
import i18n from 'i18next'
import { getProperty, listToMapping } from 'common/utils/componentHelper'
import ReactGA from 'react-ga'
import { createSelector } from 'reselect'
import { browserHistory } from 'index'


export const isAdminPreviewerSelector = createSelector(
  state => getProperty(state.auth.user, 'isPreviewer'),
  isPreviewer => isPreviewer
)
export const accessTokenSelector = createSelector(
  state => getProperty(state.auth.user, 'accessToken'),
  token => token
)

export const refreshTokenSelector = createSelector(
  (state) => getProperty(state.auth.user, 'refreshToken'),
  token => token
)

export const credentialsSelector = createSelector(
  (state) => getProperty(state.auth.user, 'profile.credentials'),
  credentials => credentials
)

export const credentialsByTypeSelector = createSelector(
  credentialsSelector,
  credentials => listToMapping(credentials, 'type')
)

export const domainSelector = createSelector(
  (state) => getProperty(state.auth, 'domain'),
  domain => domain
)

export const connectedSocialNetworksSelector = createSelector(
  credentialsByTypeSelector,
  credentialsByType => {
    const credentials = { ...credentialsByType }
    delete credentials.email
    return Object.keys(credentials)
  }
)

export const socialNetworkAuthorizationSelector = createSelector(
  (state, name) => getProperty(state.auth.socialNetworks, name),
  socialNetworkAuthorization => socialNetworkAuthorization
)

export const appSelector = createSelector(
  (state) => state.auth.app,
  app => app
)


function hasNoEventAccess(error) {
  return (error.title === '409 Conflict' || error.status === 409)
}

function loginStart(status) {
  return {
    type: 'LOGIN_START',
    data: {
      lastOperationStatus: status,
    },
  }
}

export function clearLoginErrorMessage() {
  return dispatch => dispatch({ type: 'CLEAR_LOGIN_ERROR_MESSAGE' })
}

export function getClientId(state) {
  if (state.app.isNativeApp) {
    return 'klik_native_web'
  } else if (state.app.isWebview) {
    return 'klik_integrated_web'
  }

  return 'klik_web'
}


/*
  Change the page for the multi form login

  page: The page you want to go to
*/
export function changeLoginPage(location, page) {
  return (dispatch, getState) => {
    if (!getState().auth.autoLoginFailed) {
      dispatch(deleteAllToasts())
      dispatch({ type: 'CLEAR_LOGIN_ERROR_MESSAGE' })
    }

    browserHistoryPush(`${location.pathname}?page=${page}`)
  }
}


export function loginError(error, _additionalPayload = {}) {
  clearTokens()
  clearTokens('attendee')
  const additionalPayload = { ..._additionalPayload }

  return (dispatch) => {
    if (hasNoEventAccess(error)) {
      dispatch(changeLoginPage(browserHistory.location, 'no_access'))
    } else if (!additionalPayload.loginErrorMessage) {
      additionalPayload.loginErrorMessage = error.description
    }

    dispatch({
      type: 'LOGIN_ERROR',
      data: {
        receivedAt: Date.now(),
        additionalPayload: additionalPayload,
      },
    })
  }
}

/*
  Returns a promise which returns a pair of tokens when you want to access your klik profile

  token: Current user's token
*/
export function previewKlikProfile(token, domain) {
  return (dispatch, getState) => servicePreviewKlikProfile(
    token, domain, getClientId(getState()),
    (response) => Promise.resolve({ access_token: response.access_token, refresh_token: response.refresh_token }),
    (error) => dispatch(showToast({ title: i18n.getFixedT(i18n.language)('common_customModule:Login Failed'), message: error.description, level: 'error', permanent: false }))
  )
}


/**
 * Returns a promise which returns a pair of tokens to use to view klik profile of an attendee
 */
export function openKlikProfile(profileId) {
  return (dispatch, getState) => serviceOpenKlikProfile(
    getState().admin.events.currentEvent.id,
    profileId,
    (response) => Promise.resolve({ access_token: response.access_token, refresh_token: response.refresh_token }),
    (error) => dispatch(showToast({ title: i18n.getFixedT(i18n.language)('common_customModule:Login Failed'), message: error.description, level: 'error', permanent: false }))
  )
}


/*
  Call to save new tokens

  accessToken: (string) Access token to save
  refreshToken: (string) Refresh token to save
*/
export function renewTokens(accessToken, refreshToken, roles) {
  return (dispatch, getState) => {
    saveTokens(accessToken, refreshToken, getState().auth.app === 'admin' ? 'admin' : 'user')
    dispatch({ type: 'LOGIN_SUCCESS', data: { accessToken: accessToken, refreshToken: refreshToken, roles: roles } })
  }
}

function redirectAfterLogin(desiredPath, isAdmin) {
  let path = desiredPath

  if (path && path.startsWith('/admin') && !isAdmin) {
    path = '' // ignore you if you can't access the admin site
  }

  if (!path) {
    path = isAdmin ? '/admin' : '/events'
  }

  browserHistoryPush(path)
}

function isEmbeddedAttendeeApp(state) {
  return state.app.isNativeApp || state.app.isWebview
}

/*
  To call once the login is successful

  options: {
    accessToken: The new access token
    refreshToken: The new refresh token
    email: The email of the connected user
    shouldRedirect: Should we redirect after the login (DEFAULT: TRUE)
    path: The path you're trying to access
  }
*/
export function loginSuccess(response, statusCode) {
  return (dispatch, getState) => {
    const selectedDomain = domainSelector(getState())

    if (statusCode === 202) {
      dispatch({
        type: 'RECEIVED_MULTI_FACTOR_AUTHENTIFICATION_METHODS',
        payload: {
          methods: response.methods,
          token: response.mfa_token,
        },
      })

      return Promise.resolve({ loginType: 'MFA_LOGIN' })
    }

    const loginData = {
      accessToken: response.access_token,
      receivedAt: Date.now(),
      refreshToken: response.refresh_token,
      additional_domains: response.additional_domains,
      attendee_domains: response.attendee_domains,
      isAttendee: response.attendee_token,
      isAdmin: response.admin_token,
      isExhibitor: response.is_exhibitor,
      isOrganizer: response.is_organizer,
      hasPassword: response.has_password,
      attendeeEvents: response.attendee_events,
      roles: response.roles,
    }

    const { accessToken, refreshToken, isAdmin } = loginData

    if (response.language) {
      i18n.changeLanguage(response.language)
    }

    if (loginData.attendeeEvents.length === 0 && isEmbeddedAttendeeApp(getState())) {
      dispatch(loginError(undefined, { loginErrorMessage: i18n.getFixedT(i18n.language)('login:Error Message Not Attendee') }))
      browserHistoryPush('/login')
      return Promise.resolve({})
    }

    dispatch({ type: 'SELECT_APP', data: { app: isAdmin ? 'admin' : 'attendee' } })

    const attendeeDomains = response.attendee_domains || []
    const needDomainSelection = getState().app.isNativeApp && window.location.pathname.startsWith('/webview_native/login') && !response._autologin && attendeeDomains.length > 1 && (!attendeeDomains.some(domain => domain === selectedDomain) || selectedDomain === 'default')
    if (!needDomainSelection) {
      if (!window.location.pathname.startsWith('/leaderboard') && !getState().auth.user.isPreviewer) {
        saveTokens(accessToken, refreshToken, isAdmin ? 'admin' : 'user')
      }

      dispatch({ type: 'LOGIN_SUCCESS', data: loginData })
      redirectAfterLogin(getState().auth.requestedPath, isAdmin)
      return Promise.resolve({})
    }

    dispatch({ type: 'LOGIN_SUCCESS', data: loginData })
    return Promise.resolve({ needDomainSelection })
  }
}


function shouldForceAttendee(state) {
  return isEmbeddedAttendeeApp(state) || state.auth.loginBranding.eventId ||
    (state.auth.requestedPath && !state.auth.requestedPath.startsWith('/admin'))
}

/*
  Log in the user using the user's email address and password

  email: User's email address
  password: User's password
*/
export function login(email, password, domain) {
  return (dispatch, getState) => {
    // Specify the user scope if trying to access the attendee site
    const scope = shouldForceAttendee(getState()) ? 'user' : ''

    dispatch(loginStart(i18n.getFixedT(i18n.language)('common_customModule:Logging In')))
    return serviceLogin(email, password, domain, scope, getClientId(getState()),
      (response, statusCode) => dispatch(loginSuccess(response, statusCode)),
      error => dispatch(loginError(error))
    )
  }
}


/*
  Auto-login the user using the provided token

  token: (string) User's access token or refresh token
*/
export function autologin(token, domain) {
  return (dispatch, getState) => {
    dispatch(loginStart(i18n.getFixedT(i18n.language)('common_customModule:Logging In')))
    dispatch({ type: 'AUTOLOGIN_START' })
    return serviceRefreshLogin(token, domain, getClientId(getState()),
      response => dispatch(loginSuccess({ ...response, _autologin: true })),
      error => {
        dispatch(loginError(error, { autoLoginFailed: true }))

        browserHistoryPush('/login')
        return Promise.reject(error)
      }
    )
  }
}

export function loginSocial(code, codeType, redirectUri, domain) {
  return (dispatch, getState) => {
    dispatch(loginStart(i18n.getFixedT(i18n.language)('common_customModule:Logging In')))
    return serviceSocialLogin(code, codeType, redirectUri, domain, getClientId(getState()),
      response => dispatch(loginSuccess(response)),
      error => dispatch(loginError(error))
    )
  }
}


function loginEmailSuccess(email, data) {
  return {
    type: 'LOGIN_EMAIL',
    data: {
      hasPassword: data.has_password,
      hasMobilePhone: data.has_mobile_phone,
      socialNetworks: data.social_networks,
      email: email,
    },
  }
}

/*
  Send a request to check if the account exists and if it has a password

  email: The account's email address
*/
export function loginEmail(email, location, domain, page = 'password') {
  return (dispatch) => {
    dispatch(loginStart('Email login started'))
    serviceLoginEmail(
      email, domain,
      response => {
        dispatch(loginEmailSuccess(email, response))
        dispatch(changeLoginPage(location, page))
      },
      error => dispatch(loginError(error))
    )
  }
}


export function loginMultiFactorAuthentification(methodToken, methodId, mfaToken, domain) {
  return (dispatch, getState) => {
    dispatch(loginStart())
    return serviceLoginMultiFactorAuthentification(
      methodToken,
      methodId,
      mfaToken,
      domain,
      getClientId(getState()),
      shouldForceAttendee(getState()) ? 'user' : '',
      response => dispatch(loginSuccess(response)),
      error => {
        dispatch(loginError(error))
        return Promise.reject()
      }
    )
  }
}


/*
  Log out the user and clear tokens of the respective app

  appType: 'admin' or 'attendee' (used to clear tokens of respective app)
*/
export function logout(appType) {
  return dispatch => {
    serviceInvalidateTokens()

    clearTokens()
    clearTokens('attendee')

    ReactGA.set({ userId: undefined, eventId: undefined })
    dispatch({ type: 'LOGOUT', app: appType })
    browserHistoryPush('/login')
  }
}

export function setRequestedPath(path) {
  return dispatch => {
    dispatch({ type: 'SET_REQUESTED_PATH', data: { path } })
    return Promise.resolve()
  }
}

export function getLoginBranding(eventId) {
  return dispatch => {
    dispatch({ type: 'GET_LOGIN_BRANDING_START' })
    return serviceGetMinimalEvent(
      eventId,
      (response) => {
        dispatch({
          type: 'GET_LOGIN_BRANDING_SUCCESS',
          data: {
            loginBranding: { ...response, brandColor: response.colors.primary, eventId: eventId },
          },
        })
      },
      () => dispatch({ type: 'GET_LOGIN_BRANDING_ERROR' })
    )
  }
}

export function getLoginBrandingForDomain(domain) {
  return dispatch => {
    dispatch({ type: 'GET_LOGIN_BRANDING_START' })
    return serviceGetDomainBranding(
      domain,
      (response) => {
        dispatch({ type: 'GET_LOGIN_BRANDING_SUCCESS', data: { loginBranding: { ...response, brandColor: response.colors.primary, isDomainBranded: true } } })
      },
      () => dispatch({ type: 'GET_LOGIN_BRANDING_ERROR' })
    )
  }
}

export function getDomainBranding(domainId) {
  return dispatch => serviceGetDomainBranding(
    domainId,
    (response) => Promise.resolve(response),
    (error) => {
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'common_customModule')('get_domain_branding'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    }
  )
}

export function sendLoginCode(type) {
  return (dispatch, getState) => {
    dispatch({ type: 'SEND_LOGIN_CODE', data: { type: type } })
    return serviceSendLoginCode(
      getState().auth.user.email,
      getState().auth.domain,
      type,
      () => Promise.resolve(),
      () => Promise.reject()
    )
  }
}

export function loginWithCode(code) { // eslint-disable-line
  return (dispatch, getState) => {
    dispatch({ type: 'LOGIN_WITH_CODE', payload: { code: code } })
    return serviceLoginWithCode(
      getState().auth.user.email,
      code,
      getState().auth.domain,
      shouldForceAttendee(getState()) ? 'user' : '',
      getClientId(getState()),
      response => dispatch(loginSuccess(response)),
      error => dispatch(loginError(error))
    )
  }
}


export function retrieveCurrentUser() {
  return (dispatch, getState) => {
    dispatch({ type: 'RETRIEVE_CURRENT_USER_PROFILE_START' })
    return serviceGetCurrentUser(
      (response) => {
        const currentUserId = getProperty(getState().auth.user.profile, 'id')
        if (currentUserId !== response.id) {
          ReactGA.set({ userId: response.id })
        }

        return dispatch({ type: 'RETRIEVED_CURRENT_USER_PROFILE', data: { profile: response } })
      },
      () => {},
    )
  }
}


export function flagUserAsPreviewer(value = true) {
  return dispatch => {
    dispatch({ type: 'FLAG_USER_AS_PREVIEWER', data: { value } })
    return Promise.resolve()
  }
}

export function flagUserAsPreviewWidget(value = true) {
  return dispatch => {
    dispatch({ type: 'FLAG_USER_AS_PREVIEW_WIDGET', data: { value } })
    return Promise.resolve()
  }
}


export function changeRequestedEvent(eventId) {
  return { type: 'CHANGE_REQUESTED_EVENT', data: { eventId: eventId } }
}


export function getSocialNetworkAuthorizationUrls() {
  return dispatch => serviceGetSocialNetworkAuthorizationUrls(
    (response) => {
      const socialNetworksByName = {}
      response.forEach((socialNetwork) => { socialNetworksByName[socialNetwork.name] = socialNetwork })
      dispatch({ type: 'GET_SOCIAL_NETWORKS_AUTHORIZATION_URLS', payload: socialNetworksByName })
    },
    () => {}
  )
}


const initialState = {
  isFetching: false,
  isError: false,
  isLoaded: false,
  lastOperationStatus: '',

  multiFactorAuthentification: {
    methods: [],
    token: undefined,
  },

  currEventId: '',
  roles: {},
  loginErrorMessage: '',
  codeSentBy: '',
  app: undefined,
  loginBranding: {
    allowed_social_network: ['facebook', 'linkedin'],
    logo_url: '/assets/images/klik_bizzabo_logo_white.png',
    splash_url: '/assets/images/login_bg.jpg',
    title: 'PixMob Klik',
    brandColor: '#00fab9',
    isFetching: false,
    eventId: null,
    isDomainBranded: false,
  },
  requestedEventId: undefined,
  domain: 'default',
  requestedPath: '',
  socialNetworks: {},
  user: {
    profile: {
      isLoading: false,
      loaded: false,
    },
    email: undefined,
    hasPassword: undefined,
    hasMobilePhone: undefined,
    socialNetworks: [],
    accessToken: undefined,
    refreshToken: undefined,
    isLoggedIn: false,
    additional_domains: [],
    attendee_domains: [],
    isPreviewer: false,
    isPreviewWidget: false,
  },
}

export default function auth(state = initialState, action) {
  switch (action.type) {

    case 'RETRIEVE_CURRENT_USER_PROFILE_START':
      return {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            loaded: false,
            isLoading: true,
          },
        },
      }

    case 'RETRIEVED_CURRENT_USER_PROFILE':
      return {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...action.data.profile,
            loaded: true,
            isLoading: false,
          },
        },
      }

    case 'LOGIN_EMAIL':
      return {
        ...state,
        isFetching: false,
        autologinFetching: false,
        autoLoginFailed: false,
        loginErrorMessage: '',
        user: {
          ...state.user,
          hasPassword: action.data.hasPassword,
          hasMobilePhone: action.data.hasMobilePhone,
          socialNetworks: action.data.socialNetworks,
          email: action.data.email,
        },
      }

    case 'LOGIN_START':
      return {
        ...state,
        isFetching: true,
        isError: false,
        isLoaded: false,
        lastOperationStatus: action.data.lastOperationStatus,
        autoLoginFailed: false,
      }

    case 'AUTOLOGIN_START':
      return {
        ...state,
        autologinFetching: true,
        autoLoginFailed: false,
      }

    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isFetching: false,
        autologinFetching: false,
        isError: false,
        isLoaded: true,
        loginErrorMessage: '',
        lastOperationStatus: action.data.lastOperationStatus,
        roles: action.data.roles,
        user: {
          ...state.user,
          email: action.data.email,
          accessToken: action.data.accessToken,
          refreshToken: action.data.refreshToken,
          isAdmin: action.data.isAdmin,
          isOrganizer: action.data.isOrganizer,
          isExhibitor: action.data.isExhibitor,
          isLoggedIn: true,
          hasPassword: action.data.hasPassword,
          additional_domains: action.data.additional_domains || [],
          attendee_domains: action.data.attendee_domains || [],
          attendeeEvents: action.data.attendeeEvents || [],
        },
      }

    case 'CHANGE_EVENT_SUCCESS':
      return {
        ...state,
        currEventId: action.data.eventId,
      }

    case 'LOGIN_ERROR':
      return {
        ...state,
        ...action.data.additionalPayload,
        isFetching: false,
        autologinFetching: false,
        autoLoginFailed: getProperty(action.data, 'additionalPayload.autoLoginFailed') || false,
        isError: true,
        requestedPath: '',
        requestedEventId: '',
      }

    case 'LOGIN_WITH_CODE':
      return { ...state, loginErrorMessage: '' }

    case 'SEND_LOGIN_CODE':
      return { ...state, codeSentBy: action.data.type }

    case 'GET_LOGIN_BRANDING_START':
      return { ...state, loginBranding: { ...state.loginBranding, isFetching: true } }

    case 'GET_LOGIN_BRANDING_SUCCESS':
      return {
        ...state,
        loginBranding: { ...(action.data.loginBranding || state.loginBranding), isFetching: false },
        domain: action.data.loginBranding ? action.data.loginBranding.domain : state.domain,
      }

    case 'GET_LOGIN_BRANDING_ERROR':
      return { ...state, loginBranding: { ...state.loginBranding, isFetching: false } }

    case 'SET_REQUESTED_PATH':
      return { ...state, requestedPath: action.data.path }

    case 'CHANGE_REQUESTED_EVENT':
      return { ...state, requestedEventId: action.data.eventId }

    case 'CLEAR_LOGIN_ERROR_MESSAGE':
      return { ...state, loginErrorMessage: '' }

    case 'FLAG_USER_AS_PREVIEWER':
      return { ...state, user: { ...state.user, isPreviewer: action.data.value } }

    case 'FLAG_USER_AS_PREVIEW_WIDGET':
      return { ...state, user: { ...state.user, isPreviewWidget: action.data.value } }

    case 'SELECT_APP':
      return { ...state, app: action.data.app }

    case 'RECEIVED_MULTI_FACTOR_AUTHENTIFICATION_METHODS':
      return {
        ...state,
        isFetching: false,
        multiFactorAuthentification: {
          methods: action.payload.methods,
          token: action.payload.token,
        },
      }

    case 'GET_SOCIAL_NETWORKS_AUTHORIZATION_URLS':
      return { ...state, socialNetworks: action.payload }

    case 'LOGOUT': // some things need to persist across logout
      const newState = { ...initialState, socialNetworks: state.socialNetworks }
      if (state.app === 'attendee') {
        newState.loginBranding = state.loginBranding
        newState.requestedEventId = state.requestedEventId
        newState.domain = state.domain
      }

      return newState

    default:
      return state
  }
}
