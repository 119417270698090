import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import moment from 'moment'
import { getLanguage } from 'common/utils/accessToken'
import updateCSSProperties from 'common/utils/updateCSSProperties'
import { nullifyForEnvironments } from 'common/utils/componentHelper'

import { clearLoginErrorMessage, getSocialNetworkAuthorizationUrls } from 'common/modules/auth'

import Toaster from 'common/containers/Toaster'

import { CommonRoutes } from 'routes'

import UnsupportedBrowser from './UnsupportedBrowser'
import VersionDetector from './VersionDetector'
import WebviewDetector from './WebviewDetector'
import { styles } from './styles.scss'


@updateCSSProperties({
  '--cp-brand-color': '#00FAB9',
})
@connect(
  undefined,
  dispatch => bindActionCreators({
    clearLoginErrorMessage,
    getSocialNetworkAuthorizationUrls,
  }, dispatch)
)
export default class App extends Component {
  static propTypes = {
    children: PropTypes.any,
  };

  componentDidMount() {
    this.props.getSocialNetworkAuthorizationUrls()

    i18n.loadNamespaces([
      'admin_customModule',
      'attendee_customModule',
      'common_customModule',
      'common_footer',
      'common_footerBar',
      'common_leaderboard',
      'common_markdownEditor',
      'common_minisite',
      'common_module',
      'common_resetPassword',
      'common_searchBar',
      'common_tooltips',
      'common_uploadAsset',
      'common_validation',
      'login',
    ])

    i18n.on('languageChanged', (language) => {
      moment.locale(language)
      this.props.clearLoginErrorMessage()
    })

    i18n.changeLanguage(getLanguage() || 'en')
  }

  render() {
    const { match } = this.props

    return (
      <div className={styles} >
        <WebviewDetector>
          {CommonRoutes(match)}

          <Toaster />
          <VersionDetector />
          {nullifyForEnvironments(<UnsupportedBrowser />, ['development'])}
        </WebviewDetector>
      </div>
    )
  }
}
