import axios from 'axios'
import { apiErrorHandler } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceGetDomains() {
  return new Promise((resolve, reject) => {
    axios.get('/domains', getAxiosConfig())
      .then(response => resolve(response.data))
      .catch(() => apiErrorHandler(error => reject(error), 'backend'))
  })
}
