import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceGetLastVisits(eventId, success, error) {
  return axios.get(`/events/${eventId}/attendees/me/last_visits`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateLastVisit(eventId, type, success, error) {
  return axios.put(`/events/${eventId}/attendees/me/last_visits/${type}`, {}, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
