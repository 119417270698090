import { combineReducers } from 'redux'

import AverageContagionScoreEmployeesModule from './AverageContagionScores/employees'
import AverageContagionScoreZonesModule from './AverageContagionScores/zones'
import DashboardCountDevicesOnsiteModule from './Dashboard/countDevicesOnsite'
import DashboardCountEmployeesModule from './Dashboard/countEmployees'
import DashboardOvertimeModule from './Dashboard/overtime'
import DashboardTechnicalStatsModule from './Dashboard/technicalStats'
import EmployeeContagionDailyScoresModule from './EmployeeContagion/dailyScores'
import EmployeeListModule from './EmployeeContagion/employeeList'
import HighRiskEmployeesComparisonModule from './HighRiskComparisons/employees'
import HighRiskZonesComparisonModule from './HighRiskComparisons/zones'
import NetworkDailyScoresModule from './EmployeeNetwork/dailyScores'
import NetworkEmployeesModule from './EmployeeNetwork/topEmployees'
import NetworkFiltersModule from './EmployeeNetwork/filters'
import NetworkGraphModule from './EmployeeNetwork/networkGraph'
import TrackingFiltersModule from './Tracking/filters'
import TrackingHeatmapModule from './Tracking/heatmap'
import TrackingOvertimeModule from './Tracking/overtime'
import TrackingZoneStatsModule from './Tracking/zoneStats'
import ZoneDailyScoresModule from './ZoneContagion/dailyScores'
import ZoneListModule from './ZoneContagion/zoneList'


const reducers = {
  averageContagionScores: combineReducers({
    employees: AverageContagionScoreEmployeesModule.reducers.index,
    zones: AverageContagionScoreZonesModule.reducers.index,
  }),

  dashboard: combineReducers({
    countDevicesOnsite: DashboardCountDevicesOnsiteModule.reducers.index,
    countEmployees: DashboardCountEmployeesModule.reducers.index,
    overtime: DashboardOvertimeModule.reducers.index,
    technicalStats: DashboardTechnicalStatsModule.reducers.index,
  }),

  employeeNetwork: combineReducers({
    dailyScores: NetworkDailyScoresModule.reducers.index,
    filters: NetworkFiltersModule.reducers.index,
    networkGraph: NetworkGraphModule.reducers.index,
    topEmployees: NetworkEmployeesModule.reducers.index,
  }),

  employeeContagion: combineReducers({
    employeeList: EmployeeListModule.reducers.index,
    dailyScores: EmployeeContagionDailyScoresModule.reducers.index,
  }),

  highRiskComparisons: combineReducers({
    employees: HighRiskEmployeesComparisonModule.reducers.index,
    zones: HighRiskZonesComparisonModule.reducers.index,
  }),

  tracking: combineReducers({
    filters: TrackingFiltersModule.reducers.index,
    heatmap: TrackingHeatmapModule.reducers.index,
    overtime: TrackingOvertimeModule.reducers.index,
    zoneStats: TrackingZoneStatsModule.reducers.index,
  }),

  zoneContagion: combineReducers({
    zoneDailyScores: ZoneDailyScoresModule.reducers.index,
    zoneList: ZoneListModule.reducers.index,
  }),
}

export default combineReducers(reducers)
