import { serviceGetCarousel, serviceUpdateCarousel, serviceGetCarouselPreview } from 'admin/services/events'
import generateModule from 'common/utils/generateModule'

const { actions, reducers, initialState } = generateModule({
  itemName: 'carousel',
  services: {
    get: serviceGetCarousel,
    mod: serviceUpdateCarousel,
  },
})

initialState.currentCarousel = undefined


actions.updateCarousel = (carousel, indexLastUpdate) =>
  (dispatch, getState) => {
    dispatch(actions.modifyStart(carousel))
    return serviceUpdateCarousel(
      getState().admin.events.currentEvent.id,
      carousel,
      (response) => {
        dispatch(actions.modifySuccess(response))

        if (indexLastUpdate) {
          dispatch(actions.setCurrentCarouselCard(indexLastUpdate))
        }
      },
      error => dispatch(actions.modifyError(error))
    )
  }


actions.setCurrentCarouselCard = (index, isCreateMode) =>
  dispatch => {
    dispatch({ type: 'SET_CURRENT_CAROUSEL_CARD', payload: { index, isCreateMode } })
    return Promise.resolve()
  }

reducers['SET_CURRENT_CAROUSEL_CARD'] = (state, action) => ({
  ...state,
  currentCarousel: action.payload.index != null
    ? ({
      data: { ...state.data[action.payload.index] },
      index: action.payload.index,
    })
    : undefined,
  isCreateMode: action.payload.isCreateMode,
})


actions.getCarouselPreview = (carousel, language) =>
  (dispatch, getState) => serviceGetCarouselPreview(
    getState().admin.events.currentEvent.id,
    carousel,
    language,
    (preview) => Promise.resolve(preview),
    (error) => Promise.reject(error.description)
  )


export const getCarousel = actions.get
export const getCarouselIfNeeded = actions.getIfNeeded
export const setCurrentCarouselCard = actions.setCurrentCarouselCard
export const updateCarousel = actions.updateCarousel
export const getCarouselPreview = actions.getCarouselPreview

const carousels = reducers.index
export default carousels
