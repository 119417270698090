import { serviceGetGames } from 'attendee/services/gamification'
import generateModule from 'common/utils/generateModule'

const games = generateModule({
  itemName: 'game',
  itemNameDisplay: 'game',
  getModuleState: (getState) => getState().attendee.games,
  autoRefresh: 60,
  services: {
    get: serviceGetGames,
  },
  app: 'attendee',
})

games.initialState.currentGame = undefined


games.actions.get = () =>
  (dispatch, getState) => {
    dispatch(games.actions.getStart())
    return serviceGetGames(
      getState().attendee.attEvents.currEvent.id,
      (response) => {
        const currentGame = response.find(d => d.id === 'main') || response[0]
        dispatch(games.actions.getSuccess(response))
        dispatch({ type: 'SELECT_GAME', payload: { currentGame } })
        return Promise.resolve()
      },
      (error) => dispatch(games.actions.getError(error))
    )
  }

games.reducers['SELECT_GAME'] = (state, action) => ({
  ...state,
  currentGame: action.payload.currentGame,
})


games.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isError: false,
  isLoaded: false,
  didInvalidate: true,
  data: [],
  currentGame: undefined,
})

games.actions.getGames = games.actions.get
export default games
