import { addTimeFilterSupport } from './helper'
import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetDevicesOnsiteStatsOvertime } from 'admin/services/dashboard'
import { eventTimezoneSelector } from 'admin/modules/events'

const AttendeesOnSiteOverTimeModule = generateDataModule({
  initialData: [],
  initialState: { minimalData: [], test: [] },
  moduleKey: 'dashboardAttendeesOnSiteOverTimeModule',
  reducerKey: 'admin.dashboard.attendeesOnSiteOverTime',
  services: {
    get: { service: serviceGetDevicesOnsiteStatsOvertime },
  },
})

addTimeFilterSupport(AttendeesOnSiteOverTimeModule, true)

AttendeesOnSiteOverTimeModule.registerSelector('fullData', (state, moduleState) => moduleState.data, data => data)
AttendeesOnSiteOverTimeModule.registerSelector('minimalData', (state, moduleState) => moduleState.minimalData, data => data || [])

AttendeesOnSiteOverTimeModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {
    if (!AttendeesOnSiteOverTimeModule.selectors.areTimeFiltersInitialized(getState())) {
      dispatch(AttendeesOnSiteOverTimeModule.actions.initializeFilters())
      dispatch(AttendeesOnSiteOverTimeModule.actions.initializeMinimalFilters())
    }
    dispatch({ type: AttendeesOnSiteOverTimeModule.actionKeys.getStart })
    const state = getState()
    const timeFilters = AttendeesOnSiteOverTimeModule.selectors.timeFilters(state)
    const minimalFilters = AttendeesOnSiteOverTimeModule.selectors.minimalFilters(state)
    const timezone = eventTimezoneSelector(state)

    const getAttendeesOnSite = () => AttendeesOnSiteOverTimeModule._getService('get')(timeFilters, timezone)
    const getMinimal = () => AttendeesOnSiteOverTimeModule._getService('get')(minimalFilters, timezone)

    return Promise.all([getAttendeesOnSite(), getMinimal()])
      .then(responses => {
        dispatch({
          type: AttendeesOnSiteOverTimeModule.actionKeys.getSuccess,
          payload: {
            attendeesOnSite: responses[0],
            minimalData: responses[1],
          },
        })
      })
      .catch(() => {
        dispatch({ type: AttendeesOnSiteOverTimeModule.actionKeys.getError })
      })
  }
)

AttendeesOnSiteOverTimeModule.registerReducer(AttendeesOnSiteOverTimeModule.actionKeys.getSuccess, (state, action) => ({
  ...state,
  data: action.payload.attendeesOnSite,
  test: action.payload.attendeesOnSite,
  minimalData: action.payload.minimalData,
  isLoading: false,
  isLoaded: true,
  lastUpdated: Date.now(),
}))


export default AttendeesOnSiteOverTimeModule

