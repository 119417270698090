import { combineReducers } from 'redux'

import AppTrafficModule from './appTraffic'
import AttendeesOnSiteStatsModule from './attendeesOnSiteStats'
import AttendeesOnSiteOverTimeModule from './attendeesOnSiteOverTime'
import BadgesModule from './badges'
import CheckInBreakdownModule from './checkInBreakdown'
import CheckInOvertimeModule from './checkInOvertime'
import CheckInProgressModule from './checkInProgress'
import clicksPerTouchpointModule from './clicksPerTouchpoint'
import CommunicationsModule from './communications'
import ContactExchangesModule from './contactExchanges'
import ConversationAcceptanceStatsModule from './conversationAcceptanceStats'
import CurrentAndUpcomingMeetingsModule from './currentAndUpcomingMeetings'
import FiltersModule from './filters'
import HeatmapModule from './heatmap'
import InstallsStatsModule from './installsStats'
import LeaderboardModule from './leaderboard'
import MeetingAcceptanceStatsModule from './meetingAcceptanceStats'
import SessionsModule from './sessions'
import StatisticsModule from './statistics'
import TopicsModule from './topics'
import TouchpointClicksModule from './touchpointClicks'


const reducers = {
  appTraffic: AppTrafficModule.reducers.index,
  attendeesOnSiteStats: AttendeesOnSiteStatsModule.reducers.index,
  attendeesOnSiteOverTime: AttendeesOnSiteOverTimeModule.reducers.index,
  badges: BadgesModule.reducers.index,
  checkInBreakdown: CheckInBreakdownModule.reducers.index,
  checkInOvertime: CheckInOvertimeModule.reducers.index,
  checkInProgress: CheckInProgressModule.reducers.index,
  clicksPerTouchpoint: clicksPerTouchpointModule.reducers.index,
  communications: CommunicationsModule.reducers.index,
  contactExchanges: ContactExchangesModule.reducers.index,
  conversationAcceptanceStats: ConversationAcceptanceStatsModule.reducers.index,
  currentAndUpcomingMeetings: CurrentAndUpcomingMeetingsModule.reducers.index,
  filters: FiltersModule.reducers.index,
  heatmap: HeatmapModule.reducers.index,
  installsStats: InstallsStatsModule.reducers.index,
  leaderboard: LeaderboardModule.reducers.index,
  meetingAcceptanceStats: MeetingAcceptanceStatsModule.reducers.index,
  sessions: SessionsModule.reducers.index,
  statistics: StatisticsModule.reducers.index,
  topics: TopicsModule.reducers.index,
  touchpointClicks: TouchpointClicksModule.reducers.index,
}

export default combineReducers(reducers)
