import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { eventLanguageSelector } from 'admin/modules/events'
import { getAxiosConfig } from './helper'
import { store } from '../../index'


function getLanguage() {
  return eventLanguageSelector(store.getState()) || undefined
}

function translateEmailSchedule(schedule, mainLanguage) {
  const data = { ...schedule }

  if (data.template && data.template.subject) {
    data.template = { ...data.template, subject: getTranslatedObject(data.template.subject, false, mainLanguage) }
  }

  return data
}

function translateEmailTemplate(template, mainLanguage) {
  const data = { ...template }
  data.html = getTranslatedObject(data.html, false, mainLanguage)
  data.short_text = getTranslatedObject(data.short_text, false, mainLanguage)
  data.subject = getTranslatedObject(data.subject, false, mainLanguage)

  data.options = data.options.map(option => ({
    ...option,
    fields: option.fields.map(field => {
      const _field = { ...field } // eslint-disable-line no-underscore-dangle

      if (_field.label) _field.label = getTranslatedObject(_field.label, false, mainLanguage)
      if (_field.value && _field.type !== 'visibility' && _field.type !== 'color') _field.value = getTranslatedObject(_field.value, false, mainLanguage)

      return _field
    }),
  }))

  return data
}

export function serviceGetSchedules(eventId, success, error) {
  return axios.get(`/events/${eventId}/scheduled_notifications`, getAxiosConfig())
    .then((response) => Promise.resolve(response.data.map(schedule => translateEmailSchedule(schedule, getLanguage()))))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetSchedule(eventId, scheduleId, success, error) {
  return axios.get(`/events/${eventId}/scheduled_notifications/${scheduleId}`, getAxiosConfig())
    .then((response) => Promise.resolve(translateEmailSchedule(response.data, getLanguage())))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function servicePostSchedule(eventId, newSchedule, success, error) {
  return axios.post(`/events/${eventId}/scheduled_notifications`, newSchedule, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function servicePutSchedule(eventId, newSchedule, success, error) {
  return axios.put(`/events/${eventId}/scheduled_notifications/${newSchedule.id}`, newSchedule, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeleteSchedule(eventId, scheduleId, success, error) {
  return axios.delete(`/events/${eventId}/scheduled_notifications/${scheduleId}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDuplicateSchedule(eventId, scheduleId, success, error) {
  return axios.post(`/events/${eventId}/scheduled_notifications/${scheduleId}/clone`, {}, getAxiosConfig())
    .then((response) => Promise.resolve(translateEmailSchedule(response.data, getLanguage())))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetScheduleRecipientsCount(eventId, scheduleId, data, success, error) {
  return axios.post(`/events/${eventId}/scheduled_notifications/${scheduleId}/recipients/count`, data, getAxiosConfig())
    .then((response) => success(response.data.count || 0), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceSendPreview(eventId, schedule, success, error) {
  return axios.post(`/events/${eventId}/scheduled_notifications/${schedule.id}/preview`, schedule, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceResendSchedule(eventId, schedule, success, error) {
  return axios.post(`/events/${eventId}/scheduled_notifications/${schedule.id}/resend`, schedule, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetEmailTemplate(eventId, templateId, success, error) {
  return axios.get(`/events/${eventId}/templates/${templateId}`, getAxiosConfig())
    .then((response) => Promise.resolve(translateEmailTemplate(response.data, getLanguage())))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetEmailTemplates(eventId, success, error) {
  return axios.get(`/events/${eventId}/templates`, getAxiosConfig())
    .then((response) => Promise.resolve(response.data.map(emailTemplate => translateEmailTemplate(emailTemplate, getLanguage()))))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function servicePostEmailTemplateRenderedHTML(eventId, templateId, fields, lang, success, error) {
  return axios.post(`/events/${eventId}/templates/${templateId}/preview?language=${lang}`, fields, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function servicePostEmailRenderedHTML(eventId, html, success, error) {
  return axios.post(`/events/${eventId}/templates/sample`, { html }, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function servicePostEmailTemplate(eventId, template, success, error) {
  return axios.post(`/events/${eventId}/templates`, template, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function servicePutEmailTemplate(eventId, template, success, error) {
  return axios.put(`/events/${eventId}/templates/${template.id}`, template, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceScheduleEmailTemplate(eventId, templateId, success, error) {
  return axios.post(`/events/${eventId}/templates/${templateId}/schedule`, {}, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceSendWelcomeEmail(eventId, options, success, error) {
  const url = options.preview ? `/events/${eventId}/tasks/send_welcome_email_preview` : `/events/${eventId}/tasks/send_welcome_email`
  return axios.post(url, {}, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
