import { createSelector } from 'reselect'
import generateFilterModule from './generateFilterModule'

const selectFilters = state => state.attendee.filterSettings.topics.data
export const filtersSelector = createSelector(selectFilters, filters => filters)

export const areAdvancedFiltersEnabledSelector = createSelector(
  selectFilters,
  filters => filters.tags.length > 0
)

export const advancedFiltersCountSelector = createSelector(
  selectFilters,
  filters => filters.tags.length
)

export const querySelector = createSelector(
  selectFilters,
  filters => {
    const query = {}
    if (filters.query) query.query = filters.query
    if (filters.tags.length > 0) query.tags = [...filters.tags]
    return query
  }
)

export default generateFilterModule('topics')
