import { combineReducers } from 'redux'

import AppModule from './app'
import event from './event'
import EventsModule from './events'
import exhibitors from './exhibitors'
import leads from './leads'
import LocationsModule from './locations'
import MapsModule from './maps'
import QualifiersModule from './qualifiers'
import stats from './stats'
import touchpoints from './touchpoints'


export default combineReducers({
  app: AppModule.reducers.index,
  event,
  events: EventsModule.reducers.index,
  exhibitors,
  leads,
  locations: LocationsModule.reducers.index,
  maps: MapsModule.reducers.index,
  qualifiers: QualifiersModule.reducers.index,
  stats,
  touchpoints: touchpoints.reducers.index,
})
