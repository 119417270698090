import { store } from '../../index'
import { getProperty } from 'common/utils/componentHelper'
import { getTranslatedValue } from 'common/utils/translator'
import { prepareLink, setAsAdminPreviewUrl } from 'common/components/Link'
import { appUrl } from 'config'

import { accountIdSelector } from 'attendee/modules/profile'
import { eventIdSelector } from 'attendee/modules/events'
import { isAdminPreviewerSelector } from 'common/modules/auth'
import { conversationInvitationsByAccountIdSelector } from 'attendee/modules/conversationInvitations'
import VideoCallInvitationsModule from 'attendee/modules/videoCallInvitations'


export function makeurl(path) {
  const eventId = eventIdSelector(store.getState())
  return `/events/${eventId}${path}`
}

// ////////////////////////////////////////////////////////////////////////////
// Contacts
// ////////////////////////////////////////////////////////////////////////////

export function getUserFullName(account) {
  return `${getProperty(account, 'first_name')} ${getProperty(account, 'last_name')}`
}

export function getUserJobString(t, account) {
  const occupation = getProperty(account, 'occupation')
  const company = getProperty(account, 'company')
  const jobInfo = { occupation: occupation, company: company }

  if (occupation && company) return t('job.occupation_and_company', jobInfo)
  if (occupation) return t('job.occupation_only', jobInfo)
  if (company) return t('job.company_only', jobInfo)
  return ''
}

// ////////////////////////////////////////////////////////////////////////////
// Exhibitors
// ////////////////////////////////////////////////////////////////////////////

export const ExhibitorHelper = {
  getExhibitorName: exhibitor => getTranslatedValue(exhibitor.name) || getTranslatedValue(exhibitor.company),
  getExhibitorLocationName: exhibitor => getTranslatedValue(getProperty(exhibitor, 'location_name')),
}


// ////////////////////////////////////////////////////////////////////////////
// Locations
// ////////////////////////////////////////////////////////////////////////////

export function isLocationLinkable(location, locations, maps) {
  let curr = location
  if (!curr) return false

  const findLocation = (id) => locations.find(_location => _location.name === id)
  const findMap = (id) => maps.find(_map => _map.id === id)

  let map = findMap(curr.map)
  while (curr && curr.parent && (!map || map.private)) {
    curr = findLocation(curr.parent)
    map = findMap(curr.map)
  }

  return map && !map.private
}

export function getLocationLink(location) {
  return makeurl(`/floorplan/${location.map}/${location.name}`)
}


export const NetworkingHelper = {
  getConversationInvitation: (accountId) => conversationInvitationsByAccountIdSelector(store.getState())[accountId],

  getConversationProfiles: (conversationInvitation) => {
    if (!conversationInvitation) return { otherProfile: null, selfProfile: null }

    return {
      otherProfile: { ...conversationInvitation.account },
      selfProfile: conversationInvitation.from_profile.id !== conversationInvitation.account.id
        ? { ...conversationInvitation.from_profile }
        : { ...conversationInvitation.to_profile },
    }
  },

  getInvitationProfiles: invitation => {
    if (!invitation) {
      return { otherProfile: null, selfProfile: null }
    }

    const selfAccountId = accountIdSelector(store.getState())
    const otherProfile = invitation.from_profile.id === selfAccountId ? invitation.to_profile : invitation.from_profile
    const selfProfile = invitation.from_profile.id === selfAccountId ? invitation.from_profile : invitation.to_profile
    return { otherProfile, selfProfile }
  },

  getVideoChatProfiles: videoInvitation => NetworkingHelper.getInvitationProfiles(videoInvitation),
}

export const VideoCallHelper = {
  acceptInvitation: invitationId => store.dispatch(VideoCallInvitationsModule.actions.accept(invitationId)),
  createInvitation: otherAccountId => store.dispatch(VideoCallInvitationsModule.actions.create(otherAccountId)),
  rejectInvitation: invitationId => store.dispatch(VideoCallInvitationsModule.actions.reject(invitationId)),

  acceptOrCreate: otherAccountId => {
    const pendingVideoCallInvitation = VideoCallInvitationsModule.selectors.pendingInvitationsByAccountId(store.getState())[otherAccountId]
    if (pendingVideoCallInvitation) {
      return VideoCallHelper.acceptInvitation(pendingVideoCallInvitation.id)
    }

    return VideoCallHelper.createInvitation(otherAccountId)
  },
}

export const EventHelper = {
  eventChangeReload: (eventId) => {
    const path = prepareLink(`/events/${eventId}`)
    const isPreviewer = isAdminPreviewerSelector(store.getState())
    let url = `${appUrl}${path}`
    if (isPreviewer) url = setAsAdminPreviewUrl(url)
    window.location.href = url
  },
}

// ////////////////////////////////////////////////////////////////////////////
// Sessions
// ////////////////////////////////////////////////////////////////////////////

export const SessionHelper = {
  getFeedbackRating: (session) => getProperty(session, 'me.feedback.rating'),
}


// ////////////////////////////////////////////////////////////////////////////
// Speakers
// ////////////////////////////////////////////////////////////////////////////

export const SpeakerHelper = {
  getCompanies: speaker => {
    const companies = []

    SpeakerHelper._loopThroughExperience(speaker, experience => {
      if (experience.company) {
        companies.push(getTranslatedValue(experience.company))
      }
    })

    return companies
  },

  _loopThroughExperience: (speaker, callback) => {
    const experience = getProperty(speaker, 'experience')
    if (!experience) return
    experience.forEach(e => {
      if (callback) callback(e)
    })
  },
}
