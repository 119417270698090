import { getProperty } from 'common/utils/componentHelper'
import search, { buildFieldList } from 'common/utils/search'
import { isSessionSaved, isSessionConfirmed } from 'attendee/containers/Schedule/SessionRelations';
import Moment from 'moment'


export function filterSavedOnly(sessions, filters) {
  if (!filters.showSavedOnly) return sessions
  return sessions.filter(session => isSessionSaved(session) && !isSessionConfirmed(session))
}

export function filterConfirmedOnly(sessions, filters) {
  if (!filters.showConfirmedOnly) return sessions
  return sessions.filter(session => isSessionConfirmed(session))
}

export function filterFavorites(sessions, filters) {
  if (!filters.showFavoritesOnly) return sessions
  return sessions.filter(session => getProperty(session, 'me.interested') || getProperty(session, 'me.going') || getProperty(session, 'me.invited.status') === 'pending')
}

export function filterOutFullCapacity(sessions, filters) {
  if (!filters.hideSessionsAtFullCapacity) return sessions
  return sessions.filter(session => (session.capacity == null || session.capacity_available !== 0 || session.me.going || session.me.interested))
}

export function filterOutConflicting(sessions, filters) {
  if (!filters.hideSessionsConflicting) return sessions

  const goingSessions = sessions.filter(s => getProperty(s, 'me.going'))
  const overlaps = (session) => goingSessions.filter(s => session.id !== s.id && Math.max(session.start_date, s.start_date) < Math.min(session.end_date, s.end_date)).length > 0
  return sessions.filter(session => !overlaps(session, goingSessions) && getProperty(session, 'me.registration_allowed'))
}

export function filterBySearch(sessions, filters) {
  if (!filters.search) return sessions

  const getSearchFields = (session) => {
    const fields = [...buildFieldList(session, ['title', 'location.display_name', 'description'])]
    session.speakers.forEach(speaker => { fields.push(...buildFieldList(speaker, ['name', 'role'])) })
    return fields
  }

  return sessions.filter(session => search(filters.search, getSearchFields(session)))
}

export function filterByLocation(sessions, filters) {
  if (!filters.location) return sessions
  return sessions.filter(session => session.location && session.location.name === filters.location)
}

export function filterByDate(sessions, filters, timezone) {
  if (!filters.selectedDate) return sessions
  return sessions.filter(session => Moment.unix(session.start_date).tz(timezone).isSame(Moment.unix(filters.selectedDate).tz(timezone), 'day'))
}

export function filterByCalendar(sessions, filters) {
  if (!filters.calendarTypes || filters.calendarTypes.length <= 0) return sessions
  return sessions.filter(session => filters.calendarTypes.some(calendarId => session.calendar_id === calendarId))
}

export function filterByTags(sessions, filters) {
  if (!filters.tags || filters.tags.length <= 0) return sessions

  const getTagId = (tag) => ((typeof (tag) === 'string') ? tag : tag.id)

  return sessions.filter(session => (
    filters.tagsConditionType === 'AND'
      ? filters.tags.every(tag => session.tags.some(sessionTag => (getTagId(tag) === sessionTag.id)))
      : filters.tags.some(tag => session.tags.some(sessionTag => (getTagId(tag) === sessionTag.id)))
  ))
}

export function sortSessions(sessions, calendarOrder) {
  return sessions.sort((sessionA, sessionB) => {
    const startTimeA = sessionA.start_date
    const startTimeB = sessionB.start_date
    if (startTimeA !== startTimeB) {
      return startTimeA - startTimeB
    }

    const calendarOrderA = calendarOrder[sessionA.calendar_id]
    const calendarOrderB = calendarOrder[sessionB.calendar_id]
    if (calendarOrderA !== calendarOrderB) {
      return calendarOrderA - calendarOrderB
    }

    const titleA = sessionA.title
    const titleB = sessionB.title
    return titleA.localeCompare(titleB)
  })
}
