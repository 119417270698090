import { serviceGetEventFeatures, serviceUpdateEventFeatures } from 'admin/services/events'
import generateModule from 'common/utils/generateModule'


const { actions, reducers } = generateModule({
  itemName: 'eventFeature',
  getModuleState: (getState) => getState().admin.eventFeatures,
  idFieldName: 'id',
  dataIsNotArray: true,
  services: {
    get: serviceGetEventFeatures,
    mod: serviceUpdateEventFeatures,
  },
})


export const getEventFeatures = actions.get
export const getEventFeaturesIfNeeded = actions.getIfNeeded
export const modifyEventFeatures = actions.modify

const eventFeatures = reducers.index
export default eventFeatures
