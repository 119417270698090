// Protocols that we allow when sanitizing a url
const SAFE_PROTOCOLS = ['http', 'https', 'mailto', 'webcal', 'tel']


function hasProtocol(protocols, value) {
  if (!value) return false

  return protocols.some(protocol => {
    const protocolRegex = new RegExp(`^${protocol}:`)
    return value.match(protocolRegex)
  })
}


/*
| Returns a sanitized link or an empty string if the url is suspected
| to be malicious. To be used mainly for getting an href
*/
export function sanitizeLink(url) {
  return (hasProtocol(SAFE_PROTOCOLS, url) ? url : '')
}


/*
| Returns a url with "HTTP" prepended if the protocol is missing.
*/
export function prependHTTP(url) {
  if (!url) return url
  let link = url

  if (!hasProtocol(SAFE_PROTOCOLS, link)) {
    link = 'http://' + link
  }

  return link
}

export function isKlikURL(url) {
  return url.startsWith('klik://')
}

export function getURLfromCTA(cta) {
  return isKlikURL(cta.url) ? cta.url.substring(6) : cta.url
}

export function replaceQueryStringInURL(url, key, value) {
  const hasParam = url.match(RegExp('(\\&|\\?)' + key + '='))

  if (hasParam) {
    const paramRegex = RegExp(key + '=[^&]+')
    const newParam = `${key}=${value}`
    return url.replace(paramRegex, newParam)
  }

  const hasParams = url.indexOf('?') > -1
  return hasParams ? url + `&${key}=${value}` : url + `?${key}=${value}`
}

export function buildExternalURL(pathname) {
  return window.location.origin + pathname
}
