import fullAccessPermissions from './FullAccess'

export default {
  action: {
    ...fullAccessPermissions.action,

    // Attendees
    addAttendees: false,
    deleteAttendees: false,
    editAttendees: false,
    generateWebviewLinks: false,
    impersonateAttendees: false,
    importAttendees: false,
    manageFields: false,
    viewAttendeesLocation: false,

    // Events
    createEvents: false,
    editEvents: false,

    // Exhibitors
    addExhibitorAdmins: false,
    addExhibitors: false,
    addRepresentatives: false,
    deleteExhibitorAdmins: false,
    deleteExhibitors: false,
    deleteRepresentatives: false,
    editExhibitors: false,
    impersonateExhibitors: false,
    importExhibitors: false,
    viewExhibitorsLocation: false,

    // Schedule
    addAttendeesToSessions: false,
    addCalendars: false,
    addSessions: false,
    deleteSessions: false,
    editSessions: false,
    exportSessions: false,
    importSessions: false,
    modifyCalendars: false,
    modifyTags: false,

    // Users
    modifyUsers: false,
  },

  menu: {
    ...fullAccessPermissions.menu,
    checkIn: false,
    communication: false,
    event: false,
    floorPlan: false,
    gamification: false,
    networking: false,
    registration: false,
    speakers: false,
    system: false,
    users: false,
  },

  page: {
    ...fullAccessPermissions.page,
    broadcast: false,
    exports: false,
  },
}
