import { createSelector } from 'reselect'
import generateFilterModule from './generateFilterModule'

const selectFilters = state => state.attendee.filterSettings.badges.data
export const filtersSelector = createSelector(selectFilters, filters => filters)

export const areFiltersEnabledSelector = createSelector(
  selectFilters,
  filters => filters.search || filters.tags.length > 0
)

export const filtersCountSelector = createSelector(
  selectFilters,
  (filters) => {
    let filtersCount = 0
    if (filters.search) filtersCount++
    filtersCount += filters.tags.length
    return filtersCount
  }
)

export default generateFilterModule('badges')
