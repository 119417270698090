import axios from 'axios'
import { assetsUrl } from 'config'
import { apiErrorHandler, apiCatchError, buildError } from 'common/utils/api'
import { buildAssetURL } from 'common/utils/assets'
import { getTranslatedObject } from 'common/utils/translator'
import { getAxiosConfig } from './helper'


export function translateCustomField(customField) {
  return {
    ...customField,
    title: getTranslatedObject(customField.title),
    options: customField.options ? customField.options.map(option => ({ ...option, title: getTranslatedObject(option.title) })) : [],
  }
}

export function translateEvent(event) {
  const data = { ...event }

  data.description = getTranslatedObject(data.description)
  data.location = getTranslatedObject(data.location)
  data.title = getTranslatedObject(data.title)
  data.date_label = getTranslatedObject(data.date_label)

  if (data.levels) {
    data.levels = data.levels.map(level => ({ ...level, title: getTranslatedObject(level.title) }))
  }

  return data
}

function translateEventSections(sections) {
  const data = { ...sections }

  if (data.branding) {
    data.branding.rules_display_name = getTranslatedObject(data.branding.rules_display_name)
  }

  if (data.ui) {
    if (data.ui.tutorial) data.ui.tutorial = { ...data.ui.tutorial, label: getTranslatedObject(data.ui.tutorial.label) }
    data.ui.tutorial_title = getTranslatedObject(data.ui.tutorial_title)
    data.ui.onboarding = data.ui.onboarding.map(onboarding => ({
      ...onboarding,
      description: getTranslatedObject(onboarding.description),
      prompt: getTranslatedObject(onboarding.prompt),
    }))
    data.ui.onboarding_welcome_text = getTranslatedObject(data.ui.onboarding_welcome_text)
  }

  if (data.fields) {
    data.fields.account_custom_fields = data.fields.account_custom_fields.map(translateCustomField)
    data.fields.account_groups = data.fields.account_groups.map(group => ({ ...group, title: getTranslatedObject(group.title) }))
  }

  return data
}

function translateCarousel(carousel) {
  return {
    ...carousel,
    body: getTranslatedObject(carousel.body),
  }
}

function sortCarousel(carousel, field) {
  return carousel.sort((a, b) => b[field] - a[field])
}

export function serviceGetEvent(eventId, success, error) {
  return axios.get(`/events/${eventId}`, getAxiosConfig())
    .then((response) => Promise.resolve(translateEvent(response.data)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetManagedEvents(isArchived, success, error) {
  return axios.get(`/events?managed=true&archived=${isArchived}&include_archived=true`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceAddEvent(event, success, error) {
  const data = {
    ...event,
  }

  if (event.admin_email !== undefined && event.admin_email.length === 0) {
    delete data.admin_email
  }

  if (event.admin_pass !== undefined && event.admin_pass.length === 0) {
    delete data.admin_pass
  }

  return axios.post('/events/', JSON.stringify(event), getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceCreateDemoEvent(event, success, error) {
  return axios.post('/demo_events', JSON.stringify(event), getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateEvent(eventId, event, success, error) {
  return axios.put(`/events/${eventId}/?sections=all`, JSON.stringify(event), getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateDomainBranding(domain, data, success, error) {
  return axios.put(`/domains/${domain}`, data, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetEventConfig(eventId, sections, success, error) {
  return axios.get(`/events/${eventId}/conf?sections=${sections}`, getAxiosConfig())
    .then((response) => Promise.resolve(translateEventSections(response.data)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateEventConf(eventId, sections, data, success, error) {
  return axios.put(`/events/${eventId}/?sections=${sections}`, JSON.stringify(data), getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetEventMenu(eventId) {
  return new Promise((resolve, reject) => {
    axios.get(`/events/${eventId}/conf/menu`, getAxiosConfig())
      .then(response => resolve(response.data.event_menu))
      .catch(() => apiErrorHandler(error => reject(error), 'backend'))
  })
}

export function serviceUpdateEventMenu(eventId, menu) {
  return new Promise((resolve, reject) => {
    axios.put(`/events/${eventId}/conf/menu`, { event_menu: menu }, getAxiosConfig())
      .then(response => resolve(response.data.event_menu))
      .catch(() => apiErrorHandler(error => reject(error), 'backend'))
  })
}

export function serviceGetEventFeatures(eventId, success, error) {
  return axios.get(`/events/${eventId}/conf/features/event_features`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateEventFeatures(eventId, features, success, error) {
  return axios.put(`/events/${eventId}/conf/features/event_features`, features, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetCarouselPreview(eventId, carouselCard, language, success, error) {
  return axios.post(`/events/${eventId}/conf/carousel/preview`, { card: carouselCard, language }, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetCarousel(eventId, success, error) {
  return axios.get(`/events/${eventId}/conf/carousel`, getAxiosConfig())
    .then((response) => response.data.event_carousel.map(translateCarousel))
    .then((data) => success(sortCarousel(data, 'active')), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateCarousel(eventId, carousel, success, error) {
  return axios.put(`/events/${eventId}/conf/carousel`, { event_carousel: carousel }, getAxiosConfig())
    .then((response) => response.data.event_carousel.map(translateCarousel))
    .then((data) => success(sortCarousel(data, 'active')), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUploadAsset(eventId, assetName, asset, success, error) {
  const axiosConfig = { ...getAxiosConfig() }
  axiosConfig.headers = { ...axiosConfig.headers, 'Content-Type': 'multipart/form-data' }

  return axios.post(`${assetsUrl}/upload`, asset, axiosConfig)
    .then((response) => success(buildAssetURL(response.data.id)), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceModifyEventSectionFields(eventId, fields, success, error) {
  return axios.put(`/events/${eventId}/conf?sections=fields`, JSON.stringify(fields), getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetEventSectionFields(eventId, success, error) {
  return axios.get(`/events/${eventId}/conf?sections=fields`, getAxiosConfig())
    .then((response) => Promise.resolve(translateEventSections(response.data)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}


export function serviceGetCustomFields(eventId, success, error) {
  return axios.get(`/events/${eventId}/fields?type=custom`, getAxiosConfig())
    .then((response) => [...response.data].map(customField => translateCustomField(customField)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceCreateCustomField(eventId, customField, success, error) {
  return axios.post(`/events/${eventId}/fields?type=custom`, customField, getAxiosConfig())
    .then((response) => translateCustomField(response.data))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateCustomField(eventId, customField, success, error) {
  return axios.put(`/events/${eventId}/fields/${customField.id}?type=custom`, customField, getAxiosConfig())
    .then((response) => translateCustomField(response.data))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeleteCustomField(eventId, customFieldId, success, error) {
  return axios.delete(`/events/${eventId}/fields/${customFieldId}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetBridges(eventId, success, error) {
  return axios.get('/bridges', getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function servicePutBridge(eventId, bridgeAddress, data, success, error) {
  return axios.put(`/bridges/${bridgeAddress}`, data, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

function translatePrivacyPolicy(data) {
  return {
    ...data,
    summary: getTranslatedObject(data.summary),
    decline_consequences: getTranslatedObject(data.decline_consequences),
    privacy_policy_url: getTranslatedObject(data.privacy_policy_url),
    terms_and_conditions_url: getTranslatedObject(data.terms_and_conditions_url),
  }
}

export function serviceGetPrivacyPolicy(eventId) {
  return axios.get(`/events/${eventId}/conf/ui/privacy_policy`, getAxiosConfig())
    .then(response => translatePrivacyPolicy(response.data))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceUpdatePrivacyPolicy(eventId, data) {
  return axios.put(`/events/${eventId}/conf/ui/privacy_policy`, data, getAxiosConfig())
    .then(response => translatePrivacyPolicy(response.data))
    .catch(error => Promise.reject(buildError(error)))
}
