import { DataModule } from 'redux-data-module'
import { serviceGetTopics, servicePutTopic } from 'admin/services/networking'
import { getTranslation } from 'common/utils/translator'

import { currentEventIdSelector } from 'admin/modules/events'
import { showToast } from 'common/modules/toaster'


const TopicsModule = new DataModule({
  moduleKey: 'cpo/topics',
  reducerKey: 'admin.topics',

  services: {
    get: { service: serviceGetTopics },
    put: { service: servicePutTopic },
  },
});

(function registerFilters() {
  TopicsModule.initialState.filters = {
    searchTerm: '',
  }

  TopicsModule.registerSelector('filters', (state, moduleState) => moduleState.filters, (filters) => filters)
  TopicsModule.registerSelector('query', (state, moduleState) => moduleState.filters, (filters) => {
    const query = {}
    if (filters.searchTerm) query.query = filters.searchTerm
    query.limit = 50
    return query
  })

  TopicsModule.registerActionKey('setFilters')

  TopicsModule.actions.setFilters = (filters) => (dispatch) => dispatch({
    type: TopicsModule.actionKeys.setFilters,
    payload: filters || TopicsModule.initialState.filters,
  })

  TopicsModule.reducers[TopicsModule.actionKeys.setFilters] = (state, action) => ({
    ...state,
    filters: { ...state.filters, ...action.payload },
  })
}());

(function registerGetTopics() {
  TopicsModule.actions.get = (before) => (dispatch, getState) => {
    const query = TopicsModule.selectors.query(getState())
    if (before) query.before = before

    dispatch({ type: TopicsModule.actionKeys.getStart, payload: query })
    return serviceGetTopics(currentEventIdSelector(getState()), query)
      .then((response) => {
        dispatch({ type: TopicsModule.actionKeys.getSuccess, payload: { topics: response, query: query } })
        return Promise.resolve(response.length < query.limit)
      })
      .catch((error) => {
        dispatch({ type: TopicsModule.actionKeys.getError, payload: query })
        dispatch(showToast({
          title: getTranslation('admin_customModule', 'topics.get.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
        return Promise.reject(error)
      })
  }

  TopicsModule.reducers[TopicsModule.actionKeys.getSuccess] = (state, action) => ({
    ...state,
    isLoaded: true,
    isLoading: false,
    data: action.payload.query.before != null ? [...state.data, ...action.payload.topics] : action.payload.topics,
  })
}());

(function registerMarkAsRemoved() {
  TopicsModule.registerActionKey('markAsRemoved')

  TopicsModule.actions.markAsRemoved = (id) =>
    (dispatch, getState) => servicePutTopic(currentEventIdSelector(getState()), { id: id, hidden_by_admin: true })
      .then((response) => dispatch({ type: TopicsModule.actionKeys.markAsRemoved, payload: response }))
      .catch((error) => {
        dispatch(showToast({
          title: getTranslation('admin_customModules', 'mark_as_removed.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      })

  TopicsModule.reducers[TopicsModule.actionKeys.markAsRemoved] = (state, action) => ({
    ...state,
    data: state.data.map(d => (d.id === action.payload.id ? { ...action.payload } : d)),
  })
}());

(function registerClearData() {
  TopicsModule.registerActionKey('clearData')
  TopicsModule.actions.clearData = () => (dispatch) => dispatch({ type: TopicsModule.actionKeys.clearData })
  TopicsModule.reducers[TopicsModule.actionKeys.clearData] = (state) => ({ ...state, data: [] })
}())


export default TopicsModule
