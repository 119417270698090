import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceGetVenueMaps(eventId, success, error) {
  return axios.get(`/events/${eventId}/maps`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'locator'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetLocations(eventId, success, error) {
  return axios.get(`/events/${eventId}/locations`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetAttendeeLocation(eventId, success, error) {
  return axios.get(`/events/${eventId}/attendees/me/location`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
