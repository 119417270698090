import React, { Component } from 'react'

import Checkbox from 'common/components/Checkbox'


export default class CheckboxInput extends Component {
  getLabelStyle() {
    const { labelSize } = this.props

    const style = {
      marginLeft: '12px',
      fontSize: `${labelSize}px`,
    }

    return style
  }

  render() {
    const { id, isTranslatable, size, field, label, disabled, rounded } = this.props
    const iconSize = this.props.iconSize || (size * 0.66)

    return (
      <div className="klik-checkbox-input form-group schema-form-text" id={id} data-isTranslatable={isTranslatable} data-input-type="checkbox" data-disabled={disabled ? '' : null} onClick={() => (disabled ? null : field.onChange(!field.checked))} style={{ display: 'inline-flex' }}>
        <Checkbox size={size} iconSize={iconSize} checked={field.checked} disabled={disabled} rounded={rounded} />
        {label ? <span className="klik-checkbox-label" style={this.getLabelStyle()}>{label}</span> : null}
      </div>
    )
  }
}

CheckboxInput.defaultProps = {
  id: '',
  size: 16,
  labelSize: 12,
  rounded: false,
}
