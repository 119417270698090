import { serviceGetExhibitors } from 'attendee/services/exhibitors'
import generateModule from 'common/utils/generateModule'
import { createSelector } from 'reselect'
import { filtersSelector } from 'attendee/modules/FilterSettings/exhibitors'
import search, { buildFieldList } from 'common/utils/search'
import { getProperty, listToMapping } from 'common/utils/componentHelper'


const selectState = (state) => state.attendee.exhibitors
const selectExhibitors = (state) => selectState(state).data

export const exhibitorsSelector = createSelector(
  selectExhibitors,
  filtersSelector,
  (exhibitors, filters) => {
    let filteredExhibitors = [...exhibitors];

    (function filterBySearch() {
      if (!filters.search) return
      filteredExhibitors = filteredExhibitors.filter(exhibitor => search(filters.search, buildFieldList(exhibitor, ['name', 'description', 'company', 'location_name', 'location.display_name', 'contact_email'])))
    }());

    (function sortAlphabetically() {
      filteredExhibitors.sort((a, b) => {
        const nameA = a.name || a.company
        const nameB = b.name || b.company
        return nameA.localeCompare(nameB)
      })
    }());

    const exhibitorsByExhibitorType = []
    const exhibitorTypesById = {}

    // Get exhibitor types and group exhibitors by types
    filteredExhibitors.forEach(exhibitor => {
      const exhibitorType = getProperty(exhibitor, 'exhibitor_type')
      const exhibitorTypeId = getProperty(exhibitorType, 'id') || null

      if (exhibitorType && !exhibitorTypesById[exhibitorTypeId]) {
        exhibitorTypesById[exhibitorTypeId] = exhibitorType
      }

      if (!exhibitorsByExhibitorType[exhibitorTypeId]) {
        exhibitorsByExhibitorType[exhibitorTypeId] = []
      }

      exhibitorsByExhibitorType[exhibitorTypeId].push(exhibitor)
    })

    // Get exhibitors by types, ordered
    const exhibitorsByTypes = []
    Object.values(exhibitorTypesById).sort((a, b) => b.order - a.order).forEach(exhibitorType => {
      const exhibitorsForType = exhibitorsByExhibitorType[exhibitorType.id]
      if (exhibitorsForType) {
        exhibitorsByTypes.push(exhibitorsForType)
      }
    })

    if (exhibitorsByExhibitorType[null]) {
      exhibitorsByTypes.push(exhibitorsByExhibitorType[null])
    }

    return exhibitorsByTypes
  }
)

export const exhibitorsByIdSelector = createSelector(selectExhibitors, (exhibitors) => listToMapping(exhibitors, 'id'))

export const exhibitorTypesByIdSelector = createSelector(selectExhibitors, exhibitors => {
  const exhibitorTypesById = {}

  exhibitors.forEach(exhibitor => {
    const exhibitorType = getProperty(exhibitor, 'exhibitor_type')
    const exhibitorTypeId = getProperty(exhibitorType, 'id') || null

    if (exhibitorType && !exhibitorTypesById[exhibitorTypeId]) {
      exhibitorTypesById[exhibitorTypeId] = exhibitorType
    }
  })

  return exhibitorTypesById
})

export const hasExhibitorTypesSelector = createSelector(exhibitorTypesByIdSelector, exhibitorTypes => Object.keys(exhibitorTypes).length > 0)


const exhibitors = generateModule({
  itemName: 'exhibitor',
  itemNameDisplay: 'exhibitor',
  getModuleState: (getState) => getState().attendee.exhibitors,
  autoRefresh: 60,
  services: {
    get: serviceGetExhibitors,
  },
  app: 'attendee',
})


exhibitors.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isError: false,
  isLoaded: false,
  didInvalidate: true,
  data: [],
})

export default exhibitors
