import { generateDataModule } from 'admin/modules/generateModule'
import { addSortingSupport, addPaginationSupport } from './helper'
import { serviceGetCurrentAndUpcomingMeetings } from 'admin/services/dashboard'
import sort from 'common/utils/sortHelper'
import LocationsHelper from 'admin/utils/DataObjects/Locations'
import { eventTimezoneSelector } from 'admin/modules/events'
import Moment from 'moment'


const DEFAULT_DATA = {
  data: {
    stats: [],
  },
}

const currentAndUpcomingMeetingsModule = generateDataModule({
  initialData: {
    currentMeetings: DEFAULT_DATA,
    upcomingMeetings: DEFAULT_DATA,
  },
  initialState: {
    filters: {
      locationName: undefined,
    },
    currentQueryParams: {
      search_by: 'end_date',
      time_range: undefined,
      statuses: 'confirmed',
    },
    upcomingQueryParams: {
      search_by: 'start_date',
      time_range: 60 * 60 * 12,
      statuses: 'confirmed',
      sort_column: 'time',
      sort_order: 1,
      per_page: 10,
      page: undefined,
    },
    pagination: {
      page: undefined,
      pageCount: 1,
    },
  },

  moduleKey: 'dashboardCurrentAndUpcomingMeetings',
  reducerKey: 'admin.dashboard.currentAndUpcomingMeetings',
  services: {
    get: { service: serviceGetCurrentAndUpcomingMeetings },
  },
})


function filterMeetings(meetings, filters) {
  let filteredMeetings = [...meetings]
  filteredMeetings = filters.locationName ? filteredMeetings.filter(meeting => meeting.location === filters.locationName) : filteredMeetings
  return filteredMeetings
}

addSortingSupport(currentAndUpcomingMeetingsModule, { id: 'amount', order: -1 }, {
  baseKey: 'currentMeetingsSorter',
  resetKey: 'currentMeetingsResetSorter',
  setKey: 'currentMeetingsSetSorter',
})

addSortingSupport(currentAndUpcomingMeetingsModule, { id: 'time', order: 1 }, {
  baseKey: 'upcomingMeetingsSorter',
  resetKey: 'upcomingMeetingsResetSorter',
  setKey: 'upcomingMeetingsSetSorter',
})

addPaginationSupport(currentAndUpcomingMeetingsModule)

currentAndUpcomingMeetingsModule.registerSelector('rawCurrent', (state, moduleState) => moduleState.data.currentMeetings.data.stats, sessions => sessions)
currentAndUpcomingMeetingsModule.registerSelector('rawUpcoming', (state, moduleState) => moduleState.data.upcomingMeetings.data.stats, sessions => sessions)

currentAndUpcomingMeetingsModule.registerSelector(
  'currentMeetings',
  [
    (state, moduleState) => moduleState.data.currentMeetings.data.stats,
    state => currentAndUpcomingMeetingsModule.selectors.currentMeetingsSorter(state),
    state => currentAndUpcomingMeetingsModule.selectors.filters(state),
  ],
  (meetings, sorter, filters) => sort(filterMeetings(meetings, filters), [
    { id: sorter.id, order: sorter.order },
    { id: 'amount', order: -1 },
  ])
)

currentAndUpcomingMeetingsModule.registerSelector(
  'upcomingMeetings',
  [
    (state, moduleState) => moduleState.data.upcomingMeetings.data.stats,
    state => currentAndUpcomingMeetingsModule.selectors.upcomingMeetingsSorter(state),
    state => currentAndUpcomingMeetingsModule.selectors.filters(state),

  ],
  (meetings, sorter, filters) => sort(filterMeetings(meetings, filters), [
    { id: sorter.id, order: sorter.order },
    { id: 'location', order: 1 },
    'amount',
  ])
)

currentAndUpcomingMeetingsModule.registerSelector(
  'locations',
  [
    state => currentAndUpcomingMeetingsModule.selectors.rawCurrent(state),
    state => currentAndUpcomingMeetingsModule.selectors.rawUpcoming(state),
  ],
  (currentMeetings, upcomingMeetings) => {
    const locations = {};

    [...currentMeetings, ...upcomingMeetings].forEach(meeting => {
      if (meeting.location && !locations[meeting.location]) {
        locations[meeting.location] = {
          value: meeting.location,
          name: LocationsHelper.getLocationTitle(meeting.location),
        }
      }
    })
    return sort(Object.values(locations), ['display_name', 'name'])
  }
)

currentAndUpcomingMeetingsModule.registerSelector('filters', (state, moduleState) => moduleState.filters, filters => filters)
currentAndUpcomingMeetingsModule.registerSelector('currentQueryParams', (state, moduleState) => moduleState.currentQueryParams, currentQueryParams => currentQueryParams)
currentAndUpcomingMeetingsModule.registerSelector('upcomingQueryParams', (state, moduleState) => moduleState.upcomingQueryParams, upcomingQueryParams => upcomingQueryParams)
currentAndUpcomingMeetingsModule.registerSelector('timeRange', (state, moduleState) => moduleState.currentQueryParams.time_range, timeRange => timeRange / 60)

currentAndUpcomingMeetingsModule.registerAction(
  'setFilters',
  filters => dispatch => {
    dispatch({ type: currentAndUpcomingMeetingsModule.actionKeys.setFilters, payload: filters })
    return Promise.resolve()
  }
)

currentAndUpcomingMeetingsModule.registerReducer(
  currentAndUpcomingMeetingsModule.actionKeys.setFilters,
  (state, action) => ({ ...state, filters: { ...state.filters, ...action.payload } })
)
currentAndUpcomingMeetingsModule.registerAction(
  'resetFilters',
  () => dispatch => {
    dispatch({ type: currentAndUpcomingMeetingsModule.actionKeys.resetFilters })
    return Promise.resolve()
  }
)


currentAndUpcomingMeetingsModule.registerReducer(
  currentAndUpcomingMeetingsModule.actionKeys.resetFilters,
  state => ({ ...state, filters: { ...currentAndUpcomingMeetingsModule.initialState.filters } })
)

currentAndUpcomingMeetingsModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {

    // get time remaining until end of current meetings ( 15 minute intervals)
    const timezone = eventTimezoneSelector(getState())
    const currentMinute = Moment().tz(timezone).minute()
    const timeRemaining = (15 - (currentMinute % 15)) * 60

    const currentQueryParams = currentAndUpcomingMeetingsModule.selectors.currentQueryParams(getState())
    currentQueryParams.time_range = timeRemaining

    const upcomingQueryParams = currentAndUpcomingMeetingsModule.selectors.upcomingQueryParams(getState())

    dispatch({ type: currentAndUpcomingMeetingsModule.actionKeys.getStart })

    return currentAndUpcomingMeetingsModule._getService('get')(currentQueryParams, upcomingQueryParams)
      .then(response => {
        dispatch({ type: currentAndUpcomingMeetingsModule.actionKeys.getSuccess, payload: response })
      })
      .catch(() => {
        dispatch({ type: currentAndUpcomingMeetingsModule.actionKeys.getError })
      })
  }
)

currentAndUpcomingMeetingsModule.registerReducer(currentAndUpcomingMeetingsModule.actionKeys.getSuccess, (state, action) => ({
  ...state,
  data: {
    currentMeetings: action.payload.currentMeetings,
    upcomingMeetings: action.payload.upcomingMeetings,
  },
  pagination: {
    page: action.payload.page,
    pageCount: action.payload.pageCount,
  },
  isLoading: false,
  isLoaded: true,
}))


export default currentAndUpcomingMeetingsModule
