import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getAxiosConfig } from './helper'

const enableMocks = false;
const notificationMock = {
  id: '6nHJwgUQEeyezAJCrBIAAg',
  date: 1629833075,
  accepted: false,
  ignored: false,
  rejected: false,
  short_text: 'Test notification',
  read: false,
  call_to_action: {
    url: 'http://localhost:3000/events/3CcQtgUQEeyLpwJCrBIAAg/attendees/3CcQtgUQEeyLpwJCrBIAAg/invitations/contact/3CcQtgUQEeyLpwJCrBIAAg/accept',
  },
  from_profile: {
    id: '3CcQtgUQEeyLpwJCrBIAAg',
    account_id: '3CcQtgUQEeyLpwJCrBIAAg',
    first_name: 'Grom',
    last_name: 'Hellscream',
    occupation: 'Director',
    company: 'Durotar',
    location: null,
    image: null,
    interest_tags: [],
    custom_fields: {},
    introduction: null,
  },
  to_profile: {
    id: 'FlZn3gRMEeylAQJCrBIAAg',
    account_id: 'FlZn3gRMEeylAQJCrBIAAg',
    first_name: 'Illidan',
    last_name: 'Stormrage',
    occupation: 'CEO',
    company: 'Black Temple',
    location: 'Montreal',
    image: null,
    interest_tags: [],
    custom_fields: {},
    introduction: null,
    ignored: [],
  },
  account: {
    id: '3CcQtgUQEeyLpwJCrBIAAg',
    account_id: '3CcQtgUQEeyLpwJCrBIAAg',
    first_name: 'Grom',
    last_name: 'Hellscream',
    occupation: 'Director',
    company: 'Durotar',
    location: null,
    image: null,
    interest_tags: [],
    custom_fields: {},
    introduction: null,
  },
  contact: null,
  updated_at: 1629833075,
  last_invite_date: 1629833075,
  initiated_from: {},
};

const notificationsMock = {
  notifications: [notificationMock],
  num_unread: 0,
};

export function serviceGetNotifications(eventId, success, error) {
  if (enableMocks) {
    return success(notificationsMock);
  }

  return axios.get(`events/${eventId}/attendees/me/notifications`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceClickNotification(eventId, notification, success, error) {
  return axios.put(`events/${eventId}/attendees/me/notifications/${notification.id}`, { read: true, clicked: true }, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceReadNotification(eventId, success, error) {
  return axios.put(`events/${eventId}/attendees/me/notifications/all`, { read: true }, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
