import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PropTypeCallToAction } from 'common/utils/PropTypesHelper'
import { isKlikURL, getURLfromCTA } from 'common/utils/links'
import { getTranslatedValue } from 'common/utils/translator'

import ExternalLink from 'common/components/ExternalLink'
import Link from 'common/components/Link'


export default class CallToAction extends Component {
  getClassName() {
    let className = 'CallToAction'
    className += this.props.className ? ` ${this.props.className}` : ''
    return className
  }

  render() {
    const { data, customContent, shouldShowArrow, style } = this.props
    const className = this.getClassName()

    if (data.url && isKlikURL(data.url)) {
      return (
        <Link className={className} style={style} to={getURLfromCTA(data)} onClick={this.props.onClick}>
          {customContent}
          {customContent ? null : getTranslatedValue(data.label)}
          {(customContent || !shouldShowArrow) ? null : <Arrow />}
        </Link>
      )
    }

    if (data.http_url || data.url) {
      return (
        <ExternalLink className={className} style={style} href={data.http_url || data.url} target={data.target} onClick={this.props.onClick}>
          {customContent}
          {customContent ? null : getTranslatedValue(data.label)}
          {(customContent || !shouldShowArrow) ? null : <Arrow />}
        </ExternalLink>
      )
    }

    return (
      <div className={className} style={style}>
        {customContent}
        {customContent ? null : getTranslatedValue(data.label)}
        {(customContent || !shouldShowArrow) ? null : <Arrow />}
      </div>
    )
  }
}

CallToAction.defaultProps = {
  customContent: null,
}

CallToAction.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  data: PropTypeCallToAction,
  customContent: PropTypes.node,
  shouldShowArrow: PropTypes.bool,
  onClick: PropTypes.func,
}


function Arrow() {
  return <i className="fa fa-angle-right" />
}
