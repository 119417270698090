import { getTranslatedValue } from 'common/utils/translator'
import { store } from '../../../index'

import TouchpointsModule from 'admin/modules/touchpoints'


const getTouchpointTitleById = () => TouchpointsModule.selectors.touchpointsTitleById(store.getState())

export default {
  getTouchpointTitle: (touchpointId, touchpointTitleById) => {
    let _touchpointId = touchpointId

    if (typeof _touchpointId === 'string') {
      const _touchpointTitleById = touchpointTitleById || getTouchpointTitleById()
      _touchpointId = _touchpointTitleById[_touchpointId] || _touchpointId
    }
    return getTranslatedValue(_touchpointId) || _touchpointId
  },
}
