import { createSelector } from 'reselect'
import generateFilterModule from './generateFilterModule'

const selectFilters = state => state.attendee.filterSettings.schedule.data
export const filtersSelector = createSelector(selectFilters, filters => filters)

export const advancedFiltersEnabledSelector = createSelector(
  selectFilters,
  filters => [filters.search, filters.hideSessionsAtFullCapacity, filters.hideSessionsConflicting, filters.showDaysWithNoSessions].some(b => b) || filters.tags.length > 0
)

export const advancedFiltersCountSelector = createSelector(
  selectFilters,
  (filters) => {
    let filtersCount = 0
    if (filters.search) filtersCount++
    filtersCount += filters.tags.length
    if (filters.hideSessionsAtFullCapacity) filtersCount++
    if (filters.hideSessionsConflicting) filtersCount++
    if (filters.showDaysWithNoSessions) filtersCount++
    return filtersCount
  }
)

export default generateFilterModule('schedule')
