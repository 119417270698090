import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getAxiosConfig } from './helper'
import { formatAsId } from 'common/utils/stringFormatter'

function getCarouselWithId(carousel) {
  return { ...carousel, id: formatAsId(carousel.name) }
}


export function serviceGetCarousel(eventId, success, error) { // eslint-disable-line import/prefer-default-export
  return axios.get(`/events/${eventId}/attendees/me/my_carousel`, getAxiosConfig())
    .then((response) => success(response.data.map(getCarouselWithId)), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
