import { serviceGetContacts, serviceUpdateContact, serviceDeleteContact } from 'attendee/services/contacts'
import generateModule from 'common/utils/generateModule'
import { handleErrorMessage } from './index'
import i18n from 'i18next'
import { createSelector } from 'reselect'
import { listToMapping } from 'common/utils/componentHelper'


const selectState = (state) => state.attendee.attContacts
const selectData = (state) => selectState(state).data

export const contactsByAccountIdSelector = createSelector(selectData, (contacts) => listToMapping(contacts, 'account.id'))


function findIndexOfContact(contacts, id) {
  for (let i = 0; i < contacts.length; i++) {
    if (contacts[i].id === id) {
      return i;
    }
  }

  return -1
}

function updateContact(_contacts, id, fields) {
  const contacts = [..._contacts]
  const contactIndex = findIndexOfContact(_contacts, id)

  if (contactIndex !== -1) {
    contacts[contactIndex] = {
      ...contacts[contactIndex],
      ...fields,
    }
  }

  return contacts
}


const attContacts = generateModule({
  itemName: 'attContact',
  itemNameDisplay: 'contact',
  autoRefresh: 60,
  services: {
    get: serviceGetContacts,
    upd: serviceUpdateContact,
    del: serviceDeleteContact,
  },
  app: 'attendee',
})

attContacts.actions.get = () =>
  (dispatch, getState) => {
    dispatch(attContacts.actions.getStart())
    return serviceGetContacts(
      getState().attendee.attEvents.currEvent.id,
      result => dispatch(attContacts.actions.getSuccess(result)),
      error => dispatch(attContacts.actions.getError(error.description))
    )
  }

attContacts.actions.update = contact => (dispatch, getState) => {
  const initialContact = getState().attendee.attContacts.data.find(c => c.id === contact.id)

  dispatch({ type: 'UPDATE_CONTACT_RATINGS', payload: { id: contact.id, ratings: contact.ratings } })
  dispatch({ type: 'UPDATE_CONTACT_NOTES', payload: { id: contact.id, notes: contact.notes } })

  dispatch(attContacts.actions.updateStart(contact))
  return serviceUpdateContact(
    contact.event_id,
    contact.account.id,
    { ratings: contact.ratings, notes: contact.notes },
    c => {
      dispatch(attContacts.actions.updateSuccess(c))
      return Promise.resolve(contact.ratings)
    },
    error => {
      dispatch({ type: 'UPDATE_CONTACT_RATINGS', payload: { id: contact.id, ratings: initialContact.ratings } })
      dispatch({ type: 'UPDATE_CONTACT_NOTES', payload: { id: contact.id, notes: initialContact.notes } })

      dispatch(attContacts.actions.updateError(error.description))
      handleErrorMessage(dispatch, getState, error)
      dispatch(attContacts.actions.get('me'))
    }
  )
}

attContacts.actions.delete = (contactId, otherAttendeeId) =>
  (dispatch, getState) => {
    const contactEventId = getState().attendee.attContacts.data.find(c => c.id === contactId).event_id
    dispatch(attContacts.actions.deleteStart(contactId))
    return serviceDeleteContact(
      contactEventId,
      otherAttendeeId,
      () => {
        dispatch(attContacts.actions.deleteSuccess(contactId, i18n.getFixedT(i18n.language, 'attendee_customModule')('contacts.delete.success', { contactId: contactId })))
        dispatch({ type: 'DELETED_CONTACT', data: { id: contactId } })
      },
      error => {
        dispatch(attContacts.actions.deleteError(error.description))
        handleErrorMessage(dispatch, getState, error)
        dispatch(attContacts.actions.get('me'))
      }
    )
  }

attContacts.reducers['UPDATE_CONTACT_NOTES'] = (state, action) => ({
  ...state,
  data: updateContact(state.data, action.payload.id, { notes: action.payload.notes }),
})

attContacts.reducers['UPDATE_CONTACT_RATINGS'] = (state, action) => ({
  ...state,
  data: updateContact(state.data, action.payload.id, { ratings: action.payload.ratings }),
})

attContacts.reducers['DELETED_CONTACT'] = (state, action) => ({
  ...state,
  data: state.data.filter((contact) => contact.account.id !== action.data.id),
})

attContacts.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isLoaded: false,
  isError: false,
  didInvalidate: true,
  data: [],
})

attContacts.actions.getContacts = attContacts.actions.get
attContacts.actions.updateContact = attContacts.actions.update
attContacts.actions.deleteContact = attContacts.actions.delete
export default attContacts
