import React, { Component } from 'react'


export default class Checkbox extends Component {
  _getClassName() {
    let className = 'klik-checkbox'
    className += this.props.className ? this.props.className : ''
    className += this.props.rounded ? ' rounded-checkbox' : ''
    return className
  }

  onChange = (e) => {
    this.props.onChange(e.target.checked)
  }

  render() {
    const { checked, disabled } = this.props
    const size = `${this.props.size}px`
    const iconSize = (this.props.iconSize || (this.props.size * 0.66)) + 'px'

    return (
      <div className={this._getClassName()} data-checked={checked}>
        <input type="checkbox" checked={checked} onClick={this.onChange} disabled={disabled} />

        <div className="state" style={{ width: size, height: size }}>
          <i className="fa fa-check" style={{ fontSize: iconSize }} />
        </div>
      </div>
    )
  }
}

Checkbox.defaultProps = {
  className: '',
  size: 16,
  iconSize: 12,
  checked: false,
  onChange: () => {},
  rounded: false,
}
