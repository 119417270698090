import axios from 'axios'
import { apiErrorHandler, apiCatchError, buildError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { getAxiosConfig } from './helper'

function translateAchievement(achievement) {
  return {
    ...achievement,
    name: getTranslatedObject(achievement.name),
    description: getTranslatedObject(achievement.description),
  }
}

export function serviceGetGame(eventId, gameId) {
  return axios.get(`/events/${eventId}/games/${gameId}`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceUpdateGame(eventId, gameId, game) {
  return axios.put(`/events/${eventId}/games/${gameId}`, JSON.stringify(game), getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetAchievements(eventId, gameId, success, error) {
  return axios.get(`/events/${eventId}/games/${gameId}/achievements`, getAxiosConfig())
    .then((response) => Promise.resolve(response.data.map(d => translateAchievement(d))))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetAchievement(eventId, gameId, achievementId, success, error) {
  return axios.get(`/events/${eventId}/games/${gameId}/achievements/${achievementId}`, getAxiosConfig())
    .then((response) => Promise.resolve(translateAchievement(response.data)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateAchievement(eventId, gameId, newAchievement, success, error) {
  return axios.put(`/events/${eventId}/games/${gameId}/achievements/${newAchievement.id}`, JSON.stringify(newAchievement), getAxiosConfig())
    .then((response) => Promise.resolve(translateAchievement(response.data)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceCreateAchievement(eventId, gameId, newAchievement, success, error) {
  return axios.post(`/events/${eventId}/games/${gameId}/achievements`, JSON.stringify(newAchievement), getAxiosConfig())
    .then((response) => Promise.resolve(translateAchievement(response.data)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeleteAchievement(eventId, gameId, achievement, success, error) {
  return axios.delete(`/events/${eventId}/games/${gameId}/achievements/${achievement.id}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
