import React, { Component } from 'react'
import { isBrowserSupported } from 'common/utils/domUtils'

import ExternalLink from 'common/components/ExternalLink'

import { styles } from './styles.scss'


export default class UnsupportedBrowser extends Component {
  state = {
    isDisplayed: !isBrowserSupported(),
  }

  dismiss = () => this.setState({ isDisplayed: false })

  render = () => {
    const { isDisplayed } = this.state

    return isDisplayed ? (
      <div className={styles}>
        <center>
          You are using a browser version that is no longer supported. Please upgrade to a&nbsp;
          <ExternalLink className="action" target="_blank" href="https://browser-update.org/update.html">supported browser</ExternalLink>.
          &nbsp;&nbsp;
          <span className="action" onClick={this.dismiss}>Dismiss</span>
        </center>
      </div>
    ) : null
  }
}
