import { serviceGetTopics, serviceGetTopic, serviceGetAttendeeTopics, servicePostTopic, servicePutTopic, serviceDeleteTopic, serviceCreateTopicMeeting, serviceAddTopicMeetingParticipant, serviceRemoveTopicMeetingParticipant, serviecGetTopicMeeting, serviceUpdateTopicMeeting, serviceGetTopicMeetingParticipants } from 'attendee/services/topics'
import generateModule from 'common/utils/generateModule'
import { listToMapping } from 'common/utils/componentHelper'
import { handleErrorMessage } from './index'
import { createSelector } from 'reselect'
import { eventIdSelector } from './events'
import { querySelector } from './FilterSettings/topics'
import { accountIdSelector } from 'attendee/modules/profile'


export const MAX_TOPICS_PER_ATTENDEE = 3

const selectState = (state) => state.attendee.topics
const selectTopics = (state) => selectState(state).data
const selectOwnTopics = (state) => selectState(state).ownTopics
const selectAttendeeTopics = (state) => selectState(state).attendeeTopics
const selectConversationTopic = (state) => selectState(state).conversationTopic
const selectTopicMeeting = (state) => selectState(state).meeting

export const topicsSelector = createSelector(selectTopics, (topics) => topics)
export const topicsByIdSelector = createSelector(selectTopics, (topics) => listToMapping(topics, 'id'))
export const ownTopicsSelector = createSelector(selectOwnTopics, (topics) => topics)
export const ownTopicsByIdSelector = createSelector(selectOwnTopics, (topics) => listToMapping(topics, 'id'))
export const attendeeTopicsSelector = createSelector(selectAttendeeTopics, (topics) => topics)
export const conversationTopicSelector = createSelector(selectConversationTopic, (topic) => topic)
export const topicMeetingSelector = createSelector(selectTopicMeeting, meeting => meeting)

const topicsModule = generateModule({
  itemName: 'topic',
  itemNameDisplay: 'topic',
  autoRefresh: 60,
  optimisticUpdate: false,
  services: {
    get: serviceGetTopics,
    add: servicePostTopic,
    upd: servicePutTopic,
    del: serviceDeleteTopic,
  },
  app: 'attendee',
})

topicsModule.initialState.currentAttendeeId = null
topicsModule.initialState.attendeeTopics = []
topicsModule.initialState.conversationTopic = null
topicsModule.initialState.ownTopics = []


topicsModule.actions.get = (before) => (dispatch, getState) => {
  const query = querySelector(getState())
  if (before) query.before = before

  dispatch(topicsModule.actions.getStart())
  return serviceGetTopics(
    eventIdSelector(getState()),
    query,
    accountIdSelector(getState()),
    (response) => dispatch({ type: 'GET_TOPICS_SUCCESS', payload: { topics: response, shouldReplace: !before } }),
    (error) => dispatch(topicsModule.actions.getError(error))
  )
}

topicsModule.reducers['GET_TOPICS_SUCCESS'] = (state, action) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  data: action.payload.shouldReplace ? action.payload.topics : state.data,
})


topicsModule.actions.getOwnTopics = () => (dispatch, getState) => {
  dispatch({ type: 'GET_OWN_TOPICS_START' })
  return serviceGetAttendeeTopics(
    eventIdSelector(getState()),
    'me',
    (response) => dispatch({ type: 'GET_OWN_TOPICS_SUCCESS', payload: response }),
    () => dispatch({ type: 'GET_OWN_TOPICS_ERROR' })
  )
}

topicsModule.reducers['GET_OWN_TOPICS_SUCCESS'] = (state, action) => ({
  ...state,
  ownTopics: action.payload,
})


topicsModule.actions.getAttendeeTopics = (accountId) => (dispatch, getState) => {
  dispatch({ type: 'GET_ATTENDEE_TOPICS_START', payload: { accountId: accountId } })
  return serviceGetAttendeeTopics(
    eventIdSelector(getState()),
    accountId,
    (response) => {
      const mainTopic = topicsByIdSelector(getState()) // Topic that has the "account" in it
      const attendeeTopics = response.map(r => ({ ...r, account: { ...mainTopic.account } }))
      dispatch({ type: 'GET_ATTENDEE_TOPICS_SUCCESS', payload: { topics: attendeeTopics, accountId: accountId } })
    },
    () => dispatch({ type: 'GET_ATTENDEE_TOPICS_ERROR' })
  )
}

topicsModule.reducers['GET_ATTENDEE_TOPICS_START'] = (state, action) => ({
  ...state,
  attendeeTopics: action.payload.accountId !== state.currentAttendeeId
    ? topicsModule.initialState.attendeeTopics
    : state.attendeeTopics,
  currentAttendeeId: action.payload.accountId,
})

topicsModule.reducers['GET_ATTENDEE_TOPICS_SUCCESS'] = (state, action) => ({
  ...state,
  attendeeTopics: action.payload.topics,
})


topicsModule.reducers['GET_ATTENDEE_TOPICS_START'] = (state, action) => ({
  ...state,
  attendeeTopics: action.payload.accountId !== state.currentAttendeeId
    ? topicsModule.initialState.attendeeTopics
    : state.attendeeTopics,
  currentAttendeeId: action.payload.accountId,
})

topicsModule.reducers['GET_ATTENDEE_TOPICS_SUCCESS'] = (state, action) => ({
  ...state,
  attendeeTopics: action.payload.topics,
})


topicsModule.actions.clearAttendeeTopics = () => (dispatch) => {
  dispatch({ type: 'CLEAR_ATTENDEE_TOPICS' })
  return Promise.resolve()
}

topicsModule.reducers['CLEAR_ATTENDEE_TOPICS'] = (state) => ({
  ...state,
  attendeeTopics: topicsModule.initialState.attendeeTopics,
  currentAttendeeId: topicsModule.initialState.currentAttendeeId,
})


topicsModule.actions.getConversationTopic = (topicId) => (dispatch, getState) => {
  if (!topicId) {
    dispatch({ type: 'CLEAR_CONVERSATION_TOPIC' })
    return Promise.resolve()
  }

  dispatch({ type: 'GET_CONVERSATION_TOPIC_START', payload: topicId })
  return serviceGetTopic(
    eventIdSelector(getState()),
    topicId,
    (response) => dispatch({ type: 'GET_CONVERSATION_TOPIC_SUCCESS', payload: response.hidden_by_admin ? null : response }),
    () => dispatch({ type: 'GET_CONVERSATION_TOPIC_ERROR', payload: topicId })
  )
}

topicsModule.reducers['CLEAR_CONVERSATION_TOPIC'] = (state) => ({
  ...state,
  conversationTopic: topicsModule.initialState.conversationTopic,
})

topicsModule.reducers['GET_CONVERSATION_TOPIC_START'] = (state, action) => ({
  ...state,
  conversationTopic: (state.conversationTopic && state.conversationTopic.id === action.payload ? state.conversationTopic : topicsModule.initialState.conversationTopic),
})

topicsModule.reducers['GET_CONVERSATION_TOPIC_SUCCESS'] = (state, action) => ({
  ...state,
  conversationTopic: action.payload,
})

topicsModule.reducers['GET_CONVERSATION_TOPIC_ERROR'] = (state) => ({
  ...state,
  conversationTopic: topicsModule.initialState.conversationTopic,
})


topicsModule.actions.add = (data, meetingData) => (dispatch, getState) => {
  dispatch(topicsModule.actions.addStart(data))
  return servicePostTopic(
    eventIdSelector(getState()),
    data,
    meetingData,
    (response) => dispatch(topicsModule.actions.addSuccess(response)),
    (error) => {
      dispatch(topicsModule.actions.addError(error.description))
      handleErrorMessage(dispatch, getState, error)
    }
  )
}

// We do not want to execute the default reducer
topicsModule.reducers['ADD_TOPIC_START'] = (state) => ({ ...state })

topicsModule.reducers['ADD_TOPIC_SUCCESS'] = (state, action) => ({
  ...state,
  data: [action.payload.data, ...state.data],
  ownTopics: [...state.ownTopics, action.payload.data],
})


topicsModule.actions.update = (topic, meetingId) => (dispatch, getState) => {
  dispatch(topicsModule.actions.updateStart(topic))
  return servicePutTopic(
    eventIdSelector(getState()),
    topic,
    meetingId,
    (response) => dispatch(topicsModule.actions.updateSuccess(response)),
    (error) => {
      dispatch(topicsModule.actions.updateError(error.description))
      handleErrorMessage(dispatch, getState, error)
    }
  )
}

topicsModule.reducers['UPDATE_TOPIC_SUCCESS'] = (state, action) => ({
  ...state,
  data: state.data.map(topic => (topic.id === action.payload.data.id ? action.payload.data : topic)),
  ownTopics: state.ownTopics.map(topic => (topic.id === action.payload.data.id ? action.payload.data : topic)),
})

topicsModule.actions.delete = (topicId) => (dispatch, getState) => {
  dispatch(topicsModule.actions.deleteStart(topicId))
  return serviceDeleteTopic(
    eventIdSelector(getState()),
    topicId,
    () => dispatch(topicsModule.actions.deleteSuccess(topicId)),
    (error) => {
      dispatch(topicsModule.actions.deleteError(error.description))
      handleErrorMessage(dispatch, getState, error)
    }
  )
}

topicsModule.reducers['DELETE_TOPIC_SUCCESS'] = (state, action) => ({
  ...state,
  data: state.data.filter(topic => topic.id !== action.payload.id),
  ownTopics: state.ownTopics.filter(topic => topic.id !== action.payload.id),
})


topicsModule.actions.clearAllTopics = () => (dispatch) => {
  dispatch({ type: 'TOPICS_CLEAR_ALL' })
  return Promise.resolve()
}

topicsModule.reducers['TOPICS_CLEAR_ALL'] = (state) => ({
  ...state,
  data: [],
})

topicsModule.actions.createTopicMeeting = (payload) =>
  (dispatch, getState) => {
    dispatch({ type: 'CREATE_TOPIC_MEETING_START' })
    return serviceCreateTopicMeeting(
      eventIdSelector(getState()),
      payload,
      (response) => dispatch({ type: 'CREATE_TOPIC_MEETING_SUCCESS', payload: { meeting: response } }),
      (error) => handleErrorMessage(dispatch, getState, error)
    )
  }

topicsModule.actions.addTopicMeetingParticipant = (meetingId, participantId) =>
  (dispatch, getState) => {
    dispatch({ type: 'ADD_TOPIC_MEETING_PARTICIPANT_START' })
    return serviceAddTopicMeetingParticipant(
      eventIdSelector(getState()),
      meetingId,
      participantId,
      (response) => dispatch({ type: 'ADD_TOPIC_MEETING_PARTICIPANT_SUCCESS', payload: response }),
      (error) => handleErrorMessage(dispatch, getState, error)
    )
  }

topicsModule.reducers['ADD_TOPIC_MEETING_PARTICIPANT_SUCCESS'] = (state, action) => ({
  ...state,
  participants: action.payload.participants,
})

topicsModule.actions.removeTopicMeetingParticipant = (meetingId, participantId) =>
  (dispatch, getState) => {
    dispatch({ type: 'REMOVE_TOPIC_MEETING_PARTICIPANT_START' })
    return serviceRemoveTopicMeetingParticipant(
      eventIdSelector(getState()),
      meetingId,
      participantId,
      (response) => dispatch({ type: 'REMOVE_TOPIC_MEETING_PARTICIPANT_SUCCESS', payload: response }),
      (error) => handleErrorMessage(dispatch, getState, error)
    )
  }


topicsModule.actions.getTopicMeeting = (meetingId) =>
  (dispatch, getState) => {
    dispatch({ type: 'GET_TOPIC_MEETING_START' })
    return serviecGetTopicMeeting(
      eventIdSelector(getState()),
      meetingId,
      (response) => dispatch({ type: 'GET_TOPIC_MEETING_SUCCESS', payload: response }),
      (error) => handleErrorMessage(dispatch, getState, error)
    )
  }

topicsModule.reducers['GET_TOPIC_MEETING_SUCCESS'] = (state, action) => ({
  ...state,
  meeting: action.payload.data,
})


topicsModule.actions.updateTopicMeeting = (meetingId, meetingTimes) =>
  (dispatch, getState) => {
    dispatch({ type: 'UPDATE_TOPIC_MEETING_START' })
    return serviceUpdateTopicMeeting(
      eventIdSelector(getState()),
      accountIdSelector(getState()),
      meetingId,
      meetingTimes,
      (response) => dispatch({ type: 'UPDATE_TOPIC_MEETING_SUCCESS', payload: response }),
      (error) => handleErrorMessage(dispatch, getState, error)
    )
  }

topicsModule.actions.getTopicMeetingParticipants = (meetingId) =>
  (dispatch, getState) => {
    dispatch({ type: 'GET_TOPIC_MEETING_PARTICIPANTS_START' })
    return serviceGetTopicMeetingParticipants(
      eventIdSelector(getState()),
      meetingId,
      (response) => dispatch({ type: 'GET_TOPIC_MEETING_PARTICIPANTS_SUCCESS', payload: response }),
      (error) => handleErrorMessage(dispatch, getState, error)
    )
  }


topicsModule.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isLoaded: false,
  isError: false,
  didInvalidate: true,
  data: [],
  ownTopics: [],
  meeting: null,
})


topicsModule.actions.getTopics = topicsModule.actions.get
topicsModule.actions.postTopic = topicsModule.actions.add
topicsModule.actions.updateTopic = topicsModule.actions.update
topicsModule.actions.deleteTopic = topicsModule.actions.delete
export default topicsModule
