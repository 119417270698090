import { generateBaseModule } from 'admin/modules/generateModule'


const initialFilters = {
  attendeeType: null,
  date: parseInt(Date.now() / 1000, 10),
  map: null,
  zone: null,
  timeSelector: {
    end: undefined,
    head: undefined,
    isLive: false,
    isPlaying: false,
    start: undefined,
  },
}

const filterIds = Object.keys(initialFilters)

const FiltersModule = generateBaseModule({
  initialState: { ...initialFilters },
  moduleKey: 'cpo/bluetrace/trackingFilters',
  reducerKey: 'admin.bluetrace.tracking.filters',
})


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

filterIds.forEach(filterId => {
  FiltersModule.registerSelector(
    filterId,
    (state, moduleState) => moduleState[filterId],
    filterValue => filterValue
  )
})

FiltersModule.registerSelector(
  'timeSelector',
  (state, moduleState) => moduleState.timeSelector,
  data => data
)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////

FiltersModule.registerActionKey('setFilter')
const setFilterAction = (filterId, value) => dispatch => {
  dispatch({
    type: FiltersModule.actionKeys.setFilter,
    payload: { id: filterId, value },
  })

  return Promise.resolve()
}

filterIds.forEach(filterId => {
  FiltersModule.registerAction(filterId, value => dispatch => dispatch(setFilterAction(filterId, value)))
})

FiltersModule.registerAction('updateTimeSelector', timeSelector => dispatch => {
  dispatch({ type: FiltersModule.actionKeys.updateTimeSelector, payload: timeSelector })
  return Promise.resolve()
})


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////

FiltersModule.registerReducer(
  FiltersModule.actionKeys.setFilter,
  (state, action) => ({
    ...state,
    [action.payload.id]: action.payload.value,
  })
)

FiltersModule.registerReducer(
  FiltersModule.actionKeys.updateTimeSelector,
  (state, action) => ({
    ...state,
    timeSelector: {
      ...state.timeSelector,
      ...action.payload,
    },
  })
)


export default FiltersModule
