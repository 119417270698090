import { isIE, isSafari, isBrowserSupported } from 'common/utils/domUtils'

if (isIE() || isSafari() || !isBrowserSupported()) {
  require('eventsource-polyfill') // eslint-disable-line global-require
  require('babel-polyfill') // eslint-disable-line global-require
}

if (Promise.prototype.finally == null) {
  require('promise.prototype.finally').shim() // eslint-disable-line global-require
}

if (window.URLSearchParams == null) {
  require('url-search-params-polyfill') // eslint-disable-line global-require
}

if (Array.prototype.flat == null) {
  require('array-flat-polyfill') // eslint-disable-line global-require
}
