import { generateDataModule } from 'admin/modules/generateModule'
import { addSortingSupport, addPaginationSupport } from './helper'
import { serviceGetCommunicationsData } from 'admin/services/dashboard'
import sort from 'common/utils/sortHelper'

const CommunicationsModule = generateDataModule({
  initialData: [],
  initialState: {
    filters: {
      recipientType: '',
      channel: '',
    },
    queryParams: {
      sort_column: 'name',
      sort_order: 1,
      per_page: 2,
      page: undefined,
    },
    pagination: {
      page: 1,
      pageCount: 1,
    },

  },
  moduleKey: 'dashboardCommunications',
  reducerKey: 'admin.dashboard.communications',
  services: {
    get: { service: serviceGetCommunicationsData },
  },

})


addSortingSupport(CommunicationsModule, { id: 'name', order: 1 })
addPaginationSupport(CommunicationsModule)


CommunicationsModule.registerSelector('data', (state, moduleState) => moduleState.data, communications => communications)
CommunicationsModule.registerSelector('minimalData', (state, moduleState) => moduleState.data, communications => communications.slice(0, 4))


CommunicationsModule.registerSelector(
  'fullData',
  [
    state => CommunicationsModule.selectors.data(state),
    state => CommunicationsModule.selectors.sorter(state),
    state => CommunicationsModule.selectors.filters(state),
  ],
  (communications, sorter, filters) => sort(
    communications.filter(communication => {
      if ((filters.recipientType && filters.recipientType !== communication.recipient_type)
        || (filters.channel && !communication.channels.some(channel => filters.channel === channel))) {
        return false
      }
      return true
    }),
    [
      { id: sorter.id, order: sorter.order },
      { id: 'sent', order: -1 },
      { id: 'open_percentage', order: -1 },
    ]
  )
)

CommunicationsModule.registerSelector(
  'filters',
  (state, moduleState) => moduleState.filters,
  filters => filters,
)

CommunicationsModule.registerSelector(
  'availableChannels',
  state => CommunicationsModule.selectors.data(state),
  communications => {
    const channels = {}
    communications.forEach(communication => {
      communication.channels.forEach(channel => {
        if (!channels[channel]) {
          channels[channel] = true
        }
      })
    })
    return Object.keys(channels)
  }
)

CommunicationsModule.registerSelector(
  'availableRecipientTypes',
  state => CommunicationsModule.selectors.data(state),
  communications => {
    const recipientTypes = {}
    communications.forEach(communication => {
      if (!recipientTypes[communication.recipient_type]) {
        recipientTypes[communication.recipient_type] = true
      }
    })
    return Object.keys(recipientTypes)
  }
)

CommunicationsModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {
    const queryParams = CommunicationsModule.selectors.queryParams(getState())
    const getCommunications = () => CommunicationsModule._getService('get')(queryParams)
    dispatch({ type: CommunicationsModule.actionKeys.getStart })

    return getCommunications().then(response => {
      dispatch({
        type: CommunicationsModule.actionKeys.getSuccess,
        payload: response,
      })
    })
      .catch(() => {
        dispatch({ type: CommunicationsModule.actionKeys.getError })
      })
  }
)

CommunicationsModule.registerReducer(CommunicationsModule.actionKeys.getSuccess, (state, action) => ({
  ...state,
  data: action.payload.data,
  pagination: {
    page: action.payload.page,
    pageCount: action.payload.pageCount,
  },
  isLoading: false,
  isLoaded: true,
}))


CommunicationsModule.registerAction(
  'setFilters',
  filters => dispatch => {
    dispatch({ type: CommunicationsModule.actionKeys.setFilters, payload: filters })
    return Promise.resolve()
  }
)

CommunicationsModule.registerReducer(
  CommunicationsModule.actionKeys.setFilters,
  (state, action) => ({ ...state, filters: { ...state.filters, ...action.payload } })
)


CommunicationsModule.registerAction(
  'resetFilters',
  () => dispatch => {
    dispatch({ type: CommunicationsModule.actionKeys.resetFilters })
    return Promise.resolve()
  }
)

CommunicationsModule.registerReducer(
  CommunicationsModule.actionKeys.resetFilters,
  state => ({ ...state, filters: CommunicationsModule.initialState.filters })
)

export default CommunicationsModule

