import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { createRootReducer } from 'common/modules'
import { routerMiddleware } from 'connected-react-router'

function applyDevMiddlewares(history) {
  const logger = createLogger({
    collapsed: true,
    predicate: () => ['development', 'test'].includes(process.env.NODE_ENV),
  })

  const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f

  return compose(applyMiddleware(routerMiddleware(history), thunkMiddleware, logger), devTools)
}

function applyProductionMiddlewares(history) {
  return applyMiddleware(routerMiddleware(history), thunkMiddleware)
}

export default function configureStore(initialState, history) {
  const store = createStore(
    createRootReducer(history),
    initialState,
    (process.env.NODE_ENV === 'development') ? applyDevMiddlewares(history) : applyProductionMiddlewares(history)
  )

  // if (module.hot) {
  //   // Enable Webpack hot module replacement for reducers
  //   module.hot.accept(['./common/modules', './admin/modules', './attendee/modules'], () => { store.replaceReducer(rootReducer) })
  // }

  return store
}
