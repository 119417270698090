import { createSelector } from 'reselect'
import generateFilterModule from './generateFilterModule'
import { eventCustomFieldsByNameSelector } from '../events'
import { tagsByIdSelector } from '../tags'
import sort from 'common/utils/sortHelper'


const getCustomFieldsLength = customFields => Object.values(customFields).filter(value => value).length

const selectFilters = state => state.attendee.filterSettings.recommendations.data
export const filtersSelector = createSelector(selectFilters, filters => filters)

export const areAdvancedFiltersEnabledSelector = createSelector(
  selectFilters,
  filters => filters.tags.length > 0 || getCustomFieldsLength(filters.customFields) > 0
)

export const advancedFiltersCountSelector = createSelector(
  selectFilters,
  filters => filters.tags.length + getCustomFieldsLength(filters.customFields)
)

export const querySelector = createSelector(
  selectFilters,
  filters => {
    const query = {}

    if (filters.query) query.query = filters.query
    if (filters.tags.length > 0) query.tags = [...filters.tags]

    const customFieldKeys = Object.keys(filters.customFields || {})
    if (customFieldKeys.length > 0) {
      query.customFields = {}
    }

    customFieldKeys.forEach(key => {
      const value = filters.customFields[key]
      if (value) {
        query.customFields = {
          ...query.customFields,
          [key]: value,
        }
      }
    })

    return query
  }
)

export const filterBadgesSelector = createSelector(
  selectFilters,
  eventCustomFieldsByNameSelector,
  tagsByIdSelector,
  (filters, customFieldsByName, tagsById) => {
    const filterBadges = []

    filters.tags.forEach(tagId => {
      const tag = tagsById[tagId]

      if (tag) {
        filterBadges.push({
          filterKey: tagId,
          filterType: 'tags',
          id: `tags~${tagId}`,
          name: tag.name || tag.id,
          value: tagId,
        })
      }
    })

    Object.keys(filters.customFields).forEach(key => {
      const customField = customFieldsByName[key]

      if (customField) {
        const customFieldValue = filters.customFields[key]
        const option = customField.options.find(_option => _option.value === customFieldValue)

        if (option) {
          filterBadges.push({
            filterKey: key,
            filterType: 'customFields',
            id: `cf~${customField.id}~${customFieldValue}`,
            name: option.title || option.value,
            value: customFieldValue,
          })
        }
      }
    })

    return sort(filterBadges, ['title', 'name'])
  }
)

export default generateFilterModule('recommendations')
