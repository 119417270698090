import { serviceGetRegistrationStats } from 'admin/services/analytics'
import generateModule from 'common/utils/generateModule'


const { actions, reducers, initialState } = generateModule({
  itemName: 'profileCompletion',
  autoRefresh: 3,
  getModuleState: (getState) => getState().admin.analytics.onlineEngagement.profileCompletion,
  services: {
    get: serviceGetRegistrationStats,
  },
})


function getFormattedStats(stats) {
  const values = [
    { status: 'Registered', count: 0 },
    { status: 'Visited website', count: 0 },
    { status: 'Completed Registration', count: 0 },
  ]

  stats.groups.forEach(group => {
    let groupPosition = values.map((v => v.status)).indexOf(group.registration_status)
    if (groupPosition === -1) {
      groupPosition = 0
    }

    for (let i = groupPosition; i >= 0; i--) {
      values[i].count += group.count
    }
  })

  return values
}


actions.get = (attendeeType) =>
  (dispatch, getState) => {
    dispatch(actions.getStart())
    return serviceGetRegistrationStats(
      getState().admin.events.currentEvent.id,
      attendeeType,
      (response) => dispatch(actions.getSuccess(getFormattedStats(response))),
      () => dispatch(actions.getError()),
    )
  }


reducers['CHANGE_EVENT_SUCCESS'] = (state) => ({
  ...state,
  didInvalidate: true,
  data: initialState.data,
})


export const getProfileCompletion = actions.get
export const getProfileCompletionIfNeeded = actions.getIfNeeded

const profileCompletion = reducers.index
export default profileCompletion
