import { getProperty } from 'common/utils/componentHelper'
import { getTranslatedValue } from 'common/utils/translator'
import { store } from '../../../index'

import * as LocationsAC from 'admin/modules/locations'


const getLocationsById = () => LocationsAC.locationsByIdSelector(store.getState())

export default {
  getLocationTitle: (location, locationsById) => {
    let _location = location

    if (typeof _location === 'string') {
      const _locationsById = locationsById || getLocationsById()
      _location = _locationsById[_location] || _location
    }
    return getTranslatedValue(getProperty(_location, 'display_name')) || _location
  },
}

