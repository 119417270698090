import { BaseModule, DataModule } from 'redux-data-module'


const generateModule = (ModuleClass, options) => {
  const module = new ModuleClass({
    ...options,
    app: 'exhibitor',
    reducerKey: `exhibitor.${options.reducerKey || options.moduleKey}`,
    services: options.services,
  })

  return module
}

export const generateBaseModule = (options) => generateModule(BaseModule, options)

export const generateDataModule = (options) => generateModule(DataModule, options)
