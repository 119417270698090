import { serviceGetZoneStats, serviceGetZoneStatsOvertime, serviceGetDeviceStats, serviceGetZonesStatsOvertime } from 'admin/services/locator'
import { showToast } from 'common/modules/toaster'
import { getProperty } from 'common/utils/componentHelper'
import i18n from 'i18next'
import { createSelector } from 'reselect'

const selectState = (state) => state.admin.zoneStats
const selectZoneStats = (state) => getProperty(selectState(state).stats, 'data') || []
const selectDeviceStats = (state) => getProperty(selectState(state).deviceStats, 'data') || []

export const stateSelector = createSelector(selectState, (state) => state)
export const zoneStatsSelector = createSelector(selectZoneStats, (stats) => stats)
export const deviceStatsSelector = createSelector(selectDeviceStats, (stats) => stats)


function getZoneStatsSuccess(data, groupedby) {
  return {
    type: 'GET_ZONE_STATS_SUCCESS',
    data: data,
    groupedby: groupedby,
  }
}

function getZoneStatsStart(groupedby) {
  return {
    type: 'GET_ZONE_STATS_START',
    groupedby: groupedby,
    status: i18n.getFixedT(i18n.language, 'admin_customModule')('zone_stats.loading_tracking_data'),
  }
}

export function getZoneStats(event, groupby) {
  return (dispatch) => {
    dispatch(getZoneStatsStart(groupby))
    serviceGetZoneStats(
      event,
      groupby,
      data => dispatch(getZoneStatsSuccess(data, groupby)),
      error => {
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('zone_stats.loading_tracking_data'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}

function getZoneStatsOvertimeSuccess(data, groupedby, zone, start, end) {
  return {
    type: 'GET_ZONE_STATS_OVERTIME_SUCCESS',
    data: data,
    groupedby: groupedby,
    zone: zone,
    start: start,
    end: end,
  }
}

function getZoneStatsOvertimeStart(groupedby, zone, start, end) {
  return {
    type: 'GET_ZONE_STATS_OVERTIME_START',
    groupedby: groupedby,
    zone: zone,
    start: start,
    end: end,
    status: i18n.getFixedT(i18n.language, 'admin_customModule')('zone_stats.loading_tracking_data'),
  }
}

export function getZoneStatsOvertime(event, groupby, zone, start, end) {
  return (dispatch) => {
    dispatch(getZoneStatsOvertimeStart(groupby, zone, start, end))
    serviceGetZoneStatsOvertime(
      event, zone.name, groupby, start, end,
      data => dispatch(getZoneStatsOvertimeSuccess(data, groupby, zone, start, end)),
      error => dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('zone_stats.loading_tracking_data'),
        message: error.description,
        name: 'get_zone_stats_overtime_error',
        level: 'error',
      }))
    )
  }
}

export function getZonesStatsOvertime() {
  return (dispatch, getState) => {
    dispatch({ type: 'GET_ZONES_STATS_OVERTIME_START' })
    return serviceGetZonesStatsOvertime(
      getState().admin.events.currentEvent.id,
      data => dispatch({
        type: 'GET_ZONES_STATS_OVERTIME_SUCCESS',
        payload: { zones: new Map(data.map((x) => [x.zone.name, x.timeseries])) },
      }),
      () => dispatch({ type: 'GET_ZONES_STATS_OVERTIME_ERROR' })
    )
  }
}

function getZoneDeviceStatsSuccess(data) {
  return {
    type: 'GET_ZONE_DEVICE_STATS_SUCCESS',
    data: data,
  }
}

function getZoneDeviceStatsStart() {
  return {
    type: 'GET_ZONE_DEVICE_STATS_START',
    status: i18n.getFixedT(i18n.language, 'admin_customModule')('zone_stats.loading_tracking_data'),
  }
}

export function getZoneDeviceStats(event) {
  return (dispatch) => {
    dispatch(getZoneDeviceStatsStart())
    serviceGetDeviceStats(
      event,
      data => dispatch(getZoneDeviceStatsSuccess(data)),
      error => dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('zone_stats.loading_tracking_data'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
    )
  }
}


const initialState = {
  isFetching: false,
  isLoaded: false,
  lastOperationStatus: '',
  isError: false,
  stats: {
    data: undefined,
    groupedby: undefined,
  },
  deviceStats: {
    data: undefined,
  },
}

export default function zoneStats(state = initialState, action) {
  switch (action.type) {
    case 'GET_ZONE_STATS_START':
      return {
        ...state,
        isError: false,
        isFetching: true,
        lastOperationStatus: action.status,
      }

    case 'GET_ZONE_STATS_SUCCESS':
      return {
        ...state,
        isLoaded: true,
        isError: false,
        isFetching: false,
        stats: {
          ...state.stats,
          data: action.data,
          groupedby: action.groupedby,
        },
      }

    case 'GET_ZONE_DEVICE_STATS_START':
      return {
        ...state,
        isError: false,
        isFetching: true,
        lastOperationStatus: action.status,
      }

    case 'GET_ZONE_DEVICE_STATS_SUCCESS':
      return {
        ...state,
        isLoaded: true,
        isError: false,
        isFetching: false,
        deviceStats: {
          ...state.deviceStats,
          data: action.data,
        },
      }

    default:
      return state
  }
}
