import axios from 'axios'
import { apiErrorHandler } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { getAxiosConfig } from './helper'


function translateAttendeeType(attendeeType) {
  const _attendeeType = { ...attendeeType }

  if (_attendeeType.title) {
    _attendeeType.title = getTranslatedObject(_attendeeType.title)
  }

  return _attendeeType
}

export function serviceGetAttendeeTypes(eventId) {
  return new Promise((resolve, reject) => {
    axios.get(`/events/${eventId}/attendees/types`, getAxiosConfig())
      .then(response => resolve(response.data.map(data => translateAttendeeType(data))))
      .catch(() => apiErrorHandler(error => reject(error), 'backend'))
  })
}

export function serviceCreateAttendeeType(eventId, attendeeType) {
  return new Promise((resolve, reject) => {
    axios.post(`/events/${eventId}/attendees/types`, attendeeType, getAxiosConfig())
      .then(response => resolve(translateAttendeeType(response.data)))
      .catch(() => apiErrorHandler(error => reject(error), 'backend'))
  })
}

export function serviceUpdateAttendeeType(eventId, attendeeType) {
  return new Promise((resolve, reject) => {
    axios.put(`/events/${eventId}/attendees/types/${attendeeType.id}`, attendeeType, getAxiosConfig())
      .then(response => resolve(translateAttendeeType(response.data)))
      .catch(() => apiErrorHandler(error => reject(error), 'backend'))
  })
}

export function serviceDeleteAttendeeType(eventId, attendeeTypeId) {
  return new Promise((resolve, reject) => {
    axios.delete(`/events/${eventId}/attendees/types/${attendeeTypeId}`, getAxiosConfig())
      .then(response => resolve(response.data))
      .catch(() => apiErrorHandler(error => reject(error), 'backend'))
  })
}
