import i18n from 'i18next'
import XHR from 'i18next-xhr-backend'
import { envName } from 'config'
import { getLanguage } from 'common/utils/accessToken'

const resBundle = require('i18next-resource-store-loader!../static/locales/index.js') // eslint-disable-line import/no-webpack-loader-syntax

const shouldSkipLoadingResources = window.location.search.indexOf('_notranslation=true') !== -1

const initOptions = {
  lng: getLanguage() || 'en',
  // fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  resources: shouldSkipLoadingResources ? {} : resBundle,
}

if (envName === 'demo' || envName === 'development' || envName === 'test' || envName === 'staging') {
  initOptions.saveMissing = true
  initOptions.appendNamespaceToMissingKey = true
}

i18n
  .use(XHR)
  .init(initOptions)

export default i18n
