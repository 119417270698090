import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { createPageTitle } from 'common/utils/componentHelper'
import handlePageLoader from 'common/utils/decorators/PageLoader'

import { appSelector } from 'common/modules/auth'

import { Redirect } from 'react-router-dom'
import Button from 'common/components/Button'
import ButtonContainer from 'ControlPanel/Components/ButtonContainer'
import DocumentMeta from 'react-document-meta'

import { styles } from './styles.scss'


@translate(['login'])
@connect(
  state => ({
    app: appSelector(state),
  })
)
@handlePageLoader()
export default class NotFound extends Component {
  t = (key, extra) => this.props.t(`not_found_page.${key}`, extra)

  getHomeLink = () => {
    switch (this.props.app) {
      case 'admin': return '/admin'
      case 'attendee': return '/events'
      default: return '/'
    }
  }

  render() {
    const t = this.t

    return (
      <div className={styles}>
        <DocumentMeta {...createPageTitle(t('page_title'))} />

        <h1>{t('title')}</h1>
        <p>{t('description.0')}</p>
        <p>{t('description.1')}</p>

        <ButtonContainer alignment="center">
          <Button link={Button.InternalLink(this.getHomeLink())}>
            <span>{t('back_button')}</span>
          </Button>
        </ButtonContainer>
      </div>
    )
  }
}


export const NotFoundRedirect = ({ location }) => <Redirect to={{ ...location, state: { is404: true } }} />
