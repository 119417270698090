import { createSelector } from 'reselect'

export const selectState = (state) => state.admin.app

export const eventSearchTermSelector = createSelector(selectState, (state) => state.eventSearchTerm)


export function setEventSearchTerm(value) {
  return (dispatch) => dispatch({ type: 'SET_EVENT_SEARCH_TERM', payload: value })
}

export function selectGamificationTab(tab) {
  return dispatch => dispatch({ type: 'SELECT_GAMIFICATION_TAB', data: { tab } })
}

export function selectSectionTab(sectionId, tab) {
  return dispatch => dispatch({ type: 'SELECT_SECTION_TAB', payload: { sectionId, tab } })
}

/**
 * Change the visibility of the account merge modal. We don't reset account values when hiding because we don't want any weird
 * visual glitches during the "hide" transition, due to account becoming null while transitioning.
 *
 * @param {Object} options
 * @param {Object} options.isVisible - Should the modal be visible
 * @param {Object} options.account - Account to merge
 * @param {boolean} options.isAttendeeUpdate - When trying to update an admin account (user management), this flag will be false
 * @param {boolean} options.canMerge - Either the user can merge the account or not
 * @param {string} options.errorMessage - Error message to display when canMerge is false
 * @param {function} options.resolver - Function to call when merging is successful
 * @param {function} options.rejecter - Function to call when merging is not succesful
 */
export function setAccountMergeModal(options) {
  return (dispatch, getState) => {
    const previousState = getState().admin.app.accountMergeModal

    const { isVisible, ...otherOptions } = options

    // When the modal becomes hidden, we want to keep the other values as they were so the modal
    // does not change as the close transition is playing
    Object.keys(otherOptions).forEach(key => { otherOptions[key] = isVisible ? otherOptions[key] : previousState[key] })

    dispatch({ type: 'SET_ACCOUNT_MERGE_MODAL', payload: { isVisible, ...otherOptions } })
  }
}

export function startExportProgress(url) {
  return (dispatch) => {
    dispatch({ type: 'START_EXPORT_PROGRESS' })
    const eventSource = new EventSource(url)

    eventSource.addEventListener('message', (e) => {
      dispatch({ type: 'UPDATE_EXPORT_PROGRESS', state: JSON.parse(e.data), eventSource: eventSource })
    })
  }
}

export function finishExportProgress() {
  return dispatch => dispatch({ type: 'END_EXPORT_PROGRESS' })
}

const initialState = {
  dashboardLastTab: undefined,
  analyticsLastTab: undefined,
  checkInLastTab: undefined,
  communicationLastTab: undefined,
  eventSetupLastTab: undefined,
  exhibitorsLastTab: 'types',
  floorPlanLastTab: undefined,
  gamificationLastTab: undefined,
  networkingLastTab: undefined,

  eventSearchTerm: '',

  accountMergeModal: {
    isVisible: false,
    account: null,
    isAttendeeUpdate: false,
  },

  exportProgress: {
    isVisible: false,
    progress: null,
    eventSource: null,
  },
}

export default function app(state = initialState, action) {
  switch (action.type) {
    case 'SELECT_SECTION_TAB':
      return { ...state, [`${action.payload.sectionId}LastTab`]: action.payload.tab }

    case 'CHANGE_EVENT_SUCCESS':
      return initialState

    case 'SET_EVENT_SEARCH_TERM':
      return { ...state, eventSearchTerm: action.payload }

    case 'SET_ACCOUNT_MERGE_MODAL':
      return { ...state, accountMergeModal: { ...action.payload } }

    case 'START_EXPORT_PROGRESS':
      return { ...state, exportProgress: { isVisible: true, progress: null } }

    case 'END_EXPORT_PROGRESS':
      if (state.exportProgress.eventSource) {
        state.exportProgress.eventSource.close()
      }
      return { ...state, exportProgress: { isVisible: false, progress: null } }

    case 'UPDATE_EXPORT_PROGRESS':
      if (action.state.finished && action.eventSource) {
        action.eventSource.close()
      }
      return { ...state, exportProgress: { ...state.exportProgress, progress: action.state, eventSource: action.eventSource } }

    default:
      return state
  }
}
