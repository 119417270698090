import React from 'react'
import LoadingIcon from 'common/components/LoadingIcon'

export default function LoadedComponent(props) {
  const loading = props.monitored.some(state => state.isFetching || state.isLoading)
  const error = props.monitored.some(state => state.isError)
  const loaded = props.monitored.every(state => state.isLoaded)

  function getMsgs(msgs, state, inx) {
    if (state.lastOperationStatus) {
      msgs.push(<li key={inx}>{state.lastOperationStatus}...</li>)
    }
    return msgs
  }

  const msgs = props.monitored.reduce(getMsgs, [])
  if (msgs.length < 1) {
    msgs.push(<li key={-1}>Loading...</li>)
  }
  const msglist = <ul style={{ listStyleType: 'none', paddingLeft: 0, marginTop: '1em' }}>{msgs}</ul>

  if (loaded || props.alwaysDisplayChildren) {
    return props.children
  } else if (loading) {
    return <div className="page-spinner"><LoadingIcon /></div>
  } else if (error) {
    return props.errorContent || <div>An error occurred while loading: {msglist}</div>
  }

  return <div>Something has gone wrong with the loading component</div>
}
