import { serviceGetCurrentUser, serviceUpdateAccount } from 'common/services/profile'
import { showToast } from 'common/modules/toaster'
import { createSelector } from 'reselect'
import { getProperty } from 'common/utils/componentHelper'


const selectNotificationSettings = state => getProperty(state.account.data, 'settings.notifications')

export const notificationSettingsSelector = createSelector(
  selectNotificationSettings,
  settings => {
    const data = {};
    ['reminder', 'invitation', 'unsolicited'].forEach(type => { data[type] = getProperty(settings, type) || [] })
    return data
  }
)


export function getAccount() {
  return dispatch => {
    dispatch({ type: 'GET_ACCOUNT_START' })
    return serviceGetCurrentUser(
      response => dispatch({ type: 'GET_ACCOUNT_SUCCESS', data: response }),
      error => {
        dispatch({ type: 'GET_ACCOUNT_ERROR' })
        dispatch(showToast({ message: error.description, level: 'error' }))
      })
  }
}

export function updateAccount(account) {
  return dispatch => {
    dispatch({ type: 'UPDATE_ACCOUNT_START', account })
    return serviceUpdateAccount(account,
      response => dispatch({ type: 'UPDATE_ACCOUNT_SUCCESS', data: response }),
      error => {
        dispatch({ type: 'UPDATE_ACCOUNT_ERROR' })
        dispatch(showToast({ message: error.description, level: 'error' }))
      })
  }
}


const initialState = {
  isFetching: false,
  isError: false,
  isLoaded: false,
  data: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_ACCOUNT_START':
    case 'UPDATE_ACCOUNT_START':
      return {
        ...state,
        isFetching: true,
        isError: false,
        data: {
          ...state.data,
          ...action.account,
        },
      }

    case 'GET_ACCOUNT_SUCCESS':
    case 'UPDATE_ACCOUNT_SUCCESS':
      return {
        ...state,
        isFetching: false,
        data: action.data,
        isLoaded: true,
      }

    case 'GET_ACCOUNT_ERROR':
      return {
        ...state,
        isFetching: false,
        isError: true,
      }

    default:
      return { ...state }
  }
}
