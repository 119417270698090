import { addSortingSupport, addPaginationSupport } from './helper'
import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetSessionsData } from 'admin/services/dashboard'
import { calendarsByIdSelector } from 'admin/modules/calendars'
import { eventTimezoneSelector } from 'admin/modules/events'
import { getProperty } from 'common/utils/componentHelper'
import search, { buildFieldList } from 'common/utils/search'
import Moment from 'moment'
import sort from 'common/utils/sortHelper'


const SessionsModule = generateDataModule({
  initialData: {
    current: [],
    tracking: {},
    upcoming: [],
  },
  initialState: {
    filters: {
      search: '',
      calendarId: undefined,
      locationName: undefined,
    },
    queryParams: {
      sort_column: 'title',
      sort_order: 1,
      per_page: 100,
      page: undefined,
    },
    pagination: {
      page: undefined,
      pageCount: 1,
    },
  },
  moduleKey: 'dashboardSessions',
  reducerKey: 'admin.dashboard.sessions',
  services: {
    get: { service: serviceGetSessionsData },
  },
})


addSortingSupport(SessionsModule, { id: 'title', order: 1 }, {
  baseKey: 'currentSessionsSorter',
  resetKey: 'currentSessionsResetSorter',
  setKey: 'currentSessionsSetSorter',
})

addSortingSupport(SessionsModule, { id: 'title', order: 1 }, {
  baseKey: 'upcomingSessionsSorter',
  resetKey: 'upcomingSessionsResetSorter',
  setKey: 'upcomingSessionsSetSorter',
})

addPaginationSupport(SessionsModule)

function getTrackingStatsBySessionId(sessions, stats) {
  const statsBySessionId = {}

  for (let sessionIndex = 0; sessionIndex < sessions.length; sessionIndex++) {
    const session = sessions[sessionIndex]
    const sessionStats = []

    if (session.location) {
      for (let statIndex = 0; statIndex < stats.length; statIndex++) {
        const stat = stats[statIndex]

        if (stat.date < session.date || stat.date > session.end_date) {
          continue // eslint-disable-line no-continue
        }

        const location = stat.locations.find(_location => _location.name === session.location.name)
        if (location) {
          sessionStats.push({ date: stat.date, count: location.count })
        }
      }
    }

    statsBySessionId[session.id] = sessionStats
  }

  return statsBySessionId
}

function formatSession(session, trackingStatsBySessionId) {
  const trackingStats = (trackingStatsBySessionId[session.id])
  const currentAttendance = trackingStats ? getProperty(trackingStats, `${trackingStats.length - 1}.count`) || 0 : 0

  const formattedSession = { ...session }
  formattedSession.location_display_name = getProperty(session, 'location.display_name')
  formattedSession.current_attendance = parseInt(currentAttendance, 10)

  return formattedSession
}

function filterSessions(sessions, filters) {
  let filteredSessions = [...sessions]
  filteredSessions = filters.search ? filteredSessions.filter(session => search(filters.search, buildFieldList(session, ['title']))) : filteredSessions
  filteredSessions = filters.calendarId ? filteredSessions.filter(session => session.calendar_id === filters.calendarId) : filteredSessions
  filteredSessions = filters.locationName ? filteredSessions.filter(session => session.location && session.location.name === filters.locationName) : filteredSessions
  return filteredSessions
}

function sortSessions(sessions, sorter) {
  const sortOptions = [
    'end_date',
    { id: 'duration', order: -1 },
    'name',
    'id',
  ]

  if (sorter) {
    sortOptions.unshift({ id: sorter.id, order: sorter.order })
  }

  return sort(sessions, sortOptions)
}


SessionsModule.registerSelector(
  'rawCurrent',
  (state, moduleState) => moduleState.data.current,
  sessions => sessions
)

SessionsModule.registerSelector(
  'rawUpcoming',
  (state, moduleState) => moduleState.data.upcoming,
  sessions => sessions
)

SessionsModule.registerSelector(
  'current',
  [
    state => SessionsModule.selectors.rawCurrent(state),
    state => SessionsModule.selectors.filters(state),
    state => SessionsModule.selectors.currentSessionsSorter(state),
  ],
  (sessions, filters, sorter) => sortSessions(filterSessions(sessions, filters), sorter)
)

SessionsModule.registerSelector(
  'upcoming',
  [
    state => SessionsModule.selectors.rawUpcoming(state),
    state => SessionsModule.selectors.filters(state),
    state => SessionsModule.selectors.upcomingSessionsSorter(state),
  ],
  (sessions, filters, sorter) => sortSessions(filterSessions(sessions, filters), sorter)
)

SessionsModule.registerSelector(
  'tracking',
  (state, moduleState) => moduleState.data.tracking,
  trackingData => trackingData
)

SessionsModule.registerSelector(
  'trackingCountOnly',
  state => SessionsModule.selectors.tracking(state),
  trackingData => trackingData.map(data => data.count)
)

SessionsModule.registerSelector(
  'minimalData',
  (state, moduleState) => moduleState.data.current,
  sessions => sortSessions(sessions).slice(0, 3)
)

SessionsModule.registerSelector(
  'calendars',
  [
    state => SessionsModule.selectors.rawCurrent(state),
    state => SessionsModule.selectors.rawUpcoming(state),
    state => calendarsByIdSelector(state),
  ],
  (currentSessions, upcomingSessions, calendarsById) => {
    const calendars = {};

    [...currentSessions, ...upcomingSessions].forEach(session => {
      const calendar = calendarsById[session.calendar_id]

      if (calendar && !calendars[calendar.id]) {
        calendars[calendar.id] = calendar
      }
    })

    return sort(Object.values(calendars), ['name', 'id'])
  }
)

SessionsModule.registerSelector(
  'locations',
  [
    state => SessionsModule.selectors.rawCurrent(state),
    state => SessionsModule.selectors.rawUpcoming(state),
  ],
  (currentSessions, upcomingSessions) => {
    const locations = {};

    [...currentSessions, ...upcomingSessions].forEach(session => {
      if (session.location && !locations[session.location.name]) {
        locations[session.location.name] = session.location
      }
    })

    return sort(Object.values(locations), ['display_name', 'name'])
  }
)

SessionsModule.registerSelector(
  'filters',
  (state, moduleState) => moduleState.filters,
  filters => filters
)


SessionsModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {
    const timezone = eventTimezoneSelector(getState())
    const currentMoment = Moment.unix(Moment().unix()).tz(timezone)
    const queryParams = SessionsModule.selectors.queryParams(getState())

    dispatch({ type: SessionsModule.actionKeys.getStart })

    return SessionsModule._getService('get')(currentMoment, queryParams)
      .then(response => {
        const trackingData = getTrackingStatsBySessionId([...response.current, ...response.upcoming], response.tracking)
        const data = {
          current: response.current.map(session => formatSession(session, trackingData)),
          upcoming: response.upcoming.map(session => formatSession(session, trackingData)),
          tracking: trackingData,
        }

        dispatch({
          type: SessionsModule.actionKeys.getSuccess,
          payload: {
            data: data,
            page: response.page,
            pageCount: response.pageCount,
          },
        })
      })
      .catch(() => {
        dispatch({ type: SessionsModule.actionKeys.getError })
      })
  })


SessionsModule.registerReducer(
  SessionsModule.actionKeys.getSuccess,
  (state, action) => ({
    ...state,
    data: {
      current: action.payload.data.current,
      tracking: action.payload.data.tracking,
      upcoming: action.payload.data.upcoming,
    },
    pagination: {
      page: action.payload.page,
      pageCount: action.payload.pageCount,
    },
  })
)


SessionsModule.registerAction(
  'setFilters',
  filters => dispatch => {
    dispatch({ type: SessionsModule.actionKeys.setFilters, payload: filters })
    return Promise.resolve()
  }
)

SessionsModule.registerReducer(
  SessionsModule.actionKeys.setFilters,
  (state, action) => ({ ...state, filters: { ...state.filters, ...action.payload } })
)


SessionsModule.registerAction(
  'resetFilters',
  () => dispatch => {
    dispatch({ type: SessionsModule.actionKeys.resetFilters })
    return Promise.resolve()
  }
)

SessionsModule.registerReducer(
  SessionsModule.actionKeys.resetFilters,
  state => ({ ...state, filters: { ...SessionsModule.initialState.filters } })
)


export default SessionsModule
