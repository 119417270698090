import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getProperty } from 'common/utils/componentHelper'
import { eventFeaturesSelector, eventIdSelector } from 'attendee/modules/events'


export function isFeatureEnabled(features, id) {
  const featureField = getProperty(features, id)
  return !!getProperty(featureField, 'enabled')
}


export default function withFeatureAccess() {
  return (WrappedComponent) => {
    class PropsProxy extends Component {
      hasAccess = (id) => isFeatureEnabled(this.props.eventFeaturesController.status, id)

      render() {
        const passedProps = { ...this.props }
        passedProps.hasFeatureAccess = this.hasAccess

        return <WrappedComponent {...passedProps} />
      }
    }

    return connect(
      state => ({
        eventFeatures: eventFeaturesSelector(state),
        eventId: eventIdSelector(state),
      }),
      undefined,
      (state, actions, props) => ({
        ...props,
        eventFeaturesController: {
          status: state.eventFeatures,
          eventId: state.eventId,
        },
      }),
      { forwardRef: true }
    )(PropsProxy)
  }
}
