import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { translate } from 'react-i18next'

import ButtonContainer from 'common/containers/Login/ButtonContainer'
import TitleLabel from 'common/containers/Login/TitleLabel'


@reduxForm({ form: 'login', fields: ['email', 'password_notrim'], destroyOnUnmount: false })
@translate(['login'], { wait: true })
export default class SendCode extends Component {
  componentWillMount() {
    // Cannot send a code if we don't know the email
    if (!this.props.user.email) {
      this.props.changeLoginPage(this.props.location, 'email')
    }
  }

  clickReturnEmailPage = () => {
    this.props.initializeForm({ email: '', password_notrim: '' })
    this.props.changeLoginPage(this.props.location, 'email')
  }

  renderCodeHandler = (buttonTypes) => {
    const { t, location } = this.props
    const label = (() => {
      switch (buttonTypes[0]) {
        case 'email': return t('Send login code by email')
        case 'sms': return t('Send login code by SMS')
        default: return t('Log in with my social media account')
      }
    })()

    const onClickContainer = () => {
      if (buttonTypes[0] === 'email' || buttonTypes[0] === 'sms') {
        this.props.sendLoginCode(buttonTypes[0])
        this.props.changeLoginPage(this.props.location, 'enter_code')
      }
    }

    return (
      <div className={`SendCode-container ${(buttonTypes[0] === 'email' || buttonTypes[0] === 'sms') ? 'clickable' : ''}`} onClick={onClickContainer}>
        <div className="SendCode-label">{label}</div>
        <ButtonContainer buttons={buttonTypes} location={location} />
      </div>
    )
  }

  render() {
    const { t } = this.props
    const canSendBySms = this.props.user.hasMobilePhone
    const socialNetworksLinked = (this.props.user.socialNetworks || []).filter(o => ['facebook', 'linkedin', 'eegsignal'].includes(o))
    const hasPassword = this.props.user.hasPassword

    return (
      <div className="SendCode">
        {!hasPassword && <div className="no-password-description">
          {t('no_password_description')}
        </div>}

        {this.renderCodeHandler(['email'])}
        {canSendBySms && this.renderCodeHandler(['sms'])}
        {socialNetworksLinked.length > 0 && this.renderCodeHandler(socialNetworksLinked)}

        <TitleLabel label={t('Return to the email page')} onClick={this.clickReturnEmailPage} />
      </div>
    )
  }
}
