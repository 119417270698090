import { serviceGetLocations } from 'attendee/services/maps'
import generateModule from 'common/utils/generateModule'
import { listToMapping } from 'common/utils/componentHelper'
import { createSelector } from 'reselect'
import { publicMapsByIdSelector } from './venueMaps'


// ////////////////////////////////////////////////////////////////////////////
// Selectors
// ////////////////////////////////////////////////////////////////////////////

const selectLocations = state => state.attendee.attLocations.data

export const locationsSelector = createSelector(
  selectLocations,
  (locations) => locations
)

export const publicLocationsSelector = createSelector(
  selectLocations,
  publicMapsByIdSelector,
  (locations, publicMapsById) => locations.filter(location => !!publicMapsById[location.map])
)

export const publicLocationsByIdSelector = createSelector(publicLocationsSelector, locations => listToMapping(locations, 'name'))

export const networkingLocationsSelector = createSelector(
  selectLocations,
  (locations) => locations.filter(location => location.networking)
)

export const locationsByMapIdSelector = createSelector(
  publicLocationsSelector,
  locations => {
    const locationsByMapId = {}

    locations.forEach(location => {
      if (!locationsByMapId[location.map]) {
        locationsByMapId[location.map] = []
      }

      locationsByMapId[location.map].push(location)
    })

    return locationsByMapId
  }
)


// ////////////////////////////////////////////////////////////////////////////
// Module
// ////////////////////////////////////////////////////////////////////////////

const attLocations = generateModule({
  itemName: 'attLocation',
  itemNameDisplay: 'location',
  toasterFieldName: 'name',
  autoRefresh: 60,
  services: {
    get: serviceGetLocations,
  },
  app: 'attendee',
})

attLocations.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isLoaded: false,
  isError: false,
  didInvalidate: true,
  data: [],
})

attLocations.actions.getLocations = attLocations.actions.get
export default attLocations
