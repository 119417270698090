import fullAccess from 'common/utils/Permissions/FullAccess'
import organizerDeploy from 'common/utils/Permissions/OrganizerDeploy'
import organizer from 'common/utils/Permissions/Organizer'
import contentManager from 'common/utils/Permissions/ContentManager'
import onsiteSupervisor from 'common/utils/Permissions/OnsiteSupervisor'
import staff from 'common/utils/Permissions/Staff'
import staffWithImpersonation from 'common/utils/Permissions/StaffWithImpersonation'
import staffCannotCreate from 'common/utils/Permissions/StaffCannotCreate'
import blueTraceAdmin from 'common/utils/Permissions/BlueTraceAdmin'
import blueTraceTech from 'common/utils/Permissions/BlueTraceTech'
import readOnly from 'common/utils/Permissions/ReadOnly'


const permissions = {
  full: fullAccess,
  employee: fullAccess,
  organizer_deployer: organizerDeploy,
  organizer: organizer,
  content_manager: contentManager,
  onsite_manager: onsiteSupervisor,
  staff: staff,
  staff_with_impersonation: staffWithImpersonation,
  staff_no_create: staffCannotCreate,
  bluetrace_admin: blueTraceAdmin,
  bluetrace_tech: blueTraceTech,
  read_only: readOnly,
  firmware: readOnly,
}

export function getPermissions(role) {
  return { ...(permissions[role] || permissions.full) }
}

export function getRole(roles, eventId) {
  return roles[eventId] || roles['*'] || 'read_only'
}

export default permissions
