import { createSelector } from 'reselect'

const initialState = {
  isLoaded: false,
  isPublished: true,
  eventId: undefined,
  isLeaderboardVisible: false,
  isMenuMounted: false,
  isWatchingSessionVideo: false,

  status: {
    completedSplash: false,
    visitedSplash: false,
  },
}


const selectState = state => state.attendee.app
const selectIsMenuMounted = state => selectState(state).isMenuMounted

export const isMenuMountedSelector = createSelector(selectIsMenuMounted, isMounted => isMounted)
export const isWatchingSessionVideoSelector = createSelector(selectState, state => state.isWatchingSessionVideo)


export function setIsPublished(isPublished) {
  return dispatch => {
    dispatch({ type: 'ATTENDEE_APP_SET_IS_PUBLISHED', payload: { isPublished } })
    return Promise.resolve()
  }
}

export function setIsLoaded(isLoaded) {
  return dispatch => new Promise(resolve => {
    dispatch({ type: 'ATTENDEE_APP_SET_IS_LOADED', payload: { isLoaded } })
    resolve()
  })
}

export function setIsMenuMounted(isMounted) {
  return dispatch => {
    dispatch({ type: 'ATTENDEE_APP_SET_IS_MENU_MOUNTED', payload: { isMounted } })
    return Promise.resolve()
  }
}

function changeStatus(status) {
  return dispatch => {
    dispatch({ type: 'ATTENDEE_APP_CHANGE_STATUS', payload: { status } })
    return Promise.resolve()
  }
}

export function completedSplash() {
  return changeStatus('completedSplash')
}

export function visitedSplash() {
  return changeStatus('visitedSplash')
}

export function setLeaderboardVisibility(isVisible) {
  return dispatch => {
    dispatch({ type: 'SET_ATTENDEE_LEADERBOARD_VISIBILITY', payload: { isVisible } })
    return Promise.resolve()
  }
}

export function setIsWatchingSessionVideo(isWatching) {
  return dispatch => {
    dispatch({ type: 'ATTENDEE_APP_SET_IS_WATCHING_VIDEO', payload: !!isWatching })
    return Promise.resolve()
  }
}


export default function app(state = initialState, action) {
  switch (action.type) {
    case 'ATTENDEE_APP_SET_IS_PUBLISHED':
      return { ...state, isPublished: action.payload.isPublished }

    case 'ATTENDEE_APP_SET_IS_LOADED':
      return { ...state, isLoaded: action.payload.isLoaded }

    case 'ATTENDEE_APP_SET_IS_MENU_MOUNTED':
      return { ...state, isMenuMounted: action.payload.isMounted }

    case 'ATTENDEE_APP_CHANGE_STATUS':
      return { ...state, status: { ...state.status, [action.payload.status]: true } }

    case 'SET_ATTENDEE_LEADERBOARD_VISIBILITY':
      return { ...state, isLeaderboardVisible: action.payload.isVisible }

    case 'CHANGE_ATT_EVENT_SUCCESS':
      return { ...state, ...initialState, eventId: action.eventId }

    case 'ATTENDEE_APP_SET_IS_WATCHING_VIDEO':
      return { ...state, isWatchingSessionVideo: action.payload }

    default:
      return state
  }
}
