import { serviceGetEvent, serviceGetEventConfig, serviceGetManagedEvents, serviceAddEvent, serviceCreateDemoEvent, serviceUpdateEvent, serviceUpdateEventConf, serviceUploadAsset } from 'admin/services/events'
import { serviceSendWelcomeEmail } from 'admin/services/emailing'
import { serviceGetEventStats } from 'admin/services/analytics'
import { serviceRefreshLogin } from 'common/services/login'
import { renewTokens, getClientId } from 'common/modules/auth'
import { showToast } from 'common/modules/toaster'
import { getDatesUniqueDays, getProperty, listToMapping, replaceVariableInCurrentPath } from 'common/utils/componentHelper'
import { saveEvent, saveTokens } from 'common/utils/accessToken'
import { browserHistoryPush } from 'common/components/Link'
import { addVenueMap, updateVenueMap } from 'admin/modules/venueMaps'
import i18n from 'i18next'
import moment from 'moment'
import { createSelector } from 'reselect'
import { KLIK_SUPPORTED_LANGUAGES } from 'common/utils/translator'

const selectEventData = state => state.admin.events.currentEvent.fields
const selectCurrentEventId = state => state.admin.events.currentEvent.id
const selectEventTimezone = state => state.admin.events.currentEvent.fields.timezone
const selectNetworkingIntroductionLabel = state => state.admin.events.currentEvent.fields.networking_introduction_label
const selectAssetUploadsInProgress = state => state.admin.events.assetUploadInProgress

export const currentEventIdSelector = createSelector(selectCurrentEventId, eventId => eventId)
export const currentEventSelector = createSelector(selectEventData, event => event)
export const eventTimezoneSelector = createSelector(selectEventTimezone, timezone => timezone)
export const isStandaloneEventSelector = createSelector(selectEventData, event => !!event.standalone)
export const networkingIntroductionLabelSelector = createSelector(selectNetworkingIntroductionLabel, label => label)
export const assetUploadsInProgressSelector = createSelector(selectAssetUploadsInProgress, assetUploadsInProgress => assetUploadsInProgress)
export const eventStartDateSelector = createSelector(selectEventData, event => event.start_date)
export const eventEndDateSelector = createSelector(selectEventData, event => event.end_date)
export const domainSelector = createSelector(selectEventData, event => event.domain || 'default')
export const eventLanguageSelector = createSelector(selectEventData, event => getProperty(event, 'language'))
export const eventLanguageLabelSelector = createSelector(eventLanguageSelector, (language) => {
  const matchingLanguage = KLIK_SUPPORTED_LANGUAGES.find(l => l.value === language)
  return getProperty(matchingLanguage, 'label') || language
})
export const eventsSelector = createSelector(state => state.admin.events.events, _events => _events)
export const eventAvailableLanguagesSelector = createSelector(selectEventData, event => getProperty(event, 'available_languages') || [])

export const eventDaysSelector = createSelector(
  eventStartDateSelector,
  eventEndDateSelector,
  eventTimezoneSelector,
  (startDate, endDate, timezone) => getDatesUniqueDays(startDate, endDate, timezone, 'label', 'timestamp').map(date => ({
    start: moment.unix(date.timestamp).tz(timezone).startOf('day').unix(),
    end: moment.unix(date.timestamp).tz(timezone).endOf('day').unix(),
    startMoment: moment.unix(date.timestamp).tz(timezone).startOf('day'),
    endMoment: moment.unix(date.timestamp).tz(timezone).endOf('day'),
  }))
)

export const eventsByIdSelector = createSelector(
  eventsSelector,
  (_events) => listToMapping(_events, 'id')
)

export const eventsDropdownValuesSelector = createSelector(
  eventsSelector,
  (_events) => _events.map(e => ({ label: e.title, value: e.id }))
)

function getEventStart() {
  return {
    type: 'GET_EVENT_START',
    data: {
      status: i18n.getFixedT(i18n.language, 'admin_customModule')('events.get_event.start'),
    },
  }
}

function getEventSuccess(response) {
  return {
    type: 'GET_EVENT_SUCCESS',
    data: {
      event: response,
      status: i18n.getFixedT(i18n.language, 'admin_customModule')('events.get_event.success'),
    },
  }
}

function getEventError(error) {
  return {
    type: 'GET_EVENT_ERROR',
    data: {
      status: error,
    },
  }
}

export function getEvent(eventId) {
  return dispatch => {
    dispatch(getEventStart())
    serviceGetEvent(
      eventId,
      response => {
        dispatch(getEventSuccess(response))
      },
      error => {
        const message = i18n.getFixedT(i18n.language, 'admin_customModule')('events.get_event.error', { error: error })
        dispatch(getEventError(message))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.get_event.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}

function getEventsStart() {
  return {
    type: 'GET_EVENTS_START',
    data: {
      status: i18n.getFixedT(i18n.language, 'admin_customModule')('events.get_events.start'),
    },
  }
}

function sortEvents(eventsToSort) {
  const currentTime = moment().unix()
  const currentlyHappeningEvents = []
  const futureEvents = []
  const pastEvents = []

  const sortByTime = (eventA, eventB, ascending) => {
    if (eventA.start_date === eventB.start_date) {
      return ascending ? eventA.end_date - eventB.end_date : eventB.end_date - eventA.end_date
    }
    return ascending ? eventA.start_date - eventB.start_date : eventB.start_date - eventA.start_date
  }

  eventsToSort.forEach((event) => {
    if (event.start_date <= currentTime && event.end_date >= currentTime) {
      currentlyHappeningEvents.push(event)
    } else if (event.start_date > currentTime) {
      futureEvents.push(event)
    } else {
      pastEvents.push(event)
    }
  })

  currentlyHappeningEvents.sort((a, b) => sortByTime(a, b, true))
  futureEvents.sort((a, b) => sortByTime(a, b, true))
  pastEvents.sort((a, b) => sortByTime(a, b, false))

  return [...currentlyHappeningEvents, ...futureEvents, ...pastEvents]
}

function getEventsSuccess(data, status) {
  return {
    type: 'GET_EVENTS_SUCCESS',
    data: {
      events: sortEvents(data),
      status: status,
    },
  }
}

function getEventsError(status) {
  return {
    type: 'GET_EVENTS_ERROR',
    data: {
      status: status,
    },
  }
}

export function getEvents(archived = false) {
  return (dispatch) => {
    dispatch(getEventsStart())
    return serviceGetManagedEvents(
      archived,
      (result) => {
        dispatch(getEventsSuccess(result, i18n.getFixedT(i18n.language, 'admin_customModule')('events.get_events.success')))
      },
      (error) => {
        dispatch(getEventsError(i18n.getFixedT(i18n.language, 'admin_customModule')('events.get_events.error', { error: error.description })))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.get_events.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}

function shouldFetchEvent(state) {
  const data = state.events
  let retVal = false
  if (!data) {
    retVal = true
  } else if (data.isFetching) {
    retVal = false
  } else {
    retVal = data.didInvalidate
  }
  return retVal
}

export function getEventsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchEvent(getState())) {
      return dispatch(getEvents())
    }
    return {}
  }
}

function changeEventSuccess(eventId) {
  return {
    type: 'CHANGE_EVENT_SUCCESS',
    data: {
      eventId: eventId,
    },
  }
}

export function changeEvent(eventId, shouldRedirect = true) {
  return (dispatch, getState) => {
    dispatch(changeEventSuccess(eventId))

    const roles = getState().auth.roles
    dispatch({ type: 'CHECK_PERMISSIONS', data: { eventId: eventId, roles: roles } })

    if (shouldRedirect) {
      browserHistoryPush(replaceVariableInCurrentPath('/admin/events/', eventId))
    }

    return Promise.resolve()
  }
}

export function selectEvent(eventId) {
  return (dispatch) => dispatch(changeEvent(eventId))
}

function addEventStart(event) {
  return {
    type: 'ADD_EVENT_START',
    data: {
      event: event,
      status: i18n.getFixedT(i18n.language, 'admin_customModule')('events.add_event.start'),
    },
  }
}

function addEventSuccess(event, status) {
  return {
    type: 'ADD_EVENT_SUCCESS',
    data: {
      event: event,
      status: status,
    },
  }
}

function addEventError(event, status) {
  return {
    type: 'ADD_EVENT_ERROR',
    data: {
      event: event,
      status: status,
    },
  }
}

export function addEvent(event) {
  return (dispatch, getState) => {
    const refreshToken = getState().auth.user.refreshToken
    dispatch(addEventStart(event))

    return serviceAddEvent(
      event,
      () => serviceRefreshLogin(refreshToken, getState().auth.domain, getClientId(getState()), (resp) => {
        saveTokens(resp.access_token, resp.refresh_token, 'admin')
        dispatch(renewTokens(resp.access_token, resp.refresh_token, resp.roles))
        return serviceGetEvent(
          event.id,
          (response) => {
            const message = i18n.getFixedT(i18n.language, 'admin_customModule')('events.add_event.success', { eventId: event.id })
            dispatch(addEventSuccess(response, message))
            dispatch(selectEvent(event.id))
            dispatch(showToast({
              title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.add_event.title'),
              message: message,
              level: 'success',
              permanent: false,
            }))
            return Promise.resolve()
          },
          (error) => {
            dispatch(showToast({
              title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.add_event.title'),
              message: error.description,
              level: 'error',
              permanent: false,
            }))
          }
        )
      }),
      (error) => {
        dispatch(addEventError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.add_event.title'),
          message: error.description,
          name: 'add_event_error',
          level: 'error',
          permanent: false,
        }))
        dispatch(getEvents())
      }
    )
  }
}

export function createDemoEvent(event) {
  return (dispatch, getState) => {
    const refreshToken = getState().auth.user.refreshToken
    return serviceCreateDemoEvent(
      event,
      () => {
        dispatch(addEventStart(event))
        serviceRefreshLogin(refreshToken, getState().auth.domain, getClientId(getState()), (resp) => {
          saveTokens(resp.access_token, resp.refresh_token, 'admin')
          dispatch(renewTokens(resp.access_token, resp.refresh_token, resp.roles))
          serviceGetEvent(
            event.id,
            (response) => {
              const message = i18n.getFixedT(i18n.language, 'admin_customModule')('events.add_event.success', { eventId: event.id })
              dispatch(addEventSuccess(response))
              dispatch(selectEvent(event.id))
              dispatch(showToast({
                title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.add_event.title'),
                message: message,
                level: 'success',
                permanent: false,
              }))
            }
          )
        })
      },
      (error) => {
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.add_event.title'),
          message: error.description,
          name: 'add_event_error',
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}


function updateEventStart(event) {
  return {
    event: event,
    type: 'UPDATE_EVENT_START',
  }
}

function updateEventSuccess(event, status) {
  return {
    type: 'UPDATE_EVENT_SUCCESS',
    data: {
      event: event,
      status: status,
    },
  }
}

function updateEventError(status) {
  return {
    type: 'UPDATE_EVENT_ERROR',
    data: {
      status: status,
    },
  }
}

export function updateEvent(event, onsuccess = null) {
  return (dispatch, getState) => {
    const updatedEvent = Object.assign({}, event) // { ...getState().admin.events.currentEvent.fields, ...event }

    dispatch(updateEventStart(updatedEvent))
    return serviceUpdateEvent(
      getState().admin.events.currentEvent.id,
      updatedEvent,
      (e) => {
        const message = i18n.getFixedT(i18n.language, 'admin_customModule')('events.update_event.success')
        dispatch(updateEventSuccess(e, message))
        if (!onsuccess) {
          dispatch(showToast({
            title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.update_event.title'),
            message: message,
            name: 'update_event_success',
            level: 'success',
            permanent: false,
          }))
        } else {
          onsuccess()
        }
      },
      (error) => {
        dispatch(updateEventError(i18n.getFixedT(i18n.language, 'admin_customModule')('events.update_event.error', { error: error.description })))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.update_event.title'),
          message: error.description,
          name: 'update_event_error',
          level: 'error',
          permanent: false,
        }))
        dispatch(getEvents())
      }
    )
  }
}

function getEventConfigStart(eventId) {
  return {
    type: 'GET_EVENT_CONFIG_START',
    data: {
      eventId: eventId,
    },
  }
}

function getEventConfigSuccess(response) {
  return {
    type: 'GET_EVENT_CONFIG_SUCCESS',
    data: {
      sections: response,
    },
  }
}

function getEventConfigError(error) {
  return {
    type: 'GET_EVENT_CONFIG_ERROR',
    data: {
      status: error,
    },
  }
}

/*
* Get one or more sections of the Event object
*
* eventId: The ID of the Event to GET
* sections: The sections to update (string e.g. -> 'email fields')
*/
export function getEventConfig(eventId, sections = 'branding features') {
  return dispatch => {
    dispatch(getEventConfigStart(eventId))
    return serviceGetEventConfig(
      eventId,
      sections,
      (response) => {
        dispatch(getEventConfigSuccess(response))
      },
      (error) => {
        dispatch(getEventConfigError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.get_Event_config.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}

// TODO: find a way to combine getEventConfig and getEventProfile
export function getEventProfile(eventId, sections = 'branding features') {
  return dispatch =>
    serviceGetEvent(
      eventId,
      (eventDetails) =>
        serviceGetEventConfig(
          eventId,
          sections,
          (eventSections) => {
            let eventFields = {
              ...eventDetails,
            }
            Object.keys(eventSections).forEach((key) => {
              eventFields = {
                ...eventFields,
                ...eventSections[key],
              }
            })
            dispatch({ type: 'GET_EVENT_PROFILE', data: { eventFields: eventFields } })
          },
          (error) => dispatch(getEventConfigError(error.description))
        ),
      (error) => dispatch(getEventError(error.description))
    )
}


function updateEventConfStart() {
  return {
    type: 'UPDATE_EVENT_CONF_START',
  }
}

function updateEventConfSuccess(response) {
  return {
    type: 'UPDATE_EVENT_CONF_SUCCESS',
    data: {
      sections: response,
    },
  }
}

function updateEventConfError(status) {
  return {
    type: 'UPDATE_EVENT_CONF_ERROR',
    data: {
      status: status,
    },
  }
}

/*
* Update one or more sections of the Event object
*
* sections: The sections to update (string e.g. -> 'email fields')
* data: The data to update
* onSuccess: Callback to execute on success
*/
export function updateEventConf(sections, data, onSuccess = null) {
  return (dispatch, getState) => {
    const eventId = getState().admin.events.currentEvent.id
    dispatch(updateEventConfStart())
    return serviceUpdateEventConf(
      eventId,
      sections,
      data,
      (response) => {
        dispatch(updateEventConfSuccess(response))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.update_event_config.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('events.update_event_config.success', { eventId: eventId }),
          level: 'success',
          permanent: false,
        }))
        if (onSuccess) {
          onSuccess()
        }
      },
      (error) => {
        dispatch(updateEventConfError())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.update_event_config.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}


export function updateLevels(levels) {
  return (dispatch, getState) => {
    dispatch(updateEventStart())
    serviceUpdateEvent(
      getState().admin.events.currentEvent.id,
      { levels: levels },
      (e) => {
        const message = i18n.getFixedT(i18n.language, 'admin_customModule')('events.update_levels.success')
        dispatch(updateEventSuccess(e, message))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.update_levels.title'),
          message: message,
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch(updateEventError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.update_levels.title'),
          message: error.description,
          level: 'error',
          permanent: 'false',
        }))
      }
    )
  }
}


function uploadAssetStart(assetName, asset) {
  return {
    type: 'UPLOAD_ASSET_START',
    data: {
      assetName: assetName,
      asset: asset,
    },
  }
}

function uploadAssetSuccess(assetName, status) {
  return {
    type: 'UPLOAD_ASSET_SUCCESS',
    data: {
      assetName: assetName,
      status: status,
    },
  }
}

function uploadAssetError(assetName, status) {
  return {
    type: 'UPLOAD_ASSET_ERROR',
    data: {
      assetName: assetName,
      status: status,
    },
  }
}


/**
  * Upload asset to the server and update a redux-form field
  *
  * assetName: The name of the asset (on the server)
  * asset: The asset to send to the server
  * formField: The redux-form field to update
  *
  * Note: We HAVE TO pass a redux-form field to this function because we can't update the assets
  *       and keep reference to the old state.
  *       When we update the assets, we need to update the state (updating status) AND update the field
  *       from redux-form. This solution increase the coupling (redux-form / state)
  *
  *       Any solution is welcome! (FRB)
  */
export function uploadAsset(assetName, asset, formField = undefined) {
  return (dispatch, getState) => {
    dispatch(uploadAssetStart(assetName, asset))
    return serviceUploadAsset(
      getState().admin.events.currentEvent.id,
      assetName,
      asset,
      (resp) => {
        const message = i18n.getFixedT(i18n.language, 'admin_customModule')('events.upload_asset.success')
        dispatch(uploadAssetSuccess(assetName, message))
        if (formField) {
          formField.onChange(resp)
        }

        return Promise.resolve(resp)
      },
      (error) => {
        const message = error.description
        dispatch(uploadAssetError(assetName, message))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.upload_asset.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}


export function addNewVenueMap(mapName, mapNumber, asset) {
  return (dispatch, getState) => {
    const eventId = getState().admin.events.currentEvent.id
    const assetName = `${eventId}_map_${mapNumber}`
    dispatch(uploadAssetStart(assetName, asset))
    serviceUploadAsset(
      eventId,
      assetName,
      asset,
      (resp) => {
        const message = i18n.getFixedT(i18n.language, 'admin_customModule')('events.upload_asset.success')
        dispatch(uploadAssetSuccess(assetName, message))
        dispatch(addVenueMap({
          name: mapName,
          image_url: resp,
          event_id: eventId,
        }))
      },
      (error) => {
        const message = error.description
        dispatch(uploadAssetError(assetName, message))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.upload_asset.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}

export function editVenueMap(mapId, mapName, mapNumber, mapPrivate, asset) {
  return (dispatch, getState) => {
    const eventId = getState().admin.events.currentEvent.id
    const assetName = `${eventId}_map_${mapNumber}`
    if (asset) {
      dispatch(uploadAssetStart(assetName, asset))
      serviceUploadAsset(
        eventId,
        assetName,
        asset,
        (resp) => {
          const message = i18n.getFixedT(i18n.language, 'admin_customModule')('events.upload_asset.success')
          dispatch(uploadAssetSuccess(assetName, resp, message))
          dispatch(updateVenueMap({
            id: mapId,
            name: mapName,
            private: mapPrivate,
            event_id: eventId,
            image_url: resp,
          }, true))
        },
        (error) => {
          const message = error.description
          dispatch(uploadAssetError(message))
          dispatch(showToast({
            title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.upload_asset.title'),
            message: error.description,
            level: 'error',
            permanent: false,
          }))
        }
      )
    } else {
      dispatch(updateVenueMap({
        id: mapId,
        name: mapName,
        private: mapPrivate,
        event_id: eventId,
        image_url: getState().admin.venueMaps.data.find(v => v.id === mapId).image_url,
      }))
    }
  }
}


/*
 * Event stats
 */


function getEventStatsStart() {
  return {
    type: 'GET_EVENT_STATS_START',
  }
}

function getEventStatsSuccess(response) {
  return {
    type: 'GET_EVENT_STATS_SUCCESS',
    data: { response: response },
  }
}

function getEventStatsError(error) {
  return {
    type: 'GET_EVENT_STATS_ERROR',
    data: {
      status: error,
    },
  }
}

export function getEventStats(query) {
  return (dispatch, getState) => {
    dispatch(getEventStatsStart())
    serviceGetEventStats(
      getState().admin.events.currentEvent.id,
      query,
      (response) => {
        dispatch(getEventStatsSuccess(response))
      },
      (error) => {
        dispatch(getEventStatsError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.get_event_stats.title'),
          message: error.description,
          level: 'error',
          permanent: 'false',
        }))
      }
    )
  }
}

function sendWelcomeEmailStart(eventId) {
  return {
    type: 'SEND_WELCOME_EMAIL_START',
    data: {
      eventId: eventId,
    },
  }
}

function sendWelcomeEmailSuccess(response) {
  return {
    type: 'SEND_WELCOME_EMAIL_SUCCESS',
    data: {
      version: response.version,
    },
  }
}

function sendWelcomeEmailError(error) {
  return {
    type: 'SEND_WELCOME_EMAIL_ERROR',
    data: {
      status: error,
    },
  }
}

/*
* Send the Welcome email to all attendees who havent received it yet
*
* options: object with possible values of:
*   - preview: (boolean) if the email is a preview
*/
export function sendWelcomeEmail(options = {}) {
  return (dispatch, getState) => {
    const eventId = getState().admin.events.currentEvent.id
    dispatch(sendWelcomeEmailStart(eventId))
    serviceSendWelcomeEmail(
      eventId,
      options,
      (response) => {
        dispatch(sendWelcomeEmailSuccess(response))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.send_welcome_email.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('events.send_welcome_email.success'),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch(sendWelcomeEmailError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('events.send_welcome_email.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}


const initialState = {
  isFetching: false,
  isModifying: false,
  isError: false,
  isLoaded: false,
  didInvalidate: true,
  lastOperationStatus: '',
  events: [],
  currentEvent: {},
  assetUploadInProgress: [],
  stats: [],
}

function getFieldsFromSections(sections) {
  const sectionsToReturn = {}
  Object.keys(sections).forEach((key) => {
    Object.assign(sectionsToReturn, sections[key])
  })

  return sectionsToReturn
}

export default function events(state = initialState, action) {
  switch (action.type) {
    case 'GET_EVENTS_START':
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
      }

    case 'GET_EVENTS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isLoaded: true,
        isError: false,
        events: action.data.events,
        lastOperationStatus: action.data.status,
      }

    case 'GET_EVENTS_ERROR':
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        isError: true,
        lastOperationStatus: action.data.status,
      }


    case 'GET_EVENT_START':
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
      }

    case 'GET_EVENT_SUCCESS':
      return {
        ...state,
        currentEvent: {
          ...state.currentEvent,
          fields: {
            ...action.data.event,
            start_date: action.data.event.start_date,
            end_date: action.data.event.end_date,
          },
        },
        isFetching: false,
        isLoaded: true,
        isError: false,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
      }

    case 'GET_EVENT_ERROR':
      return {
        ...state,
        isFetching: false,
        isError: true,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
      }

    case 'CHANGE_EVENT_SUCCESS':
      saveEvent(action.data.eventId)
      return {
        ...state,
        currentEvent: {
          id: action.data.eventId,
          fields: (state.currentEvent.fields && action.data.eventId === state.currentEvent.fields.id)
            ? state.currentEvent.fields
            : { ...state.events.find(e => e.id === action.data.eventId) || {} },
        },
      }

    case 'GET_EVENT_CONFIG_START': {
      return {
        ...state,
        currentEvent: {
          id: action.data.eventId,
        },
      }
    }

    case 'GET_EVENT_CONFIG_SUCCESS': {
      return {
        ...state,
        currentEvent: {
          id: state.currentEvent.id,
          fields: {
            ...state.currentEvent.fields,
            ...getFieldsFromSections(action.data.sections),
          },
        },
      }
    }

    case 'GET_EVENT_CONFIG_ERROR': {
      return {
        ...state,
        status: action.data.status,
      }
    }

    case 'GET_EVENT_PROFILE': {
      return {
        ...state,
        currentEvent: {
          ...state.currentEvent,
          fields: {
            ...state.currentEvent.fields,
            ...action.data.eventFields,
          },
        },
      }
    }

    case 'ADD_EVENT_START': {
      const e = state.events.slice()
      e.push(action.data.event)
      return {
        ...state,
        isModifying: true,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
        events: e,
      }
    }

    case 'ADD_EVENT_SUCCESS': {
      const e = state.events.slice()
      e[e.length - 1] = action.data.event
      return {
        ...state,
        isModifying: false,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
        events: e,
      }
    }

    case 'ADD_EVENT_ERROR':
      return {
        ...state,
        isModifying: false,
        didInvalidate: true,
        lastOperationStatus: action.data.status,
      }


    case 'UPDATE_EVENT_START':
      return {
        ...state,
        isModifying: true,
        didInvalidate: false,
      }

    case 'UPDATE_EVENT_SUCCESS': {
      const eventIdx = state.events.findIndex(event => event.id === action.data.event.id)
      const e = state.events.slice()
      e[eventIdx] = action.data.event

      return {
        ...state,
        isModifying: false,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
        events: e,
        currentEvent: {
          ...state.currentEvent,
          fields: { ...state.currentEvent.fields, ...action.data.event },
        },
      }
    }

    case 'UPDATE_EVENT_ERROR':
      return {
        ...state,
        isModifying: false,
        didInvalidate: true,
        lastOperationStatus: action.data.status,
      }

    case 'UPDATE_EVENT_CONF_START':
      return {
        ...state,
        isModifying: true,
        didInvalidate: false,
      }

    case 'UPDATE_EVENT_CONF_SUCCESS': {
      return {
        ...state,
        isModifying: false,
        didInvalidate: false,
        lastOperationStatus: 'Event updated successfully',
        currentEvent: {
          id: state.currentEvent.id,
          fields: {
            ...state.currentEvent.fields,
            ...action.data.sections,
          },
        },
      }
    }

    case 'UPDATE_EVENT_CONF_ERROR':
      return {
        ...state,
        isModifying: false,
        didInvalidate: true,
        lastOperationStatus: 'Event failed to update',
      }

    case 'UPLOAD_ASSET_START': {
      const assetUploadInProgress = state.assetUploadInProgress.slice()
      assetUploadInProgress.push(action.data.assetName)
      return {
        ...state,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
        assetUploadInProgress: assetUploadInProgress,
      }
    }

    case 'UPLOAD_ASSET_SUCCESS': {
      const assetUploadInProgress = state.assetUploadInProgress.slice()
      assetUploadInProgress.splice(assetUploadInProgress.indexOf(action.data.assetName), 1)
      return {
        ...state,
        didInvalidate: false,
        lastOperationStatus: action.data.status,
        assetUploadInProgress: assetUploadInProgress,
      }
    }

    case 'UPLOAD_ASSET_ERROR': {
      const assetUploadInProgress = state.assetUploadInProgress.slice()
      assetUploadInProgress.splice(assetUploadInProgress.indexOf(action.data.assetName), 1)
      return {
        ...state,
        didInvalidate: true,
        lastOperationStatus: action.data.status,
        assetUploadInProgress: assetUploadInProgress,
      }
    }

    case 'GET_EVENT_STATS_START':
      return {
        ...state,
        isFetching: true,
      }

    case 'GET_EVENT_STATS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        stats: action.data.response.timeseries,
      }

    case 'GET_EVENT_STATS_ERROR':
      return {
        ...state,
        isFetching: false,
        isError: true,
        lastOperationStatus: action.data.status,
      }

    default:
      return state
  }
}
