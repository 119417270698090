import { serviceGetEvents, serviceGetEventConfig, serviceGetEvent } from 'attendee/services/events'
import { saveEvent, setSoundNotificationSetting } from 'common/utils/accessToken'
import { browserHistoryPush } from 'common/components/Link'
import { getProperty, getDatesUniqueDays, listToMapping } from 'common/utils/componentHelper'
import generateModule from 'common/utils/generateModule'
import i18n from 'i18next'
import { brandPrimary } from 'config'
import { getTextColor } from 'common/utils/domUtils'
import ReactGA from 'react-ga'
import { createSelector } from 'reselect'
import { getTranslatedValue } from 'common/utils/translator'
import Moment from 'moment'


const selectCurrentEvent = state => state.attendee.attEvents.currEvent
const selectEventId = state => getProperty(state.attendee.attEvents.currEvent, 'id')
const selectEventColors = state => state.attendee.attEvents.color
const selectEventFeatures = state => getProperty(state.attendee.attEvents.currEvent, 'event_features')
const selectEventTimezone = state => getProperty(state.attendee.attEvents.currEvent, 'timezone')
const selectEventCustomFields = state => getProperty(state.attendee.attEvents.currEvent, 'account_custom_fields') || []
const selectEvents = state => state.attendee.attEvents.data
const selectNetworkingIntroductionLabel = state => getProperty(state.attendee.attEvents.currEvent, 'networking_introduction_label')
const selectNetworkingDescription = state => getProperty(state.attendee.attEvents.currEvent, 'networking_description')
const selectSessions = state => state.attendee.sessions.data

export const currentEventSelector = createSelector(selectCurrentEvent, event => event)
export const eventIdSelector = createSelector(selectEventId, eventId => eventId)
export const eventColorsSelector = createSelector(selectEventColors, colors => colors)
export const isStandaloneEventSelector = createSelector(selectCurrentEvent, event => event.standalone)
export const eventFeaturesSelector = createSelector(selectEventFeatures, eventFeatures => eventFeatures)
export const eventDatesSelector = createSelector(selectCurrentEvent, event => getDatesUniqueDays(event.start_date, event.end_date, event.timezone))
export const eventTimezoneSelector = createSelector(selectEventTimezone, timezone => timezone)
export const allowedSocialNetworksSelector = createSelector(selectCurrentEvent, event => getProperty(event, 'allowed_social_network') || [])
export const eventsSelector = createSelector(selectEvents, events => events)
export const hasMultipleEventsSelector = createSelector(eventsSelector, events => events.length > 1)
export const networkingIntroductionLabelSelector = createSelector(selectNetworkingIntroductionLabel, label => label)
export const networkingDescriptionSelector = createSelector(selectNetworkingDescription, description => description)
export const domainSelector = createSelector(selectCurrentEvent, event => event.domain || 'default')
export const eventDefaultLanguageSelector = createSelector(selectCurrentEvent, event => getProperty(event, 'language'))
export const availableLanguagesSelector = createSelector(selectCurrentEvent, event => getProperty(event, 'available_languages') || [])
export const availableLanguagesSetSelector = createSelector(availableLanguagesSelector, languages => new Set(languages))
export const eventBackgroundImageUrlSelector = createSelector(selectCurrentEvent, (event) => getProperty(event, 'banner_url'))
export const eventDropdownValuesSelector = createSelector(selectEvents, eventIdSelector, (events, eventId) =>
  events.filter(event => event.id !== eventId).map(event => ({
    label: getTranslatedValue(event.title),
    value: event.id,
  }))
)
export const eventTermsLinkSelector = createSelector(selectCurrentEvent, (event) => event.privacy_policy.terms_and_conditions_url)
export const eventPrivacyLinkSelector = createSelector(selectCurrentEvent, (event) => event.privacy_policy.privacy_policy_url)

export const eventDaysSelector = createSelector(
  currentEventSelector,
  eventTimezoneSelector,
  (event, timezone) => getDatesUniqueDays(event.start_date, event.end_date, timezone, 'label', 'timestamp').map(date => ({
    start: Moment.unix(date.timestamp).tz(timezone).startOf('day').unix(),
    end: Moment.unix(date.timestamp).tz(timezone).endOf('day').unix(),
  }))
)

export const eventCustomFieldsSelector = createSelector(selectEventCustomFields, customFields => customFields)
export const eventCustomFieldsByNameSelector = createSelector(selectEventCustomFields, customFields => listToMapping(customFields, 'name'))
export const publicCustomFieldsSelector = createSelector(eventCustomFieldsSelector, customFields => (customFields || []).filter(customField => customField.public))
export const networkingCustomFieldsSelector = createSelector(publicCustomFieldsSelector, customFields => customFields.filter(customField => (customField.type === 'choice' && customField.on_networking_profile)))

export const isBizzaboEventSelector = createSelector(selectCurrentEvent, event => event && event.id && event.id.startsWith('bizzabo-'))

// TODO: Remove this (and every usage) once '1282_LRP_HR_Conference' event is over
export const shouldHideDownloadContactButtonOnActivityCardsSelector = createSelector(selectEventId, eventId => eventId === '1282_LRP_HR_Conference')

export const eventDaysToSessions = createSelector(
  eventDatesSelector,
  selectSessions,
  eventTimezoneSelector,
  (eventDates, sessions, timezone) => {
    const daysToSessions = {}
    eventDates.forEach(date => {
      const sesh = sessions.filter(session => Moment.unix(session.start_date).tz(timezone)
        .isSame(Moment.unix(date.value).tz(timezone), 'day'))
      daysToSessions[date.value] = sesh
    })
    return daysToSessions
  }
)
const attEvents = generateModule({
  itemName: 'attEvent',
  itemNameDisplay: 'event',
  autoRefresh: 60,
  services: {
    get: serviceGetEvents,
  },
  app: 'attendee',
})

attEvents.initialState.currEvent = undefined
attEvents.initialState.color = { primary: brandPrimary, text: '#000' }
attEvents.initialState.sectionsLoaded = []

attEvents.actions.get = () =>
  (dispatch) => {
    dispatch(attEvents.actions.getStart())

    return serviceGetEvents(
      (result) => { dispatch(attEvents.actions.getSuccess(result, i18n.getFixedT(i18n.language, 'attendee_customModule')('events.get.success'))) },
      (error) => {
        dispatch(attEvents.actions.getError(error.description))
        return Promise.reject()
      }
    )
  }

attEvents.actions.retrieveEvent = (eventId, sections) =>
  (dispatch) => {
    const getEventBase = () => serviceGetEvent(
      eventId,
      (baseEvent) => Promise.resolve(baseEvent),
      (error) => Promise.reject(error)
    )

    const getEventConfig = () => serviceGetEventConfig(
      eventId,
      sections,
      (response) => {
        // Merge all the sections into one object
        let eventFields = {}
        Object.keys(response).forEach((key) => {
          eventFields = {
            ...eventFields,
            ...response[key],
          }
        })

        return Promise.resolve(eventFields)
      },
      (error) => Promise.reject(error)
    )

    let retrievedEvent = {}
    return Promise.all([
      getEventBase()
        .then(eventBase => { retrievedEvent = { ...eventBase } })
        .then(() => getEventConfig().then(eventConfig => { retrievedEvent = { ...retrievedEvent, ...eventConfig } }))
        .then(() => dispatch({ type: 'GET_ATT_EVENT', payload: { eventFields: retrievedEvent, sections: sections.split(' ') } }))
        .catch((error) => dispatch(attEvents.actions.getError(error.description))),
    ])
  }

function chooseEvent(allEvents, eventId) {
  return allEvents.filter(event => event.id === eventId)[0]
}

attEvents.actions.changeEventSuccess = (eventId) => {
  saveEvent(eventId, 'attendee')
  ReactGA.set({ dimension1: eventId })
  return {
    type: 'CHANGE_ATT_EVENT_SUCCESS',
    eventId: eventId,
  }
}

attEvents.actions.changeEvent = (eventId, currURLEventId) =>
  (dispatch, getState) => {
    const eventModule = { ...getState().attendee.attEvents }
    if (eventModule.sectionsLoaded.Length || (eventModule.currEvent && eventModule.currEvent.id !== eventId)) {
      dispatch({ type: 'ATTENDEE_INVALIDATE_DATA' })
    }

    dispatch(attEvents.actions.changeEventSuccess(eventId))
    dispatch(attEvents.actions.retrieveEvent(eventId, 'branding features fields ui email'))

    if (eventId !== currURLEventId) {
      browserHistoryPush('/events/' + eventId) // aesthetics; don't flicker the URL
    }

    return Promise.resolve(eventId)
  }

attEvents.reducers.GET_ATTEVENTS_SUCCESS = (state, action) => (
  {
    ...state,
    data: action.payload.data,
    isFetching: false,
    isError: false,
    isLoaded: true,
    didInvalidate: false,
    lastUpdated: action.payload.receivedAt,
    lastOperationStatus: action.payload.status,
  }
)

attEvents.reducers['CHANGE_ATT_EVENT_SUCCESS'] = (state, action) => { // eslint-disable-line dot-notation
  const eventObject = state.data.filter(event => event.id === action.eventId)[0]

  if (eventObject) {
    setSoundNotificationSetting(eventObject.sound_notification || false)
  }

  const currEvent = chooseEvent(state.data, action.eventId) || { id: action.eventId }
  const color = (currEvent && currEvent.colors) ? currEvent.colors.primary : state.color.primary
  return {
    ...state,
    currEvent: currEvent,
    color: { primary: color, text: getTextColor(color) },
    sectionsLoaded: [],
  }
}

attEvents.reducers['GET_ATT_EVENT'] = (state, action) => { // eslint-disable-line dot-notation
  const color = action.payload.eventFields.colors ? action.payload.eventFields.colors.primary : state.color.primary
  return {
    ...state,
    color: { primary: color, text: getTextColor(color) },
    currEvent: {
      ...state.currEvent,
      ...action.payload.eventFields,
    },
    sectionsLoaded: [...state.sectionsLoaded, ...action.payload.sections],
  }
}

attEvents.actions.getEvents = attEvents.actions.get
attEvents.actions.getEventsIfNeeded = attEvents.actions.getIfNeeded

export default attEvents
