import React, { Component } from 'react'

import ExternalLink from 'common/components/ExternalLink'


const icons = {
  success: 'fa fa-check',
  info: 'fa fa-question-circle',
  warning: 'fa fa-warning',
  error: 'fa fa-ban',
}

// Note: this should never become a stateless function component, since that would make animations not work (see FlipMove docs)
export default class Toast extends Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props)

    this.state = { updatedAt: Date.now() }
  }

  componentDidMount() {
    this.restartAnimationForTimeBar()
    this.restartTimer()
  }

  componentWillReceiveProps(newProps) {
    if (newProps.toast.updatedAt > this.state.updatedAt) {
      this.restartAnimationForTimeBar()
      this.restartTimer()
      this.setState({ updatedAt: newProps.toast.updatedAt })
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  restartAnimationForTimeBar = () => {
    const timeBar = this['time-bar']
    timeBar.classList.remove('time-bar-animated')
    timeBar.offsetWidth
    timeBar.classList.add('time-bar-animated')
  }

  restartTimer = () => {
    clearInterval(this.timer)
    this.timer = setInterval(this.deleteToast, 5000)
  }

  deleteToast = () => {
    this.props.delete(this.props.index)
  }

  render() {
    const { level, title, message, permanent, showTimeBar } = this.props.toast

    return (
      <div className={'toast ' + level} onClick={this.deleteToast}>
        {showTimeBar ? <div className={!permanent ? 'time-bar' : ''} ref={(self) => { this['time-bar'] = self }} /> : ''}
        <div className="close-btn fa fa-close" />
        <div className={'icon ' + icons[level]} />
        <div className="content">
          <div className="title">{title}</div>
          <div className="message">{message}</div>
        </div>
        {(!this.props.numToasts || this.props.numToasts <= 1) ? '' : <ExternalLink className="num-toasts" onClick={(e) => { this.props.expand(); e.stopPropagation(); return false }}>{this.props.numToasts - 1} more notifications</ExternalLink>}
      </div>
    )
  }
}
