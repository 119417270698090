import { serviceGetAppointmentSession, serviceGetAppointments, serviceGetSessionRelations, serviceGetSessionRelationsForAttendee, serviceDeleteAllAppointments, serviceDeleteAppointment, serviceAddAppointment, serviceEditAppointment, serviceAddParticipant, serviceRemoveParticipant, serviceCheckInParticipantToAppointment, serviceCheckOutParticipantFromAppointment } from 'admin/services/appointment'
import generateModule from 'common/utils/generateModule'
import { getProperty } from 'common/utils/componentHelper'
import { currentEventIdSelector } from 'admin/modules/events'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'
import { createSelector } from 'reselect'
import search, { buildFieldList } from 'common/utils/search'
import { buildErrorMessage } from './index'


// ////////////////////////////////////////////////////////////////////////////
// Selectors
// ////////////////////////////////////////////////////////////////////////////

const selectSession = state => state.admin.appointmentSummary.data.session
const selectAppointments = state => state.admin.appointmentSummary.data.appointments
const selectSessionRelations = state => state.admin.appointmentSummary.data.sessionRelations
const selectSelectedAppointmentIds = state => state.admin.appointmentSummary.selectedAppointmentIds
const selectFilters = state => state.admin.appointmentSummary.filters
const selectCurrentTime = state => state.admin.appointmentSummary.currentTime
const selectAppointmentModal = state => state.admin.appointmentSummary.appointmentModal
const selectAddParticipantModal = state => state.admin.appointmentSummary.addParticipantModal
const selectRemoveParticipantModal = state => state.admin.appointmentSummary.removeParticipantModal
const selectCheckOutParticipantModal = state => state.admin.appointmentSummary.checkOutParticipantModal
const selectDeleteAppointmentModal = state => state.admin.appointmentSummary.deleteAppointmentModal
const selectDeleteAllAppointmentsModal = state => state.admin.appointmentSummary.deleteAllAppointmentsModal

export const appointmentModalSelector = createSelector(selectAppointmentModal, modalState => modalState)
export const addParticipantModalSelector = createSelector(selectAddParticipantModal, modalState => modalState)
export const removeParticipantModalSelector = createSelector(selectRemoveParticipantModal, modalState => modalState)
export const checkOutParticipantModalSelector = createSelector(selectCheckOutParticipantModal, modalState => modalState)
export const deleteAppointmentModalSelector = createSelector(selectDeleteAppointmentModal, modalState => modalState)
export const deleteAllAppointmentsModalSelector = createSelector(selectDeleteAllAppointmentsModal, modalState => modalState)
export const filtersSelector = createSelector(selectFilters, filters => filters)
export const sessionSelector = createSelector(selectSession, session => session)
export const currentTimeSelector = createSelector(selectCurrentTime, currentTime => currentTime)
export const allAppointmentsSelector = createSelector(selectAppointments, appointments => appointments)

export const appointmentsByChronologicalOrderSelector = createSelector(
  allAppointmentsSelector,
  appointments => appointments.sort((a, b) => {
    if (a.start_date === b.end_date) return a.end_date - b.end_date
    return a.start_date - b.start_date
  })
)

export const currentAppointmentsSelector = createSelector(
  appointmentsByChronologicalOrderSelector,
  selectCurrentTime,
  (appointments, currentTime) => {
    const currentAppointments = []
    for (let i = 0; i < appointments.length; i++) {
      if (appointments[i].start_date <= currentTime && appointments[i].end_date >= currentTime) {
        currentAppointments.push(appointments[i])
      }
    }
    return currentAppointments
  }
)

export const previousAppointmentSelector = createSelector(
  appointmentsByChronologicalOrderSelector,
  currentAppointmentsSelector,
  (appointments, currentAppointments) => {
    if (currentAppointments.length <= 0) return null
    const earliestStartTime = Math.min(...currentAppointments.map(currentAppointment => currentAppointment.start_date))

    for (let i = appointments.length - 1; i >= 0; i--) {
      if (appointments[i].start_date < earliestStartTime) return appointments[i]
    }

    return null
  }
)

export const nextAppointmentSelector = createSelector(
  appointmentsByChronologicalOrderSelector,
  currentAppointmentsSelector,
  (appointments, currentAppointments) => {
    if (currentAppointments.length <= 0) return null
    const latestStartTime = Math.max(...currentAppointments.map(currentAppointment => currentAppointment.start_date))

    for (let i = 0; i < appointments.length; i++) {
      if (appointments[i].start_date > latestStartTime) return appointments[i]
    }

    return null
  }
)

export const searchedAppointmentsSelector = createSelector(
  appointmentsByChronologicalOrderSelector,
  selectFilters,
  (appointments, filters) => {
    const searchedAppointments = []
    if (!filters.search) return searchedAppointments

    appointments.forEach(appointment => {
      const doesMatch = appointment.participants.some(participant =>
        search(filters.search, buildFieldList(participant, ['first_name', 'last_name', 'email', 'company']))
      )

      if (doesMatch) searchedAppointments.push(appointment)
    })

    return searchedAppointments
  }
)

export const searchedAppointmentsByIdSelector = createSelector(
  searchedAppointmentsSelector,
  (appointments) => {
    const appointmentsById = {}
    appointments.forEach(appointment => { appointmentsById[appointment.id] = appointment })
    return appointmentsById
  }
)

export const appointmentsSelector = createSelector(
  appointmentsByChronologicalOrderSelector,
  searchedAppointmentsByIdSelector,
  selectCurrentTime,
  selectFilters,
  (appointments, searchResultsById, currentTime, filters) => {
    const filteredAppointments = []

    appointments.forEach(appointment => {
      if (searchResultsById[appointment.id]) {
        filteredAppointments.push(appointment)
        return
      } else if (filters.search) {
        // If we have a search and it does not match, let's hide it
        return
      }

      if (filters.hideFull && appointment.participants.length >= appointment.capacity) return
      if (filters.hidePast && appointment.end_date < currentTime) return

      filteredAppointments.push(appointment)
    })

    return filteredAppointments
  }
)

export const appointmentsByIdSelector = createSelector(
  allAppointmentsSelector,
  (appointments) => {
    const appointmentsById = {}
    appointments.forEach(appointment => { appointmentsById[appointment.id] = appointment })
    return appointmentsById
  }
)

export const selectedAppointmentsSelector = createSelector(
  appointmentsByChronologicalOrderSelector,
  selectSelectedAppointmentIds,
  (allAppointments, selectedAppointmentIds) =>
    allAppointments.filter(appointment => selectedAppointmentIds.indexOf(appointment.id) !== -1)
)

export const selectedAppointmentsByIdSelector = createSelector(
  selectedAppointmentsSelector,
  (appointments) => {
    const appointmentsById = {}
    appointments.forEach(appointment => { appointmentsById[appointment.id] = appointment })
    return appointmentsById
  }
)

export const sessionRelationsByAccountIdSelector = createSelector(
  selectSessionRelations,
  sessionRelations => {
    const sessionRelationsById = {}
    sessionRelations.forEach(account => { sessionRelationsById[account.id] = account.relations })
    return sessionRelationsById
  }
)


// ////////////////////////////////////////////////////////////////////////////
// Initial state
// ////////////////////////////////////////////////////////////////////////////

const { actions, reducers, initialState } = generateModule({
  itemName: 'appointment',
  idFieldName: 'id',
  dataIsNotArray: true,
  services: {
    get: serviceGetAppointmentSession,
  },
})

initialState.data = { session: undefined, appointments: [], sessionRelations: [] }
initialState.filters = { search: '', hideFull: false, hidePast: false }
initialState.currentTime = null // Used for the time indicator and filters
initialState.selectedAppointmentIds = []

initialState.appointmentModal = { show: false, appointmentId: null }
initialState.addParticipantModal = { show: false, appointmentId: null }
initialState.removeParticipantModal = { show: false, appointmentId: null, accountId: null }
initialState.checkOutParticipantModal = { show: false, appointmentId: null, accountId: null }
initialState.deleteAppointmentModal = { show: false, appointmentId: null }
initialState.deleteAllAppointmentsModal = { show: false }


// ////////////////////////////////////////////////////////////////////////////
// Helper functions
// ////////////////////////////////////////////////////////////////////////////

function getSessionRelationsForAttendee(eventId, attendeeId, calendarId, sessionId) {
  return serviceGetSessionRelationsForAttendee(
    eventId,
    attendeeId,
    calendarId,
    sessionId,
    (response) => Promise.resolve(response[0]),
    error => Promise.reject(error.description)
  )
}


// ////////////////////////////////////////////////////////////////////////////
// Backend actions
// ////////////////////////////////////////////////////////////////////////////

actions.get = (sessionId) =>
  (dispatch, getState) => {
    const eventId = currentEventIdSelector(getState())
    dispatch({ type: 'GET_APPOINTMENT_START', payload: { sessionId } })

    const getAppointmentSession = () => serviceGetAppointmentSession(
      eventId,
      sessionId,
      response => Promise.resolve(response),
      error => Promise.reject(error.description)
    )

    const getAppointments = (session) => serviceGetAppointments(
      eventId,
      session.calendar_id,
      session.id,
      response => Promise.resolve(response),
      error => Promise.reject(error.description)
    )

    const getSessionRelations = (session) => serviceGetSessionRelations(
      eventId,
      session.calendar_id,
      session.id,
      response => Promise.resolve(response),
      error => Promise.reject(error.description)
    )

    return getAppointmentSession()
      .then(session => Promise.all([
        getAppointments(session),
        getSessionRelations(session),
      ]).then((values) => {
        const appointments = values[0].map(appointment => ({ ...appointment, end_date: appointment.start_date + session.duration }))
        const sessionRelations = values[1]
        return Promise.resolve({ session, appointments, sessionRelations })
      }))
      .then((data) => dispatch({ type: 'GET_APPOINTMENT_SUCCESS', payload: { ...data } }))
      .catch(error => {
        dispatch({ type: 'GET_APPOINTMENT_ERROR', payload: { sessionId } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('appointment.get.title'),
          message: buildErrorMessage(error),
          level: 'error',
          permanent: false,
        }))
      })
  }

reducers['GET_APPOINTMENT_START'] = (state) => ({
  ...state,
  isFetching: true,
  isError: false,
})

reducers['GET_APPOINTMENT_SUCCESS'] = (state, action) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  data: { ...action.payload },
})

reducers['GET_APPOINTMENT_ERROR'] = (state) => ({
  ...state,
  isFetching: false,
  isError: true,
})


actions.deleteAllAppointments = (calendarId, sessionId) =>
  (dispatch, getState) => {
    dispatch({ type: 'DELETE_ALL_APPOINTMENTS_START', payload: { calendarId, sessionId } })

    return serviceDeleteAllAppointments(
      currentEventIdSelector(getState()),
      calendarId,
      sessionId,
      () => dispatch({ type: 'DELETE_ALL_APPOINTMENTS_SUCCESS', payload: { calendarId, sessionId } }),
      error => {
        dispatch({ type: 'DELETE_ALL_APPOINTMENTS_ERROR', payload: { calendarId, sessionId } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('appointment.delete_all_appointments.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['DELETE_ALL_APPOINTMENTS_SUCCESS'] = (state) => ({
  ...state,
  data: { ...state.data, appointments: initialState.data.appointments },
})


actions.deleteAppointment = (calendarId, sessionId, appointmentId) =>
  (dispatch, getState) => {
    dispatch({ type: 'DELETE_APPOINTMENT_START', payload: { sessionId, appointmentId } })

    return serviceDeleteAppointment(
      currentEventIdSelector(getState()),
      calendarId,
      sessionId,
      appointmentId,
      () => dispatch({ type: 'DELETE_APPOINTMENT_SUCCESS', payload: { sessionId, appointmentId } }),
      error => {
        dispatch({ type: 'DELETE_APPOINTMENT_ERROR', payload: { sessionId } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('appointment.delete_appointment.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['DELETE_APPOINTMENT_SUCCESS'] = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    appointments: state.data.appointments.filter(appointment => appointment.id !== action.payload.appointmentId),
  },
})


actions.addAppointment = (calendarId, sessionId, data) =>
  (dispatch, getState) => {
    const session = sessionSelector(getState())
    dispatch({ type: 'ADD_APPOINTMENT_START', payload: { sessionId, data } })

    return serviceAddAppointment(
      currentEventIdSelector(getState()),
      calendarId,
      sessionId,
      data,
      response => {
        const newAppointments = Array.isArray(response)
          ? response.map(appointment => ({ ...appointment, participants: appointment.participants || [], end_date: appointment.start_date + session.duration }))
          : [{ ...response, participants: response.participants || [], end_date: response.start_date + session.duration }]

        dispatch({ type: 'ADD_APPOINTMENT_SUCCESS', payload: newAppointments })
      },
      error => {
        dispatch({ type: 'ADD_APPOINTMENT_ERROR', payload: { sessionId, data } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('appointment.add_appointment.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['ADD_APPOINTMENT_SUCCESS'] = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    appointments: [...state.data.appointments, ...action.payload],
  },
})


actions.editAppointment = (calendarId, sessionId, data) =>
  (dispatch, getState) => {
    const state = getState()
    const session = sessionSelector(state)
    const appointmentsById = appointmentsByIdSelector(state)
    dispatch({ type: 'EDIT_APPOINTMENT_START', payload: { sessionId, data } })

    return serviceEditAppointment(
      currentEventIdSelector(getState()),
      calendarId,
      sessionId,
      data,
      response => {
        dispatch({
          type: 'EDIT_APPOINTMENT_SUCCESS',
          payload: {
            ...response,
            participants: response.participants || getProperty(appointmentsById[response.id], 'participants') || [],
            end_date: response.start_date + session.duration,
          },
        })
      },
      error => {
        dispatch({ type: 'EDIT_APPOINTMENT_ERROR', payload: { sessionId, data } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('appointment.edit_appointment.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['EDIT_APPOINTMENT_SUCCESS'] = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    appointments: state.data.appointments.map(appointment => (appointment.id !== action.payload.id ? appointment : { ...action.payload })),
  },
})


actions.addParticipant = (sessionId, appointmentId, attendee) =>
  (dispatch, getState) => {
    const eventId = currentEventIdSelector(getState())
    dispatch({ type: 'APPOINTMENT_ADD_PARTICIPANT_START', payload: { sessionId, appointmentId, attendee } })

    return serviceAddParticipant(
      eventId,
      sessionId,
      appointmentId,
      attendee.id,
      response => Promise.resolve(response),
      error => Promise.reject(error.description)
    )
      .then((session) => getSessionRelationsForAttendee(eventId, attendee.id, session.calendar_id, session.id))
      .then((relation) => dispatch({ type: 'APPOINTMENT_ADD_PARTICIPANT_SUCCESS', payload: { sessionId, appointmentId, attendee, relation } }))
      .catch(error => {
        dispatch({ type: 'APPOINTMENT_ADD_PARTICIPANT_ERROR', payload: { sessionId, appointmentId, attendee } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('appointment.add_participant.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      })
  }

reducers['APPOINTMENT_ADD_PARTICIPANT_SUCCESS'] = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    appointments: state.data.appointments.map(appointment => (appointment.id !== action.payload.appointmentId
      ? appointment
      : {
        ...appointment,
        participants: [...appointment.participants, action.payload.attendee],
      }
    )),
    sessionRelations: [...state.data.sessionRelations, { ...action.payload.relation }],
  },
})


actions.removeParticipant = (sessionId, appointmentId, attendeeId) =>
  (dispatch, getState) => {
    dispatch({ type: 'APPOINTMENT_REMOVE_PARTICIPANT_START', payload: { sessionId, appointmentId, attendeeId } })

    return serviceRemoveParticipant(
      currentEventIdSelector(getState()),
      sessionId,
      appointmentId,
      attendeeId,
      () => dispatch({ type: 'APPOINTMENT_REMOVE_PARTICIPANT_SUCCESS', payload: { sessionId, appointmentId, attendeeId } }),
      error => {
        dispatch({ type: 'APPOINTMENT_REMOVE_PARTICIPANT_ERROR', payload: { sessionId, appointmentId, attendeeId } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('appointment.remove_participant.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['APPOINTMENT_REMOVE_PARTICIPANT_SUCCESS'] = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    appointments: state.data.appointments.map(appointment => (appointment.id !== action.payload.appointmentId
      ? appointment
      : {
        ...appointment,
        participants: appointment.participants.filter(participant => participant.id !== action.payload.attendeeId),
      }
    )),
    sessionRelations: state.data.sessionRelations.filter(relation => relation.id !== action.payload.attendeeId),
  },
})


actions.checkInParticipant = (sessionId, appointmentId, attendeeId) =>
  (dispatch, getState) => {
    dispatch({ type: 'APPOINTMENT_CHECK_IN_PARTICIPANT_START', payload: { sessionId, appointmentId, attendeeId } })

    return serviceCheckInParticipantToAppointment(
      currentEventIdSelector(getState()),
      attendeeId,
      sessionId,
      appointmentId,
      () => dispatch({ type: 'APPOINTMENT_CHECK_IN_PARTICIPANT_SUCCESS', payload: { sessionId, appointmentId, attendeeId } }),
      error => {
        dispatch({ type: 'APPOINTMENT_CHECK_IN_PARTICIPANT_ERROR', payload: { sessionId, appointmentId, attendeeId } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('appointment.check_in_participant.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['APPOINTMENT_CHECK_IN_PARTICIPANT_SUCCESS'] = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    sessionRelations: state.data.sessionRelations.map(relation => (relation.id === action.payload.attendeeId
      ? { ...relation, relations: { ...relation.relations, currently_checked_in: true } }
      : relation
    )),
  },
})


actions.checkOutParticipant = (sessionId, appointmentId, attendeeId) =>
  (dispatch, getState) => {
    dispatch({ type: 'APPOINTMENT_CHECK_OUT_PARTICIPANT_START', payload: { sessionId, appointmentId, attendeeId } })

    return serviceCheckOutParticipantFromAppointment(
      currentEventIdSelector(getState()),
      attendeeId,
      sessionId,
      appointmentId,
      () => dispatch({ type: 'APPOINTMENT_CHECK_OUT_PARTICIPANT_SUCCESS', payload: { sessionId, appointmentId, attendeeId } }),
      error => {
        dispatch({ type: 'APPOINTMENT_CHECK_OUT_PARTICIPANT_ERROR', payload: { sessionId, appointmentId, attendeeId } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('appointment.check_out_participant.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['APPOINTMENT_CHECK_OUT_PARTICIPANT_SUCCESS'] = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    appointments: state.data.appointments.map(appointment => (appointment.id === action.payload.appointmentId
      ? {
        ...appointment,
        participants: appointment.participants.filter(participant => participant.id !== action.payload.attendeeId),
      }
      : appointment
    )),
    sessionRelations: state.data.sessionRelations.filter(relation => relation.id !== action.payload.attendeeId),
  },
})


// ////////////////////////////////////////////////////////////////////////////
// State actions
// ////////////////////////////////////////////////////////////////////////////

actions.clearData = () => dispatch => dispatch({ type: 'CLEAR_APPOINTMENT_DATA' })
reducers['CLEAR_APPOINTMENT_DATA'] = () => ({ ...initialState })

actions.setFilters = (filter) => dispatch => dispatch({ type: 'SET_APPOINTMENT_FILTERS', payload: { ...filter } })
reducers['SET_APPOINTMENT_FILTERS'] = (state, action) => ({ ...state, filters: { ...state.filters, ...action.payload } })

actions.setCurrentTime = (time) => dispatch => dispatch({ type: 'SET_APPOINTMENT_CURRENT_TIME', payload: { time } })
reducers['SET_APPOINTMENT_CURRENT_TIME'] = (state, action) => ({ ...state, currentTime: action.payload.time })

actions.setSelectedAppointments = (appointments) => dispatch => dispatch({ type: 'SET_SELECTED_APPOINTMENTS', payload: { ids: appointments.map(appointment => appointment.id) } })
reducers['SET_SELECTED_APPOINTMENTS'] = (state, action) => ({ ...state, selectedAppointmentIds: action.payload.ids })


actions.setAppointmentModal = (modalState) => dispatch => dispatch({ type: 'SET_APPOINTMENT_MODAL', payload: { ...modalState } })
reducers['SET_APPOINTMENT_MODAL'] = (state, action) => ({ ...state, appointmentModal: { ...state.appointmentModal, ...action.payload } })

actions.setAddParticipantModal = (modalState) => dispatch => dispatch({ type: 'SET_ADD_APPOINTMENT_PARTICIPANT_MODAL', payload: { ...modalState } })
reducers['SET_ADD_APPOINTMENT_PARTICIPANT_MODAL'] = (state, action) => ({ ...state, addParticipantModal: { ...state.addParticipantModal, ...action.payload } })

actions.setRemoveParticipantModal = (modalState) => dispatch => dispatch({ type: 'SET_REMOVE_APPOINTMENT_PARTICIPANT_MODAL', payload: { ...modalState } })
reducers['SET_REMOVE_APPOINTMENT_PARTICIPANT_MODAL'] = (state, action) => ({ ...state, removeParticipantModal: { ...state.removeParticipantModal, ...action.payload } })

actions.setCheckOutParticipantModal = (modalState) => dispatch => dispatch({ type: 'SET_CHECK_OUT_APPOINTMENT_PARTICIPANT_MODAL', payload: { ...modalState } })
reducers['SET_CHECK_OUT_APPOINTMENT_PARTICIPANT_MODAL'] = (state, action) => ({ ...state, checkOutParticipantModal: { ...state.checkOutParticipantModal, ...action.payload } })

actions.setDeleteAllApointmentsModal = (modalState) => dispatch => dispatch({ type: 'SET_DELETE_ALL_APPOINTMENTS_MODAL', payload: { ...modalState } })
reducers['SET_DELETE_ALL_APPOINTMENTS_MODAL'] = (state, action) => ({ ...state, deleteAllAppointmentsModal: { ...state.deleteAllAppointmentsModal, ...action.payload } })

actions.setDeleteAppointmentModal = (modalState) => dispatch => dispatch({ type: 'SET_DELETE_APPOINTMENT_MODAL', payload: { ...modalState } })
reducers['SET_DELETE_APPOINTMENT_MODAL'] = (state, action) => ({ ...state, deleteAppointmentModal: { ...state.deleteAppointmentModal, ...action.payload } })


// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////

export const getAppointmentSummary = actions.get
export const addAppointment = actions.addAppointment
export const editAppointment = actions.editAppointment
export const deleteAppointment = actions.deleteAppointment
export const deleteAllAppointments = actions.deleteAllAppointments
export const addParticipant = actions.addParticipant
export const removeParticipant = actions.removeParticipant
export const checkInParticipant = actions.checkInParticipant
export const checkOutParticipant = actions.checkOutParticipant

export const setFilters = actions.setFilters
export const setCurrentTime = actions.setCurrentTime
export const setSelectedAppointments = actions.setSelectedAppointments
export const clearAppointmentSummaryData = actions.clearData

export const setAppointmentModal = actions.setAppointmentModal
export const setAddParticipantModal = actions.setAddParticipantModal
export const setRemoveParticipantModal = actions.setRemoveParticipantModal
export const setCheckOutParticipantModal = actions.setCheckOutParticipantModal
export const setDeleteAppointmentModal = actions.setDeleteAppointmentModal
export const setDeleteAllApointmentsModal = actions.setDeleteAllApointmentsModal


export default reducers.index
