import generateModule from 'common/utils/generateModule'
import { getProperty } from 'common/utils/componentHelper'
import { currentEventIdSelector } from 'admin/modules/events'


/**
 * @param {string} options.moduleKey - Key in the state for that module
 * @param {string} options.reducerKey - Key that represents the key from the analytics reducer
 * @param {object|array} options.initialData - Object or array representing the initial data
 */
export default function generateAnalyticsModule(options) {
  const module = generateModule({
    itemName: options.moduleKey,
    getModuleState: (getState) => getProperty(getState().admin.analytics, options.reducerKey || options.moduleKey),
    autoRefresh: 60,
    dataIsNotArray: !Array.isArray(options.initialData || []),
    services: {
      get: () => {}, // Fake function just so GET actions are automatically generated for the module
    },
  })

  if (options.initialData) {
    module.initialState.data = options.initialData
  }

  module.actions.get = (query) =>
    (dispatch, getState) => {
      const eventId = currentEventIdSelector(getState())
      dispatch(module.actions.getStart())

      return options.getter(
        eventId,
        query,
        response => dispatch(module.actions.getSuccess(response)),
        () => dispatch(module.actions.getError())
      )
    }

  module.reducers['CHANGE_EVENT_SUCCESS'] = (state) => ({
    ...state,
    didInvalidate: true,
    data: module.initialState.data,
  })

  module.reducerKey = options.reducerKey || options.moduleKey
  module.getData = module.actions.get
  module.getDataIfNeeded = module.actions.getIfNeeded

  return module
}
