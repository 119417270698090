import Moment from 'moment'
import { showToast } from 'common/modules/toaster'


export default function generatePutServices(actionKeyPrefix, options) {
  const actions = {}
  const reducers = {}

  const startType = `${actionKeyPrefix}_PUT_START`.toUpperCase()
  actions.putStart = args => ({
    type: startType,
    requestTime: Moment().unix(),
    payload: args,
  })

  reducers[startType] = state => ({
    ...state,
    isModifying: true,
    isError: false,
  })


  const successType = `${actionKeyPrefix}_PUT_SUCCESS`.toUpperCase()
  actions.putSuccess = response => ({
    type: successType,
    requestTime: Moment().unix(),
    payload: response,
  })

  reducers[successType] = (state, action) => ({
    ...state,
    isModifying: false,
    data: (() => {
      if (Array.isArray(state.data)) {
        if (!options.idField) return action.payload
        return state.data.map(data => (data[options.idField] === action.payload[options.idField] ? action.payload : data))
      }
      return action.payload
    })(),
    lastUpdated: action.requestTime,
  })


  const errorType = `${actionKeyPrefix}_PUT_ERROR`.toUpperCase()
  actions.putError = errorMessage => ({
    type: errorType,
    requestTime: Moment().unix(),
    payload: errorMessage,
  })

  reducers[errorType] = state => ({
    ...state,
    isModifying: false,
    isError: true,
  })


  actions.put = (...args) =>
    (dispatch, getState) => {
      dispatch(actions.putStart(...args))
      return options.services.put.service(getState, ...args)
        .then(response => {
          dispatch(actions.putSuccess(response))

          if (options.services.put.showOnSuccess) {
            dispatch(showToast({
              title: options.t('put.title'),
              message: (options.services.put.successMessage
                ? options.services.put.successMessage(response, ...args)
                : options.t('put.success')),
              level: 'success',
            }))
          }
        })
        .catch(error => {
          dispatch(actions.putError(error.description))

          if (options.services.put.showOnError) {
            dispatch(showToast({
              title: options.t('put.title'),
              message: (options.services.put.errorMessage
                ? options.services.put.errorMessage(error, ...args)
                : error.description),
              level: 'error',
            }))
          }
        })
    }

  return { actions, reducers }
}
