import { getProperty } from 'common/utils/componentHelper'
import { getTranslatedValue } from './translator'


function sortString(stringA, stringB, order) {
  if (!stringB) return -1
  if (!stringA) return 1
  return order === 1 ? (stringA || '').localeCompare(stringB || '', undefined, { numeric: true }) : (stringB || '').localeCompare(stringA || '', undefined, { numeric: true })
}

function sortNumber(numberA, numberB, order) {
  if (numberB == null) return -1
  if (numberA == null) return 1
  return order === 1 ? numberA - numberB : numberB - numberA
}

function sortBoolean(boolA, boolB, order) {
  return sortNumber(boolA, boolB, order)
}


/**
 * Helper function to sort data on a field, with other fields as equality fallback.
 *
 * @param {*[]} data - Data to sort
 * @param {string[]|Object[]} fields - Fields to sort on
 * @param {string} fields.id - The ID (name) of the field
 * @param {number} fields.order - The order of the field
 * @param {string} fields.type - The type of the field
 */
export default function sort(data = [], fields = []) {
  return [...data].sort((a, b) => {
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i]
      const fieldId = getProperty(field, 'id') || field
      const fieldSortOrder = getProperty(field, 'order') || 1
      const fieldType = getProperty(field, 'type')

      const fieldA = fieldId ? getTranslatedValue(getProperty(a, fieldId)) : getTranslatedValue(a)
      const fieldB = fieldId ? getTranslatedValue(getProperty(b, fieldId)) : getTranslatedValue(b)
      const fieldTypeA = fieldType || typeof fieldA
      const fieldTypeB = fieldType || typeof fieldB
      const isSameType = fieldTypeA === fieldTypeB

      if (fieldA !== fieldB && isSameType) {
        switch (fieldTypeA) {
          case 'number': return sortNumber(fieldA, fieldB, fieldSortOrder)
          case 'string': return sortString(fieldA, fieldB, fieldSortOrder)
          case 'boolean': return sortBoolean(fieldA, fieldB, fieldSortOrder)
          default: console.warn(`Type "${fieldTypeA}" is not specified in the sorting function. Skipping field`)
        }
      } else if (!isSameType) {
        console.warn(`Cannot sort two different types: ${fieldTypeA} and ${fieldTypeB}`)
      }
    }

    return null
  })
}
