import { generateDataModule, invalidateOnEventChange } from 'admin/modules/generateModule'
import { serviceGetDashboardOvertime } from 'admin/services/bluetrace'
import Moment from 'moment'

import { eventTimezoneSelector } from 'admin/modules/events'


const OvertimeModule = generateDataModule({
  initialData: [],
  moduleKey: 'cpo/bluetrace/dashboard/overtime',
  reducerKey: 'admin.bluetrace.dashboard.overtime',
  refreshTime: 10000,
  services: {
    get: { service: serviceGetDashboardOvertime },
  },
})

invalidateOnEventChange(OvertimeModule)


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

OvertimeModule.registerSelector(
  'data',
  (state, moduleState) => moduleState.data,
  data => data.map(d => ({
    date: d.date,
    value: Math.round(d.count),
  }))
)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////

OvertimeModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {
    const state = getState()
    const timezone = eventTimezoneSelector(state)

    const queryParams = {
      start_date: Moment().tz(timezone).startOf('day').unix(),
      end_date: Moment().tz(timezone).endOf('day').unix(),
    }

    dispatch({ type: OvertimeModule.actionKeys.getStart })

    return serviceGetDashboardOvertime(OvertimeModule._getEventId(), queryParams)
      .then(response => {
        dispatch({
          type: OvertimeModule.actionKeys.getSuccess,
          payload: response,
        })
      })
      .catch(() => {
        dispatch({ type: OvertimeModule.actionKeys.getError })
        return Promise.reject()
      })
  }
)


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////


export default OvertimeModule
