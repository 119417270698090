import { DataModule } from 'redux-data-module'
import { serviceGetInfoPopups } from 'attendee/services/infoPopups'
import { handleErrorMessage } from './index'

import { eventIdSelector } from 'attendee/modules/events'


const InfoPopupsModule = new DataModule({
  moduleKey: 'uf/infoPopups',
  reducerKey: 'attendee.infoPopups',

  services: {
    get: { service: serviceGetInfoPopups },
  },
})


const registerPopupSelector = (selectorId, popupType) => {
  InfoPopupsModule.registerSelector(
    selectorId,
    state => InfoPopupsModule.selectors.data(state),
    data => data.find(d => d.active && d.type === popupType)
  )
}
registerPopupSelector('gamificationPopup', 'gamification')
registerPopupSelector('recommendationsPopup', 'networking')
registerPopupSelector('topicsPopup', 'networking_topics')


InfoPopupsModule.actions.get = () => (dispatch, getState) => {
  dispatch({ type: InfoPopupsModule.actionKeys.getStart })

  return serviceGetInfoPopups(eventIdSelector(getState()))
    .then(response => {
      dispatch({ type: InfoPopupsModule.actionKeys.getSuccess, payload: response })
    })
    .catch(error => {
      dispatch({ type: InfoPopupsModule.actionKeys.getError })
      handleErrorMessage(dispatch, getState, error)
      return Promise.reject()
    })
}


export default InfoPopupsModule
