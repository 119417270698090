import React, { Component } from 'react'
import { translate } from 'react-i18next'
import config from 'config'
import i18n from 'i18next'

import ExternalLink from 'common/components/ExternalLink'

/* component styles */
import { styles } from './styles.scss'

@translate(['login'], { wait: true })
export default class Footer extends Component {
  static defaultProps = {
    replyTo: 'support@bizzabo.com',
    emailSubject: 'Issue with the Contact Page',
  }

  render() {
    const { useWhiteLogo } = this.props
    const replyTo = this.props.replyTo ? this.props.replyTo : Footer.defaultProps.replyTo
    const emailSubject = this.props.emailSubject ? this.props.emailSubject : Footer.defaultProps.emailSubject
    const t = this.props.t
    const logoUrl = `/assets/images/klik_bizzabo_logo_${useWhiteLogo ? 'white' : 'black'}.png`

    return (
      <div className={styles + ' footer-wrapper'}>
        <footer id="footer">
          <p id="footer-text">
            <ExternalLink href="http://klik.co" target="_blank" style={{ color: 'white' }}>
              <img src={logoUrl} className="footer-logo" alt="klik" />
            </ExternalLink>
            <span>
              |&nbsp;&nbsp;&nbsp;<ExternalLink href={`mailto:${replyTo}?Subject=${emailSubject}`} target="_blank">Support</ExternalLink>&nbsp;&nbsp;&nbsp;
            </span>
            <span>
              |&nbsp;&nbsp;&nbsp;<ExternalLink href={config.tosLink[i18n.language]} target="_blank">{t('login:tos_link_short')}</ExternalLink>&nbsp;&nbsp;&nbsp;
            </span>
            <span>
              |&nbsp;&nbsp;&nbsp;<ExternalLink href={config.ppLink[i18n.language]} target="_blank">{t('login:pp_link_short')}</ExternalLink>
            </span>
          </p>
        </footer>
      </div>
    )
  }
}
