import React from 'react'


export default function TitleLabel(props) {
  const styling = {
    textTransform: props.uppercase ? 'uppercase' : 'none',
    cursor: props.onClick ? 'pointer' : 'default',
  }

  return (
    <div className="TitleLabel" id={props.id} style={{ ...styling }} onClick={props.onClick}>
      {props.label}
    </div>
  )
}
