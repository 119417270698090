import React, { Component } from 'react'

export default (loadCheck) => (WrappedComponent) => class Proxy extends Component {
  componentWillMount = () => {
    if (loadCheck) {
      window.PageLoader.startLoading()
    }
  }

  componentDidMount() {
    if (!loadCheck) {
      window.PageLoader.stopLoading()
    } else {
      this.checkIfLoaded(this.props)
    }
  }

  componentDidUpdate = (prevProps) => {
    if (loadCheck) {
      if (window.PageLoader.isLoading && loadCheck(this.refToWrappedComponent, this.props, prevProps)) {
        window.PageLoader.stopLoading()
      } else if (!window.PageLoader.isLoading) {
        window.PageLoader.startLoading()
      }
    }
    if (loadCheck && loadCheck(this.refToWrappedComponent, this.props, prevProps)) {
      window.PageLoader.stopLoading()
    }
    this.checkIfLoaded(this.props, prevProps)
  }

  checkIfLoaded = (props, prevProps) => {
    if (loadCheck) {
      if (window.PageLoader.isLoading && loadCheck(this.refToWrappedComponent, props, prevProps)) {
        window.PageLoader.stopLoading()
      } else if (!window.PageLoader.isLoading) {
        window.PageLoader.startLoading()
      }
    }
    if (loadCheck && loadCheck(this.refToWrappedComponent, props, prevProps)) {
      window.PageLoader.stopLoading()
    }
  }

  render = () => <WrappedComponent {...this.props} ref={ref => { this.refToWrappedComponent = ref }} />
}
