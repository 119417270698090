import { BaseModule } from 'redux-data-module'
import { serviceResetPassword } from 'admin/services/users'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'


const AppModule = new BaseModule({
  initialState: {
    isLoaded: false,
  },
  moduleKey: 'cpe/app',
  reducerKey: 'exhibitor.app',
})


AppModule.registerSelector('isLoaded', (state, moduleState) => moduleState.isLoaded, isLoaded => isLoaded)


AppModule.registerAction('setIsLoaded', isLoaded => dispatch => {
  dispatch({ type: AppModule.actionKeys.setIsLoaded, payload: isLoaded })
  return Promise.resolve()
})

AppModule.registerReducer(AppModule.actionKeys.setIsLoaded, (state, action) => ({
  ...state,
  isLoaded: action.payload,
}))


AppModule.registerAction('setPassword', password => dispatch => serviceResetPassword(null, 'me', password)
  .then(() => dispatch({ type: AppModule.actionKeys.setPassword }))
  .catch(error => {
    dispatch(showToast({
      title: i18n.getFixedT(i18n.language, 'cpe_modules')('exhibitors.reset_password.title'),
      message: error.description,
      level: 'error',
      permanent: false,
    }))
    return Promise.reject(error)
  })
)


export default AppModule
