import { serviceGetAllContactInvitations, serviceCreateContactInvitation, serviceAcceptContactInvitation, serviceRejectContactInvitation, serviceReinviteContactInvitation } from 'attendee/services/contactInvitations'
import { DataModule } from 'redux-data-module'
import { listToMapping } from 'common/utils/componentHelper'
import { handleErrorMessage } from './index'
import { eventIdSelector } from './events'


const ContactInvitationsModule = new DataModule({
  moduleKey: 'uf/contactInvitations',
  reducerKey: 'attendee.contactInvitations',

  services: {
    get: { service: serviceGetAllContactInvitations },
  },
});

ContactInvitationsModule.store = 'contactInvitations'
ContactInvitationsModule.app = 'attendee'


ContactInvitationsModule.registerSelector(
  'invitationsByAccountId',
  (state, moduleState) => moduleState.data,
  (invitations) => listToMapping(invitations, 'account.id')
)


ContactInvitationsModule.actions.get = () => (dispatch, getState) => {
  dispatch({ type: ContactInvitationsModule.actionKeys.getStart })
  return serviceGetAllContactInvitations(eventIdSelector(getState()))
    .then(([received, sent]) => {
      dispatch({
        type: ContactInvitationsModule.actionKeys.getSuccess,
        payload: [...received, ...sent],
      })
    })
    .catch((error) => {
      dispatch({ type: ContactInvitationsModule.actionKeys.getError })
      handleErrorMessage(dispatch, getState, error)
    })
}


ContactInvitationsModule.registerActionKey('create')

ContactInvitationsModule.actions.create = (accountId) => (dispatch, getState) =>
  serviceCreateContactInvitation(eventIdSelector(getState()), accountId)
    .then((response) => dispatch({
      type: ContactInvitationsModule.actionKeys.create,
      payload: response,
    }))
    .catch((error) => handleErrorMessage(dispatch, getState, error))

ContactInvitationsModule.reducers[ContactInvitationsModule.actionKeys.create] = (state, action) => ({
  ...state,
  data: [...state.data, { ...action.payload }],
})


ContactInvitationsModule.registerActionKey('accept')

ContactInvitationsModule.actions.accept = (invitationId) => (dispatch, getState) =>
  serviceAcceptContactInvitation(eventIdSelector(getState()), invitationId)
    .then((response) => dispatch({
      type: ContactInvitationsModule.actionKeys.accept,
      payload: response,
    }))
    .catch((error) => handleErrorMessage(dispatch, getState, error))

ContactInvitationsModule.reducers[ContactInvitationsModule.actionKeys.accept] = (state, action) => ({
  ...state,
  data: state.data.map(invitation => (invitation.id === action.payload.id ? { ...action.payload } : invitation)),
})


ContactInvitationsModule.registerActionKey('reject')

ContactInvitationsModule.actions.reject = (invitationId) => (dispatch, getState) =>
  serviceRejectContactInvitation(eventIdSelector(getState()), invitationId)
    .then((response) => dispatch({
      type: ContactInvitationsModule.actionKeys.reject,
      payload: response,
    }))
    .catch((error) => handleErrorMessage(dispatch, getState, error))

ContactInvitationsModule.reducers[ContactInvitationsModule.actionKeys.reject] = (state, action) => ({
  ...state,
  data: state.data.map(invitation => (invitation.id === action.payload.id ? { ...action.payload } : invitation)),
})


ContactInvitationsModule.registerActionKey('reinvite')

ContactInvitationsModule.actions.reinvite = (invitationId) => (dispatch, getState) =>
  serviceReinviteContactInvitation(eventIdSelector(getState()), invitationId)
    .then((response) => dispatch({
      type: ContactInvitationsModule.actionKeys.reinvite,
      payload: response,
    }))
    .catch((error) => handleErrorMessage(dispatch, getState, error))

ContactInvitationsModule.reducers[ContactInvitationsModule.actionKeys.reinvite] = (state, action) => ({
  ...state,
  data: state.data.map(invitation => (invitation.id === action.payload.id ? { ...action.payload } : invitation)),
})


export default ContactInvitationsModule
