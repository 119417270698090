import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetCheckInProgress } from 'admin/services/dashboard'


const CheckInProgressModule = generateDataModule({
  initialData: {
    checkedIn: 0,
    notCheckedIn: 0,
  },
  moduleKey: 'dashboardCheckInProgress',
  reducerKey: 'admin.dashboard.checkInProgress',
  services: {
    get: { service: serviceGetCheckInProgress },
  },
})

CheckInProgressModule.registerSelector(
  'total',
  [
    (state, moduleState) => moduleState.data.checkedIn,
    (state, moduleState) => moduleState.data.notCheckedIn,
  ],
  (checkedInCount, notCheckedInCount) => (checkedInCount || 0) + (notCheckedInCount || 0)
)
CheckInProgressModule.registerSelector('totalCheckIns', (state, moduleState) => moduleState.data.checkedIn, totalCheckIns => totalCheckIns || 0)

CheckInProgressModule.registerDataAction(
  'get',
  () => dispatch => {
    dispatch({ type: CheckInProgressModule.actionKeys.getStart })

    return CheckInProgressModule._getService('get')()
      .then(response => {
        dispatch({ type: CheckInProgressModule.actionKeys.getSuccess, payload: response })
      })
      .catch(() => {
        dispatch({ type: CheckInProgressModule.actionKeys.getError })
      })
  }
)

CheckInProgressModule.registerReducer(
  CheckInProgressModule.actionKeys.getSuccess,
  (state, action) => ({
    ...state,
    data: {
      checkedIn: action.payload.checked_in,
      notCheckedIn: action.payload.not_checked_in,
      total: action.payload.checked_in + action.payload.not_checked_in,
    },
  })
)


export default CheckInProgressModule
