import { addSortingSupport } from './helper'
import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetLeaderboard } from 'admin/services/dashboard'
import sort from 'common/utils/sortHelper'


const LeaderboardModule = generateDataModule({
  initialData: [],
  moduleKey: 'dashboardLeaderboard',
  reducerKey: 'admin.dashboard.leaderboard',
  services: {
    get: { service: serviceGetLeaderboard },
  },
})

addSortingSupport(LeaderboardModule, { id: 'rank', order: 1 })


LeaderboardModule.registerSelector(
  'minimalData',
  (state, moduleState) => moduleState.data,
  attendees => attendees.slice(0, 4)
)

LeaderboardModule.registerSelector(
  'fullData',
  [
    (state, moduleState) => moduleState.data,
    state => LeaderboardModule.selectors.sorter(state),
  ],
  (attendees, sorter) => sort(attendees, [
    { id: sorter.id, order: sorter.order },
    { id: 'score', order: -1 },
    'rank',
    'first_name',
    'last_name',
    'id',
  ])
)


export default LeaderboardModule
