import i18n from 'i18next'

export const ROLES_HIERARCHY = ['full', 'employee', 'organizer_deployer', 'organizer', 'content_manager', 'onsite_manager', 'staff', 'staff_with_impersonation', 'staff_no_create', 'bluetrace_admin', 'bluetrace_tech', 'read_only']

function t(key, extra) {
  return i18n.getFixedT(i18n.language, 'admin_accountRoles')(key, extra)
}

function getIndexOfRole(role) {
  for (let i = 0; i < ROLES_HIERARCHY.length; i++) {
    if (ROLES_HIERARCHY[i] === role) {
      return i
    }
  }

  return -1
}

export function getUserRole(_userRole, eventId) {
  if (_userRole == null) {
    return null
  }

  if (eventId == null) {
    return null
  }

  if (typeof _userRole === 'string') {
    return _userRole
  }

  let role = _userRole['*']
  if (role != null) {
    return role
  }

  role = _userRole[eventId]
  if (role == null) {
    return null
  }

  return role
}

export function getUserRoleId(userRole, eventId) {
  const role = getUserRole(userRole, eventId)
  return role ? role.id : undefined
}

export function getRoleTitle(role) {
  return t(role)
}


export function getRoles() {
  return ROLES_HIERARCHY.map(role => ({
    value: role,
    title: t(role),
  }))
}

export function getLimitedRoles(_userRole, eventId) {
  const userRole = getUserRole(_userRole, eventId)
  if (userRole == null) {
    return []
  }

  return getRoles()
    .slice(getIndexOfRole(userRole))
    .filter((role) => !['full', 'bluetrace_admin', 'bluetrace_tech'].includes(role.value)) // We don't want "full" but we still need to support it
}
