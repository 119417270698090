import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetZoneStats } from 'admin/services/bluetrace'
import Moment from 'moment'

import { eventTimezoneSelector } from 'admin/modules/events'
import TrackingFiltersModule from './filters'


const ZoneStatsModule = generateDataModule({
  initialData: {
    averageDwellTime: undefined,
    peakCount: undefined,
    peakTime: undefined,
    uniqueVisits: undefined,
  },
  moduleKey: 'cpo/bluetrace/zoneStats',
  reducerKey: 'admin.bluetrace.tracking.zoneStats',
  refreshTime: 10000,
  services: {
    get: { service: serviceGetZoneStats },
  },
})


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

ZoneStatsModule.registerSelector(
  'data',
  (state, moduleState) => moduleState.data,
  data => data
)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////

ZoneStatsModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {
    const state = getState()
    const timezone = eventTimezoneSelector(state)
    const dateMoment = Moment.unix(TrackingFiltersModule.selectors.date(state)).tz(timezone)
    const zoneId = TrackingFiltersModule.selectors.zone(state)

    const queryParams = {
      group: TrackingFiltersModule.selectors.attendeeType(state),
      start_date: dateMoment.startOf('day').unix(),
      end_date: dateMoment.endOf('day').unix(),
    }

    dispatch({ type: ZoneStatsModule.actionKeys.getStart })

    return serviceGetZoneStats(ZoneStatsModule._getEventId(), zoneId, queryParams)
      .then(response => {
        dispatch({
          type: ZoneStatsModule.actionKeys.getSuccess,
          payload: response || ZoneStatsModule.initialState.data,
        })
      })
      .catch(() => {
        dispatch({ type: ZoneStatsModule.actionKeys.getError })
        return Promise.reject()
      })
  }
)


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////


export default ZoneStatsModule
