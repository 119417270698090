import { serviceUploadProfiles, serviceUploadProfilesWithMapping, serviceUploadSessions, serviceUploadSessionsWithMapping, serviceUploadPreRegistration, serviceUploadPreRegistrationWithMapping, serviceUploadSpeakers, serviceUploadSpeakersWithMapping, serviceUploadTouchpoints, serviceUploadTouchpointsWithMapping, serviceUploadExhibitors, serviceUploadExhibitorsWithMapping, serviceUploadSessionParticipants, serviceUploadSessionParticipantsWithMapping, serviceUploadAppointments, serviceUploadAppointmentsWithMapping, serviceUploadAppointmentParticipants, serviceUploadAppointmentParticipantsWithMapping } from 'common/services/fileUpload'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'
import { createSelector } from 'reselect'

const selectState = (state) => state.fileUpload

export const moduleSelector = createSelector(selectState, (state) => state)


function getEventId(getState) {
  return getState().admin.events.currentEvent.id
}

function getFile(getState) {
  return getState().fileUpload.fileObject
}

export function clearError() {
  return dispatch => { dispatch({ type: 'CLEAR_FILE_UPLOAD_ERROR' }) }
}

export function clearImportStats() {
  return dispatch => { dispatch({ type: 'CLEAR_IMPORT_STATS' }) }
}

function displayErrorMessage(dispatch, error) {
  dispatch(showToast({
    title: i18n.getFixedT(i18n.language, 'common_module')('upload.csv.title'),
    message: error.description,
    level: 'error',
    permanent: false,
  }))
}

function uploadFileStart(file) {
  return {
    type: 'UPLOAD_FILE_START',
    payload: {
      fileObject: file,
    },
  }
}

function importFileSuccess(data) {
  return {
    type: 'IMPORT_FILE_SUCCESS',
    payload: {
      file: data,
    },
  }
}

function mappingFileSuccess(data) {
  return {
    type: 'MAPPING_FILE_SUCCESS',
    payload: {
      file: data,
    },
  }
}

function uploadFileError(error) {
  return {
    type: 'UPLOAD_FILE_ERROR',
    errorMessage: error,
  }
}


// //////////////////////////////////////////////////////////////////////////////
// Speakers
// //////////////////////////////////////////////////////////////////////////////

export function uploadTouchpoints(file) {
  return (dispatch, getState) => {
    dispatch(uploadFileStart(file))
    return serviceUploadTouchpoints(
      getEventId(getState),
      file,
      (response) => dispatch(mappingFileSuccess(response)),
      (error) => {
        dispatch(uploadFileError())
        displayErrorMessage(dispatch, error)
      }
    )
  }
}

function runImport(service, mapping, dispatch, getState) {
  dispatch(uploadFileStart())

  return service(
    getEventId(getState),
    getFile(getState),
    mapping,
    (response) => {
      dispatch(importFileSuccess(response))
    },
    (error) => {
      dispatch(uploadFileError(error.description))
    }
  )
}

export function uploadTouchpointsWithMapping(mapping) {
  return (dispatch, getState) => runImport(serviceUploadTouchpointsWithMapping, mapping, dispatch, getState)
}


// //////////////////////////////////////////////////////////////////////////////
// Speakers
// //////////////////////////////////////////////////////////////////////////////

export function uploadSpeakers(file) {
  return (dispatch, getState) => {
    dispatch(uploadFileStart(file))
    return serviceUploadSpeakers(
      getEventId(getState),
      file,
      (response) => {
        dispatch(mappingFileSuccess(response))
      },
      (error) => {
        dispatch(uploadFileError())
        displayErrorMessage(dispatch, error)
      }
    )
  }
}

export function uploadSpeakersWithMapping(mapping) {
  return (dispatch, getState) => runImport(serviceUploadSpeakersWithMapping, mapping, dispatch, getState)
}


// //////////////////////////////////////////////////////////////////////////////
// Exhibitors
// //////////////////////////////////////////////////////////////////////////////

export function uploadExhibitors(file) {
  return (dispatch, getState) => {
    dispatch(uploadFileStart(file))
    return serviceUploadExhibitors(
      getEventId(getState),
      file,
      (response) => {
        dispatch(mappingFileSuccess(response))
      },
      (error) => {
        dispatch(uploadFileError())
        displayErrorMessage(dispatch, error)
      }
    )
  }
}

export function uploadExhibitorsWithMapping(mapping) {
  return (dispatch, getState) => runImport(serviceUploadExhibitorsWithMapping, mapping, dispatch, getState)
}


// //////////////////////////////////////////////////////////////////////////////
// Appointments
// //////////////////////////////////////////////////////////////////////////////

export function uploadAppointments(file, calendarId, sessionId) {
  return (dispatch, getState) => {
    dispatch(uploadFileStart(file))
    return serviceUploadAppointments(
      getEventId(getState),
      file,
      calendarId,
      sessionId,
      response => dispatch(mappingFileSuccess(response)),
      error => {
        dispatch(uploadFileError())
        displayErrorMessage(dispatch, error)
      }
    )
  }
}

export function uploadAppointmentsWithMapping(mapping, calendarId, sessionId) {
  return (dispatch, getState) => {
    const service = (eventId, file, _mapping, response, error) => serviceUploadAppointmentsWithMapping(
      eventId, file, calendarId, sessionId, _mapping, response, error)
    return runImport(service, mapping, dispatch, getState)
  }
}

export function uploadAppointmentParticipants(file, calendarId, sessionId) {
  return (dispatch, getState) => {
    dispatch(uploadFileStart(file))
    return serviceUploadAppointmentParticipants(
      getEventId(getState),
      file,
      calendarId,
      sessionId,
      response => dispatch(mappingFileSuccess(response)),
      error => {
        dispatch(uploadFileError())
        displayErrorMessage(dispatch, error)
      }
    )
  }
}

export function uploadAppointmentParticipantsWithMapping(mapping, calendarId, sessionId) {
  return (dispatch, getState) => {
    const service = (eventId, file, _mapping, response, error) => serviceUploadAppointmentParticipantsWithMapping(
      eventId, file, calendarId, sessionId, _mapping, response, error)
    return runImport(service, mapping, dispatch, getState)
  }
}

// //////////////////////////////////////////////////////////////////////////////
// Sessions
// //////////////////////////////////////////////////////////////////////////////

export function uploadSessions(file) {
  return (dispatch, getState) => {
    dispatch(uploadFileStart(file))
    return serviceUploadSessions(
      getEventId(getState),
      file,
      (response) => {
        dispatch(mappingFileSuccess(response))
      },
      (error) => {
        dispatch(uploadFileError())
        displayErrorMessage(dispatch, error)
      }
    )
  }
}

export function uploadSessionsWithMapping(mapping, calendarId) {
  return (dispatch, getState) => {
    const service = (eventId, file, _mapping, response, error) => serviceUploadSessionsWithMapping(
      eventId, file, calendarId, _mapping, response, error
    )
    return runImport(service, mapping, dispatch, getState)
  }
}

export function uploadPreRegistration(file) {
  return (dispatch, getState) => {
    dispatch(uploadFileStart(file))
    return serviceUploadPreRegistration(
      getEventId(getState),
      file,
      (response) => {
        dispatch(mappingFileSuccess(response))
      },
      (error) => {
        dispatch(uploadFileError())
        displayErrorMessage(dispatch, error)
      }
    )
  }
}

export function uploadPreRegistrationWithMapping(mapping, calendarId) {
  return (dispatch, getState) => {
    const service = (eventId, file, _mapping, response, error) => serviceUploadPreRegistrationWithMapping(
      eventId, file, calendarId, _mapping, response, error
    )
    return runImport(service, mapping, dispatch, getState)
  }
}

export function uploadSessionParticipants(calendarId, sessionId, relation, file) {
  return (dispatch, getState) => {
    dispatch(uploadFileStart(file))
    return serviceUploadSessionParticipants(
      getEventId(getState),
      calendarId,
      sessionId,
      relation,
      file,
      (response) => {
        dispatch(mappingFileSuccess(response))
      },
      (error) => {
        dispatch(uploadFileError())
        displayErrorMessage(dispatch, error)
      }
    )
  }
}

export function uploadSessionParticipantsWithMapping(calendarId, sessionId, relation, mapping) {
  return (dispatch, getState) => {
    const service = (eventId, file, _mapping, response, error) => serviceUploadSessionParticipantsWithMapping(
      eventId, calendarId, sessionId, relation, file, _mapping, response, error)
    return runImport(service, mapping, dispatch, getState)
  }
}

// //////////////////////////////////////////////////////////////////////////////
// Attendees
// //////////////////////////////////////////////////////////////////////////////

export function uploadAttendees(file) {
  return (dispatch, getState) => {
    dispatch(uploadFileStart(file))

    return serviceUploadProfiles(
      getEventId(getState),
      file,
      (response) => {
        dispatch(mappingFileSuccess(response))
      },
      (error) => {
        dispatch(uploadFileError())
        displayErrorMessage(dispatch, error)
      }
    )
  }
}


export function uploadAttendeesWithMapping(mapping) {
  return (dispatch, getState) => runImport(serviceUploadProfilesWithMapping, mapping, dispatch, getState)
}


const initialState = {
  isLoading: false,
  isLoaded: false,
  isError: false,
  lastOperationStatus: '',
  available: [],
  example: [],
  headers: [],
  fileObject: {},
  importStats: undefined,
  errorMessage: undefined,
}

export default function fileUpload(state = initialState, action) {
  switch (action.type) {
    case 'UPLOAD_FILE_START':
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        errorMessage: undefined,
        fileObject: action.payload.fileObject ? action.payload.fileObject : state.fileObject,
        importStats: undefined,
        lastOperationStatus: 'POST',
      }

    case 'MAPPING_FILE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        errorMessage: undefined,
        importStats: undefined,
        available: action.payload.file.available || [],
        example: action.payload.file.example || [],
        headers: action.payload.file.headers || [],
      }

    case 'IMPORT_FILE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        errorMessage: undefined,
        importStats: action.payload.file,
      }

    case 'UPLOAD_FILE_ERROR':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: true,
        errorMessage: action.errorMessage,
      }

    case 'UPLOAD_FILE_CLEAR':
      return {
        ...initialState,
        importStats: action.payload.importStats || initialState.importStats,
        errorMessage: state.errorMessage,
      }

    case 'CLEAR_IMPORT_STATS':
      return {
        ...initialState,
        importStats: undefined,
        errorMessage: undefined,
      }

    case 'CLEAR_FILE_UPLOAD_ERROR':
      return {
        ...state,
        errorMessage: undefined,
        importStats: undefined,
      }

    default:
      return state
  }
}
