import React, { Component } from 'react'
import cssVars from 'css-vars-ponyfill';

export function updateCSSVariables(properties) {
  cssVars({ variables: properties })
}


export default function updateCSSProperties(properties) {
  return (WrappedComponent) => {
    class PropsProxy extends Component {
      componentDidMount() {
        updateCSSVariables(properties)
      }

      componentWillUnmount() {
        const nullProperties = {}
        Object.keys(properties).forEach(key => { nullProperties[key] = null })
        updateCSSProperties(nullProperties)
      }

      render() {
        return <WrappedComponent {...this.props} />
      }
    }

    return PropsProxy
  }
}
