import { serviceGetCalendars, servicePostCalendar, serviceDeleteCalendar, servicePutCalendar } from 'admin/services/sessions'
import { listToMapping } from 'common/utils/componentHelper'
import generateModule from 'common/utils/generateModule'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'
import { getTranslatedValue } from 'common/utils/translator'
import { createSelector } from 'reselect'

const selectCalendars = state => state.admin.calendars.data
const selectSelectedCalendarId = state => state.admin.calendars.selectedCalendarId

export const calendarsByIdSelector = createSelector(
  selectCalendars,
  calendars => listToMapping(calendars, 'id')
)

export const selectedCalendarSelector = createSelector(
  calendarsByIdSelector,
  selectSelectedCalendarId,
  (calendarsById, calendarId) => calendarsById[calendarId]
)


const { actions, reducers, initialState } = generateModule({
  itemName: 'calendar',
  services: {
    get: serviceGetCalendars,
    add: servicePostCalendar,
    upd: servicePutCalendar,
    del: serviceDeleteCalendar,
  },
  optimisticUpdate: false,
})

initialState.selectedCalendarId = undefined

actions.selectCalendar = (calendarId) =>
  dispatch => {
    dispatch({ type: 'SELECT_CALENDAR', payload: { calendarId } })
    return Promise.resolve()
  }

reducers['SELECT_CALENDAR'] = (state, action) => ({ ...state, selectedCalendarId: action.payload.calendarId })


actions.add = (calendar) =>
  ((dispatch, getState) => {
    dispatch(actions.addStart(calendar))
    return servicePostCalendar(
      getState().admin.events.currentEvent.id,
      calendar,
      response => {
        dispatch(actions.addSuccess(response))
        dispatch(actions.get())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('calendars.create.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('calendars.create.success', { name: getTranslatedValue(response.name) }),
          level: 'success',
          permanent: false,
        }))
        return Promise.resolve(response)
      },
      error => {
        dispatch(actions.addError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('calendars.create.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  })

actions.update = (calendar) =>
  ((dispatch, getState) => {
    dispatch(actions.updateStart(calendar))
    return servicePutCalendar(
      getState().admin.events.currentEvent.id,
      calendar,
      response => {
        dispatch(actions.updateSuccess(response))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('calendars.save.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('calendars.save.success', { name: getTranslatedValue(response.name) }),
          level: 'success',
          permanent: false,
        }))
        return Promise.resolve(response)
      },
      error => {
        dispatch(actions.updateError())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('calendars.save.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  })


reducers['CHANGE_EVENT_SUCCESS'] = (state) => ({ ...state, didInvalidate: true })


export const getCalendars = actions.get
export const getCalendarsIfNeeded = actions.getIfNeeded
export const createCalendar = actions.add
export const saveCalendar = actions.update
export const deleteCalendar = actions.delete
export const reorderCalendars = actions.reorderCalendars
export const selectCalendar = actions.selectCalendar

const calendars = reducers.index
export default calendars
