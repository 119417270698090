const loadedFonts = {}

function loadFont(name, url) {
  // Prevent loading the font uselessly
  if (Object.hasOwnProperty.call(loadedFonts, name)) {
    return Promise.resolve()
  }

  const font = new FontFace(name, `url(${url})`)
  return font.load().then((loadedFont) => {
    document.fonts.add(loadedFont)
    loadedFonts[name] = url
  })
}


/*
| @param {object[]} fonts - Fonts to load.
| @param {string} fonts[].name - Name of the font to load.
| @param {string} fonts[].url - URL to fetch the font.
| @returns {Promise} Resolves when all the fonts are loaded
*/
export function loadFonts(fonts) {
  return Promise.all([...fonts.map(font => loadFont(font.name, font.url))])
}
