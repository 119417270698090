import { combineReducers } from 'redux'

import profileCompletion from './profileCompletion'
import socialNetworks from './socialNetworks'
import clientStats from './clientStats'

const onlineEngagement = combineReducers({
  profileCompletion,
  socialNetworks,
  clientStats,
})

export default onlineEngagement
