import { getProperty, listToMapping } from 'common/utils/componentHelper'


export default function initializeReducers(MapGraphicsModule) {
  const getMapState = (state, mapId) => state[mapId] || { ...MapGraphicsModule._initialMapState }

  const getMapStateFromAction = (state, action) => (action.type.startsWith(MapGraphicsModule.actionKeyPrefix)
    ? getMapState(state, getProperty(action, 'payload.mapId'))
    : null)

  const updateStateSelectionOnNewData = (state, itemsById, type) => {
    const updatedState = { ...state }
    Object.keys(updatedState).forEach(mapId => {
      updatedState[mapId].selection = updatedState[mapId].selection.filter(selected =>
        selected.type !== type || itemsById[selected.dataId] != null
      )
    })
    return updatedState
  }


  MapGraphicsModule.registerReducer(
    'ADD_VENUE_MAP_SUCCESS',
    (state, action) => ({
      ...state,
      [action.payload.map.id]: { ...getMapState(state, action.payload.map.id) },
    })
  )

  MapGraphicsModule.registerReducer(
    'GET_VENUEMAPS_SUCCESS',
    (state, action) => {
      const mapStates = {}
      action.payload.data.forEach(map => { mapStates[map.id] = getMapState(state, map.id) })
      return { ...state, ...mapStates }
    }
  )

  MapGraphicsModule.registerReducer(
    'GET_LOCATIONS_SUCCESS',
    (state, action) => ({
      ...updateStateSelectionOnNewData(
        state,
        listToMapping(action.payload.data, 'name'),
        'zone',
      ),
    })
  )

  MapGraphicsModule.registerReducer(
    'GET_INFRAS_SUCCESS',
    (state, action) => ({
      ...updateStateSelectionOnNewData(
        state,
        listToMapping(action.payload.data, 'id'),
        'infra',
      ),
    })
  )

  MapGraphicsModule.registerReducer(
    'UPDATE_INFRA_SUCCESS',
    (state, action) => {
      const matchingMapState = { ...getMapState(state, action.payload.data.map) }
      if (!matchingMapState || matchingMapState.selection) return { ...state }

      return {
        ...state,
        [action.payload.data.map]: {
          ...matchingMapState,
          selection: { dataId: action.payload.dataId, type: 'infra' },
        },
      }
    }
  )

  MapGraphicsModule.registerReducer(
    'FloorPlan/RESET',
    (state) => {
      const mapStates = {}
      Object.keys(state).forEach(mapId => { mapStates[mapId] = { ...MapGraphicsModule._initialMapState } })
      return { ...state, ...mapStates }
    }
  )

  MapGraphicsModule.registerReducer(
    MapGraphicsModule.actionKeys.setDrawingMode,
    (state, action, mapState) => ({
      ...state,
      [action.payload.mapId]: {
        ...mapState,
        drawingMode: action.payload.mode,
      },
    }),
    (state, action) => getMapStateFromAction(state, action)
  )

  MapGraphicsModule.registerReducer(
    MapGraphicsModule.actionKeys.setSelection,
    (state, action, mapState) => ({
      ...state,
      [action.payload.mapId]: {
        ...mapState,
        selection: [...action.payload.selection],
      },
    }),
    (state, action) => getMapStateFromAction(state, action)
  )

  MapGraphicsModule.registerReducer(
    MapGraphicsModule.actionKeys.addToSelection,
    (state, action, mapState) => ({
      ...state,
      [action.payload.mapId]: {
        ...mapState,
        selection: [
          ...mapState.selection,
          action.payload.selection,
        ],
      },
    }),
    (state, action) => getMapStateFromAction(state, action)
  )

  MapGraphicsModule.registerReducer(
    MapGraphicsModule.actionKeys.removeFromSelection,
    (state, action, mapState) => ({
      ...state,
      [action.payload.mapId]: {
        ...mapState,
        selection: state[action.payload.mapId].selection.filter(
          s => !(s.type === action.payload.type && s.dataId === action.payload.dataId)
        ),
      },
    }),
    (state, action) => getMapStateFromAction(state, action)
  )

  MapGraphicsModule.registerReducer(
    MapGraphicsModule.actionKeys.clearSelection,
    (state, action, mapState) => ({
      ...state,
      [action.payload]: {
        ...mapState,
        selection: MapGraphicsModule._initialMapState.selection,
      },
    }),
    (state, action) => getMapState(state, action.payload)
  )
}
