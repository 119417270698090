const doesSupportServiceWorker = ('serviceWorker' in navigator)
/* eslint-disable no-unused-vars */
const forceUpdate = 2;
/* eslint-enable no-unused-vars */

export const EventTypes = {
  updateFound: '__sw_update_found__',
}

export function requestContentUpdate() {
  if (!doesSupportServiceWorker) {
    return
  }

  navigator.serviceWorker.ready.then(registration => {
    const worker = registration.waiting || registration.installing
    worker.postMessage({ type: 'SKIP_WAITING' })
  })
}

export function checkForUpdate() {
  if (!doesSupportServiceWorker) {
    return
  }

  navigator.serviceWorker.ready.then(registration => {
    registration.update()
  })
}


let refreshing // To prevent multiple refresh issue

function dispatchUpdateFound() {
  window.dispatchEvent(new CustomEvent(EventTypes.updateFound))
}

function trackInstall(worker) {
  worker.addEventListener('statechange', () => {
    if (worker.state === 'installed') {
      dispatchUpdateFound(worker)
    }
  })
}

if (doesSupportServiceWorker) {
  const hasInitialServiceWorker = !!navigator.serviceWorker.controller

  window.addEventListener('load', () => {
    // Fires when a new service worker has skipped waiting and became the new active worker
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (refreshing || !hasInitialServiceWorker) return
      refreshing = true
      window.location.reload()
    })

    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        if (!navigator.serviceWorker.controller) {
          return
        }

        if (registration.waiting && hasInitialServiceWorker) {
          dispatchUpdateFound(registration.waiting)
        } else if (registration.installing) {
          trackInstall(registration.installing)
        } else {
          registration.addEventListener('updatefound', () => {
            trackInstall(registration.installing)
          })

          registration.update()
        }
      })
      .catch(error => {
        console.error('[SW] Registration failed:', error);
      })
  })
}
