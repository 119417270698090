const cannotConnect = {
  backend: 'Cannot connect to backend',
  locator: 'Cannot connect to locator',
}

export function buildError(error, connection = 'backend') {
  if (error.response) {
    const status = error.response.status || undefined
    const data = error.response.data || {}
    data.description = data.description || data.message || 'An internal error occured'
    return { status: status, ...data }
  }

  if (error.stack) {
    return { description: cannotConnect[connection], offline: true }
  }

  console.error('Unknown type of error thrown during service request')
  throw error
}

export function apiErrorHandler(handler, connection) {
  return (error) => handler(buildError(error, connection))
}

export function apiCatchError(error) {
  throw error
}

function encodeQueryParam(param) {
  if (Array.isArray(param)) {
    return param.map((p) => encodeQueryParam(p)).join(',')
  } else if (typeof (param) === 'object') {
    const encodedParam = {}
    Object.keys(param).forEach((key) => {
      encodedParam[key] = encodeQueryParam(param[key])
    })
    return JSON.stringify(encodedParam)
  }
  return encodeURIComponent(param)
}

export function parseQuery(query) {
  let queryString = ''

  Object.keys(query).forEach((key) => {
    const param = encodeQueryParam(query[key])

    if (param) {
      queryString += `&${key}=${param}`
    }
  })

  return queryString
}
