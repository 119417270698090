import fullAccessPermissions from './FullAccess'

export default {
  action: {
    ...fullAccessPermissions.action,
  },

  menu: {
    ...fullAccessPermissions.menu,
  },

  page: {
    ...fullAccessPermissions.page,
    apiDocs: false,
    broadcast: false,
    exports: false,
    platformNews: false,
  },
}
