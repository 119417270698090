import { generateTableModule } from 'admin/modules/generateModule'
import { serviceGetZoneList } from 'admin/services/bluetrace'
import Moment from 'moment'

import { eventTimezoneSelector } from 'admin/modules/events'


const ZoneListModule = generateTableModule({
  initialSort: {
    id: 'current_score',
    order: 1,
  },
  module: {
    initialData: [],
    initialState: {
      columnsVisibility: {
        chevron: true,
        current_score: true,
        display_name: true,
        icon: true,
        previous_score: true,
      },
      pagination: {
        per_page: 5,
      },
    },
    moduleKey: 'cpo/bluetrace/zoneContagion/zoneList',
    reducerKey: 'admin.bluetrace.zoneContagion.zoneList',
    refreshTime: 10000,
    services: {
      get: { service: serviceGetZoneList },
    },
  },
  needsDataRefreshOnQueryChanges: true,
})


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////

ZoneListModule.registerDataAction('get', () => (dispatch, getState) => {
  const state = getState()
  const timezone = eventTimezoneSelector(state)

  const queryParams = {
    ...ZoneListModule.selectors.queryParams(state),
    end_date: Moment().tz(timezone).endOf('day').unix(),
    start_date: Moment().tz(timezone).startOf('day').subtract(6, 'days').unix(),
  }

  return ZoneListModule._getService('get')(queryParams)
    .then(response => dispatch({
      type: ZoneListModule.actionKeys.getSuccess,
      payload: {
        data: response.data,
        pagination: response.pagination,
      },
    }))
    .catch(error => {
      dispatch({ type: ZoneListModule.actionKeys.getError })
      return Promise.reject(error)
    })
})


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////


export default ZoneListModule
