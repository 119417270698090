import { serviceGetEventSectionFields, serviceModifyEventSectionFields } from 'admin/services/events'
import { serviceCreateGroupings } from 'admin/services/groupings'
import { showToast } from 'common/modules/toaster'
import { getEventConfig } from 'admin/modules/events'
import generateModule from 'common/utils/generateModule'
import i18n from 'i18next'


const { actions, reducers } = generateModule({
  itemName: 'accountGroup',
  services: {
    get: serviceGetEventSectionFields,
    mod: serviceModifyEventSectionFields,
  },
})

actions.get = () =>
  ((dispatch, getState) => {
    dispatch(actions.getStart())
    serviceGetEventSectionFields(
      getState().admin.events.currentEvent.id,
      (result) => {
        dispatch(actions.getSuccess(result.fields.account_groups))
      },
      (error) => {
        dispatch(actions.getError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('account_groups.get.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  })

actions.modify = (data) =>
  ((dispatch, getState) => {
    dispatch(actions.modifyStart(data))
    return serviceModifyEventSectionFields(
      getState().admin.events.currentEvent.id,
      { account_groups: data },
      () => {
        dispatch(actions.modifySuccess())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('account_groups.modify.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('account_groups.modify.success'),
          name: 'modify_account_groups_success',
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch(actions.modifyError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('account_groups.modify.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
        dispatch(actions.get())
      }
    )
  })

function createStart() {
  return {
    type: 'ACCOUNT_GROUPS_CREATE_START',
  }
}

function createSuccess() {
  return {
    type: 'ACCOUNT_GROUPS_CREATE_SUCCESS',
  }
}

function createError() {
  return {
    type: 'ACCOUNT_GROUPS_CREATE_ERROR',
  }
}

actions.create = () =>
  ((dispatch, getState) => {
    dispatch(createStart())
    const eventId = getState().admin.events.currentEvent.id
    serviceCreateGroupings(
      eventId,
      () => {
        dispatch(createSuccess())
        dispatch(getEventConfig(eventId, 'fields'))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('account_groups.create.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('account_groups.create.success'),
          name: 'create_account_groups_success',
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch(createError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('account_groups.create.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  })

reducers['ACCOUNT_GROUPS_CREATE_START'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isModifying: true,
})

reducers['ACCOUNT_GROUPS_CREATE_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isModifying: false,
})

reducers['ACCOUNT_GROUPS_CREATE_ERROR'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: false,
})


reducers['CHANGE_EVENT_SUCCESS'] = (state) => ({ ...state, didInvalidate: true })


export const getAccountGroups = actions.get
export const getAccountGroupsIfNeeded = actions.getIfNeeded
export const modifyAccountGroups = actions.modify
export const createAccountGroups = actions.create
const accountGroups = reducers.index
export default accountGroups
