import axios from 'axios'
import { buildError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { listToMapping } from 'common/utils/componentHelper'
import { getAxiosConfig } from './helper'


export function translateEvent(event) {
  const data = { ...event }

  data.description = getTranslatedObject(data.description)
  data.location = getTranslatedObject(data.location)
  data.title = getTranslatedObject(data.title)
  data.date_label = getTranslatedObject(data.date_label)

  return data
}


export function serviceGetEvents() {
  return axios.get('/events?exhibitor=true', getAxiosConfig())
    .then(response => {
      const events = response.data.map(translateEvent)
      return listToMapping(events, 'id')
    })
    .catch(error => Promise.reject(buildError(error)))
}
