import { DataModule } from 'redux-data-module'
import { serviceAcceptVideoCallInvitation, serviceCreateVideoCallInvitation, serviceGetVideoCallInvitations, serviceRejectVideoCallInvitation } from 'attendee/services/videoCallInvitations'
import { getProperty, listToMapping } from 'common/utils/componentHelper'
import { handleErrorMessage } from './index'
import sort from 'common/utils/sortHelper'
import { NetworkingHelper } from 'attendee/utils'

import { eventIdSelector } from 'attendee/modules/events'
import { accountIdSelector } from 'attendee/modules/profile'


const VideoCallInvitationsModule = new DataModule({
  moduleKey: 'uf/videoCallInvitations',
  reducerKey: 'attendee.videoCallInvitations',
  services: {
    get: { service: serviceGetVideoCallInvitations },
  },
})


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

VideoCallInvitationsModule.registerSelector(
  'invitationsByAccountId',
  (state, moduleState) => moduleState.data,
  invitations => {
    const invitationsByAccountId = {}

    for (let i = 0; i < invitations.length; i++) {
      const accountId = getProperty(invitations[i], 'account.id')
      if (accountId) {
        if (!invitationsByAccountId[accountId]) {
          invitationsByAccountId[accountId] = []
        }

        invitationsByAccountId[accountId].push(invitations[i])
      }
    }

    Object.keys(invitationsByAccountId).forEach(accountId => {
      invitationsByAccountId[accountId] = sort(invitationsByAccountId[accountId], [{ id: 'date', order: -1 }, 'id'])
    })

    return invitationsByAccountId
  }
)

VideoCallInvitationsModule.registerSelector(
  'videoInvitationsById',
  (state, moduleState) => moduleState.data,
  invitations => {
    const videoConversationsById = {}

    invitations.forEach(invitation => {
      videoConversationsById[invitation.to_profile.id] = invitation
    })
    return videoConversationsById
  }
)

VideoCallInvitationsModule.registerSelector(
  'pendingInvitations',
  [
    (state, moduleState) => moduleState.data,
    state => accountIdSelector(state),
  ],
  (invitations, ownProfileId) => {
    const pendingInvitations = invitations.filter(invitation => !invitation.ignored && !invitation.accepted && invitation.from_profile.id !== ownProfileId)
    return sort(pendingInvitations, [{ id: 'date', order: -1 }, 'id'])
  }
)

VideoCallInvitationsModule.registerSelector(
  'pendingInvitationsByAccountId',
  state => VideoCallInvitationsModule.selectors.pendingInvitations(state),
  pendingInvitations => listToMapping(pendingInvitations, 'account.id')
)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////

VideoCallInvitationsModule.registerDataAction('get', () => (dispatch, getState) => {
  dispatch({ type: VideoCallInvitationsModule.actionKeys.getStart })

  return serviceGetVideoCallInvitations(eventIdSelector(getState()))
    .then(response => {
      dispatch({ type: VideoCallInvitationsModule.actionKeys.getSuccess, payload: response })
    })
    .catch(error => {
      dispatch({ type: VideoCallInvitationsModule.actionKeys.getError })
      handleErrorMessage(dispatch, getState, error)
      return Promise.reject()
    })
})

VideoCallInvitationsModule.registerDataAction('accept', invitationId => (dispatch, getState) => {
  dispatch({ type: VideoCallInvitationsModule.actionKeys.acceptStart, payload: invitationId })

  return serviceAcceptVideoCallInvitation(eventIdSelector(getState()), invitationId)
    .then(response => {
      dispatch({ type: VideoCallInvitationsModule.actionKeys.acceptSuccess, payload: response })
    })
    .catch(error => {
      dispatch({ type: VideoCallInvitationsModule.actionKeys.acceptError })
      handleErrorMessage(dispatch, getState, error)
      return Promise.reject()
    })
})

VideoCallInvitationsModule.registerDataAction('reject', invitationId => (dispatch, getState) => {
  dispatch({ type: VideoCallInvitationsModule.actionKeys.rejectStart, payload: invitationId })

  return serviceRejectVideoCallInvitation(eventIdSelector(getState()), invitationId)
    .then(response => {
      dispatch({ type: VideoCallInvitationsModule.actionKeys.rejectSuccess, payload: response })
    })
    .catch(error => {
      dispatch({ type: VideoCallInvitationsModule.actionKeys.rejectError })
      handleErrorMessage(dispatch, getState, error)
      return Promise.reject()
    })
})

VideoCallInvitationsModule.registerDataAction('create', accountId => (dispatch, getState) => {

  dispatch({ type: VideoCallInvitationsModule.actionKeys.createStart, payload: accountId })

  return serviceCreateVideoCallInvitation(eventIdSelector(getState()), accountId)
    .then(response => {
      dispatch({ type: VideoCallInvitationsModule.actionKeys.createSuccess, payload: response })
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({ type: VideoCallInvitationsModule.actionKeys.createError })
      handleErrorMessage(dispatch, getState, error)
      return Promise.reject()
    })
})

VideoCallInvitationsModule.registerAction('receivedFromEvent', invitation => (dispatch, getState) => {
  const invitationsById = VideoCallInvitationsModule.selectors.dataById(getState())

  if (invitation && !invitationsById[invitation.id]) {
    dispatch({
      type: VideoCallInvitationsModule.actionKeys.receivedFromEvent,
      payload: {
        ...invitation,
        account: invitation.account || NetworkingHelper.getInvitationProfiles(invitation).otherProfile,
      },
    })
  }

  return Promise.resolve()
})


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////

function findIndexOfInvitation(stateData, invitationId) {
  for (let i = 0; i < stateData.length; i++) {
    if (stateData[i].id === invitationId) {
      return i
    }
  }
  return -1
}

function updateInvitation(state, invitation) {
  const invitationIndex = findIndexOfInvitation(state.data, invitation.id)
  if (invitationIndex !== -1) {
    state.data[invitationIndex] = { ...state.data[invitationIndex], ...invitation } // eslint-disable-line no-param-reassign
  } else {
    state.data.push(invitation)
  }
}

VideoCallInvitationsModule.registerReducer(
  VideoCallInvitationsModule.actionKeys.acceptSuccess,
  (state, action) => {
    const updatedState = { ...state }
    updateInvitation(state, action.payload)
    return updatedState
  }
)

VideoCallInvitationsModule.registerReducer(
  VideoCallInvitationsModule.actionKeys.rejectSuccess,
  (state, action) => {
    const updatedState = { ...state }
    updateInvitation(state, action.payload)
    return updatedState
  }
)

VideoCallInvitationsModule.registerReducer(
  VideoCallInvitationsModule.actionKeys.createSuccess,
  (state, action) => ({
    ...state,
    data: [...state.data, action.payload],
  })
)

VideoCallInvitationsModule.registerReducer(
  VideoCallInvitationsModule.actionKeys.receivedFromEvent,
  (state, action) => ({
    ...state,
    data: [...state, action.payload],
  })
)


export default VideoCallInvitationsModule
