import React, { Component } from 'react'
import secured from 'common/utils/authCheck'
import { getProperty } from 'common/utils/componentHelper'
import { getQueryParam } from 'common/components/Link'

import { Redirect, Route, Switch } from 'react-router-dom'
import SecuredRoute from './SecuredRoute'

import LoadingIcon from 'common/components/LoadingIcon'

import AdminAppSelector from 'common/containers/AdminAppSelector'
import App from 'common/containers/App'
import ExportContacts from 'common/containers/ExportContacts'
import FullscreenHeatmap from 'admin/containers/FullscreenHeatmap'
import Login from 'common/containers/Login'
import NotFound, { NotFoundRedirect } from 'common/containers/NotFound'


const AppAdmin = React.lazy(() => import(/* webpackChunkName: "AdminApp" */ 'admin/containers/AppAdmin'))
const AppAttendee = React.lazy(() => import(/* webpackChunkName: "AttendeeApp" */ 'attendee/containers/AppAttendee'))
const AppExhibitor = React.lazy(() => import(/* webpackChunkName: "ExhibitorApp" */ 'exhibitor/containers/App'))
const TVLeaderboard = React.lazy(() => import(/* webpackChunkName: "TVLeaderboard" */ 'admin/containers/TVLeaderboard'))


export const buildRoute = (base, path) => (base.charAt(base.length - 1) === '/'
  ? `${base.slice(0, -1)}${path}`
  : `${base}${path}`
)

export const SecuredComponent = (component, isLoginPage) => secured(component, isLoginPage)


export const AppRoutes = () => {
  // Special case to handle social networks redirects. Without it, it redirects to /login
  // and then we lose that "code" query param and the popup never closes.
  if ((window.location.pathname === '/' || window.location.pathname === '/_=_') && getQueryParam(window.location, 'code')) {
    return null
  }

  return (
    <Switch>
      <Redirect exact from="/webview_native" to="/webview_native/login" />
      <Redirect exact from="/webview" to="/webview/login" />
      <Redirect exact from="/" to="/login" />

      <Route path="/webview_native" component={App} />
      <Route path="/webview" component={App} />
      <Route render={(props) => (getProperty(props, 'location.state.is404') ? <NotFound {...props} /> : <App {...props} />)} />
    </Switch>
  )
}


export const CommonRoutes = (match) => (
  <React.Suspense fallback={<LazyLoadIndicator />}>
    <Switch>
      <Redirect from={buildRoute(match.path, '/login/:event_id')} to={buildRoute(match.path, '/:event_id')} />
      <SecuredRoute exact path={buildRoute(match.path, '/login')} component={Login} match={match} isLoginPage />

      <SecuredRoute exact path={buildRoute(match.path, '/admin/events/:event_id/heatmap/:map_id')} component={FullscreenHeatmap} match={match} />
      <SecuredRoute path={buildRoute(match.path, '/admin/events/:event_id?')} component={AppAdmin} match={match} />
      <SecuredRoute path={buildRoute(match.path, '/admin/exhibitors/:event_id?/:exhibitor_id?')} component={AppExhibitor} match={match} />
      <SecuredRoute exact path={buildRoute(match.path, '/admin')} component={AdminAppSelector} match={match} />

      <SecuredRoute exact path={buildRoute(match.path, '/events/:event_id/exportcontacts')} component={ExportContacts} match={match} />
      <SecuredRoute path={buildRoute(match.path, '/events/:event_id?')} component={AppAttendee} match={match} />

      <Redirect exact from={buildRoute(match.path, '/leaderboard/:event_id')} to={buildRoute(match.path, '/leaderboard/:event_id/en')} />
      <SecuredRoute exact path={buildRoute(match.path, '/leaderboard/:event_id/:language')} component={TVLeaderboard} match={match} />

      <SecuredRoute exact path={buildRoute(match.path, '/:event_id')} component={Login} match={match} isLoginPage />

      <Route component={NotFoundRedirect} />
    </Switch>
  </React.Suspense>
)


class LazyLoadIndicator extends Component {
  render() {
    return (
      <div className="LazyLoadIndicator">
        <LoadingIcon />
      </div>
    )
  }
}
