import axios from 'axios'
import { buildError } from 'common/utils/api'
import { getAxiosConfig } from './helper'

export function serviceGetCustomPages(eventId) {
  return axios.get(`/events/${eventId}/attendees/me/custom_resources?adjacents=true`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

