import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getDatesUniqueDays } from 'common/utils/componentHelper'
import { getAxiosConfig, buildQueryParams } from './helper'
import { translateSession } from './sessions'
import { translateAttendee } from './users'
import { translateEvent } from './events'


function buildAnalyticsParams(query) {
  const _query = { ...query }
  if (_query.date) { // date must already be localized to timezone, and start of the day desired
    _query.start_date = _query.date
    _query.end_date = _query.date + (24 * 60 * 60)
    delete _query.date
  }

  const params = Object.keys(_query).filter(key => _query[key]).map(key =>
    encodeURIComponent(key) + '=' + encodeURIComponent(_query[key])
  ).join('&')

  return params === '' ? params : '?' + params
}

export function serviceGetEventSum(eventId, statType, query, success, error) {
  return axios.get(`/events/${eventId}/stats/${statType}/sum${buildAnalyticsParams(query)}`, getAxiosConfig())
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetNetworkingEngagement(eventId, query, success, error) {
  const resolver = (response) => Promise.resolve(response)
  const rejecter = (response) => Promise.reject(response)

  return Promise.all([
    serviceGetEventSum(eventId, 'conversation_invitations_created', query, resolver, rejecter),
    serviceGetEventSum(eventId, 'conversation_invitations_accepted', query, resolver, rejecter),
    serviceGetEventSum(eventId, 'meeting_invitations_created', query, resolver, rejecter),
    serviceGetEventSum(eventId, 'meeting_invitations_accepted', query, resolver, rejecter),
    serviceGetEventSum(eventId, 'conversation_invitations_created_from_topic', query, resolver, rejecter),
    serviceGetEventSum(eventId, 'conversation_invitations_accepted_from_topic', query, resolver, rejecter),
    serviceGetEventSum(eventId, 'meeting_invitations_created_from_topic', query, resolver, rejecter),
    serviceGetEventSum(eventId, 'meeting_invitations_accepted_from_topic', query, resolver, rejecter),
  ])
    .then((values) => success({
      conversationsCreated: values[0].data.count,
      conversationsAccepted: values[1].data.count,
      meetingsCreated: values[2].data.count,
      meetingsAccepted: values[3].data.count,
      conversationsCreatedThroughTopics: values[4].data.count,
      conversationsAcceptedThroughTopics: values[5].data.count,
      meetingsCreatedThroughTopics: values[6].data.count,
      meetingsAcceptedThroughTopics: values[7].data.count,
    }))
    .catch((e) => error(e))
}

function serviceGetEventsStats(eventId, success, error) {
  return axios(`/events/stats?current_event=${eventId}`, getAxiosConfig())
    .then((response) => response.data.map(data => ({ ...data, event: translateEvent(data.event) })))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

function serviceGetEventsStatsBenchmark(success, error) {
  return axios('/events/stats/benchmarks', getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetMultiEventsStats(eventId, query, success, error) {
  const resolver = (response) => Promise.resolve(response)
  const rejecter = (response) => Promise.reject(response)

  return Promise.all([
    serviceGetEventsStats(eventId, resolver, rejecter),
    serviceGetEventsStatsBenchmark(resolver, rejecter),
  ])
    .then(values => success({
      events: values[0].map(stats => {
        const dates = getDatesUniqueDays(stats.event.start_date, stats.event.end_date, stats.event.timezone)
        const data = { ...stats, ...stats.event }

        // Make sure data is always valid, no need to check in components
        data.event_time = stats.event.start_date || 0
        data.event_duration = dates.length || 0
        data.num_attendees = stats.num_attendees || 0
        data.num_sessions = stats.num_sessions || 0
        data.num_activated = stats.num_activated || 0
        data.avg_contact_exchanges = stats.avg_contacts_exchanged || 0
        data.avg_session_attendance = stats.avg_sessions_attended || 0
        data.pct_profile_completion = (data.num_activated / data.num_attendees) * 100 || 0
        data.avg_engagement_score = stats.avg_engagement_score || 0

        return data
      }),
      benchmark: values[1],
    }))
    .catch(e => error(e))
}

export function serviceGetTopSessions(eventId, query, success, error) {
  const q = { ...query, limit: 10 }
  return axios.get(`/events/${eventId}/sessions/top${buildAnalyticsParams(q)}`, getAxiosConfig())
    .then((response) => Promise.resolve(response.data.map(translateSession)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetSessionsAnalytics(eventId, query, success, error) {
  const q = { ...query, include_stats: true }

  return axios.get(`/events/${eventId}/sessions${buildAnalyticsParams(q)}`, getAxiosConfig())
    .then((response) => Promise.resolve(response.data.map(translateSession)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetSessionAnalytics(eventId, sessionId, query, success, error) {
  return axios.get(`/events/${eventId}/sessions/${sessionId}/stats${buildAnalyticsParams(query)}`, getAxiosConfig())
    .then((response) => Promise.resolve(translateSession(response.data)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetTopAttendees(eventId, query, success, error) {
  const q = { ...query, limit: 10 }

  return axios.get(`/events/${eventId}/attendees/top${buildAnalyticsParams(q)}`, getAxiosConfig())
    .then((response) => Promise.resolve(response.data.map(translateAttendee)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetAttendeeEngagement(eventId, query, success, error) {
  return axios.get(`/events/${eventId}/attendees/onsite/stats${buildAnalyticsParams(query)}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetSessionsStats(eventId, query, success, error) {
  return axios.get(`/events/${eventId}/sessions/stats${buildAnalyticsParams(query)}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetEventEngagement(eventId, query, success, error) {
  const getAttendeeEngagement = () =>
    serviceGetAttendeeEngagement(
      eventId,
      query,
      (data) => Promise.resolve(data),
      () => Promise.reject()
    )

  const getSessionEngagement = () =>
    serviceGetSessionsStats(
      eventId,
      query,
      (data) => Promise.resolve(data),
      () => Promise.reject()
    )

  return Promise.all([
    getAttendeeEngagement(),
    getSessionEngagement(),
  ]).then((values) =>
    success({
      attendees: { ...values[0] },
      sessions: { ...values[1] },
    }), apiErrorHandler(error, 'backend'))
}

export function serviceGetTrackingAnalytics(eventId, query, success, error) {
  const getAttendeesOvertime = () => (
    axios.get(`/events/${eventId}/locations/stats/attendees_overtime${buildAnalyticsParams({ ...query, per_location: true })}`, getAxiosConfig())
      .then((response) => Promise.resolve(response.data))
  )

  const getAttendeesOvertimeByTag = () => (
    axios.get(`/events/${eventId}/locations/stats/attendees_overtime_by_tag${buildAnalyticsParams(query)}`, getAxiosConfig())
      .then((response) => Promise.resolve(response.data))
  )

  return Promise.all([
    getAttendeesOvertime(),
    getAttendeesOvertimeByTag(),
  ]).then((values) => success({
    overtime: values[0].timeseries,
    byTag: values[1].timeseries,
  }), apiErrorHandler(error, 'backend'))
}

export function serviceGetTrackingZoneOverview(eventId, locationId, query, success, error) {
  return axios.get(`/events/${eventId}/locations/${locationId}/stats${buildAnalyticsParams(query)}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetSocialNetworkStats(eventId, attendeeType, success, error) {
  let q = ''
  if (attendeeType) {
    q = `?group=${attendeeType}`
  }

  return axios.get(`/events/${eventId}/attendees/stats/social${q}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetClientStats(eventId, attendeeType, success, error) {
  return axios.get(`/events/${eventId}/attendees/stats/clients${buildAnalyticsParams({ group: attendeeType })}`, getAxiosConfig())
    .then((response) => success(response.data.clients), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetSessionAttendanceDistribution(eventId, query, success, error) {
  return axios.get(`/events/${eventId}/attendees/stats/distributions/sessions_attended${buildQueryParams(query)}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetContactExchangesDistribution(eventId, query, success, error) {
  return axios.get(`/events/${eventId}/attendees/stats/distributions/contacts_exchanged${buildQueryParams(query)}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetEngagementOvertime(eventId, query, success, error) {
  return axios.get(`/events/${eventId}/stats/kliks/overtime${buildQueryParams(query)}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetEventStats(eventId, query, success, error) {
  const url = `/events/${eventId}/stats/kliks/overtime${buildAnalyticsParams(query)}`

  return axios.get(url, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetDevicesOnsiteStatsOvertime(eventId, query, success, error) {
  return axios.get(`/events/${eventId}/locations/stats/attendees_overtime${buildAnalyticsParams(query)}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetRegistrationStats(eventId, attendeeType, success, error) {
  let q = ''
  if (attendeeType) {
    q = `&filter=group:${attendeeType}`
  }

  return axios.get(`/events/${eventId}/attendees/stats?groupby=registration_status${q}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetCheckinStats(eventId, success, error) {
  return axios.get(`/events/${eventId}/attendees/stats?groupby=group&filter=checked_in:true`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetAppMetrics(eventId, query, success, error) {
  const _query = buildAnalyticsParams(query, false)
  return axios.get(`/events/${eventId}/stats/appmetrics/overtime${_query}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetBadgesData(eventId, query, success, error) {
  return axios.get(`/events/${eventId}/games/main/achievements?include_stats=true`, getAxiosConfig())
    .then((response) => {
      response.data.forEach(a => {
        a.achieved_count = a.stats ? a.stats.achieved : 0  // eslint-disable-line
      })
      success(response.data)
    }, apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetTopicsTagsUsage(eventId, query, success, error) {
  const formatTagStat = (stat) => ({
    tagId: stat.tag.id,
    tagName: stat.tag.name,
    count: stat.count,
  })

  const getMostUsed = () => axios.get(`/events/${eventId}/topics/stats/top_tags/used?limit=10`, getAxiosConfig())
    .then((response) => Promise.resolve(response.data.map(formatTagStat)))
    .catch(() => Promise.resolve([]))

  return Promise.all([
    getMostUsed(),
  ]).then((values) => success({
    mostUsed: values[0],
    mostEngaged: [],
  }), apiErrorHandler(error, 'backend'))
}
