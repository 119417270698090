import { serviceGetCheckinStats } from 'admin/services/analytics'
import generateModule from 'common/utils/generateModule'

const { actions, reducers } = generateModule({
  itemName: 'checkinProgress',
  services: {
    get: serviceGetCheckinStats,
  },
  dataIsNotArray: true,
})

export const getCheckinProgress = actions.get
const stats = reducers.index
export default stats
