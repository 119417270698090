import { getProperty } from 'common/utils/componentHelper'
import Moment from 'moment'


export function isSessionConfirmed(session) {
  return session.me && (session.me.going)
}

export function isSessionSaved(session) {
  return getProperty(session, 'me.interested')
}

// Session that has capacity
// Session that has invitation status
export function isLimitedSession(session) {
  return session.me
    && (
      (session.me.invited && session.me.invited.status)
      || (session.capacity !== null)
    )
}

export function getSessionActionRequired(session) {
  if (session.me && session.me.invited && session.me.invited.status === 'pending') {
    return 'confirmation'
  }

  if (session.me && session.capacity !== null && !session.me.going) {
    return 'reservation'
  }

  return ''
}

export function isSessionInCalendar(session) {
  return getProperty(session, 'me.interested')
    || getProperty(session, 'me.going')
    || getProperty(session, 'me.invited')
}

export function isSessionActionCompleted(session) {
  return (getProperty(session, 'me.invited.status') === 'accepted')
    || !!(getProperty(session, 'me.going') && session.capacity !== null)
}

export function isSessionFull(session) {
  return (session.capacity !== null && session.capacity_available === 0)
}

export function isSessionAlmostFull(session) {
  return (session.capacity !== null && session.capacity_available <= 10 && session.capacity_available > 0)
}

export function doesSessionHaveLimitedCapacity(session) {
  return (session.capacity !== null && session.capacity_available >= 0)
}

export function hasSessionStarted(session) {
  return (Moment().unix() >= session.start_date)
}

export function hasSessionEnded(session) {
  return (Moment().unix() >= session.end_date)
}

export function isRegistrationOpen(session) {
  const openDate = getProperty(session, 'calendar.registration_open_date')
  return openDate ? Moment().unix() >= openDate : true
}

export function hasAttendedSession(session) {
  return getProperty(session, 'me.attended')
}

export function isSessionInvitation(session) {
  return getProperty(session, 'me.invited')
}

export function isRegistrationAllowed(session) {
  return getProperty(session, 'me.registration_allowed')
}

export function isAttendeeManagementDisabled(session) {
  return getProperty(session, 'calendar.attendee_management_disabled')
}
