import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetNetworkingMeetingAcceptanceStats } from 'admin/services/dashboard'


const MeetingAcceptanceStatsModule = generateDataModule({
  initialData: {
    accepted: 0,
    notAccepted: 0,
    total: 0,
  },
  moduleKey: 'dashboardMeetingAcceptanceStats',
  reducerKey: 'admin.dashboard.meetingAcceptanceStats',
  services: {
    get: { service: serviceGetNetworkingMeetingAcceptanceStats },
  },
})


MeetingAcceptanceStatsModule.registerDataAction(
  'get',
  () => dispatch => {
    dispatch({ type: MeetingAcceptanceStatsModule.actionKeys.getStart })

    return MeetingAcceptanceStatsModule._getService('get')()
      .then(response => {
        dispatch({ type: MeetingAcceptanceStatsModule.actionKeys.getSuccess, payload: response })
      })
      .catch(() => {
        dispatch({ type: MeetingAcceptanceStatsModule.actionKeys.getError })
      })
  }
)

MeetingAcceptanceStatsModule.registerReducer(
  MeetingAcceptanceStatsModule.actionKeys.getSuccess,
  (state, action) => ({
    ...state,
    data: {
      accepted: action.payload.accepted,
      notAccepted: action.payload.notAccepted,
      total: action.payload.total,
    },
  })
)


export default MeetingAcceptanceStatsModule
