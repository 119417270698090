import React, { Component } from 'react'

import { styles } from './styles.scss'


function InputCodeCharacter(props) {
  const { showPlaceholder, selected, placeholderCharacter, character, position } = props

  const selectedClassName = selected ? 'selected' : ''
  const placeholderClassName = showPlaceholder ? 'placeholder' : ''
  const className = `InputCode-Character ${selectedClassName} ${placeholderClassName}`
  const visibleCharacter = showPlaceholder ? placeholderCharacter : character

  return (
    <div className={className} onClick={(e) => { props.onClick(e, position) }}>
      <div className="character">{visibleCharacter}</div>
    </div>
  )
}


export default class InputCode extends Component {
  constructor(props) {
    super(props)

    this.codeLength = props.length;
    this.state = {
      code: Array(this.codeLength).join('.').split('.'),
      isFocused: false,
      currentPosition: -1,
    }
  }

  componentDidMount() {
    this.onClickCode()
  }

  setCode = (code) => {
    this.setState({ code }, () => {
      this.props.onCodeChange && this.props.onCodeChange(this.getCode())
    })
  }

  getCode(state = { ...this.state }) {
    return state.code.join('')
  }

  getCurrentPosition(code = [...this.state.code]) {
    let index = code.length - 1

    for (let i = 0, l = code.length; i < l; i++) {
      if (!code[i]) {
        index = i
        break
      }
    }

    return Math.clamp(index, 0, code.length - 1)
  }

  onClickCode = (e, positionClicked) => {
    const { code } = this.state
    let position

    if (code[positionClicked]) {
      position = positionClicked
    } else {
      position = this.getCurrentPosition()
    }

    this.Input.focus()
    this.setState({ currentPosition: position })
  }

  onCodeChange = (e) => {
    const { code, currentPosition } = this.state
    const targetValue = e.target.value

    let offsetPositionBy = 0
    for (let i = 0, l = targetValue.length; i < l; i++) {
      offsetPositionBy = i + 1
      if (currentPosition + i >= this.codeLength) {
        break
      }
      code[currentPosition + i] = targetValue[i]
    }

    this.Input.value = ''
    this.setCode(code)
    this.setState({ currentPosition: currentPosition + offsetPositionBy })
  }

  onKeyUp = (e) => {
    if (e.key === 'Backspace') {
      this.handleBackspace()
    }

    if (e.key === 'ArrowLeft') {
      this.handleMovePosition(-1)
    } else if (e.key === 'ArrowRight') {
      this.handleMovePosition(1)
    }
  }

  handleMovePosition = (moveBy) => {
    let { currentPosition } = this.state
    currentPosition += moveBy

    this.setState({ currentPosition: Math.clamp(currentPosition, 0, this.codeLength - 1) })
  }

  handleBackspace = () => {
    const { code } = this.state
    let { currentPosition } = this.state

    if (currentPosition === -1) {
      currentPosition = code.length
    }

    while (currentPosition > 0 && !code[currentPosition]) {
      currentPosition--
    }

    code[currentPosition] = ''
    this.setCode(code)
    this.setState({ currentPosition: currentPosition })
  }

  renderCharacterInput = () => {
    const { placeholder } = this.props
    const { code, currentPosition } = this.state
    const getPlaceholderCharacter = (index) => placeholder[index]
    const content = []

    for (let i = 0; i < this.codeLength; i++) {
      content.push(
        <InputCodeCharacter
          onClick={(e, position) => { this.onClickCode(e, position) }}
          position={i}
          selected={currentPosition === i}
          character={code[i]}
          placeholderCharacter={getPlaceholderCharacter(i)}
          showPlaceholder={!code}
        />
      )
    }

    return content
  }

  render() {
    return (
      <div className={styles}>
        <input
          className="InputCode-input-hidden"
          value="" // NOTE: Leave empty, we use it only to get the keypad
          onChange={this.onCodeChange}
          onBlur={() => this.setState({ currentPosition: -1 })}
          onKeyUp={this.onKeyUp}
          type="number"
          pattern="\d*"
          ref={ref => { this.Input = ref }}
        />

        <div className="InputCode">
          {this.renderCharacterInput().map(e => e)}
        </div>
      </div>
    )
  }
}
