import axios from 'axios'
import i18n from 'i18next'

import { buildError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { getAxiosConfig } from './helper'
import { buildQueryString } from 'common/services/helper'


export function translateTopic(topic) {
  const data = { ...topic }
  data.title = getTranslatedObject(topic.title)
  data.call_to_actions = topic.call_to_actions.map(cta => ({
    ...cta,
    label: getTranslatedObject(cta.label),
  }))
  data.tags = topic.tags.map(tag => ({
    ...tag,
    name: getTranslatedObject(tag.name),
  }))
  return data
}

function translateTopics(topics) {
  return topics.map(topic => translateTopic(topic))
}


export function serviceGetTopics(eventId, query) {
  const queryString = buildQueryString(query)

  // TODO: Once the backend bug is fixed, make sure to pass "mul" so we get the data in all languages
  //       https://pixmob.tpondemand.com/entity/24928-full-test-search-doesnt-work-when
  const axiosConfig = getAxiosConfig()
  axiosConfig.headers = { ...axiosConfig.headers, 'Accept-Language': i18n.language }

  return axios.get(`/events/${eventId}/topics${queryString}`, axiosConfig)
    .then((response) => translateTopics(response.data))
    .catch((error) => Promise.reject(buildError(error)))
}

export function servicePutTopic(eventId, topic) {
  return axios.put(`/events/${eventId}/topics/${topic.id}`, topic, getAxiosConfig())
    .then((response) => translateTopic(response.data))
    .catch((error) => Promise.reject(buildError(error)))
}
