import { hot } from 'react-hot-loader/root'
import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { isIE9OrBelow, safeScrollToTop } from 'common/utils/domUtils'

import { I18nextProvider } from 'react-i18next'
import { Provider, ReactReduxContext } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import ExternalLink from 'common/components/ExternalLink'

import { AppRoutes } from 'routes'

import i18n from './i18n'


class AppNode extends Component {
  componentDidMount() {
    window.ReactGA = ReactGA
    this.unlisten = this.props.history.listen((location, action) => {
      if (action === 'PUSH') {
        const pathname = location.pathname

        ReactGA.set({ page: pathname })
        ReactGA.pageview(pathname)

        // list of paths to NOT scroll to top of page on load
        // NOTE: We need these here because clicking on a card would change the url then would scroll back to the top
        if (!(/^(\/(webview|webview_native))?\/events\/.+\/directory/.test(pathname)
          || /^(\/(webview|webview_native))?\/events\/.+\/exhibitors/.test(pathname)
          || /^(\/(webview|webview_native))?\/events\/.+\/networking\/recommended/.test(pathname)
          || /^(\/(webview|webview_native))?\/events\/.+\/networking\/meeting_requests/.test(pathname)
          || /^(\/(webview|webview_native))?\/events\/.+\/networking\/contacts/.test(pathname)
          || /^(\/(webview|webview_native))?\/events\/.+\/networking\/topics/.test(pathname)
          || /^(\/(webview|webview_native))?\/events\/.+\/schedule\/.+$/.test(pathname)
          || /^(\/(webview|webview_native))?\/events\/.+\/contacts\/.+$/.test(pathname)
          || /^(\/(webview|webview_native))?\/events\/.+\/activities/.test(pathname))) {
          safeScrollToTop()
        }
      }
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  render() {
    const { history, store } = this.props

    if (isIE9OrBelow()) {
      return (
        <div>
          <p>It seems you are using Internet Explorer 9 or older.</p>
          <p>Your web browser is not supported by our website.</p>
          <p><ExternalLink target="_blank" href="https://browser-update.org/update.html">Please upgrade your browser.</ExternalLink></p>
        </div>
      )
    }

    return (
      <I18nextProvider i18n={i18n}>
        <Provider store={store} context={ReactReduxContext}>
          <ConnectedRouter history={history} context={ReactReduxContext}>
            {AppRoutes()}
          </ConnectedRouter>
        </Provider>
      </I18nextProvider>
    )
  }
}

export default hot(AppNode)
