import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetHighRiskEmployeesComparison } from 'admin/services/bluetrace'
import { getProperty } from 'common/utils/componentHelper'
import sort from 'common/utils/sortHelper'


const HighRiskEmployeesComparisonModule = generateDataModule({
  initialData: {
    currentWeek: [],
    previousWeek: [],
  },
  moduleKey: 'cpo/bluetrace/highRiskEmployeesComparison',
  reducerKey: 'admin.bluetrace.highRiskComparisons.employees',
  refreshTime: 10000,
  services: {
    get: { service: serviceGetHighRiskEmployeesComparison },
  },
})


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

function getFormattedTopEmployees(currentEmployeesById, previousEmployeesById = {}) {
  const topEmployees = []

  Object.keys(currentEmployeesById).forEach(employeeId => {
    const previousEntry = previousEmployeesById[employeeId]

    const employeeEntry = {
      ...currentEmployeesById[employeeId],
      previousContagionScore: getProperty(previousEntry, 'contagionScore') || 0,
    }

    topEmployees.push(employeeEntry)
  })

  return sort(topEmployees, [
    { id: 'contagionScore', order: -1 },
    'name',
    'id',
  ])
}

HighRiskEmployeesComparisonModule.registerSelector(
  'currentWeek',
  [
    (state, moduleState) => moduleState.data.currentWeek,
    (state, moduleState) => moduleState.data.previousWeek,
  ],
  (currentEmployees, previousEmployees) => getFormattedTopEmployees(currentEmployees, previousEmployees)
)

HighRiskEmployeesComparisonModule.registerSelector(
  'previousWeek',
  (state, moduleState) => moduleState.data.previousWeek,
  employees => getFormattedTopEmployees(employees)
)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////


export default HighRiskEmployeesComparisonModule
