import { generateDataModule } from 'admin/modules/generateModule'
import { getProperty } from 'common/utils/componentHelper'
import { serviceGetCheckInBreakdown } from 'admin/services/dashboard'
import { attendeeTypesByIdSelector } from 'admin/modules/attendeeTypes'
import { customFieldsByIdSelector } from 'admin/modules/customFields'
import sort from 'common/utils/sortHelper'

const ATTENDEE_TYPE = 'group'


const CheckInBreakdownModule = generateDataModule({
  initialData: {
    full: [],
    minimal: [],
  },
  initialState: {
    groupBy: ATTENDEE_TYPE,
  },
  moduleKey: 'dashboardCheckInBreakdown',
  reducerKey: 'admin.dashboard.checkInBreakdown',
  services: {
    get: { service: serviceGetCheckInBreakdown },
  },
})

CheckInBreakdownModule.ATTENDEE_TYPE = ATTENDEE_TYPE


function formatData(data, attendeeTypesById, customFieldsById) {
  const formattedData = data.map(entry => {
    const modifiedEntry = { ...entry }

    if (entry.fieldId === ATTENDEE_TYPE) {
      const field = attendeeTypesById[entry.fieldValue]
      modifiedEntry.fieldLabel = getProperty(field, 'title')
    } else {
      const field = customFieldsById[entry.fieldId]
      const option = (getProperty(field, 'options') || []).find(o => o.value === entry.fieldValue)
      modifiedEntry.fieldLabel = getProperty(option, 'title')
    }

    return modifiedEntry
  })

  return sort(formattedData, [
    { id: 'count', order: -1 },
    'fieldLabel',
    'fieldId',
    'fieldValue',
  ])
}

CheckInBreakdownModule.registerSelector(
  'fullData',
  [
    (state, moduleState) => moduleState.data.full,
    state => attendeeTypesByIdSelector(state),
    state => customFieldsByIdSelector(state),
  ],
  (data, attendeeTypesById, customFieldsById) => formatData(data, attendeeTypesById, customFieldsById)
)

CheckInBreakdownModule.registerSelector(
  'minimalData',
  [
    (state, moduleState) => moduleState.data.minimal,
    state => attendeeTypesByIdSelector(state),
    state => customFieldsByIdSelector(state),
  ],
  (data, attendeeTypesById, customFieldsById) => formatData(data, attendeeTypesById, customFieldsById).slice(0, 5)
)

CheckInBreakdownModule.registerSelector(
  'groupBy',
  (state, moduleState) => moduleState.groupBy,
  groupBy => groupBy
)


CheckInBreakdownModule.registerDataAction(
  'get',
  groupBy => dispatch => {
    const service = CheckInBreakdownModule._getService('get')

    const getMinimalBreakdown = () => service(CheckInBreakdownModule.initialState.groupBy)
    const getFullBreakdown = () => service(groupBy)

    dispatch({ type: CheckInBreakdownModule.actionKeys.getStart })

    const services = groupBy ? [getMinimalBreakdown, getFullBreakdown] : [getMinimalBreakdown]

    return Promise.all(services.map(s => s()))
      .then(values => {
        dispatch({
          type: CheckInBreakdownModule.actionKeys.getSuccess,
          payload: {
            full: values[1] || values[0],
            groupBy: groupBy,
            minimal: values[0],
          },
        })
      })
      .catch((error) => {
        console.error(error)
        dispatch({ type: CheckInBreakdownModule.actionKeys.getError })
      })
  }
)

CheckInBreakdownModule.registerReducer(
  CheckInBreakdownModule.actionKeys.getSuccess,
  (state, action) => ({
    ...state,
    data: {
      full: action.payload.full,
      minimal: action.payload.minimal,
    },
    groupBy: action.payload.groupBy || state.groupBy,
  })
)


export default CheckInBreakdownModule
