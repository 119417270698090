import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { triangleStyles as styles } from './styles.scss'


export default class Triangle extends Component {
  _getClassName() {
    let className = `Triangle ${styles}`
    className += this.props.className ? ` ${this.props.className}` : ''
    return className
  }

  render() {
    const { direction } = this.props
    return <div className={this._getClassName()} data-direction={direction} ref={ref => { this.refToElement = ref }} />
  }
}

Triangle.propTypes = {
  direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
}

Triangle.defaultProps = {
  className: '',
}
