import { createPageTitle } from 'common/utils/componentHelper'
import React, { Component } from 'react'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import handlePageLoader from 'common/utils/decorators/PageLoader'

import DocumentMeta from 'react-document-meta'
import { Row } from 'react-bootstrap'
import { isIE, isBrowserSupported, loopThroughDOMElements } from 'common/utils/domUtils'

/* actions */
import * as actionCreatorsAuth from 'common/modules/auth'

/* components */
import { getQueryParam } from 'common/components/Link'
import Footer from 'common/components/Footer'
import TermsAndConditions from './TermsAndConditions'
import EnterEmail from './Screens/EnterEmail'
import EnterPassword from './Screens/EnterPassword'
import SendCode from './Screens/SendCode'
import EnterCode from './Screens/EnterCode'
import MultiFactorAuthentification from './Screens/MultiFactorAuthentification'
import DomainSelector from './Screens/DomainSelector'

import LanguageSelector from './LanguageSelector'
import LoginNoEventAccess from './LoginNoEventAccess'

/* component styles */
import { styles } from './styles.scss'

if (isIE() || !isBrowserSupported()) {
  require('babel-polyfill') // eslint-disable-line global-require
}


@translate(['login'], { wait: true })
@connect(
  state => state.auth,
  dispatch => bindActionCreators(actionCreatorsAuth, dispatch)
)
@handlePageLoader(
  (self, nextProps) => !nextProps.autologinFetching
)
export default class Login extends Component {
  componentWillMount() {
    const [pageParam, codeParam] = getQueryParam(this.props.location, ['page', 'code'])

    // We make sure the default page is "email" if not specified, and not in an oauth landing page
    if (!pageParam && !codeParam) {
      this.props.changeLoginPage(this.props.location, 'email')
    }
  }

  changeInputBorderColor = () => {
    const inputs = document.querySelectorAll('.form-control')
    loopThroughDOMElements(inputs, (input) => {
      /* eslint-disable no-param-reassign */
      input.style.borderColor = '#aaa'
      input.style.boxShadow = 'none'
      /* eslint-enable */
    })
  }

  renderContent() {
    const page = getQueryParam(this.props.location, 'page') || ''
    const passedProps = {
      ...this.props,
      changeInputBorderColor: this.changeInputBorderColor,
    }

    switch (page.toLowerCase()) {
      default:
      case 'email': return <EnterEmail {...passedProps} />
      case 'password': return <EnterPassword {...passedProps} />
      case 'send_code' : return <SendCode {...passedProps} />
      case 'enter_code': return <EnterCode {...passedProps} />
      case 'no_access': return <LoginNoEventAccess />
      case 'multi_factor_authentification': return <MultiFactorAuthentification {...passedProps} />
      case 'domain_selector': return <DomainSelector {...passedProps} />
    }
  }

  render() {
    const { t } = this.props
    const page = (getQueryParam(this.props.location, 'page') || '').toLowerCase()

    const logo = () => {
      const url = this.props.loginBranding.action_url ? this.props.loginBranding.action_url : this.props.loginBranding.logo_url
      return <img className="logo" role="presentation" src={url} />
    }

    return (
      <main className={styles}>
        <DocumentMeta {...createPageTitle(t('Page Title Login'))} />
        <div className="background" style={{ backgroundImage: 'url(' + this.props.loginBranding.splash_url + ')' }}>
          <div className="splash">
            <div className="logo-container">
              {logo()}
            </div>

            <Row>
              <CSSTransitionGroup transitionName="fade-in-animation" transitionAppear transitionAppearTimeout={800} transitionEnterTimeout={800} transitionLeaveTimeout={0}>
                <div className="well login-well">
                  <LanguageSelector />
                  {this.renderContent()}
                  {page === 'email' ? <TermsAndConditions /> : null}
                </div>
              </CSSTransitionGroup>
            </Row>

            <Footer emailSubject="Issue with the login" useWhiteLogo />
          </div>
        </div>
      </main>
    )
  }
}
