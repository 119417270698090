import { serviceGetTagCounts } from 'admin/services/locator'
import generateModule from 'common/utils/generateModule'
import { createSelector } from 'reselect'

const selectData = state => state.admin.trackingTagCounts.data

export const trackingTagCountsSelector = createSelector(selectData, data => data)


const { actions, reducers } = generateModule({
  itemName: 'trackingTagCount',
  services: {
    get: serviceGetTagCounts,
  },
})

export const getTrackingTagCounts = actions.get
const trackingTagCounts = reducers.index
export default trackingTagCounts
