import fullAccessPermissions from './FullAccess'

export default {
  action: {
    ...fullAccessPermissions.action,

    // Attendees
    generateWebviewLinks: false,
    impersonateAttendees: false,
    manageFields: false,
    viewAttendeesLocation: false,

    // Events
    createEvents: false,
    editEvents: false,

    // Maps
    modifyMaps: false,

    // Schedule
    addCalendars: false,
    addSessions: false,
    deleteSessions: false,
    editSessions: false,
    exportSessions: false,
    importSessions: false,
    modifyCalendars: false,
    modifyTags: false,

    // Speakers
    modifySpeakers: false,

    // Users
    modifyUsers: false,
  },

  menu: {
    ...fullAccessPermissions.menu,
    analytics: false,
    checkIn: false,
    communication: false,
    dashboard: false,
    event: false,
    floorPlan: false,
    gamification: false,
    registration: false,
    speakers: false,
    system: false,
    users: false,
  },

  page: {
    ...fullAccessPermissions.page,
    broadcast: false,
    exports: false,
  },
}
