import axios from 'axios'
import { buildError } from 'common/utils/api'
import { getAxiosConfig } from './helper'
import { getTranslatedObject } from 'common/utils/translator'
import { listToMapping } from 'common/utils/componentHelper'


export function translateQualifier(data) {
  return {
    ...data,
    title: getTranslatedObject(data.title),
    options: (data.options || []).map(option => ({
      ...option,
      title: getTranslatedObject(option.title),
    })),
  }
}


export function serviceGetQualifiers(eventId, exhibitorId) {
  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}/qualifiers`, getAxiosConfig())
    .then(response => listToMapping(response.data.map(translateQualifier), 'id'))
    .catch(error => Promise.reject(buildError(error)))
}

export function servicePutQualifier(eventId, exhibitorId, qualifier) {
  return axios.put(`/events/${eventId}/exhibitors/${exhibitorId}/qualifiers/${qualifier.id}`, qualifier, getAxiosConfig())
    .then(response => translateQualifier(response.data))
    .catch(error => Promise.reject(buildError(error)))
}

export function servicePostQualifier(eventId, exhibitorId, qualifier) {
  const data = { ...qualifier }
  delete data.id

  return axios.post(`/events/${eventId}/exhibitors/${exhibitorId}/qualifiers`, data, getAxiosConfig())
    .then(response => translateQualifier(response.data))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceDeleteQualifier(eventId, exhibitorId, qualifierId) {
  return axios.delete(`/events/${eventId}/exhibitors/${exhibitorId}/qualifiers/${qualifierId}`, getAxiosConfig())
    .catch(error => Promise.reject(buildError(error)))
}


export function serviceGetQualifiersByLead(eventId, exhibitorId, leadId) {
  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}/leads/${leadId}/qualifiers/responses`, getAxiosConfig())
    .then(response => response.data.map(qualifier => {
        const qualifierOptionsByValue = listToMapping(qualifier.options, 'value')

        return {
          ...qualifier,
          responses: qualifier.responses.map(qualifierResponse => ({
            ...qualifierResponse,
            response: (() => {
              switch (qualifier.type) {
                case 'text': return qualifierResponse.response
                case 'single': return qualifierOptionsByValue[qualifierResponse.response] || qualifierResponse.response
                case 'multiple': return qualifierResponse.response.map(answer => qualifierOptionsByValue[answer] || answer)
                default: qualifierResponse.response
              }
            })(),
          }))
        }
      })
    )
    .catch(error => Promise.reject(buildError(error)))
}
