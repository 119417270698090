import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import FlipMove from 'react-flip-move'
import * as actionCreators from 'common/modules/toaster'

import Toast from './toast'
import { styles } from './styles.scss'


@withRouter
@connect(
  state => state.toaster,
  dispatch => bindActionCreators(actionCreators, dispatch)
)
export default class Toaster extends Component {
  constructor(props) {
    super(props)

    this.state = { expanded: false }

    // Everytime we push to a new url, clear the toasts
    this.unlisten = props.history.listen((location, action) => {
      if (action === 'PUSH' && this.props.toasts.length > 0) {
        this.props.deleteAllToasts()
      }
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  expand = () => {
    this.setState({ expanded: true })
  }

  deleteOne = (index) => {
    this.props.deleteToast(index)
    this.setState({ expanded: false })
  }

  render() {
    const { toasts, deleteToast } = this.props
    let toastsToDisplay = []

    if (this.state.expanded) {
      toastsToDisplay = toasts.map((toast, index) => <Toast key={index} toast={toast} index={index} delete={this.deleteOne} expand={this.expand} />)
    } else if (toasts.length > 0) {
      let latestToastIndex = 0
      toasts.forEach((t, index) => {
        if (t.updatedAt < toasts[latestToastIndex].updatedAt) {
          latestToastIndex = index
        }
      })

      toastsToDisplay.push(<Toast key="single" toast={toasts[latestToastIndex]} index={latestToastIndex} delete={deleteToast} expand={this.expand} numToasts={toasts.length} />)
    }

    return (
      <div className={styles}>
        <div className="overlay">
          <div className="toast-container">
            <FlipMove enterAnimation="fade" leaveAnimation="fade" duration="500" easing="ease-in-out">
              {toastsToDisplay}
            </FlipMove>
          </div>
        </div>
      </div>
    )
  }
}
