import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { translate } from 'react-i18next'
import { getTextColor, isIE } from 'common/utils/domUtils'

import Input from 'common/components/Input'
import TitleLabel from 'common/containers/Login/TitleLabel'
import ButtonContainer from 'common/containers/Login/ButtonContainer'


@translate(['login'], { wait: true })
@reduxForm({
  form: 'login',
  fields: ['email', 'password_notrim'],
  destroyOnUnmount: false,
})
export default class EnterPassword extends Component {
  componentWillMount() {
    if (!this.props.user.hasPassword && this.props.user.email) {
      this.props.changeLoginPage(this.props.location, 'send_code')
    } else {
      this.props.initializeForm({ email: this.props.values.email, password_notrim: '' })
    }
  }

  componentDidMount() {
    this.props.changeInputBorderColor()
  }

  onSubmit = (e) => {
    e.preventDefault()

    this.props.login(this.props.values.email, this.props.values.password_notrim, this.props.domain)
      .then(this.onLogin)
  }

  clickForgotPassword = () => {
    this.props.changeLoginPage(this.props.location, 'send_code')
  }

  onLogin = ({ loginType, needDomainSelection }) => {
    if (loginType === 'MFA_LOGIN') {
      this.props.changeLoginPage(this.props.location, 'multi_factor_authentification')
    } else if (needDomainSelection) {
      this.props.changeLoginPage(this.props.location, 'domain_selector')
    } else if (!this.props.isError) {
      this.props.destroyForm()
    }
  }

  render() {
    const { t, loginErrorMessage } = this.props
    const { brandColor } = this.props.loginBranding
    const { email, password_notrim: password } = this.props.fields
    const socialNetworks = (this.props.user.socialNetworks || []).filter(o => ['facebook', 'linkedin', 'eegsignal'].includes(o)) || []
    const autofocus = !isIE() // IE auto-focus removes placeholder text, making field un-labeled

    return (
      <div className="EnterPassword">
        <form onSubmit={this.onSubmit}>
          <Input field={email} placeholder={t('login_email.placeholder')} type="email" />
          <Input field={password} placeholder={t('login_password.placeholder')} type="password" autoFocus={autofocus} />
          {loginErrorMessage &&
            <div className="login-error-message-container">
              {loginErrorMessage}
            </div>}

          <button style={{ color: getTextColor(brandColor), backgroundColor: brandColor }} className="btn btn-block" type="submit">
            {this.props.isFetching
              ? <i className="fa fa-spin fa-circle-o-notch" aria-hidden="true" />
              : t('login_password.log_in')}
          </button>

          {socialNetworks.length <= 0 ? null : <div>
            <TitleLabel id="social-login-label" label={t('social_login')} uppercase />
            <ButtonContainer buttons={socialNetworks} location={location} loginCallback={this.onLogin} />
          </div>}

          <TitleLabel label={t('forgot_password')} onClick={this.clickForgotPassword} />
        </form>
      </div>
    )
  }
}
