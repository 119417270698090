import { serviceAddConversationMessage, serviceGetConversation, serviceGetConversationMessages } from 'attendee/services/meetingRequests'
import generateModule from 'common/utils/generateModule'
import { handleErrorMessage } from './index'
import { getProperty } from 'common/utils/componentHelper'
import Moment from 'moment'
import { createSelector } from 'reselect'

const selectConversation = state => state.attendee.conversation.data

export const conversationSelector = createSelector(
  selectConversation,
  (conversation) => conversation
)


const conversation = generateModule({
  itemName: 'conversation',
  itemNameDisplay: 'conversation',
  getModuleState: (getState) => getState().attendee.conversation,
  autoRefresh: 60,
  app: 'attendee',
  dataIsNotArray: true,
  services: {},
})

conversation.initialState.data = undefined


conversation.actions.refreshConversation = () =>
  (dispatch, getState) => {
    const currentConversation = conversationSelector(getState())

    if (currentConversation) {
      return dispatch(conversation.actions.getConversation(currentConversation.id))
    }

    return Promise.resolve()
  }

conversation.actions.getConversation = (conversationId, _options) =>
  (dispatch, getState) => {
    const options = { clearConversation: false, ..._options }
    const eventId = getState().attendee.attEvents.currEvent.id

    dispatch({ type: 'GET_CONVERSATION_START', payload: { clearConversation: options.clearConversation } })

    return serviceGetConversationMessages(
      eventId,
      conversationId,
      (response) => Promise.resolve(response),
      (error) => Promise.reject(error)
    ).then((responseMessages) => serviceGetConversation(
      eventId,
      conversationId,
      (response) => {
        const conversationData = { ...response, messages: responseMessages }
        dispatch({ type: 'GET_CONVERSATION_SUCCESS', payload: { conversation: conversationData } })
        return Promise.resolve()
      },
      (error) => Promise.reject(error)
    )).catch(error => {
      dispatch({ type: 'GET_CONVERSATION_ERROR' })
      handleErrorMessage(dispatch, getState, error)
    })
  }

conversation.reducers['GET_CONVERSATION_START'] = (state, action) => ({
  ...state,
  isFetching: true,
  didInvalidate: false,
  data: action.payload.clearConversation ? conversation.initialState.data : state.data,
})

conversation.reducers['GET_CONVERSATION_SUCCESS'] = (state, action) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  isError: false,
  data: action.payload.conversation,
})

conversation.reducers['GET_CONVERSATION_ERROR'] = (state) => ({
  ...state,
  isFetching: false,
  isError: true,
})


conversation.actions.getConversationFromMeetingRequest = (meetingRequestId) =>
  (dispatch, getState) => {
    const _conversation = getProperty(getState().attendee.meetingRequests.data.find(o => o.id === meetingRequestId), 'conversation')
    dispatch({ type: 'GET_CONVERSATION_FROM_MEETING_REQUEST', payload: { conversation: _conversation } })
    return Promise.resolve()
  }

conversation.reducers['GET_CONVERSATION_FROM_MEETING_REQUEST'] = (state, action) => ({
  ...state,
  data: action.payload.conversation,
})


conversation.actions.addConversationMessage = (body, conversationId) =>
  (dispatch, getState) => {
    const message = {
      sender_id: getState().attendee.attProfile.data.id,
      date: Moment().unix(),
      body,
    }

    dispatch({ type: 'ADD_CONVERSATION_MESSAGE', payload: { message, conversationId } })
    return serviceAddConversationMessage(
      getState().attendee.attEvents.currEvent.id,
      conversationId || getState().attendee.conversation.data.id,
      body,
      () => dispatch({ type: 'ADDED_CONVERSATION_MESSAGE', payload: { message, conversationId } }),
      () => { }
    )
  }

conversation.reducers['ADD_CONVERSATION_MESSAGE'] = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    messages: [
      { ...action.payload.message },
      ...(getProperty(state, 'data.messages') || []),
    ],
  },
})


conversation.actions.clearConversation = () =>
  (dispatch) => {
    dispatch({ type: 'CLEAR_CONVERSATION' })
    return Promise.resolve()
  }

conversation.reducers['CLEAR_CONVERSATION'] = (state) => ({
  ...state,
  data: conversation.initialState.data,
})


conversation.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isError: false,
  isLoaded: false,
  didInvalidate: true,
  data: undefined,
})


conversation.actions.getConversation = conversation.actions.getConversation
conversation.actions.getConversationFromMeetingRequest = conversation.actions.getConversationFromMeetingRequest
conversation.actions.addConversationMessage = conversation.actions.addConversationMessage
conversation.actions.clearConversation = conversation.actions.clearConversation
export default conversation
