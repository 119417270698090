import { serviceGetAchievements, serviceGetAchievement, serviceCreateAchievement, serviceUpdateAchievement, serviceDeleteAchievement } from 'admin/services/gamification'
import generateModule from 'common/utils/generateModule'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'
import { listToMapping } from 'common/utils/componentHelper'
import { getTranslatedValue } from 'common/utils/translator'
import search, { buildFieldList } from 'common/utils/search'
import { createSelector } from 'reselect'
import { areConditionsValid } from 'admin/containers/Gamification/Rules/validation'


const selectModuleState = (state) => state.admin.achievements
const selectAchievements = (state) => selectModuleState(state).data
const selectSearchTerm = (state) => selectModuleState(state).searchTerm

export const achievementsSelector = createSelector(selectAchievements, (achievements) => achievements)

export const achievementsByIdSelector = createSelector(
  achievementsSelector,
  (achievements) => listToMapping(achievements, 'id')
)

export const currentBadgeSelector = createSelector(
  selectModuleState,
  achievementsByIdSelector,
  (state, achievementsById) => achievementsById[state.currentAchievementId]
)

export const filteredAchievementsSelector = createSelector(
  achievementsSelector,
  selectSearchTerm,
  (achievements, searchTerm) => {
    let filteredAchievements = [...achievements];

    (function filterBySearch() {
      if (!searchTerm) return
      filteredAchievements = filteredAchievements.filter(achievement => search(searchTerm, buildFieldList(achievement, ['name', 'description'])))
    }());

    filteredAchievements = filteredAchievements.map((badge) => ({
      ...badge,
      _incomplete: badge.rules.length === 0
        || !areConditionsValid(badge.actor_conditions)
        || badge.rules.some((rule) => !areConditionsValid(rule.target_conditions)),
    }));

    (function sort() {
      filteredAchievements = filteredAchievements.sort((a, b) => {
        if (a._incomplete === b._incomplete) {
          const pointsA = a.rules.length > 0 ? a.points : Number.MAX_SAFE_INTEGER
          const pointsB = b.rules.length > 0 ? b.points : Number.MAX_SAFE_INTEGER

          if (pointsA === pointsB || (a._incomplete && b._incomplete)) {
            return getTranslatedValue(a.name).localeCompare(getTranslatedValue(b.name))
          }

          return pointsB - pointsA
        }

        return b._incomplete - a._incomplete
      })
    }());

    return filteredAchievements
  }
)


function getEventId(getState) {
  return getState().admin.events.currentEvent.id
}

const { actions, reducers, initialState } = generateModule({
  itemName: 'achievements',
  idFieldName: 'id',
  services: {
    get: serviceGetAchievements,
    add: serviceCreateAchievement,
  },
})

initialState.searchTerm = ''
initialState.currentAchievementId = null

actions.setSearchTerm = (searchTerm) => (dispatch) => dispatch({ type: 'ACHIEVEMENTS_SET_SEARCH_TERM', payload: searchTerm })
reducers['ACHIEVEMENTS_SET_SEARCH_TERM'] = (state, action) => ({ ...state, searchTerm: action.payload })


actions.getAchievement = (achievementId, gameId = 'main') =>
  (dispatch, getState) => {
    dispatch({ type: 'GET_ACHIEVEMENT_START', payload: achievementId })

    return serviceGetAchievement(
      getEventId(getState),
      gameId,
      achievementId,
      (response) => {
        const isKnown = !!achievementsByIdSelector(getState())[achievementId]
        dispatch({ type: 'GET_ACHIEVEMENT_SUCCESS', payload: { achievement: response, isKnown: isKnown } })
      },
      (error) => {
        dispatch({ type: 'GET_ACHIEVEMENT_ERROR', payload: achievementId })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('achievements.get_badge.title'),
          message: error.decription,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['GET_ACHIEVEMENT_SUCCESS'] = (state, action) => ({
  ...state,
  data: action.payload.isKnown
    ? state.data.map(d => (d.id === action.payload.achievement.id ? action.payload.achievement : d))
    : [...state.data, action.payload.achievement],
})


actions.setCurrentAchievement = (achievementId) => (dispatch) => dispatch({ type: 'SET_CURRENT_ACHIEVEMENT', payload: achievementId })
reducers['SET_CURRENT_ACHIEVEMENT'] = (state, action) => ({ ...state, currentAchievementId: action.payload })


actions.get = (gameId = 'main') =>
  (dispatch, getState) => {
    dispatch(actions.getStart())
    return serviceGetAchievements(
      getEventId(getState),
      gameId,
      response => {
        dispatch(actions.getSuccess(response))
      },
      error => {
        dispatch(actions.getError(error))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('achievements.get.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }


actions.createAchievement = (gameId, achievement) =>
  (dispatch, getState) => {
    const createData = { ...achievement }
    delete createData.id

    dispatch({ type: 'CREATE_ACHIEVEMENT_START' })
    return serviceCreateAchievement(
      getEventId(getState),
      gameId,
      createData,
      response => {
        dispatch({ type: 'CREATE_ACHIEVEMENT_SUCCESS', payload: { achievement: response } })
        return Promise.resolve()
      },
      error => {
        dispatch({ type: 'CREATE_ACHIEVEMENT_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('achievements.create.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
        return Promise.reject()
      }
    )
  }

reducers['CREATE_ACHIEVEMENT_START'] = (state) => ({
  ...state,
  isModifying: true,
  didInvalidate: false,
})

reducers['CREATE_ACHIEVEMENT_SUCCESS'] = (state, action) => ({
  ...state,
  isModifying: false,
  data: [...state.data, action.payload.achievement],
})

reducers['CREATE_ACHIEVEMENT_ERROR'] = (state) => ({
  ...state,
  isModifying: false,
  didInvalidate: true,
})


actions.updateAchievement = (gameId, achievement) =>
  (dispatch, getState) => {
    dispatch({ type: 'UPDATE_ACHIEVEMENT_START' })
    return serviceUpdateAchievement(
      getEventId(getState),
      gameId,
      achievement,
      response => {
        dispatch({ type: 'UPDATE_ACHIEVEMENT_SUCCESS', payload: { achievement: response } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('achievements.update.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('achievements.update.success'),
          level: 'success',
          permanent: false,
        }))
        return Promise.resolve()
      },
      error => {
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('achievements.update.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
        return Promise.reject()
      }
    )
  }

reducers['UPDATE_ACHIEVEMENT_START'] = (state) => ({
  ...state,
  isModifying: true,
  didInvalidate: false,
})

reducers['UPDATE_ACHIEVEMENT_SUCCESS'] = (state, action) => ({
  ...state,
  isModifying: false,
  data: state.data.map(d => (d.id !== action.payload.achievement.id ? d : action.payload.achievement)),
})

reducers['UPDATE_ACHIEVEMENT_ERROR'] = (state) => ({
  ...state,
  isModifying: false,
  didInvalidate: true,
})


actions.deleteAchievement = (gameId, achievement) =>
  (dispatch, getState) => {
    dispatch({ type: 'DELETE_ACHIEVEMENT_START' })
    return serviceDeleteAchievement(
      getEventId(getState),
      gameId,
      achievement,
      () => {
        dispatch({ type: 'DELETE_ACHIEVEMENT_SUCCESS', payload: { achievement } })
      },
      error => {
        dispatch({ type: 'DELETE_ACHIEVEMENT_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('achievements.delete.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['DELETE_ACHIEVEMENT_START'] = (state) => ({
  ...state,
  isModifying: true,
  didInvalidate: false,
})

reducers['DELETE_ACHIEVEMENT_SUCCESS'] = (state, action) => ({
  ...state,
  isModifying: false,
  data: state.data.filter(d => d.id !== action.payload.achievement.id),
})

reducers['DELETE_ACHIEVEMENT_ERROR'] = (state) => ({
  ...state,
  isModifying: false,
  didInvalidate: true,
})


export const getAchievements = actions.get
export const getAchievement = actions.getAchievement
export const createAchievement = actions.createAchievement
export const updateAchievement = actions.updateAchievement
export const deleteAchievement = actions.deleteAchievement
export const setSearchTerm = actions.setSearchTerm
export const setCurrentAchievement = actions.setCurrentAchievement

const achievements = reducers.index
export default achievements
