import { serviceGetTags } from 'attendee/services/sessions'
import generateModule from 'common/utils/generateModule'
import { createSelector } from 'reselect'
import { listToMapping } from 'common/utils/componentHelper'
import sort from 'common/utils/sortHelper'

const selectTags = state => state.attendee.tags.data

export const tagsSelector = createSelector(
  selectTags,
  tags => sort(tags, ['name', 'id'])
)

export const tagsByIdSelector = createSelector(selectTags, tags => listToMapping(tags, 'id'))


const tags = generateModule({
  itemName: 'tag',
  itemNameDisplay: 'tag',
  autoRefresh: 60,
  services: {
    get: serviceGetTags,
  },
  app: 'attendee',
})

tags.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isLoaded: false,
  isError: false,
  didInvalidate: true,
  data: [],
})

export default tags
