/* eslint-disable */
import { useEffect, useRef } from 'react'
import { meta } from 'config'
import Moment from 'moment-timezone'
import i18n from 'i18next'

/**
 * Returns the property of an object or undefined if the property does not exist.
 * Works with nested keys and will return undefined if one of the previous key
 * is undefined
 *
 * @param obj Object you want to look into
 * @param key Key you want the value for
 * @return The value for the key or undefined if the key is undefined
 */
export function getProperty(obj, key) {
  return key.split('.').reduce((o, v) => (typeof o === 'undefined' || o === null ? o : o[v]), obj)
}

/**
 * Deletes a property on an object. Works with nested key (key1.key2.key3)
 *
 * @param {Object} obj Object that you want to delete a property from
 * @param {string} key Key that you want deleted from the object
 */
export function deleteProperty(obj, key) {
  const keys = key.split('.')
  let current = obj

  // Don't check the last key, will call delete on it anyway
  for (let i = 0; i < keys.length - 1; i++) {
    current = current[keys[i]]
    if (current == null) {
      return
    }
  }

  delete current[keys[keys.length - 1]]
}

export function getUrlParam(match, paramId) {
  return getProperty(match, `params.${paramId}`)
}

/**
 * Returns a string containing the base class name and props class name, if existing
 *
 * @param {string} className Base className
 * @param {object} props Props containing the className
 * @param {string} classNameKey Key to use to retrieve the className from props
 */
export function appendPropsClassName(className, props, classNameKey = 'className') {
  const classNameValue = getProperty(props, classNameKey)
  return classNameValue ? `${className} ${classNameValue}` : className
}

/**
 * Returns the style object with the new property, if not undefined/null
 *
 * @param {object} style - Current style object to modify
 * @param {string} propertyName - Property name to modify
 * @param {string|number} value - Value to apply
 */
export function applyStyle(style, propertyName, value) {
  if (value == null) return style
  style[propertyName] = value // eslint-disable-line no-param-reassign
  return style
}

/**
 * Will try to call the given function with the passed parameters
 *
 * @param {function} functionToCall
 * @param  {...any} args
 */
export function tryCall(functionToCall, ...args) {
  if (functionToCall && typeof functionToCall === 'function') {
    return functionToCall(...args)
  }
  return undefined
}

export function createPageTitle(title) {
  return Object.assign({}, meta, { title: `${title} - klik` })
}

export function getDatesUniqueDays(startTime, endTime, timezone = 'UTC', textKey = 'name', valueKey = 'value', limitUntilToday = false) {
  const dates = []

  if (!startTime || !endTime) {
    return dates
  }

  const currDate = Moment.unix(startTime)
    .tz(timezone)
    .startOf('day')
  const lastDate = Moment.unix(endTime)
    .tz(timezone)
    .endOf('day')
  const dateLimit = limitUntilToday
    ? Moment.unix(Moment().unix())
      .tz(timezone)
      .endOf('day')
    : lastDate

  const createDateObject = () => {
    const dateObject = {}
    dateObject[textKey] = currDate
      .clone()
      .locale(i18n.language)
      .format('LL')
    dateObject[valueKey] = currDate.clone().unix()
    return dateObject
  }

  dates.push(createDateObject())
  while (currDate.add(1, 'days').isSameOrBefore(dateLimit, 'day')) {
    dates.push(createDateObject())
  }

  return dates
}

/**
 * Takes a duration in seconds and return a string in the provided format.
 * @param {number} seconds - Duration in seconds
 * @param {Object} format - The format you want
 * @param {string} format.hours - Show hours
 * @param {string} format.minutes - Show minutes
 * @param {string} format.seconds - Show seconds
 * @return {string} Formatted string that represents the duration passed
 */
export function formatTime(seconds, format) {
  if (seconds === 0) return 0
  if (!seconds) return '-'

  const timeFormat = { hours: true, minutes: true, seconds: false, ...format }
  const numHours = Math.floor(seconds / 3600)
  let numMinutes = Math.floor((seconds - numHours * 3600) / 60)
  let numSeconds = Math.floor(seconds - numHours * 3600 - numMinutes * 60)
  let formattedTime = ''

  if (timeFormat.hours && numHours) {
    formattedTime += `${numHours}h`
  } else {
    numMinutes += numHours * 60
  }

  if (timeFormat.minutes && numMinutes) {
    formattedTime += `${numMinutes}m`
  } else {
    numSeconds += numMinutes * 60
  }

  if (timeFormat.seconds && numSeconds) {
    formattedTime += `${numSeconds}s`
  }

  return formattedTime
}

// eslint-disable
/**
 * Workaround to fix issues with numbers like 1.005
 * using other methods would yield result: 1 instead of: 1.01
 */
export function formatDecimalNumber(number, decimals = 2, alwaysShowDecimal) {
  if (alwaysShowDecimal) {
    let negative = false;
    let n = number

    if (decimals === undefined) {
      decimals = 0
    }

    if (decimals < 0) {
      negative = true
      n = n * - 1;
    }

    let multiplicator = Math.pow(10, decimals)
    n = parseFloat((n * multiplicator).toFixed(11))
    n = (Math.round(n) / multiplicator).toFixed(decimals)

    if (negative) {
      n = (n * -1).toFixed(decimals)
    }

    return n
  }

  const multiplicator = 10 ** decimals
  let n = parseFloat((number * multiplicator).toFixed(11))
  n = Math.round(n) / multiplicator
  return +n.toFixed(decimals)
}

/**
 * Normalizes a value into percentage. Will either return the percentage as a number
 * or as a string with the percent (%) sign added
 */
export function normalizeAsPercentage(value, total, decimals = 0, asString = true, defaultEmpty = '-') {
  if (value == null) return defaultEmpty
  if (value === 0) return asString ? `${value}%` : value
  
  const normalizedValue = formatDecimalNumber(value / total * 100, decimals, false)
  return asString ? `${normalizedValue}%` : normalizedValue
}


/**
 * Swap 2 elements from an array
 *
 * @param _array The array that you want to do the swap on
 * @param i1 The first index
 * @param i2 The second index
 * @return A copy of the array after the swap
 */
export function swapArray(_array, i1, i2) {
  const array = [..._array]
  array[i1] = array.splice(i2, 1, array[i1])[0]
  return array
}

/**
 * Will check the current environment and will return the value if the environment
 * is NOT in the array of environments
 *
 * @param {*} value
 * @param {Object[]} [environments]
 * @return {(*|null)}
 */
export function nullifyForEnvironments(value, environments = []) {
  if (value == null) throw new Error('You need to pass a component')
  if (!environments || environments.length === 0) throw new Error('You passed no environments. Render the componement normally instead')
  return environments.includes(process.env.NODE_ENV) ? null : value
}

export function replaceVariableInCurrentPath(prefix, variable) {
  const path = window.location.pathname

  if (path.indexOf(prefix) < 0) {
    return prefix + variable
  }

  const prefixIdx = path.indexOf(prefix, 1) + prefix.length
  const prefixPart = path.substr(0, prefixIdx + 1)

  const remainderSlashIndex = (prefixPart + variable).lastIndexOf('/') + 1
  const remainderIdx = path.indexOf('/', remainderSlashIndex)
  const remainderPart = remainderIdx >= 0 ? path.substr(remainderIdx) : ''

  return prefixPart + variable + remainderPart + window.location.search
}

/**
 * Take an array of objects and build a mapping based on a field for easier access
 * @param {Object[]} list - The array of data
 * @param {string} idField - The field you want to use as key for the mapping
 * @param {Object} options
 * @param {boolean} options.copy - Should it copies the item (defaults to false)
 * @param {boolean} options.mapToId - Should it map to it's index only (defaults to false)
 * @returns {Object} Mapping where the key is the "idField" passed as parameter
 */
export function listToMapping(list, idField, options) {
  const _options = { copy: false, mapToId: false, ...options }

  if (!Array.isArray(list)) {
    console.warn('listToMapping(): The list must be an array!')
    return {}
  }

  const mapping = {}
  list.forEach((item, index) => {
    const id = getProperty(item, idField)
    if (!id) return

    let itemToMap = _options.mapToId ? index : item
    if (_options.copy) itemToMap = { ...item }

    mapping[id] = itemToMap
  })

  return mapping
}

export function promisify(func) {
  return new Promise(resolve => {
    func()
    resolve()
  })
}

export function cloneObject(value) {
  if (value == null || typeof value !== 'object') {
    return value
  }

  const clone = new value.constructor()
  Object.keys(value).forEach(key => {
    clone[key] = cloneObject(value[key])
  })

  return clone
}

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
/* eslint-enable */
