import { serviceGetLocations, serviceAddLocation, serviceUpdateLocation, serviceDeleteLocation } from 'admin/services/maps'
import generateModule from 'common/utils/generateModule'
import { showToast } from 'common/modules/toaster'
import { listToMapping } from 'common/utils/componentHelper'
import i18n from 'i18next'
import { createSelector } from 'reselect'
import sort from 'common/utils/sortHelper'


const selectState = (state) => state.admin.locations
const selectLocations = (state) => selectState(state).data

export const locationsSelector = createSelector(selectLocations, (locations) => locations)
export const locationsByIdSelector = createSelector(selectLocations, (locations) => listToMapping(locations, 'name'))

export const locationsByMapIdSelector = createSelector(selectLocations, (locations) => {
  const locationsByMapId = {}

  locations.forEach(location => {
    if (!locationsByMapId[location.map]) {
      locationsByMapId[location.map] = []
    }
    locationsByMapId[location.map].push(location)
  })

  return locationsByMapId
})

export const ZoneByTagIdSelector = createSelector(selectLocations, zones => {
  const zoneByTagId = {}
  zones.forEach(zone => {
    zone.tuning.tags.forEach(tag => {
      zoneByTagId[tag] = zone.display_name
    })
  })
  return zoneByTagId
})
export const sortedLocationsSelector = createSelector(selectLocations, locations => sort(locations, ['display_name', 'id']))


const { actions, reducers, initialState } = generateModule({
  itemName: 'location',
  idFieldName: 'name',
  services: {
    get: serviceGetLocations,
    add: serviceAddLocation,
    upd: serviceUpdateLocation,
    del: serviceDeleteLocation,
  },
})

actions.updateMany = (locations) =>
  (dispatch, getState) => {
    let index = 0
    function updateALocation() {
      if (index >= locations.length) {
        dispatch(actions.get())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('locations.update.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('locations.update.success'),
          level: 'success',
          permanent: false,
        }))
        return
      }
      const location = locations[index]
      dispatch(actions.updateStart(location))
      serviceUpdateLocation(getState().admin.events.currentEvent.id, location,
        (newLocation) => {
          dispatch(actions.updateSuccess(newLocation, 'ok'))
          index++
          updateALocation()
        },
        (error) => {
          dispatch(actions.updateError(error.description))
          dispatch(showToast({ title: i18n.getFixedT(i18n.language, 'admin_customModule')('locations.update.title'), message: error.description, level: 'error', permanent: false }))
        }
      )
    }

    updateALocation()
  }


reducers['CHANGE_EVENT_SUCCESS'] = (state) => ({
  ...state,
  didInvalidate: true,
  data: initialState.data,
})


export const getLocations = actions.get
export const addLocation = actions.add
export const getLocationsIfNeeded = actions.getIfNeeded
export const updateLocations = actions.updateMany
export const updateLocation = actions.update
export const deleteLocation = actions.delete

const locations = reducers.index
export default locations
