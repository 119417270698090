import { serviceGetSpeakers, servicePostSpeaker, serviceUpdateSpeaker, serviceGetSpeaker, serviceDeleteSpeaker, serviceDeleteSpeakers } from 'admin/services/speakers'
import generateModule from 'common/utils/generateModule'
import { showToast } from 'common/modules/toaster'
import search, { buildFieldList } from 'common/utils/search'
import i18n from 'i18next'
import { createSelector } from 'reselect'


const selectState = (state) => state.admin.speakers
const selectSpeakers = (state) => selectState(state).data
const selectCurrentSpeaker = (state) => selectState(state).currentSpeaker
const selectSearchTerm = (state) => selectState(state).searchTerm

export const moduleSelector = createSelector(selectState, (state) => state)
export const currentSpeakerSelector = createSelector(selectCurrentSpeaker, (speaker) => speaker)
export const paginationSelector = createSelector(selectState, (state) => state.pagination)
export const queryParamsSelector = createSelector(selectState, (state) => state.queryParams)

export const filteredSpeakersSelector = createSelector(
  selectSpeakers,
  selectSearchTerm,
  (speakers, searchTerm) => {
    let filteredSpeakers = [...speakers];

    (function filterBySearch() {
      if (!searchTerm) return
      filteredSpeakers = filteredSpeakers.filter((speaker) => search(
        searchTerm,
        (() => {
          const fields = [...buildFieldList(speaker, ['first_name', 'last_name'])]
          speaker.experience.forEach(experience => { fields.push(...buildFieldList(experience, ['company', 'position'])) })
          return fields
        })(),
      ))
    }());

    (function sortByName() {
      filteredSpeakers = filteredSpeakers.sort((a, b) => {
        const nameA = `${a.first_name} ${a.last_name}`
        const nameB = `${b.first_name} ${b.last_name}`
        return nameA.localeCompare(nameB)
      })
    }());

    return filteredSpeakers
  }
)


const { actions, reducers, initialState } = generateModule({
  itemName: 'speaker',
  itemNameDisplay: 'speaker',
  services: {
    get: serviceGetSpeakers,
    upd: serviceUpdateSpeaker,
    del: serviceDeleteSpeaker,
  },
  app: 'admin',
})

initialState.currentSpeaker = undefined
initialState.isFetchingOne = false
initialState.searchTerm = ''
initialState.queryParams = {
  query: undefined,
  sort_column: 'name',
  sort_order: 1,
  per_page: 15,
  page: 1,
}
initialState.pagination = {
  page: 1,
  pageCount: 1,
}


actions.changePage = (page) => (dispatch) => (dispatch({ type: 'speakers/CHANGE_PAGE', payload: page }))
reducers['speakers/CHANGE_PAGE'] = (state, action) => ({
  ...state,
  pagination: {
    ...state.pagination,
    page: action.payload,
  },
  queryParams: {
    ...state.queryParams,
    page: action.payload,
  },
})


actions.get = (overwriteQueryParams = {}) =>
  (dispatch, getState) => {
    const currEvent = getState().admin.events.currentEvent
    const queryParam = { ...selectState(getState()).queryParams, ...overwriteQueryParams }

    dispatch(actions.getStart())

    return serviceGetSpeakers(
      currEvent.id,
      queryParam,
      response => {
        dispatch({
          type: 'GET_SPEAKERS_SUCCESS',
          payload: {
            data: response.data,
            page: response.page,
            pageCount: response.pageCount,
          },
        })
      },
      error => {
        dispatch(actions.getError(error.description))
      },
    )
  }


reducers['GET_SPEAKERS_START'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isModifying: true,
  isError: false,
})

reducers['GET_SPEAKERS_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  data: action.payload.data,
  pagination: {
    page: action.payload.page,
    pageCount: action.payload.pageCount,
  },
  isModifying: false,
  isError: false,
})

reducers['GET_SPEAKERS_ERROR'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isModifying: false,
  isError: true,
})

actions.setSearchTerm = (searchTerm) => (dispatch) => dispatch({ type: 'speakers/SET_SEARCH_TERM', payload: searchTerm })
reducers['speakers/SET_SEARCH_TERM'] = (state, action) => ({ ...state, queryParams: { ...state.queryParams, query: action.payload, page: 1 } })

actions.updateSpeaker = (speaker) =>
  (dispatch, getState) => {
    dispatch(actions.updateStart(speaker))
    return serviceUpdateSpeaker(
      getState().admin.events.currentEvent.id, speaker,
      response => {
        dispatch(actions.updateSuccess(response))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('speakers.update.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('speakers.update.success', { name: response.first_name + ' ' + response.last_name }),
          level: 'success',
          permanent: false,
        }))
      },
      error => {
        dispatch(actions.updateError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('speakers.update.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

actions.createSpeaker = (speaker) =>
  (dispatch, getState) => {
    dispatch({ type: 'CREATE_SPEAKER_START' })
    return servicePostSpeaker(getState().admin.events.currentEvent.id, speaker,
      response => {
        dispatch({ type: 'CREATE_SPEAKER_SUCCESS', payload: { speaker: response } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('speakers.create.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('speakers.create.success', { name: response.first_name + ' ' + response.last_name }),
          level: 'success',
          permanent: false,
        }))
        dispatch(actions.get())
      },
      error => {
        dispatch({ type: 'CREATE_SPEAKER_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('speakers.create.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['CREATE_SPEAKER_START'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isModifying: true,
  isError: false,
})

reducers['CREATE_SPEAKER_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isModifying: false,
  isError: false,
})

reducers['CREATE_SPEAKER_ERROR'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isModifying: false,
  isError: true,
})

actions.getSpeaker = (speakerId) =>
  (dispatch, getState) => {
    dispatch({ type: 'GET_SPEAKER_START' })
    return serviceGetSpeaker(getState().admin.events.currentEvent.id, speakerId,
      response => {
        dispatch({ type: 'GET_SPEAKER_SUCCESS', payload: { speaker: response } })
      },
      error => {
        dispatch({ type: 'GET_SPEAKER_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('speakers.get.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['GET_SPEAKER_START'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isFetchingOne: true,
  isError: false,
  isLoaded: false,
})

reducers['GET_SPEAKER_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  isFetchingOne: false,
  isError: false,
  isLoaded: true,
  currentSpeaker: action.payload.speaker,
})

reducers['GET_SPEAKER_ERROR'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isFetchingOne: false,
  isError: true,
  isLoaded: true,
})


actions.deleteSpeakers = () =>
  (dispatch, getState) => {
    dispatch({ type: 'DELETE_SPEAKERS_START' })
    return serviceDeleteSpeakers(
      getState().admin.events.currentEvent.id,
      () => {
        dispatch({ type: 'DELETE_SPEAKERS_SUCCESS' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('speakers.delete_all.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('speakers.delete_all.success'),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch({ type: 'DELETE_SPEAKERS_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('speakers.delete_all.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['DELETE_SPEAKERS_START'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isError: false,
  isLoaded: false,
})

reducers['DELETE_SPEAKERS_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isError: false,
  isLoaded: true,
  data: [],
})

reducers['DELETE_SPEAKERS_ERROR'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  isError: true,
  isLoaded: true,
})


actions.clearCurrentSpeaker = () => (dispatch) => dispatch({ type: 'CLEAR_CURRENT_SPEAKER' })
reducers['CLEAR_CURRENT_SPEAKER'] = (state) => ({ ...state, currentSpeaker: null })


export const getSpeakers = actions.get
export const createSpeaker = actions.createSpeaker
export const updateSpeaker = actions.updateSpeaker
export const getSpeaker = actions.getSpeaker
export const deleteSpeaker = actions.delete
export const deleteSpeakers = actions.deleteSpeakers
export const clearCurrentSpeaker = actions.clearCurrentSpeaker
export const setSearchTerm = actions.setSearchTerm
export const changePage = actions.changePage

export default reducers.index
