import { generateDataModule } from './generateModule'
import { serviceCreateCustomPage, serviceCreateCustomPageChild, serviceDeleteCustomPage, serviceGetCustomPage, serviceGetCustomPages, serviceReorderCustomPageChildren, serviceUpdateCustomPage } from 'admin/services/customPages'
import { addSortingSupport } from './Dashboard/helper'
import sort from 'common/utils/sortHelper'


const CustomPagesModule = generateDataModule({
  initialState: {
    searchTerm: '',
  },
  moduleKey: 'cpo/customPages',
  reducerKey: 'admin.customPages',
  refreshTime: 10000,

  services: {
    get: { service: serviceGetCustomPages },
    post: { service: serviceCreateCustomPage },
    put: { service: serviceUpdateCustomPage },
    delete: { service: serviceDeleteCustomPage },
  },
});

addSortingSupport(CustomPagesModule, { id: 'title', order: 1 })


// //////////////////////////////////////////////////////////////////////////////////////
// Helper functions
// //////////////////////////////////////////////////////////////////////////////////////

function updatePageList(pages, page) {
  let pageIndex = -1

  for (let i = 0; i < pages.length; i++) {
    if (pages[i].id === page.id) {
      pageIndex = i
      break
    }
  }

  if (pageIndex === -1) {
    pages.push(page)
  } else {
    pages[pageIndex] = page // eslint-disable-line no-param-reassign
  }

  return pages
}

function deleteChildPage(pages, pageId) {
  for (let i = 0; i < pages.length; i++) {
    if (pages[i].id === pageId) {
      pages.splice(i, 1)
      return pages
    }

    if (pages[i].children && pages[i].children.length > 0) {
      deleteChildPage(pages[i].children, pageId)
    }
  }

  return pages
}


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

CustomPagesModule.registerSelector('searchTerm', (state, moduleState) => moduleState.searchTerm, searchTerm => searchTerm)

CustomPagesModule.registerSelector(
  'rootPages',
  [
    (state, moduleState) => moduleState.data,
    state => CustomPagesModule.selectors.sorter(state),
  ],
  (customPages, sorter) => sort(
    customPages.filter(page => (page.parents || []).length === 0),
    [sorter],
  )
)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////

CustomPagesModule.registerAction('setSearchTerm', searchTerm => dispatch => dispatch({
  type: CustomPagesModule.actionKeys.setSearchTerm,
  payload: searchTerm,
}))

CustomPagesModule.registerDataAction('getPage', pageId => dispatch => {
  dispatch({
    type: CustomPagesModule.actionKeys.getPageStart,
    payload: pageId,
  })

  return serviceGetCustomPage(CustomPagesModule._getEventId(), pageId)
    .then(response => {
      dispatch({
        type: CustomPagesModule.actionKeys.getPageSuccess,
        payload: response,
      })
    })
    .catch(error => {
      dispatch({
        type: CustomPagesModule.actionKeys.getPageError,
        payload: pageId,
      })
      return Promise.reject(error)
    })
})

CustomPagesModule.registerDataAction('post', page => dispatch => {
  dispatch({
    type: CustomPagesModule.actionKeys.postStart,
    payload: page,
  })

  return CustomPagesModule._getService('post')(page)
    .then(response => {
      dispatch({
        type: CustomPagesModule.actionKeys.postSuccess,
        payload: response,
      })
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({
        type: CustomPagesModule.actionKeys.postError,
        payload: page,
      })
      return Promise.reject(error)
    })
})

CustomPagesModule.registerDataAction('postChild', (pageId, page) => dispatch => {
  dispatch({
    type: CustomPagesModule.actionKeys.postChildStart,
    payload: { page, pageId },
  })

  return serviceCreateCustomPageChild(CustomPagesModule._getEventId(), pageId, page)
    .then(response => {
      dispatch({
        type: CustomPagesModule.actionKeys.postChildSuccess,
        payload: response,
      })
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({
        type: CustomPagesModule.actionKeys.postChildError,
        payload: { page, pageId },
      })
      return Promise.reject(error)
    })
})

CustomPagesModule.registerDataAction('put', page => dispatch => {
  dispatch({
    type: CustomPagesModule.actionKeys.putStart,
    payload: page,
  })

  return CustomPagesModule._getService('put')(page)
    .then(response => {
      dispatch({
        type: CustomPagesModule.actionKeys.putSuccess,
        payload: response,
      })
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({
        type: CustomPagesModule.actionKeys.putError,
        payload: page,
      })
      return Promise.reject(error)
    })
})

CustomPagesModule.registerDataAction('reorderChildren', (pageId, childrenIds) => dispatch => {
  dispatch({
    type: CustomPagesModule.actionKeys.reorderChildrenStart,
    payload: { childrenIds, pageId },
  })

  return serviceReorderCustomPageChildren(CustomPagesModule._getEventId(), pageId, childrenIds)
    .then(response => {
      dispatch({
        type: CustomPagesModule.actionKeys.reorderChildrenSuccess,
        payload: response,
      })
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({
        type: CustomPagesModule.actionKeys.reorderChildrenError,
        payload: { childrenIds, pageId },
      })
      return Promise.reject(error)
    })
})


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////

CustomPagesModule.registerReducer(
  CustomPagesModule.actionKeys.setSearchTerm,
  (state, action) => ({ ...state, searchTerm: action.payload })
)

CustomPagesModule.registerReducer(
  CustomPagesModule.actionKeys.getPageSuccess,
  (state, action) => ({
    ...state,
    data: updatePageList([...state.data], action.payload),
  })
)

CustomPagesModule.registerReducer(
  CustomPagesModule.actionKeys.postSuccess,
  (state, action) => ({
    ...state,
    data: updatePageList([...state.data], action.payload),
    isModifying: false,
    lastUpdated: Date.now(),
  }),
)

CustomPagesModule.registerReducer(
  CustomPagesModule.actionKeys.putSuccess,
  (state, action) => ({
    ...state,
    data: updatePageList([...state.data], action.payload),
    isModifying: false,
    lastUpdated: Date.now(),
  }),
)

CustomPagesModule.registerReducer(
  CustomPagesModule.actionKeys.deleteSuccess,
  (state, action) => ({
    ...state,
    data: deleteChildPage([...state.data], action.payload),
    isModifying: false,
    lastUpdated: Date.now(),
  })
)

CustomPagesModule.registerReducer(
  CustomPagesModule.actionKeys.postChildSuccess,
  (state, action) => ({
    ...state,
    data: updatePageList([...state.data], action.payload),
  })
)

CustomPagesModule.registerReducer(
  CustomPagesModule.actionKeys.reorderChildrenSuccess,
  (state, action) => ({
    ...state,
    data: updatePageList([...state.data], action.payload),
  })
)


export default CustomPagesModule
