import { generateDataModule } from './generateModule'
import { serviceGetAdminAccounts } from 'admin/services/users'
import { getUserRoleId } from 'admin/components/AccountRoleSelector/RoleHelper'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'
import sort from 'common/utils/sortHelper'

import { currentEventIdSelector } from 'admin/modules/events'


const UserManagementModule = generateDataModule({
  moduleKey: 'cpo/userManagement',
  reducerKey: 'admin.userManagement',

  services: {
    get: { service: serviceGetAdminAccounts },
  },
})


UserManagementModule.registerSelector(
  'sortedUsers',
  (state, moduleState) => moduleState.data,
  users => sort(users, ['first_name', 'last_name', 'email', 'id'])
)

UserManagementModule.registerSelector(
  'sortedUsersByPermission',
  [
    UserManagementModule.selectors.sortedUsers,
    currentEventIdSelector,
  ],
  (users, eventId) => {
    const userByPermission = {}

    users.forEach(user => {
      const permission = getUserRoleId(user.event_permissions, eventId)

      if (!userByPermission[permission]) {
        userByPermission[permission] = []
      }

      userByPermission[permission].push(user)
    })

    return userByPermission
  }
)


UserManagementModule.actions.get = (query = '') => (dispatch) => {
  const eventId = UserManagementModule._getEventId()
  dispatch({ type: UserManagementModule.actionKeys.getStart })

  return serviceGetAdminAccounts(eventId, { query, managing_event: eventId })
    .then(response => dispatch({ type: UserManagementModule.actionKeys.getSuccess, payload: response }))
    .catch(error => {
      dispatch({ type: UserManagementModule.actionKeys.getError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('account_management.get.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject(error)
    })
}


export default UserManagementModule
