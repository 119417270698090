import { eventDaysSelector, currentEventSelector, eventTimezoneSelector } from 'admin/modules/events'
import { isEventOver } from 'common/utils/eventHelper'
import moment from 'moment'

const sortingDefaultOptions = {
  baseKey: 'sorter',
  resetKey: 'resetSorter',
  setKey: 'setSorter',
}

/**
 * Add support functionality to a redux module. Creates basic actions, reducers
 * and selectors. Be aware that this is only a frontend sort.
 *
 * @param {Object} reduxModule
 * @param {Object} initialSorter
 * @param {string} initialSorter.id
 * @param {number} initialSorter.order (1 or -1)
 * @param {Object} options
 * @param {string} options.baseKey - Key used for the selector and the state
 * @param {string} options.resetKey - Key used for the reset action
 * @param {string} options.setKey - Key used for the set action
 */
export function addSortingSupport(reduxModule, initialSorter, options) {
  const _options = { ...sortingDefaultOptions, ...options }

  reduxModule.initialState[_options.baseKey] = { ...initialSorter } // eslint-disable-line no-param-reassign

  reduxModule.registerSelector(
    _options.baseKey,
    (state, moduleState) => moduleState[_options.baseKey],
    sorter => sorter
  )

  reduxModule.registerAction(
    _options.setKey,
    (id, order) => dispatch => {
      dispatch({ type: reduxModule.actionKeys[_options.setKey], payload: { id, order } })
      return Promise.resolve()
    }
  )

  reduxModule.registerReducer(
    reduxModule.actionKeys[_options.setKey],
    (state, action) => ({
      ...state,
      [_options.baseKey]: { id: action.payload.id, order: action.payload.order },
    })
  )

  reduxModule.registerAction(
    _options.resetKey,
    () => dispatch => {
      dispatch({ type: reduxModule.actionKeys[_options.resetKey] })
      return Promise.resolve()
    }
  )

  reduxModule.registerReducer(
    reduxModule.actionKeys[_options.resetKey],
    state => ({ ...state, [_options.baseKey]: reduxModule.initialState[_options.baseKey] })
  )
}

export function addSearchSupport(reduxModule) {
  reduxModule.registerSelector('search', (state, moduleState) => moduleState.search, searchTerm => searchTerm)
  reduxModule.registerAction('setSearch', searchTerm => dispatch => {
    dispatch({ type: reduxModule.actionKeys.setSearch, payload: searchTerm })
    return Promise.resolve()
  })
  reduxModule.registerReducer(
    reduxModule.actionKeys.setSearch,
    (state, action) => ({ ...state, search: action.payload })
  )
}

export function addPaginationSupport(reduxModule) {
  reduxModule.registerSelector('pagination', (state, moduleState) => moduleState.pagination, pagination => pagination)
  reduxModule.registerSelector('queryParams', (state, moduleState) => moduleState.queryParams, queryParams => queryParams)
  reduxModule.registerAction(
    'changePage',
    (page) => dispatch => {
      dispatch({
        type: reduxModule.actionKeys.changePage,
        payload: page,
      })
      return Promise.resolve()
    }
  )

  reduxModule.registerReducer(
    reduxModule.actionKeys.changePage,
    (state, action) => ({
      ...state,
      pagination: {
        ...state.pagination,
        page: action.payload,
      },
      queryParams: {
        ...state.queryParams,
        page: action.payload,
      },
    })
  )
}

export function addTimeFilterSupport(reduxModule, shouldFetchOnChange) {
  reduxModule.initialState.timeFilters = {} // eslint-disable-line no-param-reassign
  reduxModule.initialState.areTimeFiltersInitialized = false // eslint-disable-line no-param-reassign

  reduxModule.registerReducer(
    'CHANGE_EVENT_SUCCESS',
    () => reduxModule.initialState
  )
  reduxModule.registerSelector(
    'timeFilters',
    (state, moduleState) => moduleState.timeFilters,
    timeFilters => timeFilters
  )

  reduxModule.registerSelector(
    'minimalFilters',
    (state, moduleState) => moduleState.minimalFilters,
    minimalFilters => minimalFilters
  )

  reduxModule.registerSelector(
    'areTimeFiltersInitialized',
    (state, moduleState) => moduleState.areTimeFiltersInitialized,
    areTimeFiltersInitialized => areTimeFiltersInitialized
  )

  reduxModule.registerSelector(
    'isEventOver',
    (state, moduleState) => moduleState.isEventOver,
    eventOver => eventOver
  )
  reduxModule.registerSelector(
    'isLive',
    (state, moduleState) => moduleState.isLive,
    isLive => isLive
  )

  reduxModule.registerSelector(
    'currentDayStartTime',
    (state, moduleState) => moduleState.currentDayStartTime,
    currentDayStartTime => currentDayStartTime
  )

  reduxModule.registerSelector(
    'range',
    (state, moduleState) => moduleState.timeFilters,
    timeFilters => timeFilters.range
  )

  reduxModule.registerSelector(
    'timeFiltersDate',
    state => reduxModule.selectors.timeFilters(state),
    data => ({ startDate: data.startDate, endDate: data.endDate })
  )


  reduxModule.registerAction(
    'initializeFilters', // initialize filters for expanded cards
    () => (dispatch, getState) => {
      const eventOver = isEventOver(currentEventSelector(getState()))
      const eventDays = eventDaysSelector(getState())
      let startDate
      let endDate
      let currentDayStartTime

      if (!eventOver) {
        const timezone = eventTimezoneSelector(getState())
        currentDayStartTime = moment.tz(timezone).startOf('day').unix()
        startDate = currentDayStartTime
      } else {
        const lastDay = eventDays.slice(-1).pop()
        startDate = lastDay.start
        endDate = lastDay.end
      }

      const initialQuery =
      {
        startDate: startDate,
        endDate: endDate,
        timeOfDay: 'entire',
        isLive: false,
        isEventOver: eventOver,
        currentDayStartTime: currentDayStartTime,
        // range: showLiveData ? 60 * 24 : null,
      }
      dispatch({
        type: reduxModule.actionKeys.initializeFilters,
        payload: initialQuery,
      })
    }
  )

  reduxModule.registerReducer(
    reduxModule.actionKeys.initializeFilters,
    (state, action) => ({
      ...state,
      timeFilters: {
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeOfDay: action.payload.timeOfDay,
        range: action.payload.range,
      },
      areTimeFiltersInitialized: true,
      isLive: action.payload.isLive,
      isEventOver: action.payload.isEventOver,
      currentDayStartTime: action.payload.currentDayStartTime,
    })
  )

  reduxModule.registerAction(
    'initializeMinimalFilters', // initialize filters for non expanded cards
    () => (dispatch, getState) => {
      const eventOver = isEventOver(currentEventSelector(getState()))
      const eventDays = eventDaysSelector(getState())
      let startDate
      let endDate
      if (!eventOver) {
        const timezone = eventTimezoneSelector(getState())
        startDate = moment.tz(timezone).startOf('day').unix()
      } else {
        const lastDay = eventDays.slice(-1).pop()
        startDate = lastDay.start
        endDate = lastDay.end
      }

      const initialQuery =
      {
        startDate: startDate,
        endDate: endDate,
        timeOfDay: 'entire',
      }
      dispatch({
        type: reduxModule.actionKeys.initializeMinimalFilters,
        payload: initialQuery,
      })
    }
  )

  reduxModule.registerReducer(
    reduxModule.actionKeys.initializeMinimalFilters,
    (state, action) => ({
      ...state,
      minimalFilters: {
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeOfDay: action.payload.timeOfDay,
        range: action.payload.range,
      },
    })
  )

  reduxModule.registerAction(
    'liveToggle',
    (isLive) => (dispatch) => {
      dispatch({
        type: reduxModule.actionKeys.liveToggle,
        payload: isLive,
      })
      return dispatch(reduxModule.actions.get())
    }
  )

  reduxModule.registerReducer(
    reduxModule.actionKeys.liveToggle,
    (state, action) => {
      const range = action.payload ? 60 * 24 : null
      return {
        ...state,
        isLive: action.payload,
        timeFilters: ({ ...state.timeFilters, range: range }),
      }
    }
  )

  reduxModule.registerAction(
    'setTimeFilters',
    (query) => dispatch => {
      dispatch({ type: reduxModule.actionKeys.setTimeFilters, payload: query })
      return shouldFetchOnChange
        ? dispatch(reduxModule.actions.get())
        : Promise.resolve()
    }
  )
  reduxModule.registerReducer(
    reduxModule.actionKeys.setTimeFilters,
    (state, action) => ({ ...state, timeFilters: ({ ...state.timeFilters, ...action.payload }) })
  )
}

