import { DataModule } from 'redux-data-module'
import { serviceGetCustomPages, serviceGetCustomPagesChildren } from 'attendee/services/customPages'
import { eventIdSelector } from 'attendee/modules/events'
import { handleErrorMessage } from './index'

const CustomPagesModule = new DataModule({
  initialState: {},
  moduleKey: 'uf/customPages',
  reducerKey: 'attendee.customPages',

  services: {
    get: { service: serviceGetCustomPages },
  },
})

CustomPagesModule.registerSelector(
  'customPagesMenuItems',
  (state, moduleState) => moduleState.data,
  customPages => customPages
)

CustomPagesModule.registerSelector(
  'idToData',
  (state, moduleState) => moduleState.data,
  customPages => {
    const obj = {}
    customPages.forEach(elem => {
      obj[elem.id] = elem
    })
    return obj;
  }
)

CustomPagesModule.actions.get = () => (dispatch, getState) => {
  const state = getState()

  dispatch({ type: CustomPagesModule.actionKeys.getStart })

  return serviceGetCustomPages(eventIdSelector(state))
    .then(response => {
      dispatch({ type: CustomPagesModule.actionKeys.getSuccess, payload: response })
    })
    .catch(error => {
      dispatch({ type: CustomPagesModule.actionKeys.getError })
      handleErrorMessage(dispatch, getState, error)
      return Promise.reject()
    })
}

CustomPagesModule.registerDataAction('getCustomChildren', (pageId) => (dispatch, getState) => {
  dispatch({ type: CustomPagesModule.actionKeys.getCustomChildrenStart })

  return serviceGetCustomPagesChildren(eventIdSelector(getState()), pageId)
    .then(response => {
      dispatch({ type: CustomPagesModule.actionKeys.getCustomChildrenSuccess, payload: response })
    })
    .catch(error => {
      dispatch({ type: CustomPagesModule.actionKeys.getCustomChildrenError })
      handleErrorMessage(dispatch, getState, error)
      return Promise.reject(error)
    })
})

CustomPagesModule.registerReducer(CustomPagesModule.actionKeys.getCustomChildrenSuccess, (state, action) => ({
  ...state,
  children: action.payload,
}))


export default CustomPagesModule
