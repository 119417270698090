import axios from 'axios'
import { apiErrorHandler, apiCatchError, buildError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { parsePaginationHeaders } from 'common/services/helper'
import { buildQueryParams, getAxiosConfig } from './helper'


export function translateAttendee(attendee) {
  const _attendee = { ...attendee }

  if (_attendee.group) {
    _attendee.group = {
      ..._attendee.group,
      title: getTranslatedObject(_attendee.group.title),
    }
  }

  if (_attendee.call_to_actions) {
    _attendee.call_to_actions = _attendee.call_to_actions.map(cta => ({
      ...cta,
      label: getTranslatedObject(cta.label),
    }))
  }

  return _attendee
}

export function buildAttendeesQueryString(params) {
  const regularKeys = new Set(['page', 'query', 'sort_column', 'sort_order'])
  let queryString = ''
  let queryStringFilter = ''

  if (params) {
    const paramKeys = Object.keys(params)

    if (paramKeys.length > 0) {
      queryString += '?'
    }

    paramKeys.forEach(paramKey => {
      if (params[paramKey] != null) {
        if (regularKeys.has(paramKey)) {
          queryString += `&${paramKey}=${params[paramKey]}`
        } else if (params[paramKey].length > 0) {
          if (queryStringFilter.length < 1) {
            queryStringFilter += '&filter='
          }
          queryStringFilter += `${paramKey}:${params[paramKey]}|`
        }
      }
    })
  }

  return queryString + queryStringFilter
}

export function serviceGetAttendeeFilterAvailableFields(eventId) {
  return axios
    .get(`/events/${eventId}/attendees/filters`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetAttendees(eventId, params) {
  return axios
    .get(`/events/${eventId}/attendees${buildAttendeesQueryString(params)}`, getAxiosConfig())
    .then(response => ({
      data: response.data,
      ...parsePaginationHeaders(response),
    }))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceDeleteAttendee(eventId, attendeeId) {
  return axios
    .delete(`/events/${eventId}/attendees/${attendeeId}`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetUsers(eventId, query, sortColumn, sortOrder, success, error) {
  const axiosConfig = {
    ...getAxiosConfig(),
    params: {
      ...(query ? { query } : undefined),
      ...(sortColumn ? { sort_column: sortColumn } : undefined),
      ...(sortOrder ? { sort_order: sortOrder } : undefined),
    },
  }

  return axios
    .get(`/events/${eventId}/attendees`, axiosConfig)
    .then(response => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceGetProfileStats(eventId, attendeeId, success, error) {
  return axios
    .get(`/events/${eventId}/attendees/${attendeeId}/stats`, getAxiosConfig())
    .then(response => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceGetProfile(eventId, profileId, success, error) {
  return axios
    .get(`/events/${eventId}/attendees/${profileId}`, getAxiosConfig())
    .then(response => Promise.resolve(translateAttendee(response.data)))
    .then(data => success(data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceGetAttendeeLocation(eventId, attendeeId, success, error) {
  return axios
    .get(`/events/${eventId}/attendees/${attendeeId}/location`, getAxiosConfig())
    .then(response => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceGetAccount(success, error) {
  return axios
    .get('/accounts/me', getAxiosConfig())
    .then(response => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceGetDocToken(eventId) { // eslint-disable-line
  return axios.get('/docs/token', getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceAddAttendee(eventId, profile, success, error) {
  return axios
    .post(`/events/${eventId}/attendees`, profile, getAxiosConfig())
    .then(response => success(profile, response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceAddAdmin(eventId, profile, success, error) {
  // doesn't need eventId, just adding it here for consistency - easier to call from addProfile
  const p = {
    first_name: profile.first_name,
    last_name: profile.last_name,
    email: profile.email,
    password: profile.password,
    custom_fields: profile.custom_fields,
    mobile_phone: profile.mobile_phone,
    group: profile.group,
    event_permissions: profile.event_permissions,
  }

  return axios
    .post('/accounts', JSON.stringify(p), getAxiosConfig())
    .then(response => success(p, response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceUpdateProfile(eventId, profile, params, success, error) {
  let queryString = ''
  if (params) {
    Object.keys(params).forEach(key => {
      queryString += params[key] ? `&${key}=${params[key]}` : ''
    })
  }

  return axios
    .put(`/events/${eventId}/attendees/${profile.id}?${queryString}`, JSON.stringify(profile), getAxiosConfig())
    .then(response => Promise.resolve(translateAttendee(response.data)))
    .then(data => success(data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceDeleteDevice(eventId, profileId, deviceId, success, error) {
  return axios
    .delete(`/events/${eventId}/attendees/${profileId}/devices/${deviceId}`, getAxiosConfig())
    .then(response => success(deviceId, response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceDeleteCredential(eventId, profileId, credential, success, error) {
  return axios
    .delete(`/events/${eventId}/attendees/${profileId}/credentials/${credential}`, getAxiosConfig())
    .then(response => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceResetPassword(eventId, profileId, password) {
  return axios
    .put(`/accounts/${profileId}`, { password: password }, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetActivities(eventId, profileId, success, error) {
  return axios
    .get(`/events/${eventId}/attendees/${profileId}/activities`, getAxiosConfig())
    .then(response => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceAddActivity(eventId, profileId, activity, success, error) {
  return axios
    .post(`/events/${eventId}/attendees/${profileId}/activities`, JSON.stringify(activity), getAxiosConfig())
    .then(response => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceGetAdminAccounts(eventId, queryParams) {
  const queryString = buildQueryParams(queryParams)

  return axios.get(`/accounts${queryString}`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceCreateAdminAccount(eventId, account) {
  return axios.post('/accounts', account, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceUpdateAdminAccount(eventId, account, params) {
  const data = { ...account }
  const id = data.id
  delete data.id

  const queryString = buildQueryParams(params)

  return axios.put(`/accounts/${id}${queryString}`, data, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceDeleteAdminAccount(eventId, accountId) {
  return axios.delete(`/accounts/${accountId}`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceAddPermission(eventId, data) {
  return axios.put(`/accounts/${data.accountId}/roles/${eventId}`, data.role, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceDeletePermission(eventId, accountId) {
  return axios.delete(`/accounts/${accountId}/roles/${eventId}`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceAddAchievement(eventId, accountId, achievementId, success, error) {
  return axios
    .post(`events/${eventId}/attendees/${accountId}/achievements`, { id: achievementId }, getAxiosConfig())
    .then(response => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceGetAttendeeLogs(eventId, attendeeId, success, error) {
  return axios
    .get(`/events/${eventId}/audit_logs?target_type=account&target_id=${attendeeId}`, getAxiosConfig())
    .then(response => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}
