import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getQueryParam } from 'common/components/Link'
import i18n from 'i18next'
import handlePageLoader from 'common/utils/decorators/PageLoader'

import * as eventsAC from 'admin/modules/events'
import * as infrasAC from 'admin/modules/infras'
import * as mapsAC from 'admin/modules/venueMaps'
import * as trackingTagCountsAC from 'admin/modules/trackingTagCounts'

import LoadedComponent from 'common/utils/loadedComponent'
import Heatmap from 'common/components/FloorMap/Heatmap'

import { styles } from './styles.scss'


@connect(
  state => ({
    mapsState: state.admin.venueMaps,
    infrasState: state.admin.infras,
    trackingTagCountsState: state.admin.trackingTagCounts,
  }),
  dispatch => bindActionCreators({
    getMap: mapsAC.getMap,
    getInfras: infrasAC.getInfras,
    getTrackingTagCounts: trackingTagCountsAC.getTrackingTagCounts,
    changeEvent: eventsAC.changeEvent,
  }, dispatch)
)
@handlePageLoader((self, nextProps) => nextProps.mapsState.isLoaded)
export default class FullscreenHeatmap extends Component {
  constructor(props) {
    super(props)

    this.state = {
      resolution: this.getMaxResolution(),
    }
  }

  componentWillMount() {
    const language = getQueryParam(this.props.location, 'language') || 'en'
    i18n.changeLanguage(language)

    this.props.changeEvent(this.props.match.params.event_id, false)
    this.getMapData(this.props.match.params.event_id, this.props.match.params.map_id)
    this.getTrackingData(this.props.match.params.event_id)
  }

  componentDidMount() {
    this.startDataInterval()
    window.addEventListener('resize', this.onWindowResized)
  }

  componentWillUnmount() {
    this.stopDataInterval()
    window.removeEventListener('resize', this.onWindowResized)
  }

  getMaxResolution = () => Math.max(window.innerWidth, window.innerHeight)

  onWindowResized = () => {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout)
    }

    this.resizeTimeout = setTimeout(() => {
      const resolution = this.getMaxResolution()
      if (resolution !== this.state.resolution) {
        this.setState({ resolution: resolution })
      }

      this.resizeTimeout = null
    }, 300)
  }

  getMapData = (eventId, mapId) => {
    this.props.getMap(mapId)
    this.props.getInfras(eventId)
  }

  getTrackingData = (eventId) => {
    this.props.getTrackingTagCounts(eventId)
  }

  startDataInterval = () => {
    this.trackingInterval = setInterval(() => { this.getTrackingData(this.props.match.params.event_id) }, 5000)
    this.mapInterval = setInterval(() => { this.getMapData(this.props.match.params.event_id, this.props.match.params.map_id) }, 30000)
  }

  stopDataInterval = () => {
    this.trackingInterval = clearInterval(this.trackingInterval)
    this.mapInterval = clearInterval(this.mapInterval)
  }

  render() {
    const { resolution } = this.state
    const { mapsState, infrasState, trackingTagCountsState } = this.props

    return (
      <div className={styles}>
        <LoadedComponent monitored={[mapsState]}>
          <Heatmap
            map={mapsState.currentMap}
            tagCounts={trackingTagCountsState.data}
            tags={infrasState.data.filter(i => i.type === 'tag')}
            heatmapConfig={{
              canvasResolution: resolution,
            }}
          />
        </LoadedComponent>
      </div>
    )
  }
}
