import axios from 'axios'
import config from 'config'
import { buildError } from 'common/utils/api'
import { buildQueryParams, getAxiosConfig } from './helper'
import { store } from '../../index'
import { translateInfra } from './maps'
import { getProperty, listToMapping } from 'common/utils/componentHelper'
import { getTranslatedValue } from 'common/utils/translator'
import Moment from 'moment'

import { parsePaginationHeaders } from 'common/services/helper'
import { eventTimezoneSelector } from 'admin/modules/events'


function getWeekDates(weekAgo = 0, startKey = 'start_date', endKey = 'end_date') {
  const timezone = eventTimezoneSelector(store.getState())
  const currentMoment = Moment().tz(timezone)

  let daysToSubtract = 7 * weekAgo
  const endDate = currentMoment.clone().subtract(daysToSubtract, 'days').endOf('day').unix()

  daysToSubtract += 6
  const startDate = currentMoment.clone().subtract(daysToSubtract, 'days').startOf('day').unix()

  return { [endKey]: endDate, [startKey]: startDate }
}


function getHighRiskComparisons(url, params) {
  return axios.get(url + buildQueryParams({ ...params, timestamp: true }), getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(error))
}

function formatHighRiskEmployees(data) {
  const formattedData = {}

  data.forEach(employee => {
    if (employee.account) {
      formattedData[employee.account.id] = {
        contagionScore: employee.score,
        id: employee.account.id,
        name: employee.account.display_name,
      }
    }
  })

  return formattedData
}

export function serviceGetHighRiskEmployeesComparison(eventId) {
  const url = `/events/${eventId}/trace/attendees/connectivity`

  return Promise.all([
    getHighRiskComparisons(url, getWeekDates(0)),
    getHighRiskComparisons(url, getWeekDates(1)),
  ])
    .then(([currentData, previousData]) => ({ currentWeek: formatHighRiskEmployees(currentData), previousWeek: formatHighRiskEmployees(previousData) }))
    .catch(error => Promise.reject(buildError(error)))
}

function formatHighRiskZones(data) {
  const formattedData = {}

  data.forEach(entry => {
    const zone = entry.location
    if (!zone) return

    formattedData[zone.name] = entry.score
  })

  return formattedData
}

export function serviceGetHighRiskZonesComparison(eventId) {
  const url = `/events/${eventId}/trace/zones/connectivity`

  return Promise.all([
    getHighRiskComparisons(url, getWeekDates(0, 'start_date', 'end_date')),
    getHighRiskComparisons(url, getWeekDates(1, 'start_date', 'end_date')),
  ])
    .then(([currentData, previousData]) => ({ currentWeek: formatHighRiskZones(currentData), previousWeek: formatHighRiskZones(previousData) }))
    .catch(error => Promise.reject(buildError(error)))
}

function getAverageContagionScore(url, params, scoreKey) {
  return axios.get(url + buildQueryParams(params), getAxiosConfig())
    .then(response => response.data[scoreKey] || 0)
    .catch(error => Promise.reject(error))
}

export function serviceGetAverageContagionScoreEmployees(eventId) {
  const url = `/events/${eventId}/trace/attendees/connectivity/stats`

  return Promise.all([
    getAverageContagionScore(url, getWeekDates(0), 'avg_score'),
    getAverageContagionScore(url, getWeekDates(1), 'avg_score'),
  ])
    .then(([currentScore, previousScore]) => ({ currentScore, previousScore }))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetAverageContagionScoreZones(eventId) {
  const url = `/events/${eventId}/trace/zones/connectivity/stats`

  return Promise.all([
    getAverageContagionScore(url, getWeekDates(0, 'start', 'end'), 'score'),
    getAverageContagionScore(url, getWeekDates(1, 'start', 'end'), 'score'),
  ])
    .then(([currentScore, previousScore]) => ({ currentScore, previousScore }))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetTrackingOvertime(eventId, queryParams) {
  const queryString = buildQueryParams({
    ...queryParams,
    per_location: true,
  })

  return axios.get(`/events/${eventId}/locations/stats/attendees_overtime${queryString}`, getAxiosConfig())
    .then(response => response.data.timeseries)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetZoneStats(eventId, locationId, queryParams) {
  if (!locationId) {
    return Promise.resolve(undefined)
  }

  const queryString = buildQueryParams(queryParams)

  return axios.get(`/events/${eventId}/locations/${locationId}/stats${queryString}`, getAxiosConfig())
    .then(response => ({
      averageDwellTime: response.data.avg_dwell_time_per_visit,
      peakCount: response.data.max_attendees,
      peakTime: response.data.max_attendee_time,
      uniqueVisits: response.data.num_unique_visits,
    }))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetHeatmapData(eventId, queryParams) {
  const queryString = buildQueryParams(queryParams)

  return axios.get(`/events/${eventId}/locations/stats/attendees_overtime_by_tag${queryString}`, getAxiosConfig())
    .then(response => response.data.timeseries)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetNetworkTopEmployees(eventId, queryParams) {
  const queryString = buildQueryParams(queryParams)

  return axios.get(`/events/${eventId}/trace/attendees/connectivity${queryString}`, getAxiosConfig())
    .then(response => {
      const employees = []

      for (let i = 0; i < response.data.length; i++) {
        const employee = response.data[i]
        if (employee.account) {
          employees.push({
            id: employee.account.id,
            name: employee.account.display_name || employee.account.id,
            score: Math.round(employee.score || 0),
          })
        }
      }
      return employees
    })
    .catch(error => Promise.reject(buildError(error)))
}
export function serviceGetAllEmployees(eventId, queryParams) {
  const queryString = buildQueryParams(queryParams)

  return axios.get(`/events/${eventId}/trace/attendees/connectivity${queryString}`, getAxiosConfig())
    .then(response => {
      const employeeList = []

      for (let i = 0; i < response.data.length; i++) {
        const employee = response.data[i]
        if (employee.account) {
          employeeList.push({
            ...employee,
            id: employee.account.id,
            name: employee.account.display_name || employee.account.id,
            current_score: Math.round(employee.score || 0),
            previous_score: Math.round(employee.previous_score || 0),
          })
        }
      }

      const pagination = parsePaginationHeaders(response)
      return { data: employeeList, pagination }
    })
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetZoneList(eventId, queryParams) {
  return axios.get(`/events/${eventId}/trace/zones/connectivity${buildQueryParams(queryParams)}`, getAxiosConfig())
    .then(response => {
      const data = []

      response.data.forEach(entry => {
        if (!entry.location) return

        data.push({
          name: entry.location.name,
          display_name: getTranslatedValue(getProperty(entry.location, 'display_name') || entry.location.name),
          current_score: entry.score,
          previous_score: entry.previous_score,
        })
      })

      return {
        data,
        pagination: parsePaginationHeaders(response),
      }
    })
    .catch(error => {
      console.log(error)
      return Promise.reject(buildError(error))
    })
}

function formatDailyScore(data, start, timezone) {
  const scores = []
  const scoresByDate = {}

  data.forEach(d => {
    scoresByDate[Moment.unix(d.date).tz(timezone).startOf('day').unix()] = Math.round(d.score)
  })

  for (let i = 0; i < 7; i++) {
    scores.push(scoresByDate[Moment.unix(start).tz(timezone).startOf('day').add(i, 'days').unix()])
  }

  return scores
}

export function serviceGetZoneDailyScores(eventId, zoneId, timezone) {
  const currentDayMoment = Moment().tz(timezone)

  const getWeekData = queryParams => axios.get(`/events/${eventId}/trace/zones/${zoneId}/connectivity${buildQueryParams(queryParams)}`, getAxiosConfig())
    .then(response => formatDailyScore(response.data, queryParams.start_date, timezone))

  const getCurrentWeek = () => getWeekData({
    end_date: currentDayMoment.clone().endOf('day').unix(),
    start_date: currentDayMoment.clone().startOf('day').subtract(6, 'days').unix(),
  })

  const getPreviousWeek = () => getWeekData({
    end_date: currentDayMoment.clone().endOf('day').subtract(7, 'days').unix(),
    start_date: currentDayMoment.clone().startOf('day').subtract(13, 'days').unix(),
  })

  return Promise.all([getCurrentWeek(), getPreviousWeek()])
    .then(([currentWeek, previousWeek]) => ({ currentWeek, previousWeek }))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetNetworkDailyScores(eventId, employeeId, timezone) {
  const currentDayMoment = Moment().tz(timezone)

  const getWeekData = queryParams => axios.get(`/events/${eventId}/trace/attendees/${employeeId}/connectivity${buildQueryParams(queryParams)}`, getAxiosConfig())
    .then(response => formatDailyScore(response.data, queryParams.start_date, timezone))
    .catch(error => Promise.reject(buildError(error)))

  const getCurrentWeek = () => getWeekData({
    end_date: currentDayMoment.clone().endOf('day').unix(),
    start_date: currentDayMoment.clone().startOf('day').subtract(6, 'days').unix(),
  })

  const getPreviousWeek = () => getWeekData({
    end_date: currentDayMoment.clone().endOf('day').subtract(7, 'days').unix(),
    start_date: currentDayMoment.clone().startOf('day').subtract(13, 'days').unix(),
  })

  return Promise.all([getCurrentWeek(), getPreviousWeek()])
    .then(([currentWeek, previousWeek]) => ({ currentWeek, previousWeek }))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetNetworkGraph(eventId, employeeId, queryParams) {
  const queryString = buildQueryParams(queryParams)

  return axios.get(`/events/${eventId}/trace/attendees/${employeeId}/graph${queryString}`, getAxiosConfig())
    .then(response => {
      const data = getProperty(response, 'data') || {}
      const accounts = listToMapping(data.accounts, 'id')

      let nodes = []
      for (let i = 0; i < (data.nodes || []).length; i++) {
        const account = accounts[data.nodes[i]]
        if (account) {
          nodes.push({ id: account.id, name: account.display_name })
        } else {
          nodes.push({ id: data.nodes[i], name: data.nodes[i] })
        }
      }

      const relations = {}
      let links = []
      for (let i = 0; i < (data.links || []).length; i++) {
        const otherAccountId = data.links[i].accounts.find(d => d !== employeeId)
        if (!otherAccountId) continue // eslint-disable-line no-continue

        const isDirectLinkToTarget = !!data.links[i].accounts.find(d => d === employeeId)

        if (isDirectLinkToTarget) {
          const otherAccount = accounts[otherAccountId]
          links.push({ source: employeeId, target: otherAccountId })

          relations[otherAccountId] = {
            duration: parseInt(data.links[i].total_duration, 10) || 0,
            name: otherAccount ? (otherAccount.display_name || otherAccountId) : otherAccountId,
            occurences: data.links[i].segments || 0,
          }
        } else {
          links.push({ source: data.links[i].accounts[0], target: data.links[i].accounts[1] })
        }
      }

      links = links.sort((linkA, linkB) => {
        if (linkA.source === employeeId || linkA.target === employeeId) {
          return -1
        } else if (linkB.source === employeeId || linkB.target === employeeId) {
          return 1
        }
        return 0
      })

      nodes = nodes.sort((nodeA, nodeB) => {
        const isEmployeeA = nodeA.id === employeeId
        const isEmployeeB = nodeB.id === employeeId
        if (isEmployeeA || isEmployeeB) {
          return isEmployeeA ? -1 : 1
        }

        const hasRelationA = relations[nodeA.id]
        const hasRelationB = relations[nodeB.id]
        if (hasRelationA || hasRelationB) {
          return hasRelationA ? -1 : 1
        }

        return 0
      })

      return { accounts, links, nodes, relations }
    })
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetCountEmployees(eventId) {
  return axios.get(`/events/${eventId}/attendees/stats`, getAxiosConfig())
    .then(response => response.data.count)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetCountDevicesOnsite(eventId) {
  const axiosConfig = getAxiosConfig()
  axiosConfig.baseURL = config.locatorUrl

  return axios.get(`/events/${eventId}/devices/stats?since=600`, axiosConfig)
    .then(response => response.data.paired_active)
    .catch(error => Promise.reject(buildError(error)))
}


export function serviceGetTechnicalStats(eventId) {
  const getBridges = () => axios.get('/bridges', getAxiosConfig())
    .then(response => listToMapping(response.data, 'addr'))

  const getInfras = bridgesByAddress => axios.get(`/events/${eventId}/infra`, getAxiosConfig())
    .then(response => {
      const filterOnMap = infra => infra.map && infra.x != null && infra.y != null
      const filterOnEvent = infra => infra.event_id === eventId

      const filterHubs = infra => infra.type === 'beacon' && filterOnEvent(infra) && filterOnMap(infra)
      const filterTags = infra => infra.type === 'tag' && filterOnEvent(infra) && filterOnMap(infra)

      const filterConnectedHubs = infra => !!bridgesByAddress[getProperty(infra, 'status.bridge')]
      const filterConnectedTags = infra => getProperty(infra, 'status.status') === 'healthy'

      const hubs = response.data.filter(filterHubs).map(translateInfra)
      const tags = response.data.filter(filterTags).map(translateInfra)

      return {
        hubs_connected: hubs.filter(filterConnectedHubs).length || 0,
        hubs_total: hubs.length || 0,
        tags_connected: tags.filter(filterConnectedTags).length || 0,
        tags_total: tags.length || 0,
      }
    })

  return getBridges()
    .then(bridges => getInfras(bridges))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetDashboardOvertime(eventId, queryParams) {
  const queryString = buildQueryParams({
    ...queryParams,
    per_location: true,
  })

  return axios.get(`/events/${eventId}/locations/stats/attendees_overtime${queryString}`, getAxiosConfig())
    .then(response => response.data.timeseries)
    .catch(error => Promise.reject(buildError(error)))
}
