import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetInstallsStats } from 'admin/services/dashboard'
import { eventStartDateSelector, eventEndDateSelector, eventTimezoneSelector } from 'admin/modules/events'
import Moment from 'moment'


const InstallsStatsModule = generateDataModule({
  initialData: {
    full: {
      installs: 0,
      overtime: [],
    },
    minimal: {
      installs: 0,
      overtime: [],
    },
    totalInstalls: 0,
  },
  moduleKey: 'dashboardInstallsStats',
  reducerKey: 'admin.dashboard.installsStats',
  services: {
    get: { service: serviceGetInstallsStats },
  },
})


InstallsStatsModule.registerSelector('beforeEvent', state => InstallsStatsModule.selectors.data(state), data => data.beforeEvent)
InstallsStatsModule.registerSelector('duringEvent', state => InstallsStatsModule.selectors.data(state), data => data.duringEvent)
InstallsStatsModule.registerSelector('today', state => InstallsStatsModule.selectors.data(state), data => data.today)
InstallsStatsModule.registerSelector('total', state => InstallsStatsModule.selectors.data(state), data => data.total)


InstallsStatsModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {
    const state = getState()
    const timezone = eventTimezoneSelector(state)
    const eventStartMoment = Moment.unix(eventStartDateSelector(state)).tz(timezone)
    const eventEndMoment = Moment.unix(eventEndDateSelector(state)).tz(timezone)
    const currentMoment = Moment.unix(Moment().unix()).tz(timezone)

    dispatch({ type: InstallsStatsModule.actionKeys.getStart })
    return InstallsStatsModule._getService('get')(eventStartMoment, eventEndMoment, currentMoment)
      .then(response => {
        dispatch({
          type: InstallsStatsModule.actionKeys.getSuccess,
          payload: {
            beforeEvent: response.before_event || 0,
            duringEvent: response.during_event || 0,
            today: response.today || 0,
            total: response.total || 0,
          },
        })
      })
      .catch(() => {
        dispatch({ type: InstallsStatsModule.actionKeys.getError })
      })
  }
)

InstallsStatsModule.registerReducer(
  InstallsStatsModule.actionKeys.getSuccess,
  (state, action) => ({
    ...state,
    data: action.payload,
    isLoaded: true,
    isLoading: false,
    lastUpdated: Date.now(),
  })
)


export default InstallsStatsModule
