import { serviceGetEmailTemplate, serviceGetSchedule, servicePostEmailTemplateRenderedHTML, servicePutEmailTemplate, servicePutSchedule, serviceDeleteSchedule, serviceSendPreview, serviceGetScheduleRecipientsCount } from 'admin/services/emailing'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'


export function setSelectedTemplate(template) {
  return (dispatch, getState) => {
    const selectTemplate = (selectedTemplate) => {
      dispatch({ type: 'SET_SELECTED_TEMPLATE', payload: { selectedTemplate } })
      return Promise.resolve(selectedTemplate)
    }

    if (typeof template === 'string') {
      return serviceGetEmailTemplate(
        getState().admin.events.currentEvent.id,
        template,
        (response) => selectTemplate(response),
        () => Promise.reject()
      )
    }

    return selectTemplate(template)
  }
}

export function setSelectedSchedule(schedule) {
  return (dispatch, getState) => {
    const selectSchedule = (selectedSchedule) => {
      dispatch({ type: 'SET_SELECTED_SCHEDULE', payload: { selectedSchedule } })
      return Promise.resolve(selectedSchedule)
    }

    if (typeof schedule === 'string') {
      return serviceGetSchedule(
        getState().admin.events.currentEvent.id,
        schedule,
        (response) => selectSchedule(response),
        () => Promise.reject()
      )
    }

    return selectSchedule(schedule)
  }
}

export function clearEmailEditor() {
  return dispatch => {
    dispatch({ type: 'CLEAR_EMAIL_EDITOR' })
    return Promise.resolve()
  }
}

export function getEmailTemplate(templateId) {
  return (dispatch, getState) => serviceGetEmailTemplate(
    getState().admin.events.currentEvent.id,
    templateId,
    (response) => {
      dispatch(setSelectedTemplate(response))
      Promise.resolve(response)
    },
    () => Promise.reject()
  )
}

export function getEmailPreview(template, language) {
  return (dispatch, getState) => {
    dispatch({ type: 'GET_EMAIL_PREVIEW_START' })
    return servicePostEmailTemplateRenderedHTML(
      getState().admin.events.currentEvent.id,
      template.id,
      template,
      language,
      (response) => dispatch({ type: 'GET_EMAIL_PREVIEW_SUCCESS', payload: { html: response.html, short_text: response.short_text } }),
      (error) => dispatch({ type: 'GET_EMAIL_PREVIEW_ERROR', payload: { error: error.description } })
    )
  }
}

export function saveEmailTemplate(template) {
  return (dispatch, getState) => {
    dispatch({ type: 'SAVE_EMAIL_TEMPLATE_START' })
    return servicePutEmailTemplate(
      getState().admin.events.currentEvent.id,
      template,
      response => dispatch({ type: 'SAVE_EMAIL_TEMPLATE_SUCCESS', payload: { template: response } }),
      error => {
        dispatch({ type: 'SAVE_EMAIL_TEMPLATE_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_communication')('actions.save_email_template.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
        return Promise.reject()
      }
    )
  }
}

export function saveEmailSchedule(schedule) {
  return (dispatch, getState) => {
    dispatch({ type: 'SAVE_EMAIL_SCHEDULE_START' })

    return servicePutSchedule(
      getState().admin.events.currentEvent.id,
      schedule,
      response => dispatch({ type: 'SAVE_EMAIL_SCHEDULE_SUCCESS', payload: { schedule: response } }),
      error => {
        dispatch({ type: 'SAVE_EMAIL_SCHEDULE_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.save_schedule.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
        return Promise.reject()
      }
    )
  }
}

export function deleteEmailSchedule(scheduleId) {
  return (dispatch, getState) => {
    dispatch({ type: 'DELETE_EMAIL_SCHEDULE_START' })

    return serviceDeleteSchedule(
      getState().admin.events.currentEvent.id,
      scheduleId,
      () => dispatch({ type: 'DELETE_EMAIL_SCHEDULE_SUCCESS' }),
      error => {
        dispatch({ type: 'DELETE_EMAIL_SCHEDULE_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.delete_schedule.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}

export function sendPreview(previewArgs) {
  return (dispatch, getState) => {
    dispatch({ type: 'SEND_EMAIL_PREVIEW_START' })
    return serviceSendPreview(
      getState().admin.events.currentEvent.id,
      previewArgs,
      () => {
        dispatch({ type: 'SEND_EMAIL_PREVIEW_SUCCESS' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.post_preview_schedule.success.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.post_preview_schedule.success.message'),
          level: 'success',
          permanent: false,
        }))
        return Promise.resolve()
      },
      (error) => {
        dispatch({ type: 'SEND_EMAIL_PREVIEW_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.post_preview_schedule.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
        return Promise.reject()
      }
    )
  }
}

export function getRecipientsCount(target) {
  return (dispatch, getState) => {
    dispatch({ type: 'GET_RECIPIENTS_COUNT_START' })
    return serviceGetScheduleRecipientsCount(
      getState().admin.events.currentEvent.id,
      getState().admin.emailEditor.selectedSchedule.id,
      target ? { target: { ...target } } : null,
      (response) => dispatch({ type: 'GET_RECIPIENTS_COUNT_SUCCESS', payload: response }),
      () => dispatch({ type: 'GET_RECIPIENTS_COUNT_ERROR' })
    )
  }
}


const initialState = {
  selectedTemplate: undefined,
  selectedSchedule: undefined,
  previewHTML: undefined,
  previewShortText: undefined,
  previewError: undefined,
  isLoadingPreview: false,
  recipientsCount: 0,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_SELECTED_TEMPLATE':
      return { ...state, selectedTemplate: action.payload.selectedTemplate }

    case 'SET_SELECTED_SCHEDULE':
      return { ...state, selectedSchedule: action.payload.selectedSchedule, recipientsCount: initialState.recipientsCount }

    case 'CLEAR_EMAIL_EDITOR':
      return { ...state, selectedTemplate: undefined, selectedSchedule: undefined, previewHTML: undefined, previewShortText: undefined, previewError: undefined }

    case 'GET_EMAIL_PREVIEW_START':
      return { ...state, isLoadingPreview: true }
    case 'GET_EMAIL_PREVIEW_SUCCESS':
      return { ...state, isLoadingPreview: false, previewHTML: action.payload.html, previewShortText: action.payload.short_text, previewError: undefined }
    case 'GET_EMAIL_PREVIEW_ERROR':
      return { ...state, isLoadingPreview: false, previewHTML: undefined, previewShortText: undefined, previewError: action.payload.error }

    case 'SAVE_EMAIL_TEMPLATE_START':
      return { ...state }
    case 'SAVE_EMAIL_TEMPLATE_SUCCESS':
      return { ...state, selectedTemplate: action.payload.template }
    case 'SAVE_EMAIL_TEMPLATE_ERROR':
      return { ...state }

    case 'SAVE_EMAIL_SCHEDULE_START':
      return { ...state }
    case 'SAVE_EMAIL_SCHEDULE_SUCCESS':
      return { ...state, selectedSchedule: action.payload.schedule }
    case 'SAVE_EMAIL_SCHEDULE_ERROR':
      return { ...state }

    case 'SEND_EMAIL_PREVIEW_START':
      return { ...state }
    case 'SEND_EMAIL_PREVIEW_SUCCESS':
      return { ...state }
    case 'SEND_EMAIL_PREVIEW_ERROR':
      return { ...state }

    case 'GET_RECIPIENTS_COUNT_START':
      return { ...state }
    case 'GET_RECIPIENTS_COUNT_SUCCESS':
      return { ...state, recipientsCount: action.payload }
    case 'GET_RECIPIENTS_COUNT_ERROR':
      return { ...state }


    default:
      return { ...state }
  }
}
