import { serviceGetRepresentativeLeaderboard, serviceGetExhibitorSummaryStats, serviceGetExhibitorLeadsOvertime, serviceGetExhibitorVisitsOvertime } from 'admin/services/exhibitors'
import generateModule from 'common/utils/generateModule'


function getEventId(getState) {
  return getState().exhibitor.exhibitors.currentExhibitor.event_id
}

function getExhibitorId(getState) {
  return getState().exhibitor.exhibitors.currentExhibitor.id
}


const { actions, reducers, initialState } = generateModule({
  itemName: 'stat',
  getModuleState: (getState) => getState().exhibitor.stats,
  getEventId: getEventId,
  idFieldName: 'id',
  app: 'exhibitor',
  services: {},
})

initialState.leaderboard = {
  description: 'leaderboard stats',
  data: [],
  isLoading: false,
  isLoaded: false,
}

initialState.boothSummary = {
  description: 'booth summary stats',
  data: undefined,
  isLoading: false,
  isLoaded: false,
}

initialState.visitsOvertime = {
  description: 'visits overtime stats',
  data: [],
  isLoading: false,
  isLoaded: false,
}

initialState.leadsOvertime = {
  description: 'leads overtime stats',
  data: [],
  isLoading: false,
  isLoaded: false,
}


actions.getLeaderboardStats = () =>
  (dispatch, getState) => {
    dispatch({ type: 'CPE_GET_LEADERBOARD_STATS_START' })

    return serviceGetRepresentativeLeaderboard(
      getEventId(getState),
      getExhibitorId(getState),
      (response) => dispatch({ type: 'CPE_GET_LEADERBOARD_STATS_SUCCESS', payload: { leaderboard: response } }),
      () => dispatch({ type: 'CPE_GET_LEADERBOARD_STATS_ERROR' })
    )
  }

reducers['CPE_GET_LEADERBOARD_STATS_START'] = (state) => ({
  ...state,
  leaderboard: { ...state.leaderboard, isLoading: true },
})

reducers['CPE_GET_LEADERBOARD_STATS_SUCCESS'] = (state, action) => ({
  ...state,
  leaderboard: {
    ...state.leaderboard,
    data: action.payload.leaderboard,
    isLoading: false,
    isLoaded: true,
  },
})


actions.getExhibitorSummaryStats = () =>
  (dispatch, getState) => {
    dispatch({ type: 'CPE_GET_EXHIBITOR_SUMMARY_STATS_START' })

    return serviceGetExhibitorSummaryStats(
      getEventId(getState),
      getExhibitorId(getState),
      (response) => dispatch({ type: 'CPE_GET_EXHIBITOR_SUMMARY_STATS_SUCCESS', payload: { stats: response } }),
      () => dispatch({ type: 'CPE_GET_EXHIBITOR_SUMMARY_STATS_ERROR' })
    )
  }

reducers['CPE_GET_EXHIBITOR_SUMMARY_STATS_START'] = (state) => ({
  ...state,
  boothSummary: { ...state.boothSummary, isLoading: true },
})

reducers['CPE_GET_EXHIBITOR_SUMMARY_STATS_SUCCESS'] = (state, action) => ({
  ...state,
  boothSummary: {
    ...state.boothSummary,
    data: action.payload.stats,
    isLoading: false,
    isLoaded: true,
  },
})


actions.getLeadsOvertimeStats = (query) =>
  (dispatch, getState) => {
    dispatch({ type: 'CPE_GET_EXHIBITOR_LEADS_OVERTIME_START' })

    return serviceGetExhibitorLeadsOvertime(
      getEventId(getState),
      getExhibitorId(getState),
      query || {},
      (response) => dispatch({ type: 'CPE_GET_EXHIBITOR_LEADS_OVERTIME_SUCCESS', payload: { stats: response } }),
      () => dispatch({ type: 'CPE_GET_EXHIBITOR_LEADS_OVERTIME_ERROR' })
    )
  }

reducers['CPE_GET_EXHIBITOR_LEADS_OVERTIME_START'] = (state) => ({
  ...state,
  leadsOvertime: { ...state.leadsOvertime, isLoading: true },
})

reducers['CPE_GET_EXHIBITOR_LEADS_OVERTIME_SUCCESS'] = (state, action) => ({
  ...state,
  leadsOvertime: {
    ...state.leadsOvertime,
    data: action.payload.stats,
    isLoading: false,
    isLoaded: true,
  },
})


actions.getVisitsOvertimeStats = (query) =>
  (dispatch, getState) => {
    dispatch({ type: 'CPE_GET_EXHIBITOR_VISITS_OVERTIME_START' })

    return serviceGetExhibitorVisitsOvertime(
      getEventId(getState),
      getExhibitorId(getState),
      query || {},
      (response) => dispatch({ type: 'CPE_GET_EXHIBITOR_VISITS_OVERTIME_SUCCESS', payload: { stats: response } }),
      () => dispatch({ type: 'CPE_GET_EXHIBITOR_VISITS_OVERTIME_ERROR' })
    )
  }

reducers['CPE_GET_EXHIBITOR_VISITS_OVERTIME_START'] = (state) => ({
  ...state,
  visitsOvertime: { ...state.visitsOvertime, isLoading: true },
})

reducers['CPE_GET_EXHIBITOR_VISITS_OVERTIME_SUCCESS'] = (state, action) => ({
  ...state,
  visitsOvertime: {
    ...state.visitsOvertime,
    data: action.payload.stats,
    isLoading: false,
    isLoaded: true,
  },
})


export const getLeaderboardStats = actions.getLeaderboardStats
// export const getTouchpointStats = actions.getTouchpointStats
export const addTouchpoint = actions.addTouchpoint
export const getExhibitorSummaryStats = actions.getExhibitorSummaryStats
export const getLeadsOvertimeStats = actions.getLeadsOvertimeStats
export const getVisitsOvertimeStats = actions.getVisitsOvertimeStats

const stats = reducers.index
export default stats
