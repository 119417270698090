import { serviceGetSessions } from 'attendee/services/sessions'
import generateModule from 'common/utils/generateModule'
import { isSessionInvitation, isSessionSaved, isSessionConfirmed } from 'attendee/containers/Schedule/SessionRelations'
import { updateSessionRelation, removeSession } from './Sessions/Helper'
import { getSessions, getAppointments, addToAgenda, removeFromAgenda, confirmAttendanceToSession, cancelAttendanceToSession, acceptInvitation, declineInvitation, sendFeedbackForSession, setBookingConflictModal, confirmAppointmentReservation, cancelAppointmentReservation } from './Sessions/Actions'
import { createSelector } from 'reselect'
import { getProperty, listToMapping } from 'common/utils/componentHelper'
import { filtersSelector } from './FilterSettings/schedule'
import { eventTimezoneSelector } from './events'
import { filterSavedOnly, filterConfirmedOnly, filterFavorites, filterOutFullCapacity, filterOutConflicting, filterBySearch, filterByLocation, filterByDate, filterByCalendar, filterByTags, sortSessions } from './Sessions/Filters'


// ////////////////////////////////////////////////////////////////////////////
// Selectors
// ////////////////////////////////////////////////////////////////////////////

const selectCalendars = state => state.attendee.sessions.calendars
const selectSessions = state => state.attendee.sessions.data
const selectCalendarOrder = state => state.attendee.sessions.calendarOrder
const selectAppointments = state => state.attendee.sessions.appointments
const selectSaveForLaterModal = state => state.attendee.sessions.saveForLaterModal
const selectConfirmationModal = state => state.attendee.sessions.confirmationModal
const selectBookingConflictModal = state => state.attendee.sessions.bookingConflictModal

export const calendarsSelector = createSelector(selectCalendars, calendars => calendars)
export const calendarsByIdSelector = createSelector(selectCalendars, calendars => listToMapping(calendars, 'id'))
export const currentCalendarIdSelector = createSelector(filtersSelector, filters => (getProperty(filters, 'calendarTypes') || [])[0])
export const calendarSessionsSelector = createSelector(selectSessions, sessions => filterConfirmedOnly([...sessions], { showConfirmedOnly: true }))
export const appointmentsSelector = createSelector(selectAppointments, appointments => appointments)
export const saveForLaterModalSelector = createSelector(selectSaveForLaterModal, modalState => modalState)
export const confirmationModalSelector = createSelector(selectConfirmationModal, modalState => modalState)
export const bookingConflictModalSelector = createSelector(selectBookingConflictModal, modalState => modalState)

export const calendarDropdownOptionsSelector = createSelector(
  selectCalendars,
  calendars => calendars.map(calendar => ({ value: calendar.id, label: calendar.name }))
)

export const allSessionsByIdSelector = createSelector(
  selectSessions,
  sessions => {
    const sessionsById = {}
    sessions.forEach(session => { sessionsById[session.id] = session })
    return sessionsById
  }
)

export const filteredSessionsSelector = createSelector(
  selectSessions,
  filtersSelector,
  selectCalendarOrder,
  eventTimezoneSelector,
  (sessions, filters, calendarOrder, timezone) => {
    let filteredSessions = [...sessions]
    filteredSessions = filterSavedOnly(filteredSessions, filters)
    filteredSessions = filterConfirmedOnly(filteredSessions, filters)
    filteredSessions = filterFavorites(filteredSessions, filters)
    filteredSessions = filterOutFullCapacity(filteredSessions, filters)
    filteredSessions = filterOutConflicting(filteredSessions, filters)
    filteredSessions = filterBySearch(filteredSessions, filters)
    filteredSessions = filterByLocation(filteredSessions, filters)
    filteredSessions = filterByDate(filteredSessions, filters, timezone)
    filteredSessions = filterByCalendar(filteredSessions, filters)
    filteredSessions = filterByTags(filteredSessions, filters)
    filteredSessions = sortSessions(filteredSessions, calendarOrder)
    return filteredSessions
  }
)

export const savedSessionsCountSelector = createSelector(
  selectSessions,
  sessions => sessions.filter(session => isSessionSaved(session) && !isSessionConfirmed(session)).length
)

export const filteredSessionsByTimeSelector = createSelector(
  filteredSessionsSelector,
  sessions => {
    const sessionsByTime = {}

    sessions.forEach(session => {
      if (!sessionsByTime[session.start_date]) sessionsByTime[session.start_date] = []
      sessionsByTime[session.start_date].push(session)
    })

    return sessionsByTime
  }
)


// ////////////////////////////////////////////////////////////////////////////
// Module
// ////////////////////////////////////////////////////////////////////////////

const module = generateModule({
  itemName: 'session',
  itemNameDisplay: 'session',
  autoRefresh: 60,
  services: {
    get: serviceGetSessions,
  },
  app: 'attendee',
})

module.initialState.appointments = [] // Holds appointments for a session
module.initialState.calendars = []
module.initialState.calendarOrder = {} // Map from calendarId to calendarIndex

module.initialState.bookingConflictModal = {
  isVisible: false,
  error: false,
}

module.initialState.confirmationModal = {
  isVisible: false,
  name: null,
  onConfirm: null,
}

module.initialState.saveForLaterModal = {
  isVisible: false,
  sessionId: null,
}


// ////////////////////////////////////////////////////////////////////////////
// Module Actions
// ////////////////////////////////////////////////////////////////////////////

module.actions.get = () => getSessions()
module.reducers['GET_SESSIONS_SUCCESS'] = (state, action) => ({
  ...state,
  data: action.payload.sessions,
  calendars: action.payload.calendars,
  calendarOrder: action.payload.calendarOrder,
  isFetching: false,
  isLoaded: true,
  lastUpdated: Date.now(),
})


module.actions.getAppointments = (calendarId, sessionId, shouldFilterOutConflicting) => getAppointments(calendarId, sessionId, shouldFilterOutConflicting)

module.reducers['GET_APPOINTMENTS_FOR_SESSION_START'] = (state) => ({
  ...state,
  appointments: module.initialState.appointments,
})

module.reducers['GET_APPOINTMENTS_FOR_SESSION_SUCCESS'] = (state, action) => ({
  ...state,
  appointments: action.payload.appointments,
})


module.actions.addToAgenda = (session) => addToAgenda(session)
module.reducers['ADD_TO_AGENDA'] = (state, action) => ({
  ...state,
  data: updateSessionRelation(state.data, action.payload.session, { interested: true }),
})

module.actions.removeFromAgenda = (session) => removeFromAgenda(session)
module.reducers['REMOVE_FROM_AGENDA'] = (state, action) => ({
  ...state,
  data: updateSessionRelation(state.data, action.payload.session, { interested: false }),
})


module.actions.confirmAttendanceToSession = (session) => confirmAttendanceToSession(session)
module.reducers['CONFIRM_ATTENDANCE_TO_SESSION'] = (state, action) => ({
  ...state,
  data: updateSessionRelation(state.data, action.payload.session, { going: true }, -1),
})

module.actions.cancelAttendanceToSession = (session) => cancelAttendanceToSession(session)
module.reducers['CANCEL_ATTENDANCE_TO_SESSION'] = (state, action) => ({
  ...state,
  data: isSessionInvitation(action.payload.session)
    ? removeSession(state.data, action.payload.session)
    : updateSessionRelation(state.data, action.payload.session, { going: false, interested: false }, 1),
})


module.actions.confirmAppointmentReservation = (session, appointmentId, isReplacing) => confirmAppointmentReservation(session, appointmentId, isReplacing)
module.reducers['CONFIRM_APPOINTMENT_RESERVATION'] = (state, action) => ({
  ...state,
  data: state.data.map(session => (session.id === action.payload.id ? { ...session, ...action.payload } : session)),
})

module.actions.cancelAppointmentReservation = (session, appointmentId) => cancelAppointmentReservation(session, appointmentId)
module.reducers['CANCEL_APPOINTMENT_RESERVATION'] = (state, action) => ({
  ...state,
  data: state.data.map(session => (session.id === action.payload.id ? { ...session, ...action.payload } : session)),
})


module.actions.acceptInvitation = (session) => acceptInvitation(session)
module.reducers['ACCEPT_SESSION_INVITATION'] = (state, action) => ({
  ...state,
  data: updateSessionRelation(state.data, action.payload.session, { going: true, invited: { status: 'accepted' } }, -1),
})

module.reducers['ACCEPT_SESSION_INVITATION_ERROR'] = (state, action) => ({
  ...state,
  data: updateSessionRelation(state.data, action.payload.session, { going: false, invited: { statis: 'pending' } }, 1),
})

module.actions.declineInvitation = (session) => declineInvitation(session, module.actions.get)
module.reducers['DECLINE_SESSION_INVITATION'] = (state, action) => ({
  ...state,
  data: removeSession(state.data, action.payload.session),
})


module.actions.sendFeedbackForSession = (session, feedback) => sendFeedbackForSession(session, feedback)
module.reducers['SEND_FEEDBACK_FOR_SESSION'] = (state, action) => ({
  ...state,
  data: updateSessionRelation(state.data, action.payload.session, {
    feedback: {
      rating: action.payload.feedback.rating,
      comments: action.payload.feedback.comments,
      feedback_id: action.payload.feedback.id,
    },
  }),
})


module.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isLoaded: false,
  isError: false,
  didInvalidate: true,
  data: [],
  calendars: [],
})


// ////////////////////////////////////////////////////////////////////////////
// Modals
// ////////////////////////////////////////////////////////////////////////////

module.actions.setSaveForLaterModal = modalState => dispatch => dispatch({ type: 'SESSIONS_SET_SAVE_FOR_LATER_MODAL', payload: modalState })
module.reducers['SESSIONS_SET_SAVE_FOR_LATER_MODAL'] = (state, action) => ({
  ...state,
  saveForLaterModal: action.payload.isVisible ? action.payload : { ...state.saveForLaterModal, isVisible: false },
})

module.actions.setConfirmationModal = modalState => dispatch => dispatch({ type: 'SESSIONS_SET_CONFIRMATION_MODAL', payload: modalState })
module.reducers['SESSIONS_SET_CONFIRMATION_MODAL'] = (state, action) => ({
  ...state,
  confirmationModal: action.payload.isVisible ? action.payload : { ...state.confirmationModal, isVisible: false },
})

module.actions.setBookingConflictModal = (isVisible, error) => setBookingConflictModal(isVisible, error)
module.reducers['SESSIONS_SET_BOOKING_CONFLICT_MODAL'] = (state, action) => ({
  ...state,
  bookingConflictModal: action.payload.isVisible ? { ...action.payload } : { ...state.bookingConflictModal, isVisible: false },
})


module.actions.getSessions = module.actions.get
export default module
