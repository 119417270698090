import { generateDataModule, invalidateOnEventChange } from 'admin/modules/generateModule'
import { serviceGetTechnicalStats } from 'admin/services/bluetrace'


const TechnicalStatsModule = generateDataModule({
  initialData: {
    hubs_connected: 0,
    hubs_total: 0,
    tags_connected: 0,
    tags_total: 0,
  },
  moduleKey: 'cpo/bluetrace/dashboard/technicalStats',
  reducerKey: 'admin.bluetrace.dashboard.technicalStats',
  refreshTime: 10000,
  services: {
    get: { service: serviceGetTechnicalStats },
  },
})

invalidateOnEventChange(TechnicalStatsModule)


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////


export default TechnicalStatsModule
