import { generateDataModule } from './generateModule'
import { getProperty } from 'common/utils/componentHelper'
import { serviceGetExhibitors, serviceCreateExhibitor, serviceGetExhibitor, serviceUpdateExhibitor, serviceDeleteExhibitor, serviceAddRepresentative, serviceDeleteRepresentative, serviceGetRepresentativesForExhibitor, serviceGetExhibitorAnalytics, serviceAddExhibitorAdmin, serviceDeleteExhibitorAdmin, serviceResetExhibitorAdminPassword } from 'admin/services/exhibitors'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'


const ExhibitorsModule = generateDataModule({
  initialState: {
    currentExhibitor: undefined,
    pagination: {
      page: 1,
      pageCount: 1,
    },
    queryParams: {
      include_stats: true,
      page: 1,
      per_page: 15,
      query: undefined,
      sort_column: 'name',
      sort_order: 1,
    },
  },

  moduleKey: 'cpo/exhibitors',
  reducerKey: 'admin.exhibitors',
  refreshTime: 30000,

  services: {
    get: { service: serviceGetExhibitors },
    put: { service: serviceUpdateExhibitor },
    post: { service: serviceCreateExhibitor },
    delete: { service: serviceDeleteExhibitor },
  },
})

// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

ExhibitorsModule.registerSelector(
  'idsToCode',
  state => ExhibitorsModule.selectors.data(state),
  exhibitors => {
    const idsToCode = {}
    exhibitors.forEach(exhibitor => {
      idsToCode[exhibitor.id] = exhibitor.code
    })
    return idsToCode
  }
)

ExhibitorsModule.registerSelector('currentExhibitor', (state, moduleState) => moduleState.currentExhibitor, exhibitor => exhibitor)

ExhibitorsModule.registerSelector('pagination', (state, moduleState) => moduleState.pagination, pagination => pagination)

ExhibitorsModule.registerSelector('queryParams', (state, moduleState) => moduleState.queryParams, queryParams => queryParams)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////

ExhibitorsModule.registerDataAction('get', (overwriteQueryParams = {}) => (dispatch, getState) => {
  const queryParams = { ...ExhibitorsModule.selectors.queryParams(getState()), ...overwriteQueryParams }

  dispatch({ type: ExhibitorsModule.actionKeys.getStart })

  return ExhibitorsModule._getService('get')(queryParams)
    .then(response => {
      dispatch({
        payload: {
          data: response.data,
          page: response.page,
          pageCount: response.pageCount,
        },
        type: ExhibitorsModule.actionKeys.getSuccess,
      })
    })
    .catch(() => {
      dispatch({ type: ExhibitorsModule.actionKeys.getError })
      return Promise.reject()
    })
})

ExhibitorsModule.registerDataAction('getOne', exhibitorId => dispatch => {
  const eventId = ExhibitorsModule._getEventId()

  dispatch({ type: ExhibitorsModule.actionKeys.getOneStart })

  const getExhibitor = () => serviceGetExhibitor(eventId, exhibitorId)
    .catch(error => Promise.reject(error.description))

  const getRepresentatives = () => serviceGetRepresentativesForExhibitor(eventId, exhibitorId)

  const getAnalytics = () => serviceGetExhibitorAnalytics(eventId, exhibitorId)

  return Promise.all([getExhibitor(), getRepresentatives(), getAnalytics()])
    .then(([exhibitor, representatives, analytics]) => {
      dispatch({
        payload: { analytics, exhibitor, representatives },
        type: ExhibitorsModule.actionKeys.getOneSuccess,
      })
    })
    .catch(() => {
      dispatch({ type: ExhibitorsModule.actionKeys.getOneError })
      return Promise.reject()
    })
})

ExhibitorsModule.registerDataAction('create', data => dispatch => {
  dispatch({ type: ExhibitorsModule.actionKeys.createStart })

  return ExhibitorsModule._getService('post')(data)
    .then(response => {
      dispatch({
        payload: response,
        type: ExhibitorsModule.actionKeys.createSuccess,
      })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('exhibitors.create.title'),
        message: i18n.getFixedT(i18n.language, 'admin_customModule')('exhibitors.create.success'),
        level: 'success',
        permanent: false,
      }))
    })
    .catch(error => {
      dispatch({ type: ExhibitorsModule.actionKeys.createError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('exhibitors.create.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    })
})

ExhibitorsModule.registerDataAction('update', data => dispatch => {
  dispatch({ type: ExhibitorsModule.actionKeys.updateStart })

  return ExhibitorsModule._getService('put')(data)
    .then(response => {
      dispatch({
        payload: response,
        type: ExhibitorsModule.actionKeys.updateSuccess,
      })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('exhibitors.update.title'),
        message: i18n.getFixedT(i18n.language, 'admin_customModule')('exhibitors.update.success'),
        level: 'success',
        permanent: false,
      }))
    })
    .catch(error => {
      dispatch({ type: ExhibitorsModule.actionKeys.updateError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('exhibitors.update.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    })
})

ExhibitorsModule.registerDataAction('delete', exhibitorId => dispatch => {
  dispatch({ type: ExhibitorsModule.actionKeys.deleteStart })

  return ExhibitorsModule._getService('delete')(exhibitorId)
    .then(() => {
      dispatch({
        payload: exhibitorId,
        type: ExhibitorsModule.actionKeys.deleteSuccess,
      })
    })
    .catch(error => {
      dispatch({ type: ExhibitorsModule.actionKeys.deleteError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('exhibitors.delete.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    })
})

ExhibitorsModule.registerDataAction('addRepresentative', (exhibitorId, attendeeId, options) => dispatch => {
  const showSuccessMessage = getProperty(options, 'showSuccessMessage')
  const showErrorMessage = getProperty(options, 'showErrorMessage')

  dispatch({
    payload: { attendeeId, exhibitorId },
    type: ExhibitorsModule.actionKeys.addRepresentativeStart,
  })

  return serviceAddRepresentative(ExhibitorsModule._getEventId(), exhibitorId, attendeeId)
    .then(() => {
      dispatch({
        payload: { attendeeId, exhibitorId },
        type: ExhibitorsModule.actionKeys.addRepresentativeSuccess,
      })

      if (showSuccessMessage) {
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('exhibitors.add_representative.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('exhibitors.add_representative.success'),
          level: 'success',
          permanent: false,
        }))
      }
    })
    .catch(error => {
      dispatch({
        payload: { attendeeId, exhibitorId },
        type: ExhibitorsModule.actionKeys.addRepresentativeError,
      })

      if (showErrorMessage) {
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('exhibitors.add_representative.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }

      return Promise.reject()
    })
})

ExhibitorsModule.registerAction('addRepresentatives', (exhibitorId, attendeeIds) => dispatch => Promise.all([...attendeeIds.map(attendeeId =>
  dispatch(ExhibitorsModule.actions.addRepresentative(exhibitorId, attendeeId, { showErrorMessage: true, showSuccessMessage: false }))
)]))

ExhibitorsModule.registerDataAction('deleteRepresentative', (exhibitorId, attendeeId) => dispatch => {
  dispatch({
    payload: { attendeeId, exhibitorId },
    type: ExhibitorsModule.actionKeys.deleteRepresentativeStart,
  })

  return serviceDeleteRepresentative(ExhibitorsModule._getEventId(), exhibitorId, attendeeId)
    .then(() => {
      dispatch({
        payload: { attendeeId, exhibitorId },
        type: ExhibitorsModule.actionKeys.deleteRepresentativeSuccess,
      })
    })
    .catch(error => {
      dispatch({
        payload: { attendeeId, exhibitorId },
        type: ExhibitorsModule.actionKeys.deleteRepresentativeError,
      })
      dispatch(showToast({
        title: '',
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    })
})

ExhibitorsModule.registerDataAction('addAdmin', (exhibitorId, data) => dispatch => {
  dispatch({
    payload: data,
    type: ExhibitorsModule.actionKeys.addAdminStart,
  })

  return serviceAddExhibitorAdmin(ExhibitorsModule._getEventId(), exhibitorId, data)
    .then(response => {
      dispatch({
        payload: response,
        type: ExhibitorsModule.actionKeys.addAdminSuccess,
      })
    })
    .catch(error => {
      dispatch({
        payload: data,
        type: ExhibitorsModule.actionKeys.addAdminError,
      })
      dispatch(showToast({
        title: '',
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    })
})

ExhibitorsModule.registerDataAction('deleteAdmin', (exhibitorId, accountId) => dispatch => {
  dispatch({
    payload: { accountId, exhibitorId },
    type: ExhibitorsModule.actionKeys.deleteAdminStart,
  })

  return serviceDeleteExhibitorAdmin(ExhibitorsModule._getEventId(), exhibitorId, accountId)
    .then(() => {
      dispatch({
        payload: { accountId, exhibitorId },
        type: ExhibitorsModule.actionKeys.deleteAdminSuccess,
      })
    })
    .catch(error => {
      dispatch({
        payload: { accountId, exhibitorId },
        type: ExhibitorsModule.actionKeys.deleteAdminError,
      })
      dispatch(showToast({
        title: '',
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    })
})

ExhibitorsModule.registerAction('resetPassword', (exhibitorId, accountId, password) => dispatch =>
  serviceResetExhibitorAdminPassword(ExhibitorsModule._getEventId(), exhibitorId, accountId, password)
    .then(() => {
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('exhibitors.reset_password.title'),
        message: i18n.getFixedT(i18n.language, 'admin_customModule')('exhibitors.reset_password.success'),
        level: 'success',
        permanent: false,
      }))
    })
    .catch(error => {
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('exhibitors.reset_password.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    })
)

ExhibitorsModule.registerAction('setSearchTerm', searchTerm => dispatch => {
  dispatch({
    payload: searchTerm,
    type: ExhibitorsModule.actionKeys.setSearchTerm,
  })
  return Promise.resolve()
})

ExhibitorsModule.registerAction('changePage', page => dispatch => {
  dispatch({
    payload: page,
    type: ExhibitorsModule.actionKeys.changePage,
  })
  return Promise.resolve()
})

ExhibitorsModule.registerAction('setSortingColumn', columnKey => (dispatch, getState) => {
  const queryParams = ExhibitorsModule.selectors.queryParams(getState())

  dispatch({
    payload: {
      sort_column: columnKey,
      sort_order: queryParams.sort_column && queryParams.sort_column === columnKey ? queryParams.sort_order * -1 : 1,
    },
    type: ExhibitorsModule.actionKeys.setSortingColumn,
  })

  return Promise.resolve()
})


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////

ExhibitorsModule.registerReducer(
  ExhibitorsModule.actionKeys.getSuccess,
  (state, action) => ({
    ...state,
    data: action.payload.data,
    isLoaded: true,
    isLoading: false,
    lastUpdated: Date.now(),
    pagination: {
      page: action.payload.page,
      pageCount: action.payload.pageCount,
    },
  })
)

ExhibitorsModule.registerReducer(
  ExhibitorsModule.actionKeys.getOneSuccess,
  (state, action) => ({
    ...state,
    currentExhibitor: {
      ...action.payload.exhibitor,
      analytics: action.payload.analytics,
      representatives: action.payload.representatives,
    },
  })
)

ExhibitorsModule.registerReducer(
  ExhibitorsModule.actionKeys.createSuccess,
  (state, action) => ({
    ...state,
    data: [...state, action.payload],
  })
)

ExhibitorsModule.registerReducer(
  ExhibitorsModule.actionKeys.updateSuccess,
  (state, action) => {
    const updatedData = [...state.data]

    const getIndex = () => {
      for (let i = 0; i < state.data.length; i++) {
        if (state.data[i].id === action.payload.id) {
          return i
        }
      }
      return -1
    }

    updatedData[getIndex()] = action.payload

    return {
      ...state,
      currentExhibitor: { ...state.currentExhibitor, ...action.payload.exhibitor },
      data: updatedData,
    }
  }
)

ExhibitorsModule.registerReducer(
  ExhibitorsModule.actionKeys.deleteSuccess,
  (state, action) => ({
    ...state,
    data: state.data.filter(d => d.id !== action.payload),
  })
)

ExhibitorsModule.registerReducer(
  ExhibitorsModule.actionKeys.setSearchTerm,
  (state, action) => ({
    ...state,
    queryParams: {
      ...state.queryParams,
      page: 1,
      query: action.payload,
    },
  })
)

ExhibitorsModule.registerReducer(
  ExhibitorsModule.actionKeys.changePage,
  (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      page: action.payload,
    },
    queryParams: {
      ...state.queryParams,
      page: action.payload,
    },
  })
)

ExhibitorsModule.registerReducer(
  ExhibitorsModule.actionKeys.setSortingColumn,
  (state, action) => ({
    ...state,
    queryParams: {
      ...state.queryParams,
      sort_column: action.payload.sort_column,
      sort_order: action.payload.sort_order,
    },
  })
)


export default ExhibitorsModule
