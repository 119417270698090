
/**
 * Sets a new function that can be called by the webview
 */
export function setWebviewFunction(name, func) {
  window.klikWebview[name] = func
}

function sendWebview(type, msg) {
  window.webkit.messageHandlers[type].postMessage(msg)
}

const callbacks = {}
let callbackCount = 0

/**
 * Generic way of calling the webview and receiving a callback once the task is complete
 */
export function callWebview(cmd, args, cb) {
  callbackCount += 1
  const cbid = callbackCount.toString()
  callbacks[cbid] = cb

  const msg = {
    command: cmd,
    cb: cbid,
    ...args,
  }

  sendWebview('commandWithCallback', msg)
}

function handleWebviewCallback(cbid, data, err) {
  const cb = callbacks[cbid]
  if (cb) {
    cb(data, err)
    delete callbacks[cbid]
  }
}

export function initializeWebview() {
  window.klikWebview = {
    commandCallback: handleWebviewCallback,
  }
}

if (!window.klikWebview) {
  initializeWebview()
}
