import { DataModule } from 'redux-data-module'
import { serviceDeleteAttendee, serviceGetAttendees, serviceGetAttendeeFilterAvailableFields } from 'admin/services/users'

import { showToast } from 'common/modules/toaster'
import { getTranslation } from 'common/utils/translator'

import { currentEventIdSelector } from 'admin/modules/events'

const DEFAULT_COLUMNS = [
  { label_key: 'last_imported', key: 'records.last_imported', sort_key: 'last_imported', active: true },
  { label_key: 'last name', key: 'last_name', sort_key: 'last_name', active: true },
  { label_key: 'first name', key: 'first_name', sort_key: 'first_name', active: true },
  { label_key: 'email', key: 'email', sort_key: 'email', active: true },
  { label_key: 'score', key: 'score', sort_key: 'score', active: false },
  { label_key: 'email status', key: 'registration_status', sort_key: 'registration_status_percentage', active: true },
  { label_key: 'last_checked_in', key: 'records.last_checked_in', sort_key: 'last_checked_in', active: true },
  { label_key: 'city', key: 'city', sort_key: 'city', active: false },
  { label_key: 'company', key: 'company', sort_key: 'company', active: false },
  { label_key: 'country', key: 'country', sort_key: 'country', active: false },
  { label_key: 'group', key: 'group.title', sort_key: 'group', active: false },
  { label_key: 'number of connections', key: 'number_of_connections', sort_key: 'number_of_connections', active: false },
  { label_key: 'occupation', key: 'occupation', sort_key: 'occupation', active: false },
  { label_key: 'custom_id', key: 'custom_id', sort_key: 'custom_id', active: false },
]

const AttendeesModule = new DataModule({
  moduleKey: 'cpo/attendees',
  reducerKey: 'admin.attendees',

  initialState: {
    filterValues: [],
    availableFilterFields: {},
    queryParams: {
      query: undefined,
      registration_status: [],
      checked_in: [],
      group: [],
      sort_column: 'last_name',
      sort_order: 1,
    },

    pagination: {
      page: 1,
      pageCount: 1,
    },

    tableColumns: [],
  },

  services: {
    delete: { service: serviceDeleteAttendee },
    get: { service: serviceGetAttendees },
  },
})

  ; (function registerSelectors() {
    AttendeesModule.registerSelector('pagination', (state, moduleState) => moduleState.pagination, pagination => pagination)
    AttendeesModule.registerSelector('tableColumns', (state, moduleState) => moduleState.tableColumns, columns => columns)
    AttendeesModule.registerSelector('filterValues', (state, moduleState) => moduleState.filterValues, value => value)


    AttendeesModule.registerSelector(
      'dropdownFilterValues',
      [
        (state, moduleState) => moduleState.availableFilterFields,
        (state, moduleState) => moduleState.queryParams,
      ],
      (filterFields, params) => {
        return ({
          emailStatus: (filterFields.registration_status || []),
          checkedIn: (filterFields.checked_in || []),
          attendeeTypes: (filterFields.group || []),
          customFields: (filterFields.custom_fields || [])
            .map(field => ({
              ...field,
              options: field.options,
            }))
        })
      },
    )
    AttendeesModule.registerSelector(
      'queryParams',
      [(state, moduleState) => moduleState.queryParams, (state, moduleState) => moduleState.pagination],
      (queryParams, pagination) => ({
        ...queryParams,
        page: pagination.page,
      })
    )
  }())
  ; (function registerTableActions() {
    AttendeesModule.registerActionKey('initializeTableColumns')

    AttendeesModule.actions.initializeTableColumns = extraColumns => (dispatch, getState) => {
      const columns = extraColumns ? [...AttendeesModule.selectors.tableColumns(getState()), ...extraColumns] : null

      return Promise.resolve(
        dispatch({
          type: AttendeesModule.actionKeys.initializeTableColumns,
          payload: columns || DEFAULT_COLUMNS,
        })
      )
    }

    AttendeesModule.reducers[AttendeesModule.actionKeys.initializeTableColumns] = (state, action) => ({
      ...state,
      tableColumns: action.payload,
    })

    AttendeesModule.registerActionKey('toggleTableColumnVisibility')

    AttendeesModule.actions.toggleTableColumnVisibility = columnIndex => dispatch =>
      dispatch({
        type: AttendeesModule.actionKeys.toggleTableColumnVisibility,
        payload: columnIndex,
      })

    AttendeesModule.reducers[AttendeesModule.actionKeys.toggleTableColumnVisibility] = (state, action) => ({
      ...state,
      tableColumns: state.tableColumns.map((column, index) => (index === action.payload ? { ...column, active: !column.active } : column)),
    })

    AttendeesModule.registerActionKey('setSortingColumn')

    AttendeesModule.actions.setSortingColumn = columnKey => (dispatch, getState) => {
      const queryParams = AttendeesModule.selectors.queryParams(getState())

      dispatch({
        type: AttendeesModule.actionKeys.setSortingColumn,
        payload: {
          sort_column: columnKey,
          sort_order: queryParams.sort_column && queryParams.sort_column === columnKey ? queryParams.sort_order * -1 : 1,
        },
      })

      return Promise.resolve()
    }

    AttendeesModule.reducers[AttendeesModule.actionKeys.setSortingColumn] = (state, action) => ({
      ...state,
      queryParams: {
        ...state.queryParams,
        sort_column: action.payload.sort_column,
        sort_order: action.payload.sort_order,
      },
    })
  }())
  ; (function registerGetAttendees() {
    AttendeesModule.actions.get = (queryParams = {}, shouldSaveQuery = true) => (dispatch, getState) => {
      dispatch({
        type: AttendeesModule.actionKeys.getStart,
        payload: {
          queryParams: queryParams,
          // searchTerm: queryParams.query,
          shouldSaveQuery: shouldSaveQuery,
        },
      })

      return serviceGetAttendees(currentEventIdSelector(getState()), queryParams)
        .then(response => dispatch({ type: AttendeesModule.actionKeys.getSuccess, payload: response }))
        .catch(error => {
          dispatch({ type: AttendeesModule.actionKeys.getError })
          dispatch(
            showToast({
              title: getTranslation('admin_customModule', 'attendees.get.title'),
              message: error.description,
              level: 'error',
              permanent: false,
            })
          )
        })
    }

    AttendeesModule.reducers[AttendeesModule.actionKeys.getStart] = (state, action) => ({
      ...state,
      isLoading: true,
      queryParams: action.payload.shouldSaveQuery ? {
        ...state.queryParams, ...action.payload.queryParams,
        // query: action.payload.shouldSaveQuery ? action.payload.searchTerm : state.queryParams.query,

      } : { ...state.queryParams },
    })

    AttendeesModule.reducers[AttendeesModule.actionKeys.getSuccess] = (state, action) => ({
      ...state,
      data: action.payload.data,
      isLoaded: true,
      isLoading: false,
      pagination: {
        page: action.payload.page,
        pageCount: action.payload.pageCount,
      },
    })
  }())
  ; (function registerDeleteAttendee() {
    AttendeesModule.actions.delete = attendeeId => (dispatch, getState) => {
      dispatch({
        type: AttendeesModule.actionKeys.deleteStart,
        payload: attendeeId,
      })

      return serviceDeleteAttendee(currentEventIdSelector(getState()), attendeeId)
        .then(() =>
          dispatch({
            type: AttendeesModule.actionKeys.deleteSuccess,
            payload: attendeeId,
          })
        )
        .catch(error => {
          dispatch({
            type: AttendeesModule.actionKeys.deleteError,
            payload: attendeeId,
          })

          dispatch(
            showToast({
              title: getTranslation('admin_customModule', 'attendees.delete.title'),
              message: error.description,
              level: 'error',
              permanent: false,
            })
          )
        })
    }
  }())
  ; (function registerPaginationActions() {
    AttendeesModule.registerActionKey('changePage')

    AttendeesModule.actions.changePage = page => dispatch => {
      dispatch({
        type: AttendeesModule.actionKeys.changePage,
        payload: page,
      })

      return Promise.resolve(page)
    }

    AttendeesModule.reducers[AttendeesModule.actionKeys.changePage] = (state, action) => ({
      ...state,
      pagination: {
        ...state.pagination,
        page: action.payload,
      },
    })
  }());


AttendeesModule.registerActionKey('getAvailableFilterFieldsStart')
AttendeesModule.registerActionKey('getAvailableFilterFieldsSuccess')
AttendeesModule.registerActionKey('getAvailableFilterFieldsError')

AttendeesModule.actions.getAvailableFilterFields = () => (dispatch, getState) => {
  dispatch({ type: AttendeesModule.actionKeys.getAvailableFilterFieldsStart })
  return serviceGetAttendeeFilterAvailableFields(currentEventIdSelector(getState()))
    .then(response => dispatch({ type: AttendeesModule.actionKeys.getAvailableFilterFieldsSuccess, payload: response }))
    .catch(() => dispatch({ type: AttendeesModule.actionKeys.getAvailableFilterFieldsError }))
}

AttendeesModule.reducers[AttendeesModule.actionKeys.getAvailableFilterFieldsSuccess] = (state, action) => ({
  ...state,
  availableFilterFields: { ...action.payload },
})


AttendeesModule.registerActionKey('resetFilters')

AttendeesModule.actions.resetFilters = () => dispatch => {
  dispatch({ type: AttendeesModule.actionKeys.resetFilters })
  return Promise.resolve()
}

AttendeesModule.reducers[AttendeesModule.actionKeys.resetFilters] = (state) => ({
  ...state,
  queryParams: AttendeesModule.initialState.queryParams,
})


AttendeesModule.reducers['CHANGE_EVENT_SUCCESS'] = state => ({
  ...state,
  pagination: AttendeesModule.initialState.pagination,
  tableColumns: DEFAULT_COLUMNS,
  queryParams: AttendeesModule.initialState.queryParams,
})


AttendeesModule.registerActionKey('setFilterValues')

AttendeesModule.actions.setFilterValues = (state) => dispatch => {
  dispatch({
    type: AttendeesModule.actionKeys.setFilterValues,
    payload: state,
  })
}

AttendeesModule.reducers[AttendeesModule.actionKeys.setFilterValues] = (state, action) => ({
  ...state,
  filterValues: action.payload,
})

export default AttendeesModule

