import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { translate } from 'react-i18next'
import { getTextColor } from 'common/utils/domUtils'

import Input from 'common/components/Input'
import SelectDropdown from 'common/components/SelectDropdown'

@translate(['login'], { wait: true })
@reduxForm({
  form: 'login',
  fields: ['email', 'password_notrim', 'methodId', 'mfaToken', 'methodToken'],
  destroyOnUnmount: false,
})
export default class EnterPassword extends Component {
  componentWillMount() {
    if (this.props.multiFactorAuthentification.methods.length <= 0) {
      this.props.changeLoginPage(this.props.location, 'email')
    } else {
      this.props.initializeForm({
        email: this.props.values.email,
        password_notrim: this.props.values.password_notrim,
        mfaToken: this.props.multiFactorAuthentification.token,
        methodId: (this.props.multiFactorAuthentification.methods.length === 1
          ? this.props.multiFactorAuthentification.methods[0].id
          : ''
        ),
        methodToken: '',
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.multiFactorAuthentification.token !== this.props.multiFactorAuthentification.token) {
      nextProps.fields.mfaToken.onChange(nextProps.multiFactorAuthentification.token)
    }
  }

  componentDidMount() {
    this.props.changeInputBorderColor()
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { domain } = this.props
    const { methodToken, methodId, mfaToken, email, password_notrim } = this.props.values

    this.props.loginMultiFactorAuthentification(methodToken, methodId, mfaToken, domain)
      .then(({ needDomainSelection }) => {
        if (needDomainSelection) {
          this.props.changeLoginPage(this.props.location, 'domain_selector')
        } else {
          this.props.destroyForm()
        }
      })
      .catch(() => {
        // Request another mfa_token
        this.props.login(email, password_notrim, domain)
        this.props.fields.methodToken.onChange('')
      })
  }


  render() {
    const { t, loginErrorMessage, multiFactorAuthentification } = this.props
    const { brandColor } = this.props.loginBranding
    const { email, methodId, methodToken } = this.props.fields
    const isButtonDisabled = (!methodId.value || !methodToken.value)

    return (
      <div className="EnterPassword">
        <form onSubmit={this.onSubmit}>
          <Input field={email} placeholder={t('login_email.placeholder')} type="email" readOnly />

          <Input
            field={methodId}
            customInput={
              <SelectDropdown
                value={methodId.value}
                data={[
                  { value: '', label: t('mfa_method_default_name') },
                  ...multiFactorAuthentification.methods.map(method => ({ value: `${method.id}`, label: method.name })),
                ]}
                textField="label"
                valueField="value"
                onChange={selected => {
                  methodId.onChange(selected.value)

                  if (!selected.value) {
                    methodToken.onChange('')
                  }
                }}
              />
            }
          />

          {methodId.value && <Input field={methodToken} placeholder={t('mfa_method_token_placeholder')} />}

          {loginErrorMessage &&
            <div className="login-error-message-container">
              {loginErrorMessage}
            </div>}

          <button style={{ color: getTextColor(brandColor), backgroundColor: brandColor }} className="btn btn-block" type="submit" disabled={isButtonDisabled}>
            {this.props.isFetching
              ? <i className="fa fa-spin fa-circle-o-notch" aria-hidden="true" />
              : t('login_password.log_in')}
          </button>
        </form>
      </div>
    )
  }
}
