import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { getAxiosConfig } from './helper';


function translateAppointmentSession(session) {
  const data = { ...session }
  if (data.title) data.title = getTranslatedObject(data.title)
  return data
}


export function serviceGetAppointmentSession(eventId, sessionId, success, error) {
  return axios.get(`/events/${eventId}/sessions/${sessionId}`, getAxiosConfig())
    .then((response) => success(translateAppointmentSession(response.data), apiErrorHandler(error, 'backend')))
    .catch(response => apiCatchError(response))
}

export function serviceGetAppointments(eventId, calendarId, sessionId, success, error) {
  return axios.get(`/events/${eventId}/calendars/${calendarId}/sessions/${sessionId}/appointments?with_participants=true`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceGetSessionRelations(eventId, calendarId, sessionId, success, error) {
  return axios.get(`/events/${eventId}/calendars/${calendarId}/sessions/${sessionId}/attendees/going`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceGetSessionRelationsForAttendee(eventId, attendeeId, calendarId, sessionId, success, error) {
  return axios.get(`/events/${eventId}/calendars/${calendarId}/sessions/${sessionId}/attendees/going?query=${attendeeId}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceAddAppointment(eventId, calendarId, sessionId, data, success, error) {
  return axios.post(`/events/${eventId}/calendars/${calendarId}/sessions/${sessionId}/appointments`, data, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceEditAppointment(eventId, calendarId, sessionId, data, success, error) {
  return axios.put(`/events/${eventId}/calendars/${calendarId}/sessions/${sessionId}/appointments/${data.id}`, data, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceDeleteAllAppointments(eventId, calendarId, sessionId, success, error) {
  return axios.delete(`/events/${eventId}/calendars/${calendarId}/sessions/${sessionId}/appointments`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceDeleteAppointment(eventId, calendarId, sessionId, appointmentId, success, error) {
  return axios.delete(`/events/${eventId}/calendars/${calendarId}/sessions/${sessionId}/appointments/${appointmentId}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceAddParticipant(eventId, sessionId, appointmentId, attendeeId, success, error) {
  return axios.put(`/events/${eventId}/attendees/${attendeeId}/sessions/${sessionId}/going?skip_capacity_check=true&appointment_id=${appointmentId}`, {}, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceRemoveParticipant(eventId, sessionId, appointmentId, attendeeId, success, error) {
  return axios.delete(`/events/${eventId}/attendees/${attendeeId}/sessions/${sessionId}/going?skip_capacity_check=true&appointment_id=${appointmentId}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceCheckInParticipantToAppointment(eventId, attendeeId, sessionId, appointmentId, success, error) {
  return axios.put(`/events/${eventId}/attendees/${attendeeId}/sessions/${sessionId}/check_in?skip_validation=true`, { appointment_id: appointmentId }, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceCheckOutParticipantFromAppointment(eventId, attendeeId, sessionId, appointmentId, success, error) {
  return axios.delete(`/events/${eventId}/attendees/${attendeeId}/sessions/${sessionId}/check_in?appointment_id=${appointmentId}&skip_validation=true`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
