import { serviceGetSessionAttendanceDistribution } from 'admin/services/analytics'
import generateModule from 'common/utils/generateModule'


const { actions, reducers, initialState } = generateModule({
  itemName: 'sessionAttendanceDistribution',
  autoRefresh: 60,
  getModuleState: (getState) => getState().admin.analytics.distributionGraphs.sessionAttendance,
  services: {
    get: serviceGetSessionAttendanceDistribution,
  },
})


actions.get = (query) =>
  (dispatch, getState) => {
    dispatch(actions.getStart())
    return serviceGetSessionAttendanceDistribution(
      getState().admin.events.currentEvent.id,
      query,
      (response) => {
        dispatch(actions.getSuccess(response))
      },
      () => dispatch(actions.getError())
    )
  }


reducers['CHANGE_EVENT_SUCCESS'] = (state) => ({
  ...state,
  didInvalidate: true,
  data: initialState.data,
})


export const getSessionAttendanceDistribution = actions.get
export const getSessionAttendanceDistributionIfNeeded = actions.getIfNeeded

const sessionAttendance = reducers.index
export default sessionAttendance
