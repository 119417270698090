import axios from 'axios'
import { buildError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { getAxiosConfig as getBaseAxiosConfig } from './helper'

const getAxiosConfig = () => getBaseAxiosConfig({ 'Accept-Language': 'mul' })


export function translateBroadcast(broadcast) {
  const data = { ...broadcast }

  if (data.message) {
    data.message = getTranslatedObject(broadcast.message)
  }

  return data
}


export function serviceGetBroadcast() {
  return axios.get('/broadcast', getAxiosConfig())
    .then((response) => translateBroadcast(response.data))
    .catch((error) => Promise.reject(buildError(error)))
}

export function serviceSetBroadcast(broadcast) {
  return axios.post('/broadcast', broadcast, getAxiosConfig())
    .then((response) => translateBroadcast(response.data))
    .catch((error) => Promise.reject(buildError(error)))
}

export function serviceDeleteBroadcast() {
  return axios.delete('/broadcast', getAxiosConfig())
    .catch((error) => Promise.reject(buildError(error)))
}
