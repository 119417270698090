import React, { Component } from 'react'

import Tooltip from 'common/components/Tooltip'

export default class Input extends Component {
  render() {
    const {
      autoFocus,
      className,
      customInput,
      disabled,
      field,
      id,
      isRequired,
      label,
      placeholder,
      readOnly,
      textareaRows,
      tooltip,
      type,
      isTranslatable,
    } = this.props

    const inputComponent = () => {
      switch (type) {
        case 'textarea':
          return <textarea className="form-control" id={field.name} rows={textareaRows} placeholder={placeholder} readOnly={readOnly} {...field} />
        default:
          return (
            <input
              className="form-control"
              id={field.name}
              type={type}
              placeholder={placeholder}
              readOnly={readOnly}
              disabled={disabled}
              autoFocus={autoFocus}
              {...field}
            />
          )
      }
    }

    const helpBlockComponent = () => (
      <div className="help-block">
        <span className="text-danger pull-right">{field.error}</span>
      </div>
    )

    return (
      <div
        className={`form-group schema-form-text ${field.error && field.touched && 'has-error'} ${className || ''}`}
        id={id}
        data-isTranslatable={isTranslatable}
        ref={ref => {
          this.refToRootElement = ref
        }}
      >
        {label && (
          <label className={`control-label ${isRequired ? 'field-required' : ''}`} htmlFor={field.name}>
            {label} {tooltip ? <Tooltip description={tooltip} /> : null}
          </label>
        )}
        {customInput || inputComponent()}
        {field && field.error && field.touched && helpBlockComponent()}
      </div>
    )
  }
}

Input.defaultProps = {
  /* eslint-disable no-multi-spaces */
  autoFocus: false, // Shoud the input be autofocused
  className: '', // Class name of the wrapper
  customInput: undefined, // You can use a custom input instead
  disabled: false, // Should the input be disabled
  field: {}, // The redux form field
  isRequired: false, // Is the field required
  label: '', // The label for the field input
  placeholder: '', // Input's placeholder
  readOnly: false, // Is the input field readOnly
  textareaRows: 4, // Number of rows to display for a textarea input
  tooltip: '', // Text that you want to display in a tooltip
  type: 'text', // Input type
  isTranslatable: false, // Is the field translatable
} /* eslint-enable */
