import i18n from 'i18next'

export const KLIK_SUPPORTED_LANGUAGES = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'Français' },
  { value: 'es', label: 'Español' },
  { value: 'de', label: 'Deutsche' },
  { value: 'zh', label: '简体中文' },
]

export function getLanguageLabel(language, uiLanguage = i18n.language) {
  return i18n.getFixedT(uiLanguage, 'languages')(language)
}

export const KLIK_SUPPORTED_LANGUAGE_VALUES = KLIK_SUPPORTED_LANGUAGES.map(l => l.value)

const languages = [...KLIK_SUPPORTED_LANGUAGES.map(l => l.value), 'und']

export function getTranslation(namespace, key, language = 'en', parameters = {}) { // eslint-disable-line import/prefer-default-export
  return i18n.getFixedT(language)(namespace + ':' + key, parameters)
}

export function t(namespace, key, extra) {
  return getTranslation(namespace, key, i18n.language, extra)
}

/**
 * Primary language is the language that you are currently using. So if
 * I am using 'en' and the language I want to check is 'en', then I
 * am using the primary language
 */
export function isPrimaryLanguage(language) {
  return i18n.language === language
}

/**
 * Pass an object that contains translation and return an object that contains the languages key (no und)
 */
export function getTranslatedObject(objectToTranslate, showEmptyValue = false, mainLanguage = i18n.language) {
  let objectToReturn = {}

  if (objectToTranslate instanceof Object) objectToReturn = { ...objectToTranslate }
  else objectToReturn[mainLanguage] = objectToTranslate || ''

  // If there is only a 'und' value, use it as the primary language value
  if (objectToReturn.und && !objectToReturn[mainLanguage]) objectToReturn[mainLanguage] = objectToReturn.und

  delete objectToReturn.und

  if (showEmptyValue) {
    languages.forEach(language => {
      if (!objectToReturn[language]) objectToReturn[language] = ''
    })
  }

  return objectToReturn
}


/**
 * Pass a translation object and get the translated string (default uses primary language)
 */
export function getTranslatedValue(translationObject, language = i18n.language) {
  const getTranslationFallback = () => {
    for (let i = 0; i < languages.length; i++) {
      const translation = translationObject[languages[i]]
      if (translation) return translation
    }
    return ''
  }

  if (translationObject instanceof Object) {
    let translation = translationObject[language]
    if (!translation) translation = getTranslationFallback()
    return translation || ''
  }

  return translationObject != null ? translationObject : ''
}
