import { BaseModule } from 'redux-data-module'

const FieldsManagerModule = new BaseModule({
  actionKeyPrefix: 'cpo/fieldsManager',
  initialState: {
    selection: {
      listId: 'standard',
      itemId: null,
    },
  },
  moduleKey: 'fieldsManager',
  reducerKey: 'admin.fieldsManager',
})


FieldsManagerModule.registerSelector(
  'selection',
  (state, moduleState) => moduleState.selection,
  (selection) => selection
)

FieldsManagerModule.registerAction('setSelection', (listId, itemId) =>
  (dispatch) => {
    dispatch({
      type: FieldsManagerModule.actionKeys.setSelection,
      payload: { listId, itemId },
    })
    return Promise.resolve()
  }
)

FieldsManagerModule.registerReducer(FieldsManagerModule.actionKeys.setSelection, (state, action) => ({
  ...state,
  selection: { ...action.payload },
}))


FieldsManagerModule.registerAction('resetSelection', () =>
  (dispatch) => {
    dispatch({ type: FieldsManagerModule.actionKeys.resetSelection })
    return Promise.resolve()
  }
)

FieldsManagerModule.registerReducer(FieldsManagerModule.actionKeys.resetSelection, (state) => ({
  ...state,
  selection: { ...FieldsManagerModule.initialState.selection },
}))


export default FieldsManagerModule
