import { assetsUrl } from 'config'

function appendParam(query, key, value) {
  return query
    ? `${query}&${key}=${value}`
    : `?${key}=${value}`
}

function buildQueryString(width, height, format) {
  let queryString = ''
  const params = { width, height, format }

  Object.keys(params).forEach(key => {
    if (params[key]) {
      queryString = appendParam(queryString, key, params[key])
    }
  })

  return queryString
}

export function buildAssetURL(assetId) {
  return `${assetsUrl}/${assetId}`
}

export function getImageAssetURL(url, width, height, format) {
  return url + buildQueryString(width, height, format)
}
