import { serviceGetSessions, serviceGetAppointments, serviceSendFeedbackForSession, serviceAddToAgenda, serviceRemoveFromAgenda, serviceConfirmAttendanceToSession, serviceCancelAttendanceToSession, serviceAcceptInvitation, serviceDeclineInvitation, serviceConfirmAppointmentReservation, serviceCancelAppointmentReservation } from 'attendee/services/sessions'
import { listToMapping } from 'common/utils/componentHelper'
import { getSessions as getSessionsFromCalendar } from './Helper'
import { handleErrorMessage } from '../index'


export function setBookingConflictModal(isVisible, error) {
  return dispatch => dispatch({ type: 'SESSIONS_SET_BOOKING_CONFLICT_MODAL', payload: { isVisible, error } })
}


export function getSessions() {
  return (dispatch, getState) => {
    dispatch({ type: 'GET_SESSIONS_START' })
    return serviceGetSessions(
      getState().attendee.attEvents.currEvent.id,
      (response) => {
        const { sessions, calendars } = getSessionsFromCalendar(response)
        const calendarOrder = listToMapping(calendars, 'id', { mapToId: true })
        dispatch({ type: 'GET_SESSIONS_SUCCESS', payload: { sessions, calendars, calendarOrder } })
      },
      () => dispatch({ type: 'GET_SESSIONS_ERROR' })
    )
  }
}

export function getAppointments(calendarId, sessionId, shouldFilterOutConflicting) {
  return (dispatch, getState) => {
    dispatch({ type: 'GET_APPOINTMENTS_FOR_SESSION_START', payload: { calendarId, sessionId } })

    return serviceGetAppointments(
      getState().attendee.attEvents.currEvent.id,
      calendarId,
      sessionId,
      shouldFilterOutConflicting,
      (response) => {
        dispatch({ type: 'GET_APPOINTMENTS_FOR_SESSION_SUCCESS', payload: { calendarId, sessionId, appointments: response } })
        return Promise.resolve()
      },
      () => {
        dispatch({ type: 'GET_APPOINTMENTS_FOR_SESSION_ERROR', payload: { calendarId, sessionId } })
        return Promise.reject()
      }
    )
  }
}

export function sendFeedbackForSession(session, feedback) {
  return (dispatch, getState) => {
    dispatch({ type: 'SEND_FEEDBACK_FOR_SESSION', payload: { session, feedback: feedback } })
    return serviceSendFeedbackForSession(
      getState().attendee.attEvents.currEvent.id,
      session.id,
      feedback,
      () => Promise.resolve(),
      error => {
        dispatch({ type: 'SEND_FEEDBACK_FOR_SESSION', payload: { session, feedback: session.me.feedback } })
        handleErrorMessage(dispatch, getState, error)
        return Promise.reject(error)
      }
    )
  }
}


export function addToAgenda(session) {
  return (dispatch, getState) => {
    dispatch({ type: 'ADD_TO_AGENDA', payload: { session } })
    return serviceAddToAgenda(
      getState().attendee.attEvents.currEvent.id,
      session.id,
      () => {},
      error => {
        dispatch({ type: 'REMOVE_FROM_AGENDA', payload: { session } })
        handleErrorMessage(dispatch, getState, error)
      }
    )
  }
}

export function removeFromAgenda(session) {
  return (dispatch, getState) => {
    dispatch({ type: 'REMOVE_FROM_AGENDA', payload: { session } })
    return serviceRemoveFromAgenda(
      getState().attendee.attEvents.currEvent.id,
      session.id,
      () => {},
      error => {
        dispatch({ type: 'ADD_TO_AGENDA', payload: { session } })
        handleErrorMessage(dispatch, getState, error)
      }
    )
  }
}


export function confirmAppointmentReservation(session, appointmentId, isReplacing) {
  return (dispatch, getState) => serviceConfirmAppointmentReservation(
    getState().attendee.attEvents.currEvent.id,
    session.id,
    appointmentId,
    isReplacing,
    (response) => {
      // TODO: There is a concurrency error on the backend. For now just add "going" in the session relation
      const newSession = { ...response, me: { ...response.me, going: true } }
      dispatch({ type: 'CONFIRM_APPOINTMENT_RESERVATION', payload: newSession })
      return Promise.resolve()
    },
    error => {
      if (error.status === 409) {
        dispatch(setBookingConflictModal(true, error.description))
      } else {
        handleErrorMessage(dispatch, getState, error)
      }
      return Promise.reject(error)
    }
  )
}

export function cancelAppointmentReservation(session, appointmentId) {
  return (dispatch, getState) => serviceCancelAppointmentReservation(
    getState().attendee.attEvents.currEvent.id,
    session.id,
    appointmentId,
    (response) => {
      // TODO: There is not response when deleting an appointment. Remove the "going" and "interested" from session relation
      const newSession = { ...response, me: { ...response.me, interested: false, going: false } }
      dispatch({ type: 'CANCEL_APPOINTMENT_RESERVATION', payload: newSession })
      return Promise.resolve()
    },
    error => {
      handleErrorMessage(dispatch, getState, error)
      return Promise.reject(error)
    }
  )
}

export function confirmAttendanceToSession(session) {
  return (dispatch, getState) => {
    dispatch({ type: 'CONFIRM_ATTENDANCE_TO_SESSION', payload: { session } })
    return serviceConfirmAttendanceToSession(
      getState().attendee.attEvents.currEvent.id,
      session.id,
      () => Promise.resolve(),
      error => {
        dispatch({ type: 'CANCEL_ATTENDANCE_TO_SESSION', payload: { session } })
        if (error.status === 409) {
          dispatch(setBookingConflictModal(true, error.description))
        } else {
          handleErrorMessage(dispatch, getState, error)
        }
        return Promise.reject(error)
      }
    )
  }
}

export function cancelAttendanceToSession(session) {
  return (dispatch, getState) => {
    dispatch({ type: 'CANCEL_ATTENDANCE_TO_SESSION', payload: { session } })
    return serviceCancelAttendanceToSession(
      getState().attendee.attEvents.currEvent.id,
      session.id,
      () => Promise.resolve(),
      error => {
        dispatch({ type: 'CONFIRM_ATTENDANCE_TO_SESSION', payload: { session } })
        handleErrorMessage(dispatch, getState, error)
        return Promise.reject(error)
      }
    )
  }
}


export function acceptInvitation(session) {
  return (dispatch, getState) => {
    dispatch({ type: 'ACCEPT_SESSION_INVITATION', payload: { session } })
    return serviceAcceptInvitation(
      getState().attendee.attEvents.currEvent.id,
      session.id,
      () => Promise.resolve(),
      error => {
        dispatch({ type: 'ACCEPT_SESSION_INVITATION_ERROR', payload: { session } })
        if (error.status === 409) {
          dispatch(setBookingConflictModal(true, error.description))
        } else {
          handleErrorMessage(dispatch, getState, error)
        }
        return Promise.reject(error)
      }
    )
  }
}

export function declineInvitation(session, getSessionsFunction) {
  return (dispatch, getState) => {
    dispatch({ type: 'DECLINE_SESSION_INVITATION', payload: { session } })
    return serviceDeclineInvitation(
      getState().attendee.attEvents.currEvent.id,
      session.id,
      () => {},
      error => {
        dispatch(getSessionsFunction())
        handleErrorMessage(dispatch, getState, error)
      }
    )
  }
}
