import { DataModule } from 'redux-data-module'
import { serviceDeleteQualifier, serviceGetQualifiers, servicePostQualifier, servicePutQualifier } from 'exhibitor/services/qualifiers'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'

import { selectors as eventSelectors } from 'exhibitor/modules/event'
import { selectors as exhibitorSelectors } from 'exhibitor/modules/exhibitors'


function getServiceData(state) {
  return [
    eventSelectors.eventId(state),
    exhibitorSelectors.exhibitorId(state),
  ]
}


const QualifiersModule = new DataModule({
  initialData: {},
  moduleKey: 'cpe/qualifiers',
  reducerKey: 'exhibitor.qualifiers',

  services: {
    get: { service: serviceGetQualifiers },
  },
})


QualifiersModule.registerSelector(
  'data',
  (state, moduleState) => moduleState.data,
  qualifiers => Object.values(qualifiers).sort((a, b) => {
    if (a.order === b.order) {
      return a.id.localeCompare(b.id)
    }

    return a.order - b.order
  })
)


QualifiersModule.actions.get = () => (dispatch, getState) => {
  const rootState = getState()
  const eventId = eventSelectors.eventId(rootState)
  const exhibitorId = exhibitorSelectors.exhibitorId(rootState)

  dispatch({ type: QualifiersModule.actionKeys.getStart })

  return serviceGetQualifiers(eventId, exhibitorId)
    .then(response => {
      dispatch({ type: QualifiersModule.actionKeys.getSuccess, payload: response })
      return Promise.resolve()
    })
    .catch(error => {
      dispatch({ type: QualifiersModule.actionKeys.getError })

      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'cpe_modules')('qualifiers.get.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))

      return Promise.reject()
    })
}


QualifiersModule.registerActionKey('updateOneSuccess')
QualifiersModule.registerDataAction('updateMany', qualifiers => (dispatch, getState) => {
  if (qualifiers.length === 0) return Promise.resolve()
  const [eventId, exhibitorId] = getServiceData(getState())

  const updateMany = () => {
    let result = Promise.resolve()

    qualifiers.forEach(qualifier => {
      result = result.then(() => servicePutQualifier(eventId, exhibitorId, qualifier)
        .then(response => {
          dispatch({
            type: QualifiersModule.actionKeys.updateOneSuccess,
            payload: response,
          })
        })
      )
    })

    return result
  }

  dispatch({ type: QualifiersModule.actionKeys.updateManyStart, payload: qualifiers })

  return updateMany()
    .then(() => { dispatch({ type: QualifiersModule.actionKeys.updateManySuccess }) })
    .catch(error => {
      dispatch({ type: QualifiersModule.actionKeys.updateManyError })

      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'cpe_modules')('qualifiers.update_many.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))

      return Promise.reject()
    })
})

QualifiersModule.registerReducer(
  QualifiersModule.actionKeys.updateOneSuccess,
  (state, action) => ({
    ...state,
    data: {
      ...state.data,
      [action.payload.id]: action.payload,
    },
  })
)


QualifiersModule.registerActionKey('createOneSuccess')
QualifiersModule.registerDataAction('createMany', qualifiers => (dispatch, getState) => {
  if (qualifiers.length === 0) return Promise.resolve()
  const [eventId, exhibitorId] = getServiceData(getState())

  const createMany = () => {
    let result = Promise.resolve()

    qualifiers.forEach(qualifier => {
      result = result.then(() => servicePostQualifier(eventId, exhibitorId, qualifier)
        .then(response => {
          dispatch({
            type: QualifiersModule.actionKeys.createOneSuccess,
            payload: response,
          })
        })
      )
    })

    return result
  }

  dispatch({ type: QualifiersModule.actionKeys.createManyStart, payload: qualifiers })

  return createMany()
    .then(() => { dispatch({ type: QualifiersModule.actionKeys.createManySuccess }) })
    .catch(error => {
      dispatch({ type: QualifiersModule.actionKeys.createManyError })

      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'cpe_modules')('qualifiers.create_many.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))

      return Promise.reject()
    })
})

QualifiersModule.registerReducer(
  QualifiersModule.actionKeys.createOneSuccess,
  (state, action) => ({
    ...state,
    data: {
      ...state.data,
      [action.payload.id]: action.payload,
    },
  })
)


QualifiersModule.registerActionKey('deleteOneSuccess')
QualifiersModule.registerDataAction('deleteMany', qualifierIds => (dispatch, getState) => {
  if (qualifierIds.length === 0) return Promise.resolve()
  const [eventId, exhibitorId] = getServiceData(getState())

  const deleteMany = () => {
    let result = Promise.resolve()

    qualifierIds.forEach(qualifierId => {
      result = result.then(() => serviceDeleteQualifier(eventId, exhibitorId, qualifierId)
        .then(() => {
          dispatch({
            type: QualifiersModule.actionKeys.deleteOneSuccess,
            payload: qualifierId,
          })
        })
      )
    })

    return result
  }

  dispatch({ type: QualifiersModule.actionKeys.deleteManyStart, payload: qualifierIds })

  return deleteMany()
    .then(() => { dispatch({ type: QualifiersModule.actionKeys.deleteManySuccess }) })
    .catch(error => {
      dispatch({ type: QualifiersModule.actionKeys.deleteManyError })

      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'cpe_modules')('qualifiers.delete_many.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))

      return Promise.reject()
    })
})

QualifiersModule.registerReducer(
  QualifiersModule.actionKeys.deleteOneSuccess,
  (state, action) => {
    const newData = { ...state.data }
    delete newData[action.payload]

    return {
      ...state,
      data: newData,
    }
  }
)


export default QualifiersModule
