let tokenStorage = {}

export function readItem(key) {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    /* No access to localstorage */
    return undefined
  }
}

export function setItem(key, item) {
  try {
    localStorage.setItem(key, item)
  } catch (e) {
    /* No access to localstorage */
  }
}

function removeItem(key) {
  try {
    localStorage.removeItem(key)
  } catch (e) {
    /* No access to localstorage */
  }
}

export function setTokens(refresh, isAdmin = 'admin') {
  tokenStorage[isAdmin + 'App_refresh_token'] = refresh

  if (refresh) {
    setItem(`${isAdmin}App_refresh_token`, refresh)
  }
}

export function saveTokens(accessToken, refreshToken, scope) {
  try {
    window.webkit.messageHandlers['tokens'].postMessage({
      accessToken: accessToken,
      refreshToken: refreshToken,
    })
  } catch (e) {
    // Not an ios webview
  }

  try {
    window.AndroidInterface.receiveTokens(accessToken, refreshToken)
  } catch (e) {
    // Not an android webview
  }

  if (scope === 'admin') {
    setTokens(refreshToken, 'admin')
  } else if (scope === 'user') {
    setTokens(refreshToken, 'attendee')
  }
}

export function saveEvent(eventId, isAdmin = 'admin') {
  setItem(`${isAdmin}App_last_event`, eventId)
}

export function clearTokens(isAdmin = 'admin') {
  tokenStorage[isAdmin + 'App_refresh_token'] = undefined
  removeItem(`${isAdmin}App_refresh_token`)
}

export function retrieveStoredTokens(tokenType = 'admin') {
  const app = `${tokenType.toLowerCase()}App`

  return {
    [`${app}_refresh_token`]: readItem(`${app}_refresh_token`),
    [`${app}_event_id`]: readItem(`${app}_last_event`),
  }
}

export function getRefreshToken(tokenType = 'admin') {
  if (!tokenStorage || !tokenStorage[tokenType + 'App_refresh_token']) {
    tokenStorage = retrieveStoredTokens(tokenType)
  }
  return tokenStorage[tokenType + 'App_refresh_token']
}

export function hasSeenDonwloadAppNotification() {
  return readItem('download_app_notification') === 'true'
}

export function setSeenDownloadAppNotification() {
  setItem('download_app_notification', true)
}


export function getSoundNotificationSetting() {
  const status = readItem('sound_notification')
  if (status === 'true') {
    return true
  }
  return false
}

export function setSoundNotificationSetting(booleanValue) {
  let value = false
  if (booleanValue) {
    value = true
  }

  setItem('sound_notification', value)
}

export function setLanguage(language) {
  setItem('language', language)
}

export function getLanguage() {
  return readItem('language')
}

export function getFloorPlanPreferences() {
  try {
    return JSON.parse(readItem('floor_plan_preferences'))
  } catch (e) {
    return undefined
  }
}

export function updateFloorPlanPreferences(prefs) {
  setItem('floor_plan_preferences', JSON.stringify(prefs))
}

export function setLastSeenCarouselCard(eventId, cardName) {
  setItem(`carousel~${eventId}`, cardName)
}

export function getLastSeenCarouselCard(eventId) {
  return readItem(`carousel~${eventId}`)
}

export function setLoadAsWebview(value) {
  setItem('load_as_webview', !!value)
}

export function getLoadAsWebview() {
  const value = readItem('load_as_webview')
  return value ? JSON.parse(value) : false
}
