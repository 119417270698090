import axios from 'axios'
import { apiErrorHandler, apiCatchError, buildError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { getAxiosConfig } from './helper'


function translateMap(mapData) {
  return {
    ...mapData,
    name: getTranslatedObject(mapData.name),
    image_url: getTranslatedObject(mapData.image_url),
  }
}

export function translateInfra(infraData) {
  const translatedInfra = { ...infraData }
  if (translatedInfra.name) translatedInfra.name = getTranslatedObject(translatedInfra.name)
  if (translatedInfra.description) translatedInfra.description = getTranslatedObject(translatedInfra.description)
  return translatedInfra
}


export function serviceGetInfras(eventId, success, error) {
  return axios.get(`/events/${eventId}/infra`, getAxiosConfig())
    .then((response) => Promise.resolve(response.data.slice().map(translateInfra)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateInfra(eventId, infra, success, error) {
  return axios.put(`/events/${eventId}/infra/${infra.id}`, JSON.stringify(infra), getAxiosConfig())
    .then((response) => success(infra, response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeleteInfra(eventId, infraId, success, error) {
  return axios.delete(`/events/${eventId}/infra/${infraId}`, getAxiosConfig())
    .then((response) => success(infraId, response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceSetRXGain(eventId, infraId, gain, success, error) {
  const requestPayload = {
    command: 'set_rx_gain',
    gain: gain,
    localname: infraId,
  }

  return axios.post(`/events/${eventId}/infra/${infraId}`, requestPayload, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetMap(eventId, mapId, success, error) {
  return axios.get(`/events/${eventId}/maps/${mapId}`, getAxiosConfig())
    .then(response => translateMap(response.data))
    .then(data => success(data, apiErrorHandler(error, 'backend')))
    .catch(response => apiCatchError(response))
}

export function serviceGetVenueMaps(eventId, success, error) {
  return axios.get(`/events/${eventId}/maps`, getAxiosConfig())
    .then((response) => response.data.map(translateMap))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceAddVenueMap(eventId, map, success, error) {
  return axios.post(`/events/${eventId}/maps`, JSON.stringify(map), getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateVenueMap(eventId, map, success, error) {
  const id = map.id
  delete map.id // eslint-disable-line no-param-reassign

  return axios.put(`/events/${eventId}/maps/${id}`, JSON.stringify(map), getAxiosConfig())
    .then((response) => { success(map, response.data); map.id = id }, apiErrorHandler(error, 'backend')) // eslint-disable-line no-param-reassign
    .catch((response) => apiCatchError(response))
}

export function serviceDeleteVenueMap(eventId, mapId, success, error) {
  return axios.delete(`/events/${eventId}/maps/${mapId}`, getAxiosConfig())
    .then((response) => success(mapId, response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}


export function serviceGetLocations(eventId, success, error) {
  return axios.get(`/events/${eventId}/locations`, getAxiosConfig())
    .then((response) => {
      const translatedData = response.data.slice().map(d => ({
        ...d,
        display_name: getTranslatedObject(d.display_name),
      }))

      return Promise.resolve(translatedData)
    })
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateLocation(eventId, location, success, error) {
  return axios.put(`/events/${eventId}/locations/${location.name}`, location, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceAddLocation(eventId, location, success, error) {
  return serviceUpdateLocation(eventId, location, success, error)
}

export function serviceDeleteLocation(eventId, locationName, success, error) {
  return axios.delete(`/events/${eventId}/locations/${locationName}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}


export function translateTouchpoint(data) {
  const touchpoint = { ...data }
  touchpoint.name = getTranslatedObject(touchpoint.name)
  touchpoint.description = getTranslatedObject(touchpoint.description)
  touchpoint.call_to_actions = touchpoint.call_to_actions.map(cta => ({
    ...cta,
    label: getTranslatedObject(cta.label),
  }))
  return touchpoint
}

export function serviceGetTouchpoints(eventId) {
  return axios.get(`/events/${eventId}/touchpoints`, getAxiosConfig())
    .then(response => response.data.map(translateTouchpoint))
    .catch((error) => Promise.reject(buildError(error)))
}

export function serviceAddTouchpoint(eventId, touchpoint) {
  return axios.post(`/events/${eventId}/touchpoints`, touchpoint, getAxiosConfig())
    .then(response => translateTouchpoint(response.data))
    .catch((error) => Promise.reject(buildError(error)))
}

export function serviceGetTouchpoint(eventId, touchpointId) {
  return axios.get(`/events/${eventId}/touchpoints/${touchpointId}`, getAxiosConfig())
    .then(response => translateTouchpoint(response.data))
    .catch((error) => Promise.reject(buildError(error)))
}

export function serviceUpdateTouchpoint(eventId, touchpoint) {
  return axios.put(`/events/${eventId}/touchpoints/${touchpoint.id}`, touchpoint, getAxiosConfig())
    .then(response => translateTouchpoint(response.data))
    .catch((error) => Promise.reject(buildError(error)))
}

export function serviceDeleteTouchpoint(eventId, touchpointId) {
  return axios.delete(`/events/${eventId}/touchpoints/${touchpointId}`, getAxiosConfig())
    .then(response => response.data)
    .catch((error) => Promise.reject(buildError(error)))
}

export function serviceDeployTouchpoint(eventId, touchpointId, infra) {
  return axios.put(`/events/${eventId}/touchpoints/${touchpointId}/deploy`, infra, getAxiosConfig())
    .then(response => response.data)
    .catch((error) => Promise.reject(buildError(error)))
}

export function serviceUndeployTouchpoint(eventId, touchpointId, infraId) {
  return axios.delete(`/events/${eventId}/touchpoints/${touchpointId}/deploy?id=${infraId}`, getAxiosConfig())
    .then(response => response.data)
    .catch((error) => Promise.reject(buildError(error)))
}
