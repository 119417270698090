import React, { Component } from 'react'
import { connect } from 'react-redux'


export default function WithCurrentEvent() {
  return (WrappedComponent) => {
    class PropsProxy extends Component {
      render() {
        return <WrappedComponent {...this.props} />
      }
    }

    return connect(state => ({ currentEvent: state.attendee.attEvents.currEvent }))(PropsProxy)
  }
}
