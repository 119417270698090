import React, { Component } from 'react'
import { connect } from 'react-redux'
import { apiUrl } from 'config'
import i18n from 'i18next'
import { getProperty } from 'common/utils/componentHelper'
import * as GAEvents from 'attendee/utils/GAEvents'

import ExternalLink from 'common/components/ExternalLink'


@connect(state => ({ authAccessToken: state.auth.user.accessToken }))
export default class ExportLink extends Component {
  static defaultProps = {
    attendeeId: 'me',
    format: 'xlsx',
    style: {},
    limitToEvent: false,
  }

  onClick = () => {
    const { currentEvent, limitToEvent } = this.props
    const eventTitle = limitToEvent ? `"${currentEvent.title}"` : 'All events'
    GAEvents.exportAllContacts(eventTitle)
  }

  render() {
    const { currentEvent, attendeeId, accessToken, authAccessToken, format, style, limitToEvent, className, id } = this.props
    const tokenToUse = accessToken || authAccessToken
    const eventId = this.props.eventId || getProperty(currentEvent, 'id') || ''

    let apiLink = `${apiUrl}/events/${eventId}/attendees/${attendeeId}/contacts.${format}?access_token=${encodeURIComponent(tokenToUse)}&_lang=${i18n.language}`
    if (limitToEvent) {
      apiLink += '&event=true'
    }

    return (
      <ExternalLink className={`export-contacts-btn ${className}`} id={id} href={apiLink} style={style} onClick={this.onClick}>
        {this.props.children}
      </ExternalLink>
    )
  }
}
