import { serviceGetSchedules, serviceGetSchedule, servicePostSchedule, serviceDuplicateSchedule, servicePutSchedule, serviceDeleteSchedule, serviceSendPreview, serviceResendSchedule } from 'admin/services/emailing'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'
import { listToMapping } from 'common/utils/componentHelper'
import { createSelector } from 'reselect'


const selectSchedules = state => state.admin.schedules.schedules

export const actionSchedulesSelector = createSelector(selectSchedules, schedules => schedules.filter(schedule => schedule.trigger.type !== 'date'))
export const actionSchedulesByActionTypeSelector = createSelector(actionSchedulesSelector, schedules => listToMapping(schedules, 'trigger.event_type'))


// /////////////////////////////////////////////////////////////////////////////
// GET Schedules
// /////////////////////////////////////////////////////////////////////////////

function getSchedulesStart() {
  return {
    type: 'GET_SCHEDULES_START',
  }
}

function getSchedulesSuccess(schedules) {
  return {
    type: 'GET_SCHEDULES_SUCCESS',
    payload: {
      schedules: schedules,
    },
  }
}

function getSchedulesError() {
  return {
    type: 'GET_SCHEDULES_ERROR',
  }
}

export function getSchedules() {
  return (dispatch, getState) => {
    dispatch(getSchedulesStart())
    return serviceGetSchedules(
      getState().admin.events.currentEvent.id,
      (response) => dispatch(getSchedulesSuccess(response)),
      (error) => {
        dispatch(getSchedulesError())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.get_schedules.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}

export function getSchedule(scheduleId) {
  return (dispatch, getState) => {
    dispatch({ type: 'GET_SCHEDULE_START' })
    return serviceGetSchedule(
      getState().admin.events.currentEvent.id,
      scheduleId,
      (response) => dispatch({
        type: 'GET_SCHEDULE_SUCCESS',
        payload: { schedule: response },
      }),
      (error) => {
        dispatch({ type: 'GET_SCHEDULE_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.get_schedules.error'),
          message: error.description,
          level: 'error',
          persistOnPageChange: true,
        }))
      }
    )
  }
}


// /////////////////////////////////////////////////////////////////////////////
// SELECT Schedule
// /////////////////////////////////////////////////////////////////////////////

export function selectSchedule(schedule) {
  return (dispatch) => Promise.resolve(dispatch({ type: 'SELECT_SCHEDULE', payload: { schedule: schedule } }))
}


// /////////////////////////////////////////////////////////////////////////////
// POST Schedule
// /////////////////////////////////////////////////////////////////////////////

function postScheduleStart() {
  return {
    type: 'POST_SCHEDULE_START',
  }
}

function postScheduleSuccess(schedule) {
  return {
    type: 'POST_SCHEDULE_SUCCESS',
    payload: {
      schedule: schedule,
    },
  }
}

function postScheduleError() {
  return {
    type: 'POST_SCHEDULE_ERROR',
  }
}

export function postSchedule(scheduleToCreate, showSuccess = true) {
  return (dispatch, getState) => {
    dispatch(postScheduleStart())
    return servicePostSchedule(
      getState().admin.events.currentEvent.id,
      scheduleToCreate,
      (response) => {
        dispatch(postScheduleSuccess(response))
        if (showSuccess) {
          dispatch(showToast({
            title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.save_schedule.success.title'),
            message: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.save_schedule.success.message'),
            level: 'success',
            permanent: false,
            persistOnPageChange: true,
          }))
        }
      },
      (error) => {
        dispatch(postScheduleError())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.save_schedule.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}

export function duplicateSchedule(schedule) {
  return (dispatch, getState) => {
    const scheduleId = (typeof schedule === 'string') ? schedule : schedule.id

    return serviceDuplicateSchedule(
      getState().admin.events.currentEvent.id,
      scheduleId,
      (response) => Promise.resolve(response),
      (error) => {
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.duplicate.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))

        return Promise.reject()
      }
    )
  }
}

export function sendPreview(schedule) {
  return (dispatch, getState) => {
    dispatch({ type: 'POST_PREVIEW_SCHEDULE_START' })
    return serviceSendPreview(
      getState().admin.events.currentEvent.id,
      schedule,
      () => {
        dispatch({ type: 'POST_PREVIEW_SCHEDULE_SUCCESS' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.post_preview_schedule.success.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.post_preview_schedule.success.message'),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch({ type: 'POST_PREVIEW_SCHEDULE_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.post_preview_schedule.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}

export function resendSchedule(schedule) {
  return (dispatch, getState) => {
    dispatch({ type: 'RESEND_SCHEDULE_START' })
    return serviceResendSchedule(
      getState().admin.events.currentEvent.id,
      schedule,
      () => {
        dispatch({ type: 'RESEND_SCHEDULE_SUCCESS' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.resend_schedule.success.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.resend_schedule.success.message'),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch({ type: 'RESEND_SCHEDULE_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.resend_schedule.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}


// /////////////////////////////////////////////////////////////////////////////
// PUT Schedule
// /////////////////////////////////////////////////////////////////////////////

function putScheduleStart() {
  return { type: 'PUT_SCHEDULE_START' }
}

function putScheduleSuccess(schedule) {
  return {
    type: 'PUT_SCHEDULE_SUCCESS',
    payload: {
      schedule: schedule,
    },
  }
}

function putScheduleError() {
  return { type: 'PUT_SCHEDULE_ERROR' }
}

export function putSchedule(newSchedule, showSuccess = true) {
  return (dispatch, getState) => {
    dispatch(putScheduleStart())
    return servicePutSchedule(
      getState().admin.events.currentEvent.id,
      newSchedule,
      (response) => {
        dispatch(putScheduleSuccess(response))
        if (showSuccess) {
          dispatch(showToast({
            title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.save_schedule.success.title'),
            message: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.save_schedule.success.message'),
            level: 'success',
            permanent: false,
            persistOnPageChange: true,
          }))
        }
      },
      (error) => {
        dispatch(putScheduleError())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.save_schedule.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}


// /////////////////////////////////////////////////////////////////////////////
// DELETE Schedule
// /////////////////////////////////////////////////////////////////////////////

function deleteScheduleStart() {
  return { type: 'DELETE_SCHEDULE_START' }
}

function deleteScheduleSuccess() {
  return { type: 'DELETE_SCHEDULE_SUCCESS' }
}

function deleteScheduleError() {
  return { type: 'DELETE_SCHEDULE_ERROR' }
}

export function deleteSchedule(scheduleId) {
  return (dispatch, getState) => {
    dispatch((deleteScheduleStart()))
    return serviceDeleteSchedule(
      getState().admin.events.currentEvent.id,
      scheduleId,
      () => {
        dispatch(deleteScheduleSuccess())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.delete_schedule.success.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.delete_schedule.success.message'),
          level: 'success',
          permanent: false,
          persistOnPageChange: true,
        }))
      },
      (error) => {
        dispatch(deleteScheduleError())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('schedules.delete_schedule.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}


const initialState = {
  isLoading: false,
  isLoaded: false,
  isError: false,

  schedules: [],
  currentSchedule: {},
}

export default function gamification(state = initialState, action) {
  switch (action.type) {
    case 'INSTANTIATED_TEMPLATE':
      return { ...state, schedules: [...state.schedules, action.payload.schedule] }

    // /////////////////////////////////////////////////////////////////////////////
    // GET Schedules
    // /////////////////////////////////////////////////////////////////////////////

    case 'GET_SCHEDULES_START':
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        lastOperationStatus: 'GET',
      }

    case 'GET_SCHEDULES_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        schedules: action.payload.schedules,
      }

    case 'GET_SCHEDULES_ERROR':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: true,
      }

    case 'GET_SCHEDULE_START':
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        lastOperationStatus: 'GET',
      }

    case 'GET_SCHEDULE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        currentSchedule: action.payload.schedule,
      }

    case 'GET_SCHEDULE_ERROR':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: true,
      }

    // /////////////////////////////////////////////////////////////////////////////
    // SELECT Schedule
    // /////////////////////////////////////////////////////////////////////////////

    case 'SELECT_SCHEDULE':
      return {
        ...state,
        lastOperationStatus: 'SELECT',
        currentSchedule: action.payload.schedule,
      }


    // /////////////////////////////////////////////////////////////////////////////
    // POST Schedule
    // /////////////////////////////////////////////////////////////////////////////

    case 'POST_SCHEDULE_START':
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        lastOperationStatus: 'POST',
      }

    case 'POST_PREVIEW_SCHEDULE_START':
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        lastOperationStatus: 'POST',
      }

    case 'POST_SCHEDULE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        currentSchedule: action.payload.schedule,
      }

    case 'POST_PREVIEW_SCHEDULE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
      }

    case 'POST_SCHEDULE_ERROR':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: true,
      }

    case 'POST_PREVIEW_SCHEDULE_ERROR':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: true,
      }


    // /////////////////////////////////////////////////////////////////////////////
    // PUT Schedule
    // /////////////////////////////////////////////////////////////////////////////

    case 'PUT_SCHEDULE_START':
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        lastOperationStatus: 'PUT',
      }

    case 'PUT_SCHEDULE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        currentSchedule: action.payload.schedule,
      }

    case 'PUT_SCHEDULE_ERROR':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: true,
      }

    // /////////////////////////////////////////////////////////////////////////////
    // DELETE Schedule
    // /////////////////////////////////////////////////////////////////////////////

    case 'DELETE_SCHEDULE_START':
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        lastOperationStatus: 'DELETE',
      }

    case 'DELETE_SCHEDULE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
      }

    case 'DELETE_SCHEDULE_ERROR':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: true,
      }

    default:
      return state
  }
}
