import React, { Component } from 'react'

import Button from './index'


const SOCIAL_NETWORKS = {
  linkedin: { backgroundColor: '#0077B5', color: '#FFF', icon: 'fa-linkedin' },
  facebook: { backgroundColor: '#3B5998', color: '#FFF', icon: 'fa-facebook' },
  twitter: { backgroundColor: '#00A0D1', color: '#FFF', icon: 'fa-twitter' },
  instagram: { backgroundColor: '#CD486B', color: '#FFF', icon: 'fa-instagram' },
  website: { backgroundColor: '#000', color: '#FFF' },
}


export default class SocialLink extends Component {
  constructor(props) {
    super(props)

    this.socialBranding = (() => {
      const { socialNetwork } = this.props
      const type = socialNetwork ? socialNetwork.type : undefined
      return SOCIAL_NETWORKS[type]
    })()
  }

  _getClassName() {
    let className = 'SocialLink'
    className += this.props.className ? ` ${this.props.className}` : ''
    return className
  }

  _getIcon() {
    const icon = this.socialBranding ? this.socialBranding.icon : undefined
    return icon ? <i className={`fa ${icon} social-icon`} /> : null
  }

  _getStyle() {
    const { backgroundColor, color } = this.socialBranding
    return { backgroundColor, color }
  }

  render() {
    const { children, showIcon, socialNetwork } = this.props
    const dataHasIcon = !!(showIcon && this._getIcon())
    const link = Button.ExternalLink(socialNetwork.url, '_blank')

    return (
      <Button overwriteClassName className={this._getClassName()} link={link} style={{ ...this._getStyle() }} extraProps={{ 'data-has-icon': dataHasIcon, 'data-social-network': socialNetwork.type }}>
        {!showIcon ? null : this._getIcon()}
        {children}
      </Button>
    )
  }
}

SocialLink.defaultProps = {
  className: '',
}
