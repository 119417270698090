import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceGetBadgePrintingConfig(eventId, success, error) {
  return axios.get(`/events/${eventId}/conf/branding`, getAxiosConfig())
    .then(response => success(response.data.badge), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceUpdateBadgePrintingConfig(eventId, data, success, error) {
  return axios.put(`/events/${eventId}/conf/branding`, { badge: data }, getAxiosConfig())
    .then(response => success(response.data.badge), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}

export function serviceGetBadgePrintingPreview(eventId, config, success, error) {
  return axios.post(`/events/${eventId}/conf/badge`, { badge: config } || {}, getAxiosConfig())
    .then(response => success(response.data), apiErrorHandler(error, 'backend'))
    .catch(response => apiCatchError(response))
}
