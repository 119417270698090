import Moment from 'moment'


function validateEvent(event) {
  if (!event) throw Error('You need to pass an event')
}

export function isEventOver(event) {
  validateEvent(event)
  return Moment().unix() > event.end_date
}

export function hasEventStarted(event) {
  validateEvent(event)
  return Moment().unix() >= event.start_date
}

export function getEventDays(event) {
  if (!event) throw Error('You need to pass an event')

  const dates = []

  const currentDayMoment = Moment.unix(event.start_date).tz(event.timezone)
  const lastDayMoment = Moment.unix(event.end_date).tz(event.timezone)

  do {
    const dayMoment = currentDayMoment.clone()
    dates.push({ start: dayMoment.clone().startOf('day').unix(), end: dayMoment.endOf('day').unix(), moment: dayMoment })
  } while (currentDayMoment.add(1, 'days').isSameOrBefore(lastDayMoment, 'day'))

  return dates
}
