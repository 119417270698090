import { serviceGetBusyCalendar } from 'attendee/services/meetingRequests'
import generateModule from 'common/utils/generateModule'
import { eventDaysSelector, eventIdSelector, eventTimezoneSelector } from './events'
import { handleErrorMessage } from './index'
import { createSelector } from 'reselect'
import sort from 'common/utils/sortHelper'
import Moment from 'moment'


const selectState = (state) => state.attendee.busyCalendars
const selectBusyCalendars = (state) => selectState(state).data
const selectCommonDates = state => selectState(state).commonDates

export const busyCalendarsSelector = createSelector(selectBusyCalendars, busyCalendars => busyCalendars)

export const commonDatesSelector = createSelector(
  selectCommonDates,
  eventTimezoneSelector,
  (commonDates, timezone) => {
    const dates = []
    let lastProcessedMoment

    sort(commonDates, ['start']).forEach(date => {
      const moment = Moment.unix(date.start).tz(timezone)
      const dateEntry = { ...date, moment: moment.clone() }

      if (!lastProcessedMoment || lastProcessedMoment.isBefore(moment, 'day')) {
        dates.push(dateEntry)
      }

      lastProcessedMoment = moment.clone()
    })

    return dates
  }
)

export const commonDatesInFutureSelector = createSelector(
  commonDatesSelector,
  commonDates => {
    const currentTime = Date.now() / 1000
    return commonDates.filter(date => date.end > currentTime)
  }
)


function _findCommonDates(datesA, datesB, eventDates) {
  if (datesA.length === 0 && datesB.length === 0) return eventDates
  if (datesA.length === 0) return datesB
  if (datesB.length === 0) return datesA

  const intersection = [...new Set(datesA.filter(dateA => datesB.some(
    dateB => dateA.start === dateB.start && dateA.end === dateB.end
  )))]

  return sort(intersection, 'start')
}


const module = generateModule({
  itemName: 'busyCalendar',
  itemNameDisplay: 'busyCalendar',
  autoRefresh: 60,
  services: {
    get: serviceGetBusyCalendar,
  },
  app: 'attendee',
  dataIsNotArray: true,
})

module.initialState.commonDates = []


module.actions.getBusyCalendar = (accountId1, accountId2) =>
  (dispatch, getState) => {
    const accountIds = [accountId1, accountId2].filter(a => !!a)
    dispatch({ type: 'GET_BUSY_CALENDAR_START', payload: { accountIds } })

    const getPromises = []
    accountIds.forEach(accountId => {
      getPromises.push(serviceGetBusyCalendar(
        eventIdSelector(getState()),
        accountId,
        (response) => Promise.resolve({ calendar: response.calendar, id: accountId, dates: response.restricted_to_dates }),
        (error) => Promise.reject(error.description),
      ))
    })

    return Promise.all(getPromises)
      .then(results => {
        let commonDates = []

        if (results.length === 2) {
          commonDates = _findCommonDates(results[0].dates, results[1].dates, eventDaysSelector(getState()))
        }

        const calendars = {}
        results.forEach(result => {
          calendars[result.id] = result.calendar
        })

        dispatch({ type: 'GET_BUSY_CALENDAR_SUCCESS', payload: { calendars, commonDates } })
      })
      .catch(errorDescriptions => {
        let errorString = ''
        errorDescriptions.forEach(e => { errorString += `${e}\n` })
        dispatch({ type: 'GET_BUSY_CALENDAR_ERROR' })

        handleErrorMessage(dispatch, getState, { description: errorString })
      })
  }

module.reducers['GET_BUSY_CALENDAR_START'] = (state) => ({
  ...state,
  isError: false,
  isFetching: true,
})

module.reducers['GET_BUSY_CALENDAR_SUCCESS'] = (state, action) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  data: action.payload.calendars,
  commonDates: action.payload.commonDates,
})

module.reducers['GET_BUSY_CALENDAR_ERROR'] = (state) => ({
  ...state,
  isFetching: false,
  isError: true,
})


module.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isLoaded: false,
  isError: false,
  didInvalidate: true,
  data: {},
  commonDates: [],
})

export default module
