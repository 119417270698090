import { serviceGetSpeakers } from 'attendee/services/speakers'
import generateModule from 'common/utils/generateModule'
import { createSelector } from 'reselect'
import { filtersSelector } from 'attendee/modules/FilterSettings/speakers'
import search, { buildFieldList } from 'common/utils/search'
import { listToMapping } from 'common/utils/componentHelper'


const selectState = (state) => state.attendee.speakers
const selectSpeakers = (state) => selectState(state).data


export const speakersSelector = createSelector(
  selectSpeakers,
  filtersSelector,
  (speakers, filters) => {
    let filteredSpeakers = [...speakers];

    (function filterBySearch() {
      if (!filters.search) return
      filteredSpeakers = filteredSpeakers.filter(speaker => search(
        filters.search,
        (() => {
          const fields = [...buildFieldList(speaker, ['first_name', 'last_name', 'bio'])]
          speaker.sessions.forEach(session => { fields.push(...buildFieldList(session, ['title', 'calendar_name', 'role'])) })
          speaker.experience.forEach(experience => { fields.push(...buildFieldList(experience, ['company', 'position'])) })
          return fields
        })()
      ))
    }());

    (function sortAlphabetically() {
      filteredSpeakers = filteredSpeakers.sort((a, b) => {
        let nameA = a.last_name || a.first_name
        let nameB = b.last_name || b.first_name

        if (nameA === nameB) {
          nameA = a.first_name
          nameB = b.first_name
        }

        return nameA.localeCompare(nameB)
      })
    }());

    return filteredSpeakers
  }
)

export const speakersByIdSelector = createSelector(selectSpeakers, (speakers) => listToMapping(speakers, 'id'))

const speakers = generateModule({
  itemName: 'speaker',
  itemNameDisplay: 'speaker',
  autoRefresh: 60,
  services: {
    get: serviceGetSpeakers,
  },
  app: 'attendee',
})

speakers.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isLoaded: false,
  isError: false,
  didInvalidate: true,
  data: [],
})

export default speakers
