import axios from 'axios'
import config from 'config'
import i18n from 'i18next'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { renewTokens, loginError } from 'common/modules/auth'
import { deleteAllToasts } from 'common/modules/'
import { getAccessToken, getRefreshToken } from './helper'
import { socialNetworksBranding } from 'common/utils/social'
import { browserHistoryPush } from 'common/components/Link'
import { store } from '../../index'


function getAxiosConfig() {
  return {
    baseURL: config.apiUrl,
    headers: {
      'Accept-Language': i18n.language,
      'Content-Type': 'application/json; charset=utf-8',
      'Cache-Control': 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      Pragma: 'no-cache',
    },
  }
}


export function serviceInvalidateTokens() {
  const axiosConfig = getAxiosConfig()
  axiosConfig.headers.Authorization = `Bearer ${getAccessToken()}`

  return axios.post('/accounts/me/invalidate_tokens', {}, axiosConfig)
    .catch(response => apiCatchError(response))
}


function login(data, success, error) {
  const args = {
    ...data,
    client_id: data.client_id || config.clientId,
  }

  return axios.post('/login', JSON.stringify(args), getAxiosConfig())
    .then((response) => success(response.data, response.status), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceLoginEmail(email, domain, success, error) {
  let url = '/login?email=' + encodeURIComponent(email)
  url += domain ? `&domain=${domain}` : ''

  return axios.get(url, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceLogin(email, password, domain, scope = '', app, success, error) {
  const loginData = {
    grant_type: 'password',
    client_id: app,
    username: `email:${email}`,
    password: password,
    scope: scope,
    domain: domain,
  }

  return login(loginData, success, error)
}

export function serviceLoginMultiFactorAuthentification(methodToken, methodId, mfaToken, domain, app, scope, success, error) {
  const loginData = {
    grant_type: 'one_time_password',
    client_id: app,
    mfa_token: mfaToken,
    method_id: methodId,
    method_token: methodToken,
    domain: domain,
    scope,
  }

  return login(loginData, success, error)
}

export function servicePreviewKlikProfile(token, domain, app, success, error) {
  const loginData = {
    grant_type: 'refresh_token',
    refresh_token: token,
    client_id: app,
    scope: 'user',
    domain: domain,
  }

  return login(loginData, success, error)
}

export function serviceRefreshLogin(refresh, domain, app, success, error) {
  const loginData = {
    grant_type: 'refresh_token',
    refresh_token: refresh,
    client_id: app,
  }

  if (domain) {
    loginData.domain = domain
  }

  return login(loginData, success, error)
}

export function serviceSocialLogin(code, codeType, redirectUri, domain, app, success, error) {
  const loginData = {
    grant_type: 'authorize_code',
    client_id: app,
    code: code,
    code_type: codeType,
    redirectUri: redirectUri,
    domain: domain,
    scope: 'user', // force social networks to login to the attendee app
  }

  return login(loginData, success, error)
}

export function serviceSendLoginCode(email, domain, type, success, error) {
  return axios.post(`/accounts/email:${encodeURIComponent(email)}/login_code?domain=${encodeURIComponent(domain)}`, { transport: type }, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceLoginWithCode(email, code, domain, scope, app, success, error) {
  const loginData = {
    grant_type: 'login_code',
    client_id: app,
    code: code,
    username: `email:${email}`,
    domain: domain,
    scope: scope,
  }

  return login(loginData, success, error)
}

export function serviceGetSocialNetworkAuthorizationUrls(success, error) {
  const redirectUri = (() => {
    let uri = window.location.origin || window.location.protocol + '//' + window.location.host

    // Workaround for IE - to chop off trailing / from URI
    if (uri.charAt(uri.length - 1) === '/') {
      uri = uri.slice(0, -1)
    }

    return uri
  })()

  const withBrandingInfo = (response) => response.map((socialNetwork) => ({ ...socialNetwork, ...socialNetworksBranding[socialNetwork.name] }))

  return axios.get(`/social_networks?redirect_uri=${encodeURIComponent(redirectUri)}`, getAxiosConfig())
    .then((response) => success(withBrandingInfo(response.data)), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}


// Interceptor for refresh token renewal
axios.interceptors.response.use(response => response, err => {
  if (err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest && err.config.url.indexOf('/login') < 0) { // eslint-disable-line no-underscore-dangle
    const loginData = {
      grant_type: 'refresh_token',
      refresh_token: getRefreshToken(),
      client_id: 'klik_web_refreshtoken',
    }

    return login(
      loginData,
      response => {
        store.dispatch(renewTokens(response.access_token, response.refresh_token, response.roles))
        err.config.__isRetryRequest = true // eslint-disable-line no-param-reassign
        err.config.headers.Authorization = `Bearer ${response.access_token}` // eslint-disable-line no-param-reassign
        return axios.request(err.config)
      },
      () => {
        if (window.location.pathname !== '/login') {
          browserHistoryPush('/login')
          store.dispatch(loginError(err, { loginErrorMessage: i18n.getFixedT(i18n.language, 'login')('invalid_token_error_message') }))
        }
        store.dispatch(deleteAllToasts())
      }
    )
  }

  return Promise.reject(err)
})
