import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetNetworkDailyScores } from 'admin/services/bluetrace'

import { eventTimezoneSelector } from 'admin/modules/events'
import FiltersModule from './filters'


const NetworkDailyScoresModule = generateDataModule({
  initialData: {
    previousWeek: [],
    currentWeek: [],
  },
  moduleKey: 'cpo/bluetrace/networkDailyScores',
  reducerKey: 'admin.bluetrace.employeeNetwork.dailyScores',
  refreshTime: 10000,
  services: {
    get: { service: serviceGetNetworkDailyScores },
  },
})


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////

NetworkDailyScoresModule.registerDataAction('get', () => (dispatch, getState) => {
  const state = getState()
  const employeeId = FiltersModule.selectors.employeeId(state)
  if (!employeeId) return Promise.resolve()

  const timezone = eventTimezoneSelector(state)

  dispatch({ type: NetworkDailyScoresModule.actionKeys.getStart })

  return NetworkDailyScoresModule._getService('get')(employeeId, timezone)
    .then(response => dispatch({ type: NetworkDailyScoresModule.actionKeys.getSuccess, payload: response }))
    .catch(() => {
      dispatch({ type: NetworkDailyScoresModule.actionKeys.getError })
      return Promise.reject()
    })
})


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////


export default NetworkDailyScoresModule
