import { BaseModule } from 'redux-data-module'

const NavigatorModule = new BaseModule({
  actionKeyPrefix: 'navigator',
  initialState: {
    isBlockedByRouter: false,
    isBlockedManually: false,
    isNavigationConfirmed: false,
  },
  moduleKey: 'navigator',
  reducerKey: 'navigator',
})


NavigatorModule.registerSelector(
  'isBlockedByRouter',
  (state, moduleState) => moduleState.isBlockedByRouter,
  (value) => value
)

NavigatorModule.registerSelector(
  'isBlockedManually',
  (state, moduleState) => moduleState.isBlockedManually,
  (value) => value
)

NavigatorModule.registerSelector(
  'isNavigationConfirmed',
  (state, moduleState) => moduleState.isNavigationConfirmed,
  (value) => value
)


NavigatorModule.registerActionKey('setBlockedByRouter')

NavigatorModule.actions.setBlockedByRouter = (value) =>
  (dispatch) => {
    if (value) {
      dispatch(NavigatorModule.actions.setNavigationConfirmed(false))
    }

    dispatch({
      type: NavigatorModule.actionKeys.setBlockedByRouter,
      payload: value,
    })
  }

NavigatorModule.reducers[NavigatorModule.actionKeys.setBlockedByRouter] = (state, action) => ({
  ...state,
  isBlockedByRouter: action.payload,
})


NavigatorModule.registerActionKey('setBlockedManually')

NavigatorModule.actions.setBlockedManually = (value) =>
  (dispatch) => {
    if (value) {
      dispatch(NavigatorModule.actions.setNavigationConfirmed(false))
    }

    dispatch({
      type: NavigatorModule.actionKeys.setBlockedManually,
      payload: value,
    })
  }

NavigatorModule.reducers[NavigatorModule.actionKeys.setBlockedManually] = (state, action) => ({
  ...state,
  isBlockedManually: action.payload,
})


NavigatorModule.registerActionKey('setNavigationConfirmed')

NavigatorModule.actions.setNavigationConfirmed = (value) =>
  (dispatch, getState) => {
    if (value) {
      if (NavigatorModule.selectors.isBlockedManually(getState())) {
        dispatch(NavigatorModule.actions.setBlockedManually(false))
      } else if (NavigatorModule.selectors.isBlockedByRouter(getState())) {
        dispatch(NavigatorModule.actions.setBlockedByRouter(false))
      }
    }

    dispatch({
      type: NavigatorModule.actionKeys.setNavigationConfirmed,
      payload: value,
    })
  }

NavigatorModule.reducers[NavigatorModule.actionKeys.setNavigationConfirmed] = (state, action) => ({
  ...state,
  isNavigationConfirmed: action.payload,
})


export default NavigatorModule