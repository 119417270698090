import generateModule from 'common/utils/generateModule'
import { createSelector } from 'reselect'

const selectState = (state) => state.attendee.toasters
const selectToasts = (state) => selectState(state).data

export const toastsSelector = createSelector(selectToasts, (toasts) => toasts)


const toastersModule = generateModule({
  itemName: 'toasters',
  app: 'attendee',
  services: {},
})


toastersModule.actions.addToast = (errorCode) =>
  (dispatch) => dispatch({ type: 'ATTENDEE_ADD_TOAST', payload: { errorCode: errorCode } })

toastersModule.reducers['ATTENDEE_ADD_TOAST'] = (state, action) => ({
  ...state,
  data: [...state.data, action.payload],
})


toastersModule.actions.dismissToast = (index = 0) =>
  (dispatch) => dispatch({ type: 'ATTENDEE_DISMISS_TOAST', payload: { index: index } })

toastersModule.reducers['ATTENDEE_DISMISS_TOAST'] = (state, action) => ({
  ...state,
  data: state.data.filter((toast, index) => index !== action.payload.index),
})


toastersModule.actions.dismissToasts = () =>
  (dispatch) => dispatch({ type: 'ATTENDEE_DISMISS_TOASTS' })

toastersModule.reducers['ATTENDEE_DISMISS_TOASTS'] = (state) => ({
  ...state,
  data: [],
})


toastersModule.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isError: false,
  isLoaded: false,
  didInvalidate: true,
  data: [],
})


export default toastersModule
