import { serviceGetMeetingRecommendations, serviceIgnoreMeetingRecommendation } from 'attendee/services/meetingRequests'
import generateModule from 'common/utils/generateModule'


const meetingRecommendations = generateModule({
  itemName: 'meetingRecommendation',
  itemNameDisplay: 'meetingRecommendation',
  getModuleState: (getState) => getState().attendee.meetingRecommendations,
  autoRefresh: 0,
  app: 'attendee',
  services: {
    get: serviceGetMeetingRecommendations,
  },
})


meetingRecommendations.actions.get = (query, lastProfileId, manipulation = 'update') =>
  (dispatch, getState) => {
    dispatch(meetingRecommendations.actions.getStart())
    return serviceGetMeetingRecommendations(
      getState().attendee.attEvents.currEvent.id,
      query,
      lastProfileId,
      response => {
        dispatch({ type: 'GET_MEETINGRECOMMENDATIONS_SUCCESS', payload: { data: response, shouldAppend: manipulation === 'insert' } })
        return Promise.resolve(response.length === 0)
      },
      error => dispatch(meetingRecommendations.actions.getError(error))
    )
  }

meetingRecommendations.reducers['GET_MEETINGRECOMMENDATIONS_SUCCESS'] = (state, action) => ({
  ...state,
  data: action.payload.shouldAppend ? [...state.data, ...action.payload.data] : action.payload.data,
  isFetching: false,
  isError: false,
  isLoaded: true,
  didInvalidate: false,
})


meetingRecommendations.actions.ignoreMeetingRecommendation = (targetId) =>
  (dispatch, getState) => {
    dispatch({ type: 'IGNORE_MEETING_RECOMMENDATION', payload: { targetId } })
    return serviceIgnoreMeetingRecommendation(getState().attendee.attEvents.currEvent.id, targetId, () => {}, () => {})
  }

meetingRecommendations.reducers['IGNORE_MEETING_RECOMMENDATION'] = (state, action) => ({
  ...state,
  data: state.data.filter(d => d.account_id !== action.payload.targetId),
})


meetingRecommendations.actions.clearMeetingRecommendations = () =>
  dispatch => {
    dispatch({ type: 'CLEAR_MEETING_RECOMMENDATIONS' })
    return Promise.resolve()
  }


meetingRecommendations.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isError: false,
  isLoaded: false,
  didInvalidate: true,
  data: [],
})

meetingRecommendations.reducers['CLEAR_MEETING_RECOMMENDATIONS'] = (state) => ({
  ...state,
  isFetching: false,
  isError: false,
  isLoaded: false,
  didInvalidate: true,
  data: [],
})


meetingRecommendations.actions.getMeetingRecommendations = meetingRecommendations.actions.get
meetingRecommendations.actions.getMeetingRecommendationsIfNeeded = meetingRecommendations.actions.getIfNeeded
meetingRecommendations.actions.clearMeetingRecommendations = meetingRecommendations.actions.clearMeetingRecommendations
export default meetingRecommendations
