import axios from 'axios'
import { buildError } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceGetVideoCallInvitations(eventId) {
  const axiosConfig = getAxiosConfig()

  const getReceivedInvites = () => axios.get(`/events/${eventId}/attendees/me/invitations/video_call`, axiosConfig)
    .then(response => response.data)
  const getSentInvites = () => axios.get(`/events/${eventId}/attendees/me/invitations/video_call/sent`, axiosConfig)
    .then(response => response.data)

  return Promise.all([getReceivedInvites(), getSentInvites()])
    .then(([receivedInvites, sentInvites]) => [...receivedInvites, ...sentInvites])
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceCreateVideoCallInvitation(eventId, accountId, initiatedFrom) {
  const payload = {
    initiated_from: initiatedFrom,
  }
  return axios.post(`/events/${eventId}/attendees/${accountId}/invitations/video_call`, payload, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceAcceptVideoCallInvitation(eventId, invitationId) {
  return axios.post(`/events/${eventId}/attendees/me/invitations/video_call/${invitationId}/accept`, {}, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceRejectVideoCallInvitation(eventId, invitationId) {
  return axios.post(`/events/${eventId}/attendees/me/invitations/video_call/${invitationId}/reject`, {}, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}
