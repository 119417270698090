import { serviceGetInfras, serviceUpdateInfra, serviceDeleteInfra, serviceSetRXGain } from 'admin/services/maps'
import generateModule from 'common/utils/generateModule'
import { listToMapping } from 'common/utils/componentHelper'
import { createSelector } from 'reselect'
import { currentEventIdSelector } from './events'
import TouchpointsModule from 'admin/modules/touchpoints'


const selectEvent = (state) => state.admin.events.currentEvent.id
const selectState = (state) => state.admin.infras
const selectInfras = (state) => selectState(state).data

function sortInfra(a, b) {
  if (a.status && b.status && a.status.button_pressed && !b.status.button_pressed) return 1
  if (b.status && b.status.button_pressed) return -1
  return (a.status && b.status) ? b.status.last_heard - a.status.last_heard : 0
}

export const infrasSelector = createSelector(selectInfras, infras => infras)
export const infrasByIdSelector = createSelector(selectInfras, infras => listToMapping(infras, 'id'))

export const infrasByMapIdSelector = createSelector(infrasSelector, infras => {
  const infrasByMapId = {}

  infras
    .filter(infra => infra.type === 'tag' || infra.type === 'beacon' || infra.type === 'touchpoint')
    .forEach(infra => {
      if (!infrasByMapId[infra.map]) {
        infrasByMapId[infra.map] = []
      }

      infrasByMapId[infra.map].push(infra)
    })

  return infrasByMapId
})

export const tagsByMapIdSelector = createSelector(infrasSelector, infras => {
  const tagsByMapId = {}

  infras
    .filter(infra => infra.type === 'tag')
    .forEach(infra => {
      if (!tagsByMapId[infra.map]) {
        tagsByMapId[infra.map] = []
      }
      tagsByMapId[infra.map].push(infra)
    })

  return tagsByMapId
})

export const unplacedInfrasSelector = createSelector(infrasSelector, (infras) => infras
  .filter(infra => !infra.x && !infra.y && (infra.type === 'beacon' || infra.type === 'tag' || infra.type === 'touchpoint'))
  .sort(sortInfra)
)
export const unplacedBeaconsSelector = createSelector(unplacedInfrasSelector, (infras) => infras.filter(infra => infra.type === 'beacon').sort(sortInfra))
export const unplacedTagsSelector = createSelector(unplacedInfrasSelector, (infras) => infras.filter(infra => infra.type === 'tag').sort(sortInfra))

export const unplacedInfrasDropdownValuesSelector = createSelector(unplacedInfrasSelector, (infras) => infras.map((infra) => ({ value: infra.id, name: (infra.name || infra.id), type: infra.type })))

export const tagsSelector = createSelector(infrasSelector, infras => infras.filter(i => i.type === 'tag'))
export const tagsByIdSelector = createSelector(tagsSelector, tags => listToMapping(tags, 'id'))

export const beaconsSelector = createSelector(infrasSelector, infras => infras.filter(i => i.type === 'beacon'))
export const beaconsByIdSelector = createSelector(beaconsSelector, beacons => listToMapping(beacons, 'id'))
export const beaconsOnMapOnly = createSelector(beaconsSelector, beacons => beacons.filter(i => i.map !== null))

export const touchpointsSelector = createSelector(infrasSelector, infras => infras.filter(i => i.type === 'touchpoint'))
export const touchpointsByIdSelector = createSelector(touchpointsSelector, touchpoints => listToMapping(touchpoints, 'id'))

export const currentEventHubSelector = createSelector([beaconsSelector, selectEvent], (beacon, eventId) => beacon.filter(b => b.event_id === eventId))


const { actions, reducers } = generateModule({
  itemName: 'infra',
  idFieldName: 'id',
  services: {
    get: serviceGetInfras,
    upd: serviceUpdateInfra,
    del: serviceDeleteInfra,
  },
})

actions.setRXGain = (infraId, gain) => (dispatch, getState) => {
  dispatch({ type: 'infras/SET_RX_GAIN_START', payload: { infraId, gain } })
  return serviceSetRXGain(
    currentEventIdSelector(getState()),
    infraId,
    gain,
    () => dispatch({ type: 'infras/SET_RX_GAIN_SUCCESS', payload: { infraId, gain } }),
    () => dispatch({ type: 'infras/SET_RX_GAIN_ERROR', payload: { infraId, gain } })
  )
}


function _updateInfra(state, infra) {
  state.data = state.data.map(_infra => (_infra.id !== infra.id // eslint-disable-line no-param-reassign
    ? _infra
    : { ..._infra, ...infra }
  ))

  return state
}

reducers[TouchpointsModule.actionKeys.deployStart] = (state, action) => {
  const newState = { ...state }
  _updateInfra(state, action.payload.infra)
  return newState
}

reducers[TouchpointsModule.actionKeys.deployStart] = (state, action) => {
  const newState = { ...state }
  _updateInfra(state, action.payload.infra)
  return newState
}


export const getInfras = actions.get
export const getInfrasIfNeeded = actions.getIfNeeded
export const updateInfra = actions.update
export const deleteInfra = actions.delete
export const setRXGain = actions.setRXGain
const infras = reducers.index
export default infras
