import { generateDataModule } from './generateModule'
import { serviceGetGame, serviceUpdateGame } from 'admin/services/gamification'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'


const GamificationModule = generateDataModule({
  moduleKey: 'cpo/gamification',
  reducerKey: 'admin.gamification',

  initialData: {
    activities: {
      complete_profile: { rules: [] },
      add_contact: { rules: [] },
      attend_session: { rules: [] },
      check_in: { rules: [] },
    },
  },

  services: {
    get: { service: serviceGetGame, unalterable: true },
    put: { service: serviceUpdateGame },
  },
})


GamificationModule.registerSelector('activities', (state, moduleState) => moduleState.data.activities, activities => activities)


GamificationModule.actions.get = (eventId, gameId) => dispatch => {
  dispatch({ type: GamificationModule.actionKeys.getStart })

  return serviceGetGame(eventId || GamificationModule._getEventId(), gameId)
    .then(response => {
      dispatch({ type: GamificationModule.actionKeys.getSuccess, payload: response })
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({ type: GamificationModule.actionKeys.getError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('gamification.get_game.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject(error)
    })
}


GamificationModule.actions.put = (gameId, game) => dispatch => {
  dispatch({ type: GamificationModule.actionKeys.putStart })

  return serviceUpdateGame(GamificationModule._getEventId(), gameId, game)
    .then(response => {
      dispatch({ type: GamificationModule.actionKeys.putSuccess, payload: response })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('gamification.update_game.title'),
        message: i18n.getFixedT(i18n.language, 'admin_customModule')('gamification.update_game.success'),
        level: 'success',
        permanent: false,
      }))
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({ type: GamificationModule.actionKeys.putError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('gamification.update_game.title'),
        message: error.description,
        name: 'update_game_error',
        level: 'error',
        permanent: false,
      }))
      return Promise.reject(error)
    })
}

GamificationModule.registerReducer(
  GamificationModule.actionKeys.putSuccess,
  (state, action) => ({
    ...state,
    isModifying: false,
    data: { ...state.data, ...action.payload },
  })
)


export default GamificationModule
