import { serviceSendMeetingInvitation, serviceGetReceivedMeetingInvitations, serviceGetSentMeetingInvitations, serviceAcceptMeetingInvitation, serviceReplyToMeetingInvitation, serviceCancelMeetingInvitation } from 'attendee/services/meetingRequests'
import generateModule from 'common/utils/generateModule'
import { handleErrorMessage } from './index'
import { createSelector } from 'reselect'
import { filterActiveInvitations, conversationInvitationsByAccountIdSelector } from './conversationInvitations'


const selectMeetingInvitations = state => state.attendee.meetings.data

export const meetingInvitationsByAccountIdSelector = createSelector(
  selectMeetingInvitations,
  (meetingInvitations) => {
    const meetingInvitationById = {}
    meetingInvitations.forEach(meetingInvitation => {
      meetingInvitationById[meetingInvitation.account.account_id] = meetingInvitation
    })
    return meetingInvitationById
  }
)


function getEventId(getState) {
  return getState().attendee.attEvents.currEvent.id
}

function findMeetingInvitationIndex(invitations, id) {
  for (let i = 0; i < invitations.length; i++) {
    if (invitations[i].id === id) return i
  }
  return null
}


const module = generateModule({
  itemName: 'meeting',
  itemNameDisplay: 'meeting',
  getModuleState: (getState) => getState().attendee.meetings,
  autoRefresh: 60,
  app: 'attendee',
  services: {
    get: serviceGetReceivedMeetingInvitations,
  },
})


module.actions.get = () =>
  (dispatch, getState) => {
    dispatch({ type: 'GET_MEETING_INVITATIONS_START' })

    return Promise.all([
      serviceGetReceivedMeetingInvitations(
        getEventId(getState),
        response => Promise.resolve(response),
        error => Promise.reject(error.description)
      ),
      serviceGetSentMeetingInvitations(
        getEventId(getState),
        response => Promise.resolve(response),
        error => Promise.reject(error.description)
      ),
    ])
      .catch(errors => {
        let errorMessage = ''
        errors.forEach(error => {
          if (error) errorMessage += (error + '\n')
        })

        dispatch({ type: 'GET_MEETING_INVITATIONS_ERROR' })
        handleErrorMessage(dispatch, getState, { description: errorMessage })
      })
      .then(meetings => {
        let allMeetings = []
        meetings.forEach(meeting => {
          if (meeting) allMeetings = [...allMeetings, ...meeting]
        })
        dispatch({ type: 'GET_MEETING_INVITATIONS_SUCCESS', payload: { meetings: filterActiveInvitations(allMeetings, 'meetings') } })
      })
  }

module.reducers['GET_MEETING_INVITATIONS_START'] = (state) => ({
  ...state,
  isFetching: true,
  isError: false,
})

module.reducers['GET_MEETING_INVITATIONS_SUCCESS'] = (state, action) => ({
  ...state,
  data: action.payload.meetings,
  isFetching: false,
  isLoaded: true,
})

module.reducers['GET_MEETING_INVITATIONS_ERROR'] = (state) => ({
  ...state,
  isFetching: false,
  isError: true,
})


module.actions.sendMeetingInvitation = (accountId, meetingData) =>
  (dispatch, getState) => {
    const conversation = conversationInvitationsByAccountIdSelector(getState())[accountId]
    const requestPayload = {
      ...meetingData,
      initiated_from: conversation ? conversation.initiated_from : { networking: accountId },
    }

    dispatch({ type: 'SEND_MEETING_INVITATION_START', payload: { accountId, meetingData: requestPayload } })

    return serviceSendMeetingInvitation(
      getEventId(getState),
      accountId,
      requestPayload,
      (response) => {
        dispatch({ type: 'SEND_MEETING_INVITATION_SUCCESS', payload: response })
      },
      (error) => {
        dispatch({ type: 'SEND_MEETING_INVITATION_ERROR', payload: { accountId, requestPayload } })
        handleErrorMessage(dispatch, getState, error)
      }
    )
  }

module.reducers['SEND_MEETING_INVITATION_SUCCESS'] = (state, action) => {
  const invitations = [...state.data]
  const invitationIndex = findMeetingInvitationIndex(invitations, action.payload.id)

  if (invitationIndex != null) invitations[invitationIndex] = action.payload
  else invitations.push(action.payload)

  return { ...state, data: invitations }
}


module.actions.replyToMeetingInvitation = (accountId, meetingInvitationId, meetingData) =>
  (dispatch, getState) => {
    const meetingInvitation = meetingInvitationsByAccountIdSelector(getState())[accountId]
    const requestPayload = {
      ...meetingData,
      initiated_from: meetingInvitation ? meetingInvitation.initiated_from : { networking: accountId },
    }
    dispatch({ type: 'REPLY_TO_MEETING_INVITATION_START' })

    return serviceReplyToMeetingInvitation(
      getEventId(getState),
      meetingInvitationId,
      requestPayload,
      (response) => dispatch({ type: 'REPLY_TO_MEETING_INVITATION_SUCCESS', payload: response }),
      (error) => {
        dispatch({ type: 'REPLY_TO_MEETING_INVITATION_ERROR' })
        handleErrorMessage(dispatch, getState, error)
      }
    )
  }

module.reducers['REPLY_TO_MEETING_INVITATION_SUCCESS'] = (state, action) => {
  let invitations = state.data
  const invitationIndex = findMeetingInvitationIndex(invitations, action.payload.id)

  if (invitationIndex != null) {
    invitations = [...state.data]
    invitations[invitationIndex] = action.payload
  }

  return { ...state, data: invitations }
}


module.actions.acceptMeetingInvitation = (meetingInvitationId, proposedMeetingId) =>
  (dispatch, getState) => {
    dispatch({ type: 'ACCEPT_MEETING_INVITATION_START', payload: meetingInvitationId, proposedMeetingId })

    return serviceAcceptMeetingInvitation(
      getEventId(getState),
      meetingInvitationId,
      proposedMeetingId,
      (response) => dispatch({ type: 'ACCEPT_MEETING_INVITATION_SUCCESS', payload: response }),
      (error) => {
        dispatch({ type: 'ACCEPT_MEETING_INVITATION_ERROR', payload: { meetingInvitationId, proposedMeetingId } })
        handleErrorMessage(dispatch, getState, error)
      }
    )
  }

module.reducers['ACCEPT_MEETING_INVITATION_SUCCESS'] = (state, action) => {
  let invitations = state.data
  const invitationIndex = findMeetingInvitationIndex(invitations, action.payload.id)

  if (invitationIndex != null) {
    invitations = [...state.data]
    invitations[invitationIndex] = action.payload
  } else {
    invitations = [...state.data, action.payload]
  }

  return { ...state, data: invitations }
}


module.actions.cancelMeetingInvitation = (meetingInvitationId) =>
  (dispatch, getState) => {
    dispatch({ type: 'CANCEL_MEETING_INVITATION_START', payload: { meetingInvitationId } })

    return serviceCancelMeetingInvitation(
      getEventId(getState),
      meetingInvitationId,
      (response) => dispatch({ type: 'CANCEL_MEETING_INVITATION_SUCCESS', payload: response }),
      (error) => {
        dispatch({ type: 'CANCEL_MEETING_INVITATION_ERROR', payload: { meetingInvitationId } })
        handleErrorMessage(dispatch, getState, error)
      }
    )
  }

module.reducers['CANCEL_MEETING_INVITATION_SUCCESS'] = (state, action) => {
  let invitations = state.data
  const invitationIndex = findMeetingInvitationIndex(invitations, action.payload.id)

  if (invitationIndex != null) {
    invitations = [...state.data]
    invitations[invitationIndex] = action.payload
  }

  return { ...state, data: invitations }
}


module.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isError: false,
  isLoaded: false,
  didInvalidate: true,
  data: [],
})


module.actions.getMeetingInvitations = module.actions.get

export default module
