import React, { Component } from 'react'
import { connect } from 'react-redux'
import { buildRoute } from 'routes'

import { Redirect } from 'react-router-dom'


@connect(
  state => ({
    isOrganizer: state.auth.user.isOrganizer,
    isExhibitor: state.auth.user.isExhibitor,
  })
)
export default class AdminAppSelector extends Component {
  componentWillMount() {
    window.PageLoader.startLoading()
  }

  render() {
    const { isExhibitor, isOrganizer, match } = this.props

    if (isOrganizer) {
      return <Redirect to={buildRoute(match.path, '/events')} />
    }

    if (isExhibitor) {
      return <Redirect to={buildRoute(match.path, '/exhibitors')} />
    }

    return <Redirect to="/" />
  }
}
