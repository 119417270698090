import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Popper from 'popper.js'
import { getProperty } from 'common/utils/componentHelper'

import Triangle from './Triangle'

import { styles } from './styles.scss'


export default class Popover extends Component {
  constructor(props) {
    super(props)

    this.state = {
      popperData: undefined,
    }
  }

  componentDidMount() {
    this.initializePopper(this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (this.popper && !this.props.isVisible && nextProps.isVisible) {
      this.popper.scheduleUpdate()
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.referenceElement && this.props.referenceElement) {
      this.initializePopper(this.props)
    }
  }

  componentWillUnmount() {
    if (this.popper) {
      this.popper.destroy()
    }
  }

  initializePopper(props) {
    const { referenceElement, placement, flip, offset, overridePopperOptions } = props

    if (!referenceElement) {
      return
    }

    if (this.popper) {
      this.popper.destroy()
      this.popper = null
    }

    const popperOptions = overridePopperOptions || {
      placement,
      modifiers: {
        flip: {
          behavior: flip,
        },
        offset: {
          offset,
        },
        arrow: {
          element: this.refToTriangle.refToElement,
        },
      },
      onCreate: (data) => this.setState({ popperData: data }),
      onUpdate: (data) => this.setState({ popperData: data }),
    }

    this.popper = new Popper(referenceElement, this.refToPopoverElement, popperOptions)
  }

  _getClassName() {
    let className = `Popover ${styles}`
    className += this.props.className ? ` ${this.props.className}` : ''
    return className
  }

  _getArrowDirection() {
    const popperPlacement = getProperty(this.state.popperData, 'attributes.x-placement')

    switch (popperPlacement) { /* eslint-disable no-multi-spaces */
      default:
      case 'top':     return 'bottom'
      case 'right':   return 'left'
      case 'bottom':  return 'top'
      case 'left':    return 'right'
    } /* eslint-enable */
  }

  render() {
    const { isVisible, onClick } = this.props

    return (
      <div className={this._getClassName()} onClick={onClick} ref={ref => { this.refToPopoverElement = ref }} data-is-visible={isVisible ? '' : null}>
        <div className="Popover-content-wrapper">
          {this.props.children}
        </div>

        <Triangle className="Popover-arrow" direction={this._getArrowDirection()} ref={ref => { this.refToTriangle = ref }} />
      </div>
    )
  }
}

Popover.propTypes = {
  placement: PropTypes.oneOf(['auto', 'auto-start', 'auto-end', 'top', 'top-start', 'top-end', 'right', 'right-start', 'right-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end']),
  flip: PropTypes.arrayOf(PropTypes.oneOf(['top', 'right', 'bottom', 'left'])),
  overridePopperOptions: PropTypes.object,
  offset: PropTypes.string,
  referenceElement: PropTypes.instanceOf(Element),
}

Popover.defaultProps = {
  onClick: () => {},
  className: '',

  // Where the arrow should be placed
  placement: 'auto',
  // How it should flip when the popper starts overlapping with the reference element
  flip: [],
  // How much offset needs to be applied to the popper
  offset: '0',
  // Reference element to use to position the popover
  referenceElement: null,

  // Use this to pass a fully customized popper options. See docs: https://github.com/FezVrasta/popper.js/blob/master/docs/_includes/popper-documentation.md
  overridePopperOptions: undefined,
}
