import { serviceGetCarousel } from 'attendee/services/carousel'
import generateModule from 'common/utils/generateModule'
import { setLastSeenCarouselCard, getLastSeenCarouselCard } from 'common/utils/accessToken'
import { createSelector } from 'reselect'

const selectState = (state) => state.attendee.carousels

export const carouselsSelector = createSelector(selectState, (state) => state.data)
export const lastSeenCarouselIndexSelector = createSelector(
  selectState,
  carouselsSelector,
  (state, carousels) => {
    for (let i = 0; i < carousels.length; i++) {
      if (carousels[i].name === state.lastSeenCard) return i
    }
    return 0
  }
)


const carousel = generateModule({
  itemName: 'carousel',
  itemNameDisplay: 'carousel',
  autoRefresh: 60,
  app: 'attendee',
  getModuleState: (getState) => getState().attendee.carousels,
  services: {
    get: serviceGetCarousel,
  },
})

carousel.initialState.lastSeenCard = undefined


carousel.actions.setLastSeenCarouselCard = (cardName) =>
  (dispatch, getState) => {
    const eventId = getState().attendee.attEvents.currEvent.id

    setLastSeenCarouselCard(eventId, cardName)
    dispatch({
      type: 'SET_LAST_SEEN_CAROUSEL_CARD',
      payload: { cardName, eventId },
    })

    return Promise.resolve()
  }

carousel.reducers['SET_LAST_SEEN_CAROUSEL_CARD'] = (state, action) => ({
  ...state,
  lastSeenCard: action.payload.cardName,
})


carousel.reducers['CHANGE_ATT_EVENT_SUCCESS'] = (state, action) => ({
  ...state,
  lastSeenCard: getLastSeenCarouselCard(action.eventId),
})


carousel.reducers['ATTENDEE_INVALIDATE_DATA'] = (state) => ({
  ...state,
  isFetching: false,
  isError: false,
  isLoaded: false,
  didInvalidate: true,
  data: [],
  lastSeenCard: undefined, // TODO: Get the card id from localstorage
})

carousel.actions.getCarousel = carousel.actions.get

export default carousel
