import generateGetServices from './get'
import generatePutServices from './put'
import generatePostServices from './post'
import generateDeleteServices from './delete'


function getActionKeyPrefix(options) {
  const parts = []
  if (options.app) parts.push(options.app)
  parts.push(...options.moduleKey.split(/(?=[A-Z])/))
  return parts.join('_')
}

export default function generateServices(options, initialState) {
  const actionKeyPrefix = getActionKeyPrefix(options)
  let actions = {}
  let reducers = {}

  const serviceGenerators = {
    get: generateGetServices,
    put: generatePutServices,
    post: generatePostServices,
    delete: generateDeleteServices,
  }

  Object.keys(options.services).forEach(key => {
    const service = serviceGenerators[key](actionKeyPrefix, options)
    actions = { ...actions, ...service.actions }
    reducers = { ...reducers, ...service.reducers }
  })

  reducers.index = (state = initialState, action) => (reducers[action.type] ? reducers[action.type](state, action) : state)

  return { actions, reducers }
}
