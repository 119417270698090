import { serviceGetSocialNetworkStats } from 'admin/services/analytics'
import generateModule from 'common/utils/generateModule'


const { actions, reducers, initialState } = generateModule({
  itemName: 'socialNetworks',
  autoRefresh: 3,
  getModuleState: (getState) => getState().admin.analytics.onlineEngagement.socialNetworks,
  services: {
    get: serviceGetSocialNetworkStats,
  },
})


actions.get = (attendeeType) =>
  (dispatch, getState) => {
    dispatch(actions.getStart())
    return serviceGetSocialNetworkStats(
      getState().admin.events.currentEvent.id,
      attendeeType,
      (response) => dispatch(actions.getSuccess(response)),
      () => dispatch(actions.getError()),
    )
  }


reducers['CHANGE_EVENT_SUCCESS'] = (state) => ({
  ...state,
  didInvalidate: true,
  data: initialState.data,
})


export const getSocialNetworkStats = actions.get
export const getSocialNetworkStatsIfNeeded = actions.getIfNeeded

const socialNetworks = reducers.index
export default socialNetworks
