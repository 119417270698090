module.exports = {
  // keep alphabetized
  brandPrimary: '#00ffb9',
  clientId: 'klik_web',
  eventClient: 'pixmob',
  productName: 'klik',
  tosLink: {
    en: 'https://www.bizzabo.com/legal/user-terms-of-service',
    fr: 'https://www.bizzabo.com/legal/user-terms-of-service',
    zh: 'https://www.bizzabo.com/legal/user-terms-of-service',
  },
  ppLink: {
    en: 'https://www.bizzabo.com/privacy/',
    fr: 'https://www.bizzabo.com/privacy/',
    zh: 'https://www.bizzabo.com/privacy/',
  },
  authProvider: {
    facebook: {
      clientId: '150659718665327',
      url: '/login',
      scope: 'email',
      scopeDelimiter: ',',
    },

    linkedin: {
      clientId: '776s41ccgos8dq',
      url: '/login',
      scope: ['r_emailaddress', 'r_basicprofile'],
      scopeDelimiter: ' ',
    },

    salesforce: {
      clientId: '3MVG9szVa2RxsqBYSDefVb8_kepJbcdA1DKm0bpZ41cM1hVscynbfuH566oEHfWlcID4CG6ZlfHK7ZFEut3Sd',
      url: '/login',
      scope: 'email',
      scopeDelimiter: ' ',
    },

    eventbrite: {
      // ID is specified in different environment
      name: 'eventbrite',
      url: '/login',
    },
  },
  meta: {
    title: 'klik',
    description: 'Connecting thousands of people, one wristband at the time. You are next!',
    meta: {
      charset: 'utf-8',
    },
  },
  nativeAppUrl: {
    android: 'https://play.google.com/store/apps/details?id=com.pixmob.klik.event_app_android',
    ios: 'https://itunes.apple.com/ca/app/klik-event-app/id1318773802?mt=8',
  },
}
