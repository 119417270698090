import config from 'config'
import i18n from 'i18next'
import { store } from '../../index'

export function parsePaginationHeaders(response) {
  return {
    page: response.headers['x-page'],
    pageCount: response.headers['x-total-pages'],
  }
}

export function getAccessToken() {
  return store.getState().auth.user.accessToken
}

export function getRefreshToken() {
  return store.getState().auth.user.refreshToken
}

export function buildQueryString(query) {
  let queryString = ''

  if (query) {
    const queryKeys = Object.keys(query)

    if (queryKeys.length > 0) {
      queryString += '?'
    }

    queryKeys.forEach(queryKey => {
      if (query[queryKey] != null) {
        queryString += `&${queryKey}=${query[queryKey]}`
      }
    })
  }
  return queryString
}

export function getAxiosConfig(_options) {
  const options = { withToken: true, ..._options }

  const headers = {
    'Accept-Language': options['Accept-Language'] || i18n.language,
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json; charset=utf-8',
    Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
    Pragma: 'no-cache',
  }

  if (options.withToken) {
    const accessToken = getAccessToken()
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`
    }
  }

  return {
    baseURL: config.apiUrl,
    headers: headers,
  }
}

export class CountTimer {
  constructor(time, callback) {
    this.time = time
    this.callback = callback
    this.count = 0
    this.isRunning = false
    this.refTimeout = null
  }

  countOne() {
    if (!this.isRunning) {
      this.start()
    }

    this.count++
  }

  start() {
    this.count = 0
    this.isRunning = true

    this.refTimeout = clearTimeout(this.refTimeout)
    this.refTimeout = setTimeout(() => {
      this.callback(this.count)

      this.isRunning = false
      this.refTimeout = null
    }, this.time)
  }
}
