import React from 'react'
import { translate } from 'react-i18next'

function LoginNoEventAccess(props) {
  const { t } = props

  return (
    <div className="LoginNoEventAccess">
      <div className="Image" style={{ backgroundImage: 'url(/assets/images/QuestionMessage.png)' }} />
      <p>{t('nothing_here')}</p>
      <p>{t('no_event_assigned')}</p>
    </div>
  )
}

export default translate(['login'], { wait: true })(LoginNoEventAccess)
