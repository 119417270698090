import axios from 'axios'
import { apiErrorHandler, apiCatchError, buildError } from 'common/utils/api'
import { getTranslatedObject } from 'common/utils/translator'
import { getProperty } from 'common/utils/componentHelper'
import { getAxiosConfig } from './helper'
import { translateTouchpoint } from 'admin/services/maps'
import { parsePaginationHeaders } from 'common/services/helper'

function translateExhibitor(exhibitor) {
  const data = { ...exhibitor }

  if (data.name) {
    data.name = getTranslatedObject(data.name)
  }

  if (data.description) {
    data.description = getTranslatedObject(data.description)
  }

  if (data.location_name) {
    data.location_name = getTranslatedObject(data.location_name)
  }

  data.call_to_actions = data.call_to_actions.map(cta => ({ ...cta, label: getTranslatedObject(cta.label) }))

  return data
}

function translateLead(lead) { // eslint-disable-line
  const data = { ...lead }

  // TODO: Translate what needs to be translated

  return data
}

function translateExhibitorType(exhibitorType) {
  const data = { ...exhibitorType }

  if (data.title) {
    data.title = getTranslatedObject(data.title)
  }

  return data
}

function buildQueryParams(_query) {
  const query = { ..._query }
  const queryKeys = Object.keys(query)
  let queryString = '?'

  if (queryKeys.length === 0 && query.constructor === Object) {
    return ''
  }

  queryKeys.forEach(key => {
    if (query[key] != null) {
      queryString += `&${key}=${query[key]}`
    }
  })

  return queryString
}


export function serviceGetExhibitors(eventId, queryParams) {
  const params = buildQueryParams(queryParams)

  return axios.get(`/events/${eventId}/exhibitors${params}`, getAxiosConfig())
    .then(response => ({
      data: response.data.map(o => translateExhibitor(o)),
      ...parsePaginationHeaders(response),
    }))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetManagedExhibitors() {
  return axios.get('/accounts/me/exhibitors', getAxiosConfig())
    .then(response => response.data.map(o => translateExhibitor(o)))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetExhibitor(eventId, exhibitorId) {
  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}`, getAxiosConfig())
    .then(response => translateExhibitor(response.data))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceCreateExhibitor(eventId, exhibitor) {
  return axios.post(`/events/${eventId}/exhibitors`, exhibitor, getAxiosConfig())
    .then(response => translateExhibitor(response.data))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceUpdateExhibitor(eventId, exhibitor) {
  return axios.put(`/events/${eventId}/exhibitors/${exhibitor.id}`, exhibitor, getAxiosConfig())
    .then(response => translateExhibitor(response.data))
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceDeleteExhibitor(eventId, exhibitorId) {
  return axios.delete(`/events/${eventId}/exhibitors/${exhibitorId}`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceAddRepresentative(eventId, exhibitorId, attendeeId) {
  return axios.put(`/events/${eventId}/exhibitors/${exhibitorId}/representatives/${attendeeId}`, {}, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceDeleteRepresentative(eventId, exhibitorId, attendeeId) {
  return axios.delete(`/events/${eventId}/exhibitors/${exhibitorId}/representatives/${attendeeId}`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetRepresentativesForExhibitor(eventId, exhibitorId) {
  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}/representatives`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetExhibitorAnalytics(eventId, exhibitorId) {
  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}/stats`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetLeads(eventId, exhibitorId, query, success, error) {
  let queryString = ''
  queryString += getProperty(query, 'query') ? `&query=${query.query}` : ''
  queryString += getProperty(query, 'limit') ? `&limit=${query.limit}` : ''
  queryString += getProperty(query, 'offset') ? `&offset=${query.offset}` : ''

  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}/leads?${queryString}`, getAxiosConfig())
    .then((response) => Promise.resolve(response.data.map(o => translateLead(o))))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetLead(eventId, exhibitorId, accountId, success, error) {
  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}/leads/${accountId}`, getAxiosConfig())
    .then((response) => Promise.resolve(translateLead(response.data)))
    .then((data) => success(data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetLeadContacts(eventId, exhibitorId, accountId, success, error) {
  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}/leads/${accountId}/contacts`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetLeadStats(eventId, exhibitorId, accountId, success, error) {
  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}/leads/${accountId}/stats`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateLeadNotes(eventId, exhibitorId, accountId, notes, success, error) {
  return axios.put(`/events/${eventId}/exhibitors/${exhibitorId}/leads/${accountId}`, { notes }, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeleteLead(eventId, exhibitorId, accountId, success, error) { // eslint-disable-line
  /* Need to be replaced by commented block */
  return new Promise(resolve => {
    setTimeout(() => { resolve() }, 500)
  }).then(() => success())
  /* End of what needs to be replaced */

  // TODO: Make sure endpoint is correct
  // return axios.delete(`/events/${eventId}/exhibitors/${exhibitorId}/leads/${accountId}`, getAxiosConfig())
  //   .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
  //   .catch((response) => apiCatchError(response))
}

export function serviceGetRepresentativeLeaderboard(eventId, exhibitorId, success, error) {
  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}/representatives/leaderboard`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetTouchpoint(eventId, exhibitorId, touchpointID) {
  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}/touchpoints/${touchpointID}`, getAxiosConfig())
    .then((response) => translateTouchpoint(response.data))
    .catch((error) => Promise.reject(buildError(error)))
}

export function serviceGetTouchpoints(eventId, exhibitorId) {
  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}/touchpoints`, getAxiosConfig())
    .then((response) => response.data.map(translateTouchpoint))
    .catch((error) => Promise.reject(buildError(error)))
}

export function serviceUpdateTouchpoint(eventId, exhibitorID, touchpoint) {
  return axios.put(`/events/${eventId}/exhibitors/${exhibitorID}/touchpoints/${touchpoint.id}`, touchpoint, getAxiosConfig())
    .then((response) => Promise.resolve(translateTouchpoint(response.data)))
    .catch((error) => Promise.reject(buildError(error)))
}

export function serviceGetExhibitorSummaryStats(eventId, exhibitorId, success, error) {
  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}/stats`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetExhibitorLeadsOvertime(eventId, exhibitorId, query, success, error) {
  let queryString = ''
  Object.keys(query).forEach(key => { queryString += query[key] ? `&${key}=${query[key]}` : '' })

  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}/stats/leads/overtime?${queryString}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetExhibitorVisitsOvertime(eventId, exhibitorId, query, success, error) {
  let queryString = ''
  Object.keys(query).forEach(key => { queryString += query[key] ? `&${key}=${query[key]}` : '' })

  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}/stats/attendees/overtime?${queryString}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceAddExhibitorAdmin(eventId, exhibitorId, data) {
  return axios.put(`/events/${eventId}/exhibitors/${exhibitorId}/admins/email:${data.email}`, data, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceDeleteExhibitorAdmin(eventId, exhibitorId, accountId) {
  return axios.delete(`/events/${eventId}/exhibitors/${exhibitorId}/admins/${accountId}`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceResetExhibitorAdminPassword(eventId, exhibitorId, profileId, password) {
  return axios.put(`/events/${eventId}/exhibitors/${exhibitorId}/admins/${profileId}`, { password: password }, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

export function serviceGetLeadsBreakdownStats(eventId, exhibitorId) {
  return axios.get(`/events/${eventId}/exhibitors/${exhibitorId}/stats/leads/breakdown`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => apiCatchError(error))
}

export function serviceGetExhibitorTypes(eventId) {
  return axios.get(`/events/${eventId}/exhibitors/types`, getAxiosConfig())
    .then(response => response.data.map(translateExhibitorType))
    .catch(error => apiCatchError(error))
}

export function serviceCreateExhibitorType(eventId, exhibitorType) {
  return axios.post(`/events/${eventId}/exhibitors/types`, exhibitorType, getAxiosConfig())
    .then(response => translateExhibitorType(response.data))
    .catch(error => apiCatchError(error))
}

export function serviceUpdateExhibitorType(eventId, exhibitorType) {
  return axios.put(`/events/${eventId}/exhibitors/types/${exhibitorType.id}`, exhibitorType, getAxiosConfig())
    .then(response => translateExhibitorType(response.data))
    .catch(error => apiCatchError(error))
}

export function serviceDeleteExhibitorType(eventId, exhibitorTypeId) {
  return axios.delete(`/events/${eventId}/exhibitors/types/${exhibitorTypeId}`, getAxiosConfig())
    .catch(error => apiCatchError(error))
}
