import { serviceGetTouchpoints, serviceAddTouchpoint, serviceGetTouchpoint, serviceUpdateTouchpoint, serviceDeleteTouchpoint, serviceDeployTouchpoint, serviceUndeployTouchpoint } from 'admin/services/maps'
import { generateDataModule } from 'admin/modules/generateModule'
import { addSortingSupport } from './Dashboard/helper'
import { showToast } from 'common/modules/toaster'
import { browserHistoryPush } from 'common/components/Link'
import search, { buildFieldList } from 'common/utils/search'
import sort from 'common/utils/sortHelper'
import i18n from 'i18next'
import ExhibitorsModule from 'admin/modules/exhibitors'
import { infrasByIdSelector } from 'admin/modules/infras'


const TouchpointsModule = generateDataModule({
  initialState: {
    current: undefined,
    searchTerm: '',
  },
  moduleKey: 'cpo/touchpoints',
  reducerKey: 'admin.touchpoints',

  services: {
    get: { service: serviceGetTouchpoints },
    post: { service: serviceAddTouchpoint },
    put: { service: serviceUpdateTouchpoint },
    delete: { service: serviceDeleteTouchpoint },
  },
})

addSortingSupport(TouchpointsModule, { id: 'name', order: 1 })


// ////////////////////////////////////////////////////////////////////////////
// Selectors
// ////////////////////////////////////////////////////////////////////////////

TouchpointsModule.registerSelector(
  'data',
  [
    (state, moduleState) => moduleState.data,
    state => ExhibitorsModule.selectors.idsToCode(state),
  ],
  (touchpoints, exhibitorCodes) => touchpoints.map(touchpoint => ({
    ...touchpoint,
    _exhibitor_code: exhibitorCodes[touchpoint.exhibitor_id],
    _infra_count: (touchpoint.infrastructure_ids || []).length || 0,
  }))
)

TouchpointsModule.registerSelector(
  'currentTouchpoint',
  (state, moduleState) => moduleState.current,
  touchpoint => touchpoint
)

TouchpointsModule.registerSelector(
  'touchpointsByExhibitorId',
  state => TouchpointsModule.selectors.data(state),
  touchpoints => {
    const exhibitorTouchpoints = {}

    touchpoints.forEach(touchpoint => {
      if (!exhibitorTouchpoints[touchpoint.exhibitor_id]) {
        exhibitorTouchpoints[touchpoint.exhibitor_id] = []
      }

      exhibitorTouchpoints[touchpoint.exhibitor_id].push(touchpoint)
    })

    return exhibitorTouchpoints
  }
)

TouchpointsModule.registerSelector(
  'sortedTouchpoints',
  [
    state => TouchpointsModule.selectors.data(state),
    state => TouchpointsModule.selectors.sorter(state),
  ],
  (attendees, sorter) => sort(attendees, [
    { id: sorter.id, order: sorter.order },
    'deployed',
    'name',
    'id',
  ])
)

TouchpointsModule.registerSelector(
  'filteredTouchpoints',
  [
    state => TouchpointsModule.selectors.sortedTouchpoints(state),
    state => TouchpointsModule.selectors.searchTerm(state),
  ],
  (touchpoints, searchTerm) => touchpoints.filter(touchpoint => search(
    searchTerm,
    buildFieldList(touchpoint, ['name'])
  ))
)

TouchpointsModule.registerSelector(
  'searchTerm',
  (state, moduleState) => moduleState.searchTerm,
  searchTerm => searchTerm
)

TouchpointsModule.registerSelector(
  'deployedTouchpoints',
  state => TouchpointsModule.selectors.data(state),
  touchpoints => touchpoints.filter(touchpoint => touchpoint.deployed)
)

TouchpointsModule.registerSelector(
  'undeployedTouchpoints',
  state => TouchpointsModule.selectors.data(state),
  touchpoints => touchpoints.filter(touchpoint => !touchpoint.deployed)
)

TouchpointsModule.registerSelector(
  'touchpointsOnMapByMapId',
  state => TouchpointsModule.selectors.data(state),
  touchpoints => {
    const touchpointsByMapId = {}

    touchpoints.forEach((touchpoint) => {
      if (!touchpoint.map || touchpoint.x == null || touchpoint.y == null) return

      if (!touchpointsByMapId[touchpoint.map]) {
        touchpointsByMapId[touchpoint.map] = []
      }

      touchpointsByMapId[touchpoint.map].push(touchpoint)
    })

    return touchpointsByMapId
  }
)

TouchpointsModule.registerSelector(
  'touchpointsByInfraId',
  state => TouchpointsModule.selectors.data(state),
  touchpoints => {
    const touchpointsByInfraId = {}

    touchpoints.forEach(touchpoint => {
      const infraIds = touchpoint.infrastructure_ids || []
      infraIds.forEach(infraId => {
        touchpointsByInfraId[infraId] = touchpoint
      })
    })

    return touchpointsByInfraId
  }
)

TouchpointsModule.registerSelector(
  'touchpointsTitleById',
  state => TouchpointsModule.selectors.data(state),
  touchpoints => {
    const touchpointIdToTitle = {}
    touchpoints.forEach(touchpoint => {
      touchpointIdToTitle[touchpoint.id] = touchpoint.name
    })
    return touchpointIdToTitle
  }
)


// ////////////////////////////////////////////////////////////////////////////
// Actions
// ////////////////////////////////////////////////////////////////////////////

TouchpointsModule.registerDataAction('getOne', touchpointId => dispatch => {
  dispatch({ type: TouchpointsModule.actionKeys.getOneStart })

  return serviceGetTouchpoint(TouchpointsModule._getEventId(), touchpointId)
    .then(response => dispatch({
      type: TouchpointsModule.actionKeys.getOneSuccess,
      payload: response,
    }))
    .catch(error => {
      dispatch({ type: TouchpointsModule.actionKeys.getOneError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('touchpoints.get_one.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    })
})

TouchpointsModule.registerDataAction('post', data => dispatch => {
  dispatch({ type: TouchpointsModule.actionKeys.postStart })

  return TouchpointsModule._getService('post')(data)
    .then(response => {
      dispatch({
        type: TouchpointsModule.actionKeys.postSuccess,
        payload: response,
      })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('touchpoints.create.title'),
        message: i18n.getFixedT(i18n.language, 'admin_customModule')('touchpoints.create.success'),
        level: 'success',
        permanent: false,
      }))
    })
    .catch(error => {
      dispatch({ type: TouchpointsModule.actionKeys.postError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('touchpoints.create.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    })
})

TouchpointsModule.registerDataAction('put', (data, optimistic) => (dispatch, getState) => {
  const initialTouchpoint = TouchpointsModule.selectors.currentTouchpoint(getState())

  dispatch({ type: TouchpointsModule.actionKeys.putStart, payload: data, optimistic })

  return TouchpointsModule._getService('put')(data)
    .then(response => {
      dispatch({
        type: TouchpointsModule.actionKeys.putSuccess,
        payload: response,
        optimistic,
      })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('touchpoints.update.title'),
        message: i18n.getFixedT(i18n.language, 'admin_customModule')('touchpoints.update.success'),
        level: 'success',
        permanent: false,
      }))
    })
    .catch(error => {
      dispatch({
        type: TouchpointsModule.actionKeys.putError,
        payload: initialTouchpoint,
        optimistic,
      })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('touchpoints.update.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    })
})

TouchpointsModule.registerDataAction('delete', (touchpointId, redirectTo) => dispatch => {
  dispatch({ type: TouchpointsModule.actionKeys.deleteStart, payload: touchpointId })

  return TouchpointsModule._getService('delete')(touchpointId)
    .then(() => {
      dispatch({
        type: TouchpointsModule.actionKeys.deleteSuccess,
        payload: touchpointId,
      })

      if (redirectTo) {
        browserHistoryPush(redirectTo)
      }

      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('touchpoints.delete.title'),
        message: i18n.getFixedT(i18n.language, 'admin_customModule')('touchpoints.delete.success'),
        level: 'success',
        permanent: false,
      }))
    })
    .catch(error => {
      dispatch({ type: TouchpointsModule.actionKeys.deleteError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('touchpoints.delete.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    })
})

TouchpointsModule.registerAction('clearCurrentTouchpoint', () => dispatch => dispatch({
  type: TouchpointsModule.actionKeys.clearCurrentTouchpoint,
}))

TouchpointsModule.registerAction('setSearchTerm', searchTerm => dispatch => dispatch({
  type: TouchpointsModule.actionKeys.setSearchTerm,
  payload: searchTerm,
}))

TouchpointsModule.registerDataAction('deploy', (touchpointId, infra) => (dispatch, getState) => {
  const state = getState()
  const initialTouchpoint = TouchpointsModule.selectors.dataById(state)[touchpointId]
  const initialInfra = infrasByIdSelector(state)[infra.id]

  dispatch({
    type: TouchpointsModule.actionKeys.deployStart,
    payload: {
      infra,
      touchpointId,
    },
  })

  return serviceDeployTouchpoint(TouchpointsModule._getEventId(), touchpointId, infra)
    .then(response => {
      dispatch({
        type: TouchpointsModule.actionKeys.deploySuccess,
        payload: {
          infra,
          touchpoint: response,
        },
      })
    })
    .catch(error => {
      dispatch({
        type: TouchpointsModule.actionKeys.deployError,
        payload: {
          infra: initialInfra,
          touchpoint: initialTouchpoint,
        },
      })
      return Promise.reject(error)
    })
})

TouchpointsModule.registerDataAction('undeploy', (touchpointId, infraId) => dispatch => {
  dispatch({
    type: TouchpointsModule.actionKeys.undeployStart,
    payload: {
      infraId,
      touchpointId,
    },
  })

  return serviceUndeployTouchpoint(TouchpointsModule._getEventId(), touchpointId, infraId)
    .then(response => {
      dispatch({
        type: TouchpointsModule.actionKeys.undeploySuccess,
        payload: {
          infraId,
          touchpoint: response,
        },
      })
    })
    .catch(error => {
      dispatch({
        type: TouchpointsModule.actionKeys.undeployError,
        payload: {
          infraId,
          touchpointId,
        },
      })
      return Promise.reject(error)
    })
})


// ////////////////////////////////////////////////////////////////////////////
// Reducers
// ////////////////////////////////////////////////////////////////////////////

function _updateTouchpoints(state, touchpoint) {
  if (state.current) {
    state.current = { ...state.current, ...touchpoint } // eslint-disable-line no-param-reassign
  }

  state.data = state.data.map(_touchpoint => (_touchpoint.id !== touchpoint.id // eslint-disable-line no-param-reassign
    ? _touchpoint
    : { ..._touchpoint, ...touchpoint }
  ))

  return state
}

TouchpointsModule.registerReducer(
  TouchpointsModule.actionKeys.putStart,
  (state, action) => {
    const newState = { ...state, isModifying: true }
    if (action.optimistic) {
      _updateTouchpoints(newState, action.payload)
    }
    return newState
  }
)

TouchpointsModule.registerReducer(
  TouchpointsModule.actionKeys.putSuccess,
  (state, action) => {
    const newState = { ...state, isModifying: false }
    if (!action.optimistic) {
      _updateTouchpoints(newState, action.payload)
    }
    return newState
  }
)

TouchpointsModule.registerReducer(
  TouchpointsModule.actionKeys.putError,
  (state, action) => {
    const newState = { ...state, isModifying: false }
    if (action.optimistic) {
      _updateTouchpoints(newState, action.payload)
    }
    return newState
  }
)

TouchpointsModule.registerReducer(
  TouchpointsModule.actionKeys.deploySuccess,
  (state, action) => {
    const newState = { ...state }
    _updateTouchpoints(newState, action.payload.touchpoint)
    return newState
  }
)

TouchpointsModule.registerReducer(
  TouchpointsModule.actionKeys.deployError,
  (state, action) => {
    const newState = { ...state }
    _updateTouchpoints(newState, action.payload.touchpoint)
    return newState
  }
)

TouchpointsModule.registerReducer(
  TouchpointsModule.actionKeys.undeploySuccess,
  (state, action) => {
    const newState = { ...state }
    _updateTouchpoints(newState, action.payload.touchpoint)
    return newState
  }
)

TouchpointsModule.registerReducer(
  TouchpointsModule.actionKeys.getOneSuccess,
  (state, action) => ({ ...state, current: action.payload })
)

TouchpointsModule.registerReducer(
  TouchpointsModule.actionKeys.clearCurrentTouchpoint,
  state => ({ ...state, current: TouchpointsModule.initialState.current })
)

TouchpointsModule.registerReducer(
  TouchpointsModule.actionKeys.setSearchTerm,
  (state, action) => ({ ...state, searchTerm: action.payload })
)


export default TouchpointsModule
