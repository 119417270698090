import React, { Component } from 'react'
import { translate } from 'react-i18next'

import TitleLabel from 'common/containers/Login/TitleLabel'


const KLIK_DOMAIN_SPLASH_URL = '/assets/images/login_bg.jpg'
const KLIK_DOMAIN_LOGO_URL = '/assets/images/klik_bizzabo_logo_white.png'


@translate(['login'], { wait: true })
export default class DomainSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      domains: [],
    }
  }

  componentWillMount() {
    const domainIds = this.props.user.attendee_domains || []

    // NOTE: This should not happen unless you change URL
    if (domainIds.length <= 0) {
      this.props.changeLoginPage(this.props.location, 'email')
    } else {
      this.getDomains(domainIds).then((domains) => { this.setState({ domains }) })
    }
  }

  getDomains(domainIds) {
    const domains = []

    let chain = Promise.resolve()
    for (let i = 0; i < domainIds.length; i++) {
      chain = chain.then(() => ((domainIds[i] !== 'default') ? this.props.getDomainBranding(domainIds[i]) : {
        domain: 'default',
        splash_url: KLIK_DOMAIN_SPLASH_URL,
        logo_url: KLIK_DOMAIN_LOGO_URL,
      })).then((domain) => domains.push(domain))
    }

    return chain.then(() => Promise.resolve(domains))
  }

  selectDomain = (domainId) => {
    this.props.autologin(this.props.user.refreshToken, domainId)
  }

  render() {
    const { domains } = this.state
    const { t } = this.props

    return (
      <div className="domains-container">
        <TitleLabel label={t('domain_selector.title')} uppercase />
        <div id="domain_selector_text">{t('domain_selector.text')}</div>

        {domains.map(d => <Domain key={d.domain} data={d} onClick={this.selectDomain} />)}
      </div>
    )
  }
}


function Domain(props) {
  const {
    domain: id,
    splash_url: splash,
    logo_url: logo,
  } = props.data

  const onClick = () => props.onClick(id)

  return (
    <div className="domain-wrapper" style={{ backgroundImage: `url(${splash})` }} onClick={onClick}>
      <div className="logo-wrapper" style={{ backgroundImage: `url(${logo})` }} />
    </div>
  )
}
