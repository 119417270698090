import { styles } from './styles.scss'

import React, { Component } from 'react'
import { appendPropsClassName } from 'common/utils/componentHelper'

import Popover from 'common/components/Popover'

export default class Tooltip extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isPopoverVisible: false,
    }
  }

  onMouseEnter = () => this.setState({ isPopoverVisible: true })

  onMouseLeave = () => this.setState({ isPopoverVisible: false })

  renderIcon = () => (
    <i
      className={appendPropsClassName('fa fa-question-circle-o', this.props, 'iconClassName')}
      onClick={e => e.stopPropagation()}
      onMouseEnter={this.onMouseEnter}
      onMouseLeave={this.onMouseLeave}
      ref={ref => {
        this.refToTriggerElement = ref
      }}
    />
  )

  renderPopover = () => {
    const popoverProps = {
      className: appendPropsClassName('tooltip-popover', this.props, 'popoverClassName'),
      flip: this.props.flip,
      isVisible: this.state.isPopoverVisible,
      referenceElement: this.refToTriggerElement,
      placement: this.props.placement,
      offset: '0, 16px',
    }

    return (
      <Popover {...popoverProps}>{typeof this.props.description === 'string' ? <span>{this.props.description}</span> : this.props.children}</Popover>
    )
  }

  render() {
    return (
      <div className={appendPropsClassName(`Tooltip ${styles}`, this.props)}>
        {this.renderIcon()}
        {this.renderPopover()}
      </div>
    )
  }
}

Tooltip.defaultProps = {
  className: null,
  description: null,
  flip: ['top', 'bottom'],
  iconClassName: null,
  placement: 'top',
  popoverClassName: null,
}
