import { serviceGetCustomFields, serviceCreateCustomField, serviceUpdateCustomField, serviceDeleteCustomField } from 'admin/services/events'
import { showToast } from 'common/modules/toaster'
import { getTranslatedValue } from 'common/utils/translator'
import i18n from 'i18next'
import { listToMapping } from 'common/utils/componentHelper'

import generateModule from 'common/utils/generateModule'

import { createSelector } from 'reselect'
import { eventLanguageSelector } from './events'


const selectState = (state) => state.admin.customFields
const selectCustomFields = (state) => selectState(state).data

export const sortedCustomFieldsSelector = createSelector(
  selectCustomFields,
  eventLanguageSelector,
  (customFields, eventLanguage) => customFields.sort((a, b) => {
    const titleA = getTranslatedValue(a.title, eventLanguage) || a.id
    const titleB = getTranslatedValue(b.title, eventLanguage) || b.id
    return titleA.localeCompare(titleB)
  })
)

export const customFieldsByIdSelector = createSelector(
  selectCustomFields,
  (customFields) => listToMapping(customFields, 'id')
)

export const customFieldsSelector = createSelector(selectState, moduleState => moduleState.data)

export const choiceCustomFieldsSelector = createSelector(customFieldsSelector, customFields => customFields.filter(cf => cf.data_type === 'choice'))
export const sortedChoiceCustomFieldsSelector = createSelector(sortedCustomFieldsSelector, customFields => customFields.filter(cf => cf.data_type === 'choice'))

const { actions, reducers } = generateModule({
  itemName: 'custom_field',
  getModuleState: (getState) => getState().admin.customFields,
  services: {
    get: serviceGetCustomFields,
    add: serviceCreateCustomField,
    upd: serviceUpdateCustomField,
    del: serviceDeleteCustomField,
  },
})

actions.get = () =>
  ((dispatch, getState) => {
    dispatch(actions.getStart())
    return serviceGetCustomFields(
      getState().admin.events.currentEvent.id,
      (response) => {
        dispatch(actions.getSuccess(response))
      },
      (error) => {
        dispatch(actions.getError(status))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('custom_fields.get.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  })


actions.update = (data) =>
  ((dispatch, getState) => {
    dispatch(actions.updateStart(data))
    return serviceUpdateCustomField(
      getState().admin.events.currentEvent.id,
      data,
      (response) => {
        dispatch(actions.updateSuccess())
        return Promise.resolve(response)
      },
      (error) => {
        dispatch(actions.updateError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('custom_fields.update.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
        dispatch(actions.get())
        return Promise.reject(error.description)
      }
    )
  })

actions.add = (data) =>
  ((dispatch, getState) => {
    dispatch({ type: 'ADD_CUSTOM_FIELD_START' })
    return serviceCreateCustomField(
      getState().admin.events.currentEvent.id,
      data,
      (response) => {
        dispatch({ type: 'ADD_CUSTOM_FIELD_SUCCESS', payload: { customField: response } })
        return Promise.resolve(response)
      },
      (error) => {
        dispatch(actions.addError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('custom_fields.create.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
        dispatch(actions.get())
      }
    )
  })

reducers['ADD_CUSTOM_FIELD_START'] = (state) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: true,
})

reducers['ADD_CUSTOM_FIELD_SUCCESS'] = (state, action) => ({ // eslint-disable-line dot-notation
  ...state,
  isModifying: false,
  data: [
    ...state.data,
    action.payload.customField,
  ],
})

actions.delete = (id) =>
  ((dispatch, getState) => {
    dispatch(actions.deleteStart(id))
    return serviceDeleteCustomField(
      getState().admin.events.currentEvent.id,
      id,
      () => {
        dispatch(actions.deleteSuccess())
      },
      (error) => {
        dispatch(actions.deleteError(error.description))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('custom_fields.delete.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  })

export const getCustomFields = actions.get
export const getCustomFieldsIfNeeded = actions.getIfNeeded
export const createCustomField = actions.add
export const updateCustomField = actions.update
export const deleteCustomField = actions.delete

const customFields = reducers.index
export default customFields
