import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceGetBadges(eventId, success, error) {
  return axios.get(`/events/${eventId}/attendees/me/achievements`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetGames(eventId, success, error) {
  return axios.get(`/events/${eventId}/games`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetGameLog(eventId, gameId, success, error) {
  return axios.get(`/events/${eventId}/attendees/me/games/${gameId}/game_logs`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
