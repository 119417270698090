import { generateBaseModule } from 'admin/modules/generateModule'
import { getVenueMaps, mapsSelector, mapsByIdSelector } from 'admin/modules/venueMaps'
import { getInfras, tagsSelector, tagsByIdSelector } from 'admin/modules/infras'
import { getTrackingTagCounts, trackingTagCountsSelector } from 'admin/modules/trackingTagCounts'


const HeatmapModule = generateBaseModule({
  initialState: {
    mapId: undefined,
  },
  moduleKey: 'dashboardHeatmap',
  reducerKey: 'admin.dashboard.heatmap',
})


HeatmapModule.registerSelector(
  'selectedMap',
  [
    (state, moduleState) => moduleState.mapId,
    state => mapsByIdSelector(state),
  ],
  (mapId, mapsById) => mapsById[mapId]
)

HeatmapModule.registerSelector(
  'data',
  [
    state => mapsSelector(state),
    state => mapsByIdSelector(state),
    state => tagsSelector(state),
    state => tagsByIdSelector(state),
    state => trackingTagCountsSelector(state),
    state => HeatmapModule.selectors.selectedMap(state),
  ],
  (maps, mapsById, tags, tagsById, trackingTagCounts, selectedMap) => ({
    maps: maps || [],
    mapsById: mapsById || {},
    selectedMap: selectedMap,
    tags: tags || [],
    tagsById: tagsById || {},
    totalOnSite: trackingTagCounts.reduce((total, trackingTagCount) => {
      if (trackingTagCount && trackingTagCount.count && trackingTagCount.tag) {
        return total + trackingTagCount.count
      }
      return total
    }, 0),
    trackingTagCounts: trackingTagCounts || [],
  })
)


HeatmapModule.registerAction(
  'get',
  () => dispatch => {
    const eventId = HeatmapModule._getEventId()

    return Promise.all([
      dispatch(getVenueMaps()),
      dispatch(getInfras(eventId)),
      dispatch(getTrackingTagCounts(eventId)),
    ])
  }
)


HeatmapModule.registerAction(
  'selectMap',
  mapId => dispatch => {
    dispatch({ type: HeatmapModule.actionKeys.selectMap, payload: mapId })
    return Promise.resolve()
  }
)

HeatmapModule.registerReducer(
  HeatmapModule.actionKeys.selectMap,
  (state, action) => ({ ...state, mapId: action.payload })
)


export default HeatmapModule
