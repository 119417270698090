import axios from 'axios'
import { twilioServiceUrl } from 'config'
import { apiErrorHandler, apiCatchError, buildError } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceAddConversationMessage(eventId, conversationId, message, success, error) {
  return axios.post(`events/${eventId}/conversations/${conversationId}/messages`, { body: message }, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetMeetingRecommendations(eventId, query, lastProfileId, success, error) {
  let queryString = '?'

  if (query.tags) {
    queryString += `&tags=${query.tags.join(',')}`
  }

  if (query.query) {
    queryString += `&query=${query.query}&include_self=true`
  }

  if (query.customFields) {
    const customFieldStrings = []

    Object.keys(query.customFields).forEach(key => {
      customFieldStrings.push(`${key}:${query.customFields[key]}`)
    })

    queryString += `&filter=${customFieldStrings.join(',')}`
  }

  if (lastProfileId) {
    queryString += `&offset=${lastProfileId}`
  }

  return axios.get(`events/${eventId}/attendees/me/recommendations/meetings${queryString}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceIgnoreMeetingRecommendation(eventId, targetId, success, error) {
  return axios.delete(`events/${eventId}/attendees/me/recommendations/meetings/${targetId}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetConversation(eventId, conversationId, success, error) {
  return axios.get(`events/${eventId}/conversations/${conversationId}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetConversationMessages(eventId, conversationId, success, error) {
  return axios.get(`events/${eventId}/conversations/${conversationId}/messages`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}


export function serviceGetReceivedConversationInvitations(eventId, success, error) {
  return axios.get(`events/${eventId}/attendees/me/invitations/conversation?all=true`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetSentConversationInvitations(eventId, success, error) {
  return axios.get(`events/${eventId}/attendees/me/invitations/conversation/sent?all=true`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceSendConversationInvitation(eventId, accountId, message, initiatedFrom, success, error) {
  const payload = {
    introduction: message,
    initiated_from: initiatedFrom,
  }


  return axios.post(`events/${eventId}/attendees/${accountId}/invitations/conversation`, payload, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceSendVideoInvitation(eventId, accountId, initiatedFrom, success, error) {
  const payload = {
    initiated_from: initiatedFrom,
  }

  return axios.post(`events/${eventId}/attendees/${accountId}/invitations/video_call`, payload, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceAcceptConversationInvitation(eventId, conversationId, success, error) {
  return axios.post(`events/${eventId}/attendees/me/invitations/conversation/${conversationId}/accept`, {}, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceDeclineConversationInvitation(eventId, conversationId, success, error) {
  return axios.post(`events/${eventId}/attendees/me/invitations/conversation/${conversationId}/ignore`, {}, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetBusyCalendar(eventId, accountId, success, error) {
  return axios.get(`events/${eventId}/attendees/${accountId}/calendar/busy`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceSendMeetingInvitation(eventId, accountId, data, success, error) {
  return axios.post(`events/${eventId}/attendees/${accountId}/invitations/meeting`, data, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetReceivedMeetingInvitations(eventId, success, error) {
  return axios.get(`events/${eventId}/attendees/me/invitations/meeting?all=true`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetSentMeetingInvitations(eventId, success, error) {
  return axios.get(`events/${eventId}/attendees/me/invitations/meeting/sent?all=true`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceReplyToMeetingInvitation(eventId, invitationId, meetingData, success, error) {
  return axios.post(`events/${eventId}/attendees/me/invitations/meeting/${invitationId}/reply`, meetingData, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceAcceptMeetingInvitation(eventId, invitationId, meetingId, success, error) {
  return axios.post(`events/${eventId}/attendees/me/invitations/meeting/${invitationId}/accept`, { accepted_meeting: meetingId }, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceCancelMeetingInvitation(eventId, invitationId, success, error) {
  return axios.post(`events/${eventId}/attendees/me/invitations/meeting/${invitationId}/reject`, {}, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceIgnoreConversationInvitation(eventId, invitationId, success, error) {
  return axios.post(`events/${eventId}/attendees/me/invitations/conversation/${invitationId}/ignore`, {}, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUnignoreConversationInvitation(eventId, invitationId, success, error) {
  return axios.delete(`events/${eventId}/attendees/me/invitations/conversation/${invitationId}/ignore`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetVideoChatToken(eventId, conversationInvitationId, displayName) {
  const axiosConfig = getAxiosConfig()
  axiosConfig.baseURL = twilioServiceUrl

  return axios.post(`/twilio/token/video?identity=${displayName}&room=${conversationInvitationId}`, {}, axiosConfig)
    .then(response => response.data.access_token)
    .catch(error => Promise.reject(buildError(error)))
}
