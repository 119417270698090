
import { DataModule } from 'redux-data-module'
import { serviceGetTouchpoints, serviceGetTouchpoint, serviceUpdateTouchpoint } from 'admin/services/exhibitors'
import { updateStateData } from 'common/modules'
import search, { buildFieldList } from 'common/utils/search'
import sort from 'common/utils/sortHelper'
import { getTranslatedValue } from 'common/utils/translator'


function getEventId(getState) {
  return getState().exhibitor.exhibitors.currentExhibitor.event_id
}

function getExhibitorId(getState) {
  return getState().exhibitor.exhibitors.currentExhibitor.id
}

const TouchpointsModule = new DataModule({
  moduleKey: 'cpe/touchpoints',
  reducerKey: 'exhibitor.touchpoints',

  services: {
    get: { service: serviceGetTouchpoints },
  },
});

TouchpointsModule.initialState.currentTouchpointId = null;
TouchpointsModule.initialState.listFilters = {
  searchTerm: '',
  sortId: 'name',
  sortOrder: 1,
}


TouchpointsModule.registerSelector('filters', (state, moduleState) => moduleState.listFilters, filters => filters)

TouchpointsModule.registerSelector(
  'currentTouchpoint',
  [
    (state) => TouchpointsModule.selectors.dataById(state),
    (state, moduleState) => moduleState.currentTouchpointId,
  ],
  (touchpoints, touchpointId) => touchpoints[touchpointId]
)

TouchpointsModule.registerSelector(
  'filteredTouchpoints',
  [
    (state) => TouchpointsModule.selectors.data(state),
    (state, moduleState) => moduleState.listFilters,
  ],
  (touchpoints, filters) => {
    let filteredTouchpoints = touchpoints.filter(touchpoint => search(filters.searchTerm, buildFieldList(touchpoint, ['name'])))
    filteredTouchpoints = sort(
      filteredTouchpoints,
      [
        { id: filters.sortId, order: filters.sortOrder },
        { id: 'num_kliks', order: -1 },
        { id: 'name', order: 1 },
        { id: 'id', order: 1 },
      ]
    )
    return filteredTouchpoints
  }
)

TouchpointsModule.registerSelector(
  'sortTouchpoints',
  (state) => TouchpointsModule.selectors.data(state),
  touchpoints => touchpoints.sort((a, b) => (a.num_kliks !== b.num_kliks
    ? b.num_kliks - a.num_kliks
    : getTranslatedValue(a.name).localeCompare(getTranslatedValue(b.name))
  ))
)


TouchpointsModule.registerAction('setFilters', filters => dispatch => dispatch({ type: TouchpointsModule.actionKeys.setFilters, payload: filters }))
TouchpointsModule.registerReducer(TouchpointsModule.actionKeys.setFilters, (state, action) => ({
  ...state,
  listFilters: action.payload == null ? TouchpointsModule.initialState.listFilters : {
    ...state.listFilters,
    ...action.payload,
  },
}))


TouchpointsModule.actions.get = () => (dispatch, getState) => {
  dispatch({ type: TouchpointsModule.actionKeys.getStart })
  return serviceGetTouchpoints(getEventId(getState), getExhibitorId(getState))
    .then((response) => dispatch({ type: TouchpointsModule.actionKeys.getSuccess, payload: response }))
    .catch((error) => {
      dispatch({ type: TouchpointsModule.actionKeys.getError })
      return Promise.reject(error)
    })
}


TouchpointsModule.registerDataAction('getTouchpoint', touchpointId => (dispatch, getState) => {
  dispatch({ type: TouchpointsModule.actionKeys.getTouchpointStart })

  return serviceGetTouchpoint(getEventId(getState), getExhibitorId(getState), touchpointId)
    .then((response) => {
      dispatch({ type: TouchpointsModule.actionKeys.getTouchpointSuccess, payload: response })
    })
    .catch((error) => {
      dispatch({ type: TouchpointsModule.actionKeys.getTouchpointError })
      return Promise.reject(error)
    })
})

TouchpointsModule.registerReducer(TouchpointsModule.actionKeys.getTouchpointStart, state => ({
  ...state,
  isLoading: true,
}))
TouchpointsModule.registerReducer(TouchpointsModule.actionKeys.getTouchpointSuccess, (state, action) => ({
  ...state,
  currentTouchpointId: action.payload.id,
  data: updateStateData(state.data, action.payload),
  isLoading: false,
  isLoaded: true,
}))
TouchpointsModule.registerReducer(TouchpointsModule.actionKeys.getTouchpointError, state => ({
  ...state,
  isLoading: false,
  isError: true,
}))


TouchpointsModule.registerDataAction('updateTouchpoint', data => (dispatch, getState) => {
  dispatch({ type: TouchpointsModule.actionKeys.updateTouchpointStart })

  return serviceUpdateTouchpoint(getEventId(getState), getExhibitorId(getState), data)
    .then((response) => {
      dispatch({ type: TouchpointsModule.actionKeys.updateTouchpointSuccess, payload: response })
    })
    .catch((error) => {
      dispatch({ type: TouchpointsModule.actionKeys.updateTouchpointError })
      return Promise.reject(error)
    })
})

TouchpointsModule.registerReducer(TouchpointsModule.actionKeys.updateTouchpointStart, state => ({
  ...state,
  isModifying: true,
  didInvalidate: false,
}))
TouchpointsModule.registerReducer(TouchpointsModule.actionKeys.updateTouchpointSuccess, (state, action) => ({
  ...state,
  data: updateStateData(state.data, action.payload),
  isModifying: false,
}))
TouchpointsModule.registerReducer(TouchpointsModule.actionKeys.updateTouchpointError, state => ({
  ...state,
  isModifying: false,
  didInvalidate: true,
}))


export default TouchpointsModule
