import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetHeatmapData } from 'admin/services/bluetrace'
import Moment from 'moment'

import { eventTimezoneSelector } from 'admin/modules/events'
import TrackingFiltersModule from './filters'


const HeatmapModule = generateDataModule({
  initialData: [],
  moduleKey: 'cpo/bluetrace/trackingHeatmap',
  reducerKey: 'admin.bluetrace.tracking.heatmap',
  refreshTime: 10000,
  services: {
    get: { service: serviceGetHeatmapData },
  },
})


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

HeatmapModule.registerSelector(
  'data',
  (state, moduleState) => moduleState.data,
  data => data.map(d => ({
    date: d.date,
    value: Math.round(d.count),
  }))
)

HeatmapModule.registerSelector(
  'tagCounts',
  [
    (state, moduleState) => moduleState.data,
    state => TrackingFiltersModule.selectors.timeSelector(state),
  ],
  (overtimeData, timeSelector) => {
    if (overtimeData.length === 0) {
      return []
    }

    const earlierData = overtimeData.filter(data => data.date <= timeSelector.head)
    return earlierData.length > 0 ? earlierData[earlierData.length - 1].tags : overtimeData[0].tags
  }
)

HeatmapModule.registerSelector(
  'maxTagCount',
  [
    (state, moduleState) => moduleState.data,
    state => TrackingFiltersModule.selectors.map(state),
  ],
  (overtimeData, mapId) => Math.max(...overtimeData.map(data => Math.max(...data.tags.filter(tag => tag.pos && tag.pos.map === mapId).map(t => t.count))))
)

HeatmapModule.registerSelector(
  'heatmapData',
  [
    state => HeatmapModule.selectors.tagCounts(state),
    state => TrackingFiltersModule.selectors.map(state),
  ],
  (tagCounts, mapId) => tagCounts.filter(tag => tag.pos && tag.pos.map === mapId)
    .map(tag => ({ x: tag.pos.x, y: tag.pos.y, value: tag.count }))
)

HeatmapModule.registerSelector(
  'attendance',
  state => HeatmapModule.selectors.tagCounts(state),
  data => Math.round(data.reduce((acc, current) => acc + current.count, 0))
)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////

HeatmapModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {
    const state = getState()
    const timezone = eventTimezoneSelector(state)
    const dateMoment = Moment.unix(TrackingFiltersModule.selectors.date(state)).tz(timezone)

    const queryParams = {
      group: TrackingFiltersModule.selectors.attendeeType(state),
      start_date: dateMoment.startOf('day').unix(),
      end_date: dateMoment.endOf('day').unix(),
    }

    dispatch({ type: HeatmapModule.actionKeys.getStart })

    return serviceGetHeatmapData(HeatmapModule._getEventId(), queryParams)
      .then(response => {
        dispatch({
          type: HeatmapModule.actionKeys.getSuccess,
          payload: response,
        })
      })
      .catch(() => {
        dispatch({ type: HeatmapModule.actionKeys.getError })
        return Promise.reject()
      })
  }
)


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////


export default HeatmapModule
