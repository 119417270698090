// TODO WIP: This module is not ready to be used yet. This is a simple
//           implementation of the filters module that will be used for
//           the dashboard redesign. It needs to handle initialization
//           a bit better than currently.


import { BaseModule } from 'redux-data-module'
import { customFieldsByIdSelector } from 'admin/modules/customFields'


const FiltersModule = new BaseModule({
  moduleKey: 'dashboardFilters',
  reducerKey: 'admin.dashboard.filters',
})

FiltersModule._ATTENDEE_TYPE_FIELD = 'group'

FiltersModule.initialState = {
  [FiltersModule._ATTENDEE_TYPE_FIELD]: undefined,
}


FiltersModule.registerSelector(
  'filters',
  (state, moduleState) => moduleState,
  filters => filters
)


/**
 * Call to initialize with the current loaded custom fields (from state). It will reset every
 * filters back to their initial values.
 */
FiltersModule.registerAction(
  'initialize',
  () => (dispatch, getState) => {
    const initialValues = { [FiltersModule._ATTENDEE_TYPE_FIELD]: undefined }
    Object.keys(customFieldsByIdSelector(getState())).forEach(customFieldId => {
      initialValues[customFieldId] = undefined
    })

    dispatch({
      type: FiltersModule.actionKeys.initialize,
      payload: initialValues,
    })

    return Promise.resolve()
  }
)

FiltersModule.registerReducer(
  FiltersModule.actionKeys.initialize,
  (state, action) => action.payload
)


/**
 * Set a subset of filters. Pass an object ({<filter key>: <filter value>}) and only those
 * will be changed in the state.
 *
 * @param {Object} filters - Filters to set
 */
FiltersModule.registerAction(
  'set',
  filters => dispatch => {
    dispatch({
      type: FiltersModule.actionKeys.set,
      payload: filters,
    })

    return Promise.resolve()
  }
)

FiltersModule.registerReducer(
  FiltersModule.actionKeys.set,
  (state, action) => ({
    ...state,
    ...action.payload,
  })
)


export default FiltersModule
