import React, { Component } from 'react'
import { translate } from 'react-i18next'
import { checkForUpdate, EventTypes, requestContentUpdate } from '../../../../serviceWorker'

import { styles } from './styles.scss'


@translate(['common_customModule'])
export default class VersionDetector extends Component {
  constructor(props) {
    super(props)
    this.intervalTime = 1000 * 60 * 5

    this.state = {
      visible: false,
    }
  }

  componentDidMount() {
    this.startInterval()
    checkForUpdate()

    window.addEventListener(EventTypes.updateFound, this.onUpdateReady)
  }

  componentWillUpdate(nextProps, nextState) {
    if (!this.state.visible && nextState.visible) {
      this.stopInterval()
    } else if (this.state.visible && !nextState.visible) {
      this.startInterval()
    }
  }

  componentWillUnmount() {
    this.stopInterval()
    window.removeEventListener(EventTypes.updateFound, this.onUpdateReady)
  }

  startInterval = () => {
    this.interval = setInterval(() => {
      if (!this.state.visible && navigator.onLine) {
        checkForUpdate()
      }
    }, this.intervalTime)
  }

  stopInterval = () => {
    clearInterval(this.interval)
  }

  onUpdateReady = () => {
    this.setState({ visible: true })
  }

  onDismiss = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ visible: false })
  }

  onClickBanner = () => {
    requestContentUpdate()
  }

  render() {
    const { visible } = this.state
    const { t } = this.props

    return (
      <div className={`VersionDetector ${styles}`} data-visible={visible} onClick={this.onClickBanner}>
        <div className="klik-icon-wrapper">
          <img role="presentation" src="/assets/images/klik_icon.png" />
        </div>

        <div className="information-text-wrapper">
          <div>{t('version_detector.0')}</div>
          <div>{t('version_detector.1')}</div>
        </div>

        <div className="dismiss-button-wrapper" onClick={this.onDismiss}>
          <i className="fa fa-times" />
        </div>
      </div>
    )
  }
}
