import { DataModule } from 'redux-data-module'
import { serviceGetInfoPopups, servicePutInfoPopup, servicePostInfoPopup } from 'admin/services/infoPopups'

import { currentEventIdSelector } from 'admin/modules/events'
import { showToast } from 'common/modules/toaster'


const InfoPopupsModule = new DataModule({
  moduleKey: 'cpo/infoPopups',
  reducerKey: 'admin.infoPopups',

  services: {
    get: { service: serviceGetInfoPopups },
    put: { service: servicePutInfoPopup },
    post: { service: servicePostInfoPopup },
  },
})


const registerPopupSelector = (selectorId, popupType) => {
  InfoPopupsModule.registerSelector(
    selectorId,
    state => InfoPopupsModule.selectors.data(state),
    data => data.find(d => d.type === popupType)
  )
}
registerPopupSelector('gamificationPopup', 'gamification')
registerPopupSelector('recommendationsPopup', 'networking')
registerPopupSelector('topicsPopup', 'networking_topics')


InfoPopupsModule.actions.get = () => (dispatch, getState) => {
  dispatch({ type: InfoPopupsModule.actionKeys.getStart })

  return serviceGetInfoPopups(currentEventIdSelector(getState()))
    .then(response => {
      dispatch({ type: InfoPopupsModule.actionKeys.getSuccess, payload: response })
    })
    .catch(error => {
      dispatch({ type: InfoPopupsModule.actionKeys.getError })
      dispatch(showToast({
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    })
}

InfoPopupsModule.actions.post = data => (dispatch, getState) => {
  dispatch({ type: InfoPopupsModule.actionKeys.postStart })

  return servicePostInfoPopup(currentEventIdSelector(getState()), data)
    .then(response => {
      dispatch({ type: InfoPopupsModule.actionKeys.postSuccess, payload: response })
    })
    .catch(error => {
      dispatch({ type: InfoPopupsModule.actionKeys.postError })
      dispatch(showToast({
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    })
}

InfoPopupsModule.actions.put = data => (dispatch, getState) => {
  dispatch({ type: InfoPopupsModule.actionKeys.putStart })

  return servicePutInfoPopup(currentEventIdSelector(getState()), data)
    .then(response => {
      dispatch({ type: InfoPopupsModule.actionKeys.putSuccess, payload: response })
    })
    .catch(error => {
      dispatch({ type: InfoPopupsModule.actionKeys.putError })
      dispatch(showToast({
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject()
    })
}


export default InfoPopupsModule
