import ReactGA from 'react-ga'


const Dimensions = {
  sessionId: 'dimension2',
  touchpointId: 'dimension3',
  exhibitorId: 'dimension4',
  attendeeId: 'dimension5',
  callToActionUrl: 'dimension6',
}


export function clickSessionCallToAction(session, callToAction) {
  ReactGA.event({
    category: 'Interaction',
    action: 'Click session call-to-action',
    label: callToAction.label,
    [Dimensions.sessionId]: session.id,
    [Dimensions.callToActionUrl]: callToAction.http_url || callToAction.url,
  })
}

export function clickTouchpointCallToAction(touchpoint, callToAction) {
  ReactGA.event({
    category: 'Interaction',
    action: 'Click touchpoint call-to-action',
    label: callToAction.label,
    [Dimensions.touchpointId]: touchpoint.id,
    [Dimensions.callToActionUrl]: callToAction.http_url || callToAction.url,
  })
}

export function clickExhibitorCallToAction(exhibitor, callToAction) {
  ReactGA.event({
    category: 'Interaction',
    action: 'Click exhibitor call-to-action',
    label: callToAction.label,
    [Dimensions.exhibitorId]: exhibitor.id,
    [Dimensions.callToActionUrl]: callToAction.http_url || callToAction.url,
  })
}

export function exportAllContacts(eventTitle) {
  ReactGA.event({
    category: 'Interaction',
    action: 'Export all contacts',
    label: eventTitle,
  })
}

export function clickExportContact() {
  ReactGA.event({
    category: 'Interaction',
    action: 'Export contact',
    label: 'vCard',
  })
}

export function expandTimelineCard(activityType) {
  ReactGA.event({
    category: 'Interaction',
    action: 'Expand timeline card',
    label: activityType,
  })
}

export function clickNotification(notification) {
  ReactGA.event({
    category: 'Interaction',
    action: 'Click notification',
    label: notification.short_text,
  })
}

export function clickInstallAppBanner(appOS) {
  ReactGA.event({
    category: 'Interaction',
    action: 'Click install app banner',
    label: appOS,
  })
}

export function openScheduleAdvancedFilters() {
  ReactGA.event({
    category: 'Interaction',
    action: 'Opened advanced filters',
    label: 'Schedule',
  })
}
