export function showToast(payload) {
  return {
    type: 'ADD_TOAST',
    payload: payload,
  }
}

export function deleteToast(index) {
  return {
    type: 'DELETE_TOAST',
    data: {
      index: index,
    },
  }
}

export function deleteAllToasts() {
  return {
    type: 'DELETE_ALL_TOASTS',
  }
}


const initialState = {
  toasts: [],
  lastUpdated: 0,
}

/* Returns the index of a toast that has :
*   - The same title
*   - The same message
*/
function findIndexOfIdenticalToast(allToasts, newToast) {
  return allToasts.findIndex((toast) => toast.title === newToast.title && toast.message === newToast.message)
}

export default function toaster(state = initialState, action) {
  switch (action.type) {

    case 'ADD_TOAST': {
      const toasts = state.toasts
      const toast = action.payload
      toast.timeout = 5
      toast.showTimeBar = true
      toast.updatedAt = Date.now()

      const toastIndex = findIndexOfIdenticalToast(toasts, toast)

      if (toastIndex !== -1) {
        toasts[toastIndex].timeout = 5
        toasts[toastIndex].updatedAt = toast.updatedAt
      } else {
        toasts.push(toast)
      }

      return {
        ...state,
        toasts: toasts,
        transitionName: 'toaster',
        lastUpdated: Date.now(),
      }
    }

    case 'DELETE_TOAST': {
      const toasts = state.toasts
      toasts.splice(action.data.index, 1)
      return {
        ...state,
        toasts: toasts,
        lastUpdated: Date.now(),
      }
    }

    case 'DELETE_ALL_TOASTS': {
      const persistedToasts = state.toasts.filter((toast) => toast.persistOnPageChange !== undefined && toast.persistOnPageChange)
      return {
        ...state,
        toasts: persistedToasts,
        transitionName: 'instant',
        lastUpdated: Date.now(),
      }
    }

    case 'LOGOUT':
      return initialState

    default:
      return state
  }
}
