import { serviceGetBadgePrintingConfig, serviceUpdateBadgePrintingConfig, serviceGetBadgePrintingPreview } from 'admin/services/badgePrinting'
import generateModule from 'common/utils/generateModule'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'
import Moment from 'moment'

function getEventId(getState) {
  return getState().admin.events.currentEvent.id
}

const { actions, reducers, initialState } = generateModule({
  itemName: 'badgePrinting',
  idFieldName: 'id',
  services: {},
})

initialState.preview = {
  requestedAt: 0,
  isLoading: false,
  isLoaded: false,
  data: {
    fields: [],
  },
}


actions.getBadgePrintingConfig = () =>
  (dispatch, getState) => {
    dispatch({ type: 'GET_BADGE_PRINTING_CONFIG_START' })
    return serviceGetBadgePrintingConfig(
      getEventId(getState),
      response => dispatch({ type: 'GET_BADGE_PRINTING_CONFIG_SUCCESS', payload: { ...response } }),
      () => dispatch({ type: 'GET_BADGE_PRINTING_CONFIG_ERROR' })
    )
  }

reducers['GET_BADGE_PRINTING_CONFIG_START'] = (state) => ({
  ...state,
  isLoading: true,
  isError: false,
})

reducers['GET_BADGE_PRINTING_CONFIG_SUCCESS'] = (state, action) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  data: { ...action.payload },
})

reducers['GET_BADGE_PRINTING_CONFIG_ERROR'] = (state) => ({
  ...state,
  isError: true,
})


actions.updateBadgePrintingConfig = (data) =>
  (dispatch, getState) => {
    dispatch({ type: 'UPDATE_BADGE_PRINTING_START' })
    return serviceUpdateBadgePrintingConfig(
      getEventId(getState),
      data,
      response => {
        dispatch({ type: 'UPDATE_BADGE_PRINTING_SUCCESS', payload: { ...response } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('badge_printing.update.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('badge_printing.update.success'),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch({ type: 'UPDATE_BADGE_PRINTING_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule', 'badge_printing.update.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['UPDATE_BADGE_PRINTING_START'] = (state) => ({
  ...state,
  isModifying: true,
})

reducers['UPDATE_BADGE_PRINTING_SUCCESS'] = (state, action) => ({
  ...state,
  isModifying: false,
  data: { ...action.payload },
})

reducers['UPDATE_BADGE_PRINTING_ERROR'] = (state) => ({
  ...state,
  isModifying: false,
  isError: true,
})


actions.getBadgePrintingPreview = (config) =>
  (dispatch, getState) => {
    const requestedAt = Moment().unix()

    dispatch({ type: 'GET_BADGE_PRINTING_PREVIEW_START', payload: { requestedAt } })
    return serviceGetBadgePrintingPreview(
      getEventId(getState),
      config,
      response => {
        if (requestedAt >= getState().admin.badgePrinting.preview.requestedAt) {
          dispatch({ type: 'GET_BADGE_PRINTING_PREVIEW_SUCCESS', payload: { data: response, requestedAt } })
        }

        return Promise.resolve()
      },
      () => dispatch({ type: 'GET_BADGE_PRINTING_PREVIEW_ERROR' })
    )
  }

reducers['GET_BADGE_PRINTING_PREVIEW_START'] = (state, action) => ({
  ...state,
  preview: {
    ...state.preview,
    requestedAt: action.payload.requestedAt,
    isLoading: true,
  },
})

reducers['GET_BADGE_PRINTING_PREVIEW_SUCCESS'] = (state, action) => ({
  ...state,
  preview: {
    ...state.preview,
    isLoading: false,
    isLoaded: true,
    data: action.payload.data ? { ...action.payload.data } : state.data,
    requestedAt: action.payload.requestedAt,
  },
})

reducers['GET_BADGE_PRINTING_PREVIEW_ERROR'] = (state) => ({
  ...state,
  preview: { ...initialState.preview },
})


export const getBadgePrintingConfig = actions.getBadgePrintingConfig
export const updateBadgePrintingConfig = actions.updateBadgePrintingConfig
export const getBadgePrintingPreview = actions.getBadgePrintingPreview

const reducer = reducers.index
export default reducer
