import { showToast } from 'common/modules/toaster'
import { pulseOfflineNotificationOr } from 'common/modules/offline'
import { isPageVisible } from 'common/utils/domUtils'
import i18n from 'i18next'
import { REHYDRATE } from 'redux-persist/constants'
import { getTranslatedValue } from 'common/utils/translator'
import { handleErrorMessage } from '../../attendee/modules';

export function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1)
}

// Configuration object format:
// {
//   itemName: 'zone',
//   itemNameDisplay: '',  // to be displayed in toasts, messages, etc.
//   idFieldName: 'name',
//   toasterFieldName: 'display_name',
//   getModuleState: (getState) => getState().name_of_module.nested_module // Returns the state for the module
//   getEventId: (getState) => getState().module.event.id
//   dataIsNotArray: false,
//   app: 'attendee'
//   autoRefresh: 60 // seconds
//   invalidateOnUpdate: false,
//   services: {
//     get: serviceGetZones,
//     add: serviceAddZone,
//     upd: serviceUpdateZone,
//     del: serviceDeleteZone,
//     mod: modifyCustomField,
//   },
// }

export default function generateModule(cfg) {
  const itemNameUpper = cfg.itemName.toUpperCase()
  const itemNameDisplay = cfg.itemNameDisplay ? cfg.itemNameDisplay : cfg.itemName
  const itemNameCap = cfg.itemNameDisplay ? capitalize(cfg.itemNameDisplay) : capitalize(cfg.itemName)
  const itemNameMessage = cfg.itemNameMessage || cfg.itemNameDisplay || cfg.itemName
  const idFieldName = cfg.idFieldName || 'id'
  const toasterFieldName = cfg.toasterFieldName || idFieldName
  const dataIsNotArray = cfg.dataIsNotArray || false
  const app = cfg.app || 'admin'
  const invalidateOnUpdate = cfg.invalidateOnUpdate || false
  const optimisticUpdate = cfg.optimisticUpdate !== undefined ? cfg.optimisticUpdate : true
  const getEventId = cfg.getEventId || ((getState) => ((app === 'admin') ? getState().admin.events.currentEvent.id : getState().attendee.attEvents.currEvent.id))

  const actions = {}
  const reducers = {}
  let initialState = cfg.initialState || { // eslint-disable-line prefer-const
    isFetching: false,
    isModifying: false,
    isError: false,
    isLoaded: false,
    isRehydrated: false,
    didInvalidate: true,
    lastUpdated: 0,
    lastOperationStatus: '',
    data: (dataIsNotArray) ? {} : [],
  }


  /* /--------------------/ GET /--------------------/ */

  if (cfg.services.get) {
    actions.getStart = () => ({
      type: `GET_${itemNameUpper}S_START`,
    })

    actions.getSuccess = (data, status) => ({
      type: `GET_${itemNameUpper}S_SUCCESS`,
      payload: {
        data: data,
        status: status,
        receivedAt: Date.now(),
      },
    })

    actions.getError = () => ({
      type: `GET_${itemNameUpper}S_ERROR`,
      payload: {
        status: status,
        receivedAt: Date.now(),
      },
    })

    actions.get = () =>
      (dispatch, getState) => {
        dispatch(actions.getStart())
        return cfg.services.get(
          getEventId(getState),
          (result) => {
            dispatch(actions.getSuccess(result, i18n.getFixedT(i18n.language)('common_module:get.success', { itemName: capitalize(itemNameMessage) })))
          },
          (error) => {
            const message = i18n.getFixedT(i18n.language)('common_module:get.error', { itemName: itemNameDisplay, error: error.description })
            dispatch(actions.getError(message))

            if (!getState().offlineNotification.online) {
              return
            }

            if (cfg.app === 'attendee') {
              handleErrorMessage(dispatch, getState, error)
            } else {
              dispatch(showToast({
                title: i18n.getFixedT(i18n.language)('common_module:get.title', { itemName: itemNameDisplay }),
                message: error.description,
                level: 'error',
                permanent: false,
                titleOffline: null,
              }))
            }
          }
        )
      }
    actions[`get${itemNameCap}${cfg.dataIsNotArray ? '' : 's'}`] = actions.get

    actions.getIfNeeded = (...arg) =>
      (dispatch, getState) => { // eslint-disable-line consistent-return
        let shouldFetch = false
        const data = cfg.getModuleState
          ? cfg.getModuleState(getState)
          : getState()[app][`${cfg.itemName}${cfg.dataIsNotArray ? '' : 's'}`]

        if (data.isFetching || data.isModifying) {
          shouldFetch = false
        } else if (!data) {
          shouldFetch = true
        } else if (isPageVisible() && cfg.autoRefresh && ((data.lastUpdated < Date.now() - ((cfg.autoRefresh - 1) * 1000)) || data.isRehydrated)) {
          shouldFetch = true
        } else {
          shouldFetch = data.didInvalidate
        }
        if (shouldFetch) {
          return dispatch(actions.get(...arg))
        }
        return Promise.resolve()
      }
    actions[`get${itemNameCap}${cfg.dataIsNotArray ? '' : 's'}IfNeeded`] = actions.getIfNeeded


    reducers[`GET_${itemNameUpper}S_START`] = (state, action) => ({ // eslint-disable-line no-unused-vars
      ...state,
      isFetching: true,
      didInvalidate: false,
      lastOperationStatus: i18n.getFixedT(i18n.language)('common_module:get.start', { itemName: capitalize(itemNameMessage) }),
    })
    reducers.getStart = reducers[`GET_${itemNameUpper}S_START`]

    reducers[`GET_${itemNameUpper}S_SUCCESS`] = (state, action) => ({
      ...state,
      data: action.payload.data,
      isFetching: false,
      isError: false,
      isLoaded: true,
      didInvalidate: false,
      lastUpdated: action.payload.receivedAt,
      lastOperationStatus: action.payload.status,
    })
    reducers.getSuccess = reducers[`GET_${itemNameUpper}S_SUCCESS`]

    reducers[`GET_${itemNameUpper}S_ERROR`] = (state, action) => ({
      ...state,
      isFetching: false,
      isError: true,
      didInvalidate: false,
      lastOperationStatus: action.payload.status,
    })
    reducers.getError = reducers[`GET_${itemNameUpper}S_ERROR`]
  }

  /* /--------------------/ ADD /--------------------/ */

  if (cfg.services.add) {
    actions.addStart = (data) => ({
      type: `ADD_${itemNameUpper}_START`,
      payload: {
        data: data,
      },
    })

    actions.addSuccess = (data, status) => ({
      type: `ADD_${itemNameUpper}_SUCCESS`,
      payload: {
        data: data,
        status: status,
        receivedAt: Date.now(),
      },
    })

    actions.addError = (data, status) => ({
      type: `ADD_${itemNameUpper}_ERROR`,
      payload: {
        data: data,
        status: status,
        receivedAt: Date.now(),
      },
    })

    actions.add = (data) =>
      (dispatch, getState) => {
        dispatch(actions.addStart(data))
        return cfg.services.add(
          getEventId(getState),
          data,
          (newData) => {
            const message = i18n.getFixedT(i18n.language)('common_module:add.success', { itemName: capitalize(itemNameMessage), id: getTranslatedValue(newData[toasterFieldName]) })
            dispatch(actions.addSuccess(newData, message))
            dispatch(showToast({
              title: i18n.getFixedT(i18n.language)('common_module:add.title', { itemName: itemNameDisplay }),
              message: message,
              level: 'success',
              permanent: false,
            }))
            dispatch(actions.get())
          },
          (error) => {
            dispatch(actions.addError(error.description))

            if (cfg.app === 'attendee') {
              handleErrorMessage(dispatch, getState, error)
            } else {
              pulseOfflineNotificationOr(dispatch, getState, error, () => showToast({
                title: i18n.getFixedT(i18n.language)('common_module:add.title'),
                message: error.description,
                level: 'error',
                permanent: false,
              }))
            }

            dispatch(actions.get())
          }
        )
      }
    actions[`add${itemNameCap}`] = actions.add


    reducers[`ADD_${itemNameUpper}_START`] = (state, action) => ({
      ...state,
      isModifying: true,
      didInvalidate: false,
      data: [
        ...state.data,
        action.payload.data,
      ],
      lastOperationStatus: action.payload.status,
    })
    reducers.addStart = reducers[`ADD_${itemNameUpper}_START`]

    reducers[`ADD_${itemNameUpper}_SUCCESS`] = (state, action) => ({
      ...state,
      isModifying: false,
      didInvalidate: false,
      lastOperationStatus: action.payload.status,
    })
    reducers.addSuccess = [`ADD_${itemNameUpper}_SUCCESS`]

    reducers[`ADD_${itemNameUpper}_ERROR`] = (state, action) => ({
      ...state,
      isModifying: false,
      didInvalidate: true,
      lastOperationStatus: action.payload.status,
    })
    reducers.addError = reducers[`ADD_${itemNameUpper}_ERROR`]
  }


  /* /--------------------/ UPDATE /--------------------/ */

  if (cfg.services.upd) {
    actions.updateStart = (data) => ({
      type: `UPDATE_${itemNameUpper}_START`,
      payload: {
        data: data,
      },
    })

    actions.updateSuccess = (data, status) => ({
      type: `UPDATE_${itemNameUpper}_SUCCESS`,
      payload: {
        data: data,
        status: status,
        receivedAt: Date.now(),
      },
    })

    actions.updateError = (data, status) => ({
      type: `UPDATE_${itemNameUpper}_ERROR`,
      payload: {
        data: data,
        status: status,
        receivedAt: Date.now(),
      },
    })

    actions.update = (data, refresh) =>
      (dispatch, getState) => {
        dispatch(actions.updateStart(data))
        return cfg.services.upd(
          getEventId(getState),
          data,
          (newData) => {
            const message = i18n.getFixedT(i18n.language)('common_module:update.success', { itemName: capitalize(itemNameMessage), id: getTranslatedValue(newData[toasterFieldName]) })
            dispatch(actions.updateSuccess(newData, message))
            dispatch(showToast({
              title: i18n.getFixedT(i18n.language)('common_module:update.title'),
              message: message,
              level: 'success',
              permanent: false,
            }))

            if (refresh) {
              dispatch(actions.get())
            }
          },
          (error) => {
            dispatch(actions.updateError(error.description))

            if (cfg.app === 'attendee') {
              handleErrorMessage(dispatch, getState, error)
            } else {
              pulseOfflineNotificationOr(dispatch, getState, error, () => showToast({
                title: i18n.getFixedT(i18n.language)('common_module:update.title'),
                message: error.description,
                level: 'error',
                permanent: false,
              }))
            }

            dispatch(actions.get())
          }
        )
      }
    actions[`update${itemNameCap}`] = actions.update


    reducers[`UPDATE_${itemNameUpper}_START`] = (state, action) => {
      const newState = {
        ...state,
        isModifying: true,
        didInvalidate: false,
        lastOperationStatus: action.payload.status,
      }

      if (optimisticUpdate) {
        if (!dataIsNotArray) { // data is an array
          const newData = [...newState.data]
          const index = newState.data.findIndex((d) => d[idFieldName] === action.payload.data[idFieldName])
          if (index !== -1) {
            newData[index] = { ...newData[index], ...action.payload.data }
            newState.data = newData
          }
        } else { // data is an object
          const dataTemp = {}
          Object.assign(dataTemp, state.data, action.payload.data)
          newState.data = dataTemp
        }
      }
      return newState
    }
    reducers.updateStart = reducers[`UPDATE_${itemNameUpper}_START`]

    reducers[`UPDATE_${itemNameUpper}_SUCCESS`] = (state, action) => {
      const newState = {
        ...state,
        isModifying: false,
        didInvalidate: invalidateOnUpdate,
        lastOperationStatus: action.payload.status,
      }

      if (!optimisticUpdate) {
        if (!dataIsNotArray) {
          const index = newState.data.findIndex(d => d[idFieldName] === action.payload.data[idFieldName])
          if (index !== -1) {
            const newData = [...state.data]
            newData[index] = { ...action.payload.data }
            newState.data = newData
          }
        } else {
          newState.data = {
            ...state.data,
            ...action.payload.data,
          }
        }
      }

      return newState
    }
    reducers.updateSuccess = reducers[`UPDATE_${itemNameUpper}_SUCCESS`]

    reducers[`UPDATE_${itemNameUpper}_ERROR`] = (state, action) => ({
      ...state,
      isModifying: false,
      didInvalidate: true,
      lastOperationStatus: action.payload.status,
    })
    reducers.updateError = reducers[`UPDATE_${itemNameUpper}_ERROR`]
  }


  /* /--------------------/ DELETE /--------------------/ */

  if (cfg.services.del) {
    actions.deleteStart = (id) => ({
      type: `DELETE_${itemNameUpper}_START`,
      payload: {
        id: id,
      },
    })

    actions.deleteSuccess = (id, status) => ({
      type: `DELETE_${itemNameUpper}_SUCCESS`,
      payload: {
        id: id,
        status: status,
        receivedAt: Date.now(),
      },
    })

    actions.deleteError = (id, status) => ({
      type: `DELETE_${itemNameUpper}_ERROR`,
      payload: {
        id: id,
        status: status,
        receivedAt: Date.now(),
      },
    })

    actions.delete = (id, refresh) =>
      (dispatch, getState) => {
        dispatch(actions.deleteStart(id))
        return cfg.services.del(
          getEventId(getState),
          id,
          (itemId) => {
            const message = i18n.getFixedT(i18n.language)('common_module:delete.success', { itemName: capitalize(itemNameMessage), id: itemId })
            dispatch(actions.deleteSuccess(itemId, message))
            dispatch(showToast({
              title: i18n.getFixedT(i18n.language)('common_module:delete.title'),
              message: message,
              level: 'success',
              permanent: false,
            }))
            if (refresh) {
              dispatch(actions.get)
            }
          },
          (error) => {
            dispatch(actions.deleteError(error.description))

            if (cfg.app === 'attendee') {
              handleErrorMessage(dispatch, getState, error)
            } else {
              pulseOfflineNotificationOr(dispatch, getState, error, () => showToast({
                title: i18n.getFixedT(i18n.language)('common_module:delete.title'),
                message: error.description,
                level: 'error',
                permanent: false,
              }))
            }

            dispatch(actions.get())
          }
        )
      }
    actions[`delete${itemNameCap}`] = actions.delete


    reducers[`DELETE_${itemNameUpper}_START`] = (state, action) => {
      const index = state.data.findIndex((d) => d[idFieldName] === action.payload.id)
      return {
        ...state,
        isModifying: true,
        didInvalidate: false,
        data: [
          ...state.data.slice(0, index),
          ...state.data.slice(index + 1),
        ],
        lastOperationStatus: action.payload.status,
      }
    }
    reducers.deleteStart = reducers[`DELETE_${itemNameUpper}_START`]

    reducers[`DELETE_${itemNameUpper}_SUCCESS`] = (state, action) => ({
      ...state,
      isModifying: false,
      didInvalidate: false,
      lastOperationStatus: action.payload.status,
    })
    reducers.deleteSuccess = reducers[`DELETE_${itemNameUpper}_SUCCESS`]

    reducers[`DELETE_${itemNameUpper}_ERROR`] = (state, action) => ({
      ...state,
      isModifying: false,
      didInvalidate: true,
      lastOperationStatus: action.payload.status,
    })
    reducers.deleteError = reducers[`DELETE_${itemNameUpper}_ERROR`]
  }


  /* /--------------------/ MODIFY /--------------------/ */

  if (cfg.services.mod) {
    actions.modifyStart = (data) => ({
      type: `MODIFY_${itemNameUpper}_START`,
      payload: {
        data: data,
      },
    })

    actions.modifySuccess = (status) => ({
      type: `MODIFY_${itemNameUpper}_SUCCESS`,
      payload: {
        status: status,
        receivedAt: Date.now(),
      },
    })

    actions.modifyError = (status) => ({
      type: `MODIFY_${itemNameUpper}_ERROR`,
      payload: {
        status: status,
        receivedAt: Date.now(),
      },
    })

    actions.modify = (data) =>
      (dispatch, getState) => {
        dispatch(actions.modifyStart(data))
        return cfg.services.mod(
          getEventId(getState),
          data,
          () => {
            const message = i18n.getFixedT(i18n.language)('common_module:modify.success', { itemName: capitalize(itemNameMessage) })
            dispatch(actions.modifySuccess(message))
            dispatch(showToast({
              title: i18n.getFixedT(i18n.language)('common_module:modify.title'),
              message: message,
              level: 'success',
              permanent: false,
            }))
          },
          (error) => {
            dispatch(actions.modifyError(error.description))
            if (cfg.app === 'attendee') {
              handleErrorMessage(dispatch, getState, error)
            } else {
              pulseOfflineNotificationOr(dispatch, getState, error, () => showToast({
                title: i18n.getFixedT(i18n.language)('common_module:modify.title'),
                message: error.description,
                level: 'error',
                permanent: false,
              }))
            }

            dispatch(actions.get())
          }
        )
      }
    actions[`modify${itemNameCap}`] = actions.modify


    reducers[`MODIFY_${itemNameUpper}_START`] = (state, action) => ({
      ...state,
      data: action.payload.data,
      isModifying: true,
      didInvalidate: false,
      lastOperationStatus: action.payload.status,
    })
    reducers.modifyStart = reducers[`MODIFY_${itemNameUpper}_START`]

    reducers[`MODIFY_${itemNameUpper}_SUCCESS`] = (state, action) => ({
      ...state,
      isModifying: false,
      didInvalidate: false,
      lastOperationStatus: action.payload.status,
    })
    reducers.modifySuccess = reducers[`MODIFY_${itemNameUpper}_SUCCESS`]

    reducers[`MODIFY_${itemNameUpper}_ERROR`] = (state, action) => ({
      ...state,
      isModifying: false,
      didInvalidate: true,
      lastOperationStatus: action.payload.status,
    })
    reducers.modifyError = [`MODIFY_${itemNameUpper}_ERROR`]
  }

  /* /--------------------/ PERSISTENCE REDUCER /--------------------/ */

  reducers[REHYDRATE] = (state, action) => {
    const subState = action.payload[app] ? action.payload[app][cfg.itemName + (cfg.dataIsNotArray ? '' : 's')] : null
    return {
      ...state,
      ...((subState && !subState.isError && !subState.isFetching && !subState.isModifying && !subState.didInvalidate) ? subState : {}),
      isRehydrated: true,
    }
  }


  /* /--------------------/ INDEX REDUCER /--------------------/ */

  reducers.index = (state = initialState, action) => (reducers[action.type] ? reducers[action.type](state, action) : state)

  return {
    cfg: cfg,
    app: cfg.app,
    store: cfg.itemName + (cfg.dataIsNotArray ? '' : 's'),
    actions,
    reducers,
    initialState,
  }
}
