import axios from 'axios'
import { buildError } from 'common/utils/api'
import { buildQueryParams, getAxiosConfig } from './helper'


export function serviceGetWearables(eventId, queryParams) {
  const queryString = buildQueryParams(queryParams)
  return axios.get(`events/${eventId}/monitoring/devices${queryString}`, getAxiosConfig())
    .then(response => response.data)
    .catch(error => Promise.reject(buildError(error)))
}

