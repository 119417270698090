import { serviceGetBridges, servicePutBridge } from 'admin/services/events'
import { showToast } from 'common/modules/toaster'
import { listToMapping } from 'common/utils/componentHelper'
import { createSelector } from 'reselect'
import i18n from 'i18next'

const selectState = (state) => state.admin.bridges
const selectBridges = (state) => state.admin.bridges.bridges


export const moduleSelector = createSelector(selectState, (state) => state)
export const bridgesByAddressSelector = createSelector(selectBridges, _bridges => listToMapping(_bridges, 'addr'))


// //////////////////////////////////////////////////////////////////////////////
// GET Bridges
// //////////////////////////////////////////////////////////////////////////////

function getBridgesStart() {
  return { type: 'GET_BRIDGES_START' }
}

function getBridgesSuccess(data) {
  return {
    type: 'GET_BRIDGES_SUCCESS',
    payload: {
      bridges: data,
    },
  }
}

function getBridgesError() {
  return { type: 'GET_BRIDGES_ERROR' }
}

export function getBridges() {
  return (dispatch, getState) => {
    dispatch(getBridgesStart())

    return serviceGetBridges(
      getState().admin.events.currentEvent.id,
      (response) => dispatch(getBridgesSuccess(response)),
      (error) => {
        dispatch(getBridgesError())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language)('admin_customModule:bridges.get_bridges.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}


// //////////////////////////////////////////////////////////////////////////////
// PUT Bridge
// //////////////////////////////////////////////////////////////////////////////

function putBridgeStart() {
  return { type: 'PUT_BRIDGE_START' }
}

function putBridgeSuccess() {
  return { type: 'PUT_BRIDGE_SUCCESS' }
}

function putBridgeError() {
  return { type: 'PUT_BRIDGE_ERROR' }
}

export function putBridge(bridgeAddress, data) {
  return (dispatch, getState) => {
    dispatch(putBridgeStart())

    return servicePutBridge(
      getState().admin.events.currentEvent.id,
      bridgeAddress,
      data,
      (response) => dispatch(putBridgeSuccess(response)),
      (error) => {
        dispatch(putBridgeError())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language)('admin_customModule:bridges.save_bridge.error'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}


const initialState = {
  isLoading: false,
  isLoaded: false,
  isError: false,
  bridges: [],
}

export default function bridges(state = initialState, action) {
  switch (action.type) {
    case 'GET_BRIDGES_START':
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
      }

    case 'GET_BRIDGES_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        bridges: action.payload.bridges,
      }

    case 'GET_BRIDGES_ERROR':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: true,
        bridges: [],
      }

    default:
      return state
  }
}
