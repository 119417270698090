import React from 'react'
import i18n from 'i18next'


export default function LanguageSelector() {
  const onClick = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en')
  }

  return (
    <div className="LanguageSelector">
      <div onClick={onClick}>
        {i18n.language === 'en' ? 'Français' : 'English'}
      </div>
    </div>
  )
}
