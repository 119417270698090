import { serviceGetSessionAnalytics } from 'admin/services/analytics'
import generateModule from 'common/utils/generateModule'


const { actions, reducers, initialState } = generateModule({
  itemName: 'sessionByGroup',
  autoRefresh: 3,
  getModuleState: (getState) => getState().admin.analytics.sessionsByGroups,
  dataIsNotArray: true,
  services: {
    get: serviceGetSessionAnalytics,
  },
})

actions.getSessionByGroups = (sessionId, query) =>
  (dispatch, getState) => {
    const _query = {
      sort: 'num_attended',
      groupby: 'group',
      ...query,
    }

    dispatch(actions.getStart())
    return serviceGetSessionAnalytics(
      getState().admin.events.currentEvent.id,
      sessionId,
      _query,
      (response) => dispatch({
        type: 'GET_SESSIONBYGROUPS_SUCCESS',
        data: {
          id: sessionId,
          stats: response.groups,
        },
      }),
      () => dispatch(actions.getError())
    )
  }

reducers['GET_SESSIONBYGROUPS_SUCCESS'] = (state, action) => {
  const newData = { ...state.data }
  newData[action.data.id] = [...action.data.stats]

  return {
    ...state,
    data: newData,
  }
}


reducers['CHANGE_EVENT_SUCCESS'] = (state) => ({
  ...state,
  didInvalidate: true,
  data: initialState.data,
})


export const getSessionByGroups = actions.getSessionByGroups

const sessionsByGroups = reducers.index
export default sessionsByGroups
