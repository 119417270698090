import axios from 'axios'
import { apiErrorHandler, apiCatchError, parseQuery } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceGetTopics(eventId, query, accountId, success, error) { // eslint-disable-line
  return axios.get(`/events/${eventId}/attendees/me/topics/feed?${parseQuery(query)}`, getAxiosConfig())
    .then(response => {
      const data = response.data;
      response.data.forEach((topic, index) => {
        if (topic.meeting_id) {
          return axios.get(`events/${eventId}/meetings/${topic.meeting_id}/participants`, getAxiosConfig())
            .then(meeting => {
              const participants = meeting.data.participants
              const full = participants.length
              const isParticipant = participants.filter(p => p.id === accountId).length > 0
              data[index] = { ...topic, full, participants, isParticipant }
            })
        }
        return null
      })
      return (success(data), apiErrorHandler(error, 'backend'))
    })
    .catch((response) => apiCatchError(response))
}

export function serviceGetAttendeeTopics(eventId, accountId, success, error) {
  return axios.get(`/events/${eventId}/attendees/${accountId}/topics`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetTopic(eventId, topicId, success, error) {
  return axios.get(`/events/${eventId}/attendees/me/topics/${topicId}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function servicePostTopic(eventId, topic, meetingData, success, error) {
  return axios.post(`/events/${eventId}/attendees/me/topics`, topic, getAxiosConfig())
    .then((response) => success({ ...response.data, meetingData }), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export async function servicePutTopic(eventId, topic, meetingId, success, error) {
  let participants;
  if (meetingId) {
    await axios.get(`events/${eventId}/meetings/${meetingId}/participants`, getAxiosConfig()).then(response => {
      participants = response.data.participants
    })
  }
  return axios.put(`/events/${eventId}/attendees/me/topics/${topic.id}`, topic, getAxiosConfig())
    .then((response) => {
      let data = response.data
      if (participants) {
        data = { ...response.data, participants }
      }
      return (success(data), apiErrorHandler(error, 'backend'))
    }).catch((response) => apiCatchError(response))
}

export function serviceDeleteTopic(eventId, topicId, success, error) {
  return axios.delete(`/events/${eventId}/attendees/me/topics/${topicId}`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceCreateTopicMeeting(eventId, payload, success, error) {
  return axios.post(`events/${eventId}/meetings`, payload, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceAddTopicMeetingParticipant(eventId, meetingId, participantId, success, error) {
  const payload = { id: participantId }
  return axios.post(`events/${eventId}/meetings/${meetingId}/participants`, payload, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
export function serviceRemoveTopicMeetingParticipant(eventId, meetingId, participantId, success, error) {
  return axios.delete(`events/${eventId}/meetings/${meetingId}/participants/${participantId}`, getAxiosConfig())
    .then(() => success(participantId), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviecGetTopicMeeting(eventId, meetingId, success, error) {
  return axios.get(`events/${eventId}/meetings/${meetingId}`, getAxiosConfig())
    .then((response) => success(response), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateTopicMeeting(eventId, attendeeId, meetingId, meetingTimes, success, error) {
  return axios.put(`events/${eventId}/attendees/${attendeeId}/meetings/${meetingId}`, meetingTimes, getAxiosConfig())
    .then((response) => success(response), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceGetTopicMeetingParticipants(eventId, meetingId, success, error) {
  return axios.get(`events/${eventId}/meetings/${meetingId}/participants`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
