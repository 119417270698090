import React, { Component } from 'react'

import Link from 'common/components/Link'
import ExternalLink from 'common/components/ExternalLink'
import CallToAction from 'common/components/CallToAction'

import { InternalLink as getInternalLink, ExternalLink as getExternalLink } from './Links'
import SocialLink from './SocialLink'


export default class Button extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
    }
  }

  _getClassName() {
    const { className, flex, useAlternativeStyling, size, overwriteClassName } = this.props

    if (overwriteClassName) {
      return className
    }

    let buttonClassName = 'klik-button'

    buttonClassName += useAlternativeStyling ? ' secondary' : ''
    buttonClassName += size ? ` ${size}` : ''
    buttonClassName += className ? ` ${className}` : ''
    buttonClassName += flex ? ' flex-button' : ''

    return buttonClassName
  }

  _getStyle() {
    const { isBusy } = this.state
    const { disabled, color, style } = this.props
    return (disabled || isBusy) ? { ...style } : { ...color, ...style }
  }

  onClick = (e) => {
    if (!this.props.onClick) return

    const onClickPromise = this.props.onClick(e)

    if (onClickPromise && onClickPromise.finally) {
      this.setState({ isBusy: true })
      onClickPromise.finally(() => this.setState({ isBusy: false }))
    }
  }

  render() {
    const isBusy = this.props.isBusy != null ? this.props.isBusy : this.state.isBusy
    const { children, id, type, link, extraProps, callToAction, callToActionComponent: CustomCallToAction } = this.props

    const className = this._getClassName()
    const style = this._getStyle()
    const onClick = this.onClick
    const disabled = this.props.disabled || isBusy

    if (callToAction) {
      const callToActionProps = {
        className: className,
        style: style,
        data: callToAction,
        cta: callToAction,
        customContent: children,
        shouldShowArrow: false,
        onClick: onClick,
      }

      return CustomCallToAction
        ? <CustomCallToAction {...callToActionProps} />
        : <CallToAction {...callToActionProps} />
    }

    if (link) {
      return (link.internal
        ?
        <Link id={id} className={className} style={style} disabled={disabled} onClick={onClick} to={link.url} {...extraProps}>
          {isBusy ? <i className="fa fa-spin fa-circle-o-notch" /> : null}
          {children}
        </Link>
        :
        <ExternalLink id={id} className={className} style={style} disabled={disabled} onClick={onClick} href={link.url} target={link.target} extraProps={extraProps}>
          {isBusy ? <i className="fa fa-spin fa-circle-o-notch" /> : null}
          {children}
        </ExternalLink>
      )
    }

    return (
      <button id={id} className={className} style={style} disabled={disabled} onClick={onClick} type={type} {...extraProps}>
        {isBusy ? <i className="fa fa-spin fa-circle-o-notch" /> : null}
        {children}
      </button>
    )
  }
}

Button.defaultProps = {
  className: '',
  id: '',
  style: {},
  flex: false,
  onClick: () => {},
  overwriteClassName: false, // Set to true to give only the className from props

  useAlternativeStyling: false,
  disabled: false,
  size: 'medium',
  color: undefined, // { backgroundColor, color }
  type: 'button',

  link: undefined,
  callToAction: undefined,
  callToActionComponent: undefined,

  extraProps: {},
}

// Types of link
Button.InternalLink = getInternalLink
Button.ExternalLink = getExternalLink

// Types of button
Button.SocialLink = SocialLink
