function isConditionValid(condition) {
  const validateValue = () => (condition.value !== undefined && (Array.isArray(condition.value) && condition.value.length > 0))

  switch (condition.type) {
    case 'profile_field':
      return (validateValue() || condition.relationship !== undefined) && condition.field !== undefined

    default:
      return (validateValue() || condition.relationship !== undefined)
  }
}

export function areConditionsValid(conditions) {
  for (let i = 0; i < conditions.length; i++) {
    if (!isConditionValid(conditions[i])) {
      return false
    }
  }

  return true
}


export default (data) => {
  let error

  Object.keys(data.rules).forEach(key => {
    data.rules[key].forEach(rule => {
      error = areConditionsValid(rule.actor_conditions) ? error : 'Error in actor conditions'
      error = areConditionsValid(rule.target_conditions) ? error : 'Error in target conditions'
    })
  })

  return { _hasError: error }
}
