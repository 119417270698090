import generateAnalyticsModule from 'admin/utils/generateAnalyticsModule'
import { serviceGetBadgesData } from 'admin/services/analytics'
import { createSelector } from 'reselect'
import search, { buildFieldList } from 'common/utils/search'
import { getTranslatedValue } from 'common/utils/translator'


const selectBadges = (state) => state.admin.analytics.badges.data
const selectSearchTerm = (state) => state.admin.analytics.badges.searchTerm

export const badgesSelector = createSelector(
  selectBadges,
  selectSearchTerm,
  (badges, searchTerm) => badges
    .filter((badge) => search(searchTerm, buildFieldList(badge, ['name'])))
    .sort((a, b) => {
      if (a.achieved_count === b.achieved_count) {
        const nameA = getTranslatedValue(a.name)
        const nameB = getTranslatedValue(b.name)
        return nameA.localeCompare(nameB)
      }
      return b.achieved_count - a.achieved_count
    })
)


const badgesModule = generateAnalyticsModule({
  moduleKey: 'badgesData',
  initialData: [],
  getter: serviceGetBadgesData,
})

badgesModule.initialState.searchTerm = ''

badgesModule.actions.setSearchTerm = (searchTerm) => dispatch => dispatch({ type: 'BADGES_SET_SEARCH_TERM', payload: searchTerm })
badgesModule.reducers['BADGES_SET_SEARCH_TERM'] = (state, action) => ({ ...state, searchTerm: action.payload })

export default badgesModule
