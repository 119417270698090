import { serviceGetContactExchangesDistribution } from 'admin/services/analytics'
import generateModule from 'common/utils/generateModule'


const { actions, reducers, initialState } = generateModule({
  itemName: 'contactExchangesDistribution',
  autoRefresh: 60,
  getModuleState: (getState) => getState().admin.analytics.distributionGraphs.contactExchanges,
  services: {
    get: serviceGetContactExchangesDistribution,
  },
})


actions.get = (query) =>
  (dispatch, getState) => {
    dispatch(actions.getStart())
    return serviceGetContactExchangesDistribution(
      getState().admin.events.currentEvent.id,
      query,
      (response) => {
        dispatch(actions.getSuccess(response))
      },
      () => dispatch(actions.getError())
    )
  }


reducers['CHANGE_EVENT_SUCCESS'] = (state) => ({
  ...state,
  didInvalidate: true,
  data: initialState.data,
})


export const getContactExchangesDistribution = actions.get
export const getContactExchangesDistributionIfNeeded = actions.getIfNeeded

const contactExchanges = reducers.index
export default contactExchanges
