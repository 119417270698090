import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { browserHistoryPush, getQueryParam } from 'common/components/Link'
import { retrieveStoredTokens } from 'common/utils/accessToken'
import config from 'config'

import { login, autologin, setRequestedPath, getLoginBranding, getLoginBrandingForDomain, flagUserAsPreviewer, flagUserAsPreviewWidget, changeRequestedEvent } from 'common/modules/auth'

import { Route, withRouter } from 'react-router-dom'
import LoadedComponent from 'common/utils/loadedComponent'


@connect(
  (state) => ({ auth: state.auth }),
  undefined,
  undefined,
  { forwardRef: true }
)
@withRouter
export default class SecuredRoute extends Component {
  componentWillMount() {
    window.PageLoader.startLoading()
    const { auth, location, dispatch, isLoginPage, computedMatch: { params } } = this.props
    const queryParams = getQueryParam(location)

    if (auth.user.isLoggedIn || auth.isFetching) {
      return
    }

    // This stuff maybe should go in the login component itself
    const domain = (() => {
      const appUrl = document.createElement('a')
      appUrl.href = config.appUrl
      let _domain = queryParams.get('_domain')
      if (!_domain && appUrl.hostname !== window.location.hostname) {
        _domain = window.location.hostname.split('.')[0]
      }

      return (_domain !== 'localhost') ? _domain : undefined
    })();

    if (domain) {
      dispatch(getLoginBrandingForDomain(domain))
    } else if (params.event_id) {
      dispatch(changeRequestedEvent(params.event_id))
      dispatch(getLoginBranding(params.event_id))
    }

    if (queryParams.has('_previewwidget')) {
      dispatch(flagUserAsPreviewWidget())
    }

    if (queryParams.has('_adminpreview')) {
      dispatch(flagUserAsPreviewer())

      if (isLoginPage) {
        return // Show the login screen for domain branding preview, make sure to load branding first
      }
    }

    // Choose the token to use (if any available)
    let token = null
    const adminToken = retrieveStoredTokens().adminApp_refresh_token
    const nonadminToken = retrieveStoredTokens('attendee').attendeeApp_refresh_token

    if (queryParams.has('_autologin')) { // eslint-disable-line no-underscore-dangle
      token = queryParams.get('_autologin') // eslint-disable-line no-underscore-dangle
    } else if (adminToken && isLoginPage) {
      token = adminToken // we choose the admin site over the attendee site
    } else if (adminToken && location.pathname.startsWith('/admin')) {
      token = adminToken
    } else {
      token = nonadminToken
    }

    if (!isLoginPage) {
      dispatch(setRequestedPath(location.pathname + location.search))
    }

    if (token) {
      dispatch(autologin(token))
    } else if (isLoginPage && queryParams.has('username') && queryParams.has('password')) { // allows automatic login through query string args
      dispatch(login(queryParams.get('username'), queryParams.get('password'), domain))
    } else if (!isLoginPage) {
      if (queryParams.has('event_id')) {
        browserHistoryPush('/' + queryParams.get('event_id'))
      } else {
        browserHistoryPush('/login')
      }
    }
  }

  renderComponent = (routeProps) => {
    const { component: ChildComponent, isLoginPage } = this.props
    const auth = this.props.auth
    const showPage = isLoginPage && !auth.autologinFetching && !auth.loginBranding.isFetching
    const busy = { isLoaded: auth.isLoaded, isFetching: auth.autologinFetching || auth.loginBranding.isFetching }
    const error = (
      <div>
        <h2>Something has gone wrong</h2>
        <p>Your automatic login failed. If you arrived here via an email link, please try that link again or contact the event organizer.</p>
      </div>
    )

    return (
      <LoadedComponent errorContent={error} monitored={[this.props.auth, busy]} alwaysDisplayChildren={showPage}>
        <ChildComponent {...routeProps} />
      </LoadedComponent>
    )
  }

  render() {
    const { exact, path } = this.props

    return (
      <Route
        exact={exact}
        path={path}
        render={this.renderComponent}
      />
    )
  }
}

SecuredRoute.propTypes = {
  component: PropTypes.any,
  exact: PropTypes.bool,
  isLoginPage: PropTypes.bool,
  match: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
}
