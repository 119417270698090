import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { getAxiosConfig } from './helper'


export function serviceGetTooltipsSeenStatus(eventId, success, error) {
  return axios.get(`/events/${eventId}/attendees/me/tutorials`, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}

export function serviceUpdateTooltipsSeenStatus(eventId, seen, success, error) {
  return axios.put(`/events/${eventId}/attendees/me/tutorials`, seen, getAxiosConfig())
    .then((response) => success(response.data), apiErrorHandler(error, 'backend'))
    .catch((response) => apiCatchError(response))
}
