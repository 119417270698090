import { serviceGetVenueMaps, serviceAddVenueMap, serviceUpdateVenueMap, serviceDeleteVenueMap, serviceGetMap } from 'admin/services/maps'
import generateModule from 'common/utils/generateModule'
import { getFloorPlanPreferences, updateFloorPlanPreferences } from 'common/utils/accessToken'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'
import { listToMapping } from 'common/utils/componentHelper'
import { createSelector } from 'reselect'

const selectState = (state) => state.admin.venueMaps
const selectMaps = (state) => selectState(state).data

export const mapsSelector = createSelector(selectMaps, maps => maps)
export const mapsByIdSelector = createSelector(mapsSelector, maps => listToMapping(maps, 'id'))

export const viewPreferencesSelector = createSelector(selectState, (state) => state.prefs)


const { actions, reducers, initialState } = generateModule({
  itemName: 'venueMap',
  itemNameDisplay: 'venue map',
  toasterFieldName: 'name',
  idFieldName: 'id',
  services: {
    get: serviceGetVenueMaps,
    add: serviceAddVenueMap,
    upd: serviceUpdateVenueMap,
    del: serviceDeleteVenueMap,
  },
})

initialState.currentMap = null


actions.addVenueMap = (data) =>
  (dispatch, getState) => {
    const mapData = { ...data }
    dispatch({ type: 'ADD_VENUE_MAP_START' })

    if (mapData.position == null) {
      // The newest map should be the last one
      mapData.position = Math.max(...getState().admin.venueMaps.data.map(map => map.position)) + 1
    }

    return serviceAddVenueMap(
      getState().admin.events.currentEvent.id,
      mapData,
      (response) => {
        dispatch({ type: 'ADD_VENUE_MAP_SUCCESS', payload: { map: response } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('venue_map.create.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('venue_map.create.success'),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch({ type: 'ADD_VENUE_MAP_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('venue_map.create.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['ADD_VENUE_MAP_SUCCESS'] = (state, action) => ({
  ...state,
  data: [...state.data, action.payload.map],
})


actions.updateVenueMap = (data) =>
  (dispatch, getState) => {
    dispatch({ type: 'UPDATE_VENUE_MAP_START' })

    return serviceUpdateVenueMap(
      getState().admin.events.currentEvent.id,
      data,
      (_, response) => {
        dispatch({ type: 'UPDATE_VENUE_MAP_SUCCESS', payload: { map: response } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('venue_map.update.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('venue_map.update.success'),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch({ type: 'UPDATE_VENUE_MAP_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('venue_map.update.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['UPDATE_VENUE_MAP_SUCCESS'] = (state, action) => ({
  ...state,
  data: state.data.map(map => (map.id === action.payload.map.id ? action.payload.map : map)),
})


actions.deleteVenueMap = (mapId) =>
  (dispatch, getState) => {
    dispatch({ type: 'DELETE_VENUE_MAP_START' })

    return serviceDeleteVenueMap(
      getState().admin.events.currentEvent.id,
      mapId,
      () => {
        dispatch({ type: 'DELETE_VENUE_MAP_SUCCESS', payload: { mapId } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('venue_map.delete.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('venue_map.delete.success'),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch({ type: 'DELETE_VENUE_MAP_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('venue_map.delete.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['DELETE_VENUE_MAP_SUCCESS'] = (state, action) => ({
  ...state,
  data: state.data.filter(map => map.id !== action.payload.mapId),
})


function updateMapOrder(mapId, positionIndex) {
  return (dispatch, getState) => {
    const mapData = { id: mapId, position: positionIndex }
    dispatch({ type: 'UPDATE_MAP_ORDER_START' })

    return new Promise(resolve => {
      serviceUpdateVenueMap(
        getState().admin.events.currentEvent.id,
        mapData,
        (_, response) => {
          dispatch({ type: 'UPDATE_MAP_ORDER_SUCCESS', payload: { map: response } })
          resolve(response)
        },
        () => dispatch({ type: 'UPDATE_MAP_ORDER_ERROR', payload: { map: mapData } }))
    })
  }
}

actions.updateMapsOrder = (mapIds) =>
  (dispatch, getState) => {
    const positionById = mapIds.reduce((mapping, mapId, index) => ({ ...mapping, [mapId]: index }), {})
    const newData = getState().admin.venueMaps.data
      .map(mapData => ({ ...mapData, position: positionById[mapData.id] != null ? positionById[mapData.id] : mapData.position }))
      .sort((a, b) => a.position - b.position)

    dispatch({ type: 'UPDATE_MAPS_ORDER_START', payload: { maps: newData } })

    return Promise.all(mapIds.map((mapId, positionIndex) => dispatch(updateMapOrder(mapIds[positionIndex], positionIndex))))
      .then(() => dispatch({ type: 'UPDATE_MAPS_ORDER_FINISHED' }))
  }

reducers['UPDATE_MAPS_ORDER_START'] = (state, action) => ({
  ...state,
  data: action.payload.maps,
})


actions.updateMapViewPreferences = (prefs) =>
  (dispatch, getState) => {
    const newPrefs = { ...getState().admin.venueMaps.prefs, ...prefs }
    updateFloorPlanPreferences(newPrefs)
    dispatch(((p) => ({
      type: 'UPDATE_MAP_VIEW_PREFERENCES',
      data: { prefs: p },
    }))(newPrefs))
  }

reducers['UPDATE_MAP_VIEW_PREFERENCES'] = (state, action) => ({ // eslint-disable-line dot-notation, no-unused-vars
  ...state,
  prefs: { ...action.data.prefs },
})

initialState.prefs = getFloorPlanPreferences() || {
  map: true,
  zones: true,
  hubs: false,
  tags: false,
  touchpoints: false,
  attendees: true,
}


reducers['CHANGE_EVENT_SUCCESS'] = (state) => ({
  ...state,
  didInvalidate: true,
  data: initialState.data,
})


actions.getMap = id =>
  (dispatch, getState) => {
    dispatch({ type: 'GET_MAP_START', payload: { id } })
    return serviceGetMap(
      getState().admin.events.currentEvent.id,
      id,
      response => dispatch({ type: 'GET_MAP_SUCCESS', payload: response }),
      error => dispatch({ type: 'GET_MAP_ERROR', payload: error })
    )
  }

reducers['GET_MAP_START'] = state => ({
  ...state,
  isFetching: true,
  isError: false,
})

reducers['GET_MAP_SUCCESS'] = (state, action) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  currentMap: action.payload,
})

reducers['GET_MAP_ERROR'] = state => ({
  ...state,
  isFetching: false,
  isError: true,
  currentMap: null,
})


export const getVenueMaps = actions.get
export const getVenueMapsIfNeeded = actions.getIfNeeded
export const getMap = actions.getMap
export const addVenueMap = actions.addVenueMap
export const updateVenueMap = actions.updateVenueMap
export const deleteVenueMap = actions.deleteVenueMap
export const updateMapViewPreferences = actions.updateMapViewPreferences
export const updateMapsOrder = actions.updateMapsOrder
const venueMaps = reducers.index
export default venueMaps
