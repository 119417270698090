import React, { Component } from 'react'
import PropTypes from 'prop-types'

import EmptyItem from './EmptyItem'
import { styles } from './styles.scss'


function alignmentToFlex(alignment) {
  switch (alignment) {
    case 'left': return 'flex-start'
    case 'center': return 'center'
    case 'right': return 'flex-end'
    default: return alignment
  }
}


export default class Row extends Component {
  _getClassName = () => {
    let className = `${styles} flex-row`
    className += this.props.className ? ` ${this.props.className}` : ''
    return className
  }

  _getStyle = () => {
    const style = { ...this.props.style }

    if (this.props.alignment) {
      style.justifyContent = alignmentToFlex(this.props.alignment)
    }

    return style
  }

  render() {
    return (
      <div className={this._getClassName()} style={this._getStyle()}>
        {this.props.children}
      </div>
    )
  }
}

Row.propTypes = {
  alignment: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

Row.EmptyItem = EmptyItem
