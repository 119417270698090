import Moment from 'moment'
import { getProperty } from 'common/utils/componentHelper'
import { isPageVisible } from 'common/utils/domUtils'
import { showToast } from 'common/modules/toaster'


export default function generateGetServices(actionKeyPrefix, options) {
  const actions = {}
  const reducers = {}

  const startType = `${actionKeyPrefix}_GET_START`.toUpperCase()
  actions.getStart = args => ({
    type: startType,
    requestTime: Moment().unix(),
    payload: args,
  })

  reducers[startType] = state => ({
    ...state,
    isFetching: true,
    isError: false,
  })


  const successType = `${actionKeyPrefix}_GET_SUCCESS`.toUpperCase()
  actions.getSuccess = response => ({
    type: successType,
    requestTime: Moment().unix(),
    payload: response,
  })

  reducers[successType] = (state, action) => ({
    ...state,
    isFetching: false,
    isLoaded: true,
    data: action.payload,
    lastUpdated: action.requestTime,
  })


  const errorType = `${actionKeyPrefix}_GET_ERROR`.toUpperCase()
  actions.getError = errorMessage => ({
    type: errorType,
    requestTime: Moment().unix(),
    payload: errorMessage,
  })

  reducers[errorType] = state => ({
    ...state,
    isFetching: false,
    isError: true,
  })


  actions.get = (...args) =>
    (dispatch, getState) => {
      dispatch(actions.getStart(...args))
      return options.services.get.service(getState, ...args)
        .then(response => {
          dispatch(actions.getSuccess(response))

          if (options.services.get.showOnSuccess) {
            dispatch(showToast({
              title: options.t('get.title'),
              message: (options.services.get.successMessage
                ? options.services.get.successMessage(response, ...args)
                : options.t('get.success')),
              level: 'success',
            }))
          }
        })
        .catch(error => {
          dispatch(actions.getError(error.description))

          if (options.services.get.showOnError) {
            dispatch(showToast({
              title: options.t('get.title'),
              message: (options.services.get.errorMessage
                ? options.services.get.errorMessage(error, ...args)
                : error.description),
              level: 'error',
            }))
          }
        })
    }

  actions.getIfNeeded = (...args) =>
    (dispatch, getState) => {
      const state = getProperty(getState(), options.reducerKey)

      const isModuleBusy = () => state.isFetching || state.isModifying || state.isLoading
      const isInvalidated = () => state.didInvalidate
      const isTimeForRefresh = () => Moment().unix() - state.lastUpdated >= options.refreshTime
      const hasData = () => {
        if (!state.data) return false
        if (Array.isArray(state.data)) return state.data.length > 0
        if (typeof (state.data) === 'object') return Object.keys(state.data).length > 0
        return !!state.data
      }

      const shouldGet = (isPageVisible() && !isModuleBusy() && (!hasData() || isInvalidated() || isTimeForRefresh()))
      return shouldGet ? dispatch(actions.get(...args)) : Promise.resolve()
    }

  return { actions, reducers }
}
