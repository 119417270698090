import { addTimeFilterSupport } from './helper'
import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetTouchpointClicks, serviceGetCurrentSumTouchpointClicks } from 'admin/services/dashboard'
import { currentEventIdSelector, eventTimezoneSelector, eventStartDateSelector, eventEndDateSelector } from 'admin/modules/events'

const TouchpointClicksModule = generateDataModule({
  initialData: [],
  initialState: { currentSum: null, minimalData: [], totalSum: null },
  moduleKey: 'dashboardTouchpointClicks',
  reducerKey: 'admin.dashboard.touchpointClicks',
  services: {
    get: { service: serviceGetTouchpointClicks },
  },
})

addTimeFilterSupport(TouchpointClicksModule, true)

TouchpointClicksModule.registerSelector('currentSumOfTouchpointClicks', (state, moduleState) => moduleState.currentSum, currentSum => currentSum)
TouchpointClicksModule.registerSelector('fullData', (state, moduleState) => moduleState.data, data => data)
TouchpointClicksModule.registerSelector('minimalData', (state, moduleState) => moduleState.minimalData, data => data)
TouchpointClicksModule.registerSelector('totalTouchpointClicks', (state, moduleState) => moduleState.totalSum, totalSum => totalSum)

TouchpointClicksModule.registerDataAction(
  'get',
  () => (dispatch, getState) => {
    const eventId = currentEventIdSelector(getState())
    const eventEndDate = eventEndDateSelector(getState())
    const eventStartDate = eventStartDateSelector(getState())
    if (!TouchpointClicksModule.selectors.areTimeFiltersInitialized(getState())) {
      dispatch(TouchpointClicksModule.actions.initializeFilters())
      dispatch(TouchpointClicksModule.actions.initializeMinimalFilters())
    }

    dispatch({ type: TouchpointClicksModule.actionKeys.getStart })
    const timeFilters = TouchpointClicksModule.selectors.timeFilters(getState())
    const minimalFilters = TouchpointClicksModule.selectors.minimalFilters(getState())
    const timezone = eventTimezoneSelector(getState())

    const getFilteredTouchpointClicks = () => TouchpointClicksModule._getService('get')(timeFilters, timezone)
    const getMinimal = () => TouchpointClicksModule._getService('get')(minimalFilters, timezone)
    const getCurrentSum = () => serviceGetCurrentSumTouchpointClicks(eventId, timeFilters, timezone)
    const getTotalTouchpointClicks = () => serviceGetCurrentSumTouchpointClicks(eventId, { startDate: eventStartDate, endDate: eventEndDate, timeOfDay: 'entire' }, timezone)

    return Promise.all([getFilteredTouchpointClicks(), getCurrentSum(), getTotalTouchpointClicks(), getMinimal()])
      .then(responses => {
        dispatch({
          type: TouchpointClicksModule.actionKeys.getSuccess,
          payload: {
            touchpointClicks: responses[0],
            currentSum: responses[1],
            totalSum: responses[2],
            getMinimal: responses[3],
          },
        })
      })
      .catch(() => {
        dispatch({ type: TouchpointClicksModule.actionKeys.getError })
      })
  }
)

TouchpointClicksModule.registerReducer(TouchpointClicksModule.actionKeys.getSuccess, (state, action) => ({
  ...state,
  data: action.payload.touchpointClicks,
  attendeeEngagement: action.payload.attendeeEngagement,
  currentSum: action.payload.currentSum,
  totalSum: action.payload.totalSum,
  isLoading: false,
  isLoaded: true,
  lastUpdated: Date.now(),
  minimalData: action.payload.getMinimal,
}))


export default TouchpointClicksModule

