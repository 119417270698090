import { serviceGetReceivedConversationInvitations, serviceGetSentConversationInvitations, serviceSendConversationInvitation, serviceAcceptConversationInvitation, serviceDeclineConversationInvitation, serviceIgnoreConversationInvitation, serviceUnignoreConversationInvitation } from 'attendee/services/meetingRequests'
import generateModule from 'common/utils/generateModule'
import { handleErrorMessage } from './index'
import Moment from 'moment'
import { createSelector } from 'reselect'


const selectConversationInvitations = state => state.attendee.conversationInvitations.data

export const conversationInvitationsSelector = createSelector(
  selectConversationInvitations,
  (conversationInvitations) => conversationInvitations
)

export const sortedConversationInvitationsSelector = createSelector(
  selectConversationInvitations,
  (conversationInvitations) => conversationInvitations.sort((invitationA, invitationB) => {
    // Ignored at the bottom
    const ignoredValue = (() => {
      const a = invitationA.ignored ? 1 : 0
      const b = invitationB.ignored ? 1 : 0
      return a - b
    })()
    if (ignoredValue !== 0) return ignoredValue

    // Most recent message on top
    const dateValue = (() => {
      const dateA = invitationA.conversation.messages[0].date
      const dateB = invitationB.conversation.messages[0].date
      return dateB - dateA
    })()
    if (dateValue !== 0) return dateValue

    // Alphabetical order
    return invitationA.account.first_name.localeCompare(invitationB.account.first_name)
  })
)

export const conversationInvitationsByAccountIdSelector = createSelector(
  selectConversationInvitations,
  (conversationInvitations) => {
    const conversationsById = {}
    conversationInvitations.forEach(conversationInvitation => {
      conversationsById[conversationInvitation.account.account_id] = conversationInvitation
    })
    return conversationsById
  }
)


function getEventId(getState) {
  return getState().attendee.attEvents.currEvent.id
}

function findConversationInvitationIndex(invitations, id) {
  for (let i = 0; i < invitations.length; i++) {
    if (invitations[i].id === id) return i
  }
  return null
}

function findConversationInvitationIndexFromConversation(invitations, conversation) {
  for (let i = 0; i < invitations.length; i++) {
    if (invitations[i].conversation && invitations[i].conversation.id === conversation.id) return i
  }
  return null
}

export function isInvitationInThePast(invitation) {
  return ((!invitation || !invitation.proposed_meetings) ? false : Moment().unix() > Math.max(invitation.proposed_meetings.map(m => m.end_date)))
}

export function filterActiveInvitations(invitations, type) {
  const invitationMapping = {}

  invitations.forEach(invitation => {
    const invitationKey = [invitation.from_profile.account_id, invitation.to_profile.account_id].sort((a, b) => a.localeCompare(b)).join('')

    const priority = (() => {
      if (invitation.accepted && !invitation.rejected) return 2
      if (!invitation.accepted && !invitation.rejected) return 1
      return 0
    })()

    // Compare the current invitation to the one cached
    const hasHigherPriority = () => priority > invitationMapping[invitationKey].priority
    const hasSamePriority = () => priority === invitationMapping[invitationKey].priority
    const isLastUpdated = () => invitation.updated_at > invitationMapping[invitationKey].data.updated_at

    if (isInvitationInThePast(invitation)) return

    if (!invitationMapping[invitationKey] || hasHigherPriority() || (hasSamePriority() && isLastUpdated())) {
      invitationMapping[invitationKey] = {
        data: {
          ...invitation,
          ignored: (() => {
            // invitation.{profile}.ignored is only there for the person doing the request
            if (type === 'conversation' && (invitation.from_profile.ignored || invitation.to_profile.ignored)) {
              const { selfProfile, otherProfile } = invitation.from_profile.ignored
                ? { selfProfile: invitation.from_profile, otherProfile: invitation.to_profile }
                : { selfProfile: invitation.to_profile, otherProfile: invitation.from_profile }
              return !!selfProfile.ignored.find(accountId => otherProfile.account_id === accountId)
            }
            return invitation.ignored
          })(),
        },
        priority: priority,
      }
    }
  })


  const activeInvitations = []
  Object.keys(invitationMapping).forEach(key => {
    activeInvitations.push(invitationMapping[key].data)
  })

  return activeInvitations
}


const module = generateModule({
  itemName: 'conversationInvitation',
  itemNameDisplay: 'conversationInvitation',
  getModuleState: (getState) => getState().attendee.conversationInvitations,
  autoRefresh: 60,
  app: 'attendee',
  services: {
    get: serviceGetReceivedConversationInvitations,
  },
})


module.actions.get = () =>
  (dispatch, getState) => {
    dispatch({ type: 'GET_CONVERSATION_INVITATIONS_START' })

    return Promise.all([
      serviceGetReceivedConversationInvitations(
        getEventId(getState),
        response => Promise.resolve(response),
        error => Promise.reject(error.description)
      ),
      serviceGetSentConversationInvitations(
        getEventId(getState),
        response => Promise.resolve(response),
        error => Promise.reject(error.description)
      ),
    ])
      .catch(errors => {
        let errorMessage = ''
        errors.forEach(error => {
          if (error) errorMessage += (error + '\n')
        })

        handleErrorMessage(dispatch, getState, { description: errorMessage })
      })
      .then(invitations => {
        let allInvitations = []
        invitations.forEach(invitation => {
          if (invitation) allInvitations = [...allInvitations, ...invitation]
        })
        dispatch({ type: 'GET_CONVERSATION_INVITATIONS_SUCCESS', payload: { invitations: filterActiveInvitations(allInvitations, 'conversation') } })
      })
  }

module.reducers['GET_CONVERSATION_INVITATIONS_START'] = (state) => ({
  ...state,
  isError: false,
  isFetching: true,
  didInvalidate: false,
})

module.reducers['GET_CONVERSATION_INVITATIONS_SUCCESS'] = (state, action) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
  data: action.payload.invitations,
})

module.reducers['GET_CONVERSATION_INVITATIONS_ERROR'] = (state) => ({
  ...state,
  isFetching: false,
  isLoaded: true,
})


module.actions.sendConversationInvitation = (accountId, message, topicId) =>
  (dispatch, getState) => {
    const initiatedFrom = topicId ? { topic: topicId } : { networking: accountId }

    dispatch({ type: 'SEND_CONVERSATION_INVITATION_START', payload: { accountId, message } })
    return serviceSendConversationInvitation(
      getEventId(getState),
      accountId,
      message,
      initiatedFrom,
      (response) => dispatch({ type: 'SEND_CONVERSATION_INVITATION_SUCCESS', payload: { invitation: response } }),
      (error) => handleErrorMessage(dispatch, getState, error)
    )
  }

module.reducers['SEND_CONVERSATION_INVITATION_SUCCESS'] = (state, action) => ({
  ...state,
  data: [...state.data, action.payload.invitation],
})


module.actions.acceptConversationInvitation = (conversationInvitationId) =>
  (dispatch, getState) => {
    dispatch({ type: 'ACCEPT_CONVERSATION_INVITATION_START', payload: { conversationInvitationId } })

    return serviceAcceptConversationInvitation(
      getEventId(getState),
      conversationInvitationId,
      (response) => {
        dispatch({ type: 'ACCEPT_CONVERSATION_INVITATION_SUCCESS', payload: { ...response } })
      },
      (error) => {
        dispatch({ type: 'ACCEPT_CONVERSATION_INVITATION_ERROR', payload: { conversationInvitationId } })
        handleErrorMessage(dispatch, getState, error)
      }
    )
  }

module.reducers['ACCEPT_CONVERSATION_INVITATION_SUCCESS'] = (state, action) => {
  let invitations = state.data
  const invitationIndex = findConversationInvitationIndex(invitations, action.payload.id)

  if (invitationIndex != null) {
    invitations = [...state.data]
    invitations[invitationIndex] = action.payload
  }

  return { ...state, data: invitations }
}


module.actions.declineConversationInvitation = (conversationInvitationId) =>
  (dispatch, getState) => {
    dispatch({ type: 'DECLINE_CONVERSATION_INVITATION_START', payload: { conversationInvitationId } })

    return serviceDeclineConversationInvitation(
      getEventId(getState),
      conversationInvitationId,
      (response) => {
        dispatch({ type: 'DECLINE_CONVERSATION_INVITATION_SUCCESS', payload: { ...response } })
      },
      (error) => {
        dispatch({ type: 'DECLINE_CONVERSATION_INVITATION_ERROR', payload: { conversationInvitationId } })
        handleErrorMessage(dispatch, getState, error)
      }
    )
  }

module.reducers['DECLINE_CONVERSATION_INVITATION_SUCCESS'] = (state, action) => {
  let invitations = state.data
  const invitationIndex = findConversationInvitationIndex(invitations, action.payload.id)

  if (invitationIndex != null) {
    invitations = [...state.data]
    invitations[invitationIndex] = action.payload
  }

  return { ...state, data: invitations }
}


module.actions.ignoreConversationInvitation = (conversationInvitationId) =>
  (dispatch, getState) => {
    dispatch({ type: 'IGNORE_CONVERSATION_INVITATION_START', payload: { conversationInvitationId } })

    return serviceIgnoreConversationInvitation(
      getEventId(getState),
      conversationInvitationId,
      (response) => {
        dispatch({ type: 'IGNORE_CONVERSATION_INVITATION_SUCCESS', payload: { ...response } })
      },
      (error) => {
        dispatch({ type: 'IGNORE_CONVERSATION_INVITATION_ERROR', payload: { conversationInvitationId } })
        handleErrorMessage(dispatch, getState, error)
      }
    )
  }

module.reducers['IGNORE_CONVERSATION_INVITATION_SUCCESS'] = (state, action) => {
  let invitations = state.data
  const invitationIndex = findConversationInvitationIndex(invitations, action.payload.id)

  if (invitationIndex != null) {
    invitations = [...state.data]
    invitations[invitationIndex] = action.payload
  }

  return { ...state, data: invitations }
}


module.actions.unignoreConversationInvitation = (conversationInvitationId) =>
  (dispatch, getState) => {
    dispatch({ type: 'UNIGNORE_CONVERSATION_INVITATION_START', payload: { conversationInvitationId } })

    return serviceUnignoreConversationInvitation(
      getEventId(getState),
      conversationInvitationId,
      (response) => {
        dispatch({ type: 'UNIGNORE_CONVERSATION_INVITATION_SUCCESS', payload: { ...response } })
      },
      (error) => {
        dispatch({ type: 'UNIGNORE_CONVERSATION_INVITATION_ERROR', payload: { conversationInvitationId } })
        handleErrorMessage(dispatch, getState, error)
      }
    )
  }

module.reducers['UNIGNORE_CONVERSATION_INVITATION_SUCCESS'] = (state, action) => {
  let invitations = state.data
  const invitationIndex = findConversationInvitationIndex(state.data, action.payload.id)

  if (invitationIndex != null) {
    invitations = [...state.data]
    invitations[invitationIndex] = action.payload
  }

  return { ...state, data: invitations }
}


module.reducers['GET_CONVERSATION_SUCCESS'] = (state, action) => {
  let invitations = state.data
  const invitationIndex = findConversationInvitationIndexFromConversation(invitations, action.payload.conversation)

  if (invitationIndex != null) {
    invitations = [...state.data]
    invitations[invitationIndex].conversation = action.payload.conversation
  }

  return { ...state, data: invitations }
}


module.actions.getConversationInvitations = module.actions.get
module.actions.sendConversationInvitation = module.actions.sendConversationInvitation

export default module
