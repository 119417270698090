import fullAccessPermissions from './FullAccess'

export default {
  action: {
    ...fullAccessPermissions.action,

    // Attendees
    addAttendees: false,
    editAttendees: false,
    exportAttendees: false,
    deleteAttendees: false,
    generateWebviewLinks: false,
    impersonateAttendees: false,
    importAttendees: false,
    viewAttendeesLocation: false,

    // Events
    createEvents: false,

    // Exhibitors
    impersonateExhibitors: false,

    // Schedule
    addAttendeesToSessions: false,

    // Users
    modifyUsers: false,
  },

  menu: {
    ...fullAccessPermissions.menu,
    analytics: false,
    attendees: false,
    dashboard: false,
    system: false,
    users: false,
  },

  page: {
    ...fullAccessPermissions.page,
    broadcast: false,
    exports: false,
  },
}
