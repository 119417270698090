import axios from 'axios'
import { apiErrorHandler, apiCatchError } from 'common/utils/api'
import { buildQueryParams, getAxiosConfig } from './helper'
import { getProperty } from 'common/utils/componentHelper'
import { parsePaginationHeaders } from 'common/services/helper'

function formatHubStatus(data) {
  return (data !== null && data !== undefined) ? {
    last_heard: getProperty(data, 'last_heard'),
    last_status_report: getProperty(data, 'last_status_report'),
    hub_battery: getProperty(data, 'hub_battery'),
    amp_battery: getProperty(data, 'amp_battery'),
    connection: getProperty(data, 'connection'),
    report_rate: getProperty(data, 'report_rate'),
    packet_rate: getProperty(data, 'packet_rate'),
    bit_rate: getProperty(data, 'bit_rate'),
    tag_report_rate: getProperty(data, 'tag_report_rate'),
    error_rate: getProperty(data, 'error_rate'),
    error_total: getProperty(data, 'error_total'),
    devices_in_range: getProperty(data, 'devices_in_range'),
    tags_in_range: getProperty(data, 'tags_in_range'),
  } : undefined
}

function formatHub(data) {
  return data != null ? {
    event: getProperty(data, 'event_id'),
    hexnode_id: getProperty(data, 'hexnode_id'),
    id: getProperty(data, 'id'),
    internal_id: getProperty(data, 'internal_id'),
    notes: getProperty(data, 'description'),
    stats: formatHubStatus(getProperty(data, 'status')),
    status: getProperty(data, 'health.level'),
    status_message: getProperty(data, 'health.message'),
    version: getProperty(data, 'version'),
  } : undefined
}

function formatHubs(data) {
  return data.map(formatHub)
}


export function serviceGetHubs(eventId, queryParams) {
  const eventParam = eventId ? { event_id: eventId } : {}

  const queryString = buildQueryParams({
    ...queryParams,
    ...eventParam,
  })

  return axios.get('/hubs' + queryString, getAxiosConfig())
    .then((response) => ({ data: formatHubs(response.data), pagination: parsePaginationHeaders(response) }))
    .catch(apiErrorHandler(apiCatchError, 'backend'))
}

export function serviceGetHub(hubId) {
  return axios.get(`/hubs/${hubId}`, getAxiosConfig())
    .then((response) => formatHub(response.data))
    .catch(apiErrorHandler(apiCatchError, 'backend'))
}

export function serviceGetHubByInternalId(internalId) {
  return axios.get(`/hubs?internal_id=${internalId}`, getAxiosConfig())
    .then((response) => formatHub(response.data[0]))
    .catch(apiErrorHandler(apiCatchError, 'backend'))
}

export function serviceCreateHub(data) {
  return axios.post('/hubs', data, getAxiosConfig())
    .then(response => ({
      hub_id: response.data.hub_id,
      internal_id: response.data.internal_id,
      qrcode: response.data.qrcode,
    }))
    .catch(apiErrorHandler(apiCatchError, 'backend'))
}

export function serviceModifyHub(_, data) {
  return axios.put(`/hubs/${data.id}`, data, getAxiosConfig())
    .then(() => serviceGetHub(data.id))
    .catch(apiErrorHandler(apiCatchError, 'backend'))
}

export function serviceDeleteHub(_, hubId) {
  return axios.delete(`/hubs/${hubId}`, getAxiosConfig())
    .catch(apiErrorHandler(apiCatchError, 'backend'))
}

export function serviceRestartAmp(hubId) {
  const data = {
    command: 'amp_passthrough',
    payload: 'reset',
  }

  return axios.post(`/hubs/${hubId}/commands`, data, getAxiosConfig())
    .catch(apiErrorHandler(apiCatchError, 'backend'))
}

export function serviceRestartApp(hubId) {
  const data = {
    command: 'restart',
  }

  return axios.post(`/hubs/${hubId}/commands`, data, getAxiosConfig())
    .catch(apiErrorHandler(apiCatchError, 'backend'))
}

export function serviceReauthenticateHub(eventId, hubId) {
  return axios.post(`/hubs/${hubId}/credentials`, {}, getAxiosConfig())
    .then(response => ({
      hub_id: response.data.hub_id,
      internal_id: response.data.internal_id,
      qrcode: response.data.qrcode,
    }))
    .catch(apiErrorHandler(apiCatchError, 'backend'))
}
