import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { connectRouter } from 'connected-react-router'
import { isIE, isSafari, isBrowserSupported } from 'common/utils/domUtils'

import admin from 'admin/modules'
import attendee from 'attendee/modules'
import exhibitor from 'exhibitor/modules'
import auth from './auth'
import broadcast from './broadcast'
import { offline as offlineNotification } from './offline'
import toaster from './toaster'
import leaderboard from './leaderboard'
import fileUpload from './fileUpload'
import app from './app'
import tooltips from './Tooltips'
import account from './account'
import fonts from './fonts'
import MapGraphicsModule from './MapGraphics'
import navigator from './navigator'

if (isIE() || isSafari() || !isBrowserSupported()) { // form.normalize does not work without this on IE
  require('babel-polyfill') // eslint-disable-line global-require
}

const appReducer = (history) => combineReducers({
  form: form,
  auth,
  toaster,
  admin,
  attendee,
  broadcast: broadcast.reducers.index,
  exhibitor,
  leaderboard,
  fileUpload,
  offlineNotification,
  app,
  tooltips,
  fonts,
  router: connectRouter(history),
  account,
  mapGraphics: MapGraphicsModule.reducers.index,
  navigator: navigator.reducers.index,
})

export const createRootReducer = (history) =>
  (state, action) => {
    if (action.type === 'LOGOUT') {
      // some auth state must survive a logout. Everything else should be cleared
      state = { auth: state.auth, app: state.app } // eslint-disable-line no-param-reassign
    }

    /* IMPORTANT - Fields that contain 'notrim' won't be trimmed of whitespace */
    if (action.type === 'redux-form/BLUR' && action.field.indexOf('notrim') === -1 && typeof action.value === 'string') {
      action.value = action.value.trim() // eslint-disable-line no-param-reassign
    }

    return appReducer(history)(state, action)
  }

export const updateStateData = (data, newValue, idField = 'id') => {
  const updatedData = [...data]

  let index = -1;
  for (let i = 0; i < updatedData.length; i++) {
    if (updatedData[i][idField] === newValue[idField]) {
      index = i;
      break;
    }
  }

  if (index !== -1) {
    updatedData[index] = JSON.parse(JSON.stringify(newValue)) //eslint-disable-line
  } else {
    updatedData.push(JSON.parse(JSON.stringify(newValue)))
  }

  return updatedData;
}

