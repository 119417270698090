import { serviceGetDomainBranding } from 'common/services/event'
import { serviceUpdateDomainBranding } from 'admin/services/events'
import { showToast } from 'common/modules/toaster'
import { getProperty } from 'common/utils/componentHelper'
import i18n from 'i18next'
import { createSelector } from 'reselect'

import generateModule from 'common/utils/generateModule'


const selectDomainData = (state) => state.admin.domainBranding.data
export const domainSelector = createSelector(selectDomainData, data => getProperty(data, 'domain'))
export const logoUrlSelector = createSelector(selectDomainData, data => getProperty(data, 'logo_url'))
export const splashUrlSelector = createSelector(selectDomainData, data => getProperty(data, 'splash_url'))
export const primaryColorSelector = createSelector(selectDomainData, data => getProperty(data, 'colors.primary'))

export const customAppSelector = createSelector(
  selectDomainData,
  data => getProperty(data, 'custom_app') || ({
    android: {
      store_url: '',
      fcm_key: '',
    },
    ios: {
      store_url: '',
      apns_key: '',
      apns_key_id: '',
      apns_team_id: '',
      apns_topic: '',
    },
  }),
)


const { actions, reducers } = generateModule({
  itemName: 'domainBranding',
  services: {
    get: serviceGetDomainBranding,
  },
  dataIsNotArray: true,
})

actions.get = (domain) =>
  (dispatch) => {
    dispatch(actions.getStart())
    return serviceGetDomainBranding(
      domain,
      (response) => {
        dispatch(actions.getSuccess(response))
      },
      (error) => {
        dispatch(actions.getError(status))
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('domain_branding.get.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

actions.updateDomainBranding = (domain, data) =>
  (dispatch) => {
    dispatch({ type: 'UPDATE_DOMAIN_BRANDING_START' })
    return serviceUpdateDomainBranding(
      domain,
      data,
      (response) => {
        dispatch({ type: 'UPDATE_DOMAIN_BRANDING_SUCCESS', payload: { domainBranding: response } })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('domain_branding.update.title'),
          message: i18n.getFixedT(i18n.language, 'admin_customModule')('domain_branding.update.success'),
          level: 'success',
          permanent: false,
        }))
      },
      (error) => {
        dispatch({ type: 'UPDATE_DOMAIN_BRANDING_ERROR' })
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('domain_branding.update.title'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }

reducers['UPDATE_DOMAIN_BRANDING_START'] = (state) => ({
  ...state,
  isModifying: true,
  isError: false,
})

reducers['UPDATE_DOMAIN_BRANDING_SUCCESS'] = (state, action) => ({
  ...state,
  isModifying: false,
  data: action.payload.domainBranding,
})

reducers['UPDATE_DOMAIN_BRANDING_ERROR'] = (state) => ({
  ...state,
  isModifying: false,
  isError: true,
})

export const getDomainBranding = actions.get
export const updateDomainBranding = actions.updateDomainBranding

const domainBranding = reducers.index
export default domainBranding
