import { serviceGetLeaderboard, serviceGetLeaderboardAround, serviceGenerateLeaderboardLink } from 'common/services/leaderboard'
import { showToast } from 'common/modules/toaster'
import config from 'config'
import i18n from 'i18next'

/* ---> GENERATE LINK TO TV LEADERBOARD <--- */
function generateTVLeaderboardLinkStart() {
  return {
    type: 'GENERATE_TV_LEADERBOARD_LINK_START',
  }
}

function generateTVLeaderboardLinkSuccess(link) {
  return {
    type: 'GENERATE_TV_LEADERBOARD_LINK_SUCCESS',
    data: {
      link: link,
    },
  }
}

function generateTVLeaderboardLinkError() {
  return {
    type: 'GENERATE_TV_LEADERBOARD_LINK_ERROR',
  }
}

export function generateTVLeaderboardLink(eventId) {
  return dispatch => {
    dispatch(generateTVLeaderboardLinkStart())
    serviceGenerateLeaderboardLink(
      eventId,
      (response) => {
        const link = `${config.appUrl}/leaderboard/${eventId}/${i18n.language}?_autologin=${response.refresh_token}`
        dispatch(generateTVLeaderboardLinkSuccess(link))
      },
      (error) => {
        dispatch(generateTVLeaderboardLinkError())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'admin_customModule')('Failed to generate TV Leaderboard link'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}

/* ---> TOP LEADERBOARD <--- */
function getLeaderboardStart() {
  return {
    type: 'GET_LEADERBOARD_START',
  }
}

function getLeaderboardSuccess(response) {
  return {
    type: 'GET_LEADERBOARD_SUCCESS',
    data: {
      leaderboard: response.leaderboard,
      total: response.attendee_count,
    },
  }
}

function getLeaderboardError() {
  return {
    type: 'GET_LEADERBOARD_ERROR',
  }
}

export function getLeaderboard(eventId, gameId) {
  return dispatch => {
    dispatch(getLeaderboardStart())
    serviceGetLeaderboard(
      eventId,
      gameId,
      (response) => {
        dispatch(getLeaderboardSuccess(response))
      },
      (error) => {
        dispatch(getLeaderboardError())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'common_customModule')('Failed to retrieve leaderboard'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}

/* ---> AROUND LEADERBOARD <--- */
function getLeaderboardAroundStart() {
  return {
    type: 'GET_LEADERBOARD_AROUND_START',
  }
}

function getLeaderboardAroundSuccess(response, attendeeId) {
  const selfData = response.leaderboard.find(e => e.id === attendeeId)

  return {
    type: 'GET_LEADERBOARD_AROUND_SUCCESS',
    data: {
      around: response.leaderboard,
      selfId: selfData ? selfData.id : undefined,
      selfRank: selfData ? selfData.rank : undefined,
    },
  }
}

function getLeaderboardAroundError() {
  return {
    type: 'GET_LEADERBOARD_AROUND_ERROR',
  }
}

export function getLeaderboardAround(eventId, gameId, attendeeId, limit) {
  return dispatch => {
    dispatch(getLeaderboardAroundStart())
    serviceGetLeaderboardAround(
      eventId,
      gameId,
      attendeeId,
      limit,
      (response) => {
        dispatch(getLeaderboardAroundSuccess(response, attendeeId))
      },
      (error) => {
        dispatch(getLeaderboardAroundError())
        dispatch(showToast({
          title: i18n.getFixedT(i18n.language, 'common_customModule')('Failed to retrieve leaderboard'),
          message: error.description,
          level: 'error',
          permanent: false,
        }))
      }
    )
  }
}


const initialState = {
  isFetching: false,
  isError: false,
  isLoaded: false,
  lastOperationStatus: '',
  leaderboard: [],
  around: [],
  selfId: undefined,
  selfRank: undefined,
  linkTVLeaderboard: undefined,
}

export default function auth(state = initialState, action) {
  switch (action.type) {

    /* ---> Generate TV Leaderboard Link <--- */
    case 'GENERATE_TV_LEADERBOARD_LINK_START':
      return {
        ...state,
        lastOperationStatus: 'Generating TV Leaderboard link',
      }

    case 'GENERATE_TV_LEADERBOARD_LINK_SUCCESS':
      return {
        ...state,
        linkTVLeaderboard: action.data.link,
        lastOperationStatus: 'TV Leaderboard link generated successfully',
      }

    case 'GENERATE_TV_LEADERBOARD_LINK_ERROR':
      return {
        ...state,
        lastOperationStatus: 'Could not generate TV Leaderboard link',
      }

    /* ---> Top Leaderboard <--- */

    case 'GET_LEADERBOARD_START':
      return {
        ...state,
        isFetching: true,
        lastOperationStatus: 'Fetching leaderboard',
      }

    case 'GET_LEADERBOARD_SUCCESS':
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        lastOperationStatus: 'Leaderboard retrieved successfully',
        leaderboard: action.data.leaderboard,
        total: action.data.total,
      }

    case 'GET_LEADERBOARD_ERROR':
      return {
        ...state,
        isFetching: false,
        isLoaded: false,
        isError: true,
        lastOperationStatus: 'Could not retrieve leaderboard',
      }


    /* ---> Around Leaderboard <--- */

    case 'GET_LEADERBOARD_AROUND_START':
      return {
        ...state,
        isFetching: true,
        isLoaded: false,
        isError: false,
        lastOperationStatus: 'Retrieving leaderboard around attendee',
      }

    case 'GET_LEADERBOARD_AROUND_SUCCESS':
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        isError: false,
        lastOperationStatus: 'Leaderboard retrieved successfully',
        around: action.data.around,
        selfId: action.data.selfId,
        selfRank: action.data.selfRank,
      }

    case 'GET_LEADERBOARD_AROUND_ERROR':
      return {
        ...state,
        isFetching: false,
        isLoaded: false,
        isError: true,
        lastOperationStatus: 'Could not retrieve leaderboard around attendee',
      }

    default:
      return state
  }
}
