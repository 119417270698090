import { generateDataModule, invalidateOnEventChange } from 'admin/modules/generateModule'
import { serviceGetAverageContagionScoreEmployees } from 'admin/services/bluetrace'


const AverageContagionScoreEmployees = generateDataModule({
  initialData: {
    currentScore: undefined,
    previousScore: undefined,
  },
  moduleKey: 'cpo/bluetrace/averageContagionScoreEmployees',
  reducerKey: 'admin.bluetrace.averageContagionScores.employees',
  refreshTime: 10000,
  services: {
    get: { service: serviceGetAverageContagionScoreEmployees },
  },
})

invalidateOnEventChange(AverageContagionScoreEmployees)


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

AverageContagionScoreEmployees.registerSelector(
  'previousScore',
  (state, moduleState) => moduleState.data.previousScore,
  score => score || 0
)

AverageContagionScoreEmployees.registerSelector(
  'currentScore',
  (state, moduleState) => moduleState.data.currentScore,
  score => score || 0
)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////


export default AverageContagionScoreEmployees
