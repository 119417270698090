import React, { Component } from 'react'
import { translate } from 'react-i18next'
import config from 'config'
import i18n from 'i18next'

import ExternalLink from 'common/components/ExternalLink'


@translate(['login'])
export default class TermsAndConditions extends Component {
  render() {
    const { t } = this.props
    const terms = <ExternalLink href={config.tosLink[i18n.language]} target="_blank">{t('login:tos_link')}</ExternalLink>
    const privacy = <ExternalLink href={config.ppLink[i18n.language]} target="_blank">{t('login:pp_link')}</ExternalLink>

    return (
      <div className="TermsAndConditions">
        {t('login:tos')} {terms} {t('login:and')} {privacy}
      </div>
    )
  }
}
