import { getProperty } from 'common/utils/componentHelper'

import { infrasByIdSelector } from 'admin/modules/infras'
import { locationsByIdSelector } from 'admin/modules/locations'
import TouchpointsModule from 'admin/modules/touchpoints'


export default function initializeSelectors(MapGraphicsModule) {
  const selectMapState = (moduleState, mapId) => moduleState[mapId]

  const selectSelectablesByType = (state) => ({
    infra: infrasByIdSelector(state),
    zone: locationsByIdSelector(state),
  })

  const getSelectionByType = (selection, selectables, type) => (getProperty(selection, 'type') === type ? selectables[type][selection.dataId] : null)

  const getSelectedObjectsById = (selection, selectablesByType, type) => {
    const selectedObjectsById = {}
    const selectables = selectablesByType[type]

    for (let i = 0; i < selection.length; i++) {
      if (selection[i].type === type) {
        const selectedObject = selectables[selection[i].dataId]
        if (selectedObject) {
          selectedObjectsById[selection[i].dataId] = selectedObject
        }
      }
    }

    return selectedObjectsById
  }


  MapGraphicsModule.registerSelector(
    'drawingMode',
    (state, moduleState, mapId) => selectMapState(moduleState, mapId),
    (mapState) => getProperty(mapState, 'drawingMode')
  )

  MapGraphicsModule.registerSelector(
    'selection',
    (state, moduleState, mapId) => selectMapState(moduleState, mapId),
    (mapState) => getProperty(mapState, 'selection')
  )

  MapGraphicsModule.registerSelector(
    'selectedInfrasById',
    [
      (state, moduleState, mapId) => MapGraphicsModule.selectors.selection(state, mapId),
      (state) => selectSelectablesByType(state),
    ],
    (selection, selectablesByType) => getSelectedObjectsById(selection, selectablesByType, 'infra')
  )

  MapGraphicsModule.registerSelector(
    'selectedZonesById',
    [
      (state, moduleState, mapId) => MapGraphicsModule.selectors.selection(state, mapId),
      (state) => selectSelectablesByType(state),
    ],
    (selection, selectablesByType) => getSelectedObjectsById(selection, selectablesByType, 'zone')
  )

  MapGraphicsModule.registerSelector(
    'selectedInfra',
    [
      (state, moduleState, mapId) => MapGraphicsModule.selectors.selection(state, mapId),
      (state) => selectSelectablesByType(state),
    ],
    (selection, selectables) => getSelectionByType(selection, selectables, 'infra')
  )

  MapGraphicsModule.registerSelector(
    'selectedTouchpoint',
    [
      (state, moduleState, mapId) => MapGraphicsModule.selectors.selectedInfra(state, mapId),
      (state) => TouchpointsModule.selectors.touchpointsByInfraId(state),
    ],
    (selectedInfra, touchpointsByInfraId) => {
      if (!selectedInfra || selectedInfra.type !== 'touchpoint') return null
      return touchpointsByInfraId[selectedInfra.id]
    }
  )

  MapGraphicsModule.registerSelector(
    'selectedZone',
    [
      (state, moduleState, mapId) => MapGraphicsModule.selectors.selection(state, mapId),
      (state) => selectSelectablesByType(state),
    ],
    (selection, selectables) => getSelectionByType(selection, selectables, 'zone')
  )
}

