import config from 'config'
import i18n from 'i18next'
import { getAccessToken } from 'common/services/helper'


let language = i18n.language
i18n.on('languageChanged', (lng) => { language = lng })


export function getAxiosConfig() { // eslint-disable-line import/prefer-default-export
  return {
    baseURL: config.apiUrl,
    headers: {
      'Accept-Language': language,
      Authorization: `Bearer ${getAccessToken()}`,
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json; charset=utf-8',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      Pragma: 'no-cache',
    },
  }
}
