import { generateDataModule } from './generateModule'
import { serviceGetPrivacyPolicy, serviceUpdatePrivacyPolicy } from 'admin/services/events'
import { showToast } from 'common/modules/toaster'
import i18n from 'i18next'


const PrivacyPolicyModule = generateDataModule({
  moduleKey: 'cpo/PrivacyPolicy',
  reducerKey: 'admin.privacyPolicy',
  initialData: {},

  services: {
    get: { service: serviceGetPrivacyPolicy },
    put: { service: serviceUpdatePrivacyPolicy },
  },
})


PrivacyPolicyModule.actions.get = () => dispatch => {
  dispatch({ type: PrivacyPolicyModule.actionKeys.getStart })

  return PrivacyPolicyModule._getService('get')()
    .then(response => {
      dispatch({ type: PrivacyPolicyModule.actionKeys.getSuccess, payload: response })
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({ type: PrivacyPolicyModule.actionKeys.getError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('privacy_policy.get.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject(error)
    })
}


PrivacyPolicyModule.actions.put = data => dispatch => {
  dispatch({ type: PrivacyPolicyModule.actionKeys.putStart })

  return PrivacyPolicyModule._getService('put')(data)
    .then(response => {
      dispatch({ type: PrivacyPolicyModule.actionKeys.putSuccess, payload: response })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('privacy_policy.update.title'),
        message: i18n.getFixedT(i18n.language, 'admin_customModule')('privacy_policy.update.success'),
        level: 'success',
        permanent: false,
      }))
      return Promise.resolve(response)
    })
    .catch(error => {
      dispatch({ type: PrivacyPolicyModule.actionKeys.putError })
      dispatch(showToast({
        title: i18n.getFixedT(i18n.language, 'admin_customModule')('privacy_policy.update.title'),
        message: error.description,
        level: 'error',
        permanent: false,
      }))
      return Promise.reject(error)
    })
}

PrivacyPolicyModule.registerReducer(PrivacyPolicyModule.actionKeys.putSuccess, (state, action) => ({
  ...state,
  data: { ...action.payload },
}))


export default PrivacyPolicyModule
