import { generateDataModule } from 'admin/modules/generateModule'
import { serviceGetNetworkTopEmployees } from 'admin/services/bluetrace'
import { listToMapping } from 'common/utils/componentHelper'
import Moment from 'moment'

import { eventTimezoneSelector } from 'admin/modules/events'
import NetworkFiltersModule from './filters'


const NetworkEmployeesModule = generateDataModule({
  initialData: {
    searchResults: [],
    top: [],
  },
  moduleKey: 'cpo/bluetrace/networkEmployees',
  reducerKey: 'admin.bluetrace.employeeNetwork.topEmployees',
  refreshTime: 10000,
  services: {
    get: { service: serviceGetNetworkTopEmployees },
  },
})


// //////////////////////////////////////////////////////////////////////////////////////
// Selectors
// //////////////////////////////////////////////////////////////////////////////////////

NetworkEmployeesModule.registerSelector(
  'data',
  (state, moduleState) => moduleState.data.top,
  value => value
)

NetworkEmployeesModule.registerSelector(
  'dataById',
  (state, moduleState) => moduleState.data.top,
  value => listToMapping(value, 'id')
)

NetworkEmployeesModule.registerSelector(
  'searchResults',
  (state, moduleState) => moduleState.data.searchResults,
  value => value
)

NetworkEmployeesModule.registerSelector(
  'searchResultsById',
  (state, moduleState) => moduleState.data.searchResults,
  value => listToMapping(value, 'id')
)

NetworkEmployeesModule.registerSelector(
  'employeeData',
  [
    state => NetworkEmployeesModule.selectors.dataById(state),
    state => NetworkEmployeesModule.selectors.searchResultsById(state),
    state => NetworkFiltersModule.selectors.employeeId(state),
  ],
  (employeesById, searchedEmployeeById, employeeId) => searchedEmployeeById[employeeId] || employeesById[employeeId]
)


// //////////////////////////////////////////////////////////////////////////////////////
// Actions
// //////////////////////////////////////////////////////////////////////////////////////

NetworkEmployeesModule.registerDataAction('get', () => (dispatch, getState) => {
  const state = getState()
  const eventId = NetworkEmployeesModule._getEventId()
  const timezone = eventTimezoneSelector(state)
  const searchTerm = NetworkFiltersModule.selectors.search(state)

  const queryParams = {
    end_date: Moment().tz(timezone).endOf('day').unix(),
    limit: 25,
    query: searchTerm || undefined,
    start_date: Moment().tz(timezone).startOf('day').subtract(6, 'days').unix(),
  }

  dispatch({
    type: NetworkEmployeesModule.actionKeys.getStart,
    payload: {
      isSearch: !!searchTerm,
    },
  })

  return serviceGetNetworkTopEmployees(eventId, queryParams)
    .then(response => {
      dispatch({
        type: NetworkEmployeesModule.actionKeys.getSuccess,
        payload: {
          data: response,
          isSearch: !!searchTerm,
        },
      })
    })
    .catch(() => {
      dispatch({ type: NetworkEmployeesModule.actionKeys.getError })
      return Promise.reject()
    })
})


// //////////////////////////////////////////////////////////////////////////////////////
// Reducers
// //////////////////////////////////////////////////////////////////////////////////////

NetworkEmployeesModule.registerReducer(
  NetworkEmployeesModule.actionKeys.getStart,
  (state, action) => ({
    ...state,
    data: {
      ...state.data,
      searchResults: action.payload.isSearch ? [] : state.data.searchResults,
    },
  })
)

NetworkEmployeesModule.registerReducer(
  NetworkEmployeesModule.actionKeys.getSuccess,
  (state, action) => ({
    ...state,
    data: {
      searchResults: action.payload.isSearch ? action.payload.data : state.data.searchResults,
      top: action.payload.isSearch ? state.data.top : action.payload.data,
    },
  })
)


export default NetworkEmployeesModule
