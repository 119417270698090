import Moment from 'moment'
import { showToast } from 'common/modules/toaster'


export default function generateGetServices(actionKeyPrefix, options) {
  const actions = {}
  const reducers = {}

  const startType = `${actionKeyPrefix}_DELETE_START`.toUpperCase()
  actions.deleteStart = args => ({
    type: startType,
    requestTime: Moment().unix(),
    payload: args,
  })

  reducers[startType] = state => ({
    ...state,
    isModifying: true,
    isError: false,
  })


  const successType = `${actionKeyPrefix}_DELETE_SUCCESS`.toUpperCase()
  actions.deleteSuccess = args => ({
    type: successType,
    requestTime: Moment().unix(),
    payload: args,
  })

  reducers[successType] = (state, action) => ({
    ...state,
    isModifying: false,
    data: (() => {
      if (Array.isArray(state.data)) return state.data.filter(data => data[options.idField] !== action.payload)
      return state.data
    })(),
    lastUpdated: action.requestTime,
  })


  const errorType = `${actionKeyPrefix}_DELETE_ERROR`.toUpperCase()
  actions.deleteError = errorMessage => ({
    type: errorType,
    requestTime: Moment().unix(),
    payload: errorMessage,
  })

  reducers[errorType] = state => ({
    ...state,
    isModifying: false,
    isError: true,
  })


  actions.delete = (...args) =>
    (dispatch, getState) => {
      dispatch(actions.deleteStart(...args))
      return options.services.delete.service(getState, ...args)
        .then(response => {
          dispatch(actions.deleteSuccess(...args))

          if (options.services.delete.showOnSuccess) {
            dispatch(showToast({
              title: options.t('delete.title'),
              message: (options.services.delete.successMessage
                ? options.services.delete.successMessage(response, ...args)
                : options.t('delete.success')),
              level: 'success',
            }))
          }
        })
        .catch(error => {
          dispatch(actions.deleteError(error.description))

          if (options.services.delete.showOnError) {
            dispatch(showToast({
              title: options.t('delete.title'),
              message: (options.services.delete.errorMessage
                ? options.services.delete.errorMessage(error, ...args)
                : error.description),
              level: 'error',
            }))
          }
        })
    }

  return { actions, reducers }
}
